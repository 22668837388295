import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import clone from 'clone';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import * as ActionTypes from '../../ninja-redux/actionTypes';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import validator from 'validator';
// import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as helpActions from '../../ninja-redux/actions/helpActions';


import Modal from 'react-modal';
import ModalShowMessage from "../../layouts/partials/ModalShowMessage";
import $ from 'jquery';

let stateLoginChanged = false;
class QuotesParticipantsGSA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDenideSuccessIsOpen: false,
            secretKey: '',
            quoteID: '',
            errorText: '',
            errorMessage: null,
            successMessage: "",
            modalError: false,
            isError: false,
            isEdit: false,
            errors: {},
            loadedScreen: false,
            noOptionsCustomer: "Type to find customers",
            steps: [
                {
                    'link': 'quotes/flights',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Flights'
                },
                {
                    'link': 'quotes/participants-gsa',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'quotes/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            loadedScreen: false,
            inputValueShippingAgent: '',
            inputValueReceivingAgent: '',
            isLoadingShipingAgent: false,
            isLoadingReceivingAgent: false,
            disableEditStatus: ['decline', 'canceled', 'cancel', 'creating quote', 'booked'],
        };
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalIsOpen: false });
            }
        });
    }

    closeDenideSuccessModal = () => {
        this.setState({ modalDenideSuccessIsOpen: false });
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { errors, successMessage, modalError, modalDenideSuccessIsOpen } = this.state;
        const { target, target: { name, type } } = event;
        const value = (target.type == 'checkbox') ? target.checked : target.value;
        var { quotes, auth } = this.props;
        errors[name] = "";
        if (name === "selectedShippingReceiving") {
            if (quotes["selectedShippingReceiving"] === value) {
                quotes[name] = '';
            } else {
                quotes[name] = value;
            }

            if (value === 'Receiving') {
                quotes["shippingCompanyName"] = "";
                quotes["shippingIATA1"] = "";
                quotes["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                errors["shippingIATA"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                quotes.shipping_company = null;
                if (quotes[name]) {
                    quotes.receiving_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                    quotes.receivingCompanyName = auth.custdes ? auth.custdes : "";
                    quotes.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                    quotes.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
                } else {
                    quotes.receiving_company = null;
                    quotes["receivingCompanyName"] = "";
                    quotes["receivingIATA1"] = "";
                    quotes["receivingIATA2"] = "";
                }
            } else {
                quotes["receivingCompanyName"] = "";
                quotes["receivingIATA1"] = "";
                quotes["receivingIATA2"] = "";
                errors["receivingCompanyName"] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA2"] = "";
                quotes.receiving_company = null;
                if (quotes[name]) {
                    quotes.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                    quotes.shippingCompanyName = auth.custdes ? auth.custdes : "";
                    quotes.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                    quotes.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
                } else {
                    quotes.shipping_company = null;
                    quotes["shippingCompanyName"] = "";
                    quotes["shippingIATA1"] = "";
                    quotes["shippingIATA2"] = "";
                }

            }
        } else if (name === 'payingCustomer') {
            quotes.payingCustomerShipping = false;
            quotes.payingCustomerFreight = false;
            quotes.payingCustomer = !quotes.payingCustomer;
            if (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y') {
                quotes.payingCustomer = false;
                this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
            }
        } else if (name === 'payingCustomerShipping') {
            quotes.payingCustomer = false;
            quotes.payingCustomerFreight = false;
            quotes.payingCustomerShipping = !quotes.payingCustomerShipping;
            if ((quotes.selectedShippingReceiving === 'Shipping' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (quotes.shipping_company && quotes.shipping_company.status && quotes.shipping_company.status.toUpperCase() !== 'ACTIVE')) {
                quotes.payingCustomerShipping = false;
                this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
            }
        } else if (name === 'payingCustomerFreight') {
            quotes.payingCustomer = false;
            quotes.payingCustomerShipping = false;
            quotes.payingCustomerFreight = !quotes.payingCustomerFreight;

            if ((quotes.selectedShippingReceiving === 'Receiving' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (quotes.receiving_company && quotes.receiving_company.status && quotes.receiving_company.status.toUpperCase() !== 'ACTIVE')) {
                quotes.payingCustomerFreight = false;
                this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
            }
        } else {
            quotes[name] = value;
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
        this.setState({ errors });
    }

    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { quotes } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        if (name == "shippingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors["shippingIATA"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                if (quotes.shippingIATA1.length == 7 && quotes.shippingIATA2.length == 4) {
                    this.handlerCheckIATACode(quotes.shippingIATA1 + "" + quotes.shippingIATA2, 'Shipping');
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "shippingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors["shippingIATA"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                if (quotes.shippingIATA1.length == 7 && quotes.shippingIATA2.length == 4) {
                    this.handlerCheckIATACode(quotes.shippingIATA1 + "" + quotes.shippingIATA2, 'Shipping');
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else if (name == "receivingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingCompanyName"] = "";
                if (quotes.receivingIATA1.length == 7 && quotes.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(quotes.receivingIATA1 + "" + quotes.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "receivingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingCompanyName"] = "";
                if (quotes.receivingIATA1.length == 7 && quotes.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(quotes.receivingIATA1 + "" + quotes.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else {
            if (!validator.isEmpty(value)) {
                errors[name] = "";
            }
        }

        this.setState({ errors });
    }

    handlerCheckIATACode = async (iataCode, checkType = "FreightForwarder") => {
        var { quotes } = this.props;
        var { errors } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        let response = await this.props.helpActions.checkACode(iataCode);


        // console.log("response", response);
        if (response.success && response.data.length && response.data[0].reason.toUpperCase() == "VALID") {
            // console.log("checkType", checkType);

            var scanCustData = await this.props.quotesActions.scanCompany(response.data[0]['agency-name']);
            if (scanCustData.success && scanCustData.data.length) {
                // search
                // scanCustData.data
                let findCustomer = scanCustData.data.find(c => c.label === response.data[0]['agency-name'].toUpperCase());

                if (findCustomer) {
                    if (checkType === 'FreightForwarder') {
                        quotes.receiving_company = findCustomer;
                        quotes.receivingCompanyName = findCustomer.label;
                        quotes.checkReceivingIATA = true;
                        errors['receivingIATA'] = "";
                        errors['receivingIATA1'] = "";
                        errors['receivingIATA2'] = "";
                        if (quotes.payingCustomerFreight && findCustomer.status.toUpperCase() !== 'ACTIVE') {
                            quotes.payingCustomerFreight = false;
                        }
                    } else {
                        quotes.shipping_company = findCustomer;
                        quotes.shippingCompanyName = findCustomer.label;
                        quotes.checkShippingIATA = true;
                        errors['shippingIATA1'] = "";
                        errors['shippingIATA2'] = "";
                        if (quotes.payingCustomerShipping && findCustomer.status.toUpperCase() !== 'ACTIVE') {
                            quotes.payingCustomerShipping = false;
                        }
                    }
                    await this.props.quotesActions.quotesUpdateData(quotes);
                } else {
                    if (checkType === 'FreightForwarder') {
                        quotes.checkReceivingIATA = false;
                        errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['receivingIATA1'] = "";
                        errors['receivingIATA2'] = "";
                    } else {
                        quotes.checkShippingIATA = false;
                        errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['shippingIATA1'] = "";
                        errors['shippingIATA2'] = "";
                    }
                    await this.props.quotesActions.quotesUpdateData(quotes);
                }
            } else {
                if (checkType === 'FreightForwarder') {
                    quotes.checkReceivingIATA = false;
                    errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['receivingIATA1'] = "";
                    errors['receivingIATA2'] = "";
                } else {
                    quotes.checkShippingIATA = false;
                    errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['shippingIATA1'] = "";
                    errors['shippingIATA2'] = "";
                }
                await this.props.quotesActions.quotesUpdateData(quotes);
            }

        } else {

            if (checkType === 'FreightForwarder') {
                quotes.checkReceivingIATA = false;
                errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                errors['receivingIATA1'] = "";
                errors['receivingIATA2'] = "";
            } else {
                quotes.checkShippingIATA = false;
                errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                errors['shippingIATA1'] = "";
                errors['shippingIATA2'] = "";
            }
            await this.props.quotesActions.quotesUpdateData(quotes);
        }
        await this.props.loadingActions.loadingChangeData(false);
        this.setState({ errors });
    }

    componentDidUpdate = async (oldProps) => {
        const newProps = this.props;
        var { loadedScreen } = this.state;
        var { country, port, currencies } = this.props;
        if (country.countries.length && port.ports.length && currencies.data.length && !loadedScreen) {
            this.initScreen();
        }
        if (newProps.auth.state != "LOGGED_IN") {
            stateLoginChanged = false;
        }
    }

    async componentDidMount() {
        var { showError, quoteID, steps } = this.state;
        var { quotes, auth, history } = this.props;
        // console.log(quotes);
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        quoteID = this.props.match.params.quoteID;
        steps = steps.map((step, i) => {
            if (quotes.secretKey) {
                if (i < 2) {
                    step.link += '/' + quoteID;
                } else {
                    step.link = 'quotes/review/' + quotes.secretKey + '/' + quoteID;
                }
            } else {
                step.link += '/' + quoteID;
            }
            return step;
        })

        this.setState({ steps });

        // if (!auth.IsGSA) {
        //     history.push('/quotes/participants/' + quoteID);
        // }

        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    componentDidUpdate(oldProps) {
        var { history } = this.props;
        const newProps = this.props;
        var quoteID = this.props.match.params.quoteID;
        if (newProps.auth.state === 'LOGGED_IN' && !newProps.auth.IsGSA) {
            history.push('/quotes/participants/' + quoteID);
        }
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var quoteID = this.props.match.params.quoteID;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED') && !auth.expiredToken && !window.checkHasModalShowing()) {
            history.push("/login?back=" + base64.encode("/quotes/participants-gsa/" + quoteID));
        }
    }

    handClickStep = async () => {

    }

    initScreen = async () => {
        this.setState({ loadedScreen: true });
        var { secretKey, quoteID, steps, editFreightForwarder, showFreightForwarder, showPickup, showDelivery, showGroundHandlingOrigin, showGroundHandlingDestination, selectedCountryConsignee, selectedCountryConsignor, selectedCountryDelivery, selectedCountryPickup, showClearConsignor, showClearConsignee, inputValueAirline, inputValueGSA, inputValueConsignee, inputValueConsignor } = this.state;
        var { quotes, auth, history } = this.props;
        quoteID = this.props.match.params.quoteID;
        if (!quotes || quotes.quoteID !== quoteID) {
            await this.props.quotesActions.quotesReset();
            await this.props.loadingActions.loadingChangeData(true);
            let getQuotesData = await this.props.quotesActions.buildQuoteData(secretKey, quoteID, true);
            await this.props.loadingActions.loadingChangeData(false);
            if (getQuotesData.success) {
                await this.props.quotesActions.quotesUpdateData(getQuotesData.quotesData);
                quotes = getQuotesData.quotesData;
            } else {
                this.setState({ errorText: 'showContactLink' })
            }
        }

        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toLowerCase();
        if (!quotes.status || this.state.disableEditStatus.includes(status)) {
            this.setState({ errorText: 'showContactLink' })
            return;
        }

        if (quotes.selectedShippingReceiving === 'Shipping') {
            if (!quotes.shipping_company) {
                quotes.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                quotes.shipping_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' }];
                quotes.shippingCompanyName = auth.custdes ? auth.custdes : "";
            }

            if (!quotes.shippingIATA1) {
                quotes.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                quotes.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            }
            await this.props.quotesActions.quotesUpdateData(quotes);
        } else if (quotes.selectedShippingReceiving === 'Receiving') {
            if (!quotes.receiving_company) {
                quotes.receiving_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                quotes.receiving_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' }];
                quotes.receivingCompanyName = auth.custdes ? auth.custdes : "";
            }
            if (!quotes.receivingIATA1) {
                quotes.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                quotes.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            }
            await this.props.quotesActions.quotesUpdateData(quotes);
        }


        if (localStorage.getItem('SHOW_VALIDATE')) {
            localStorage.removeItem('SHOW_VALIDATE');
            this.isValidation();
        }
    }

    /* Validate form */
    isValidation = async () => {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        const { showPickup, showDelivery, showFreightForwarder, showGroundHandlingOrigin, showGroundHandlingDestination } = this.state;
        var { quotes } = this.props;

        if (quotes.selectedShippingReceiving === "Shipping" || quotes.payingCustomerShipping) {
            if (validator.isEmpty(quotes.shippingCompanyName)) {
                errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (!validator.isEmpty(quotes.shippingIATA1) && quotes.shippingIATA1.length < 7) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.shippingIATA2) && quotes.shippingIATA2.length < 4) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(quotes.shippingIATA1) && !validator.isEmpty(quotes.shippingIATA2)) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.shippingIATA1) && validator.isEmpty(quotes.shippingIATA2)) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (quotes.selectedShippingReceiving === "Receiving" || quotes.payingCustomerFreight) {
            if (validator.isEmpty(quotes.receivingCompanyName)) {
                errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.isEmpty(quotes.receivingIATA1) && quotes.receivingIATA1.length < 7) {
                errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.receivingIATA2) && quotes.receivingIATA2.length < 4) {
                errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(quotes.receivingIATA1) && !validator.isEmpty(quotes.receivingIATA2)) {
                errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.receivingIATA1) && validator.isEmpty(quotes.receivingIATA2)) {
                errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (validator.isEmpty(quotes.shippingCompanyName) && (!validator.isEmpty(quotes.shippingIATA1) || !validator.isEmpty(quotes.shippingIATA2))) {
            errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(quotes.receivingCompanyName) && (!validator.isEmpty(quotes.receivingIATA1) || !validator.isEmpty(quotes.receivingIATA2))) {
            errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        // console.log(errors);
        if (isError) {
            this.setState({ errors });
            quotes.step_3_ok = false;
            await this.props.quotesActions.quotesUpdateData(quotes);
            return false;
        }
        quotes.step_3_ok = true;
        await this.props.quotesActions.quotesUpdateData(quotes);
        return true;
    }

    handBack = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var { quotes, history } = this.props;
        history.push('/quotes/flights/' + quotes.CProfAU);
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        var checkError = await this.isValidation();
        if (!checkError) {
            window.scrollToFirstError();
            return false;
        }
        window.jumpToTop();
        var { quotes, history } = this.props;
        // console.log(quotes);
        if (quotes.secretKey) {
            history.push('/quotes/review/' + quotes.secretKey + '/' + quotes.CProfAU);
        } else {
            history.push('/quotes/review-order/' + quotes.CProfAU);
        }
    }

    handlerShowLoginForm = (e) => {
        stateLoginChanged = true;
        window.parent.postMessage('show-login-form', '*');
    }

    handleClickShowNewCustomerForm = async () => {

    }

    filterOption = (option, inputValue) => {
        // Do no filtering, just return all options
        inputValue = inputValue.toUpperCase();
        let keywords = inputValue.split(" ");
        let { label, value } = option;

        // label = label.toUpperCase();
        // if (keywords.some(v => label.includes(v))) {
        //     return true;
        // }
        // return false;
        return true;
    };

    showNoOptionCustomer = () => {
        if (this.state.noOptionsCustomer === 'Not found!') {
            return (
                <div><span>Not found!</span></div>
            );
        } else {
            return (
                <span>{this.state.noOptionsCustomer}</span>
            );
        }
    }

    handleChangeShipping = async (selectedOption) => {
        var { errors } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.shippingCompanyName = '';
        errors.shippingIATA1 = '';
        errors.shippingIATA2 = '';
        errors.shippingIATA = '';
        quotes.shippingCompanyName = '';
        quotes.shipping_company = selectedOption;
        quotes.shippingIATA1 = '';
        quotes.shippingIATA2 = '';
        if (selectedOption) {
            quotes.shippingCompanyName = selectedOption.label;
            if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
                quotes.payingCustomerShipping = false;
            }
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeReceiving = async (selectedOption) => {
        var { errors } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }

        errors.receivingCompanyName = '';
        errors.receivingIATA1 = '';
        errors.receivingIATA2 = '';
        errors.receivingIATA = '';
        quotes.receivingCompanyName = '';
        quotes.receivingIATA1 = '';
        quotes.receivingIATA2 = '';
        quotes.receiving_company = selectedOption;
        if (selectedOption) {
            quotes.receivingCompanyName = selectedOption.label;
            if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
                quotes.payingCustomerFreight = false;
            }
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handlerSelectShippingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueShippingAgent: e });
        if (this.timeoutSearchShippingAgent) {
            clearTimeout(this.timeoutSearchShippingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchShippingAgent = setTimeout(function () {
            t.handleScanCust('ShippingAgent');
        }, 1000);
    }

    handlerSelectReceivingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueReceivingAgent: e });
        if (this.timeoutSearchReceivingAgent) {
            clearTimeout(this.timeoutSearchReceivingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchReceivingAgent = setTimeout(function () {
            t.handleScanCust('ReceivingAgent');
        }, 1000);
    }

    handleScanCust = async (selectName) => {
        var { noOptionsCustomer, inputValueShippingAgent, inputValueReceivingAgent, inputValueAirline, inputValueGSA, inputValueFeederGSA, inputValueConsignee, inputValueConsignor, receivingSuggests, consigneeSuggests, consignorSuggests } = this.state;
        var keyword = '';

        switch (selectName) {
            case 'ShippingAgent':
                keyword = clone(inputValueShippingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingShipingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueShippingAgent);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        quotes.shipping_company_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        quotes.shipping_company_options = scanCustData.data;
                    }
                    this.setState({ isLoadingShipingAgent: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'ReceivingAgent':
                keyword = clone(inputValueReceivingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingReceivingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueReceivingAgent);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        quotes.receiving_company_options = quotes.receivingParticipants;
                        if (!quotes.receiving_company_options || !quotes.receiving_company_options.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        // scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        quotes.receiving_company_options = quotes.receivingParticipants.concat(scanCustData.data);
                    }
                    this.setState({ isLoadingReceivingAgent: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
        }

    }

    clickClearPopulate = async (typePopulate) => {
        const { quotes } = this.props;
        var { errors } = this.state;
        if (typePopulate === 'shipping') {
            quotes.shippingIATA1 = '';
            quotes.shippingIATA2 = '';
            quotes.shipping_company = null;
            quotes.shippingCompanyName = '';
            errors.shippingCompanyName = '';
            errors['shippingIATA'] = "";
            errors['shippingIATA1'] = "";
            errors['shippingIATA2'] = "";
        } else {
            quotes.receivingIATA1 = '';
            quotes.receivingIATA2 = '';
            quotes.receiving_company = null;
            quotes.receivingCompanyName = '';
            errors.receivingCompanyName = '';
            errors['receivingIATA'] = "";
            errors['receivingIATA1'] = "";
            errors['receivingIATA2'] = "";
        }
        await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
    }

    render() {
        var { steps,
            errors,
            isError,
            errorMessage,
            modalDenideSuccessIsOpen,
            successMessage,
            modalError,
            errorText } = this.state;
        var { quotes, auth } = this.props;
        // console.log(quotes);
        // showDelivery = true;
        // showPickup = true;
        // console.log(quotes);
        // console.log(selectedCountryPickup);
        // console.log('airline_option', quotes.airline_options);
        // console.log('airline', quotes.airline);
        // showDelivery = true;
        // console.log(quotes);
        console.log(errors);
        return (
            <div className="ebooking participants review-order quote-preview">
                <StepProgress stepData={steps} progress={70} onClick={this.handClickStep} />
                <div className="container">
                    <div className={`form-group ${(errorText != "" && errorText != "showContactLink") ? 'mt-3' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            {errorText}
                        </div>
                    </div>

                    <div className={`form-group mt-3 ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                        </div>
                    </div>

                    <div className={`form-group mt-3 ${errorText == "showContactLink" ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                        </div>
                    </div>
                </div>


                <div className='modal-content booking-modals bg-white'>
                    <div className="container block-form text-center">
                        <div className='modal-title'>
                            <h5 className='text-uppercase'>Before You Move On</h5>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div
                                    className='popup-description'
                                    style={{ maxWidth: '280px' }}
                                >
                                    Please fill in the details of the parties involved with this shipment.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row text-center mt-3'>
                        <div className="col-12">
                            <span className='gsa-custname'>GSA: {this.props.auth.custdes}</span>
                        </div>
                        <div className='col-12'>
                            <div className='d-inline-block'>
                                <div className="form-check pl-2 pl-md-0">
                                    <label className={`checkbox-container`}>
                                        Paying customer
                                        <input type="checkbox" name="payingCustomer" value="payingCustomer" checked={quotes.payingCustomer} onChange={this.handleChange} />
                                        <span className="checkmark-checkbox "></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-inline-block">
                                <div className="form-group mb-0 form-radio form-rows">
                                    <label className="radio-container">I am a shiping agent
                                        <input type="radio" name="selectedShippingReceiving" value="Shipping" checked={quotes.selectedShippingReceiving === 'Shipping' ? true : false} onClick={this.handleChange} />
                                        <span className={`checkmark`}></span>
                                    </label>
                                    <label className="radio-container">I am a freight forwarder
                                        <input type="radio" name="selectedShippingReceiving" value="Receiving" checked={quotes.selectedShippingReceiving === 'Receiving' ? true : false} onClick={this.handleChange} />
                                        <span className={`checkmark`}></span>
                                    </label>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className="container no_padding block-form">
                            <div className="form-content no_padding">
                                <div className="row border-between mt-3">
                                    <div className="col-lg-6">
                                        <div className="container-child div_center">
                                            {/* Khong co bg xanh */}
                                            {/* Bắt đầu shipping agent */}
                                            <div className="h2-detail">Shipping Agent</div>
                                            <div className="block-form-content mt-1 div_center">
                                                <div className="form-group">
                                                    <div>
                                                        <div className="form-check pl-2 pl-md-0">
                                                            <label className={`checkbox-container`}>
                                                                Paying customer
                                                                <input type="checkbox" name="payingCustomerShipping" value="payingCustomerShipping" checked={quotes.payingCustomerShipping} onChange={this.handleChange} />
                                                                <span className="checkmark-checkbox "></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="form-group form-rows col-12 col-md-8">
                                                            <label htmlFor="shippingCompanyName">Company Name <a style={{ paddingLeft: '15px' }} className={` ${(quotes.shipping_company && quotes.selectedShippingReceiving === 'Receiving') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "shipping")}>Clear</a></label>

                                                            <Select
                                                                inputId="shippingCompanyName"
                                                                noOptionsMessage={this.showNoOptionCustomer}
                                                                placeholder={``}
                                                                className={`select-box huge-input ${(errors.shippingCompanyName) ? 'input-error' : ''}`}
                                                                value={quotes.shipping_company}
                                                                isSearchable={true}
                                                                isDisabled={(quotes.selectedShippingReceiving === 'Shipping' || quotes.shippingIATA1 || quotes.shippingIATA2) ? true : false}
                                                                options={quotes.shipping_company_options}
                                                                isLoading={this.state.isLoadingShipingAgent}
                                                                onChange={this.handleChangeShipping}
                                                                // onBlur={this.handleOnBlurSelect}
                                                                // onFocus={this.handleOnFocusSelect}
                                                                inputValue={this.state.inputValueShippingAgent}
                                                                onInputChange={this.handlerSelectShippingTyping}
                                                            // filterOption={this.filterOption}
                                                            />
                                                            {/* <input type="text" className={`form-control ${(errors.shippingCompanyName) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && quotes.selectedShippingReceiving !== "Shipping") || quotes.populateImA) ? true : false} id="shippingCompanyName" name="shippingCompanyName" value={quotes.shippingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                            <InputError error={errors.shippingCompanyName} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12 col-md-8">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <label htmlFor="shippingIATA1">IATA Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8 slash-after color-blue">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA1 || errors.shippingIATA) ? 'input-error' : ''}`} readOnly={(quotes.selectedShippingReceiving === 'Shipping' || quotes.shipping_company) ? true : false} size="7" maxLength="7" id="shippingIATA1" name="shippingIATA1" onBlur={this.handleOnBlur} value={quotes.shippingIATA1} onChange={this.handleChange} />
                                                                    <EbookingInputError error={errors.shippingIATA1} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA2 || errors.shippingIATA) ? 'input-error' : ''}`} readOnly={(quotes.selectedShippingReceiving === 'Shipping' || quotes.shipping_company) ? true : false} size="4" maxLength="4" id="shippingIATA2" name="shippingIATA2" onBlur={this.handleOnBlur} value={quotes.shippingIATA2} onChange={this.handleChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Kết thúc shipping agent */}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 last-column">
                                        <div className="container-child div_center">
                                            {/* Khong co bg xanh */}
                                            {/* Bắt đầu shipping agent */}
                                            <div className={`h2-detail`}>Freight Forwarder</div>
                                            <div className="block-form-content mt-1 div_center">
                                                <div className={`form-group`}>
                                                    <div>
                                                        <div className="form-check pl-2 pl-md-0">
                                                            <label className={`checkbox-container`}>
                                                                Paying customer
                                                                <input type="checkbox" name="payingCustomerFreight" value="payingCustomerFreight" checked={quotes.payingCustomerFreight} onChange={this.handleChange} />
                                                                <span className="checkmark-checkbox "></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="form-group form-rows col-12 col-md-8">
                                                            <label htmlFor="receivingCompanyName">Company Name<span className={`red d-none rm-dnone`}>*</span><a style={{ paddingLeft: '15px' }} className={` ${(quotes.receiving_company && quotes.selectedShippingReceiving === 'Shipping') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "receiving")}>Clear</a></label>
                                                            {/* <input type="text" className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''} ${editFreightForwarder ? "d-none" : ""}`} readOnly={true} id="receivingCompanyName" name="receivingCompanyName" value={quotes.receivingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                            <Select
                                                                inputId="receivingCompanyName"
                                                                noOptionsMessage={this.showNoOptionCustomer}
                                                                placeholder={``}
                                                                className={`select-box huge-input ${(errors.receivingCompanyName) ? 'input-error' : ''}`}
                                                                value={quotes.receiving_company}
                                                                isSearchable={true}
                                                                isDisabled={(quotes.selectedShippingReceiving === 'Receiving' || quotes.receivingIATA1 || quotes.receivingIATA2) ? true : false}
                                                                options={quotes.receiving_company_options}
                                                                isLoading={this.state.isLoadingReceivingAgent}
                                                                onChange={this.handleChangeReceiving}
                                                                inputValue={this.state.inputValueReceivingAgent}
                                                                onInputChange={this.handlerSelectReceivingTyping}
                                                            />
                                                            <InputError error={errors.receivingCompanyName} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12 col-md-8">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <label htmlFor="receivingIATA1">IATA Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8 slash-after">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA1 || errors.receivingIATA) ? 'input-error' : ''}`} readOnly={(quotes.selectedShippingReceiving === 'Receiving' || quotes.receiving_company) ? true : false} size="7" maxLength="7" id="receivingIATA1" name="receivingIATA1" value={quotes.receivingIATA1} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                    <EbookingInputError error={errors.receivingIATA1} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA2 || errors.receivingIATA) ? 'input-error' : ''}`} readOnly={(quotes.selectedShippingReceiving === 'Receiving' || quotes.receiving_company) ? true : false} size="4" maxLength="4" id="receivingIATA2" name="receivingIATA2" value={quotes.receivingIATA2} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Kết thúc shipping agent */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content mt-5">
                                    <div className={`form-rows`}>
                                        <div className={`row border-between right-0`}>
                                            <div className={`col-6`}>
                                                <button type="button" onClick={this.handBack} className="btn btn-back right">Back to Choose Flight</button>
                                            </div>
                                            <div className={`col-6 pl-0`}>
                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        <button type="button" onClick={this.handSubmit} className="btn btn-ebooking-orange">Continue to Booking</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Modal
                    isOpen={modalDenideSuccessIsOpen}
                    onRequestClose={this.closeDenideSuccessModal}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalShowMessage
                        onShow={modalDenideSuccessIsOpen}
                        closeModal={this.closeDenideSuccessModal}
                        message={successMessage}
                        error={modalError}
                        onHide={this.closeDenideSuccessModal}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesParticipantsGSA);