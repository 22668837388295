import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';

/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class AcceptQuotesStep2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            showSuccess: false,
            errors: {},
            showError: false,
            acceptStatus: ['Cust Negotiation', 'Sent to Cust', 'User Negotiation', 'Decline by Cust', MESSAGES.STATUS_CUST_NEGOTIATION, MESSAGES.STATUS_DECLINE_BY_CUST, MESSAGES.STATUS_SEND_TO_CUST, MESSAGES.STATUS_USER_NEGOTIATION],
            showCase: 0,
            secretKey: '',
            quoteID: '',
            webCode: '',
            loginData: null
        };

    }

    componentDidMount = async () => {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var { showError, showSuccess, acceptStatus, showCase, secretKey, quoteID, webCode, loginData } = this.state;
        var { auth } = this.props;
        var startDate = moment();
        startDate.subtract(2, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        secretKey = this.props.match.params.secretKey;
        quoteID = this.props.match.params.quoteID;
        webCode = this.props.match.params.webCode;
        this.props.loadingActions.loadingChangeData(true);
        let getCProf = await this.props.quotesActions.getCprof({ fromdate: startDate.format('DD/MM/YY'), todate: toDate.format('DD/MM/YY'), CPROF: quoteID, SIM: 'Y', userb: 0 });
        this.props.loadingActions.loadingChangeData(true);
        let getProposal = await this.props.quotesActions.getDynamoProposal(secretKey);
        this.props.loadingActions.loadingChangeData(true);
        let phone = '';
        if (getProposal.success && getProposal.data.Success && getProposal.data.data.length) {
            phone = getProposal.data.data[0].phone;
        }
        let getLoginData = null;
        let websiteuser = '';
        let userStatus = '';
        let custname = '';
        if (phone) {
            this.props.loadingActions.loadingChangeData(true);
            getLoginData = await this.props.quotesActions.getLoginData({ phone });
            if (getLoginData.success && getLoginData.data.Success) {
                websiteuser = getLoginData.data.websiteuser;
                userStatus = getLoginData.data.status;
                custname = getLoginData.data.custname;
                loginData = getLoginData.data;
            }

            let firstCustNameCharacter = custname.substring(0, 1);
            if (websiteuser === "Y" && userStatus === "Active" && firstCustNameCharacter !== 'T') {
                showCase = 1;
            } else if (websiteuser !== "Y") {
                showCase = 2;
            } else if (userStatus === "Potential" && firstCustNameCharacter === 'T') {
                showCase = 3;
            }
        }

        let quotesData = null;
        let quotesStatus = '';
        if (getCProf.success && getCProf.data.Success && getCProf.data.cp.length) {
            for (let cpi = 0; cpi < getCProf.data.cp.length; cpi++) {
                const cp = getCProf.data.cp[cpi];
                if (cp.CProfAU + "" === quoteID) {
                    quotesData = getCProf.data.cp[cpi];
                    quotesStatus = quotesData.sitestat;
                }
            }
        }
        console.log(quotesData);
        let checkStatus = acceptStatus.find((s) => s === quotesStatus);
        if (!quotesData || !custname || !phone || (getProposal.success && getProposal.data.exprires) || !checkStatus) {
            showError = true;
        }
        this.setState({ showError, showCase, loginData, secretKey, quoteID, webCode });
        this.props.loadingActions.loadingChangeData(false);

        if (showCase == 1) {
            let quoteData = JSON.parse(getProposal.data.data[0].quoteData);
            let currentDate = moment();
            let expiseDate = moment(getProposal.data.data[0].expriseDate, 'YYYYMMDDHHmmss');
            let statusData = {
                "cporf": parseInt(quoteID),
                "status": MESSAGES.STATUS_APPROVED_BY_CUST,
                "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
                "statusres": "",
                "userb": parseInt(getProposal.data.data[0].phone),
                "dept": "IMP",
                "expirydate": expiseDate.format('DD/MM/YY')
            };
            this.props.loadingActions.loadingChangeData(true);
            let saveQuotes = await this.props.quotesActions.updateQuoteStatus(statusData, this.props.auth.jwtToken);
            if (saveQuotes.success && saveQuotes.data.Success) {
                this.setState({ showSuccess: true });
            }
            this.props.loadingActions.loadingChangeData(false);
        }
        this.props.loadingActions.loadingChangeData(false);
    }


    render() {
        var { showError, showSuccess } = this.state;
        return (
            <div className="page-main">
                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>
                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className={`content-text-desc div_center text-center mb-3 ${showError ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                <span className="alert alert-danger" style={{ backgroundColor: 'red', color: '#fff' }} role="alert">
                                                    Unfortunately this quote has expired or doesn’t exist
                                                </span>
                                            </div>
                                            <div className={`big-text ${showSuccess ? '' : 'd-none'}`}>Well Done</div>
                                            <div className={`content-text-desc div_center mt-3 ${showSuccess ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                Text description
                                            </div>
                                            <div className="content-buttons div_center mt-5">
                                                <Link to={`/dashboard/quotes`} className={`btn btn-orange mb-3 ${(showError || showSuccess) ? '' : 'd-none'}`}>Back to Dashboard</Link>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        quotesActions: bindActionCreators(quotesActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptQuotesStep2);