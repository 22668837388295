import React from 'react';
import { Link } from 'react-router-dom';

class Slider extends React.Component {
	render() {
		return (
			<div id="carouselExampleControls" className="carousel slide" data-ride="carousel">           
	            <div className="carousel-inner" role="listbox">
	                <div className="carousel-item active">
	                    <div className="item-child" style={{backgroundImage: 'url(/images/0000194_ground-handling.png)'}} data-color="lightblue" alt="First Image"></div>
	                </div>
	                <div className="carousel-item">
	                    <div className="item-child" style={{backgroundImage: 'url(/images/0000395_liege-hub.png)'}} data-color="firebrick" alt="Second Image"></div>
	                </div>
	            </div>
	        </div>
		)
	}
}

export default Slider;
