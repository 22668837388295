import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import clone from 'clone';
/**
 * Validator
 */
import { bindActionCreators } from 'redux';
import Validator from '../../libs/Validator';
import InputError from '../../components/InputError';
import * as MESSAGES from '../../libs/Messages';

class ModalDashboardContact extends React.Component {
  constructor(props) {
    super(props);
    const { itmChat, mailData } = this.props;
    let currentChat = clone(itmChat);
    const emailData = clone(mailData);
    this.state = {
      errors: {},
      currentChat,
      subject: '',
      message: '',
      mailTitle: '',
      mailContent: '',
      emailData,
      listEmail: [],
      secretData: null
    };
  }

  componentDidMount = async () => {
    const { emailData, errors } = this.state;
    const { history, auth, isBooking, isQuote } = this.props;
    this.props.loadingActions.loadingChangeData(true);
    // console.log("emailData", emailData);
    let subject;
    const {
      awb,
      FromZipCode,
      ToZipCode,
      FromCountryCode,
      ToCountryCode,
      FromPortCode,
      ToPortCode,
      PhoneCompany,
    } = emailData;
    let fromText = '';
    let toText = '';
    if (FromZipCode.trim()) {
      fromText = `${FromZipCode} (${FromCountryCode})`;
    } else {
      fromText = `${FromPortCode} (${FromCountryCode})`;
    }
    if (ToZipCode.trim()) {
      toText = `${ToZipCode} (${ToCountryCode})`;
    } else {
      toText = `${ToPortCode} (${ToCountryCode})`;
    }

    if (isBooking) {
      subject = `${awb || ''} ${fromText}-${toText} ${PhoneCompany}`;
    } else {
      subject = `${fromText} / ${toText}`;
    }
    this.setState({ subject });

    let orderID = emailData.order;
    if (!orderID) {
      let getBidAWB = await this.props.ebookingActions.getBidAWB(
        emailData.awb,
        auth.jwtToken
      );
      if (getBidAWB.success && getBidAWB.data.Success) {
        orderID = getBidAWB.data.ORD;
      }
    }

    const emailRes = await this.props.quotesActions.getGroupEmail(orderID);
    if (emailRes && emailRes.success && emailRes.data.Success) {
      this.setState({ listEmail: emailRes.data.email });
    }

    if (emailData.rowData && emailData.rowData.pq && emailData.rowData.pq.CProfAU) {
      let secretKeyData = await this.getSecretKey(emailData.rowData.pq);
      if (secretKeyData.success && secretKeyData.secretKey) {
        this.setState({ secretData: { secretKey: secretKeyData.secretKey, quoteID: secretKeyData.quoteID } });
      }
    }


    this.props.loadingActions.loadingChangeData(false);
  };

  handleChange = async (event) => {
    const { currentChat, errors } = this.state;
    var {
      target,
      target: { name, type },
    } = event;
    var value = type === 'checkbox' ? target.checked : target.value;
    // value = value.replace(/[^0-9.]/g, "");
    // var strArray = name.split("_");
    // let key = strArray[1];
    // if (currentChat.ChatData.OfferData[key].price !== value) {
    //     currentChat.ChatData.OfferData[key].isUpdated = true;
    // }
    // currentChat.ChatData.OfferData[key].price = value;
    // if (value === '' || parseFloat(value) <= 0) {
    //     errors[name] = 'Invalid';
    // } else {
    //     errors[name] = '';
    // }
    // this.setState({ currentChat, errors });
  };

  getSecretKey = async (row) => {
    let secretKey = '';
    const quoteID = row.CProfAU;
    const propalsal = await this.props.quotesActions.getProposal(quoteID, this.props.auth.jwtToken);
    if (propalsal.success) {
      secretKey = propalsal.data.secretKey;
    }
    // console.log(secretKey, quoteID, row);
    return { success: true, secretKey, quoteID };
  }

  handleTextChange = (event) => {
    var {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
  };

  handleSend = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.isValidation()) {
      return false;
    }

    const { subject, message, listEmail, emailData } = this.state;
    if (listEmail.length === 0) {
      this.props.setMsg({
        isError: true,
        errorMessage: MESSAGES.ERROR_SEND_EMAIL_EMPTY_LIST_EMAIL,
      });
      this.props.closeModal();
      return false;
    }

    const {
      PhoneCompany,
      PhoneName,
      PhoneEmail,
    } = emailData;

    const params = {
      data: {
        titleText: subject,
        PhoneName,
        PhoneCompany,
        PhoneEmail,
        message,
        quoteID: this.state.secretData.quoteID
      },
      template: 'QUOTE_SEND_US_EMAIL',
      receivers: listEmail,
      cc: [],
    };

    this.props.loadingActions.loadingChangeData(true);
    const result = await this.props.quotesActions.sendEmail(params);
    this.props.loadingActions.loadingChangeData(false);

    if (result.success) {
      this.props.setMsg({
        isError: false,
        errorMessage: MESSAGES.SUCCESS_SEND_EMAIL_US,
      });
    } else {
      this.props.setMsg({
        isError: true,
        errorMessage: MESSAGES.ERROR_SEND_EMAIL_US,
      });
    }
    this.props.closeModal();
  };

  handleOnBlur = async (event) => {
    var { errors } = this.state;
    var {
      target,
      target: { name, type },
    } = event;
    var value = type === 'checkbox' ? target.checked : target.value;
    this.setState({ errors });
  };

  isValidation() {
    this.setState({ errors: {} });
    var errors = {};
    var isError = false;

    const { subject, message } = this.state;
    var validator = new Validator();

    if (!validator.required(subject)) {
      errors['subject'] = MESSAGES.ERROR_REQUIRED;
      isError = true;
    }
    if (!validator.required(message)) {
      errors['message'] = MESSAGES.ERROR_REQUIRED;
      isError = true;
    }

    if (isError) {
      this.setState({ errors });
      return false;
    }
    return true;
  }

  handleLinkToChat = async () => {
    const { emailData: { rowData } } = this.state;
    let { quotes } = this.props;
    quotes.shipmentData = rowData;
    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });    
    this.props.history.push(`/quotes/negotiation/${this.state.secretData.secretKey}/${this.state.secretData.quoteID}?p=shipment`);
  }

  render() {
    const { onShow, description, itmChat } = this.props;
    const { currentChat, subject, message, errors } = this.state;
    if (!onShow) return null;

    return (
      <div
        className='modal-dialog modal-dialog-medium modal-dialog-centered'
        role='document'
      >
        <div className='modal-content booking-modals shadow'>
          <div className='modal-body'>
            <div className='close-button' onClick={this.props.closeModal}></div>
            <div className='modal-title'>
              <h5 className='text-uppercase'>Send Us A Message</h5>
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <div
                  className='popup-description'
                  style={{ maxWidth: '330px' }}
                >
                  This message will go straight to your contact in CAL. They
                  will get back to you as soon as possible.
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-12 ml-4 mr-4'>
                <label className='form-label' htmlFor='txtSubject'>
                  Subject
                </label>
              </div>
              <div className='col-12'>
                <div className='pl-4 pr-4'>
                  <input
                    type='text'
                    id='txtSubject'
                    name='subject'
                    value={subject}
                    readOnly={true}
                    // onChange={this.handleTextChange}
                    maxLength={255}
                    className='form-control shadow'
                  ></input>
                  <InputError error={errors.subject} />
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-12 ml-4 mr-4'>
                <label className='form-label' htmlFor='txtMessage'>
                  Your Message
                </label>
              </div>
              <div className='col-12'>
                <div className='pl-4 pr-4'>
                  <textarea
                    className='form-control shadow'
                    id='txtMessage'
                    style={{
                      width: '100%',
                      height: '100px',
                      padding: '5px 10px',
                    }}
                    name='message'
                    value={message}
                    onChange={this.handleTextChange}
                    placeholder='What else do we need to know?'
                  ></textarea>
                  <InputError error={errors.message} />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer no-border'>
            <div className='w-100'>
              <div className='row'>
                <div className='col-12 text-center'>
                  <button
                    type='submit'
                    className='btn btn-orange'
                    onClick={this.handleSend}
                  >
                    SEND
                  </button>
                </div>
                <div className='col-12 text-center'>
                  <button
                    type='button'
                    className='btn btn-link'
                    onClick={this.props.closeModal}
                  >
                    Cancel
                  </button>
                </div>

                {
                  this.state.secretData ? (<div className='col-12 text-center'>
                    <span className="text-white">Or chat with </span>
                    <a
                      href="#"
                      onClick={this.handleLinkToChat}
                    >
                      Cal Agent
                    </a>
                  </div>) : null
                }
              </div>
            </div>
          </div>
          {/* <div className="modal-footer no-border">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6"><span className="btn-clear cursor-pointer text-white" onClick={this.props.closeModal}>Cancel</span></div>
                                <div className="col-6 text-right"><span className="btn-done" onClick={this.handleCounter}>Create</span></div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingAction, dispatch),
    quotesActions: bindActionCreators(quotesActions, dispatch),
    ebookingActions: bindActionCreators(ebookingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDashboardContact);
