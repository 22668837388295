import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

class ModalEmailConfirmForm extends React.Component {
    constructor(props) {
        super(props);
        this.onShowModal = this.onShowModal.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        this.confirmForm.resetFrom();
    };

    render() {
        return (
            <div className="modal-groups ">

                <div className="modal" id="modalEmailConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalEmailConfirm')}></button>
                            </div>
                            <EmailConfirmForm onRef={ref => (this.confirmForm = ref)} loadingChangeData={this.props.loadingChangeData} history={this.props.history} showModal={this.props.showModal} hideModal={this.props.closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

const ModalEmailConfirm = connect(mapStateToProps, claimActions)(ModalEmailConfirmForm);

export default ModalEmailConfirm;


class EmailConfirmForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: props.error,
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'code': '',
            },
            btnConfirmText: 'Submit',
            btnResendText: "Get New Code"
        };

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handlerConfirm = this.handlerConfirm.bind(this);
        this.resetFrom = this.resetFrom.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    resetFrom = () => {
        this.setState({
            error: '',
            errorMessage: '',
            successMessage: '',
            successResendMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'code': '',
            },
            btnConfirmText: 'Submit'
        });
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { loginForm } = this.state;
        const { target, target: { name, type } } = event;
        loginForm[name] = target.value;
        this.setState({ loginForm });
    }

    validate() {
        this.setState({ errors: {} });
        const { loginForm } = this.state;
        var errors = {};
        var isError = false;
        var validator = new Validator();
        if (!validator.required(loginForm.code)) {
            errors['code'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }
    handlerConfirm = () => {
        const { history } = this.props;
        if (this.validate()) {
            this.setState({ successResendMessage: '', successMessage: '', errorMessage: '' });
            // this.props.loadingChangeData(true);
            // this.props.onSubmit(this.state.loginForm.code)
            //     .then((user) => {
            //         this.props.loadingChangeData(false);
            //         this.props.hideModal('modalEmailConfirm');
            //         this.setState({
            //             successMessage: 'success',
            //             errorMessage: ''
            //         });
            //     })
            //     .catch((error) => {
            //         this.props.loadingChangeData(false);
            //         this.setState({
            //             successMessage: '',
            //             errorMessage: error
            //         });
            //     });
        }
    }
    render() {
        const { breadcrumbData, isErrorLogin, errorLogins, loginForm } = this.state;

        return (
            <div>
                <div className="modal-content">
                    <div className="modal-body">
                        <h2>Verification Account (Email)</h2>
                        <hr />
                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                            {this.state.errorMessage}
                        </div>
                        <form className={`${(this.state.successMessage) ? 'hide' : ''}`}>
                            <div className="form-group small-margin">
                                <label htmlFor="code">Verification Code*</label>
                                <input type="text" className={`form-control ${(errorLogins.code) ? 'input-error' : ''}`} name="code" placeholder="Enter verification code" value={loginForm.code} onChange={this.handleChange} />
                            </div>
                            <div className="form-group small-margin">
                                <button type="button" onClick={this.handlerConfirm} className="btn btn-continues btn-forgot">{this.state.btnConfirmText}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

EmailConfirmForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.string,
};
