import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class ScFailure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        // console.log(parsedParams);
    }

    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, costDatas, cost2, total }
            = this.state;

        return (
            <div className="container ebooking">
                <div className="block-form">
                    <form>
                        <div className={`form-group`}>
                            <div className="alert alert-danger" role="alert">
                                Get Payment Form Unsuccess!
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScFailure);