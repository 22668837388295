import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import CurrencySelect from '../CurrencySelect';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';

class ClaimDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isError: false,
			errorMessage: null,
			isEdit: false,
			errors: {},
			claim: {
			},
			breadcrumbs: [
				{
					'link': '',
					'active': true,
					'name': 'New Claim'
				},
			],
			steps: [
				{
					'link': 'new-claim',
					'active': false,
					'validate': false,
					'name': 'Basic Info'
				},
				{
					'link': 'claim-detail',
					'active': true,
					'validate': true,
					'name': 'Claim Details'
				},
				{
					'link': 'claim-submit',
					'active': false,
					'validate': true,
					'name': 'Submit Documents'
				},
			]
		};

		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleClearDate = this.handleClearDate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handClickStep = this.handClickStep.bind(this);
		this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
	}

	handleClearDate = () => {
		const { claim } = this.state;
		claim.flight_date = '';
		this.setState({ claim });
	}

	handleChangeDate = (v) => {
		const { claim } = this.state;
		claim.flight_date = v.format('YYYY/MM/DD');
		this.setState({ claim });
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillMount() {
		//get redux data and set to state
		var { claim } = this.props;

		for (let i = 0; i < claim.ClaimAmount.length; i++) {
			switch (claim.ClaimAmount[i].amountcodename) {
				case 'Claim Amount':
					claim.ClaimAmount[i].amountcodename = 'claim_amount';
					break;
				case 'Invoice Value':
					claim.ClaimAmount[i].amountcodename = 'invoice_value';
					break;
				case 'Cost of Repair':
					claim.ClaimAmount[i].amountcodename = 'cost_of_repair';
					break;
				case 'Cost of Salvage':
					claim.ClaimAmount[i].amountcodename = 'cost_of_salvage';
					break;
			}
		}


		this.setState({ claim });

		const { history } = this.props;

		//check previuos step ok
		if (!claim.step_1_ok) {
			history.push('/claim');
		}

		document.title = "Claims | Challenge Group"
	}

	/**
	 * Handler click step
	 */
	handClickStep = (e) => {
		if (!this.isValidation()) {
			e.stopPropagation();
			e.preventDefault();
			return false;
		}
	}

	handleCurrencyChange = (name, value) => {
		var { claim } = this.state;
		var change_name = name;
		switch (name) {
			case 'claim_amount_currency':
				for (let i = 0; i < claim.ClaimAmount.length; i++) {
					if (claim.ClaimAmount[i].amountcodename == 'claim_amount') {
						claim.ClaimAmount[i].currcode = value ? value : null;
					}
				}
				change_name = 'ClaimAmount';
				this.isValidationWithField(name);
				break;
			case 'invoice_value_currency':
				for (let i = 0; i < claim.ClaimAmount.length; i++) {
					if (claim.ClaimAmount[i].amountcodename == 'invoice_value') {
						claim.ClaimAmount[i].currcode = value ? value : null;
					}
				}
				change_name = 'ClaimAmount';
				this.isValidationWithField(name);
				break;
			case 'cost_of_repair_currency':
				for (let i = 0; i < claim.ClaimAmount.length; i++) {
					if (claim.ClaimAmount[i].amountcodename == 'cost_of_repair') {
						claim.ClaimAmount[i].currcode = value ? value : null;
					}
				}
				change_name = 'ClaimAmount';
				this.isValidationWithField(name);
				break;
			case 'cost_of_salvage_currency':
				for (let i = 0; i < claim.ClaimAmount.length; i++) {
					if (claim.ClaimAmount[i].amountcodename == 'cost_of_salvage') {
						claim.ClaimAmount[i].currcode = value ? value : null;
					}
				}
				change_name = 'ClaimAmount';
				this.isValidationWithField(name);
				break;
		}
		this.props.claimChangeData({ fieldName: change_name, fieldValue: claim[change_name] }).then(responseData => { });
		this.setState({ claim });
	}

	//handler on blur some fields
	handleOnBlur = (event) => {
		var { errors } = this.state;
		var validator = new Validator();
		var isError = false;
		const { target, target: { name, type } } = event;
		var value = target.value;
		switch (name) {
			case 'damage_piece':
			case 'missing_piece':
			case 'partial_loss_piece':
			case 'pilferage_piece':
				if (!validator.required(value) || !validator.number(value) || parseFloat(value) <=0) {
					errors[name] = MESSAGES.ERROR_REQUIRED_NUMBER;
					isError = true;
				}

				if (!isError) {
					errors[name] = '';
				}
				break;
			case 'damage_kg':
			case 'missing_kg':
			case 'partial_loss_kg':
			case 'pilferage_kg':
				if (!validator.required(value) || !validator.weight(value) || parseFloat(value) <=0) {
					errors[name] = MESSAGES.ERROR_REQUIRED_NUMBER;
					isError = true;
				}

				if (!isError) {
					errors[name] = '';
				}
				break;
		}
		this.setState({ errors });
	}

	/* Handle subbmit form */
	handleChange(event) {
		var { claim, errors } = this.state;
		const { target, target: { name, type } } = event;
		var value = target.value;
		var change_name = name;
		switch (target.name) {
			case 'damage':
				var check_checked = target.checked;
				if (!check_checked) {
					var new_espectes = claim.ClaimAspect.filter((el) => el.aspectcode != 'Damage');
					claim.ClaimAspect = new_espectes;
				} else {
					claim.ClaimAspect.push({ aspectcode: 'Damage', quant: null, weight: null });
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, check_checked);
				break;
			case 'missing':
				var check_checked = target.checked;
				if (!check_checked) {
					var new_espectes = claim.ClaimAspect.filter((el) => el.aspectcode != 'Missing');
					claim.ClaimAspect = new_espectes;
				} else {
					claim.ClaimAspect.push({ aspectcode: 'Missing', quant: null, weight: null });
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, check_checked);
				break;
			case 'partial_loss':
				var check_checked = target.checked;
				if (!check_checked) {
					var new_espectes = claim.ClaimAspect.filter((el) => el.aspectcode != 'Partial Loss');
					claim.ClaimAspect = new_espectes;
				} else {
					claim.ClaimAspect.push({ aspectcode: 'Partial Loss', quant: null, weight: null });
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, check_checked);
				break;
			case 'pilferage':
				var check_checked = target.checked;
				if (!check_checked) {
					var new_espectes = claim.ClaimAspect.filter((el) => el.aspectcode != 'Pilferage');
					claim.ClaimAspect = new_espectes;
				} else {
					claim.ClaimAspect.push({ aspectcode: 'Pilferage', quant: null, weight: null });
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, check_checked);
				break;
			case 'damage_piece':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Damage') {
						claim.ClaimAspect[i].quant = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'missing_piece':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Missing') {
						claim.ClaimAspect[i].quant = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'partial_loss_piece':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Partial Loss') {
						claim.ClaimAspect[i].quant = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name.value);
				break;
			case 'pilferage_piece':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Pilferage') {
						claim.ClaimAspect[i].quant = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;

			case 'damage_kg':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Damage') {
						claim.ClaimAspect[i].weight = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'missing_kg':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Missing') {
						claim.ClaimAspect[i].weight = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'partial_loss_kg':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Partial Loss') {
						claim.ClaimAspect[i].weight = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'pilferage_kg':
				for (let i = 0; i < claim.ClaimAspect.length; i++) {
					if (claim.ClaimAspect[i].aspectcode == 'Pilferage') {
						claim.ClaimAspect[i].weight = value ? value : null;
					}
				}
				change_name = 'ClaimAspect';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;

			case 'claim_amount':
				if (value == "") {
					var new_amount = claim.ClaimAmount.filter((el) => el.amountcodename != 'claim_amount');
					claim.ClaimAmount = new_amount;
				} else {
					var found_exist = false;
					for (let i = 0; i < claim.ClaimAmount.length; i++) {
						if (claim.ClaimAmount[i].amountcodename == 'claim_amount') {
							found_exist = true;
							claim.ClaimAmount[i].amount = value ? value : null;
						}
					}
					if (!found_exist) {
						claim.ClaimAmount.push({ amountcodename: 'claim_amount', amount: value, currcode: this.claim_amount_currency.state.current_value });
					}
				}
				change_name = 'ClaimAmount';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'invoice_value':
				if (value == "") {
					var new_amount = claim.ClaimAmount.filter((el) => el.amountcodename != 'invoice_value');
					claim.ClaimAmount = new_amount;
				} else {
					var found_exist = false;
					for (let i = 0; i < claim.ClaimAmount.length; i++) {
						if (claim.ClaimAmount[i].amountcodename == 'invoice_value') {
							found_exist = true;
							claim.ClaimAmount[i].amount = value ? value : null;
						}
					}
					if (!found_exist) {
						claim.ClaimAmount.push({ amountcodename: 'invoice_value', amount: value, currcode: this.invoice_value_currency.state.current_value });
					}
				}
				change_name = 'ClaimAmount';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'cost_of_repair':
				if (value == "") {
					var new_amount = claim.ClaimAmount.filter((el) => el.amountcodename != 'cost_of_repair');
					claim.ClaimAmount = new_amount;
				} else {
					var found_exist = false;
					for (let i = 0; i < claim.ClaimAmount.length; i++) {
						if (claim.ClaimAmount[i].amountcodename == 'cost_of_repair') {
							found_exist = true;
							claim.ClaimAmount[i].amount = value ? value : null;
						}
					}
					if (!found_exist) {
						claim.ClaimAmount.push({ amountcodename: 'cost_of_repair', amount: value, currcode: this.cost_of_repair_currency.state.current_value });
					}
				}
				change_name = 'ClaimAmount';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;
			case 'cost_of_salvage':
				if (value == "") {
					var new_amount = claim.ClaimAmount.filter((el) => el.amountcodename != 'cost_of_salvage');
					claim.ClaimAmount = new_amount;
				} else {
					var found_exist = false;
					for (let i = 0; i < claim.ClaimAmount.length; i++) {
						if (claim.ClaimAmount[i].amountcodename == 'cost_of_salvage') {
							found_exist = true;
							claim.ClaimAmount[i].amount = value;
						}
					}
					if (!found_exist) {
						claim.ClaimAmount.push({ amountcodename: 'cost_of_salvage', amount: value, currcode: this.cost_of_salvage_currency.state.current_value });
					}
				}
				change_name = 'ClaimAmount';
				this.setState({ claim });
				this.isValidationWithField(name, value);
				break;

			case 'claimtype':
				var check_checked = target.checked;
				if (check_checked) {
					claim[name] = 'Form';
				} else {
					claim[name] = 'Prel';
				}
				errors = [];
				//this.isValidation();
				break;
			case 'Insured':
				var check_checked = target.checked;
				if (check_checked) {
					claim[name] = '';
				} else {
					claim[name] = 'Y';
				}
				break;

			default:
				claim[name] = value;
		}

		this.props.claimChangeData({ fieldName: change_name, fieldValue: claim[change_name] }).then(responseData => { });
		this.setState({ claim, errors });
		//Change Data
	}

	isValidationWithField(field_name, value) {
		const { claim, errors } = this.state;
		var validator = new Validator();
		var isError = false;
		switch (field_name) {
			case 'damage':
			case 'missing':
			case 'partial_loss':
			case 'pilferage':
				if (!claim.ClaimAspect.length) {
					errors['claim_aspects_empty'] = MESSAGES.ERROR_CLAIM_ASPECTS;
					isError = true;
				}

				if (!value) {
					errors[field_name + '_piece'] = '';
					errors[field_name + '_kg'] = '';
				}

				if (!isError) {
					errors['claim_aspects_empty'] = '';
				}
				this.setState({ errors });
				break;

			case 'damage_piece':
			case 'missing_piece':
			case 'partial_loss_piece':
			case 'pilferage_piece':
				if (!validator.required(value) || !validator.number(value) || parseFloat(value) <=0) {
					errors[field_name] = MESSAGES.ERROR_REQUIRED_NUMBER;
					isError = true;
				}

				if (!isError) {
					errors[field_name] = '';
				}
				this.setState({ errors });
				break;
			case 'damage_kg':
			case 'missing_kg':
			case 'partial_loss_kg':
			case 'pilferage_kg':
				if (!validator.required(value) || !validator.weight(value) || parseFloat(value) <=0) {
					errors[field_name] = MESSAGES.ERROR_REQUIRED_NUMBER;
					isError = true;
				}

				if (!isError) {
					errors[field_name] = '';
				}
				this.setState({ errors });
				break;

			case 'claim_amount':
			case 'invoice_value':
			case 'cost_of_repair':
			case 'cost_of_salvage':
				if (field_name == 'claim_amount') {
					if (!validator.required(value) || !validator.currency(value) || parseFloat(value) <=0) {
						errors['claim_amount'] = MESSAGES.ERROR_REQUIRED_NUMBER;
					} else {
						errors['claim_amount'] = '';
					}
				} else if (field_name == 'invoice_value') {
					if (!validator.required(value) || !validator.currency(value) || parseFloat(value) <=0) {
						errors['invoice_value'] = MESSAGES.ERROR_REQUIRED_NUMBER;
					} else {
						errors['invoice_value'] = '';
					}
				} else if (field_name == 'cost_of_repair') {
					if (!validator.required(value) || !validator.currency(value) || parseFloat(value) <=0) {
						errors['cost_of_repair'] = MESSAGES.ERROR_REQUIRED_NUMBER;
					} else {
						errors['cost_of_repair'] = '';
					}
				} else {
					if (!validator.required(value) || !validator.currency(value) || parseFloat(value) <=0) {
						errors['cost_of_salvage'] = MESSAGES.ERROR_REQUIRED_NUMBER;
					} else {
						errors['cost_of_salvage'] = '';
					}
				}
				this.setState({ errors });
				//this.isValidation();
				break;

			default:
		}

	}

	/* Validate form */
	isValidation() {
		this.setState({ errors: {}, errorMessage: '' });

		var errors = [];
		var isError = false;
		var validator = new Validator();
		const { claim } = this.state;

		//check Claim Aspects	
		for (let i = 0; i < claim.ClaimAspect.length; i++) {
			var field_name = 'damage';
			if (claim.ClaimAspect[i].aspectcode == 'Missing') {
				field_name = 'missing';
			} else if (claim.ClaimAspect[i].aspectcode == 'Partial Loss') {
				field_name = 'partial_loss';
			} else if (claim.ClaimAspect[i].aspectcode == 'Pilferage') {
				field_name = 'pilferage';
			}
			if (!validator.required(claim.ClaimAspect[i].quant) || !validator.number(claim.ClaimAspect[i].quant) || parseFloat(claim.ClaimAspect[i].quant) <=0) {
				errors[field_name + '_piece'] = MESSAGES.ERROR_REQUIRED_NUMBER;
				isError = true;
			}
			if (!validator.required(claim.ClaimAspect[i].weight) || !validator.weight(claim.ClaimAspect[i].weight) || parseFloat(claim.ClaimAspect[i].weight) <=0) {
				errors[field_name + '_kg'] = MESSAGES.ERROR_REQUIRED_NUMBER;
				isError = true;
			}

		}
		if (!claim.ClaimAspect.length) {
			errors['claim_aspects_empty'] = MESSAGES.ERROR_CLAIM_ASPECTS;
			isError = true;
		}
		//validate Claim Amount
		if (claim.claimtype == 'Form') {
			var found_claim_amount = false;
			var found_claim_other = false;
			for (let j = 0; j < claim.ClaimAmount.length; j++) {
				if (claim.ClaimAmount[j].amountcodename === 'claim_amount') {
					var check_feild_err = false;
					if (!validator.required(claim.ClaimAmount[j].amount) || !validator.currency(claim.ClaimAmount[j].amount)  || parseFloat(claim.ClaimAmount[j].amount) <=0) {
						errors[claim.ClaimAmount[j].amountcodename] = MESSAGES.ERROR_REQUIRED_NUMBER;
						isError = true;
						check_feild_err = true;
					}

					if (!validator.required(claim.ClaimAmount[j].currcode)) {
						errors[claim.ClaimAmount[j].amountcodename + '_currcode'] = MESSAGES.ERROR_REQUIRED_NUMBER;
						isError = true;
						check_feild_err = true;
					}

					if (!check_feild_err) {
						found_claim_amount = true;
					}
				} else {
					var check_feild_err = false;

					if (found_claim_other) {
						if (!validator.currency(claim.ClaimAmount[j].amount) || parseFloat(claim.ClaimAmount[j].amount) <=0) {
							errors[claim.ClaimAmount[j].amountcodename] = MESSAGES.ERROR_REQUIRED_NUMBER;
							isError = true;
							check_feild_err = true;
						}
					} else {
						if (!validator.required(claim.ClaimAmount[j].amount) || !validator.currency(claim.ClaimAmount[j].amount) || parseFloat(claim.ClaimAmount[j].amount) <=0) {
							errors[claim.ClaimAmount[j].amountcodename] = MESSAGES.ERROR_REQUIRED_NUMBER;
							isError = true;
							check_feild_err = true;
						}

						if (!validator.required(claim.ClaimAmount[j].currcode)) {
							errors[claim.ClaimAmount[j].amountcodename + '_currcode'] = MESSAGES.ERROR_REQUIRED_NUMBER;
							isError = true;
							check_feild_err = true;
						}
					}

					if (!check_feild_err) {
						found_claim_other = true;
					}
				}
			}
			if (!found_claim_amount) {
				isError = true;
				errors['claim_amout_required'] = MESSAGES.ERROR_REQUIRED_NUMBER;
				errors['claim_amount'] = MESSAGES.ERROR_REQUIRED_NUMBER;

			}
			if (!found_claim_other) {
				isError = true;
				errors['claim_amounts'] = MESSAGES.ERROR_CLAIM_AMOUNTS;
				errors['invoice_value'] = MESSAGES.ERROR_REQUIRED_NUMBER;
				errors['cost_of_repair'] = MESSAGES.ERROR_REQUIRED_NUMBER;
				errors['cost_of_salvage'] = MESSAGES.ERROR_REQUIRED_NUMBER;

			}
		} else {
			for (let j = 0; j < claim.ClaimAmount.length; j++) {				
				if (claim.ClaimAmount[j].amount && (!validator.currency(claim.ClaimAmount[j].amount) || parseFloat(claim.ClaimAmount[j].amount) <=0)) {
					errors[claim.ClaimAmount[j].amountcodename] = MESSAGES.ERROR_REQUIRED_NUMBER;
					isError = true;
				}
			}
		}

		if (isError) {
			this.setState({ errors });
			this.props.claimChangeData({ fieldName: "step_2_ok", fieldValue: false }).then(responseData => { });
			return false;
		}


		this.props.claimChangeData({ fieldName: "step_2_ok", fieldValue: true }).then(responseData => { });
		return true;
	}

	/* Handle subbmit form */
	handSubmit = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (!this.isValidation()) {
			window.scrollToFirstError();
			return false;
		}
		const { history } = this.props;
		history.push('/claim-submit');
	}

	getAspectData = (code) => {
		const { claim } = this.state;
		var data_return = {};
		for (let i = 0; i < claim.ClaimAspect.length; i++) {
			if (claim.ClaimAspect[i].aspectcode == code) {
				data_return = claim.ClaimAspect[i];
			}
		}
		return data_return;
	}

	getClaimAmount = (code) => {
		const { claim } = this.state;
		var data_return = {};
		for (let i = 0; i < claim.ClaimAmount.length; i++) {
			if (claim.ClaimAmount[i].amountcodename == code) {
				data_return = claim.ClaimAmount[i];
			}
		}
		return data_return;
	}

	hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

	render() {
		var { claim, breadcrumbs, steps, isError, errors, errorMessage }
			= this.state;
		var damage = this.getAspectData('Damage');
		var missing = this.getAspectData('Missing');
		var partial_loss = this.getAspectData('Partial Loss');
		var pilferage = this.getAspectData('Pilferage');
		var claim_amount = this.getClaimAmount('claim_amount');
		var invoice_value = this.getClaimAmount('invoice_value');
		var cost_of_repair = this.getClaimAmount('cost_of_repair');
		var cost_of_salvage = this.getClaimAmount('cost_of_salvage');

		return (
			<div className="container claim-form">
				<div className='form-title'>Basic Info</div>

				<Step stepData={steps} onClick={this.handClickStep} />

				<Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />

				<div className="block-form claim-form">
					<form>
						<h2 className="detail">Claim Details</h2>
						<div className="form-containers col-md-8 col-lg-6 form-rows">
							<div className="row">
								<div className="form-group col-md-6 col-sm-12">
									<div className="row">
										<label htmlFor="claimtype">Claim Type</label>
										<label className="toggle-check">
											<span>Preliminary</span>
											<input type="checkbox" className="toggle-check-input" name="claimtype" checked={(claim.claimtype == 'Form') ? true : false} onChange={this.handleChange} />
											<span className="toggle-check-text"></span>
											<span>Formal</span>
											<div className="clb"></div>
										</label>
									</div>

								</div>
								<div className="form-group  col-md-6 col-sm-12">
									<div className="row">
										<label htmlFor="Insured">Was the Cargo Insured?</label>
										<label className="toggle-check">
											<span>Yes</span>
											<input type="checkbox" className="toggle-check-input" name="Insured" checked={(claim.Insured == '') ? true : false} onChange={this.handleChange} />
											<span className="toggle-check-text"></span>
											<span>No</span>
											<div className="clb"></div>
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className="form-containers col-md-12 col-lg-12">
							<div className="row">
								<div className="col-10 form-rows">
									<div className="row">
										<h2 className="detail">Claim Aspects</h2>
										<InputError error={errors.claim_aspects_empty} />
									</div>
									<div className="row form-rows">
										<div className="col-md-3 col-4">
											<div className="row">
												<label htmlFor="">&nbsp;</label>
												<label className={`checkbox-container color-dark ${errors.claim_aspects_empty ? 'input-error' : ''}`}>Damage
													<input type="checkbox" name="damage" value="Damage" checked={damage.aspectcode ? true : false} onChange={this.handleChange} />
													<span className="checkmark-checkbox"></span>
												</label>
											</div>
										</div>

										<div className="col-md-6 col-8">
											<div className="form-group row">
												<div className=" col-sm-4 col-6">
													<div className="row">
														<label htmlFor="damage_piece">Pieces</label>
														<input type="text" disabled={damage.aspectcode ? false : true} className={`form-control  small ${(errors.damage_piece) ? 'input-error' : ''}`} name="damage_piece" value={damage.quant ? damage.quant : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>

												</div>
												<div className="col-sm-4 col-6">
													<div className="row">
														<label htmlFor="damage_kg">Kg</label>
														<input type="text" disabled={damage.aspectcode ? false : true} className={`form-control  small ${(errors.damage_kg) ? 'input-error' : ''}`} name="damage_kg" value={damage.weight ? damage.weight : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>
												</div>
												<InputError error={errors.damage_piece} />
												<InputError error={errors.damage_kg} />
											</div>
										</div>
									</div>


									<div className="row form-rows">
										<div className="col-md-3 col-4">
											<div className="row">
												<div className="form-group">
													<label className={`checkbox-container color-dark ${errors.claim_aspects_empty ? 'input-error' : ''}`}>Missing
														<input type="checkbox" name="missing" checked={missing.aspectcode ? true : false} onChange={this.handleChange} value="Missing" />
														<span className="checkmark-checkbox"></span>
													</label>
												</div>

											</div>
										</div>

										<div className="col-md-6 col-8">
											<div className="form-group row">
												<div className="col-sm-4 col-6">
													<div className="row">
														<input type="text" disabled={missing.aspectcode ? false : true} className={`form-control  small ${(errors.missing_piece) ? 'input-error' : ''}`} name="missing_piece" value={missing.quant ? missing.quant : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>
												</div>
												<div className="col-sm-4 col-6">
													<div className="row">
														<input type="text" disabled={missing.aspectcode ? false : true} className={`form-control  small ${(errors.missing_kg) ? 'input-error' : ''}`} name="missing_kg" value={missing.weight ? missing.weight : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>
												</div>
												<InputError error={errors.missing_piece} />
												<InputError error={errors.missing_kg} />
											</div>
										</div>
									</div>

									<div className="row form-rows">
										<div className="col-md-3 col-4">
											<div className="row">
												<label className={`checkbox-container color-dark ${errors.claim_aspects_empty ? 'input-error' : ''}`}>Partial Loss
														<input type="checkbox" name="partial_loss" checked={partial_loss.aspectcode ? true : false} value="Partial Loss" onChange={this.handleChange} />
													<span className="checkmark-checkbox"></span>
												</label>
											</div>
										</div>

										<div className="col-md-6 col-8">
											<div className="form-group row">
												<div className="col-sm-4 col-6">
													<div className="row">
														<input type="text" disabled={partial_loss.aspectcode ? false : true} className={`form-control  small ${(errors.partial_loss_piece) ? 'input-error' : ''}`} name="partial_loss_piece" value={partial_loss.quant ? partial_loss.quant : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>

												</div>
												<div className="col-sm-4 col-6">
													<div className="row">
														<input type="text" disabled={partial_loss.aspectcode ? false : true} className={`form-control  small ${(errors.partial_loss_kg) ? 'input-error' : ''}`} name="partial_loss_kg" value={partial_loss.weight ? partial_loss.weight : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />

													</div>
												</div>
												<InputError error={errors.partial_loss_piece} />
												<InputError error={errors.partial_loss_kg} />
											</div>
										</div>
									</div>

									<div className="row form-rows">
										<div className="col-md-3 col-4">
											<div className="row">
												<div className="form-group col-12">
													<div className="row">
														<label className={`checkbox-container color-dark ${errors.claim_aspects_empty ? 'input-error' : ''}`}>Pilferage
													<input type="checkbox" name="pilferage" checked={pilferage.aspectcode ? true : false} value="Pilferage" onChange={this.handleChange} />
															<span className="checkmark-checkbox"></span>
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-6 col-8">
											<div className="form-group row">
												<div className="col-sm-4 col-6">
													<div className="row">
														<input disabled={pilferage.aspectcode ? false : true} type="text" className={`form-control  small ${(errors.pilferage_piece) ? 'input-error' : ''}`} name="pilferage_piece" value={pilferage.quant ? pilferage.quant : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />
													</div>
												</div>
												<div className="col-sm-4 col-6">
													<div className="row">
														<input disabled={pilferage.aspectcode ? false : true} type="text" className={`form-control  small ${(errors.pilferage_kg) ? 'input-error' : ''}`} name="pilferage_kg" value={pilferage.weight ? pilferage.weight : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />
													</div>
												</div>
												<InputError error={errors.pilferage_piece} />
												<InputError error={errors.pilferage_kg} />
											</div>
										</div>
									</div>


								</div>
								<div className="col-12 form-rows">
									<div className="row form-rows">
										<h2 className="detail">Claim Amounts</h2>
									</div>
									<div className="form-group row form-rows">
										<div className="col-sm-3">
											<div className="row">
												<label htmlFor="claim_amount">Claim Amount</label>
											</div>
										</div>
										<div className="col-sm-9">
											<div className="row">
												<input type="text" className={`form-control  amount-input ${errors.claim_amount ? 'input-error' : ''}`} id="claim_amount" name="claim_amount" value={claim_amount.amount ? claim_amount.amount : ''} onChange={this.handleChange} />
												<CurrencySelect className={`form-control  small mgl5 ${errors.claim_amount_currency ? 'input-error' : ''}`} id="claim_amount_currency" name="claim_amount_currency" ref={el => this.claim_amount_currency = el} value={claim_amount.currcode ? claim_amount.currcode : ''} onChange={this.handleCurrencyChange} />
												<InputError error={errors.claim_amout_required} />
											</div>
										</div>

									</div>
									<div className="form-group row form-rows">
										<div className="col-sm-3">
											<div className="row">
												<label htmlFor="invoice_value">Invoice Value</label>
											</div>
										</div>
										<div className="col-sm-9">
											<div className="row">
												<input type="text" className={`form-control  amount-input ${errors.invoice_value ? 'input-error' : ''}`} id="invoice_value" name="invoice_value" value={invoice_value.amount ? invoice_value.amount : ''} onChange={this.handleChange} />
												<CurrencySelect className={`form-control  small mgl5 ${errors.invoice_value_currency ? 'input-error' : ''}`} id="invoice_value_currency" name="invoice_value_currency" ref={el => this.invoice_value_currency = el} value={invoice_value.currcode ? invoice_value.currcode : ''} onChange={this.handleCurrencyChange} />
											</div>
										</div>

									</div>
									<div className="form-group row form-rows">
										<div className="col-sm-3">
											<div className="row">
												<label htmlFor="cost_of_repair">Cost of Repair</label>
											</div>
										</div>
										<div className="col-sm-9">
											<div className="row">
												<input type="text" className={`form-control  amount-input ${errors.cost_of_repair ? 'input-error' : ''}`} id="cost_of_repair" name="cost_of_repair" value={cost_of_repair.amount ? cost_of_repair.amount : ''} onChange={this.handleChange} />
												<CurrencySelect className={`form-control  small mgl5 ${errors.cost_of_repair_currency ? 'input-error' : ''}`} id="cost_of_repair_currency" name="cost_of_repair_currency" ref={el => this.cost_of_repair_currency = el} value={cost_of_repair.currcode ? cost_of_repair.currcode : ''} onChange={this.handleCurrencyChange} />
											</div>
										</div>

									</div>
									<div className="form-group row form-rows">
										<div className="col-sm-3">
											<div className="row">
												<label htmlFor="cost_of_salvage">Cost of Salvage</label>
											</div>
										</div>
										<div className="col-sm-9">
											<div className="row">
												<input type="text" className={`form-control  amount-input ${errors.cost_of_salvage ? 'input-error' : ''}`} id="cost_of_salvage" name="cost_of_salvage" value={cost_of_salvage.amount ? cost_of_salvage.amount : ''} onChange={this.handleChange} />
												<CurrencySelect className={`form-control  small mgl5 ${errors.cost_of_salvage_currency ? 'input-error' : ''}`} id="cost_of_salvage_currency" name="cost_of_salvage_currency" ref={el => this.cost_of_salvage_currency = el} value={cost_of_salvage.currcode ? cost_of_salvage.currcode : ''} onChange={this.handleCurrencyChange} />
											</div>
										</div>

									</div>
									<div className="form-group row form-rows">
										<div className="col-12">
											<div className="row">
												<InputError error={errors.claim_amounts} />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div className="form-group form-rows">
							<div className="dv-form-responsive">
								<label htmlFor="nature_of_goods">Claim Description</label>
								<textarea className={`form-control  small ${(errors.text) ? 'input-error' : ''}`} name="text" onChange={this.handleChange} value={claim.text}> </textarea>
								<InputError error={errors.text} />
							</div>
						</div>

						<div className="form-group">
							<div className="dv-form-responsive">
								<button type="button" className="btn btn-continues right" onClick={this.handSubmit} >Continue</button>
								<div className="clb"></div>
							</div>
						</div>
					</form>

				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return state;
};

export default connect(mapStateToProps, claimActions)(ClaimDetail);