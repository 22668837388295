import React from 'react';

export default class ModalSampleConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { onShow, description } = this.props;
        if(!onShow)
          return (null);

        return (
            <div className="modal-dialog modal-dialog-small modal-dialog-centered sample-confirm" role="document">
                <div className="modal-content quotes-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                            <h5>Confirm</h5>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description" style={{'maxWidth': '350px'}}>Are you sure {description}?</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <div className="row">
                            <div className="col-6"><button type="button" className="btn btn-cancel btn-block" onClick={this.props.closeModal}>Cancel</button></div>
                            <div className="col-6"><button type="button" className="btn btn-continues btn-block" onClick={this.props.handleConfirm}>Confirm</button></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
