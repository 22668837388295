import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

class ModalConfirmForm extends React.Component {
    constructor(props) {
        super(props);
        this.onShowModal = this.onShowModal.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        this.confirmForm.resetFrom();
    };

    render() {
        return (
            <div className="modal-groups ">

                <div className="modal" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalConfirm')}></button>
                            </div>
                            <ConfirmForm onRef={ref => (this.confirmForm = ref)} loginData={this.props.loginData} loadingChangeData={this.props.loadingChangeData} history={this.props.history} auth={this.props.auth} onResend={this.props.onResend} onSubmit={this.props.onSubmit} showModal={this.props.showModal} hideModal={this.props.closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};
const ModalConfirm = connect(mapStateToProps, claimActions)(ModalConfirmForm);

export default ModalConfirm;


class ConfirmForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: props.error,
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'code': '',
            },
            showGetCode: false,
            btnConfirmText: 'Submit',
            btnResendText: "Get New Code",
            showLoginForm: true,
            showLoading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handlerConfirm = this.handlerConfirm.bind(this);
        this.handlerResendCode = this.handlerResendCode.bind(this);
        this.resetFrom = this.resetFrom.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    resetFrom = () => {
        this.setState({
            error: '',
            errorMessage: '',
            successMessage: '',
            successResendMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'code': '',
            },
            showGetCode: false,
            btnConfirmText: 'Submit',
            btnResendText: "Get New Code",
            showLoginForm: true,
            showLoading: false
        });
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { loginForm } = this.state;
        const { target, target: { name, type } } = event;
        loginForm[name] = target.value;
        this.setState({ loginForm });
    }

    validate() {
        this.setState({ errors: {} });
        const { loginForm } = this.state;
        var errors = {};
        var isError = false;
        var validator = new Validator();
        if (!validator.required(loginForm.code)) {
            errors['code'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }
    handlerConfirm = () => {
        const { history } = this.props;
        if (this.validate()) {
            this.setState({ successResendMessage: '', successMessage: '', errorMessage: '' });
            this.setState({ showLoading: true });
            this.props.onSubmit(this.props.auth.user_email, this.state.loginForm.code, this.props.loginData)
                .then((response) => {
                    // this.props.loadingChangeData(false);
                    if (response.success && response.message !== 'Invalid verification code provided, please try again.' && response.message !== 'Attempt limit exceeded, please try after some time.') {
                        this.setState({
                            showLoading: false,
                            successMessage: 'success',
                            errorMessage: '',
                            btnConfirmText: 'Submit',
                            btnResendText: 'Get New Code',
                            showLoginForm: response.showLoginForm
                        });
                    } else {
                        this.setState({
                            showLoading: false,
                            successMessage: '',
                            errorMessage: response.message,
                            btnConfirmText: 'Submit'
                        });
                    }

                })
                .catch((error) => {
                    // this.props.loadingChangeData(false);
                    this.setState({
                        showLoading: false,
                        successMessage: '',
                        errorMessage: error,
                        btnConfirmText: 'Submit'
                    });
                });
        }
    }
    handlerResendCode = () => {
        this.props.loadingChangeData(true);
        this.setState({ successResendMessage: '', showGetCode: false, successMessage: '', errorMessage: '', btnResendText: 'Submitting ...' });
        this.props.onResend(this.props.auth.user_email)
            .then((response) => {
                this.props.loadingChangeData(false);
                if (response.success) {
                    this.setState({ successResendMessage: 'success', successMessage: '', errorMessage: '', btnResendText: 'Get New Code' });
                } else {
                    this.setState({ successResendMessage: '', successMessage: '', errorMessage: response.message, btnResendText: 'Get New Code' });
                }
            })
            .catch((error) => {
                this.props.loadingChangeData(false);
                this.setState({ successResendMessage: '', successMessage: '', errorMessage: error, btnResendText: 'Get New Code' });
            });
    }
    showGetCode = (vl) => {
        this.setState({
            successMessage: '',
            errorMessage: '',
            showGetCode: vl
        });
    }

    render() {
        const { isErrorLogin, errorLogins, loginForm, showLoading } = this.state;

        return (
            <div>
                <div className="modal-content">
                    <div className={`modal-body ${showLoading ? '' : 'd-none'}`}>
                        <div className="form-group small-margin">                            
                            <label htmlFor="code">Submiting...</label>                              
                        </div>
                    </div>
                    <div className={`modal-body ${showLoading ? 'd-none' : ''}`}>
                        <h2>{this.state.showLoginForm ? "Verification Account" : "Verified Successfully"}</h2>
                        <hr />
                        <div className={`alert alert-success text-white ${(this.state.successResendMessage) ? '' : 'hide'}`}>
                            Success! Please check new code on your mail!
                        </div>
                        <div className={`${(this.state.successMessage) ? '' : 'hide'}`}>
                            <div className="block-form">
                                <form>
                                    <h2 className="detail-success">Success! You are verified!</h2>
                                    <p className="claim-text">{this.state.showLoginForm ? "You must login again after verified. Please click the login button to do it. Thanks !" : "Please wait while the system logs you in…"}</p>

                                    <div className="form-group">
                                        <button type="button" onClick={this.props.showModal.bind(this, 'modalLogin')} className={`btn btn-continues btn-left ${this.state.showLoginForm ? "" : "d-none"}`}>Login</button>
                                        <div className="clb"> </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                            {this.state.errorMessage}
                        </div>
                        <form className={`${(this.state.successMessage) ? 'hide' : ''}`}>
                            <div className="form-group small-margin">
                                <div className="row">
                                    <div className="col-7">
                                        <label htmlFor="code">Verification Code*</label>
                                    </div>
                                    <div className="col-5 register text-right">
                                        <a href="javascript:void(0)" onClick={this.showGetCode.bind(this, !this.state.showGetCode)}>New code?</a>
                                    </div>
                                </div>
                                <input type="text" className={`form-control ${(errorLogins.code) ? 'input-error' : ''}`} name="code" placeholder="Enter verification code" value={loginForm.code} onChange={this.handleChange} />
                            </div>
                            <div className="form-group small-margin">
                                <button type="button" onClick={this.handlerConfirm} className="btn btn-continues btn-forgot">{this.state.btnConfirmText}</button>
                            </div>
                            <div className={`form-group small-margin ${(this.state.showGetCode) ? '' : 'hide'}`}>
                                <button type="button" onClick={this.handlerResendCode} className="btn btn-continues btn-forgot">{this.state.btnResendText}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ConfirmForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onResend: PropTypes.func,
    error: PropTypes.string,
    setLogedIn: PropTypes.func
};
