import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Account from '../../layouts/partials/Account';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},

            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'My Account'
                },
            ],
            steps: [
                {
                    'link': 'dashboard',
                    'active': false,
                    'name': 'My Shipments'
                },
                {
                    'link': 'dashboard/quotes',
                    'active': false,
                    'name': 'My Quotes'
                },
                {
                    'link': 'account',
                    'active': true,
                    'name': 'My Info'
                },
                {
                    'link': 'account/reports',
                    'active': false,
                    'name': 'My Reports'
                }
            ],
            datas: [],
        };

    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    
    render() {
        const { breadcrumbs, steps, isError, errors, errorMessage }
            = this.state;



        return (

            <div className="container myaccount">
                <Breadcrumb breadcrumbData={breadcrumbs} />

                <h1 className="title">
                    Dashboard
                    <span className="account">Customer #: CAL879233</span>
                </h1>
                <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);