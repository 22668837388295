import React from 'react';
import queryString from 'query-string';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as authActions from '../ninja-redux/actions/authActions';
import * as helpActions from '../ninja-redux/actions/helpActions';


class EmptyLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {        
    }

    render() {        
        return (
            <div>                
                <div className="" >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.loading
});

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout);