import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class SupplierSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAttachButton: true,
            awb: ""
        };
    }
    componentDidMount() {
        var { supplier } = this.props;
        var supplierNotReset = { ...supplier };
        var awbSubmit = supplierNotReset.awb;
        if (awbSubmit.length > 11) {
            awbSubmit = awbSubmit.substr(0, 11);
        }
        this.setState({ awb: awbSubmit });
        this.props.supplierActions.supplierReset().then(res => { });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }


    render() {
        var { awb } = this.state;
        return (
            <div className="page-main">
                <div className="page-container" style={{'textAlign': "center", 'width': '100%', 'display': 'block'}}>
                    <table  style={{'width': "100%", 'height': "100%"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className="big-text">That's It!</div>
                                            <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>
                                                Thanks for taking the time.
                                            </div>
                                            <div className="content-text-desc div_center" style={{ "maxWidth": "500px" }}>
                                                We have everything we need for now.
                                            </div>
                                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "600px" }}>
                                                We will process the infromation and be in touch soon
                                            </div>
                                            <div className="content-buttons div_center mt-5">
                                                {/* <Link type="button" to={`${process.env.PUBLIC_URL}/ebooking/attach-files?awb=${awb}`} className={`btn btn-attact mr-3  mb-3 ${this.state.showAttachButton ? "" : "d-none"}`}>Attach Files</Link> */}
                                                <a href="https://www.cal-cargo.com/" className={`btn btn-orange mb-3`}>Thanks a lot</a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSuccess);