import React from 'react';

export default class Notification extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showText: false
        };
    }

    componentDidMount = () => {

    }

    componentWillUnmount = () => {
        this.autoCloseMessage();
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (newProps.message === '') {
            this.autoCloseMessage();
        } else if (oldProps.message !== newProps.message) {
            if (this.timerIDClose) {
                clearTimeout(this.timerIDClose);
            }
            this.setState({ showText: true });
            this.timerIDClose = setTimeout(this.autoCloseMessage, 10000);
        }
    }

    autoCloseMessage = () => {
        if (this.timerIDClose) {
            clearTimeout(this.timerIDClose);
            if (this.state.showText){
                this.setState({ showText: false });                
                this.props.hideMessage();
            }
        }
    }

    render() {
        const { isError, message } = this.props;
        if (message && this.state.showText) {
            return (
                <div className={`alert message-box ${isError ? 'alert-danger' : 'alert-success'}`}>
                    <button type="button" className="close" onClick={this.autoCloseMessage}>×</button>
                    {message}
                </div>
            )
        } else {
            return (null)
        }
    }
}
