import * as ActionTypes from '../actionTypes';

const initialState = {
    ports: [],
    state: "PORT_GETTING_DATA"
};

export default function reducer(state = initialState, action) {
    switch (action.type) {   
        case ActionTypes.PORT_CHANGE_DATA: {
            var newState = { ...state };
            newState['ports'] = action.payload;
            newState['state'] = "PORT_LOADED_DATA";
            return Object.assign({}, state, newState)
        }        
    }
    return state;
}