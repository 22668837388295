import * as ActionTypes from '../actionTypes';


var initialState = {
   api_checked: false,
   awb: "",
   ord: "",
   //step 1
   Dimensions: [],
   units_type: 'Centimeters',
   units_type_short: "CM",
   units_total_num_of_pieces: "",
   units_total_volume: "",
   units_total_weight: "",
   units_total_chargable_weight: "",
   TotalWeight: "",
   Weight: "",
   Volume: "",
   Pieces: "",
   Overlap: "N",
   overlap_shipment: "NonOverlap",
   overlap_shipment_check: false,
   unit_number_of_pieces: "",
   unit_length: "",
   unit_width: "",
   unit_height: "",
   unit_volume: "",
   //step 2
   shipment_dangerous_goods: false,
   shipment_dangerous_goods_type: 'CAO',
   shipment_lithium_batteries: false,
   shipment_unstackable: false,
   un: "",
   class: [],
   instructions: [],
   additional_information: '',
   handle_customs: "Yes",
   countryOfOrigine: "",
   hsCode: [],
   hsCodeField: "",
   EROI: "",
   MRN: [],
   //step 3
   pickupCompanyName: "",
   pickupCity: "",
   pickupComments: "",
   pickupAddress: "",
   pickupCountry: "",
   pickupEmail: "",
   pickupNameOfContact: "",
   pickupOpeningHours: "",
   pickupPhoneNumber: "",
   pickupPostalCode: "",
   pickupReferenceNumber: "",
   pickup_dropoff_date: '',
   pickup_dropoff_time_hour: '00',
   pickup_dropoff_time_minute: '00',
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case ActionTypes.SUPPLIER_RESET: {
         var defaultState = {
            api_checked: false,
            awb: "",
            ord: "",
            //step 1
            Dimensions: [],
            units_type: 'Centimeters',
            units_type_short: "CM",
            units_total_num_of_pieces: "",
            units_total_volume: "",
            units_total_weight: "",
            units_total_chargable_weight: "",
            TotalWeight: "",
            Weight: "",
            Volume: "",
            Pieces: "",
            Overlap: "N",
            overlap_shipment: "NonOverlap",
            overlap_shipment_check: false,
            unit_number_of_pieces: "",
            unit_length: "",
            unit_width: "",
            unit_height: "",
            unit_volume: "",
            //step 2
            shipment_dangerous_goods: false,
            shipment_dangerous_goods_type: 'CAO',
            shipment_lithium_batteries: false,
            shipment_unstackable: false,
            un: "",
            class: [],
            instructions: [],
            additional_information: '',
            handle_customs: "Yes",
            hsCode: [],
            hsCodeField: "",
            EROI: "",
            MRN: [],
            //step 3
            pickupCompanyName: "",
            pickupAddress: "",
            pickupCity: "",
            pickupComments: "",
            pickupCountry: "",
            pickupEmail: "",
            pickupNameOfContact: "",
            pickupOpeningHours: "",
            pickupPhoneNumber: "",
            pickupPostalCode: "",
            pickupReferenceNumber: "",
            pickup_dropoff_date: '',
            pickup_dropoff_time_hour: '00',
            pickup_dropoff_time_minute: '00',
         };
         return Object.assign({}, state, defaultState)
      }
      case ActionTypes.SUPPLIER_CHANGE_DATA: {
         var newState = { ...state };
         newState[action.payload.fieldName] = action.payload.fieldValue;
         return Object.assign({}, state, newState)
      }
      case ActionTypes.SUPPLIER_UPDATE_DATA: {
         var geted_data = action.payload;
         return Object.assign({}, geted_data)
      }
   }
   return state;
}