import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class Step extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { stepData } = this.props;

        return (
            <div className="steps">
                {stepData.map((step, i) => {
                    if (step.active) {
                        return (<div key={i} className="step active">
                            <span>{i + 1}.</span> <span> {step.name}</span>
                        </div>)
                    } else {
                        if (step.validate) {
                            return (<div key={i} className="step">
                                <Link onClick={this.props.onClick.bind(this)} className="validated" to={`${process.env.PUBLIC_URL}/${step.link}`}>{i + 1}.<span> {step.name}</span></Link>
                            </div>)
                        } else {
                            return (<div key={i} className="step">
                                <Link className="no-validated" to={`${process.env.PUBLIC_URL}/${step.link}`}>{i + 1}.<span> {step.name}</span></Link>
                            </div>)
                        }

                    }

                })}

            </div>
        );
    }
}

Step.propTypes = {
    onClick: PropTypes.func,
};

