import moment from 'moment';
import validator from 'validator';

const BookingHelper = {

    getVia: function (flights, toPort) {
        var via = "";
        flights.map((o, i) => {
            if (i && o.fromport != toPort) {
                if (via == "") {
                    via += o.fromport;
                } else {
                    via += " - " + o.fromport;
                }
            }
        })
        return via;
    },

    roundOff3Decimal: function (number, precision) {
        number += "";
        if (number) {
            return number.replace(/^(\d+.?\d{0,3})\d*$/, "$1");
        } else {
            return number;
        }
    },
    roundOff4Decimal: function (number, precision) {
        number += "";
        if (number) {
            return number.replace(/^(\d+.?\d{0,4})\d*$/, "$1");
        } else {
            return number;
        }
    },

    getDate: function (dateString, format) {
        var d = moment(dateString, format);
        return d;
    },

    getWeekday: function (d) {
        if (d.isValid()) {
            var wd = d.format("e");
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return days[wd];
        } else {
            return "";
        }
    },
    calculatorVolume: function (p, l, w, h) {
        var cbm = "";
        if (((!validator.isEmpty(p + "") && validator.isInt(p + ""))) && ((!validator.isEmpty(l + "") && validator.isNumeric(l + ""))) && ((!validator.isEmpty(w + "") && validator.isNumeric(w + ""))) && ((!validator.isEmpty(h + "") && validator.isNumeric(h + "")))) {
            cbm = parseInt(p) * parseFloat(l) * parseFloat(w) * parseFloat(h) * 0.000001;
            // cbm = cbm.toFixed(3) + "";
        }
        return cbm;
    },

    calculatorVolumeNew: function (unitLength, l, w, h) {
        var cbm = "";
        if (((!validator.isEmpty(l + "") && validator.isNumeric(l + ""))) && ((!validator.isEmpty(w + "") && validator.isNumeric(w + ""))) && ((!validator.isEmpty(h + "") && validator.isNumeric(h + "")))) {
            if (unitLength === 'cm' || unitLength === 'CM') {
                cbm = (parseFloat(l) * parseFloat(w) * parseFloat(h)) / 1000000;
            } else {
                cbm = ((parseFloat(l) * 2.54) * (parseFloat(w) * 2.54) * (parseFloat(h) * 2.54)) / 1000000;
            }
            // cbm = cbm.toFixed(3) + "";
        }
        return cbm;
    },
    calculatorVolumeKG: function (total_volume) {
        var kg = 0;
        if (total_volume) {
            kg = (total_volume / 6) * 1000;
            // kg = kg.toFixed(3);
        }
        return kg;
    },
    getDuration: function (d1, d2) {
        if (d1.isValid() && d2.isValid()) {
            var difference_ms = d2.valueOf() - d1.valueOf();
            var one_minute = 1000 * 60;
            var difference_m = difference_ms / one_minute;
            var return_h = Math.floor(difference_m / 60);
            if (return_h < 10) {
                return_h = "0" + return_h;
            }
            var return_m = difference_m % 60;
            if (return_m < 10) {
                return_m = "0" + return_m;
            }
            return return_h + ":" + return_m;
        }
        return "";
    },

    getDurationNew: function (n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes;
    },

    formatMoney: function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        var amount_floor = Math.floor(amount);
        var amount_decimal = amount - amount_floor;
        // if (amount_decimal == 0) {
        //     decimalCount = 0;
        // }
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    },

    getPrices: function (flights) {
        var prices = [];
        var eco_price = flights.price;
        var express_price = flights.expressprice;
        // flights.map((o, i) => {
        //     eco_price += o.price;
        //     express_price += o.expressprice;
        // })

        prices = [eco_price, express_price];
        return prices;
    },

    currencySymbol: function (iso3) {
        iso3 += "";
        iso3 = iso3.toUpperCase();
        switch (iso3) {
            case 'USD': return '$';
            case 'EUR': return '€';
            case 'NIS': return '₪';
            default: return iso3 + " ";
        }
    },
    buildQuotesData: function (quotes, sname, statusQuotes = 'Draft', auth, alertID = '') {
        var legs = [];
        if (quotes.DefaultChoosed) {
            quotes.DefaultChoosed.flight.flights.map(leg => {
                legs.push({
                    "leg": leg.leg
                });
            })
        }
        let pickup_type = 'drop off';

        if (quotes.t1_shipment === 'DTD' || quotes.t1_shipment === 'DTP' || (quotes.t1_shipment === 'T1' && quotes.deliver_from !== "Airport")) {
            pickup_type = 'pickup date';
        }
        let Dimensions = [];
        let uld = [];
        if (quotes.dimemtions_by !== "pieces") {
            for (let i = 0; i < quotes.units_list.length; i++) {
                let un = quotes.units_list[i];
                let item_pieces = un.Pcs !== '' ? parseInt(un.Pcs) : '';
                let uldType = un.paltype ? un.paltype : null;
                if (uldType && item_pieces !== '') {
                    uld.push({ "Pcs": parseInt(item_pieces), "paltype": uldType });
                }
            }
        } else {
            for (let i = 0; i < quotes.units_list.length; i++) {
                let un = quotes.units_list[i];
                let item_length = un.Length !== '' ? parseFloat(un.Length).toFixed(2) : '';
                let item_width = un.Width !== '' ? parseFloat(un.Width).toFixed(2) : '';
                let item_height = un.Height !== '' ? parseFloat(un.Height).toFixed(2) : '';
                let item_volume = un.Cbm !== '' ? parseFloat(un.Cbm).toFixed(2) : '';
                let item_pieces = un.Pcs !== '' ? parseInt(un.Pcs) : '';
                if (item_length !== '' || item_width !== '' || item_height !== '' || item_volume !== '') {
                    Dimensions.push({ "Height": item_height, "Width": item_width, "Length": item_length, "Pcs": item_pieces, "unitname": quotes.units_type_short.toUpperCase(), "Weight": item_volume, "paltype": un.paltype ? un.paltype : "" });
                }
            }
        }

        let add = [];
        let cpdet = [];
        let exp = [];
        let hs = [];
        let cls = [];
        let uns = [];
        if (quotes.shipment_dangerous_goods) {
            for (let i = 0; i < quotes.class.length; i++) {
                let cl = quotes.class[i];
                cls.push({ text: cl.value });
            }
            var dg_un = quotes.un.trim();
            var dg_un_array = dg_un.split(/\n/);
            for (let i = 0; i < dg_un_array.length; i++) {
                if (dg_un_array[i]) {
                    //additional_information
                    let dg_un_array_text = dg_un_array[i];
                    let numberLoopDGUn = parseInt(dg_un_array_text.length / 68);
                    let checkDivDGUn = dg_un_array_text.length % 68;
                    if (checkDivDGUn) {
                        numberLoopDGUn++;
                    }
                    for (let i = 0; i < numberLoopDGUn; i++) {
                        uns.push({ text: dg_un_array_text.substring(i * 68, (i + 1) * 68) });
                    }
                }
            }
            if (quotes.additional_information) {
                //additional_information
                var additional_information = quotes.additional_information.trim();
                additional_information = additional_information.replace(/(\r\n)/gm, "<br/>");
                additional_information = additional_information.replace(/(\n)/gm, "<br/>");
                var numberLoopAI = parseInt(additional_information.length / 68);
                var checkDivAI = additional_information.length % 68;
                if (checkDivAI) {
                    numberLoopAI++;
                }
                for (let i = 0; i < numberLoopAI; i++) {
                    add.push({ text: additional_information.substring(i * 68, (i + 1) * 68) });
                }
            }
        }

        for (let i = 0; i < quotes.instructions.length; i++) {
            let el = quotes.instructions[i];
            hs.push({ Code: el.value, Delivertill: null });
        }

        let firstCalcmethod = null;
        let firstPrice = null;
        let firstItem = null;
        let firstCurrency = null;


        var pDate = moment().add(2, 'day');
        var ic = [];
        let primaryPhone = null;
        let phones = [];
        let contactemail = auth.user_email;
        let contactname = auth.user_name;
        let contactweb = "Y";
        //     if (!i) {
        //         primaryPhone = quotes.selectedContacts[i].phone;
        //         contactemail = quotes.selectedContacts[i].Email;
        //         contactname = quotes.selectedContacts[i].Name;
        //         contactweb = quotes.selectedContacts[i].Websiteuser;
        //     } else {
        //         phones.push({ phone: quotes.selectedContacts[i].phone });
        //     }
        // }

        var emailTotalType = { label: "Totals", value: 'totals' };
        let quotesData = {
            "cp": {
                "CustAU": null,
                "CProfAU": null,
                "phone": primaryPhone,
                "CProfNum": "",
                "PDate": pDate.format('DD/MM/YY'),
                "SaleTypeCode": quotes.t1_shipment,
                "MustRide": "",
                "T1": quotes.t1_shipment === 'T1' ? "Y" : "",
                "CaoPax": null, //chú ý cái này
                "Overlap": quotes.overlap_shipment_check ? "Y" : "",
                "Litium": (quotes.shipment_lithium_batteries && quotes.shipment_lithium_batteries) ? 'Y' : '',
                "Unstackable": quotes.shipment_unstackable ? 'Y' : '',
                "FromPortCode": quotes.deliver_from === 'Airport' ? quotes.deliver_from_airport : "",
                "ToPortCode": quotes.deliver_to === 'Airport' ? quotes.deliver_to_airport : "",
                "FromCountryCode": quotes.deliver_from_country,
                "ToCountryCode": quotes.deliver_to_country,
                "FromZipCode": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_zipcode : "",
                "ToZipCode": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_zipcode : "",
                "FromCity": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_city : "",
                "ToCity": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_city : "",
                "AWB": null,
                "ContactName": "",
                "ShippingAgent": 0,
                "RecievingAgent": 0,
                "CNor": null,
                "CNee": null,
                "Commodity": quotes.commodity_type,
                "SubCommodity": quotes.sub_commodity,
                "caopax": quotes.shipment_dangerous_goods_type,
                "dept": "EXP", //chua hieu cai nay
                "Weight": parseFloat(quotes.units_total_weight),
                "CWeight": parseFloat(quotes.units_total_chargable_weight),
                "Volume": parseFloat(quotes.units_total_volume),
                "Price": null,
                "Pieces": parseInt(quotes.units_total_num_of_pieces),
                "Dimensions": null,
                "ETDOrigin": quotes.pickup_dropoff_date ? quotes.pickup_dropoff_date.format('DD/MM/YY HH:mm') : '',
                "enddate": "",
                "Remarks": null,
                "HandlingInstrunctionCodes": null,
                "Participents": {
                    "SHIPNAME": null,
                    "SHIPIATACODE": null,
                    "RECNAME": null,
                    "RECIATACODE": null,
                    "CNORNUM": null,
                    "CNORNAME": quotes.selectedSuggessCNOR && quotes.selectedSuggessCNOR.custname ? quotes.selectedSuggessCNOR.custname : null, //cnor
                    "CNORCOUNTRY": null,
                    "CNORADDRESS": null,
                    "CNORCITY": null,
                    "CNORPOSTALCODE": null,
                    "CNEENUM": null,
                    "CNEENAME": quotes.selectedSuggessCNEE && quotes.selectedSuggessCNEE.custname ? quotes.selectedSuggessCNEE.custname : null,
                    "CNEECOUNTRY": null,
                    "CNEEADDRESS": null,
                    "CNEEPOSTALCODE": null,
                    "CNEECITY": null,
                    "PICKNAME": null,
                    "PICKEMAIL": null,
                    "PICKPHONE": null,
                    "PICKCOUNTRY": null,
                    "PICKCITY": null,
                    "PICKADDRESS": null,
                    "PICKPOSTALCODE": null,
                    "DELNAME": null,
                    "DELEMAIL": null,
                    "DELPHONE": null,
                    "DELCOUNTRY": null,
                    "DELCITY": null,
                    "DELADDRESS": null,
                    "DELPOSTALCODE": null,
                    "DELOPENHOURS": null,
                    "DELREMARKS": null,
                    "PICKOPENHOURS": null,
                    "PICKREMARKS": null,
                    "PICKCOMPNAME": null,
                    "DELCOMPNAME": null
                },
                "comtype": "", //chua hieu cai nay
                "sname": sname,
                "sitestat": statusQuotes,
                "statdate": "", //chua hieu cai nay
                "statchangeres": "",//chua hieu cai nay
                "calcmethod": firstCalcmethod,
                "currency": auth.cur,
                "typedate": pickup_type,
                "usercode": quotes.usercode,
                "allin": "",
                "custname": auth.custname,
                "custcountry": "",
                "custcity": "",
                "custcurr": auth.cur,
                "contactemail": contactemail,
                "contactname": contactname,
                "contactweb": contactweb
            },
            "Dimensions": Dimensions,
            "cpdet": cpdet,
            "exp": exp,
            "hs": hs,
            "uld": uld,
            "phone": phones,
            "leg": legs,
            "add": add,
            "cls": cls,
            "un": uns,
            "ic": ic,
            "deleteValues": {
                "alertID": alertID,
                "haveChanged": false,
                "companyName": auth.custname,
                "deliver_from_airport": quotes.deliver_from_airport,
                "deliver_to_airport": quotes.deliver_to_airport,
                "selectedFlight": quotes.DefaultChoosed ? quotes.DefaultChoosed.flight : null,
                "Commodity": quotes.commodity_type_name ? quotes.commodity_type_name : "",
                "SubCommodity": quotes.sub_commodity_name ? quotes.sub_commodity_name : "",
                "totalPrice": 0,
                "totalType": emailTotalType,
                "unit_type": quotes.units_type_short.toUpperCase(),
                "PersionMessage": '',
                "HourExpires": pDate.format('DD/MM/YYYY HH:mm'),
                "DetailsExpires": pDate.format('dddd, MMMM Do YYYY'),
                "DetailsHourExpires": pDate.format('HH:mm'),
                "ProposalExprise": null,
                "OfferCurrency": auth.cur,
                "SelectedContacts": [],
                'sendProposalEmail': false
            },
            "emailID": null
        };
        return quotesData;
    }
}

export default BookingHelper;