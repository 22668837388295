import * as ActionTypes from '../actionTypes';

const initialState = {
    shipping: [],
    receiving: [],
    cnor: [],
    cnee: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PARTICIPANT_UPDATE: {
            var newState = { ...state };
            switch (action.payload.type) {
                case 'REC':
                    newState['receiving'] = action.payload.data;
                    break;
                case 'CNEE':
                    newState['cnee'] = action.payload.data;
                    break;
                case 'CNOR':
                    newState['cnor'] = action.payload.data;
                    break;

                default:
                    newState['shipping'] = action.payload.data;
                    break;
            }

            newState['types'] = action.payload;
            return Object.assign({}, state, newState)
        }
        case ActionTypes.PARTICIPANT_RESET: {
            var newState = { ...state };
            newState['shipping'] = [];
            newState['receiving'] = [];
            newState['cnor'] = [];
            newState['cnee'] = [];
            return Object.assign({}, state, newState)
        }
    }
    return state;
}