import React from 'react';
import axios from 'axios';
import StepProgress from '../../layouts/partials/StepProgress';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import AppConfig from '../../AppConfig';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
import uuid from 'uuid';
import SupplierUploaded from './SupplierUploaded';
import validator from 'validator';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import EbookingInputError from '../EbookingInputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import * as authActions from '../../ninja-redux/actions/authActions';


class SpecialIntructionOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            errors: {},
            selectedCountryOfOrigine: null,
            selectedInstruction: null,
            listInstructions: [],
            countries: [],
            isLoadingCountry: false,
            hsCode: "",
            maxUploadFiles: 10,
            isUploading: false,
            loaded: 0,
            showProgress: false,
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            fileStartName: "WEBSUP",
            maxFileSize: 9,
            subFolder: ""
        };

        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addClass = this.addClass.bind(this);
        this.removeAddedClass = this.removeAddedClass.bind(this);
        this.handleOnBlurSelect = this.handleOnBlurSelect.bind(this);
        this.handleClickInstruction = this.handleClickInstruction.bind(this);
        this.removeAddedInstruction = this.removeAddedInstruction.bind(this);
        this.addHsCode = this.addHsCode.bind(this);
        this.removeHsCode = this.removeHsCode.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        var { supplier } = this.props;
        if (oldProps.country != undefined && newProps.country != undefined && oldProps.country.state != "COUNTRY_LOADED_DATA" && newProps.country.state == "COUNTRY_LOADED_DATA") {
            var selectedCountryOfOrigine = null;
            var countries = [];
            var isLoadingCountry = false;
            newProps.country.countries.forEach(c => {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                countries.push(country);
                if (c.countryname == supplier.countryOfOrigine) {
                    selectedCountryOfOrigine = country;
                    this.setState({ selectedCountryOfOrigine });
                }
            });
            this.setState({ countries, isLoadingCountry });
        }
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        this.getInstructions();
        var countries = [];
        var isLoadingCountry = true;
        var selectedCountryOfOrigine = null;
        var { supplier, history } = this.props;
        if (this.props.country.state == "COUNTRY_LOADED_DATA") {
            isLoadingCountry = false;
            this.props.country.countries.forEach(c => {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                countries.push(country);
                if (c.countryname == supplier.countryOfOrigine) {
                    selectedCountryOfOrigine = country;
                }
            });
            this.setState({ countries, isLoadingCountry, selectedCountryOfOrigine, hsCode: supplier.hsCodeField });
        }


        if (!supplier.api_checked || !supplier.Dimensions.length) {
            var params = this.props.location.search;
            const parsedParams = queryString.parse(params);
            var ord = parsedParams.ord;
            var awb = parsedParams.awb;
            history.push('/supplier-update/commodity/?awb=' + awb + '&ord=' + ord);
            return;
        }
    }

    getInstructions = () => {
        var { listInstructions } = this.state;
        listInstructions = [];
        this.setState({ isLoadingInstruction: true });
        this.props.ebookingActions.getInstructions().then(response => {
            listInstructions = [];
            if (response.success) {
                var handlingInstructions = response.data.handlingInstructions;
                handlingInstructions = handlingInstructions.sort((a, b) => { return a.Descreption.localeCompare(b.Descreption) });
                handlingInstructions.forEach(a => {
                    listInstructions.push({ "value": a.Code, "label": a.Descreption });
                });
            }
            this.setState({ listInstructions, isLoadingInstruction: false });
        });
    }

    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { supplier } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (value != "") {
            errors[name] = "";
        }
        this.setState({ errors });
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { errors, isUploading } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var { supplier } = this.props;
        if (name === "shipment_dangerous_goods") {
            if (supplier.shipment_lithium_batteries == true) {
                value = true;
            }
            if (value == false) {
                supplier.class = [];
                supplier.un = "";
                supplier.shipment_dangerous_goods_type = "CAO";
            }
            supplier[name] = value;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        } else if (name === "shipment_lithium_batteries") {
            if (value == true) {
                supplier["shipment_dangerous_goods"] = true;
            }
            supplier[name] = value;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        } else if (name === "shipment_dangerous_goods_type") {
            if (value == false) {
                value = "CAO";
            } else {
                value = "PAX";
            }
            supplier[name] = value;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        } else if (name === "handle_customs") {
            supplier[name] = value;
            if (value === "Yes") {
                supplier.hsCode = [];
                supplier.EROI = "";
            } else {
                supplier.MRN = [];
            }
            if (navigator.appName == "Microsoft Internet Explorer" || navigator.appName == "Netscape") {
                window.document.execCommand('Stop');
            } else {
                window.stop();
            }
            isUploading = false;
            this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        } else if (name === "hsCode") {
            supplier.hsCodeField = value;
            this.props.supplierActions.supplierChangeData({ fieldName: "hsCodeField", fieldValue: value }).then(responseData => { });
            this.setState({ hsCode: value });
        } else {
            supplier[name] = value;
            this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        }
        errors[name] = "";
        this.setState({ errors, isUploading });
    }

    handleChangeInstructions = (selectedInstruction) => {
        this.setState({ selectedInstruction });
    }

    handleOnBlurSelect = (event) => {
        var { errors, selectedCountryOfOrigine } = this.state;
        var { target, target: { id, name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (id == "countryOfOrigine") {
            if (selectedCountryOfOrigine) {
                errors["countryOfOrigine"] = "";
            }
        }
        this.setState({ errors });
    }

    addClass = (event) => {
        var { errors } = this.state;
        var { supplier } = this.props;
        if (this.class) {
            errors.class = "";
            var selecteds_value = this.class.value;
            var checkExisted = false;
            supplier.class.forEach(a => {
                if (a == selecteds_value) {
                    checkExisted = true;
                }
            });
            if (!checkExisted) {
                supplier.class.push(selecteds_value);
                this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            }
            this.setState({ errors });
        }
    }

    removeAddedClass = (instructionID) => {
        var { supplier } = this.props;
        supplier.class.splice(instructionID, 1);
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    handleClickInstruction = () => {
        var { supplier } = this.props;
        if (this.state.selectedInstruction) {
            var checkExisted = false;
            supplier.instructions.forEach(a => {
                if (a.value == this.state.selectedInstruction.value) {
                    checkExisted = true;
                }
            });
            if (!checkExisted) {
                supplier.instructions.push(this.state.selectedInstruction);
                this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            }
            this.setState({ selectedInstruction: null });
        }
    }

    removeAddedInstruction = (instructionID) => {
        var { supplier } = this.props;
        supplier.instructions.splice(instructionID, 1);
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    addHsCode = () => {
        var { errors, hsCode } = this.state;
        var { supplier } = this.props;
        hsCode = hsCode.trim();
        if (hsCode.length < 6 || hsCode.length > 8) {
            errors.hsCode = "HS Code must be 6-8 characters.";
        } else {
            supplier.hsCodeField = "";
            supplier.hsCode.push(hsCode);
            errors.hsCode = "";
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            hsCode = "";
        }
        this.setState({ hsCode, errors });
    }

    removeHsCode = (i) => {
        var { supplier } = this.props;
        supplier.hsCode.splice(i, 1);
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    handleChangeCountryOfOrigine = (selectedCountryOfOrigine) => {
        var { supplier } = this.props;
        supplier["countryOfOrigine"] = selectedCountryOfOrigine.labelhiden ? selectedCountryOfOrigine.labelhiden : "";
        this.setState({ selectedCountryOfOrigine });
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    makerandom = (length) => {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    clickAddFile = () => {
        this.inputElement.click();
    }

    handleUploadFile = async (e) => {
        var { supplier } = this.props;
        var { errors, maxUploadFiles, acceptFiles, maxFileSize, isUploading, fileStartName } = this.state;
        var selectorFiles = e.target.files;
        var file = selectorFiles[0];
        // e.target.value = null;
        errors['MRN'] = '';
        if (file) {
            if (supplier.MRN.length < maxUploadFiles) {
                var name = file.name;
                var nameSplit = name.split('.');
                var filesize = file.size;
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                var fileName = name.replace(/\.[^/.]+$/, "");
                ext = ext.toLowerCase();
                fileName = fileName.replace(/[^\w]/gi, '-');

                var awbSubmit = supplier.awb;
                if (awbSubmit.length > 11) {
                    awbSubmit = awbSubmit.substr(0, 11);
                }

                if (fileName.length > 10) {
                    fileName = fileName.substr(0, 10);
                }

                var fileNewName = fileStartName + awbSubmit + "-" + fileName + "MRN" + this.makerandom(5) + "." + ext;

                if ((acceptFiles.indexOf(ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                    supplier.MRN.push({ file: file, ext: ext, uploaded: false, uploadError: false, uploadProgress: 0, fileType: "", fileName: fileNewName, id: uuid() });
                    this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
                    this.setState({ isUploading: true });
                    var uploadStatus = await this.uploadFile(supplier.MRN[supplier.MRN.length - 1]);
                    this.setState({ isUploading: false });
                    if (this.props.supplier.handle_customs === "Yes") {
                        if (uploadStatus.success) {
                            supplier.MRN[supplier.MRN.length - 1].uploaded = true;
                            supplier.MRN[supplier.MRN.length - 1].uploadError = false;
                            supplier.MRN[supplier.MRN.length - 1].uploadProgress = 0;
                        } else {
                            supplier.MRN[supplier.MRN.length - 1].uploaded = false;
                            supplier.MRN[supplier.MRN.length - 1].uploadError = true;
                            supplier.MRN[supplier.MRN.length - 1].uploadProgress = 0;
                        }
                        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
                    }

                } else {
                    errors['MRN'] = "Please check your type and file size!";
                }
            } else {
                errors['MRN'] = "You can upload maximum " + maxUploadFiles + " files.";
            }
        }

        this.setState({ errors });
    }

    // Start upload files
    changeProgress = (loaded, total, id) => {
        var { supplier } = this.props;
        var fileIndex = supplier.MRN.findIndex(e => e.id === id);
        if (fileIndex > -1) {
            var loadedPer = 0;
            if (total > 0) {
                loadedPer = parseInt((loaded / total) * 100);
            }
            supplier.MRN[fileIndex].uploadProgress = loadedPer;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        }
    }

    uploadFile = (fileData) => {
        var { subFolder, acceptFiles, maxFileSize } = this.state;
        var reader = new FileReader();
        var eObj = this;
        return new Promise((resolve, reject) => {
            reader.addEventListener('loadend', function (e) {
                var filesize = fileData.file.size;
                if ((acceptFiles.indexOf(fileData.ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                    var fileNewName = fileData.fileName;
                    if (subFolder != "") {
                        fileNewName = subFolder + '/' + fileData.fileName;
                    }
                    fetch(AppConfig.apiUpload + "api/service/upload-supplier-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: fileData.file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: fileData.file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total, fileData.id);
                                }
                            })
                        })
                        .then(function () {
                            resolve({ success: true, id: fileData.id });
                        }).catch(e => {
                            resolve({ success: false, id: fileData.id });
                        });
                } else {
                    resolve({ success: false, id: fileData.id, desc: "Please check your type and file size!" });
                }
            });
            reader.readAsArrayBuffer(fileData.file);
        });
    }

    handleDeleteFile(id) {
        var { supplier } = this.props;
        var fileIndex = supplier.MRN.findIndex(e => e.id === id);
        if (fileIndex > -1) {
            supplier.MRN.splice(fileIndex, 1);
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        }
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });
        var { supplier } = this.props;
        var errors = {};
        var isError = false;

        if (supplier.countryOfOrigine == "") {
            errors['countryOfOrigine'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (supplier.handle_customs === "No" && !validator.isLength(supplier.EROI, { min: 9, max: 17 })) {
            errors['EROI'] = "Must be 9-17 characters";
            isError = true;
        }

        var hsCodeField = supplier.hsCodeField.trim();

        if (supplier.handle_customs === "No" && (supplier.hsCode.length < 1 && (hsCodeField.length < 6 || hsCodeField.length > 8))) {
            errors['hsCode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (supplier.handle_customs === "No" && !validator.isEmpty(hsCodeField) && !validator.isLength(hsCodeField, { min: 6, max: 8 })) {
            errors['hsCode'] = "Must be 6-8 characters";
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
        }

        return isError;
    }


    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        const { history, supplier } = this.props;
        if (supplier.shipment_dangerous_goods) {
            await this.addClass();
        }
        await this.handleClickInstruction();
        if (this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        window.scrollToTop();
        history.push('/supplier-update/pickup/?awb=' + supplier.awb + '&ord=' + supplier.ord);
        return;
    }

    buildHSCode = () => {
        var { supplier } = this.props;
        if (supplier.hsCode.length) {
            return (
                <div className={`form-rows mt-4`}>
                    <div className="row">
                        {
                            supplier.hsCode.map((op, i) => {
                                return <div className="col-12" key={i} value={op.value}>
                                    <div className="row">
                                        <div className="col-12 col-md-9 col-lg-6">
                                            <div className="mrn-table">
                                                <div className="mrn-cell" style={{ "minWidth": "70px" }}></div>
                                                <div className="mrn-cell">
                                                    <div className="instruction-list-item bg-white">
                                                        {op}
                                                        <button type="button" onClick={this.removeHsCode.bind(this, i)} className="close-instruction"></button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>;
                            })
                        }
                    </div>
                </div>
            );
        }
    }
    render() {
        var { errors, errorMessage, selectedCountryOfOrigine, countries, isLoadingCountry, isUploading, maxUploadFiles } = this.state;
        var { supplier } = this.props;
        return (
            <div className="ebooking supplier">
                <form autoComplete="off" className="form-block">
                    <input type="hidden" value="" tabIndex={1} name="hdFakeInput"></input>
                    <div className="header-menu">
                        <div className="row">
                            <div className="col-6 text-left">
                                <Link to={'/supplier-update/commodity/?awb=' + supplier.awb + '&ord=' + supplier.ord} className="supplier-link-back">Back to Shipment Details</Link>
                            </div>
                            <div className="col-6 text-right">
                                <span className="supplier-details-awb">Details for AWB {supplier.awb}</span>
                            </div>
                        </div>
                    </div>

                    <div className={`container block-form mt-3 ${supplier.api_checked ? 'd-none' : ''}`}>
                        <div className="form-content">
                            <div className={`form-group mt-3`}>
                                <div className="alert alert-danger" role="alert">
                                    We not found information of this order. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container block-form mt-5">
                        <h2 className="formTitle mb-3">Dangerous Goods & Special Instructions</h2>
                        <div className="form-description mb-3">Mark any element below that might be relevant to your commodity.</div>
                        <div className="form-content-description dangerous_goods">
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u137_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20 ${supplier.shipment_lithium_batteries ? "grey" : ""}`}>This shipment contains dangerous goods (DG)
                                                                <input type="checkbox" tabIndex={1} name="shipment_dangerous_goods" value="shipment_dangerous_goods" checked={supplier.shipment_dangerous_goods ? supplier.shipment_dangerous_goods : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_dangerous_goods) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`hidden_dangerous_goods_element ${supplier.shipment_dangerous_goods ? "" : "hide"}`}>
                                <div className={`form-rows`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <div className="toggle-div">
                                                        <span className="mr-3 toggle-span">DG Type:</span>
                                                        <span className="toggle-span">CAO</span>
                                                        <label className="toggle-check">
                                                            <input type="checkbox" className="toggle-check-input" name="shipment_dangerous_goods_type" checked={supplier.shipment_dangerous_goods_type == "CAO" ? false : true} onChange={this.handleChange} />
                                                            <span className="toggle-check-text"></span>
                                                            <div className="clb"></div>
                                                        </label>
                                                        <span className="toggle-span">PAX</span>
                                                        <div className="clb"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-rows`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <table className="table-instruction">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ "width": "50px" }}>
                                                                    <label>Class: </label>
                                                                </td>
                                                                <td>
                                                                    <select className={`form-control`} name="class" ref={ref => { this.class = ref }}>
                                                                        <option value="1. Explosives">1. Explosives</option>
                                                                        <option value="2. Gases">2. Gases</option>
                                                                        <option value="3. Flammable Liquids">3. Flammable Liquids</option>
                                                                        <option value="4. Flammable Solids">4. Flammable Solids</option>
                                                                        <option value="5. Oxidizing Substances">5. Oxidizing Substances</option>
                                                                        <option value="6. Toxic & Infectious Substances">6. Toxic & Infectious Substances</option>
                                                                        <option value="7. Radioactive Material">7. Radioactive Material</option>
                                                                        <option value="8. Corrosives">8. Corrosives</option>
                                                                        <option value="9. Miscellaneous Dangerous Goods">9. Miscellaneous Dangerous Goods</option>
                                                                    </select>
                                                                </td>
                                                                <td style={{ "width": "82px" }}>
                                                                    <button type="button" className="btn btn-ebooking-orange" onClick={this.addClass}>Add <i className="playIcon"></i></button>
                                                                </td>
                                                            </tr>
                                                            <tr className={`${errors.class ? "" : "hide"}`}>
                                                                <td colSpan={3}>
                                                                    <InputError error={errors.class} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`form-rows mt-4 ${supplier.class.length ? "" : "hide"}`}>
                                    <div className="row">
                                        <div className="col-12" style={{ "padding": "0 66px", "maxWidth": "500px" }}>
                                            <div className="row">
                                                {
                                                    supplier.class.map((op, i) => {
                                                        return <div className="col-12" key={i} value={op}>
                                                            <div className="instruction-list-item">
                                                                {op}
                                                                <button type="button" onClick={this.removeAddedClass.bind(this, i)} className="close-instruction"></button>
                                                            </div>
                                                        </div>;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-rows mt-3`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <textarea className="form-control" placeholder="UN Numbers" style={{ width: "100%", height: "100px" }} id="un" name="un" onChange={this.handleChange}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u36_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20`}>This shipment contains lithium batteries
                                                <input type="checkbox" name="shipment_lithium_batteries" value="shipment_lithium_batteries" checked={supplier.shipment_lithium_batteries ? supplier.shipment_lithium_batteries : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_lithium_batteries) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u35_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20`}>This shipment is unstackable
                                                <input type="checkbox" name="shipment_unstackable" value="shipment_unstackable" checked={supplier.shipment_unstackable ? supplier.shipment_unstackable : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_unstackable) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container block-form mb-5">
                        <div className="form-content no_padding">
                            <div className="form-content no_padding">
                                <div className="form-rows">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="weight">Handling Instructions</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-7 col-md-6">
                                                    <Select
                                                        inputId="instructions"
                                                        className={`select-box huge-input ${(errors.instructions) ? 'input-error' : ''}`}
                                                        value={this.state.selectedInstruction}
                                                        isSearchable={true}
                                                        isDisabled={false}
                                                        onChange={this.handleChangeInstructions}
                                                        options={this.state.listInstructions}
                                                        isLoading={this.state.isLoadingInstruction}
                                                        onBlur={this.handleOnBlurSelect}
                                                    />
                                                    <InputError error={errors.instructions} />
                                                </div>
                                                <div className="form-group col-5 col-md-6 col-lg-2">
                                                    <button type="button" className="btn btn-ebooking-orange" onClick={this.handleClickInstruction}>Add Instruction <i className="playIcon"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`form-rows mt-4 ${supplier.instructions && supplier.instructions.length ? "" : "d-none"}`}>
                                    <div className="row">
                                        {
                                            supplier.instructions.map((op, i) => {
                                                return <div className="col-7 col-md-6" key={i} value={op.value}>
                                                    <div className="instruction-list-item">
                                                        {op.label}
                                                        <button type="button" onClick={this.removeAddedInstruction.bind(this, i)} className="close-instruction"></button>
                                                    </div>
                                                </div>;
                                            })
                                        }
                                    </div>
                                </div>

                                <div className={`form-containers form-rows ${(supplier.shipment_dangerous_goods || supplier.shipment_lithium_batteries || supplier.shipment_unstackable) ? "" : "d-none"}`}>
                                    <label htmlFor="additional_information">Additional Information</label>
                                    <textarea className="form-control fullwidth" id="additional_information" name="additional_information" onChange={this.handleChange} value={supplier.additional_information}></textarea>
                                    <InputError error={errors.additional_information} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="blue-bg">
                        <div className="container block-form pt-5 pb-5">
                            <h2 className="formTitle mb-3">Other Instructions</h2>
                            <div className="form-content no_padding">
                                <div className="form-content no_padding">
                                    <div className="form-rows">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="bg-blue">
                                                    <div className="row top-row">
                                                        <div className="col-12">
                                                            <div className="form-group form-radio form-rows">
                                                                <label className="radio-container no-padding">Handle Customs:</label>
                                                                <label className="radio-container">Yes
                                                                <input type="radio" name="handle_customs" value="Yes" checked={supplier.handle_customs === "Yes"} onChange={this.handleChange} />
                                                                    <span className={`checkmark ${(errors.handle_customs) ? 'input-error' : ''}`}></span>
                                                                </label>
                                                                <label className="radio-container">No
                                                                <input type="radio" name="handle_customs" value="No" checked={supplier.handle_customs === "No"} onChange={this.handleChange} />
                                                                    <span className={`checkmark ${(errors.handle_customs) ? 'input-error' : ''}`}></span>
                                                                </label>
                                                                <div className="clb"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`mrn-row ${supplier.handle_customs === "Yes" ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="form-group col-12 col-md-9 col-lg-6">
                                                        <div className="mrn-table">
                                                            <div className="mrn-cell" style={{ 'width': '50px' }}>MRN: </div>
                                                            <div className="mrn-cell">
                                                                <button type="button" className="btn btn-block btn-ebooking-orange" onClick={this.clickAddFile} disabled={(isUploading || supplier.MRN.length >= maxUploadFiles) ? true : false}>{isUploading ? "Uploading" : "Add file"} <i className="playIcon"></i></button>
                                                                <input type="file" ref={input => this.inputElement = input} className="d-none" name="upload" accept="application/pdf, image/png, image/jpeg, application/msword" disabled={(isUploading || supplier.MRN.length >= maxUploadFiles) ? true : false} onChange={this.handleUploadFile} />
                                                                <InputError error={errors.MRN} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* <div className="form-group col-5 col-md-6 col-lg-2">
                                                        <button type="button" className="btn btn-ebooking-orange">Add <i className="playIcon"></i></button>
                                                        <InputError error={errors.instructions} />
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-12 mt-4">
                                                {
                                                    supplier.MRN.map((file, i) => {
                                                        return <SupplierUploaded key={i} isUploading={isUploading} fileInfo={file} onDeleteFile={this.handleDeleteFile} />;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-rows ${supplier.handle_customs === "No" ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">

                                                    <div className="form-group col-9 col-md-9 col-lg-6">
                                                        <div className="mrn-table">
                                                            <div className="mrn-cell" style={{ 'width': '70px' }}>HS Code: </div>
                                                            <div className="mrn-cell">
                                                                <input type="text" className={`form-control ${(errors.hsCode) ? 'input-error' : ''}`} name="hsCode" value={this.state.hsCode} maxLength={8} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                <InputError error={errors.hsCode} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group col-3 col-md-3 col-lg-6">
                                                        <button type="button" className="btn btn-block btn-ebooking-orange" style={{ "maxWidth": "90px" }} onClick={this.addHsCode}>Add <i className="playIcon"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.buildHSCode()
                                    }

                                    <div className={`form-rows mt-4 ${supplier.handle_customs === "No" ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">

                                                    <div className="form-group col-12 col-md-9 col-lg-6">
                                                        <div className="mrn-table">
                                                            <div className="mrn-cell" style={{ 'width': '70px' }}>EROI #: </div>
                                                            <div className="mrn-cell">
                                                                <input type="text" className={`form-control ${(errors.EROI) ? 'input-error' : ''}`} id="EROI" name="EROI" maxLength={17} value={supplier.EROI} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                <a href="https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp?Lang=en" target="_blank"><small className="note-text">Validate EORI</small></a>
                                                                <InputError error={errors.EROI} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-rows mt-3">

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="form-group col-12">
                                                        <label htmlFor="weight" className="text-white">Country Of Origin</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-12 col-md-9 col-lg-6">
                                                        <Select
                                                            placeholder="Choose Country"
                                                            inputId="countryOfOrigine"
                                                            autoComplete="off"
                                                            className={`select-box huge-input ${(errors.countryOfOrigine) ? 'input-error' : ''}`}
                                                            value={selectedCountryOfOrigine}
                                                            isSearchable={true}
                                                            isDisabled={false}
                                                            onChange={this.handleChangeCountryOfOrigine}
                                                            options={countries}
                                                            isLoading={isLoadingCountry}
                                                            onBlur={this.handleOnBlurSelect}
                                                        />
                                                        <EbookingInputError error={errors.countryOfOrigine} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container block-form mt-5">
                        <div className="dv-button-footer div_center text-center">
                            <button type="button" className="btn btn-lg btn-orange" disabled={(!supplier.api_checked || isUploading) ? true : false} onClick={this.handSubmit}>Next Step</button>
                            <button type="button" className="btn btn-lg btn-link just-text">Two steps left</button>
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SpecialIntructionOne);