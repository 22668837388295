import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Validator
 */
import validator from 'validator';
import * as MESSAGES from '../../../libs/Messages';
import ReactTooltip from "react-tooltip";

export default class PopperItemConsignee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            styles: { popper: {}, arrow: {} },
            attributes: { popper: {} },
            tooltipID: uuidv4()
        }
    }


    componentDidMount() {
        window.loadTooltip();
    }
    render() {
        return (
            <div className="text-center">
                <span data-tip data-for={this.state.tooltipID}>{this.props.show === 'cnor' ? this.props.data.cnor : this.props.data.cnee}</span>
                <ReactTooltip id={this.state.tooltipID} aria-haspopup='true' place="bottom" effect="float" role='example'>
                    <p><b>Consignee: </b> {this.props.data.cnee}</p><p><b>Consignor: </b> {this.props.data.cnor}</p>
                </ReactTooltip>
            </div>
        );
    }
}

PopperItemConsignee.propTypes = {
    data: PropTypes.object
};