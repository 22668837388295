import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import BookingHelper from '../../BookingHelper';
/**
 * Validator
 */
import validator from 'validator';
import * as MESSAGES from '../../../libs/Messages';

export default class ShipmentItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            focusPieces: false,
            focusLength: false,
            focusWidth: false,
            focusHeight: false,
            selectedPaltype: null
        }
    }

    componentDidMount() {
        var { focusPieces, focusLength, focusWidth, focusHeight, selectedPaltype } = this.state;
        var { data } = this.props;
        if (data.edit) {
            if (data.Pcs === '') {
                focusPieces = true;
            }
            if (data.Length === '') {
                focusLength = true;
            }
            if (data.Width === '') {
                focusWidth = true;
            }
            if (data.Height === '') {
                focusHeight = true;
            }
        }
        if (this.props.isUld) {
            if (this.props.data.paltype === 'LOOSE') {
                selectedPaltype = { value: 'LOOSE', label: 'LOOSE' };
            } else if (this.props.data.paltype === 'HD') {
                selectedPaltype = { value: 'HD', label: 'HD' };
            } else if (this.props.data.paltype === 'HDS') {
                selectedPaltype = { value: 'HDS', label: 'HDS' };
            } else if (this.props.data.paltype === 'LD') {
                selectedPaltype = { value: 'LD', label: 'LD' };
            } else if (this.props.data.paltype === 'LDS') {
                selectedPaltype = { value: 'LDS', label: 'LDS' };
            } else if (this.props.data.paltype === 'MD') {
                selectedPaltype = { value: 'MD', label: 'MD' };
            } else if (this.props.data.paltype === 'MDS') {
                selectedPaltype = { value: 'MDS', label: 'MDS' };
            }
        }
        this.setState({ data: this.props.data, focusPieces, focusLength, focusWidth, focusHeight, selectedPaltype });
    }

    componentDidUpdate(oldProps) {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight } = this.state;
        if (oldProps.isUld != this.props.isUld) {
            errors.paltype = '';
            errors.Pcs = '';
            errors.Length = '';
            errors.Width = '';
            errors.Height = '';
            if (!this.props.isUld && this.props.data.edit) {
                if (this.props.data.Pcs === '') {
                    focusPieces = true;
                }
                if (this.props.data.Length === '') {
                    focusLength = true;
                }
                if (this.props.data.Width === '') {
                    focusWidth = true;
                }
                if (this.props.data.Height === '') {
                    focusHeight = true;
                }
            } else {
                focusPieces = true;
                focusLength = true;
                focusWidth = true;
                focusHeight = true;
            }
            this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
        }
    }

    checkErrors = () => {
        // console.log("vao day roi");
        var { errors } = this.state;
        errors = {};
        if (validator.isEmpty(this.props.data.Pcs + "") || !validator.isInt(this.props.data.Pcs + "") || parseInt(this.props.data.Pcs) <= 0) {
            errors["Pcs"] = MESSAGES.ERROR_REQUIRED;
        }
        if (validator.isEmpty(this.props.data.Length + "") || !validator.isNumeric(this.props.data.Length + "") || parseFloat(this.props.data.Length) <= 0) {
            errors["Length"] = MESSAGES.ERROR_REQUIRED;
        }
        if (validator.isEmpty(this.props.data.Width + "") || !validator.isNumeric(this.props.data.Width + "") || parseFloat(this.props.data.Width) <= 0) {
            errors["Width"] = MESSAGES.ERROR_REQUIRED;
        }
        if (validator.isEmpty(this.props.data.Height + "") || !validator.isNumeric(this.props.data.Height + "") || parseFloat(this.props.data.Height) <= 0) {
            errors["Height"] = MESSAGES.ERROR_REQUIRED;
        }
        // console.log(errors);
        this.setState({ errors });
    }

    clearError = () => {
        var { errors } = this.state;
        errors = {};
        this.setState({ errors });
    }

    setAllFocus = () => {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight } = this.state;
        errors = {};
        focusPieces = true;
        focusLength = true;
        focusWidth = true;
        focusHeight = true;
        this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });

    }

    handleChange = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var { unitname } = this.props;
        // value = value.toUpperCase();
        if (name === 'Pcs') {
            value = value.replace(/[^0-9]/g, "");
        } else {
            value = value.replace(/[^0-9.]/g, "");
        }
        var t = this;
        errors[name] = "";
        value = BookingHelper.roundOff(value, 5);
        this.props.data[name] = value;
        this.props.data.Cbm = BookingHelper.calculatorVolumeNew(unitname, this.props.data.Length, this.props.data.Width, this.props.data.Height);
        this.setState({ errors });
        this.props.onUpdate(this.props.dataIndex, this.props.data);
    }

    handleOnBlur = async (event) => {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        errors[name] = "";
        if (name === 'Pcs') {
            if (value === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
        }
        if (name === 'Length') {
            if (value === '') {
                focusLength = true;
            } else {
                focusLength = false;
            }
        }
        if (name === 'Width') {
            if (value === '') {
                focusWidth = true;
            } else {
                focusWidth = false;
            }
        }
        if (name === 'Height') {
            if (value === '') {
                focusHeight = true;
            } else {
                focusHeight = false;
            }
        }
        this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
        // this.props.onUpdate(this.props.dataIndex, this.props.data);
    }

    handleOnFocusField = async (feildName, e) => {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight } = this.state;
        var { data, isUld } = this.props;
        errors[feildName] = '';
        if (feildName === 'Pcs') {
            focusPieces = true;
            await this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
            if (this.txtPieces) {
                this.txtPieces.focus();
            }
        } else if (feildName === 'Length' && (!isUld || (isUld && data.paltype && data.paltype === 'LOOSE'))) {
            focusLength = true;
            await this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
            if (this.txtLength) {
                this.txtLength.focus();
            }
        } else if (feildName === 'Width' && (!isUld || (isUld && data.paltype && data.paltype === 'LOOSE'))) {
            focusWidth = true;
            await this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
            if (this.txtWidth) {
                this.txtWidth.focus();
            }
        } else if (feildName === 'Height' && (!isUld || (isUld && data.paltype && data.paltype === 'LOOSE'))) {
            focusHeight = true;
            await this.setState({ errors, focusPieces, focusLength, focusWidth, focusHeight });
            if (this.txtHeight) {
                this.txtHeight.focus();
            }
        }
    }

    handleOnBlurSelect = async (event) => {
        var { errors } = this.state;
        var { target, target: { id, name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        errors[name] = "";
        this.setState({ errors });
    }

    handleChangeUld = async (selectedItem) => {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight, selectedPaltype } = this.state;
        var { unitname } = this.props;
        errors.paltype = '';
        errors.Pcs = '';
        errors.Length = '';
        errors.Width = '';
        errors.Height = '';
        this.setState({ errors });
        if (Array.isArray(selectedItem) && !selectedItem.length) {
            selectedItem = null;
        }
        this.props.data.paltype = selectedItem.value;
        selectedPaltype = selectedItem;
        if (selectedItem && selectedItem.value === 'LOOSE') {
            this.props.data.Pcs = "";
            this.props.data.Length = "";
            this.props.data.Width = "";
            this.props.data.Height = "";
            this.props.data.Cbm = "";
            focusPieces = true;
            focusLength = true;
            focusWidth = true;
            focusHeight = true;
        } else if (selectedItem && selectedItem.value === 'HD') {
            this.props.data.Length = "317";
            this.props.data.Width = "244";
            this.props.data.Height = "300";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }

            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else if (selectedItem && selectedItem.value === 'HDS') {
            this.props.data.Length = "120";
            this.props.data.Width = "100";
            this.props.data.Height = "300";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else if (selectedItem && selectedItem.value === 'LD') {
            this.props.data.Length = "317";
            this.props.data.Width = "244";
            this.props.data.Height = "160";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else if (selectedItem && selectedItem.value === 'LDS') {
            this.props.data.Length = "120";
            this.props.data.Width = "100";
            this.props.data.Height = "160";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else if (selectedItem && selectedItem.value === 'MD') {
            this.props.data.Length = "317";
            this.props.data.Width = "244";
            this.props.data.Height = "240";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else if (selectedItem && selectedItem.value === 'MDS') {
            this.props.data.Length = "120";
            this.props.data.Width = "100";
            this.props.data.Height = "240";
            this.props.data.Cbm = "";
            if (this.props.data.Pcs === '') {
                focusPieces = true;
            } else {
                focusPieces = false;
            }
            focusLength = false;
            focusWidth = false;
            focusHeight = false;
        } else {
            this.props.data.Length = "";
            this.props.data.Width = "";
            this.props.data.Height = "";
            this.props.data.Cbm = "";
            focusPieces = true;
            focusLength = true;
            focusWidth = true;
            focusHeight = true;
        }
        this.props.data.Cbm = BookingHelper.calculatorVolumeNew(unitname, this.props.data.Pcs, this.props.data.Length, this.props.data.Width, this.props.data.Height);
        this.props.onUpdate(this.props.dataIndex, this.props.data);
        this.setState({ focusPieces, focusLength, focusWidth, focusHeight, selectedPaltype });
    }


    render() {
        var { errors, focusPieces, focusLength, focusWidth, focusHeight, selectedPaltype } = this.state;
        var { data, isUld, unitname } = this.props;
        if (isUld) {
            return (
                <div className="col-12">
                    <div className={`quote-flex edit shipment uld display-flex shadow mb-2`}>
                        <div className={`input-group-quote no-radius group-1 p-0 ${!data.edit ? 'bg-grey' : ''}`}>
                            <Select
                                placeholder={``}
                                className={`select-box huge-input`}
                                isSearchable={false}
                                onChange={this.handleChangeUld}
                                onBlur={this.handleOnBlurSelect}
                                options={[{ value: "LOOSE", label: "Loose" }, { value: "HD", label: "HD" }, { value: "HDS", label: "HD Skid" }, { value: "LD", label: "LD" }, { value: "LDS", label: "LD Skid" }, { value: "MD", label: "MD" }, { value: "MDS", label: "MD Skid" }]}
                                value={selectedPaltype}
                            />
                        </div>
                        <div className={`input-group-quote no-radius group-2 ${errors.Pcs ? 'p-0' : ''} ${(!data.paltype) ? 'bg-grey' : ''}`}>
                            <label className={`${(focusPieces) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Pcs')}>{data.Pcs}</label>
                            <input type="text" ref={(input) => { this.txtPieces = input; }} className={`input-value ${(focusPieces) ? '' : 'd-none'} ${errors.Pcs ? 'input-error' : ''}`} name="Pcs" maxLength={5} disabled={(!selectedPaltype ? true : false)} value={data.Pcs} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-3 ${errors.Length ? 'p-0' : ''} ${(!selectedPaltype || selectedPaltype.value != "LOOSE") ? 'bg-grey' : ''}`}>
                            <label className={`${(focusLength) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Length')}>{data.Length} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtLength = input; }} className={`input-value ${(focusLength) ? '' : 'd-none'} ${errors.Length ? 'input-error' : ''}`} name="Length" maxLength={9} disabled={(!selectedPaltype || selectedPaltype.value !== 'LOOSE') ? true : false} value={data.Length} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-3-1 ${errors.Width ? 'p-0' : ''} ${(!selectedPaltype || selectedPaltype.value != "LOOSE") ? 'bg-grey' : ''}`}>
                            <label className={`${(focusWidth) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Width')}>{data.Width} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtWidth = input; }} className={`input-value ${(focusWidth) ? '' : 'd-none'} ${errors.Width ? 'input-error' : ''}`} name="Width" maxLength={9} disabled={(!selectedPaltype || selectedPaltype.value !== 'LOOSE') ? true : false} value={data.Width} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-3-2 ${errors.Height ? 'p-0' : ''} ${(!selectedPaltype || selectedPaltype.value != "LOOSE") ? 'bg-grey' : ''}`}>
                            <label className={`${(focusHeight) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Height')}>{data.Height} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtHeight = input; }} className={`input-value ${(focusHeight) ? '' : 'd-none'} ${errors.Height ? 'input-error' : ''}`} name="Height" maxLength={9} disabled={(!selectedPaltype || selectedPaltype.value !== 'LOOSE') ? true : false} value={data.Height} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-4 bg-grey`}>
                            <label className="bg-grey">{BookingHelper.roundOff3Decimal(data.Cbm, 3)} <span>{data.Cbm ? 'CBM' : ''}</span></label>
                            <div className="appened-buttons"><button type="button" className="delete-button" onClick={this.props.onDelete.bind(this, this.props.dataIndex)}></button></div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    <div className={`quote-flex edit shipment display-flex shadow mb-2`}>
                        <div className={`input-group-quote no-radius group-1 ${errors.Pcs ? 'p-0' : ''}`}>
                            {/* <label className={`${errors.Pcs ? 'input-error' : ''} ${(focusPieces || data.Pcs ==='') ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Pcs')}>{data.Pcs}</label> */}
                            <input type="text" className={`input-value ${errors.Pcs ? 'input-error' : ''}`} name="Pcs" maxLength={5} value={data.Pcs} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-2 ${errors.Length ? 'p-0' : ''}`}>
                            <label className={`${errors.Length ? 'input-error' : ''} ${(focusLength) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Length')}>{data.Length ? data.Length : 0} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtLength = input; }} className={`input-value ${(focusLength) ? '' : 'd-none'} ${errors.Length ? 'input-error' : ''}`} name="Length" maxLength={9} value={data.Length} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-3 ${errors.Width ? 'p-0' : ''}`}>
                            <label className={`${errors.Width ? 'input-error' : ''} ${(focusWidth) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Width')}>{data.Width ? data.Width : 0} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtWidth = input; }} className={`input-value ${(focusWidth) ? '' : 'd-none'} ${errors.Width ? 'input-error' : ''}`} name="Width" maxLength={9} value={data.Width} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-3-1 ${errors.Height ? 'p-0' : ''}`}>
                            <label className={`${errors.Height ? 'input-error' : ''} ${(focusHeight) ? 'd-none' : ''}`} onClick={this.handleOnFocusField.bind(this, 'Height')}>{data.Height ? data.Height : 0} <span>{unitname}</span></label>
                            <input type="text" ref={(input) => { this.txtHeight = input; }} className={`input-value ${(focusHeight) ? '' : 'd-none'} ${errors.Height ? 'input-error' : ''}`} name="Height" maxLength={9} value={data.Height} onBlur={this.handleOnBlur} onChange={this.handleChange}></input>
                        </div>
                        <div className={`input-group-quote no-radius group-4 bg-grey`}>
                            <label className="bg-grey">{BookingHelper.roundOff3Decimal(data.Cbm, 3)} <span>{data.Cbm ? 'CBM' : ''}</span></label>
                            <div className="appened-buttons"><button type="button" className="delete-button" onClick={this.props.onDelete.bind(this, this.props.dataIndex)}></button></div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

ShipmentItem.propTypes = {
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    dataIndex: PropTypes.number,
    data: PropTypes.object,
    isUld: PropTypes.bool,
    unitname: PropTypes.string
};