import * as ActionTypes from '../actionTypes';

const initialState = {
    ErrorCode: null,
    ErrorDes: null,
    Success: true,
    cash: "",
    cass: "",
    cassname: "",
    credit: "",
    cur: "",
    cust: 0,
    custdes: "",
    custname: "",
    customertype: "",
    iatacode: "",
    leadname: "",
    user_email: "",
    user_name: "",
    user_sub: "",
    user_username: "",
    jwtToken: "",
    state: "LOGGING_IN",
    expiredToken: false,
    IsGSA: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.AUTH_RESET: {
            var defaultState = {
                ErrorCode: null,
                ErrorDes: null,
                Success: true,
                cash: "",
                cass: "",
                cassname: "",
                credit: "",
                cur: "",
                cust: 0,
                custdes: "",
                custname: "",
                customertype: "",
                iatacode: "",
                leadname: "",
                user_email: state.user_email,
                user_name: "",
                user_sub: "",
                user_username: "",
                jwtToken: "",
                state: "LOGGED_OUT",
                expiredToken: false,
                IsGSA: false
            };
            return Object.assign({}, state, defaultState)
        }

        case ActionTypes.CLICK_LOG_OUT: {
            var defaultState = {
                ErrorCode: null,
                ErrorDes: null,
                Success: true,
                cash: "",
                cass: "",
                cassname: "",
                credit: "",
                cur: "",
                cust: 0,
                custdes: "",
                custname: "",
                customertype: "",
                iatacode: "",
                leadname: "",
                user_email: "",
                user_name: "",
                user_sub: "",
                user_username: "",
                jwtToken: "",
                state: "CLICK_LOG_OUT",
                expiredToken: false,
                IsGSA: false
            };
            return Object.assign({}, state, defaultState)
        }

        case ActionTypes.AUTH_CHANGE_DATA: {
            var newState = { ...state };
            newState[action.payload.fieldName] = action.payload.fieldValue;            
            return Object.assign({}, state, newState)
        }

        case ActionTypes.GETTING_DATA: {
            var newState = { ...state };
            newState["jwtToken"] = action.payload;
            newState["state"] = ActionTypes.GETTING_DATA;            
            return Object.assign({}, state, newState)
        }

        case ActionTypes.AUTH_USER_DATA: {
            var geted_data = action.payload;
            geted_data.jwtToken = state.jwtToken;
            geted_data["state"] = ActionTypes.LOGGED_IN;
            if(geted_data.iatacode == null || geted_data.iatacode.trim() == ""){
                geted_data.iatacode = "";
            }
            
            if(geted_data.cur == null || geted_data.cur.trim() == ""){
                geted_data.cur = "USD";
            }
            if(geted_data.customertype !== null && geted_data.customertype.trim() == "GSA"){
                geted_data.IsGSA = true;
            }
            // if(geted_data.user_email == "manhhung86it@gmail.com"){
            //     geted_data.cash = "Y";
            //     geted_data.cass = "";
            //     geted_data.credit = "";
            // }           
            return Object.assign({}, state, geted_data)
        }

        case ActionTypes.AUTH_UPDATE_STATE: {
            var newState = { ...state };
            newState.state = action.payload;                  
            return Object.assign({}, state, newState)
        }
    }

    return state;
}