import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import uuid from 'uuid';
import moment from 'moment';
import axios from 'axios';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

/**
 * Uploaded file
 */
import ClaimUploaded from './ClaimUploaded';
import ClaimUpload from './ClaimUpload';

class ClaimSubmit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            isEdit: false,
            errors: {},
            isShowDocument: false,
            errorText: "",
            submitted: 1,
            claim: {
                uploading: [],
                ClaimFiles: [
                ],
            },
            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'New Claim'
                },
            ],
            steps: [
                {
                    'link': 'new-claim',
                    'active': false,
                    'validate': false,
                    'name': 'Basic Info'
                },
                {
                    'link': 'claim-detail',
                    'active': false,
                    'validate': false,
                    'name': 'Claim Details'
                },
                {
                    'link': 'claim-submit',
                    'active': true,
                    'validate': true,
                    'name': 'Submit Documents'
                },
            ],
            styles: {
                'borderWidth': '2px',
                'background': '#f2f3f5',
                'borderColor': '#dbdcde',
                'borderStyle': 'dashed',
                'width': '645px',
                'height': '215px',
                'display': 'table-cell',
                'textAlign': 'center',
                'verticalAlign': 'middle',
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handSubmit = this.handSubmit.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.handleNewFile = this.handleNewFile.bind(this);
        this.handleDeleteUpload = this.handleDeleteUpload.bind(this);
        this.clickAddBtn = this.clickAddBtn.bind(this);
    }

    handleClearDate = () => {
        const { claim } = this.state;
        claim.flight_date = '';
        this.setState({ claim });
    }

    showDocument = () => {
        this.setState({ isShowDocument: true });
    }

    gotoSuccess = () => {
        this.props.history.push('/claim-success')
    }

    hideDocument = () => {
        this.setState({ isShowDocument: false });
    }

    handleChangeDate = (v) => {
        const { claim } = this.state;
        claim.flight_date = v.format('YYYY/MM/DD');
        this.setState({ claim });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //get redux data and set to state
        var { claim } = this.props;

        if (!claim.uploadFolder) {
            claim.uploadFolder = uuid();
            this.props.claimActions.claimChangeData({ fieldName: 'uploadFolder', fieldValue: claim.uploadFolder }).then(responseData => { });
        }

        if (!claim.ClaimFiles.length && !claim.uploading.length) {
            claim.uploading.push(uuid());
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
            this.setState({ claim });
        }
        this.setState({ claim });

        const { history } = this.props;

        //check previuos step ok
        if (!claim.step_1_ok) {
            history.push('/claim');
        } else if (!claim.step_2_ok) {
            history.push('/claim-detail');
        }

        document.title = "Claims | Challenge Group"
    }

    /* Handle subbmit form */
    handleChange(event) {
        var { claim, errors } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        if (type === 'checkbox') {
            const check_checked = target.checked;
            value = check_checked ? 1 : 0;
            claim[name] = value;
            this.props.claimActions.claimChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });

            if (name == 'condition') {
                if (!check_checked) {
                    errors['condition'] = MESSAGES.ERROR_CONDITION;
                } else {
                    errors['condition'] = '';
                }
            }
        } else {
            claim[name] = value;
            this.props.claimActions.claimChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        }
        this.setState({ claim });
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { claim } = this.state;

        if (claim.condition != 1) {
            errors['condition'] = MESSAGES.ERROR_CONDITION;
            isError = true;
        }

        if (claim.ClaimFiles.length <= 0) {
            errors['upload_file'] = MESSAGES.ERROR_UPLOAD;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            this.props.claimActions.claimChangeData({ fieldName: "step_3_ok", fieldValue: false }).then(responseData => { });
            return false;
        }

        this.props.claimActions.claimChangeData({ fieldName: "step_3_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    handSubmit() {
        const { history } = this.props;
        let { errorText } = this.state;
        const { claim } = this.state;
        //check previuos step ok
        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        if (claim.step_1_ok && claim.step_2_ok) {

            for (let i = 0; i < claim.ClaimAspect.length; i++) {
                claim.ClaimAspect[i].quant = claim.ClaimAspect[i].quant ? parseInt(claim.ClaimAspect[i].quant) : null;
                claim.ClaimAspect[i].weight = claim.ClaimAspect[i].weight ? parseFloat(claim.ClaimAspect[i].weight) : null;

            }

            for (let i = 0; i < claim.ClaimAmount.length; i++) {
                switch (claim.ClaimAmount[i].amountcodename) {
                    case 'claim_amount':
                        claim.ClaimAmount[i].amountcodename = 'Claim Amount';
                        break;
                    case 'invoice_value':
                        claim.ClaimAmount[i].amountcodename = 'Invoice Value';
                        break;
                    case 'cost_of_repair':
                        claim.ClaimAmount[i].amountcodename = 'Cost of Repair';
                        break;
                    case 'cost_of_salvage':
                        claim.ClaimAmount[i].amountcodename = 'Cost of Salvage';
                        break;
                }
                claim.ClaimAmount[i].amount = claim.ClaimAmount[i].amount ? parseFloat(claim.ClaimAmount[i].amount) : null;
            }

            var submitClaim = {};
            submitClaim.name = claim.name;
            submitClaim.phonenum = claim.phonenum;
            submitClaim.awbnum = claim.awbnum;
            submitClaim.email = claim.email;
            submitClaim.claimertype = claim.claimertype;
            submitClaim.claimtype = claim.claimtype;
            submitClaim.insurance = claim.insurance;
            submitClaim.Insured = claim.Insured;
            submitClaim.NG = claim.NG;
            submitClaim.text = claim.text;
            submitClaim.ClaimAspect = claim.ClaimAspect;
            submitClaim.ClaimAmount = claim.ClaimAmount;
            submitClaim.ClaimFiles = claim.ClaimFiles;
            submitClaim.ClaimNum = claim.ClaimNum;
            submitClaim.uploadFolder = claim.uploadFolder;
            submitClaim.cargoType = claim.cargo_type;

            if (!claim.ClaimNum || claim.ClaimNum == "") {
                errorText = "";
                this.setState({ errorText });
                this.props.loadingActions.loadingChangeData(true);
                this.props.claimActions.addClaim(submitClaim).then(response => {
                    if (response.success && response.data.Success) {
                        this.props.loadingActions.loadingChangeData(false);
                        this.props.claimActions.claimChangeData({ fieldName: "ClaimNumSuccess", fieldValue: response.data.ClaimNum }).then(responseData => { });
                        history.push('/claim-success');
                    } else {
                        this.resendData(submitClaim);
                    }
                });
            } else {

                this.props.loadingActions.loadingChangeData(true);
                this.props.claimActions.updateClaim(submitClaim).then(response => {
                    if (response.success && response.data.Success) {
                        this.props.loadingActions.loadingChangeData(false);
                        this.props.claimActions.claimChangeData({ fieldName: "ClaimNumSuccess", fieldValue: response.data.ClaimNum }).then(responseData => { });
                        history.push('/claim-success');
                    } else {
                        this.resendData(submitClaim);
                    }
                });
            }

        } else {
            if (!claim.step_1_ok) {
                history.push('/claim');
            } else if (!claim.step_2_ok) {
                history.push('/claim-detail');
            }
        }
    }

    resendData = (submitClaim) => {
        const { history } = this.props;
        let { errorText } = this.state;
        errorText = "";
        var { submitted } = this.state;
        submitted++;
        this.setState({ submitted: submitted });
        this.props.claimActions.addClaim(submitClaim).then(response => {
            if (response.success && response.data.Success) {
                this.props.loadingActions.loadingChangeData(false);
                this.props.claimActions.claimChangeData({ fieldName: "ClaimNumSuccess", fieldValue: response.data.ClaimNum }).then(responseData => { });
                history.push('/claim-success');
            } else {
                if (submitted < 3) {
                    this.resendData(submitClaim);
                } else {
                    this.props.loadingActions.loadingChangeData(false);
                    errorText = MESSAGES.ERROR_SAVING;
                    this.setState({ errorText });
                }

            }
        });
    }

    handleDeleteFile(name, i) {
        const { claim } = this.state;
        claim.ClaimFiles.splice(i, 1);
        if (!claim.ClaimFiles.length) {
            claim.uploading.push(uuid());
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
        }
        this.setState({ claim });
        this.props.claimActions.claimChangeData({ fieldName: 'ClaimFiles', fieldValue: claim.ClaimFiles }).then(responseData => { });

    }

    handleDeleteUpload(id) {
        var { claim } = this.state;
        var result = claim.uploading.filter(w => w != id);
        claim.uploading = result;
        this.setState({ claim });
        this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
    }

    handleNewFile = (filedata, id) => {
        // console.log(filedata);
        var { errors, claim } = this.state;
        errors['upload_file'] = '';
        claim.ClaimFiles.push(filedata);
        this.setState({ claim, errors });
        this.props.claimActions.claimChangeData({ fieldName: 'ClaimFiles', fieldValue: claim.ClaimFiles }).then(responseData => { });
        this.handleDeleteUpload(id);
    }

    clickAddBtn(event) {
        var { claim } = this.state;
        if ((claim.ClaimFiles.length) < 15 && !claim.uploading.length) {
            claim.uploading.push(uuid());
            this.setState({ claim });
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
        }

    }

    render() {
        const { claim, breadcrumbs, steps, isError, errors, errorMessage, errorText } = this.state;
        // console.log("claim", claim);
        return (
            <div className="container claim-form">
                <div className='form-title'>NEW CLAIM</div>
                <Step stepData={steps} onClick={this.handClickStep} />
                <div className="block-form claim-form">
                    <form>
                        <h2 className="detail">Submit Documents</h2>
                        {(errorText != "") ? (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {errorText}
                                </div>
                            </div>
                        ) : (null)}
                        <a className="claim-link hide" href='javascript:void(0)' onClick={this.showDocument}>Document Submitting Guidlines </a>
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            Please upload each document as a separate file. Accepted file types: .doc, .pdf, .png, .jpeg. Max file size: 9MB.<br />
                            Select the relevant document type from the dropdown menu. You can upload maximum 15 files. You can add additional documents later, using the 'Claim Update' page.
                            <button type="button" className="close hide" onClick={this.hideDocument}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="row justify-content-between">
                            <div className="col-sm-6 col-md-7 form-rows">
                                {
                                    claim.ClaimFiles.map((file, i) => {
                                        return <ClaimUploaded key={i} fileInfo={file} fileIndex={i} onDeleteFile={this.handleDeleteFile} />;

                                    })
                                }

                                {
                                    claim.uploading.map((id, i) => {
                                        return (
                                            <ClaimUpload key={i} ClaimNum={claim.ClaimNum} fileIndex={id} subFolder={claim.uploadFolder} onSuccess={this.handleNewFile} />
                                        );
                                    })
                                }
                                <div>
                                    <a className="claim-upload-add-btn" onClick={this.clickAddBtn}></a>
                                </div>


                                <InputError error={errors.upload_file} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div>
                                    Please make sure you receive a confirmation email regarding your claim. If you did not receive a confirmation email, you can contact us using <a href="https://www.cal-cargo.com/contact/" target="_blank">this link</a>.
                                </div>
                                <div className="dv-agree">
                                    <span><input type="checkbox" name="condition" id="cbAgreeClaim" checked={(claim.condition == 1) ? true : false} onChange={this.handleChange} value="1" /></span>
                                    <p className="pb-3 ">
                                        <label htmlFor="cbAgreeClaim" className={(errors.condition) ? 'input-error' : ''}>
                                            I have read & accepted the
                                            <a className="" href="https://www.cal-cargo.com/terms-conditions/" target="_blank"> terms & conditions</a> and <a className="" href="https://www.cal-cargo.com/privacy-policy/" target="_blank"> privacy policy.</a>
                                        </label>
                                        <InputError error={errors.condition} />
                                    </p>
                                </div>
                                <div></div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-continues" onClick={this.handSubmit}>Submit Claim</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        claimActions: bindActionCreators(claimActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSubmit);