import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class Footer extends React.Component {
    render() {
        const { showMenuMobile, className } = this.props;
        return (
            <div className={` footer${showMenuMobile ? ' move-right' : ''}`}>
                <div className={`${className}`}>
                    <div className="container">
                        <div className="footer-nav">
                            <ul>
                                <li><a href="https://www.cal-cargo.com/terms-conditions/" target="_blank">Terms & Conditions</a></li>
                                <li><a href="https://www.cal-cargo.com/privacy-policy/" target="_blank">Privacy</a></li>
                                <li><a href="https://www.cal-cargo.com/about-us/about-the-cal-group/" target="_blank">About Us</a></li>
                                <li><a href="https://www.cal-cargo.com/contact-us/" target="_blank">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="copyrights">
                            <div className="copyright">
                                Copyright © {moment().format('YYYY')} CAL. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer;
