import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
import PropTypes from 'prop-types';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class FlightPricingAdhoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            CProfNum: "",
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': false,
                    'validate': false,
                    'name': 'Shipment Details'
                },
                {
                    'link': 'ebooking/flight-pricing-default',
                    'active': true,
                    'validate': true,
                    'name': 'Ad-Hoc'
                }
            ]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
    }


    componentDidMount() {
        //get redux data and set to state   
        var { ebooking } = this.props;
        var cloneEbooking = { ...ebooking };
        this.setState({ CProfNum: cloneEbooking.PriceQuotation.CProfNum ? cloneEbooking.PriceQuotation.CProfNum : "" });
        this.props.ebookingActions.ebookingReset().then(res => { });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    /* handler form's fields change value */
    handleChange(event) {
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        return true;
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        window.jumpToTop();
        const { history } = this.props;
        history.push('/ebooking');
    }

    render() {
        var authState = this.props.auth.state;
        const { CProfNum } = this.state;
        return (

            <div className="ebooking">
                <div className="container">
                    <div className="ad-hoc-body">
                        <div className="form-content">
                            <div className="content-text-desc text-center">Wait a minute</div>
                            <div className="content-title-medium text-center text-blue mt-3" style={{ "maxWidth": "420px", "margin": "0px auto" }}>We Need to do This in Person</div>
                            <div className="content-text-desc text-center mt-3" style={{ "maxWidth": "470px", "margin": "0px auto" }}>It looks like your booking is special enough to require human attention.</div>
                            <div className="content-text-desc text-center mt-3" style={{ "maxWidth": "530px", "margin": "0px auto" }}>We sent you an email with your reference number and will be in touch with you very shortly.</div>
                            <div className="content-text-desc text-center mt-3" style={{ "maxWidth": "445px", "margin": "0px auto" }}>Your Reference Number is: <span>{CProfNum}</span></div>
                            <div className="div_center text-center mt-5">
                                <div className="display-inline-block">
                                    <div className="display-inline float-left mr-3 d-none d-md-block">
                                        <Link to={`${process.env.PUBLIC_URL}/dashboard/`} className={`btn-back-to-dashboard`}>Back to Dashboard</Link>
                                    </div>
                                    <div className="display-inline float-left">
                                        <button type="button" onClick={this.handSubmit} disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} className="btn btn-ebooking-orange">I want to make another shipment</button>
                                        <div className="clb"></div>
                                        <Link to={`${process.env.PUBLIC_URL}/dashboard/`} className={`btn-back-to-dashboard div-center d-block d-md-none`}>Back to Dashboard</Link>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightPricingAdhoc);