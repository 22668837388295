import React from 'react';
import { Link } from 'react-router-dom';

export default class Breadcrumb extends React.Component {
        constructor(props) {
		super(props);
	}
    render() {
        const {  breadcrumbData } = this.props;
        
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>
                    {breadcrumbData.map((breadcrumb, i) => {     
                        if(breadcrumb.active){
                            return (<li key={i} className="breadcrumb-item active" aria-current="page">{breadcrumb.name}</li>) 
                        }else{
                            return (<li key={i} className="breadcrumb-item" aria-current="page"><Link to={`${process.env.PUBLIC_URL}/${breadcrumb.link}`}>{breadcrumb.name}</Link></li>) 
                        }
                                           
                     })}
                   
                </ol>
            </nav>
        );
    }
}

