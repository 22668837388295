export default {
    apiUrl: 'https://kgvonigyhi.execute-api.eu-west-1.amazonaws.com/dev/api/service/',
    apiCalUrl: 'https://02tttkg1si.execute-api.eu-west-1.amazonaws.com/dev/api/service/',
    apiUpload: 'https://kgvonigyhi.execute-api.eu-west-1.amazonaws.com/dev/',
    paymeMerchantAPIKey: "MPL15650-89635FZD-YS7QXWFN-ETGWZZBQ",
    testMode: true,
    validateETD: false,
    classOptions: [
        { label: "1. Explosives", value: '1. Explosives' },
        { label: "2. Gases", value: '2. Gases' },
        { label: "3. Flammable Liquids", value: '3. Flammable Liquids' },
        { label: "4. Flammable Solids", value: '4. Flammable Solids' },
        { label: "5. Oxidizing Substances", value: '5. Oxidizing Substances' },
        { label: "6. Toxic & Infectious Substances", value: '6. Toxic & Infectious Substances' },
        { label: "7. Radioactive Material", value: '7. Radioactive Material' },
        { label: "8. Corrosives", value: '8. Corrosives' },
        { label: "9. Miscellaneous Dangerous Goods", value: '9. Miscellaneous Dangerous Goods' }
    ],
    denideRefuse: {
        better_offer: 'We received a better offer',
        unsatisfactory: 'Service was unsatisfactory',
        ship_any_more: 'The shipment was canceled',
        other: 'Other',
    },
    dayRangeGetFlight: 21,
    defaultCurrency: 'NIS',
    uldOptions: [
        // { value: "LOOSE", label: "Loose" }, 
        { value: "HD", label: "HD" }, { value: "HDS", label: "HD Skid" }, { value: "LD", label: "LD" }, { value: "LDS", label: "LD Skid" }, { value: "MD", label: "MD" }, { value: "MDS", label: "MD Skid" }],
    unitTypeOptions: [
        { label: 'Pieces', value: 'pieces' },
        { label: 'ULD', value: 'uld' }
    ],
    unitLengthOptions: [
        { label: 'Cm', value: 'cm' },
        { label: 'Inch', value: 'in' }
    ],
    totalVolumeOptions: [{ value: "Lump Sum", label: "Lump Sum" }, { value: "Per Kg CHW", label: "Per Kg CHW" }, { value: "Per Kg ACW", label: "Per Kg ACW" }],
    chargableOptions: [{ label: "Totals", value: 'totals' }, { label: "Per Chargeable", value: 'Per Kg CHW' }, { label: "Per Actual", value: 'per_actual' }, { label: "Per Volume", value: 'per_volume' }],
    acceptBookStatus: ['APPROVED BY CUSTOMER', 'APPROVED BY AGENT', 'APPROVED'],
    disableEditStatus: ['decline', 'canceled','cancel', 'creating quote', 'booked'],
    maxShipmentItems: 100
}