import React from 'react';
import PropTypes from 'prop-types';

class DatePickerInput extends React.Component {

    render() {
        return (
                <div style={divStyle}>
                    <input className={this.props.className} type="text" value={this.props.value} onClick={this.props.onClick} onChange={this.handleChange} />
                    {this.props.value ? <a onClick={this.props.onClickClear} className="btn waves-effect waves-light btn-remove-date">
                        <i className="fa fa-close text-white"></i>
                    </a> : ''}
                </div>
                )
    }
}

const divStyle = {
    minWidth: '200px',
    position: 'relative'
};

DatePickerInput.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    onClickClear: PropTypes.func
};

export default DatePickerInput;