import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Uploaded file
 */
import FilesUpload from '../../components/FilesUpload';
import FilesUploaded from '../../components/FilesUploaded';

export default class ModalUploadFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxUploadFiles: 10,
            uploadedFiles: [],
            uploadingFiles: [],
            errors: [],
            subFolder: uuidv4(),
            errorMessage: ''
        };
    }

    async componentDidMount() {
        var { uploadingFiles, uploadedFiles } = this.state;
        uploadingFiles.push(uuidv4());
        this.setState({ uploadingFiles, uploadedFiles });
    }

    // delete uploaded files
    handleDeleteFile = async (name, i) => {
        var { uploadedFiles, uploadingFiles, maxUploadFiles } = this.state;
        uploadedFiles.splice(i, 1);
        if (uploadedFiles.length < maxUploadFiles && !uploadingFiles.length) {
            uploadingFiles.push(uuidv4());
        }
        this.setState({ uploadedFiles, uploadingFiles });
    }

    // delete file uploading
    handleDeleteUpload = async (id) => {
        var { uploadingFiles } = this.state;
        var result = uploadingFiles.filter(w => w != id);
        this.setState({ uploadingFiles: result })
    }

    // new uploaded file
    handleNewFile = async (filedata, id) => {
        var { errors, uploadedFiles, uploadingFiles, maxUploadFiles } = this.state;
        errors['upload_file'] = '';
        uploadedFiles.push(filedata);

        if ((uploadedFiles.length + uploadingFiles.length) <= maxUploadFiles) {
            uploadingFiles.push(uuidv4());
        }
        this.setState({ errors, uploadedFiles, uploadingFiles });
        this.handleDeleteUpload(id);
    }

    handleSubmit = async () => {
        var { uploadedFiles } = this.state;
        this.props.handleConfirm(uploadedFiles);
    }

    handleShowError = (textMessage) => {
        this.setState({ errorMessage: textMessage });
    }

    render() {
        const { onShow } = this.props;
        const { uploadedFiles, uploadingFiles, subFolder, errorMessage } = this.state;
        if (!onShow)
            return (null);

        return (
            <div className="modal-dialog modal-dialog-large modal-dialog-centered" role="document">
                <div className="modal-content quotes-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                            <h5>Attachments</h5>
                        </div>

                        {errorMessage ? (
                            <div className={`alert alert-danger`}>
                                {errorMessage}
                                <button type="button" className="close" onClick={() => { this.handleShowError('') }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div className="row mt-3">
                            <div className="col-12">
                                {
                                    uploadedFiles.map((file, i) => {
                                        return <FilesUploaded key={i} fileInfo={file} fileIndex={i} onDeleteFile={this.handleDeleteFile} />;
                                    })
                                }

                                {
                                    uploadingFiles.map((id, i) => {
                                        return (
                                            <FilesUpload key={i} fileIndex={id} subFolder={subFolder} onShowError={this.handleShowError} onSuccess={this.handleNewFile} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description" style={{ 'maxWidth': '350px' }}>
                                    Please upload each document as a separate file. <br />
                                    Accepted file types:<br />
                                    .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .pdf, .png, .jpg, jpeg. Max file size 9MB</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <div className="row" style={{ minWidth: '240px' }}>
                            <div className="col-6"><button type="button" className="btn btn-cancel btn-block" onClick={this.props.closeModal}>Cancel</button></div>
                            <div className="col-6"><button type="button" className="btn btn-continues btn-block" onClick={this.handleSubmit}>Submit</button></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
