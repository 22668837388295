import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import BookingHelper from '../BookingHelper';
import clone from 'clone';
import { v4 as uuidv4 } from 'uuid';
import ReactDOM from "react-dom";
import validator from 'validator';
import AppConfig from '../../AppConfig';
import * as ActionTypes from '../../ninja-redux/actionTypes';
/**
 * Validator
 */
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as dashboardActions from '../../ninja-redux/actions/dashboardActions';

import Modal from 'react-modal';
import ModalChatCounter from "../../layouts/partials/ModalChatCounter";
import ModalShowMessage from "../../layouts/partials/ModalShowMessage";
import QuotesChatOfferItem from './element/QuotesChatOfferItem';
import ModalSampleConfirm from "../../layouts/partials/ModalSampleConfirm";
import ModalQuoteActionError from "../../layouts/partials/ModalQuoteActionError";
import ModalUploadFiles from "../../layouts/partials/ModalUploadFiles";
import $ from 'jquery';

class NegotiationQuotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            cprof: "",
            secretKey: '',
            modalIsOpen: false,
            modalDenideSuccessIsOpen: false,
            modalWarningDeclineIsOpen: false,
            chats: [],
            itmChat: {},
            currency: null,
            currencies: [],
            isShowSuccessMsg: false,
            textMessage: "",
            successMessage: "",
            modalError: false,
            listChatUpdated: [],
            pickup_dropoff_time: null,
            pickup_dropoff_date: null,
            selected_flight: null,
            selectedDecline: null,
            modalConfirmIsOpen: false,
            modalQuoteErrorIsOpen: false,
            modalUploadFiles: false,
            chatId: "",
            countries: [],
            countriesZip: [],
            isDanderShow: false,
            isParticipant: false,
            quotes: {
                deliver_from: 'Airport',
                deliver_from_country: null,
                deliver_from_airport: null,
                deliver_from_city: '',
                deliver_from_zipcode: '',
                deliver_to: 'Airport',
                deliver_to_country: null,
                deliver_to_airport: null,
                deliver_to_city: '',
                deliver_to_zipcode: '',
                pickup_dropoff: { value: 'drop_off', label: 'Dropoff Time (Local)' },
                pickupOptions: [{ value: 'drop_off', label: 'Dropoff Time (Local)' }],
                pickup_dropoff_date: null,
                pickup_dropoff_time: null,
                unit_length: { value: 'cm', label: 'Cm' },
                offer_price_list: [],
                offer_total: 0,
                selected_flight: null,
                receiving_company_options: [],
                dg_class: []
            },
            loadedScreen: false,
            commodities: [],
            subCommodities: [],
            bidExp: [],
            DDC: [],
            shippmentChat: false
        };
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    componentDidMount = async () => {
        document.title = "Negotiation - Quotes Portal";
        setTimeout(
            () => this.updateDimensions(),
            2000
        );
        this.updateDimensions();
    }

    componentDidUpdate = async (oldProps) => {
        var { loadedScreen } = this.state;
        var { country, port } = this.props;
        if (country.countries.length && port.ports.length && !loadedScreen) {
            this.initScreen();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        if (this.intervalGetChat) {
            clearInterval(this.intervalGetChat);
        }
    }

    updateDimensions = () => {
        var windowHeight = window.innerHeight;
        this.setState({ windowHeight });
        var windowWidth = window.innerWidth;
        var thisNode = ReactDOM.findDOMNode(this.refs.mainBox);
        var formBox = ReactDOM.findDOMNode(this.refs.formBox);
        if (thisNode) {
            formBox.style.minHeight = windowHeight + "px";
        }
    };

    initScreen = async () => {
        this.setState({ loadedScreen: true });
        let propQuote = this.props.quotes;
        // console.log(propQuote);
        var cprof = this.props.match.params.cprof;
        var secretKey = this.props.match.params.secretKey;
        var { countries, countriesZip, currency, quotes } = this.state;
        var { country, port, currencies } = this.props;
        await this.setState({ cprof, secretKey });
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);

        let listCurrencies = currencies.data;
        if (!currencies.data.length || currencies.state === ActionTypes.CARGO_CURRENCIES_GETTING_DATA) {
            listCurrencies = await this.props.negotiationActions.getCurrencies();
        }
        countries = [];
        countriesZip = [];
        country.countries.forEach(c => {
            // if (c.forwebsite) {
            var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
            var countryZip = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
            if (c.airportonly) {
                countryZip.label = c.countryname + " (airport only)";
            }
            countriesZip.push(countryZip);
            countries.push(country);
            // }
        });

        quotes.customerTypes = [];
        quotes.subCommodityOptions = [];
        quotes.shippment_option = { value: 'PTP', label: 'PTP' };
        quotes.deliver_from = 'Airport';
        quotes.deliver_from_country = null;
        quotes.deliver_from_airport = null;
        quotes.deliver_from_city = '';
        quotes.deliver_from_zipcode = '';
        quotes.deliver_to = 'Airport';
        quotes.deliver_to_country = null;
        quotes.deliver_to_airport = null;
        quotes.deliver_to_city = '';
        quotes.deliver_to_zipcode = '';
        quotes.pickup_dropoff = { value: 'drop_off', label: 'Dropoff Time (Local)' };
        quotes.pickupOptions = [{ value: 'drop_off', label: 'Dropoff Time (Local)' }];
        quotes.pickup_dropoff_date = null;
        quotes.pickup_dropoff_time = null;
        quotes.offer_total_type = { label: "Totals", value: 'totals' };
        quotes.cost_total_type = { label: "Totals", value: 'totals' };
        quotes.cost_total_type_2 = { label: "Totals", value: 'totals' };
        quotes.dg_class = [];

        if (parsedParams.p && parsedParams.p === 'shipment') {
            this.setState({ shippmentChat: true });
            let pqData = null;
            if (!propQuote.shipmentData) {
                let getShipmentData = await this.props.dashboardActions.getManagerPage({ days: 365 }, this.props.auth.jwtToken, false);
                if (getShipmentData.success && getShipmentData.data.AWB) {
                    let AWBData = getShipmentData.data.AWB;
                    let findCprofData = AWBData.find(el => el.PQ.CProfAU + "" === cprof);
                    if (findCprofData) {
                        pqData = findCprofData.PQ;
                    }
                }
            } else {
                pqData = propQuote.shipmentData.pq;
            }
            // console.log(pqData);
            if (pqData) {
                quotes.customer = { CUSTDES: this.props.auth.custdes }
                quotes.CProfNum = pqData.CProfNum;


                quotes.deliver_from_country = { value: pqData.FromCountryCode.trim(), label: pqData.FromCountryCode.trim() }; //check again
                quotes.deliver_from_airport = { value: pqData.FromPortCode.trim(), label: pqData.FromPortCode.trim() }; //check again
                quotes.deliver_from_city = pqData.FromCity.trim();
                quotes.deliver_from_zipcode = pqData.FromZipCode.trim();
                quotes.deliver_to_country = { value: pqData.ToCountryCode.trim(), label: pqData.ToCountryCode.trim() }; //check again
                quotes.deliver_to_airport = { value: pqData.ToPortCode.trim(), label: pqData.ToPortCode.trim() }; //check again
                quotes.deliver_to_city = pqData.ToCity.trim();
                quotes.deliver_to_zipcode = pqData.ToZipCode.trim();

                if (quotes.deliver_from_zipcode) {
                    quotes.deliver_from = 'ZipCode';
                }
                if (quotes.deliver_to_zipcode) {
                    quotes.deliver_to = 'ZipCode';
                }
                if (pqData.Commodity.trim()) {
                    quotes.commodity = { value: pqData.Commodity, label: pqData.Commodity }
                }
                if (pqData.SubCommodity.trim()) {
                    quotes.sub_commodity = { value: pqData.SubCommodity, label: pqData.SubCommodity }
                }

                quotes.unit_total = { total_pieces: pqData.Pieces, total_volume: pqData.Volume, total_weight: pqData.Weight, tcw: pqData.CWeight, total_volume_kg: BookingHelper.calculatorVolumeKG(parseFloat(pqData.Volume)) };
                this.setState({ quotes, countries, countriesZip });
                this.getChatItems(false);
            } else {
                var errors = {};
                errors.wrongCProf = "Not found quote";
                this.setState({ errors });
            }
        } else {
            let getCProf = null;
            let loadCustomers;
            let customersData = [];
            this.props.loadingActions.loadingChangeData(true);
            let getDCC = await this.props.negotiationActions.getDC('P');
            if (getDCC && getDCC.success && getDCC.data && getDCC.data.Comodities) {
                this.setState({ DDC: getDCC.data.Comodities });
            }

            this.setState({ currencies: listCurrencies, countriesZip, countries });
            if (listCurrencies.length) {
                let getProposal = await this.props.negotiationActions.getDynamoProposal(secretKey);
                if (getProposal.success && getProposal.data.Success && getProposal.data.data.length) {
                    var startDate = moment();
                    startDate.subtract(2, 'months');
                    var toDate = moment();
                    toDate.add(5, 'days');
                    getCProf = await this.props.negotiationActions.getCProf({ fromdate: startDate.format('DD/MM/YY'), todate: toDate.format('DD/MM/YY'), CPROF: cprof, SIM: 'N', userb: 0 });
                } else {
                    this.props.loadingActions.loadingChangeData(false);
                    //show Error
                    var errors = {};
                    errors.wrongCProf = "Not found quote";
                    this.setState({ errors });
                }

                let quotesData = null;
                if (getCProf && getCProf.success && getCProf.data.Success && getCProf.data.cp.length) {
                    quotesData = getCProf.data.cp.find(cp => cp.CProfAU + "" === cprof);
                    quotes.apiData = quotesData;
                }

                if (quotesData && getProposal) {
                    this.props.loadingActions.loadingChangeData(true);
                    let getCProfDetails = await this.props.negotiationActions.getCProfDetails({ CPROF: cprof });
                    if (getCProfDetails.success && getCProfDetails.data.Success) {
                        this.props.loadingActions.loadingChangeData(true);

                        let SHIPNAME = quotesData.Participents.SHIPNAME.trim();
                        let RECNAME = quotesData.Participents.RECNAME.trim();
                        let CNORNAME = quotesData.Participents.CNORNAME.trim();
                        let CNEENAME = quotesData.Participents.CNEENAME.trim();
                        let PICKCOMPNAME = quotesData.Participents.PICKCOMPNAME.trim();
                        let DELNAME = quotesData.Participents.DELNAME.trim();
                        let DELCOMPNAME = quotesData.Participents.DELCOMPNAME.trim();
                        //step 1        
                        quotes.status = quotesData.sitestat;
                        quotes.usercode = quotesData.usercode;
                        quotes.showEditIcon = true;
                        quotes.CProfAU = quotesData.CProfAU;
                        quotes.CProfNum = quotesData.CProfNum;
                        quotes.express_delivery = quotesData.MustRide.trim() === 'Y' ? true : false;
                        let CUSTNAME = quotesData.custname.trim();
                        if (CUSTNAME) {
                            let checkSearched = customersData.find(cd => cd.name === CUSTNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.props.negotiationActions.scanCust(CUSTNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: CUSTNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CUSTNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        quotes.customer = findCustomer;
                                    }
                                } else {
                                    customersData.push({
                                        name: CUSTNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === CUSTNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    quotes.customer = findCustomer;
                                }
                            }
                        }

                        quotes.offer_currency = null;
                        // console.log(listCurrencies);
                        if (quotes.customer && quotes.customer.CURRENCY) {
                            quotes.offer_currency = listCurrencies.find(c => c.label === quotes.customer.CURRENCY);
                        } else {
                            quotes.offer_currency = listCurrencies.find(c => c.label === AppConfig.defaultCurrency);
                        }

                        quotes.shippment_option = quotesData.SaleTypeCode.trim() ? { value: quotesData.SaleTypeCode.trim(), label: quotesData.SaleTypeCode.trim() } : null;
                        quotes.deliver_from_country = { value: quotesData.FromCountryCode.trim(), label: quotesData.FromCountryCode.trim() }; //check again
                        quotes.deliver_from_airport = { value: quotesData.FromPortCode.trim(), label: quotesData.FromPortCode.trim() }; //check again
                        quotes.deliver_from_city = quotesData.FromCity.trim();
                        quotes.deliver_from_zipcode = quotesData.FromZipCode.trim();
                        quotes.deliver_to_country = { value: quotesData.ToCountryCode.trim(), label: quotesData.ToCountryCode.trim() }; //check again
                        quotes.deliver_to_airport = { value: quotesData.ToPortCode.trim(), label: quotesData.ToPortCode.trim() }; //check again
                        quotes.deliver_to_city = quotesData.ToCity.trim();
                        quotes.deliver_to_zipcode = quotesData.ToZipCode.trim();
                        // quotes.pickup_dropoff = (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'PTD') ? { value: 'drop_off', label: 'Drop Off' } : { value: 'pickup', label: 'Pickup' };
                        quotes.pickup_dropoff_time = (quotesData.ETDOrigin.trim() !== '' && quotesData.ETDOrigin.trim() !== '01/01/88 00:00') ? moment(quotesData.ETDOrigin, 'DD/MM/YY HH:mm') : null;
                        quotes.pickup_dropoff_date = (quotesData.ETDOrigin.trim() !== '' && quotesData.ETDOrigin.trim() !== '01/01/88 00:00') ? moment(quotesData.ETDOrigin, 'DD/MM/YY HH:mm') : null;
                        quotes.t1 = quotesData.T1 ? true : false;
                        switch (quotesData.SaleTypeCode) {
                            case "PTP":
                                quotes.deliver_from = 'Airport';
                                quotes.deliver_to = 'Airport';
                                quotes.pickupOptions = [{ value: 'drop_off', label: 'Dropoff Time (Local)' }];
                                break;
                            case "PTD":
                                quotes.deliver_from = 'Airport';
                                quotes.deliver_to = 'ZipCode';
                                quotes.pickupOptions = [{ value: 'drop_off', label: 'Dropoff Time (Local)' }];
                                break;
                            case "DTP":
                                quotes.deliver_from = 'ZipCode';
                                quotes.deliver_to = 'Airport';
                                quotes.pickupOptions = [{ value: 'pickup', label: 'Pickup Time (Local)' }];
                                break;
                            case "DTD":
                                quotes.deliver_from = 'ZipCode';
                                quotes.deliver_to = 'ZipCode';
                                quotes.pickupOptions = [{ value: 'pickup', label: 'Pickup Time (Local)' }];
                                break;
                        }
                        quotes.pickup_dropoff = quotes.pickupOptions[0];

                        if (countries.length) {
                            var findCountryFrom = null;
                            if (quotesData.FromPortCode.trim()) {
                                findCountryFrom = countries.find(c => c.labelhiden === quotes.deliver_from_country.value);
                            } else {
                                findCountryFrom = countriesZip.find(c => c.labelhiden === quotes.deliver_from_country.value);
                            }
                            if (findCountryFrom) quotes.deliver_from_country = findCountryFrom;

                            var findCountryTo = null;
                            if (quotesData.ToPortCode.trim()) {
                                findCountryTo = countries.find(c => c.labelhiden === quotes.deliver_to_country.value);
                            } else {
                                findCountryTo = countriesZip.find(c => c.labelhiden === quotes.deliver_to_country.value);
                            }
                            if (findCountryTo) quotes.deliver_to_country = findCountryTo;
                        }

                        if (port.ports.length) {
                            if (quotesData.FromPortCode.trim()) {
                                var findAirportFrom = port.ports.find(el => el.value === quotesData.FromPortCode.trim());
                                if (findAirportFrom) {
                                    quotes.deliver_from_airport = findAirportFrom;
                                }
                            }
                            if (quotesData.ToPortCode.trim()) {
                                var findAirportTo = port.ports.find(el => el.value === quotesData.ToPortCode.trim());
                                if (findAirportTo) {
                                    quotes.deliver_to_airport = findAirportTo;
                                }
                            }
                        }
                        quotes.overlap = quotesData.Overlap.trim() === 'Y' ? true : false;

                        quotes.unit_length = { value: 'cm', label: 'Cm' };

                        var selectedFlight = null;
                        if (getCProfDetails.data.leg.length) {
                            selectedFlight = {
                                "fleg": getCProfDetails.data.leg[0],
                                "vialeg": { "leg": 0, "fnumber": null, "fport": null, "tport": null, "std": null, "sta": null },
                                "tleg": { "leg": 0, "fnumber": null, "fport": null, "tport": null, "std": null, "sta": null }
                            };
                            if (getCProfDetails.data.leg.length == 2) {
                                selectedFlight.tleg = getCProfDetails.data.leg[1];
                            }
                            if (getCProfDetails.data.leg.length > 2) {
                                selectedFlight.vialeg = getCProfDetails.data.leg[2];
                            }
                            var list_flights = [selectedFlight];
                            quotes.list_flight = list_flights;
                        }
                        quotes.selected_flight = selectedFlight;
                        quotes.unit_items = [];
                        if (getCProfDetails.data.uld.length) {
                            quotes.unit_type = { label: 'ULD', value: 'uld' };
                            getCProfDetails.data.uld.forEach(unit => {
                                quotes.unit_length = { value: 'cm', label: 'Cm' };
                                var findUld = AppConfig.uldOptions.find(uld => uld.value === unit.paltype);
                                var itemUnit = { item_uld: (findUld ? findUld : null), item_pieces: unit.Pcs, item_length: '', item_width: '', item_height: '', item_volume: '', edit: false };
                                if (findUld && findUld.value === 'HD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "300";
                                } else if (findUld && findUld.value === 'HDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "300";
                                } else if (findUld && findUld.value === 'LD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "160";
                                } else if (findUld && findUld.value === 'LDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "160";
                                } else if (findUld && findUld.value === 'MD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "240";
                                } else if (findUld && findUld.value === 'MDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "240";
                                }
                                itemUnit.item_volume = BookingHelper.calculatorVolume(itemUnit.item_pieces, itemUnit.item_length, itemUnit.item_width, itemUnit.item_height);
                                quotes.unit_items.push(itemUnit);
                            })
                        } else {
                            quotes.unit_type = { value: 'pieces', label: 'Pieces' };
                            getCProfDetails.data.Dimensions.forEach(unit => {
                                if (unit.unitname === 'CM') {
                                    quotes.unit_length = { value: 'cm', label: 'Cm' };
                                } else {
                                    quotes.unit_length = { value: 'in', label: 'Inch' };
                                }
                                let cbm = BookingHelper.calculatorVolume(unit.Pcs, unit.Length, unit.Width, unit.Height);
                                if (unit.paltype) {
                                    var findUld = AppConfig.uldOptions.find(uld => uld.value === unit.paltype);
                                    quotes.unit_items.push({ item_uld: (findUld ? findUld : null), item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                                } else {
                                    quotes.unit_items.push({ item_uld: null, item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                                }
                            })
                        }

                        quotes.unit_total = { total_pieces: quotesData.Pieces, total_volume: quotesData.Volume, total_weight: quotesData.Weight, tcw: quotesData.CWeight, total_volume_kg: BookingHelper.calculatorVolumeKG(parseFloat(quotesData.Volume)) };
                        getCProfDetails.data.cls.forEach(cla => {
                            var findClass = AppConfig.classOptions.find(cl => cl.value === cla.text);
                            if (findClass) {
                                quotes.dg_class.push(findClass);
                            }
                        })
                        let internal_text = getCProfDetails.data.ic.join(" ");
                        internal_text = internal_text.replace(/<br?\/?>/g, "\n");
                        internal_text = internal_text.replace(/(<([^>]+)>)/gi, ""); //remove html tags
                        quotes.internal_text = internal_text;

                        let uns = "";
                        for (let i = 0; i < getCProfDetails.data.un.length; i++) {
                            const un = getCProfDetails.data.un[i];
                            if (i < getCProfDetails.data.un.length - 1) {
                                uns += un.text + "\n";
                            } else {
                                uns += un.text;
                            }
                        }
                        quotes.dg_un = uns;
                        let additional_information = "";
                        for (let i = 0; i < getCProfDetails.data.add.length; i++) {
                            const addElement = getCProfDetails.data.add[i];
                            additional_information += addElement.text;

                        }
                        quotes.additional_information = additional_information;

                        quotes.handling = [];
                        for (let i = 0; i < getCProfDetails.data.hs.length; i++) {
                            const hsElement = getCProfDetails.data.hs[i];
                            if (hsElement.Delivertill && hsElement.Delivertill !== '' && hsElement.Delivertill !== '01/01/88 00:00') {
                                hsElement.DelivertillDate = moment(hsElement.Delivertill, 'DD/MM/YY HH:mm');
                            }
                            quotes.handling.push({ value: hsElement.Code, label: hsElement.Descreption, Delivertill: hsElement.DelivertillDate ? hsElement.DelivertillDate : null });
                        }

                        quotes.commodity = quotesData.Commodity.trim() ? { label: quotesData.Commodity.trim(), value: quotesData.Commodity.trim() } : null;
                        quotes.sub_commodity = quotesData.SubCommodity.trim() ? { label: quotesData.SubCommodity.trim(), value: quotesData.SubCommodity.trim() } : null;

                        if (quotes.commodity) {
                            var commodities = [];
                            var subCommodities = [];
                            var getCommodities = await this.props.ebookingActions.getCommodity(0);
                            if (getCommodities.success && getCommodities.data.Comodities) {
                                commodities = getCommodities.data.Comodities;
                                this.setState({ commodities });
                            }

                            let findSelectedCommodity = commodities.find(c => (c.PartName === quotes.commodity.label || c.PartDes === quotes.commodity.label));
                            if (findSelectedCommodity) {
                                quotes.commodity = { au: findSelectedCommodity.PartAU, label: findSelectedCommodity.PartDes, value: findSelectedCommodity.PartName };
                                if (quotes.sub_commodity) {
                                    var findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                    if (findSelectedSubCommodity) {
                                        quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                    } else {
                                        quotes.subCommodityOptions = [];
                                        var loadCommodites = await this.props.ebookingActions.getCommodity(quotes.commodity.au);
                                        if (loadCommodites.success && loadCommodites.data.Success) {
                                            quotes.subCommodityOptions = loadCommodites.data.Comodities;
                                            subCommodities = quotes.subCommodityOptions;
                                            this.setState({ subCommodities });
                                        }
                                        findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                        if (findSelectedSubCommodity) {
                                            quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                        } else {
                                            if (quotes.subCommodityOptions.length == 1) {
                                                quotes.sub_commodity = { au: quotes.subCommodityOptions[0].PartAU, label: quotes.subCommodityOptions[0].PartDes, value: quotes.subCommodityOptions[0].PartName };;
                                            } else {
                                                quotes.sub_commodity = null;
                                            }
                                        }
                                    }
                                }
                            } else {
                                quotes.commodity = null;
                                quotes.sub_commodity = null;
                                quotes.subCommodityOptions = [];
                            }
                        } else {
                            quotes.commodity = null;
                            quotes.sub_commodity = null;
                            quotes.subCommodityOptions = [];
                        }

                        quotes.offer_price_list = [];

                        let firstOfferCalculation = null;
                        if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                            firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                        } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                            firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                        } else if (quotesData.calcmethod.trim()) {
                            firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                        }

                        let firstCurrencySelected = listCurrencies.find(c => c.label === quotesData.currency);
                        var firstPrice = parseFloat(quotesData.Price);
                        if (firstPrice > 0) {
                            quotes.offer_price_list.push({ items: (quotes.sub_commodity ? { value: quotes.sub_commodity.value, label: quotes.sub_commodity.value } : null), calculation_method: firstOfferCalculation, currency: firstCurrencySelected ? firstCurrencySelected : null, price: quotesData.Price, edit: false });
                        }

                        //get bid exp
                        let getBidExp = await this.props.negotiationActions.getBidExp({ cprof: cprof });
                        if (getBidExp.success && getBidExp.data.det.length) {
                            this.setState({ bidExp: getBidExp.data.det })
                            getBidExp.data.det.forEach(det => {
                                let checkOptionExist = getDCC.data.Comodities.find(dc => dc.PartName === det.subcom);
                                if (!checkOptionExist) {
                                    getDCC.data.Comodities.push({ PartAU: null, PartDes: det.subcomdes, PartName: det.subcom, label: det.subcom, value: det.subcom });
                                }
                            });
                        }

                        for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                            const cpElement = getCProfDetails.data.cp[i];
                            let itemSelected = getDCC.data.Comodities.find(po => po.PartName === cpElement.subcom);
                            let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                            let currencySelected = listCurrencies.find(c => c.label === cpElement.curr);
                            if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                                methodSelected = { value: "Per Unit", label: "Per Unit" };
                            } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                                methodSelected = { value: "Per ULD", label: "Per ULD" };
                            }
                            quotes.offer_price_list.push({ items: itemSelected ? { label: itemSelected.PartName, value: itemSelected.PartName } : null, calculation_method: methodSelected ? methodSelected : null, currency: currencySelected ? currencySelected : null, price: cpElement.price, edit: true });
                        }

                        quotes.cost_price_list = [];

                        quotes.proposal_expises = (quotesData.enddate.trim() !== '' && quotesData.enddate.trim() !== '01/01/88 00:00') ? moment(quotesData.enddate, 'DD/MM/YY HH:mm') : moment().add(2, 'day');
                        if (!quotes.offer_price_list.length) {
                            quotes.offer_price_list.push({ items: null, calculation_method: null, currency: null, price: '', edit: true });
                        }

                        quotes.offer_all_in = quotesData.allin.trim() === 'Y' ? true : false;
                        let findCalcmethod = AppConfig.chargableOptions.find(co => co.label === quotesData.calcmethod);
                        quotes.cost_total_type_2 = findCalcmethod ? findCalcmethod : { label: "Totals", value: 'totals' };

                        //Step 4            
                        quotes.is_dangerous = (quotesData.Litium.trim() === 'Y' || getCProfDetails.data.cls.length) ? true : false;
                        quotes.is_lithium = quotesData.Litium.trim() === 'Y' ? true : false;
                        quotes.is_shipment_unstackable = quotesData.Unstackable.trim() === 'Y' ? true : false;
                        quotes.dg_cao_pax = quotesData.caopax.trim() ? 'PAX' : 'CAO';

                        if (SHIPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === SHIPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.props.negotiationActions.scanCust(SHIPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: SHIPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomerShipname = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === SHIPNAME.toUpperCase()
                                    });
                                    if (findCustomerShipname) {
                                        quotes.shipping_company = findCustomerShipname;
                                        quotes.shipping_company_options = [findCustomerShipname];
                                    }
                                } else {
                                    customersData.push({
                                        name: SHIPNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomerShipname = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === SHIPNAME.toUpperCase()
                                });
                                if (findCustomerShipname) {
                                    quotes.shipping_company = findCustomerShipname;
                                    quotes.shipping_company_options = [findCustomerShipname];
                                }
                            }

                        }

                        quotes.receiving_company_options = [];
                        if (RECNAME && quotes.customer) {
                            var getREC = await this.props.negotiationActions.getParticipent({ custname: quotes.customer ? quotes.customer.CUSTNAME : '', type: 'REC' });
                            if (getREC.success && getREC.data.Success) {
                                getREC.data.customer.map(c => {
                                    quotes.receiving_company_options.push({ value: c.Cust, label: c.Custdes + " (" + c.Countryname + "," + c.cityname + ")", labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.receivingParticipants.push({ value: c.Cust, label: c.Custdes + " (" + c.Countryname + "," + c.cityname + ")", labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findCustomerRec = quotes.receiving_company_options.find(c => c.labelhiden.toUpperCase() === RECNAME.toUpperCase());
                            if (findCustomerRec) {
                                quotes.receiving_company = findCustomerRec;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === RECNAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.props.negotiationActions.scanCust(RECNAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: RECNAME,
                                            data: loadCustomers.data.data
                                        });
                                        findCustomerRec = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === RECNAME.toUpperCase()
                                        });
                                        if (findCustomerRec) {
                                            quotes.receiving_company = findCustomerRec;
                                            quotes.receiving_company_options = [findCustomerRec];
                                        }
                                    } else {
                                        customersData.push({
                                            name: RECNAME,
                                            data: []
                                        });
                                    }
                                } else {
                                    findCustomerRec = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === RECNAME.toUpperCase()
                                    });
                                    if (findCustomerRec) {
                                        quotes.receiving_company = findCustomerRec;
                                        quotes.receiving_company_options = [findCustomerRec];
                                    }
                                }

                            }
                        }
                        quotes.consignee_company_options = [];
                        quotes.consignor_company_options = [];
                        if (CNEENAME && quotes.customer) {
                            var getCNEE = await this.props.negotiationActions.getParticipent({ custname: quotes.customer.CUSTNAME, type: 'CNEE' });
                            if (getCNEE.success && getCNEE.data.Success) {
                                getCNEE.data.customer.map(c => {
                                    quotes.consignee_company_options.push({ value: c.Cust, label: c.Custdes + " - " + c.Countryname, labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.consigneeParticipants.push({ value: c.Cust, label: c.Custdes + " - " + c.Countryname, labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findConsignee = quotes.consignee_company_options.find(c => c.labelhiden === CNEENAME);
                            if (findConsignee) {
                                quotes.consignee_company = findConsignee;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === CNEENAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.props.negotiationActions.scanCust(CNEENAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: CNEENAME,
                                            data: loadCustomers.data.data
                                        });
                                        findConsignee = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === CNEENAME.toUpperCase()
                                        });
                                        if (findConsignee) {
                                            quotes.consignee_company = findConsignee;
                                            quotes.consignee_company_options = [findConsignee];
                                        }
                                    } else {
                                        customersData.push({
                                            name: CNEENAME,
                                            data: []
                                        });
                                    }
                                } else {
                                    findConsignee = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CNEENAME.toUpperCase()
                                    });
                                    if (findConsignee) {
                                        quotes.consignee_company = findConsignee;
                                        quotes.consignee_company_options = [findConsignee];
                                    }
                                }

                            }
                        }
                        if (CNORNAME && quotes.customer) {
                            var getCNOR = await this.props.negotiationActions.getParticipent({ custname: quotes.customer.CUSTNAME, type: 'CNOR' });
                            if (getCNOR.success && getCNOR.data.Success) {
                                getCNOR.data.customer.map(c => {
                                    quotes.consignor_company_options.push({ value: c.Cust, label: c.Custdes + " - " + c.Countryname, labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.consignorParticipants.push({ value: c.Cust, label: c.Custdes + " - " + c.Countryname, labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findConsignor = quotes.consignor_company_options.find(c => c.labelhiden === CNORNAME);
                            if (findConsignor) {
                                quotes.consignor_company = findConsignor;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === CNORNAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.props.negotiationActions.scanCust(CNORNAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: CNORNAME,
                                            data: loadCustomers.data.data
                                        });
                                        findConsignor = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === CNORNAME.toUpperCase()
                                        });
                                        if (findConsignor) {
                                            quotes.consignor_company = findConsignor;
                                            quotes.consignor_company_options = [findConsignor];
                                        }
                                    } else {
                                        customersData.push({
                                            name: CNORNAME,
                                            data: []
                                        });
                                    }
                                } else {
                                    findConsignor = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CNORNAME.toUpperCase()
                                    });
                                    if (findConsignor) {
                                        quotes.consignor_company = findConsignor;
                                        quotes.consignor_company_options = [findConsignor];
                                    }
                                }

                            }
                        }
                        if (PICKCOMPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === PICKCOMPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.props.negotiationActions.scanCust(PICKCOMPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: PICKCOMPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => c.CUSTDES.toUpperCase() === PICKCOMPNAME.toUpperCase());
                                    if (findCustomer) {
                                        quotes.airline = findCustomer;
                                        quotes.airline_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: PICKCOMPNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => c.CUSTDES.toUpperCase() === PICKCOMPNAME.toUpperCase());
                                if (findCustomer) {
                                    quotes.airline = findCustomer;
                                    quotes.airline_options = [findCustomer];
                                }
                            }

                        }
                        if (DELNAME) {
                            let checkSearched = customersData.find(cd => cd.name === DELNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.props.negotiationActions.scanCust(DELNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: DELNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === DELNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        quotes.gsa = findCustomer;
                                        quotes.gsa_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: DELNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === DELNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    quotes.gsa = findCustomer;
                                    quotes.gsa_options = [findCustomer];
                                }
                            }

                        }
                        if (DELCOMPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === DELCOMPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.props.negotiationActions.scanCust(DELCOMPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: DELCOMPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === DELCOMPNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        quotes.feeder_gsa = findCustomer;
                                        quotes.feeder_gsa_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: DELCOMPNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === DELCOMPNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    quotes.feeder_gsa = findCustomer;
                                    quotes.feeder_gsa_options = [findCustomer];
                                }
                            }

                        }

                        var totalVolume = 0;
                        var needUpdate = false;
                        quotes.unit_items = quotes.unit_items.map(item => {
                            if (!validator.isEmpty(item.item_pieces + "") || !validator.isEmpty(item.item_length + "") || !validator.isEmpty(item.item_width + "") || !validator.isEmpty(item.item_height + "")) {
                                needUpdate = true;
                            }
                            if (((!validator.isEmpty(item.item_length + "") && validator.isNumeric(item.item_length + ""))) && ((!validator.isEmpty(item.item_width + "") && validator.isNumeric(item.item_width + ""))) && ((!validator.isEmpty(item.item_height + "") && validator.isNumeric(item.item_height + "")))) {
                                item.item_volume = BookingHelper.calculatorVolumeNew(quotes.unit_length.value, item.item_length, item.item_width, item.item_height);
                                totalVolume += (parseInt(item.item_pieces) * parseFloat(item.item_volume));
                            }
                            return item;
                        });

                        if (!needUpdate) {
                            totalVolume = parseFloat(totalVolume);
                        }

                        var totalsValues = BookingHelper.calculatorTotal(quotes.offer_price_list, quotes.offer_total_type, quotes.cost_price_list, quotes.cost_total_type, quotes.cost_total_type_2, quotes.unit_total, quotes.offer_currency);
                        quotes.offer_total = totalsValues.offer_total;
                        quotes.cost_total = totalsValues.cost_total;
                        quotes.cost_total_2 = totalsValues.cost_total_2;
                        quotes.step_1 = true;
                        quotes.step_2 = true;

                        quotes.unit_total = { total_pieces: quotesData.Pieces, total_volume: totalVolume, total_weight: quotesData.Weight, tcw: quotesData.CWeight, total_volume_kg: BookingHelper.calculatorVolumeKG(parseFloat(totalVolume)) };
                        if (quotes.offer_price_list.length && quotes.offer_total) {
                            quotes.step_3 = true;
                        }
                        quotes.step_4 = true;
                        quotes.step_5 = true;

                        this.props.loadingActions.loadingChangeData(false);

                        if (selectedFlight) {
                            var getFlightParams = {};
                            var compareDate = moment();
                            compareDate.set('hour', 0);
                            compareDate.set('minute', 0);
                            compareDate.set('second', 0);

                            if (quotes.pickup_dropoff_date) {
                                let fromDate = clone(quotes.pickup_dropoff_date);
                                fromDate.add(1, 'days');

                                let toDate = clone(quotes.pickup_dropoff_date);
                                toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                                getFlightParams.fromdate = quotes.pickup_dropoff_date.format('DD/MM/YY');
                                getFlightParams.todate = toDate.format('DD/MM/YY');
                            } else if (selectedFlight && selectedFlight.fleg) {
                                let fromDate = moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm');
                                let toDate = moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm');
                                if (fromDate > compareDate) {
                                    fromDate = compareDate;
                                }

                                toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                                getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                                getFlightParams.todate = toDate.format('DD/MM/YY');
                            } else {
                                let fromDate = moment();
                                let toDate = moment();
                                toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                                getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                                getFlightParams.todate = toDate.format('DD/MM/YY');
                            }

                            if (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'PTD') {
                                getFlightParams.fromport = quotesData.FromPortCode;
                                getFlightParams.fcountry = quotesData.FromCountryCode;
                            } else {
                                getFlightParams.fromport = quotesData.FromZipCode;
                                getFlightParams.fcountry = quotesData.FromCountryCode;
                            }

                            if (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'DTP') {
                                getFlightParams.toport = quotesData.ToPortCode;
                                getFlightParams.tcountry = quotesData.ToCountryCode;
                            } else {
                                getFlightParams.toport = quotesData.ToZipCode;
                                getFlightParams.tcountry = quotesData.ToCountryCode;
                            }
                            this.props.loadingActions.loadingChangeData(true);
                            var selectedFlightApi = null;
                            // console.log('selectedFlight', selectedFlight);
                            var res = await this.props.negotiationActions.getBibFlights(getFlightParams);
                            this.props.loadingActions.loadingChangeData(false);
                            if (res.success && res.data.Success) {
                                var list_flight = res.data.fl;
                                for (let i = 0; i < list_flight.length; i++) {
                                    const flightData = list_flight[i];

                                    let flightNums = [flightData.fleg.leg];
                                    if (flightData.tleg.leg) {
                                        flightNums.push(flightData.tleg.leg);
                                    }
                                    if (flightData.vialeg.leg) {
                                        flightNums.push(flightData.vialeg.leg);
                                    }
                                    // console.log('flightData', flightData);
                                    // console.log('flightNums', flightNums);
                                    if (flightNums.includes(selectedFlight.fleg.leg) && (!selectedFlight.tleg.leg || flightNums.includes(selectedFlight.tleg.leg)) && (!selectedFlight.vialeg.leg || flightNums.includes(selectedFlight.vialeg.leg))) {
                                        selectedFlightApi = flightData;
                                    }

                                    // console.log('flightData', flightData);
                                    // console.log('flightData', flightData);
                                    // if ((selectedFlight.fleg.leg === flightData.fleg.leg) && (selectedFlight.tleg.leg === flightData.tleg.leg) && (selectedFlight.vialeg.leg === flightData.vialeg.leg)) {
                                    //     selectedFlightApi = flightData;
                                    // }
                                }
                            }
                            quotes.selected_flight = selectedFlightApi;
                        }

                        quotes.selectedContacts = [
                            { phone: quotesData.phone, Name: quotesData.contactname, Jobtitle: '', Email: quotesData.contactemail.trim().toLowerCase() }
                        ];

                        if (quotes.customer) {
                            let phones = getCProfDetails.data.phone;
                            for (let i = 0; i < phones.length; i++) {
                                const p = phones[i];
                                quotes.selectedContacts.push({ phone: p.phone, Name: p.name, Jobtitle: '', Email: p.email.trim().toLowerCase() });
                            }
                        }

                        this.setState({ quotes, countries, countriesZip });
                        this.props.loadingActions.loadingChangeData(false);
                        this.getChatItems(false);
                        // console.log(quotes);
                    } else {
                        this.props.loadingActions.loadingChangeData(false);
                        //show Error
                        var errors = {};
                        errors.wrongCProf = "Not found quote";
                        this.setState({ errors });
                    }
                } else {
                    this.props.loadingActions.loadingChangeData(false);
                    //show Error
                    var errors = {};
                    errors.wrongCProf = "Not found quote";
                    this.setState({ errors });
                }
            } else {
                this.props.loadingActions.loadingChangeData(false);
                //show Error
                var errors = {};
                errors.wrongCProf = "Get currencies error";
                this.setState({ errors });
            }
            this.props.loadingActions.loadingChangeData(false);
        }
    }

    getChatItems = async (getQuoteStatus = true) => {
        if (this.intervalGetChat) {
            clearInterval(this.intervalGetChat);
        }
        var { cprof, secretKey, commodities, subCommodities, currencies, bidExp, DDC, shippmentChat } = this.state;
        this.props.loadingActions.loadingChangeData(true);

        if (getQuoteStatus && !shippmentChat) {
            var { quotes } = this.state;
            let startDate = moment();
            startDate.subtract(2, 'months');
            let toDate = moment();
            toDate.add(5, 'days');
            let getCProf = await this.props.negotiationActions.getCProf({ fromdate: startDate.format('DD/MM/YY'), todate: toDate.format('DD/MM/YY'), CPROF: cprof, SIM: 'N', userb: 0 });
            let quotesData = null;
            if (getCProf && getCProf.success && getCProf.data.Success && getCProf.data.cp.length) {
                quotesData = getCProf.data.cp.find(cp => cp.CProfAU + "" === cprof);
                if (quotesData) {
                    quotes.apiData = quotesData;

                    let getCProfDetails = await this.props.negotiationActions.getCProfDetails({ CPROF: cprof });
                    if (getCProfDetails.success && getCProfDetails.data.Success) {

                        quotes.commodity = quotesData.Commodity.trim() ? { label: quotesData.Commodity.trim(), value: quotesData.Commodity.trim() } : null;
                        quotes.sub_commodity = quotesData.SubCommodity.trim() ? { label: quotesData.SubCommodity.trim(), value: quotesData.SubCommodity.trim() } : null;

                        if (quotes.commodity) {
                            let findSelectedCommodity = commodities.find(c => (c.PartName === quotes.commodity.label || c.PartDes === quotes.commodity.label));
                            if (findSelectedCommodity) {
                                quotes.commodity = { au: findSelectedCommodity.PartAU, label: findSelectedCommodity.PartDes, value: findSelectedCommodity.PartName };
                                if (quotes.sub_commodity) {
                                    var findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                    if (findSelectedSubCommodity) {
                                        quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                    } else {
                                        quotes.subCommodityOptions = [];
                                        var loadCommodites = await this.props.ebookingActions.getCommodity(quotes.commodity.au);
                                        if (loadCommodites.success && loadCommodites.data.Success) {
                                            quotes.subCommodityOptions = loadCommodites.data.Comodities;
                                            subCommodities = quotes.subCommodityOptions;
                                            this.setState({ subCommodities });
                                        }
                                        findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                        if (findSelectedSubCommodity) {
                                            quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                        } else {
                                            if (quotes.subCommodityOptions.length == 1) {
                                                quotes.sub_commodity = { au: quotes.subCommodityOptions[0].PartAU, label: quotes.subCommodityOptions[0].PartDes, value: quotes.subCommodityOptions[0].PartName };;
                                            } else {
                                                quotes.sub_commodity = null;
                                            }
                                        }
                                    }
                                }
                            } else {
                                quotes.commodity = null;
                                quotes.sub_commodity = null;
                                quotes.subCommodityOptions = [];
                            }
                        } else {
                            quotes.commodity = null;
                            quotes.sub_commodity = null;
                            quotes.subCommodityOptions = [];
                        }

                        quotes.offer_price_list = [];

                        let firstOfferCalculation = null;
                        if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                            firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                        } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                            firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                        } else if (quotesData.calcmethod.trim()) {
                            firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                        }

                        let firstCurrencySelected = currencies.find(c => c.label === quotesData.currency);
                        var firstPrice = parseFloat(quotesData.Price);
                        if (firstPrice > 0) {
                            quotes.offer_price_list.push({ items: (quotes.sub_commodity ? { value: quotes.sub_commodity.value, label: quotes.sub_commodity.value } : null), calculation_method: firstOfferCalculation, currency: firstCurrencySelected ? firstCurrencySelected : null, price: quotesData.Price, edit: false });
                        }

                        //get bid exp
                        bidExp.forEach(det => {
                            let checkOptionExist = DDC.find(dc => dc.PartName === det.subcom);
                            if (!checkOptionExist) {
                                DDC.push({ PartAU: null, PartDes: det.subcomdes, PartName: det.subcom, label: det.subcom, value: det.subcom });
                            }
                        });

                        for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                            const cpElement = getCProfDetails.data.cp[i];
                            let itemSelected = DDC.find(po => po.PartName === cpElement.subcom);
                            let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                            let currencySelected = currencies.find(c => c.label === cpElement.curr);
                            if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                                methodSelected = { value: "Per Unit", label: "Per Unit" };
                            } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                                methodSelected = { value: "Per ULD", label: "Per ULD" };
                            }
                            quotes.offer_price_list.push({ items: itemSelected ? { label: itemSelected.PartName, value: itemSelected.PartName } : null, calculation_method: methodSelected ? methodSelected : null, currency: currencySelected ? currencySelected : null, price: cpElement.price, edit: true });
                        }
                        if (!quotes.offer_price_list.length) {
                            quotes.offer_price_list.push({ items: null, calculation_method: null, currency: null, price: '', edit: true });
                        }
                    }
                    this.setState({ quotes });
                }
            }
        }
        var chatItems = await this.props.negotiationActions.getChatItems({ QuoteID: cprof, secretKey });
        if (chatItems.success && chatItems.data.data.chatData.length) {
            let cChats = [].concat(chatItems.data.data.chatData);
            // cChats.sort(function (a, b) {
            //     return (a.DateAdded - b.DateAdded);
            // })
            this.setState({ chats: cChats })
        } else {
            this.setState({ chats: [] });
        }
        this.props.loadingActions.loadingChangeData(false);
        window.gotoBotChat();
        this.startInterval();
    }

    handleChange = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        this.setState({ textMessage: value });
    }

    handleKeyDown = async (e) => {
        if (e.keyCode === 18) {
            this.setState({ altKey: true });
        }

        if ((e.keyCode === 13) && this.state.altKey) {
            this.setState({ altKey: false });
            let { textMessage } = this.state;
            textMessage += "\r\n";
            this.setState({ textMessage });
        } else if (e.keyCode === 13) {
            e.preventDefault();
            this.handleNewMess();
        }
    }

    handleShowCounter = (counterData) => {
        this.setState({ modalIsOpen: true, itmChat: counterData });
    }

    handleDeclineQuoteWithData = async (chatId, reasons) => {
        if (reasons.length) {
            await this.setState({ chatId, reasons });
            var { cprof, secretKey, listChatUpdated, chats, quotes } = this.state;
            this.props.loadingActions.loadingChangeData(true);
            this.closeConfirmModal();
            let companyName = quotes.customer ? quotes.customer.CUSTDES : '';
            let name = quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name;
            const result = await this.props.negotiationActions.declineReasonsQuote(secretKey, cprof, chatId, reasons, quotes.CProfNum, companyName, name);
            if (result.success) {
                localStorage.setItem('DASHBOARD_REFRESH', true);
                var submitedReasons = false;
                var params = {
                    status: 'Declined Reason', // Approved, Declined
                    quoteId: cprof,
                    secretKey: secretKey,
                    reasons
                }
                submitedReasons = true;
                this.props.negotiationActions.custAlert(params);
                chats = chats.map(item => {
                    if (item.Id === chatId) {
                        item.status = "DeclinedReasons";
                        item.BookingSender = result.data.oldChat.BookingSender;
                        item.MettingSender = result.data.oldChat.MettingSender;
                    }
                    item.reasons = reasons;
                    item.submitedReasons = submitedReasons;
                    return item;
                });
                if (listChatUpdated.filter(chat => chat.Id === chatId).length === 0) {
                    listChatUpdated.push(chatId);
                }
                let chatItem = chats.find(item => item.Id === chatId);
                let newChat = clone(chatItem);
                newChat.ChatData.OfferData = [];
                newChat.ChatData.TextData = MESSAGES.TEXT_OFFRER_ITEM_DECLINE;
                newChat.BookingSender = 0;
                newChat.MettingSender = 9999;
                newChat.status = 'DeclineThank';
                chats.push(newChat);
                window.gotoBotChat();
            } else {
                if (result.errorCode && result.errorCode === 'ERRDECLINE005') {
                    this.openQuoteErrorModal();
                } else {
                    this.setState({ modalDenideSuccessIsOpen: true, successMessage: 'Add declined reasons unsuccessful.', modalError: true, quotes });
                }
            }
            // console.log(chats);
            this.setState({ chats, listChatUpdated });
            this.props.loadingActions.loadingChangeData(true);
            this.props.loadingActions.loadingChangeData(false);
            return result;
        } else {
            this.setState({ modalWarningDeclineIsOpen: true });
            return false;
        }
    }

    handleDeclineQuote = async () => {
        var { cprof, secretKey, listChatUpdated, chats, chatId, reasons, quotes } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        this.closeConfirmModal();
        let companyName = quotes.customer ? quotes.customer.CUSTDES : '';
        let name = quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name;
        const result = await this.props.negotiationActions.declineQuote(secretKey, cprof, chatId, reasons, quotes.CProfNum, companyName, name);
        if (result.success) {
            localStorage.setItem('DASHBOARD_REFRESH', true);
            // console.log(result);
            quotes.apiData.statdescust = result.data.oldChat.quotesStatus;
            var checkStatus = chats.find(item => item.Id === chatId);
            var params = {};
            var submitedReasons = false;
            if (checkStatus && checkStatus.status !== "Declined") {
                params = {
                    status: 'Declined', // Approved, Declined
                    quoteId: cprof,
                    secretKey: secretKey
                }
            } else {
                params = {
                    status: 'Declined Reason', // Approved, Declined
                    quoteId: cprof,
                    secretKey: secretKey,
                    reasons
                }
                submitedReasons = true;
            }
            this.props.negotiationActions.custAlert(params);
            chats = chats.map(item => {
                if (item.Id === chatId) {
                    if (item.status === 'SalesDeclined' || item.status === 'CustomerDeclined') {
                        item.status = "CustomerDeclined";
                    } else {
                        item.status = "Declined";
                    }
                }
                item.reasons = reasons;
                item.submitedReasons = submitedReasons;
                return item;
            });
            if (listChatUpdated.filter(chat => chat.Id === chatId).length === 0) {
                listChatUpdated.push(chatId);
            }
            let newTextChat = clone(result.data.textChat);
            chats.push(newTextChat);
            let newChat = clone(result.data.newChat);
            newChat.status = "DeclinedReasonsForm";
            newChat.MettingSender = 99999;
            newChat.BookingSender = 0;
            chats.push(newChat);
            this.setState({ chats, listChatUpdated, quotes });
            window.gotoBotChat();
        } else {
            // console.log(result);          
            if (result.errorCode && result.errorCode === 'ERRDECLINE005') {
                this.openQuoteErrorModal();
            } else {
                this.setState({ modalDenideSuccessIsOpen: true, successMessage: 'Decline quote unsuccessful.', modalError: true, quotes });
            }
        }
        this.props.loadingActions.loadingChangeData(false);
        return result;
    }

    handleMessageFalse = (MESSAGES) => {
        this.setState({ modalDenideSuccessIsOpen: true, successMessage: MESSAGES.ERROR_DENIDE_OFFER, modalError: true });
    }

    createFlightData = (quotes) => {
        let selectedFlight = quotes.selected_flight;
        let shipIcons, shipInfos;
        let dStd = selectedFlight
            ? moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm')
            : moment();
        let dSta = selectedFlight
            ? moment(
                selectedFlight.tleg.sta
                    ? selectedFlight.tleg.sta
                    : selectedFlight.fleg.sta,
                'DD/MM/YY HH:mm'
            )
            : moment();

        var fromCalAirport = false;
        var toCalAirport = false;

        if (quotes.deliver_from === 'Airport' && quotes.deliver_from_airport && quotes.deliver_from_airport.flightport == "Y") {
            fromCalAirport = true;
        }
        if (quotes.deliver_to === 'Airport' && quotes.deliver_to_airport && quotes.deliver_to_airport.flightport == "Y") {
            toCalAirport = true;
        }

        if (fromCalAirport && toCalAirport) {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport : (quotes.deliver_to_airport ? quotes.deliver_to_airport.portname : "");

            let endDate = '';

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            shipIcons = ['airplane'];
            shipInfos = [
                {
                    width: '30%',
                    label: startPort,
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : "",
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '40%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '30%',
                    label: toPort,
                    date: endDate,
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        } else if (fromCalAirport && !toCalAirport) {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';
            let startDate = '';
            let startDateTime = '';
            let endDate = '';
            if (selectedFlight) {
                startDate = dStd.isValid() ? dStd.format('MMMM Do') : "";
                if (startDate) {
                    startDateTime = dStd.isValid() ? dStd.format('HH:mm') : '';
                } else {
                    startDate = dStd.isValid() ? dStd.format('HH:mm') : '';
                }
            }

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            shipIcons = ['airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: startPort,
                    date: startDate,
                    time: startDateTime,
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight
                        ? selectedFlight.tleg.leg
                            ? selectedFlight.tleg.tport
                            : selectedFlight.fleg.tport
                        : '',
                    date: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    time: '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '40%',
                    label: toPort,
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        } else if (!fromCalAirport && toCalAirport) {
            shipIcons = ['truck', 'airplane'];
            shipInfos = [
                {
                    width: '40%',
                    label: startPort,
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : "",
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? selectedFlight.fleg.fport : '',
                    date: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    time: '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: toPort,
                    date: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        } else {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : "";
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';
            shipIcons = ['truck', 'airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: (quotes.deliver_from === 'Airport' ? ("Airport " + (quotes.deliver_from_airport ? quotes.deliver_from_airport.label : '')) : (quotes.deliver_from_zipcode ? 'Zip ' + quotes.deliver_from_zipcode : '')),
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : "",
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: startPort,
                    date: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    time: '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: toPort,
                    date: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    time: '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: (quotes.deliver_to === 'Airport' ? ("Airport " + (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '')) : (quotes.deliver_to_zipcode ? 'Zip ' + quotes.deliver_to_zipcode : '')),
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        }
        return {
            shipIcons,
            shipInfos,
        };
    };

    createOfferData = (offerItems) => {
        let offers = [];
        for (let i = 0; i < offerItems.length; i++) {
            const element = offerItems[i];
            offers.push({
                item: element.subcom,
                method: element.calc,
                currency: element.curr,
                price: element.price,
            });
        }

        return offers;
    };

    handleAccept = async (itmChat) => {
        var { cprof, secretKey, listChatUpdated, chats, quotes, currencies } = this.state;
        var flightData = BookingHelper.createFlightData(quotes);
        let offerData = BookingHelper.createOfferData(itmChat.ChatData.OfferData);


        // console.log("vao day", currencies);

        var offerTotal = BookingHelper.calculatorTotalNegotiation(itmChat.ChatData.OfferData, quotes.offer_currency, quotes.apiData, currencies);
        var contactEmailTo = [];
        var contactEmailCC = [];
        quotes.selectedContacts.forEach((element, i) => {
            if (!i) {
                contactEmailTo.push(element.Email);
            } else {
                contactEmailCC.push(element.Email);
            }
        });

        var emailData = {
            titleText: '[Quote #' + quotes.CProfNum + '] You was Accepted counter',
            quoteID: quotes.CProfNum,
            companyName: quotes.customer ? quotes.customer.CUSTDES : '',
            name: quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name,
            shipIcons: flightData.shipIcons,
            shipInfos: flightData.shipInfos,
            totalPieces: quotes.apiData.Pieces,
            totalVolumn: quotes.apiData.Volume,
            totalWeight: quotes.apiData.Weight,
            TCW: quotes.apiData.CWeight,
            Unit: quotes.unit_length.value,
            result: offerData,
            totalPrice: BookingHelper.formatMoney(offerTotal.offer_total),
            totalPriceType: quotes.cost_total_type.label,
            Commodity: quotes.apiData.Commodity,
            SubCommodity: quotes.apiData.SubCommodity,
            PersionMessage: '',
            DeclineUrl: '',
            AcceptUrl: '',
            RespondUrl: '',
            HourExpires: '',
            DetailsExpires: '',
            DetailsHourExpires: '',
            OfferCurrency: quotes.offer_currency.label,
            TermUrl: '',
            step_img: flightData.shipIcons.length + 'step',
            CProfAU: quotes.apiData.CProfAU,
            emails: contactEmailTo,
            emailsCC: contactEmailCC,
        };
        this.props.loadingActions.loadingChangeData(true);
        const resultAccept = await this.props.negotiationActions.acceptQuote(secretKey, cprof, itmChat.Id, quotes.CProfNum, emailData);
        if (resultAccept.success) {
            localStorage.setItem('DASHBOARD_REFRESH', true);
            quotes.apiData.statdescust = resultAccept.data.oldChat.quotesStatus;
            // quotes.apiData.statdescust = 'Accepted by Cust';
            const params = {
                status: 'Approved', // Approved, Declined
                quoteId: cprof,
                secretKey: secretKey
            }
            this.props.negotiationActions.custAlert(params);
            var findIndex = chats.findIndex(c => c.Id === itmChat.Id);
            if (findIndex >= 0) {
                var chatItem = chats[findIndex];
                chatItem.status = 'Accepted';
                chats[findIndex] = chatItem;
            }
            chats.push(resultAccept.data.newChat);
            chats.push(resultAccept.data.newChatThanks);
            this.setState({ chats, listChatUpdated });
            window.gotoBotChat();
        } else {
            // console.log(resultAccept);      
            if (resultAccept.errorCode && resultAccept.errorCode === 'ERRACCEPT005') {
                this.openQuoteErrorModal();
            } else {
                this.setState({ modalDenideSuccessIsOpen: true, successMessage: 'Accept quote unsuccessful.', modalError: true, quotes });
            }
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleCounter = async (message, currentChat) => {
        var { listChatUpdated, chats, cprof, secretKey, quotes } = this.state;
        const cOfferData = currentChat.ChatData.OfferData;
        const offerEmpty = cOfferData.filter(offer => {
            let price = offer.price;
            if (typeof price !== "string")
                price = price.toString();
            return (price.trim() === "" || price.trim() === ".")
        }) || [];
        if (offerEmpty.length > 0) {
            this.setState({ modalDenideSuccessIsOpen: true, successMessage: MESSAGES.ERROR_COUNTER_EMPTY, modalError: true });
            return;
        }

        this.props.loadingActions.loadingChangeData(true);
        let companyName = quotes.customer ? quotes.customer.CUSTDES : '';
        let name = quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name;
        const result = await this.props.negotiationActions.offerCounterQuote(secretKey, cprof, currentChat.Id, currentChat.ChatData.OfferData, message, quotes.CProfNum, companyName, name);
        if (result.success) {
            localStorage.setItem('DASHBOARD_REFRESH', true);
            quotes.apiData.statdescust = result.data.quotesStatus;
            const params = {
                status: 'Countered', // Approved, Declined
                quoteId: cprof,
                secretKey: secretKey
            }
            this.props.negotiationActions.custAlert(params);

            let cChats = [].concat(chats);
            cChats.push(result.data)
            // cChats.sort(function (a, b) {
            //     return (a.DateAdded - b.DateAdded);
            // })

            cChats = cChats.map(chat => {
                if (chat.Id === currentChat.Id) {
                    chat.status = "Countered";
                }
                return chat;
            });
            listChatUpdated.push(result.data.Id);
            this.closeModal();
            this.setState({ modalDenideSuccessIsOpen: true, successMessage: MESSAGES.SUCCESS_COUNTER_OFFER, modalError: false, chats: cChats, listChatUpdated, quotes });
            window.gotoBotChat();
            // this.getChatItems();
        } else {
            // console.log(result);     
            this.closeModal();
            if (result.errorCode && result.errorCode === 'ERRUPDATECOUNTER005') {
                this.openQuoteErrorModal();
            } else {
                this.setState({ modalDenideSuccessIsOpen: true, successMessage: MESSAGES.ERROR_COUNTER_OFFER, modalError: true });
            }
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handlePressed = (e) => {
        if (e.key === "Enter") {
            this.handleNewMess();
        }
    }

    handleNewMess = async (files = []) => {
        var { textMessage, cprof, secretKey, chats, quotes } = this.state;
        textMessage = textMessage ? textMessage.replace(/^\s+|\s+$/g, '') : '';
        textMessage = textMessage ? textMessage.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '';
        this.props.loadingActions.loadingChangeData(true);
        const result = await this.props.negotiationActions.addChatItem({
            QuoteID: cprof,
            ChatData: {
                TextData: !files.length ? textMessage : '',
                OfferData: []
            },
            AttachmentFiles: files,
            secretKey,
            CProfNum: quotes.CProfNum,
            companyName: quotes.customer ? quotes.customer.CUSTDES : '',
            name: quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name
        });
        if (result.success) {
            const params = {
                status: 'Message', // Approved, Declined
                quoteId: cprof,
                secretKey: secretKey
            }
            this.props.negotiationActions.custAlert(params);
            chats.push(result.data)
            // this.getChatItems();
            if (!files.length) {
                this.setState({ textMessage: "" });
            }
            window.gotoBotChat();
        } else {
            this.setState({ modalDenideSuccessIsOpen: true, successMessage: "Submit message unsuccessful.", modalError: true });
        }
        this.props.loadingActions.loadingChangeData(false);

    }

    buildFlightDetail() {
        var { quotesData, step } = this.state;

    }

    buildShipmentSteps = () => {
        var { quotes } = this.state;
        var fromCalAirport = false;
        var toCalAirport = false;

        var startZip = '';
        var toZip = '';
        var startPort = '';
        var toPort = '';
        if (quotes.deliver_from === 'Airport' && quotes.deliver_from_airport && quotes.deliver_from_airport.flightport == "Y") {
            fromCalAirport = true;
        }
        if (quotes.deliver_to === 'Airport' && quotes.deliver_to_airport && quotes.deliver_to_airport.flightport == "Y") {
            toCalAirport = true;
        }

        if (fromCalAirport && toCalAirport) {
            var dStd = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.fleg.std, "DD/MM/YYYY HH:mm") : moment();
            var dSta = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.tleg.sta ? quotes.selected_flight.tleg.sta : quotes.selected_flight.fleg.sta, "DD/MM/YYYY HH:mm") : moment();
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport : (quotes.deliver_to_airport ? quotes.deliver_to_airport.portname : "");

            return (
                <div className="shipment-steps">
                    <div className="shipment-step">
                        <div className="shipment-step-icon plain">
                        </div>
                        <div className="shipment-step-line left-ball right-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text dot-text">
                                <span>{startPort}</span>
                                <span>{quotes.selected_flight ? dStd.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dStd.format("HH:mm") : ''}</span>
                            </div>
                            <div className="center-text dot-text">
                                <span>{quotes.selected_flight ? quotes.selected_flight.fleg.fnumber : ''}</span>
                            </div>
                            <div className="right-text dot-text">
                                <span>{toPort}</span>
                                <span>{quotes.selected_flight ? dSta.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dSta.format("HH:mm") : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else if (!fromCalAirport && toCalAirport) {
            var dStd = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.fleg.std, "DD/MM/YYYY HH:mm") : moment();
            var dSta = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.tleg.sta ? quotes.selected_flight.tleg.sta : quotes.selected_flight.fleg.sta, "DD/MM/YYYY HH:mm") : moment();
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : '';
            var toPort = quotes.selected_flight ? quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport : (quotes.deliver_to_airport ? quotes.deliver_to_airport.portname : "");

            return (
                <div className="shipment-steps steps-2">
                    <div className="shipment-step">
                        <div className="shipment-step-icon">
                        </div>
                        <div className="shipment-step-line left-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text dot-text">
                                <span>{quotes.deliver_from === 'Airport' ? ("Airport " + (quotes.deliver_from_airport ? quotes.deliver_from_airport.label : '')) : (quotes.deliver_from_zipcode ? 'Zip ' + quotes.deliver_from_zipcode : '')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="shipment-step">
                        <div className="shipment-step-icon plain">
                        </div>
                        <div className="shipment-step-line left-slash right-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text-center dot-text">
                                <span>{startPort}</span>
                                <span>{quotes.selected_flight ? dStd.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dStd.format("HH:mm") : ''}</span>
                            </div>
                            <div className="center-text dot-text">
                                <span>{quotes.selected_flight ? quotes.selected_flight.fleg.fnumber : ''}</span>
                            </div>
                            <div className="right-text dot-text">
                                <span>{toPort}</span>
                                <span>{quotes.selected_flight ? dSta.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dSta.format("HH:mm") : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else if (fromCalAirport && !toCalAirport) {
            var dStd = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.fleg.std, "DD/MM/YYYY HH:mm") : moment();
            var dSta = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.tleg.sta ? quotes.selected_flight.tleg.sta : quotes.selected_flight.fleg.sta, "DD/MM/YYYY HH:mm") : moment();
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';

            return (
                <div className="shipment-steps steps-2">
                    <div className="shipment-step">
                        <div className="shipment-step-icon plain">
                        </div>
                        <div className="shipment-step-line right-slash left-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text dot-text">
                                <span>{startPort}</span>
                                <span>{quotes.selected_flight ? dStd.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dStd.format("HH:mm") : ''}</span>
                            </div>
                            <div className="center-text dot-text">
                                <span>{quotes.selected_flight ? quotes.selected_flight.fleg.fnumber : ''}</span>
                            </div>
                            <div className="right-text-center dot-text">
                                <span>{toPort}</span>
                                <span>{quotes.selected_flight ? dSta.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dSta.format("HH:mm") : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="shipment-step">
                        <div className="shipment-step-icon">
                        </div>
                        <div className="shipment-step-line right-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="right-text dot-text">
                                <span>{quotes.deliver_to === 'Airport' ? ("Airport " + (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '')) : (quotes.deliver_to_zipcode ? 'Zip ' + quotes.deliver_to_zipcode : '')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else {
            var dStd = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.fleg.std, "DD/MM/YYYY HH:mm") : moment();
            var dSta = quotes.selected_flight ? BookingHelper.getDate(quotes.selected_flight.tleg.sta ? quotes.selected_flight.tleg.sta : quotes.selected_flight.fleg.sta, "DD/MM/YYYY HH:mm") : moment();
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : "";
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';

            return (
                <div className="shipment-steps steps-3">
                    <div className="shipment-step">
                        <div className="shipment-step-icon">
                        </div>
                        <div className="shipment-step-line left-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text dot-text">
                                <span>{quotes.deliver_from === 'Airport' ? ("Airport " + (quotes.deliver_from_airport ? quotes.deliver_from_airport.label : '')) : (quotes.deliver_from_zipcode ? 'Zip ' + quotes.deliver_from_zipcode : '')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="shipment-step center-step">
                        <div className="shipment-step-icon plain">
                        </div>
                        <div className="shipment-step-line left-slash right-slash"></div>
                        <div className="shipment-step-texts">
                            <div className="left-text-center dot-text">
                                <span>{startPort}</span>
                                <span>{quotes.selected_flight ? dStd.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dStd.format("HH:mm") : ''}</span>
                            </div>
                            <div className="center-text dot-text">
                                <span>{quotes.selected_flight ? quotes.selected_flight.fleg.fnumber : ''}</span>
                            </div>
                            <div className="right-text-center dot-text">
                                <span>{toPort}</span>
                                <span>{quotes.selected_flight ? dSta.format('MMMM Do') : ""}</span>
                                <span>{quotes.selected_flight ? dSta.format("HH:mm") : ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="shipment-step">
                        <div className="shipment-step-icon">
                        </div>
                        <div className="shipment-step-line right-ball"></div>
                        <div className="shipment-step-texts">
                            <div className="right-text dot-text">
                                <span>{quotes.deliver_to === 'Airport' ? ("Airport " + (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '')) : (quotes.deliver_to_zipcode ? 'Zip ' + quotes.deliver_to_zipcode : '')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    buildPriceItems() {
        var { quotes } = this.state;
        var returnElements = [];
        if (quotes && quotes.offer_price_list.length) {
            quotes.offer_price_list.forEach((el, i) => {
                returnElements.push(
                    <div className={`quote-flex display-flex mb-2`} key={i}>
                        <div className={`input-group-quote small-radius group-pr-1 shadow`}>
                            <label >{el.items ? el.items.value : ''}</label>
                        </div>
                        <div className={`input-group-quote small-radius group-pr-2 shadow`}>
                            <label >{el.calculation_method ? el.calculation_method.label : ''}</label>
                        </div>
                        <div className={`input-group-quote small-radius group-pr-3 shadow`}>
                            <label >{el.currency ? el.currency.label : ''}</label>
                        </div>
                        <div className={`input-group-quote small-radius group-pr-4 shadow`}>
                            <label >{el.price}</label>
                        </div>
                    </div>
                );
            });
        } else {
            returnElements.push(
                <div className={`quote-flex display-flex mb-2`} key={'price-item'}>
                    <div className={`input-group-quote small-radius group-pr-1 shadow`}>
                        <label ></label>
                    </div>
                    <div className={`input-group-quote small-radius group-pr-2 shadow`}>
                        <label ></label>
                    </div>
                    <div className={`input-group-quote small-radius group-pr-3 shadow`}>
                        <label ></label>
                    </div>
                    <div className={`input-group-quote small-radius group-pr-4 shadow`}>
                        <label ></label>
                    </div>
                </div>
            );
        }

        return returnElements;
    }

    handleModalConfirm = () => {
        this.closeModal();
    }

    handleRefresh = () => {
        this.closeQuoteErrorModal();
        this.getChatItems();
    }

    handleGoToDashboard = () => {
        this.closeQuoteErrorModal();
        var { history } = this.props;
        history.push('/dashboard/quotes');
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalIsOpen: false });
            }
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    closeConfirmModal = () => {
        this.setState({ modalConfirmIsOpen: false });
    }

    openConfirmModal = (itmChat) => {
        this.setState({ modalConfirmIsOpen: true, chatId: itmChat.Id, reasons: [] });
    }

    closeDenideSuccessModal = () => {
        this.setState({ modalDenideSuccessIsOpen: false });
    }

    closeWarningDeclineModal = () => {
        this.setState({ modalWarningDeclineIsOpen: false });
    }

    openQuoteErrorModal = () => {
        this.setState({ modalQuoteErrorIsOpen: true });
    }
    closeQuoteErrorModal = () => {
        this.setState({ modalQuoteErrorIsOpen: false });
    }

    // BOF - handle upload files
    openUploadFilesModal = () => {
        this.setState({ modalUploadFiles: true });
    }

    closeUploadFilesModal = () => {
        this.setState({ modalUploadFiles: false });
    }

    handlePostUploadFiles = async (files) => {
        this.closeUploadFilesModal();
        if (files.length) {
            this.handleNewMess(files);
        }
    }
    // EOF - handle upload files

    handleDanderToggle = () => {
        let { isDanderShow } = this.state;
        isDanderShow = isDanderShow ? false : true;
        this.setState({ isDanderShow });
    }

    handleParticipantToggle = () => {
        let { isParticipant } = this.state;
        isParticipant = isParticipant ? false : true;
        this.setState({ isParticipant });
    }

    startInterval = () => {
        if (this.intervalGetChat) {
            clearInterval(this.intervalGetChat);
        }
        this.intervalGetChat = setInterval(this.getChatItems, 120000);
    }

    handleDownloadQuote = async () => {
        var { cprof, secretKey, quotes, currencies } = this.state;
        // console.log(quotes);
        var flightData = BookingHelper.createFlightData(quotes);
        let offerData = BookingHelper.createOfferDataDownload(quotes.offer_price_list);
        var offerTotal = BookingHelper.calculatorTotalDownload(quotes.offer_price_list, quotes.offer_currency, quotes.apiData, currencies);

        var emailData = {
            titleText: 'You got new price quote #' + quotes.CProfNum,
            mailTitle: 'You got new price quote #' + quotes.CProfNum,
            pdfTitle: "You got new price quote #" + quotes.CProfNum,
            quoteID: quotes.CProfNum,
            companyName: quotes.customer ? quotes.customer.CUSTDES : '',
            name: quotes.selectedContacts && quotes.selectedContacts.length ? quotes.selectedContacts[0].Name : this.props.auth.user_name,
            shipIcons: flightData.shipIcons,
            shipInfos: flightData.shipInfos,
            totalPieces: quotes.apiData.Pieces,
            totalVolumn: quotes.apiData.Volume,
            totalWeight: quotes.apiData.Weight,
            TCW: quotes.apiData.CWeight,
            Unit: quotes.unit_length.value,
            result: offerData,
            totalPrice: BookingHelper.formatMoney(offerTotal.offer_total),
            totalPriceType: quotes.cost_total_type.label,
            Commodity: quotes.apiData.Commodity,
            SubCommodity: quotes.apiData.SubCommodity,
            PersionMessage: '',
            DeclineUrl: '',
            AcceptUrl: '',
            RespondUrl: '',
            HourExpires: '',
            DetailsExpires: '',
            DetailsHourExpires: '',
            OfferCurrency: quotes.offer_currency.label,
            TermUrl: '',
            step_img: flightData.shipIcons.length + 'step',
            CProfAU: quotes.apiData.CProfAU,
            emails: [],
            emailsCC: [],
        };
        this.props.loadingActions.loadingChangeData(true);
        const resultAccept = await this.props.negotiationActions.downloadQuote(secretKey, cprof, quotes.CProfNum, emailData);
        if (resultAccept.success) {
            // window.open(resultAccept.data.fileUrl, "_blank");
            this.linkDownload.href = resultAccept.data.fileUrl;
            setTimeout(() => {
                this.linkDownload.click();
            }, 100);
        } else {
            this.setState({ modalDenideSuccessIsOpen: true, successMessage: 'Download quote unsuccessful.', modalError: true, quotes });
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleDownloadFile = async (file, folder, me = true) => {
        this.props.loadingActions.loadingChangeData(true);
        let res = await this.props.negotiationActions.getNegotiationFile(file, folder, me);
        this.props.loadingActions.loadingChangeData(false);
        if (res.data && res.data.photoURL) {
            window.open(res.data.photoURL, "_blank");
        } else {
            this.setState({ modalDenideSuccessIsOpen: true, successMessage: "Download file unsuccessful.", modalError: true });
        }
    }

    buildMustBeDelivery = () => {
        var { quotes } = this.state;
        let returnElements = [];
        if (quotes && quotes.handling) {
            quotes.handling.map(hl => {
                if (hl.value === "MBD") {
                    returnElements.push(<div className="row">
                        <div className="col-12">
                            <div className="commodity-name">{hl.label}{hl.Delivertill && moment.isMoment(moment(hl.Delivertill)) ? ' by ' + hl.Delivertill.format('MMMM Do, YYYY') : ''}</div>
                        </div>
                    </div>)
                }
            });
        }
        return returnElements;
    }

    render() {
        var { errors, modalIsOpen, modalQuoteErrorIsOpen, modalDenideSuccessIsOpen, modalWarningDeclineIsOpen, modalUploadFiles, chats, itmChat,
            isShowSuccessMsg, textMessage, successMessage, quotes,
            modalError, listChatUpdated, currency, currencies, chats, modalConfirmIsOpen, isDanderShow, isParticipant, shippmentChat } = this.state;

        var quoteStatues = quotes.apiData ? quotes.apiData.statdescust : '';
        switch (quoteStatues) {
            case MESSAGES.STATUS_APPROVED_BY_CUST:
                quoteStatues = 'Approved by Customer';
                break;
            case MESSAGES.STATUS_APPROVED_BY_USER:
                quoteStatues = 'Approved by Agent';
                break;
            case MESSAGES.STATUS_CUST_NEGOTIATION:
                quoteStatues = 'Waiting for Agent';
                break;
            case MESSAGES.STATUS_DECLINE_BY_CUST:
                quoteStatues = 'Decline by Customer';
                break;
            case MESSAGES.STATUS_SEND_TO_CUST:
                quoteStatues = 'Sent to Customer';
                break;
            case MESSAGES.STATUS_USER_NEGOTIATION:
                quoteStatues = 'Waiting for Customer';
                break;
        }

        // console.log(quotes);

        if (errors.wrongCProf) {
            return (
                <div className="quotes-main-content container" ref="mainBox">
                    <div className={`row`} ref="formBox">
                        <div className="col-12">
                            <div className={`form-group`}>
                                <label className="error text-danger d-block bg-red">Unexpected error has occurred. Please try again and if problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.</label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="page-main">
                    <div className="quotes-main-content negotiation-main container" ref="mainBox">
                        <div className="row row-eq-height" ref="formBox">
                            <div className="col-lg-5">
                                <div className="quote-info negotiation h-100">
                                    <div className="quote-head-btn">
                                        <Link to={`/dashboard${shippmentChat ? '' : '/quotes'}`} className="btn-chat-back"></Link>
                                    </div>
                                    <div className="quote-title">
                                        <h1>Your Quote</h1>
                                        <span className={`${quotes.customer ? 'customer-name d-block' : 'd-none'}`}>{quotes.customer ? quotes.customer.CUSTDES : this.props.auth.custdes}</span>
                                        <span className={`${quotes.apiData ? 'customer-name d-block' : 'd-none'}`}>{quotes.apiData ? 'Status: ' + quoteStatues : ''}</span>
                                        <span>{quotes.CProfNum ? '#' + quotes.CProfNum : ''}</span>
                                    </div>
                                    <div className="radio-quote-box p-3 pl-5 pr-5">
                                        <div className="">
                                            <label className={`step-name`}>Transport Details</label>
                                            <div className="clb"></div>
                                        </div>

                                        <div className="quote-block mt-3">
                                            {this.buildFlightDetail()}

                                            <div className="row">
                                                <div className="col-12">
                                                    {this.buildShipmentSteps()}
                                                </div>
                                            </div>


                                            <div className="clb"></div>
                                        </div>
                                        <div className="mt-4">
                                            <label className={`step-name`}>Shipment Details</label>
                                            <div className="clb"></div>
                                        </div>
                                        <div className="shipment-details">
                                            <div className="d-block">
                                                <span className={`pl-2 commodity-name ${quotes.commodity ? '' : 'd-none'}`}>{quotes.commodity ? quotes.commodity.label : ''}</span><span className={`commodity-name border-left ${quotes.sub_commodity ? '' : 'd-none'}`}>{quotes.sub_commodity ? quotes.sub_commodity.label : ''}</span>
                                            </div>
                                            <div className="quote-flex-text display-flex mt-3">
                                                <div className="input-group-quote-text small-radius group-1"><label>Total Pieces</label></div>
                                                <div className="input-group-quote-text small-radius group-2"><label>Actual Weight</label></div>
                                                <div className="input-group-quote-text small-radius group-3"><label>Volume Weight</label></div>
                                                <div className="input-group-quote-text small-radius group-4"><label>Chargeable Weight</label></div>
                                            </div>
                                            <div className={`quote-flex display-flex`}>
                                                <div className={`input-group-quote small-radius group-1 shadow`}>
                                                    <label >{quotes.unit_total ? quotes.unit_total.total_pieces : ''}</label>
                                                </div>
                                                <div className={`input-group-quote small-radius group-3 shadow`}>
                                                    <label >{quotes.unit_total ? BookingHelper.roundOff3Decimal(quotes.unit_total.total_weight, 3) : ''} <span>kg</span></label>
                                                </div>
                                                <div className={`input-group-quote small-radius group-2 shadow`}>
                                                    <label >{quotes.unit_total ? BookingHelper.roundOff3Decimal(quotes.unit_total.total_volume_kg, 3) : ''} <span>kg</span></label>
                                                </div>
                                                <div className={`input-group-quote small-radius group-4 shadow`}>
                                                    <label >{quotes.unit_total ? BookingHelper.roundOff3Decimal(quotes.unit_total.tcw, 3) : ''} <span>kg</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="quote-block mt-4">
                                            <div className="select-small-options text-green select-currency float-right"><label className="lbl-selected-currency shadow ">{quotes.offer_currency ? quotes.offer_currency.label : ''}</label></div>
                                        </div> */}
                                        <div className={`quote-block mt-4 ${shippmentChat ? 'd-none' : ''}`}>
                                            <label className={`step-name`}>Pricing</label>
                                            <div className="clb"></div>
                                        </div>
                                        <div className={`pricing mt-3 ${shippmentChat ? 'd-none' : ''}`}>
                                            <div className="quote-flex-text display-flex">
                                                <div className="input-group-quote-text no-radius group-pr-1"><label>Item</label></div>
                                                <div className="input-group-quote-text no-radius group-pr-2"><label>Calculations Method</label></div>
                                                <div className="input-group-quote-text no-radius group-pr-3"><label>Currency</label></div>
                                                <div className="input-group-quote-text no-radius group-pr-4"><label>Price</label></div>
                                            </div>
                                            {this.buildPriceItems()}
                                            <div className="quote-flex display-flex total mt-2 perchargable d-none">
                                                <div className="input-group-quote group-pr-1"><label>Currency exchange rates will be calculated by the exchange rates published at the day of the flight</label></div>
                                                <div className="input-group-quote no-radius group-pr-3"><label>Total Price</label></div>
                                                <div className="input-group-quote no-radius group-pr-4 shadow"><label>{quotes.offer_currency ? quotes.offer_currency.label : ''} {BookingHelper.formatMoney(quotes.offer_total)}</label></div>
                                            </div>
                                        </div>
                                        <div className={`quote-block mt-4 ${shippmentChat ? 'd-none' : ''}`}>
                                            <div className="row">
                                                <div className="col"><div className="plus-button" onClick={this.handleDanderToggle}>{isDanderShow ? "-" : "+"}</div><label onClick={this.handleDanderToggle} className={`step-name`}>Dangerous Goods &amp; Handling</label></div>
                                                <div className="col-md-auto">
                                                    <div className="lbl-cao-pax text-uppercase">{quotes.dg_cao_pax}</div>
                                                </div>
                                            </div>
                                            {
                                                this.buildMustBeDelivery()
                                            }
                                            {isDanderShow ? (
                                                <div className="row">
                                                    <ul className="childrens">
                                                        {
                                                            quotes.handling.map((handling, i) => {
                                                                return (
                                                                    <li key={uuidv4()}>{handling.label}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className={`quote-block mt-1 ${shippmentChat ? 'd-none' : ''}`}>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="plus-button" onClick={this.handleParticipantToggle}>{isParticipant ? "-" : "+"}</div> <label onClick={this.handleParticipantToggle} className={`step-name`}>Participants</label></div>
                                            </div>
                                            {isParticipant ? (
                                                <div className="row">
                                                    <ul className="childrens">
                                                        {BookingHelper.getParticipentItem('ShippingAgent', quotes)}
                                                        {BookingHelper.getParticipentItem('Consignor', quotes)}
                                                        {BookingHelper.getParticipentItem('Consignee', quotes)}
                                                        {BookingHelper.getParticipentItem('ReceivingAgent', quotes)}
                                                        {BookingHelper.getParticipentItem('FeederGSA', quotes)}
                                                        {BookingHelper.getParticipentItem('GSA', quotes)}
                                                        {BookingHelper.getParticipentItem('Airline', quotes)}
                                                    </ul>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className={`mt-4 text-center ${shippmentChat ? 'd-none' : ''}`}>
                                        <button className="btn btn-white" onClick={this.handleDownloadQuote}>Download This Quote</button>
                                        <a className="d-none" target="_blank" href={``} ref={linkDownload => this.linkDownload = linkDownload}>download</a>
                                        <div className="clb"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="quote-form col-lg-7">
                                <div className="h-100">
                                    <div className="quote-form-content">
                                        <div className="">
                                            <div className="quotes-nav">

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="w-100 chat-nav">
                                                            <div className="chat-refresh-button" onClick={this.getChatItems}></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-sm-2 col-3">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-6 d-inline-block d-lg-none">
                                                                        <div className="row">
                                                                            <div className="back-button  mr-3"><img src="/images/btnl-back-grey.png" /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 col-lg-12">
                                                                        <div className="row">
                                                                            <div className="mail d-none"><img src="/images/mail-blue.png" /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-11 col-sm-10 col-9">
                                                        <div className="show-proposal cursor-pointer text-right d-none">View Similar Proposals</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quote-form-input form-chat form-elements mt-3">
                                                <div className={`${errors.saveData ? '' : 'd-none'}`}>
                                                    <div className={`form-group`}>
                                                        <label className="error text-danger d-block bg-red">Unexpected error has occurred. Please try again and if problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.</label>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <div className="chat-header">
                                                            <h1 className="text-uppercase">{`${quotes.customer ? quotes.customer.CUSTDES : ''}`}</h1>
                                                            <div className="chat-header-description">This chat is a little like email. Replies might take a little time. </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 lbl-account-parner">
                                                        Cal Agent - {quotes.apiData ? quotes.apiData.sname : ''}
                                                    </div>
                                                    <div className="col-6 text-right lbl-account-me mb-2">
                                                        Client representative - {quotes.apiData ? quotes.apiData.contactname : this.props.auth.user_name}
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-12 ">
                                                        <div className="chat-items">
                                                            {(chats.length > 0) ? chats.map((chat, i) => {
                                                                let me = (chat.BookingSender + "" !== "0") ? true : false;
                                                                let offerData = chat.ChatData ? chat.ChatData.OfferData : [];
                                                                let noFooter = (!Array.isArray(offerData) || offerData.length === 0) ? true : false;
                                                                let text = (chat.ChatData) ? chat.ChatData.TextData : "";
                                                                return (<QuotesChatOfferItem
                                                                    quotesData={quotes.apiData}
                                                                    currency={quotes.offer_currency}
                                                                    currencies={currencies}
                                                                    key={`chat-item-${i}`}
                                                                    me={me}
                                                                    params={this.props.match.params}
                                                                    itmChat={chat}
                                                                    offerData={offerData}
                                                                    text={text}
                                                                    itemPosition={i}
                                                                    noFooter={noFooter}
                                                                    handleShowCounter={this.handleShowCounter}
                                                                    handleMessageFalse={this.handleMessageFalse}
                                                                    handleDeclineQuote={this.handleDeclineQuote}
                                                                    handleDownloadFile={this.handleDownloadFile}
                                                                    handleDeclineQuoteWithData={this.handleDeclineQuoteWithData}
                                                                    openConfirmModal={this.openConfirmModal}
                                                                    handleAccept={this.handleAccept}
                                                                    listChatUpdated={listChatUpdated}
                                                                />)
                                                            }) : null}
                                                            {isShowSuccessMsg ? (
                                                                <div className="quote-success">
                                                                    {MESSAGES.SUCCESS_QUOTE_CHAT}
                                                                </div>
                                                            ) : null}


                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col-12 text-right">
                                                                <div className="chat-input d-inline-block shadow">
                                                                    {/* <ul className="attach-items">
                                                                        <li>
                                                                            <span className="attachment-filename">Name</span>
                                                                            <i className="fa fa-times"></i>
                                                                        </li>
                                                                    </ul> */}

                                                                    <div className="chat-attach" onClick={this.openUploadFilesModal}></div>
                                                                    <textarea className="chat-input-message" value={textMessage} rows="2" name="textMessage" onChange={this.handleChange} onKeyDown={this.handleKeyDown}>
                                                                    </textarea>
                                                                    <div className="chat-send-message-button" onClick={this.handleNewMess}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        itmChat={itmChat}
                        onRequestClose={this.closeModal}
                        onAfterOpen={this.onAfterOpen}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalChatCounter
                            onShow={modalIsOpen}
                            itmChat={itmChat}
                            closeModal={this.closeModal}
                            handleCounter={this.handleCounter}
                            onHide={this.closeModal}
                            handleConfirm={this.handleModalConfirm}
                        />
                    </Modal>

                    <Modal
                        isOpen={modalQuoteErrorIsOpen}
                        itmChat={itmChat}
                        onRequestClose={this.closeQuoteErrorModal}
                        onAfterOpen={this.onAfterOpen}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalQuoteActionError
                            onShow={modalQuoteErrorIsOpen}
                            itmChat={itmChat}
                            closeModal={this.closeQuoteErrorModal}
                            onHide={this.closeQuoteErrorModal}
                            handleRefresh={this.handleRefresh}
                            handleGoToDashboard={this.handleGoToDashboard}
                        />
                    </Modal>

                    <Modal
                        isOpen={modalDenideSuccessIsOpen}
                        onRequestClose={this.closeDenideSuccessModal}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalShowMessage
                            onShow={modalDenideSuccessIsOpen}
                            closeModal={this.closeDenideSuccessModal}
                            message={successMessage}
                            error={modalError}
                            onHide={this.closeModal}
                        />
                    </Modal>
                    <Modal
                        isOpen={modalWarningDeclineIsOpen}
                        onRequestClose={this.closeWarningDeclineModal}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalShowMessage
                            onShow={modalWarningDeclineIsOpen}
                            closeModal={this.closeWarningDeclineModal}
                            message={"You must choose a reason!"}
                            error={true}
                            onHide={this.closeModal}
                        />
                    </Modal>

                    <Modal
                        isOpen={modalConfirmIsOpen}
                        onRequestClose={this.closeConfirmModal}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalSampleConfirm
                            onShow={modalConfirmIsOpen}
                            closeModal={this.closeConfirmModal}
                            handleConfirm={this.handleDeclineQuote}
                            description=" decline item"
                            onHide={this.closeModal}
                        />
                    </Modal>
                    <Modal
                        isOpen={modalUploadFiles}
                        onRequestClose={this.closeUploadFilesModal}
                        shouldCloseOnOverlayClick={false}
                        className="modal fade show"
                        overlayClassName="mfp-bg"
                    >
                        <ModalUploadFiles
                            onShow={modalUploadFiles}
                            closeModal={this.closeUploadFilesModal}
                            handleConfirm={this.handlePostUploadFiles}
                            description=" decline item"
                            onHide={this.closeModal}
                        />
                    </Modal>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        negotiationActions: bindActionCreators(negotiationActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        dashboardActions: bindActionCreators(dashboardActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationQuotes);