import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import clone from 'clone';
/**
 * Validator
 */
import { bindActionCreators } from 'redux';
import validator from 'validator';
import InputError from '../../components/InputError';
import * as MESSAGES from '../../libs/Messages';

class ModalBookingGSA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      noOptionsCustomer: "Type to find customers",
      receiving_company_options: [],
      shipping_company_options: [],
      isLoadingShipingAgent: false,
      isLoadingReceivingAgent: false,
      inputValueReceivingAgent: '',
      inputValueShippingAgent: '',
      formData: {
        payingCustomer: false,
        payingCustomerShipping: false,
        payingCustomerFreight: false,
        selectedShippingReceiving: 'Shipping',
        shipping_company: null,
        shippingIATA1: '',
        shippingIATA2: '',
        receiving_company: null,
        receivingIATA1: '',
        receivingIATA2: ''
      }
    };
  }

  componentDidMount = async () => {
    var t = this;
    const { auth, paticipants } = this.props;
    const { errors, formData } = this.state;
    formData["selectedShippingReceiving"] = "Shipping";
    formData["receivingIATA1"] = "";
    formData["receivingIATA2"] = "";
    errors["receivingCompanyName"] = "";
    errors["receivingIATA1"] = "";
    errors["receivingIATA2"] = "";
    formData.receiving_company = null;
    formData.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
    formData.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
    formData.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
    await this.setState({ errors, formData });
    if (!paticipants.shipping.length) {
      this.props.ebookingActions.getParticipent(this.props.auth.custname, "SHP", this.props.auth.jwtToken);
      t.setState({ isLoadingShipingAgent: true });
      if (this.timeoutSearchShippingAgent) {
        clearTimeout(this.timeoutSearchShippingAgent);
      }
      this.timeoutSearchShippingAgent = setTimeout(function () {
        t.setState({ isLoadingShipingAgent: false });
        t.setState({ isLoadingReceivingAgent: false });
      }, 5000);
    }
    if (!paticipants.receiving.length) {
      this.props.ebookingActions.getParticipent(this.props.auth.custname, "REC", this.props.auth.jwtToken);
      t.setState({ isLoadingReceivingAgent: true });
      if (this.timeoutSearchShippingAgent) {
        clearTimeout(this.timeoutSearchShippingAgent);
      }
      this.timeoutSearchShippingAgent = setTimeout(function () {
        t.setState({ isLoadingShipingAgent: false });
        t.setState({ isLoadingReceivingAgent: false });
      }, 5000);
    }
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    const { shipping, receiving } = newProps.paticipants;
    const oldShipping = oldProps.paticipants.shipping;
    const oldReceiving = oldProps.paticipants.receiving;
    if (!oldShipping.length && shipping.length) {
      this.buildShippingOption();
    }
    if (!oldReceiving.length && receiving.length) {
      this.buildReceivingOption();
    }
  }

  componentWillUnmount() {
    if (this.timeoutSearchShippingAgent) {
      clearTimeout(this.timeoutSearchShippingAgent);
    }
  }

  handleChange = async (event) => {
    const { errors, formData } = this.state;
    const { auth } = this.props;
    var {
      target,
      target: { name, type },
    } = event;
    var value = type === 'checkbox' ? target.checked : target.value;
    errors[name] = "";
    if (name === "selectedShippingReceiving") {
      if (formData["selectedShippingReceiving"] === value) {
        formData[name] = '';
      } else {
        formData[name] = value;
      }
      if (value === 'Receiving') {        
        formData["shippingIATA1"] = "";
        formData["shippingIATA2"] = "";
        errors["shippingCompanyName"] = "";
        errors["shippingIATA"] = "";
        errors["shippingIATA1"] = "";
        errors["shippingIATA2"] = "";
        formData.shipping_company = null;
        if (formData[name]) {
          formData.receiving_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
          formData.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
          formData.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
        } else {       
          formData["receivingIATA1"] = "";
          formData["receivingIATA2"] = "";
          formData.receiving_company = null;
        }

      } else {
        formData["receivingIATA1"] = "";
        formData["receivingIATA2"] = "";
        errors["receivingCompanyName"] = "";
        errors["receivingIATA"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingIATA2"] = "";
        formData.receiving_company = null;
        if (formData[name]) {
          formData.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
          formData.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
          formData.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
        } else {         
          formData["shippingIATA1"] = "";
          formData["shippingIATA2"] = "";
          formData.shipping_company = null;
        }

      }
    } else if (name === 'payingCustomer') {
      formData.payingCustomerShipping = false;
      formData.payingCustomerFreight = false;
      formData.payingCustomer = !formData.payingCustomer;
      if (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y') {
        formData.payingCustomer = false;
        // this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
      }
    } else if (name === 'payingCustomerShipping') {
      formData.payingCustomer = false;
      formData.payingCustomerFreight = false;
      formData.payingCustomerShipping = !formData.payingCustomerShipping;
      if ((formData.selectedShippingReceiving === 'Shipping' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (formData.shipping_company && formData.shipping_company.status && formData.shipping_company.status.toUpperCase() !== 'ACTIVE')) {
        formData.payingCustomerShipping = false;
        // this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
      }
    } else if (name === 'payingCustomerFreight') {
      formData.payingCustomer = false;
      formData.payingCustomerShipping = false;
      formData.payingCustomerFreight = !formData.payingCustomerFreight;

      if ((formData.selectedShippingReceiving === 'Receiving' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (formData.receiving_company && formData.receiving_company.status && formData.receiving_company.status.toUpperCase() !== 'ACTIVE')) {
        formData.payingCustomerFreight = false;
        // this.setState({ modalDenideSuccessIsOpen: true, modalError: true, successMessage: "This customer can't be a payer" })
      }
    } else {
      formData[name] = value;
    }
    await this.setState({ errors, formData });
  };

  buildReceivingOption = async () => {
    const { paticipants } = this.props;
    let listReceiving = [];
    paticipants.receiving.map(c => {
      listReceiving.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
    });
    this.setState({ receiving_company_options: listReceiving });
  }

  buildShippingOption = async () => {
    const { paticipants } = this.props;
    let listShipping = [];
    paticipants.shipping.map(c => {
      listShipping.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
    });
    this.setState({ shipping_company_options: listShipping });
  }

  handleOnBlur = (event) => {
    var { errors, formData } = this.state;
    var { target, target: { name, type } } = event;
    var value = (type === 'checkbox') ? target.checked : target.value;

    if (name == "shippingIATA1") {
      if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
        errors[name] = "";
        errors["shippingIATA"] = "";
        errors["shippingIATA1"] = "";
        errors["shippingIATA2"] = "";
        errors["shippingCompanyName"] = "";
        if (formData.shippingIATA1.length == 7 && formData.shippingIATA2.length == 4) {
          this.handlerCheckIATACode(formData.shippingIATA1 + "" + formData.shippingIATA2, 'Shipping');
        }
      } else {
        errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
      }
    } else if (name == "shippingIATA2") {
      if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
        errors[name] = "";
        errors["shippingIATA"] = "";
        errors["shippingIATA1"] = "";
        errors["shippingIATA2"] = "";
        errors["shippingCompanyName"] = "";
        if (formData.shippingIATA1.length == 7 && formData.shippingIATA2.length == 4) {
          this.handlerCheckIATACode(formData.shippingIATA1 + "" + formData.shippingIATA2, 'Shipping');
        }
      } else {
        errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
      }
    } else if (name == "receivingIATA1") {
      if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
        errors[name] = "";
        errors["receivingIATA"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingCompanyName"] = "";
        if (formData.receivingIATA1.length == 7 && formData.receivingIATA2.length == 4) {
          this.handlerCheckIATACode(formData.receivingIATA1 + "" + formData.receivingIATA2);
        }
      } else {
        errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
      }
    } else if (name == "receivingIATA2") {
      if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
        errors[name] = "";
        errors["receivingIATA"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingCompanyName"] = "";
        if (formData.receivingIATA1.length == 7 && formData.receivingIATA2.length == 4) {
          this.handlerCheckIATACode(formData.receivingIATA1 + "" + formData.receivingIATA2);
        }
      } else {
        errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
      }
    } else {
      if (!validator.isEmpty(value)) {
        errors[name] = "";
      }
    }

    this.setState({ errors });
  }

  handlerCheckIATACode = async (iataCode, checkType = "FreightForwarder") => {
    var { errors, formData } = this.state;
    this.props.loadingActions.loadingChangeData(true);
    let response = await this.props.helpActions.checkACode(iataCode);


    // console.log("response", response);
    if (response.success && response.data.length && response.data[0].reason.toUpperCase() == "VALID") {
      // console.log("checkType", checkType);

      var scanCustData = await this.props.quotesActions.scanCompany(response.data[0]['agency-name']);
      if (scanCustData.success && scanCustData.data.length) {
        // search
        // scanCustData.data        
        let findCustomer = scanCustData.data.find(c => (c.label === response.data[0]['agency-name'].toUpperCase() && !c.custname.includes('T')));
        if (findCustomer) {
          if (checkType === 'FreightForwarder') {
            formData.receiving_company = findCustomer;
            errors.receivingCompanyName = '';
            errors['receivingIATA'] = "";
            errors['receivingIATA1'] = "";
            errors['receivingIATA2'] = "";
            if (formData.payingCustomerFreight && findCustomer.status.toUpperCase() !== 'ACTIVE') {
              formData.payingCustomerFreight = false;
            }
          } else {
            errors.shippingCompanyName = '';
            formData.shipping_company = findCustomer;
            errors['shippingIATA1'] = "";
            errors['shippingIATA2'] = "";
            if (formData.payingCustomerShipping && findCustomer.status.toUpperCase() !== 'ACTIVE') {
              formData.payingCustomerShipping = false;
            }
          }
        } else {
          if (checkType === 'FreightForwarder') {
            errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
            errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
            errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
          } else {
            errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
            errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
            errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
          }
        }
      } else {
        if (checkType === 'FreightForwarder') {
          errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
          errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
          errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
        } else {
          errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
          errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
          errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
        }
      }

    } else {

      if (checkType === 'FreightForwarder') {
        errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
        errors['receivingIATA1'] = "";
        errors['receivingIATA2'] = "";
      } else {
        errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
        errors['shippingIATA1'] = "";
        errors['shippingIATA2'] = "";
      }
    }
    await this.props.loadingActions.loadingChangeData(false);
    this.setState({ errors, formData });
  }

  isValidation() {
    this.setState({ errors: {} });
    var errors = {};
    var isError = false;
    const { formData } = this.state;

    if (formData.selectedShippingReceiving === "Shipping" || formData.payingCustomerShipping) {
      if (!formData.shipping_company) {
        errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
        isError = true;
      }
      if (!validator.isEmpty(formData.shippingIATA1) && formData.shippingIATA1.length < 7) {
        errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
        isError = true;
      }
      if (!validator.isEmpty(formData.shippingIATA2) && formData.shippingIATA2.length < 4) {
        errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
        isError = true;
      }
      if (validator.isEmpty(formData.shippingIATA1) && !validator.isEmpty(formData.shippingIATA2)) {
        errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
        isError = true;
      }
      if (!validator.isEmpty(formData.shippingIATA1) && validator.isEmpty(formData.shippingIATA2)) {
        errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
        isError = true;
      }
    }

    if (formData.selectedShippingReceiving === "Receiving" || formData.payingCustomerFreight) {
      if (!formData.receiving_company) {
        errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
        isError = true;
      }
      if (!validator.isEmpty(formData.receivingIATA1) && formData.receivingIATA1.length < 7) {
        errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
        isError = true;
      }
      if (!validator.isEmpty(formData.receivingIATA2) && formData.receivingIATA2.length < 4) {
        errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
        isError = true;
      }
      if (validator.isEmpty(formData.receivingIATA1) && !validator.isEmpty(formData.receivingIATA2)) {
        errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
        isError = true;
      }
      if (!validator.isEmpty(formData.receivingIATA1) && validator.isEmpty(formData.receivingIATA2)) {
        errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
        isError = true;
      }
    }

    if (!formData.shipping_company && (!validator.isEmpty(formData.shippingIATA1) || !validator.isEmpty(formData.shippingIATA2))) {
      errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
      isError = true;
  }

  if (!formData.receiving_company && (!validator.isEmpty(formData.receivingIATA1) || !validator.isEmpty(formData.receivingIATA2))) {
      errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
      isError = true;
  }

    // console.log(errors);
    if (isError) {
      this.setState({ errors });
      return false;
    }
    return true;
  }

  handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    var checkError = await this.isValidation();
    if (!checkError) {
      window.scrollToFirstError();
      return false;
    }
    window.jumpToTop();
    this.props.handleGSAContinue(this.state.formData);
  }

  showNoOptionCustomer = () => {
    if (this.state.noOptionsCustomer === 'Not found!') {
      return (
        <div><span>Not found!</span></div>
      );
    } else {
      return (
        <span>{this.state.noOptionsCustomer}</span>
      );
    }
  }

  handleChangeShipping = async (selectedOption) => {
    const { paticipants } = this.props;
    var { errors, formData } = this.state;
    if (Array.isArray(selectedOption) && !selectedOption.length) {
      selectedOption = null;
    }
    errors.shippingCompanyName = '';
    errors["shippingIATA"] = "";
    errors["shippingIATA1"] = "";
    errors["shippingIATA2"] = "";

    formData.shipping_company = selectedOption;

    formData.shippingIATA1 = '';
    formData.shippingIATA2 = '';
    if (selectedOption) {
      //Find iatacode
      let findParticipantShiping = paticipants.shipping.find(item => item.Custname === selectedOption.custname);
      if (findParticipantShiping) {
        formData.shippingIATA1 = findParticipantShiping.IataCode ? findParticipantShiping.IataCode.substr(0, 7).trim() : '';
        formData.shippingIATA2 = (findParticipantShiping.IataCode && findParticipantShiping.IataCode.length > 7) ? findParticipantShiping.IataCode.substr(7, findParticipantShiping.IataCode.length - 7).trim() : '';
      }
      if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
        formData.payingCustomerShipping = false;
      }
    }
    await this.setState({ formData })
  }


  handleChangeReceiving = async (selectedOption) => {
    const { paticipants } = this.props;
    var { errors, formData } = this.state;
    if (Array.isArray(selectedOption) && !selectedOption.length) {
      selectedOption = null;
    }

    errors.receivingCompanyName = '';
    errors["receivingIATA"] = "";
    errors["receivingIATA1"] = "";
    errors["receivingIATA1"] = "";
    formData.receivingIATA1 = '';
    formData.receivingIATA2 = '';
    formData.receiving_company = selectedOption;
    if (selectedOption) {
      //Find iatacode
      let findParticipantReceiving = paticipants.receiving.find(item => item.Custname === selectedOption.custname);
      if (findParticipantReceiving) {
        formData.receivingIATA1 = findParticipantReceiving.IataCode ? findParticipantReceiving.IataCode.substr(0, 7).trim() : '';
        formData.receivingIATA2 = (findParticipantReceiving.IataCode && findParticipantReceiving.IataCode.length > 7) ? findParticipantReceiving.IataCode.substr(7, findParticipantReceiving.IataCode.length - 7).trim() : '';
      }
      if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
        formData.payingCustomerFreight = false;
      }
    }
    await this.setState({ formData })
  }


  handlerSelectShippingTyping = (e) => {
    var t = this;
    var noOptionsCustomer = "Type to find company";
    // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
    if (e.length > 32) {
      e = e.substring(0, 32);
    }
    this.setState({ inputValueShippingAgent: e });
    if (this.timeoutSearchShippingAgent) {
      clearTimeout(this.timeoutSearchShippingAgent);
    }
    this.setState({ noOptionsCustomer });
    this.timeoutSearchShippingAgent = setTimeout(function () {
      t.handleScanCust('ShippingAgent');
    }, 1000);
  }

  handlerSelectReceivingTyping = (e) => {
    var t = this;
    var noOptionsCustomer = "Type to find company";
    // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
    if (e.length > 32) {
      e = e.substring(0, 32);
    }
    this.setState({ inputValueReceivingAgent: e });
    if (this.timeoutSearchShippingAgent) {
      clearTimeout(this.timeoutSearchShippingAgent);
    }
    this.setState({ noOptionsCustomer });
    this.timeoutSearchShippingAgent = setTimeout(function () {
      t.handleScanCust('ReceivingAgent');
    }, 1000);
  }

  handleScanCust = async (selectName) => {
    var { noOptionsCustomer, inputValueShippingAgent, inputValueReceivingAgent, formData, shipping_company_options, receiving_company_options } = this.state;
    var keyword = '';

    switch (selectName) {
      case 'ShippingAgent':
        keyword = clone(inputValueShippingAgent);
        keyword = keyword.trim();
        if (keyword.length > 2) {
          noOptionsCustomer = "Type to find company";
          this.setState({ isLoadingShipingAgent: true });
          var scanCustData = await this.props.quotesActions.scanCompany(inputValueShippingAgent);
          if (!scanCustData.success || !scanCustData.data.length) {
            noOptionsCustomer = "Not found!";
            shipping_company_options = [];
          } else {
            scanCustData.data = scanCustData.data.map(el => {
              el.dtType = 'dynamo';
              return el;
            });
            scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
            shipping_company_options = scanCustData.data;
          }
          this.setState({ isLoadingShipingAgent: false, noOptionsCustomer, formData, shipping_company_options });
        }
        break;
      case 'ReceivingAgent':
        keyword = clone(inputValueReceivingAgent);
        keyword = keyword.trim();
        if (keyword.length > 2) {
          var noOptionsCustomer = "Type to find company";
          this.setState({ isLoadingReceivingAgent: true });
          var scanCustData = await this.props.quotesActions.scanCompany(inputValueReceivingAgent);
          if (!scanCustData.success || !scanCustData.data.length) {
            noOptionsCustomer = "Not found!";
            receiving_company_options = [];
          } else {
            scanCustData.data = scanCustData.data.map(el => {
              el.dtType = 'dynamo';
              return el;
            });
            scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T') && el.status !== 'INACTIVE');
            receiving_company_options = scanCustData.data;
          }
          this.setState({ isLoadingReceivingAgent: false, noOptionsCustomer, formData, receiving_company_options });
        }
        break;
    }
  }

  clickClearPopulate = async (typePopulate) => {
    var { errors, formData } = this.state;
    if (typePopulate === 'shipping') {
      formData.shippingIATA1 = '';
      formData.shippingIATA2 = '';
      formData.shipping_company = null;
      errors.shippingCompanyName = '';
      errors['shippingIATA'] = "";
      errors['shippingIATA1'] = "";
      errors['shippingIATA2'] = "";
    } else {
      formData.receivingIATA1 = '';
      formData.receivingIATA2 = '';
      formData.receiving_company = null;
      errors.receivingCompanyName = '';
      errors['receivingIATA'] = "";
      errors['receivingIATA1'] = "";
      errors['receivingIATA2'] = "";
    }
    await this.setState({ formData });
  }

  render() {
    const { onShow, auth } = this.props;
    const { errors,
      receiving_company_options,
      shipping_company_options,
      isLoadingShipingAgent,
      isLoadingReceivingAgent,
      inputValueReceivingAgent,
      inputValueShippingAgent,
      formData } = this.state;
    if (!onShow) return null;

    return (
      <div
        className='ebooking modal-dialog modal-dialog-large modal-dialog-centered'
        role='document'
      >
        <div className='modal-content booking-modals gsa shadow bg-white'>
          <div className='modal-body'>
            <div className='close-button' onClick={this.props.closeModal}></div>
            <div className='modal-title'>
              <h5 className='text-uppercase'>Before You Move On</h5>
            </div>
            <div className='row'>
              <div className='col-12 text-center'>
                <div
                  className='popup-description'
                  style={{ maxWidth: '280px' }}
                >
                  Please fill in the details of the parties involved with this shipment.
                </div>
              </div>
            </div>
            <div className='row text-center mt-3'>
              <div className="col-12">
                <span className='gsa-custname'>GSA: {auth.custdes}</span>
              </div>
              <div className='col-12'>
                <div className='d-inline-block'>
                  <div className="form-check pl-2 pl-md-0">
                    <label className={`checkbox-container`}>
                      Paying customer
                      <input type="checkbox" name="payingCustomer" value="payingCustomer" disabled={(auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y') ? true : false} checked={formData.payingCustomer} onChange={this.handleChange} />
                      <span className="checkmark-checkbox "></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="d-inline-block">
                  <div className="form-group mb-0 form-radio form-rows">
                    <label className="radio-container">I am a shiping agent
                      <input type="radio" name="selectedShippingReceiving" value="Shipping" checked={formData.selectedShippingReceiving === 'Shipping' ? true : false} onClick={this.handleChange} />
                      <span className={`checkmark`}></span>
                    </label>
                    <label className="radio-container">I am a freight forwarder
                      <input type="radio" name="selectedShippingReceiving" value="Receiving" checked={formData.selectedShippingReceiving === 'Receiving' ? true : false} onClick={this.handleChange} />
                      <span className={`checkmark`}></span>
                    </label>
                    <div className="clb"></div>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div className="container no_padding block-form">
                <div className="form-content no_padding">
                  <div className="row border-between">
                    <div className="col-lg-6">
                      <div className="container-child div_center">
                        {/* Khong co bg xanh */}
                        {/* Bắt đầu shipping agent */}
                        <div className="h2-detail">Shipping Agent</div>
                        <div>
                          <div className="form-check pl-2 pl-md-0">
                            <label className={`checkbox-container`}>
                              Paying customer
                              <input type="checkbox" name="payingCustomerShipping" value="payingCustomerShipping" disabled={(formData.selectedShippingReceiving === 'Shipping' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (formData.shipping_company && formData.shipping_company.status && formData.shipping_company.status.toUpperCase() !== 'ACTIVE') ? true : false} checked={formData.payingCustomerShipping} onChange={this.handleChange} />
                              <span className="checkmark-checkbox "></span>
                            </label>
                          </div>
                        </div>
                        <div className="mt-4 div_center">
                          <div className="form-group mb-0">
                            <div className="row w-100">
                              <div className="form-group form-rows col-12">
                                <label htmlFor="shippingCompanyName">Company Name <a style={{ paddingLeft: '15px' }} className={` ${(formData.shipping_company && formData.selectedShippingReceiving === 'Receiving') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "shipping")}>Clear</a></label>

                                <Select
                                  inputId="shippingCompanyName"
                                  noOptionsMessage={this.showNoOptionCustomer}
                                  placeholder={``}
                                  className={`select-box huge-input${(errors.shippingCompanyName) ? 'input-error' : ''}`}
                                  value={formData.shipping_company}
                                  isSearchable={true}
                                  isDisabled={(formData.selectedShippingReceiving === 'Shipping' || formData.shippingIATA1 || formData.shippingIATA2) ? true : false}
                                  options={shipping_company_options}
                                  isLoading={isLoadingShipingAgent}
                                  onChange={this.handleChangeShipping}
                                  inputValue={inputValueShippingAgent}
                                  onInputChange={this.handlerSelectShippingTyping}
                                />

                                {/* <InputError error={errors.shippingCompanyName} /> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group form-rows col-12">
                                <div className="row">
                                  <div className="col-12 col-md-12">
                                    <label htmlFor="shippingIATA1">IATA Code</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-8 slash-after color-blue">
                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA1 || errors.shippingIATA) ? 'input-error' : ''}`} size="7" maxLength="7" id="shippingIATA1" name="shippingIATA1" readOnly={(formData.selectedShippingReceiving === 'Shipping' || formData.shipping_company) ? true : false} value={formData.shippingIATA1} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                    {/* <EbookingInputError error={errors.shippingIATA1} /> */}
                                  </div>
                                  <div className="col-4">
                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA2 || errors.shippingIATA) ? 'input-error' : ''}`} size="4" maxLength="4" id="shippingIATA2" name="shippingIATA2" readOnly={(formData.selectedShippingReceiving === 'Shipping' || formData.shipping_company) ? true : false} value={formData.shippingIATA2} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Kết thúc shipping agent */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="container-child div_center">
                        {/* Khong co bg xanh */}
                        {/* Bắt đầu shipping agent */}
                        <div className="h2-detail">Freight Forwarder</div>
                        <div>
                          <div className="form-check pl-2 pl-md-0">
                            <label className={`checkbox-container`}>
                              Paying customer
                              <input type="checkbox" name="payingCustomerFreight" value="payingCustomerFreight" disabled={(formData.selectedShippingReceiving === 'Receiving' && (auth.status.toUpperCase() !== 'ACTIVE' || auth.cash === 'Y')) || (formData.receiving_company && formData.receiving_company.status && formData.receiving_company.status.toUpperCase() !== 'ACTIVE') ? true : false} checked={formData.payingCustomerFreight} onChange={this.handleChange} />
                              <span className="checkmark-checkbox "></span>
                            </label>
                          </div>
                        </div>
                        <div className="mt-4 div_center">
                          <div className="form-group mb-0">
                            <div className="row w-100">
                              <div className="form-group form-rows col-12">
                                <label htmlFor="shippingCompanyName">Company Name <a style={{ paddingLeft: '15px' }} className={` ${(formData.receiving_company && formData.selectedShippingReceiving === 'Shipping') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "receiving")}>Clear</a></label>
                                <Select
                                  inputId="receivingCompanyName"
                                  noOptionsMessage={this.showNoOptionCustomer}
                                  placeholder={``}
                                  className={`select-box huge-input ${(errors.receivingCompanyName) ? 'input-error' : ''}`}
                                  value={formData.receiving_company}
                                  isSearchable={true}
                                  isDisabled={(formData.selectedShippingReceiving === 'Receiving' || formData.receivingIATA1 || formData.receivingIATA2) ? true : false}
                                  options={receiving_company_options}
                                  isLoading={isLoadingReceivingAgent}
                                  onChange={this.handleChangeReceiving}
                                  inputValue={inputValueReceivingAgent}
                                  onInputChange={this.handlerSelectReceivingTyping}
                                />
                                {/* <InputError error={errors.shippingCompanyName} /> */}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group form-rows col-12">
                                <div className="row">
                                  <div className="col-12 col-md-12">
                                    <label htmlFor="shippingIATA1">IATA Code</label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-8 slash-after color-blue">
                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA1 || errors.receivingIATA) ? 'input-error' : ''}`} size="7" maxLength="7" id="receivingIATA1" name="receivingIATA1" readOnly={(formData.selectedShippingReceiving === 'Receiving' || formData.receiving_company) ? true : false} value={formData.receivingIATA1} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                    {/* <EbookingInputError error={errors.shippingIATA1} /> */}
                                  </div>
                                  <div className="col-4">
                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA2 || errors.receivingIATA) ? 'input-error' : ''}`} size="4" maxLength="4" id="receivingIATA2" name="receivingIATA2" readOnly={(formData.selectedShippingReceiving === 'Receiving' || formData.receiving_company) ? true : false} value={formData.receivingIATA2} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Kết thúc shipping agent */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className={`ebooking form-rows mt-4`}>
            <div className={`row right-0`}>
              <div className={`col-6`}>
                <button type="button" className="btn btn-back right" onClick={this.props.closeModal}>Back to Choose Flight</button>
              </div>
              <div className={`col-6 pl-0`}>
                <div className="row">
                  <div className="form-group col-12">
                    <button type="button" className="btn btn-ebooking-orange" onClick={this.handleSubmit}>continue with Cal Agent</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingAction, dispatch),
    quotesActions: bindActionCreators(quotesActions, dispatch),
    ebookingActions: bindActionCreators(ebookingActions, dispatch),
    helpActions: bindActionCreators(helpActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBookingGSA);
