import React from 'react';
import StepProgress from '../../layouts/partials/StepProgress';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
/**
 * Validator
 */
import InputError from '../InputError';
import CountrySelect from '../CountrySelect';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
import EbookingInputError from '../EbookingInputError';
import BookingHelper from './BookingHelper';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';

var intervalID;
class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingFields: true,
            loadingFieldsError: false,
            extraPrice: 0,
            errorMessage: "",
            errorMessageOther: {},
            isEdit: false,
            errors: {},
            ebooking: {
                PricelistChoosed: {
                }
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': false,
                    'validate': true,
                    'current': false,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': true,
                    'current': true,
                    'validate': false,
                    'name': 'Review'
                }
            ],
            costDatas: [],
            total: 0,
            paymentUrl: "",
            pricingCase: "",
            firstName: "",
            lastName: "",
            scID: "",
            amount: 0,
            product_name: '',
            paymeToken: {},
            createToken: true,
            orderID: "",
            maxTimeLoadWebf: 90,
            errorLoadWebf: false,
            idNumber: '',
            submitedIDNumber: 0,
            maxSubmitIDNumber: 5
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClearDate = this.handleClearDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleBook = this.handleBook.bind(this);
        this.isValidationWithField = this.isValidationWithField.bind(this);
        this.handleFileLoad = this.handleFileLoad.bind(this);
    }

    /* Handle clear date field value */
    handleClearDate = () => {
        const { ebooking } = this.state;
        ebooking.flight_date = '';
        this.setState({ ebooking });
    }

    /* Handler date fields change value */
    handleChangeDate = (v) => {
        const { ebooking } = this.state;
        ebooking.dropoff_date = v.format('YYYY/MM/DD');
        this.setState({ ebooking });
    }

    postSafeCharge = (amount, currency, token, orderID) => {
        var { ebooking } = this.props;
        return new Promise((resolve, reject) => {
            var t = this;
            var routeFlight = {};
            var isexpress = "";
            var pl = 0;
            var parcelprice = 0.0;
            var parcelexpprice = 0.0;
            if ((ebooking.PriceQuotation.type == "WHITE" || ebooking.PriceQuotation.type == "PL") && ebooking.PriceQuotation.pl.length > 0) {
                routeFlight = ebooking.PricelistChoosed.selectedFlightData;
                delete routeFlight.uuid;
                delete routeFlight.duration;
                delete routeFlight.priceSort;
                delete routeFlight.expresspriceSort;
                pl = ebooking.PricelistChoosed.selectedPLData.plnum;
            } else if (ebooking.PriceQuotation.type == "WHITE") {
                routeFlight = ebooking.DefaultChoosed.flight;
                delete routeFlight.uuid;
                delete routeFlight.duration;
                delete routeFlight.priceSort;
                delete routeFlight.expresspriceSort;
                isexpress = ebooking.DefaultChoosed.priceType == "express" ? "Y" : "";
            } else if (ebooking.PriceQuotation.type == "PARCEL") {
                routeFlight = null;
                isexpress = ebooking.ParcelChoosed.priceType == "expressprice" ? "Y" : "";
                parcelprice = ebooking.PriceQuotation.price;
                parcelexpprice = ebooking.PriceQuotation.expressprice;
            }

            if (pl != 0 && pl != "0") {
                ebooking.passedData.Plnum = pl;
            } else {
                ebooking.passedData.Plnum = "";
            }
            ebooking.passedData.AWB = ebooking.already_awb ? ebooking.awb_number_1 + "" + ebooking.awb_number_2 : "";
            ebooking.passedData.MustRide = isexpress;

            var scTransactionData = {
                "orderID": orderID,
                "paymeToken": token,
                "currency": currency,
                // "awb": "70060424674",            
                "authcode": "",
                "amount": amount,
                "transid": "-1",
                "custname": this.props.auth.custname,
                // "custname": "11250006",
                "cust": this.props.auth.cust,
                // "cust": 0,
                "type": "CASH",
                "isexpress": isexpress,
                "pl": pl,
                "parcelpl": 0,
                "parcelprice": parcelprice,
                "parcelexpprice": parcelexpprice,
                "Participents": {
                    "SHIPNAME": ebooking.shippingCompanyName,
                    "SHIPIATACODE": ebooking.shippingIATA1 + ebooking.shippingIATA2,
                    "RECNAME": ebooking.receivingCompanyName,
                    "RECIATACODE": ebooking.receivingIATA1 + ebooking.receivingIATA2,
                    "CNORNUM": "",
                    "CNORNAME": ebooking.consignorCompanyName,
                    "CNORCOUNTRY": ebooking.consignorCountry,
                    "CNORADDRESS": ebooking.consignorAddress,
                    "CNORCITY": ebooking.consignorCity,
                    "CNORPOSTALCODE": ebooking.consignorPostalCode,
                    "CNEENUM": "",
                    "CNEENAME": ebooking.consigneeCompanyName,
                    "CNEECOUNTRY": ebooking.consigneeCountry,
                    "CNEEADDRESS": ebooking.consigneeAddress,
                    "CNEEPOSTALCODE": ebooking.consigneePostalCode,
                    "CNEECITY": ebooking.consigneeCity,
                    "PICKCOMPNAME": ebooking.pickupCompanyName,
                    "PICKNAME": ebooking.consignorGroundHandlingData.warhsname != "" ? ebooking.consignorGroundHandlingData.warhsname : ebooking.pickupNameOfContact,
                    "PICKEMAIL": ebooking.pickupEmail,
                    "PICKPHONE": ebooking.pickupPhoneNumber,
                    "PICKCOUNTRY": ebooking.pickupCountry,
                    "PICKCITY": ebooking.pickupCity,
                    "PICKADDRESS": ebooking.pickupAddress,
                    "PICKPOSTALCODE": ebooking.pickupPostalCode,
                    "DELCOMPNAME": ebooking.deliverCompanyName,
                    "DELNAME": ebooking.consigneeGroundHandlingData.warhsname != "" ? ebooking.consigneeGroundHandlingData.warhsname : ebooking.deliverNameOfContact,
                    "DELEMAIL": ebooking.deliverEmail,
                    "DELPHONE": ebooking.deliverPhoneNumber,
                    "DELCOUNTRY": ebooking.deliverCountry,
                    "DELCITY": ebooking.deliverCity,
                    "DELADDRESS": ebooking.deliverAddress,
                    "DELPOSTALCODE": ebooking.deliverPostalCode,
                    "DELOPENHOURS": ebooking.deliverOpeningHours,
                    "DELREMARKS": ebooking.deliverComments,
                    "PICKOPENHOURS": ebooking.pickupOpeningHours,
                    "PICKREMARKS": ebooking.pickupComments
                },
                "PQ": ebooking.passedData,
                "routes": routeFlight
            };

            resolve({ success: true, bookingData: scTransactionData });

            // t.props.ebookingActions.createSCTransaction(scTransactionData, this.props.auth.jwtToken).then(responseCreateSC => {
            //     if (responseCreateSC.success && responseCreateSC.data.scID != "") {
            //         t.setState({ scID: responseCreateSC.data.scID });
            //         resolve({ success: true });
            //     } else {
            //         resolve({ success: false });
            //     }
            // });

        });
    }

    async componentDidMount() {
        // console.log(this.props);
        //get redux data and set to state   
        var { ebooking, history, pricingCase, auth } = this.props;
        var { steps } = this.state;
        var authState = auth.state;
        // console.log(JSON.stringify(ebooking));
        window.addEventListener("message", this.handleFrameTasks);
        window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
        this.setState({ ebooking, pricingCase: pricingCase });
        if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        } else if (!ebooking.step_2_ok) {
            if (ebooking.PriceQuotation.pl.length > 0) {
                history.push('/ebooking/flight-pricing-pricelist');
            } else {
                switch (ebooking.PriceQuotation.type) {
                    case "WHITE":
                        history.push('/ebooking/flight-pricing-default');
                        break;
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;
                    default:
                        history.push('/ebooking/shipment');
                        break;
                }
            }
        } else if (!ebooking.step_3_ok) {
            if (auth.IsGSA) {
                history.push('/ebooking/participants-gsa');
            } else {
                history.push('/ebooking/participants');
            }
        }

        var amount = 0;
        var extraAmount = 0;
        var product_name = '';
        switch (ebooking.PriceQuotation.type) {
            case "PARCEL":
                pricingCase = "PARCEL";
                amount = ebooking.ParcelChoosed.total;
                var extraAmount = ebooking.ParcelChoosed.total * (this.state.extraPrice / 100);
                extraAmount = extraAmount.toFixed(2);
                amount += parseFloat(extraAmount);
                product_name = "Parcel " + (ebooking.ParcelChoosed.priceType == "price" ? "Economy" : "Express") + "(" + BookingHelper.currencySymbol(auth.cur) + BookingHelper.formatMoney(ebooking.ParcelChoosed.total) + ")";
                // product_name += ", Advance the extra " + this.state.extraPrice + "% (" + BookingHelper.currencySymbol(auth.cur) + BookingHelper.formatMoney(extraAmount) + ")"
                // items.push({
                //     "name": "Parcel " + (ebooking.ParcelChoosed.priceType == "price" ? "Economy" : "Express"),
                //     "price": ebooking.ParcelChoosed.total + "",
                //     "quantity": "1"
                // });
                // items.push({
                //     "name": "Advance the extra " + this.state.extraPrice + "%",
                //     "price": extraAmount,
                //     "quantity": "1"
                // });
                // await this.postSafeCharge(amount.toFixed(2) + "", auth.cur, items);
                amount = amount.toFixed(2);
                this.setState({ amount: amount, product_name: product_name });
                break;

            case "PL":
                product_name = "Agreement: " + ebooking.PricelistChoosed.selectedPLData.plnum;
                // product_name += ", Flight from " + ebooking.PricelistChoosed.selectedFlightData.fromport + " to " + ebooking.PricelistChoosed.selectedFlightData.toport;
                amount = 0.1;
                this.setState({ amount: amount, product_name: product_name });
                // items.push({
                //     "name": "Agreement: " + ebooking.PricelistChoosed.selectedPLData.plnum,
                //     "price": "1",
                //     "quantity": "1"
                // });

                // items.push({
                //     "name": "Flight from " + ebooking.PricelistChoosed.selectedFlightData.fromport + " to " + ebooking.PricelistChoosed.selectedFlightData.toport,
                //     "price": "1",
                //     "quantity": "1"
                // });
                // await this.postSafeCharge("2", auth.cur, items);
                break;
            case "WHITE":
                if (ebooking.PriceQuotation.pl.length > 0) {
                    pricingCase = "PRICELIST";
                    product_name = "Agreement: " + ebooking.PricelistChoosed.selectedPLData.plnum;
                    // product_name += ", Flight from " + ebooking.PricelistChoosed.selectedFlightData.fromport + " to " + ebooking.PricelistChoosed.selectedFlightData.toport;
                    amount = 0.1;
                    this.setState({ amount: amount, product_name: product_name });
                    // items.push({
                    //     "name": "Agreement: " + ebooking.PricelistChoosed.selectedPLData.plnum,
                    //     "price": "1",
                    //     "quantity": "1"
                    // });

                    // items.push({
                    //     "name": "Flight from " + ebooking.PricelistChoosed.selectedFlightData.fromport + " to " + ebooking.PricelistChoosed.selectedFlightData.toport,
                    //     "price": "1",
                    //     "quantity": "1"
                    // });
                    // await this.postSafeCharge("2", auth.cur, items);
                } else {
                    pricingCase = "WHITE";

                    var totalCostWeight = parseFloat(ebooking.units_total_chargable_weight) * parseFloat(ebooking.DefaultChoosed.price);
                    var flightAmount = 0;
                    flightAmount += totalCostWeight;

                    ebooking.DefaultChoosed.flight.Parts.map((part, i) => {
                        flightAmount += parseFloat(part.price);
                    });

                    var extraAmount = flightAmount * (this.state.extraPrice / 100);
                    extraAmount = extraAmount.toFixed(2);
                    amount = flightAmount + parseFloat(extraAmount);

                    product_name = "Flight: #" + ebooking.DefaultChoosed.flight.fnumber + " from " + ebooking.DefaultChoosed.flight.fromport + " to " + ebooking.DefaultChoosed.flight.toport + "(" + BookingHelper.currencySymbol(auth.cur) + BookingHelper.formatMoney(flightAmount) + ")";
                    // product_name += ", Advance the extra " + this.state.extraPrice + "% (" + BookingHelper.currencySymbol(auth.cur) + BookingHelper.formatMoney(extraAmount) + ")"
                    this.setState({ amount: amount, product_name: product_name });
                    // items.push({
                    //     "name": "Flight: #" + ebooking.DefaultChoosed.flight.fnumber + " from " + ebooking.DefaultChoosed.flight.fromport + " to " + ebooking.DefaultChoosed.flight.toport,
                    //     "price": amount.toFixed(2) + "",
                    //     "quantity": "1"
                    // });
                    // items.push({
                    //     "name": "Advance the extra " + this.state.extraPrice + "%",
                    //     "price": extraAmount,
                    //     "quantity": "1"
                    // });

                    // await this.postSafeCharge(lastAmount.toFixed(2) + "", auth.cur, items);
                }
                break;

            default:
                history.push('/ebooking/shipment');
                break;
        }
        if (auth.IsGSA) {
            steps[1].link = 'ebooking/participants-gsa';
        }
        this.setState({ steps, ebooking, pricingCase: pricingCase });


        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    componentWillUnmount() {
        if (intervalID) {
            clearInterval(intervalID);
        }
        window.cancelPayment();
        window.removeEventListener("message", this.handleFrameTasks);
    }

    handleFrameTasks = (e) => {
        var { history } = this.props;
        var { idNumber } = this.state;
        if (e.data === 'success-final') {
            history.push('/ebooking/success?scID=' + this.state.scID);
        } else if (e.data === 'load-cc-fields-success') {
            this.setState({ loadingFields: false, loadingFieldsError: false });
        } else if (e.data === 'load-cc-fields-unsuccess') {
            this.setState({ loadingFields: false, loadingFieldsError: true });
        } else {
            try {
                var jsonData = JSON.parse(e.data);
                if (jsonData.submitedid) {
                    idNumber = jsonData.submitedid;
                    this.setState({ idNumber });
                    this.handSubmit();
                }
            }
            catch (err) {
            }
        }
    }

    handleFileLoad = (event) => {
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        if (name === 'firstName') {
            this.setState({ firstName: value });
            var checkFirstName = window.testFirstName(value);
            errors.firstName = !checkFirstName.isValid;
            errors.firstNameRequire = "";
        } else if (name === 'lastName') {
            this.setState({ lastName: value });
            var checkLastName = window.testLastName(value);
            errors.lastName = !checkLastName.isValid;
            errors.lastNameRequire = "";
        }
        this.setState({ errors });
    }

    handleOnBlur = (event) => {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        if (name === 'firstName') {
            var checkFirstName = window.testFirstName(value);
            if (checkFirstName.isValid == false) {
                if (value != "") {
                    errors.firstName = !checkFirstName.isValid;
                } else {
                    errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
        } else if (name === 'lastName') {
            var checkLastName = window.testLastName(value);
            if (checkLastName.isValid == false) {
                if (value != "") {
                    errors.lastName = !checkLastName.isValid;
                } else {
                    errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
        }
        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '', errorMessageOther: {} });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        var { firstName, lastName } = this.state;
        var checkFirstName = window.testFirstName(firstName);
        if (checkFirstName.isValid == false) {
            isError = true;
            if (firstName != "") {
                errors.firstName = !checkFirstName.isValid;
                errors.firstNameRequire = "Value Invalid";
            } else {
                errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }
        var checkLastName = window.testLastName(lastName);
        if (checkLastName.isValid == false) {
            isError = true;
            if (lastName != "") {
                errors.lastName = !checkLastName.isValid;
                errors.lastNameRequire = "Value Invalid";
            } else {
                errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    isValidationWithField(event) {
        const { ebooking, errors } = this.state;
        var validator = new Validator();
        const { target } = event;
        var value = target.value;
        var isError = false;
        switch (target.name) {
        }
    }

    handleBook() {
        // console.log('book');
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    getWebf = () => {
        var t = this;
        var { maxTimeLoadWebf } = this.state;
        var timeLoadWebf = 0;
        var { history } = this.props;
        intervalID = setInterval(function () {
            if (timeLoadWebf <= maxTimeLoadWebf) {
                timeLoadWebf += 5;
                t.props.ebookingActions.getWebf(t.state.orderID).then(response => {
                    if (response.success && response.data.Success && response.data.webf != undefined) {
                        t.props.loadingActions.loadingChangeData(false);
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "docno", fieldValue: response.data.docno }).then(responseData => { });
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "webf", fieldValue: response.data.webf }).then(responseData => { });
                        if (intervalID) {
                            clearInterval(intervalID);
                        }
                        history.push('/ebooking/success');
                    }
                });
            } else {
                t.props.loadingActions.loadingChangeData(false);
                if (intervalID) {
                    clearInterval(intervalID);
                }
                t.props.ebookingActions.paymentError(t.state.orderID).then(response => {
                });
                t.setState({ errorLoadWebf: true });
            }
        }, 5000);
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (!this.isValidation()) {
            return false;
        }
        this.setState({ isError: false, errorMessage: "", errorMessageOther: {} });
        this.props.loadingActions.loadingChangeData(true);
        var { history, auth } = this.props;
        var orderID = uuid();
        var t = this;
        var { firstName, lastName, amount, product_name, paymeToken, createToken, idNumber, submitedIDNumber, maxSubmitIDNumber } = this.state;
        if (!paymeToken.data || createToken) {
            paymeToken = await window.submitTransaction(firstName, lastName, auth.user_email, auth.phonenum, orderID, auth.cur, amount + "", idNumber);
            this.setState({ paymeToken, createToken: false });
        }
        if (paymeToken.success) {
            var createRowDB = await this.postSafeCharge(amount, auth.cur, paymeToken.data.token, orderID);
            var dataPass = {
                "sale_price": parseInt(amount * 100),
                "currency": auth.cur,
                "product_name": product_name,
                "transaction_id": orderID,
                "installments": 1,
                "market_fee": 0.00,
                "sale_callback_url": AppConfig.apiUrl + "notifications",
                "sale_return_url": "http://online.cal-cargo.com",
                "capture_buyer": 0,
                "buyer_key": paymeToken.data.token,
                "sale_payment_method": "credit-card",
                "language": "en",
                "bookingData": createRowDB.bookingData
            };
            this.props.ebookingActions.doPayment(dataPass, this.props.auth.jwtToken).then(res => {
                this.props.loadingActions.loadingChangeData(false);
                if (res.success && res.data.payme_status === 'success') {
                    this.setState({ orderID: res.data.transaction_id, loadingFields: true });
                    this.props.loadingActions.loadingChangeData(true);
                    t.getWebf();
                } else {
                    window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
                    this.setState({ isError: true, errorMessageOther: res.data, errorMessage: "", createToken: true });
                }
            })
        } else {
            this.props.loadingActions.loadingChangeData(false);
            if (!paymeToken.error.payload) {
                paymeToken.error.payload = {
                    status_error_details: "credit card data invalid",
                    status_error_code: 'credit card invalid'
                }
            }

            // if (paymeToken.error.payload.status_error_code == 21007 && !submitedIDNumber) {
            if (paymeToken.error.payload.status_error_code == 21007 && submitedIDNumber < maxSubmitIDNumber) {
                submitedIDNumber++;
                createToken = true;
                this.setState({ createToken, submitedIDNumber });
                window.postMessage('show-id-number-form', '*');
            } else {
                this.setState({ isError: true, errorMessage: "", errorMessageOther: paymeToken.error.payload });
            }
        }
    }

    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, errorMessageOther, errorLoadWebf }
            = this.state;
        var { auth } = this.props;
        return (

            <div className="ebooking">
                <StepProgress stepData={steps} progress={100} onClick={this.handClickStep} />
                <div className="container">
                    {/* <Notification isError={errorMessageOther ? true : false} message={errorMessageOther} /> */}
                    <div className={`form-group ${(errorLoadWebf) ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            We encountered an error while creating your airway bill. This error is logged and our team will look into it. We apologize for the inconvenience. You may <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a> at any time.
                        </div>
                    </div>
                    <div className={`form-group ${errorMessage ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                    <div className={`form-group ${errorMessageOther.status_error_details ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Payment failed. {errorMessageOther.status_error_details ? errorMessageOther.status_error_details : ''} (error code: {errorMessageOther.status_error_code ? errorMessageOther.status_error_code : ''}). Please try again. If issue persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                </div>

                <form>
                    <div className="container block-form mb-4">
                        <h1 className="formTitle mb-5">Payment</h1>
                        <div className="form-content no_padding">
                            <div className="container-child div_center">
                                <div className="block-form-content mt-3 div_center form-payment">
                                    <div className={`col-12 form-group mt-4 ${this.state.loadingFieldsError ? '' : 'd-none'}`}>
                                        <div className="row">
                                            Load payment form error!
                                        </div>
                                    </div>
                                    <div className={`col-12 form-group bg-blue ${this.state.loadingFields ? 'loading' : ''} ${this.state.loadingFieldsError ? 'd-none' : ''}`}>
                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="firstName">Card Holder First Name</label>
                                                <input type="text" className={`form-control ${(errors.firstName) ? 'text-red' : ''} ${(errors.firstNameRequire) ? 'input-error' : ''}`} id="firstName" name="firstName" value={this.state.firstName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.firstNameRequire} />
                                            </div>
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="lastName">Card Holder Last Name</label>
                                                <input type="text" className={`form-control ${(errors.lastName) ? 'text-red' : ''} ${(errors.lastNameRequire) ? 'input-error' : ''}`} id="lastName" name="lastName" value={this.state.lastName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.lastNameRequire} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-sm-12">
                                                <label htmlFor="cardNumber">Card Number</label>
                                                <div id="card-number-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-4 col-6">
                                                <label htmlFor="expiration">Expiry</label>
                                                <div id="card-expiration-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-2 col-6">
                                                <label htmlFor="CVV">CVV</label>
                                                <div id="card-cvv-container" className="form-control"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group mt-4">
                                        <div className="row">
                                            <button type="button" className={`btn btn-block btn-ebooking-orange ${(this.state.loadingFields || this.state.loadingFieldsError) ? 'd-none' : ''}`} disabled={(this.state.loadingFields || this.state.loadingFieldsError) ? true : false} onClick={this.handSubmit}>Pay {BookingHelper.currencySymbol(auth.cur)}{BookingHelper.formatMoney(this.state.amount)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);