import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import * as MESSAGES from '../../libs/Messages';
import Progress from '../Progress';


export default class ClaimUploaded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
        }
    }

    componentDidMount() {

    }

    convertByte = (filesize) => {
        var return_value = '';
        if (filesize < 1000000) {
            return_value = Math.floor(parseInt(filesize) / 1000) + 'KB';
        } else {
            return_value = Math.floor(parseInt(filesize) / 1000000) + 'MB';
        }
        return return_value;
    }

    render() {
        return (
            <div className="claim-uploaded-element">
                <div className="row">
                    <div className="col-5 cut-text">{this.props.fileInfo.filenameshow}</div>
                    <div className="col-2">{this.convertByte(this.props.fileInfo.filesize)}</div>
                    <div className="col-3 cut-text">{this.props.fileInfo.filedes}</div>
                    <div className="col-2 text-right">
                        <a className="claim-upload-cancel-btn" onClick={this.props.onDeleteFile.bind(this, this.props.fileInfo.filename, this.props.fileIndex)}></a>
                    </div>
                </div>
            </div>
        );
    }
}

ClaimUploaded.propTypes = {
    onDeleteFile: PropTypes.func
};