
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';

export function loadingChangeData(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.LOADING_CHANGE_DATA,
            payload: inputData
        })
        return { success: true }
    }
}
