import React from 'react';
import queryString from 'query-string';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as authActions from '../ninja-redux/actions/authActions';
import * as helpActions from '../ninja-redux/actions/helpActions';

var pagesMustLogin = ["/ebooking/flight-pricing-ad-hoc",
    "/ebooking/booking-detail",
    "/ebooking/flight-pricing-default",
    "/ebooking/flight-pricing-parcel",
    "/ebooking/flight-pricing-pricelist",
    "/ebooking/participants",
    "/ebooking/review-order",
    "/ebooking/payment"];

class EbookingNoHeaderLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowLoading: false,
            modalId: "",
            loginData: "",
            calCode: "",
            iataCode: ""
        }

        this.props.children.props.history.listen((location, action) => {
            if (this.timerID) {
                clearInterval(this.timerID);
            }
            if (pagesMustLogin.indexOf(this.props.children.props.match.path) >= 0) {
                this.timerID = setInterval(
                    () => this.checkLogin(),
                    1000
                );
            }
        });
    }

    componentDidMount() {
        //this.showModalAuth('modalRegister');  

        if (!this.timerID && pagesMustLogin.indexOf(this.props.children.props.match.path) >= 0) {
            this.timerID = setInterval(
                () => this.checkLogin(),
                1000
            );
        }        
    }

    componentDidUpdate(oldProps) {
        var { history } = this.props.children.props;
        const newProps = this.props;
        if (oldProps.auth.state != "GETTING_DATA" && newProps.auth.state === "GETTING_DATA") {
            this.props.loadingActions.loadingChangeData(true);
            this.props.authActions.authGetLoginData(newProps.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
            });
        }

        if (oldProps.auth.state != "CLICK_LOG_OUT" && newProps.auth.state == "CLICK_LOG_OUT") {
            history.push("/login");
        }
    }   

    checkLogin = () => {
        if (pagesMustLogin.indexOf(this.props.children.props.match.path) >= 0 && (this.props.auth.state != 'LOGGING_IN' && this.props.auth.state != 'LOGGED_IN' && this.props.auth.state != 'NEW_PASSWORD_REQUIRED' && this.props.auth.state != 'EMAIL_VERIFICATION_REQUIRED') && !window.checkHasModalShowing()) {
            this.props.children.props.history.push('/ebooking/shipment');
        }
    }   

    render() {
        var { loading, auth, country, port } = this.props;
        const { isShowMenu, modalId } = this.state;
        return (
            <div>
                <div className={`ajax-loading ${(loading.isLoading || auth.state === 'LOGGING_IN' || auth.state === 'GETTING_DATA' || auth.state === 'AUTHENTICATED' || country.state === 'COUNTRY_GETTING_DATA' || port.state === 'PORT_GETTING_DATA') ? 'show' : 'hide'}`} >
                    {/* <div className={`ajax-loading ${(loading.isLoading || auth.state === 'LOGGING_IN' || auth.state === 'GETTING_DATA' || auth.state === 'AUTHENTICATED') ? 'show' : 'hide'}`} > */}
                    <div className="loader">
                        <div className="loader__figure"></div>
                        <p className="loader__label">Loading</p>
                    </div>
                </div>
                <div className={`ebooking`}  >
                    {this.props.children}
                </div>                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    auth: state.auth,
    country: state.country,
    port: state.port
});

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EbookingNoHeaderLayout);