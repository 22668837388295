import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as ActionTypes from '../actionTypes';
import BookingHelper from '../../components/BookingHelper';
import ModelAPI from '../api';
import ModelAuth from '../auth';
import { param } from 'jquery';
import moment from 'moment';

export function addChatItem(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'addChatItem',
            method: 'POST',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getChatItems(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getChatItems',
            method: 'POST',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getDynamoProposal(secretkey) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getDynamoProposal',
            "dataPass": {
                "secretKey": secretkey
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function declineQuote(secretKey, quoteID, chatID, reason, CProfNum, companyName, name) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'declineQuote',
            "dataPass": {
                secretKey,
                quoteID,
                reason,
                chatID,
                CProfNum, 
                companyName, 
                name
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function declineReasonsQuote(secretKey, quoteID, chatID, reason, CProfNum, companyName, name) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'declineReasonsQuote',
            "dataPass": {
                secretKey,
                quoteID,
                reason,
                chatID,
                CProfNum, 
                companyName, 
                name
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function acceptQuote(secretKey, quoteID, chatID, CProfNum, emailData) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'acceptQuote',
            "dataPass": {
                secretKey,
                quoteID,
                chatID,
                CProfNum,
                emailData
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function offerCounterQuote(secretKey, quoteID, chatID, offerData, textMessage, CProfNum, companyName, name) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'offerCounterQuote',
            "dataPass": {
                secretKey,
                quoteID,
                chatID,
                offerData,
                textMessage,
                CProfNum,
                companyName,
                name
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function downloadQuote(secretKey, quoteID, CProfNum, emailData) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'downloadQuote',
            "dataPass": {
                secretKey,
                quoteID,            
                CProfNum,
                emailData
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function getCProf(params = null) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getcprof',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getCProfDetails(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getcprofdet',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getCurrencies() {
    return async (dispatch) => {
        var link = 'getting-data?type=currencies';
        let dataReturn = await ModelAPI.getMethod(link);
        if (dataReturn.success == true) {
            var currencies = [];
            dataReturn.data.data.map(currency => {
                if (currency.exchange > 0 && currency.code && currency.code !== '$') {
                    currencies.push({ value: currency.exchange, label: currency.code });
                }
            });

            var nis = currencies.find(item => item.label === "NIS");
            var usd = currencies.find(item => item.label === "USD");
            var eur = currencies.find(item => item.label === "EUR");
            currencies = currencies.filter(item => (item.label !== "NIS" && item.label !== "USD" && item.label !== "EUR"));
            if (eur) {
                currencies.unshift(eur);
            }
            if (usd) {
                currencies.unshift(usd);
            }
            if (nis) {
                currencies.unshift(nis);
            }
            return currencies;
        } else {
            return [];
        }
    }
}

export function getBibFlights(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getbidflights',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}


export function custAlert(params) {
    return async (dispatch, getState) => {
        let dataReturn = await ModelAPI.postCalMethod('cust-alerts', params);
        return dataReturn;
    }
}

export function getDC(typeDC = "C") {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getdc',
            method: 'GET',
            dataPass: { dc: typeDC }
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getBidExp(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getbidexp',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);     

        return dataReturn;
    }
}

export function scanCust(k) {   
    return async (dispatch, getState) => {
        var dataPass = {
            "functionName": 'scancust',
            "dataPass": k           
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);

        if (dataReturn.success && dataReturn.data.length) {
            let searchRs = dataReturn.data;
            k = k.toUpperCase();
            searchRs = searchRs.filter(item => {                        
                var lastChar = item.label.substr(item.label.length - 2).toUpperCase(); // => "Tabs1"      
                return (lastChar !== "-C");
            }).sort((a, b) => {      
                a.label = a.label.toUpperCase();
                b.label = b.label.toUpperCase();
                var lastCharA = a.label.substr(a.label.length - 2);
                var lastCharB = b.label.substr(b.label.length - 2);
                var index1 = a.label.indexOf(k + " ");
                var index2 = b.label.indexOf(k + " ");
                if(lastCharA === "-T" && lastCharB !== "-T"){
                    return -1;
                }

                if (index1 !== 0 && index2 === 0) {
                    return -1;
                }else if(index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T"){
                    return -1;
                }

                var index1 = a.label.indexOf(k);
                var index2 = b.label.indexOf(k);
                if (index1 !== 0 && index2 === 0) {
                    return -1;
                }else if(index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T"){
                    return -1;
                }

                var flag1 = a.label.includes(" " + k + " ");
                var flag2 = b.label.includes(" " + k + " ");
                if (index1 !== 0 && !flag1 && flag2){
                    return -1;
                }else if(index1 !== 0 && flag1 && flag2 && lastCharA === "-T" && lastCharB === "-T"){
                    return -1;
                }

                var flag3 = a.label.includes(k);
                var flag4 = b.label.includes(k);
                if (index1 !== 0 && !flag1 && !flag3 && flag4){
                    return -1;
                }else if(index1 !== 0 && !flag1 && flag3 && flag4 && lastCharA === "-T" && lastCharB === "-T"){
                    return -1;
                }

                return 0;
            });

            dataReturn.data = searchRs.reverse();            
        }

        return dataReturn;
    }
}

export function getParticipent(custname, type, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getParticipent',
            "dataPass": {
                "custname": custname,
                "type": type
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass, configs);
        return dataReturn;
    }
}

export function getNegotiationFile(fileName, uploadFolder = null, me = true) {
    return async (dispatch, getState) => {
        var dataPass = {
            "fileName": fileName,
            uploadFolder
        };
        let dataReturn
        if (me) {
            dataReturn = await ModelAPI.getUploadedFile('get-uploaded-file', dataPass);
        } else {
            dataReturn = await ModelAPI.getUploadedFileSales('get-uploaded-file', dataPass);
        }
        return dataReturn;
    }
}