import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import uuid from 'uuid';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardActions from '../ninja-redux/actions/dashboardActions';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as helpActions from '../ninja-redux/actions/helpActions';
import BookingHelper from './ebooking/BookingHelper';

import Notification from './Notification';

class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                "Participents": {
                    "SHIPNAME": "",
                    "SHIPIATACODE": "",
                    "RECNAME": " ",
                    "RECIATACODE": " ",
                    "CNORNUM": null,
                    "CNORNAME": "",
                    "CNORCOUNTRY": "",
                    "CNORADDRESS": "",
                    "CNORCITY": "",
                    "CNORPOSTALCODE": "",
                    "CNEENUM": null,
                    "CNEENAME": "",
                    "CNEECOUNTRY": "",
                    "CNEEADDRESS": "",
                    "CNEEPOSTALCODE": "",
                    "CNEECITY": "",
                    "PICKNAME": "",
                    "PICKEMAIL": " ",
                    "PICKPHONE": " ",
                    "PICKCOUNTRY": "",
                    "PICKCITY": "",
                    "PICKADDRESS": "",
                    "PICKPOSTALCODE": "",
                    "DELNAME": "",
                    "DELEMAIL": "",
                    "DELPHONE": "",
                    "DELCOUNTRY": "",
                    "DELCITY": "",
                    "DELADDRESS": "",
                    "DELPOSTALCODE": " ",
                    "DELOPENHOURS": null,
                    "DELREMARKS": null,
                    "PICKOPENHOURS": null,
                    "PICKREMARKS": null,
                    "PICKCOMPNAME": " ",
                    "DELCOMPNAME": " "
                },
                "dim": [
                    {
                        "Height": 100,
                        "Width": 100,
                        "Length": 100,
                        "Weight": 0,
                        "Cbm": 1,
                        "Pcs": 0,
                        "unitname": "CM"
                    }
                ],
                "hs": [
                    {
                        "Code": "149",
                        "Descreption": "MUST GO /URGENT"
                    }
                ]
            },
            sector: { "fromport": "", "toport": "", "std": "", "sta": "", "fnumber": "", "price": 0, "expressprice": 0, "leg": 0, "routenum": 0 },
            pq: { Commodity: "", SubCommodity: "" },
            // sector: { "fromport": "LGG", "toport": "TLV", "std": "20/01/2019 06:45", "sta": "20/01/2019 11:00", "fnumber": "ICL974", "price": 0, "expressprice": 0, "leg": 0, "routenum": 0 },
            dataBooks: [],
            dataDetails: [],
            costDatas: [],
            cost2: [],
            cost2Row: 0,
            total: 0,
            totalExtra: 0
        };
        this.handlerPrint = this.handlerPrint.bind(this);
    }


    handlerPrint = function (e) {
        window.print();
    }

    componentDidMount() {
        var { isError, errorMessage } = this.state;
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var awb = parsedParams.awb;
        if (awb != undefined && awb != "") {
            if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
                this.convertDataFromRedux(awb);
                this.getData(awb);
            } else {
                isError = true;
                errorMessage = "Not found flight data!";
                this.setState({ isError, errorMessage });
            }
        } else {
            isError = true;
            errorMessage = "Not found AWB or webf!";
            this.setState({ isError, errorMessage });
        }
    }

    getData = async (awbNumber) => {
        var { isError, errorMessage, ebooking } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        var t = this;
        isError = false;
        this.setState({ isError: isError, errorMessage: "" });
        this.props.dashboardActions.getDetailsOrder(awbNumber).then(response => {
            t.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                ebooking = response.data;
                errorMessage = "";
                this.setState({ isError, errorMessage, ebooking });
            } else {
                isError = true;
                errorMessage = "Get AWB Data Unsuccess!";
                this.setState({ isError, errorMessage });
            }
        });
    }

    convertDataFromRedux = (awbNumber) => {
        var { sector, pq } = this.state;
        var reduxData = this.props.dashboard.data;
        var AWBData = reduxData.AWB;
        for (let i = 0; i < AWBData.length; i++) {
            var element = AWBData[i];
            if (element.PQ.AWB == awbNumber) {
                sector = element.SECTORS;
                pq = element.PQ;
            }
        }
        this.setState({ sector, pq });
    }

    getDate = (dateInput, outputFormat = "DD/MM/YYYY HH:mm") => {
        var return_data = "";
        if (dateInput) {
            var dTime = BookingHelper.getDate(dateInput, "DD/MM/YYYY HH:mm");
            return_data = dTime.format(outputFormat);
        }
        return return_data;
    }

    buildTotalWeight = (dims) => {
        var totalPcs = 0, totalVolume = 0, totalWeight = 0;
        dims.map(dim => {
            totalPcs += dim.Pcs;
            totalVolume += dim.Cbm;
            totalWeight += dim.Weight;
        });
        return (
            <div className={`row m-0 review-right-column`}>
                <div className="display-table p-0 pl-md-3 pr-md-3">
                    <div className="display-table-cell table-units">
                        <p>Total Number of pieces: {totalPcs}</p>
                        <p>Total Volume (CBM): {totalVolume}</p>
                        <p>Total Weight: {totalWeight} kg</p>
                        <p>Total Chargeable Weight: {totalWeight} kg</p>
                    </div>
                </div>
            </div>
        );
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    
    render() {
        const { ebooking, isError, errorMessage, cost2, sector, pq }
            = this.state;
        return (
            <div className={`ebooking review-order`}>
                <div className="container">
                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                </div>

                <div className={`container mt-3 mt-md-5 mb-3 mb-md-5 ${isError ? "d-none" : ""}`}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="page-title">Review Order</div>
                            <div className="page-description">This is a draft. The final order will be sent seperately.</div>
                            <div className="review-print d-none d-md-block">
                                <a type="button" className="btn-print" onClick={this.handlerPrint}></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`container ${isError ? "d-none" : ""}`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row pickup-item">
                                <div className="col-3 challenge_acepted_air">
                                </div>
                                <div className="col-9 border-right-5">
                                    <div className="row">
                                        <div className="col-md-3 col-12">
                                            <h3 className="font-weight-bold">Flight Details</h3>
                                            <p className={`font-weight-bold ${(sector.expressprice > 0) ? '' : 'd-none'}`}>Express</p>
                                            <p>{sector.fromport} - {sector.toport}</p>
                                            <p>Flight {sector.fnumber}</p>
                                            <p>ETD: {this.getDate(sector.std, "DD/MM/YY HH:mm")}</p>
                                            <p>ETA: {this.getDate(sector.sta, "DD/MM/YY HH:mm")}</p>
                                        </div>

                                        <div className="col-md-5 col-12">
                                            <p>Address</p>
                                            <div className={`${ebooking.Participents.DELNAME != "" ? "" : "d-none"} `}>
                                                <p>{ebooking.Participents.DELNAME}</p>
                                                <p>{ebooking.Participents.DELADDRESS ? ebooking.Participents.DELADDRESS : ""}</p>
                                                <p>{ebooking.Participents.DELCITY ? ebooking.Participents.DELCITY : ""}, {ebooking.Participents.DELCOUNTRY ? ebooking.Participents.DELCOUNTRY : ""}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-12">
                                            {/* <p>Pickup</p> */}
                                            <p>&nbsp;</p>
                                            <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getDate(sector.std, "DD/MM/YY HH:mm")}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`container ${isError ? "d-none" : ""}`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row commodity-item mt-5">
                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                    <div className="display-table">
                                        <div className="display-table-cell">
                                            <h3>Commodity Details</h3>
                                            <p>{pq.Commodity}/{pq.SubCommodity}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="row">
                                        <div className={`${ebooking.dim.length ? 'col-md-7 col-12' : 'col-12 d-none'}`}>
                                            <div className="row m-0 review-right-column">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Pieces</th>
                                                            <th scope="col">Lenght</th>
                                                            <th scope="col">Width</th>
                                                            <th scope="col">Height</th>
                                                            <th scope="col">Volume (CBM)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            ebooking.dim.map((op, i) => {
                                                                return <tr key={i}>
                                                                    <td scope="row">{op.Pcs}</td>
                                                                    <td>{op.Length} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Width} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Height} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Cbm} <span>{op.unitname.toLowerCase()}³</span></td>
                                                                </tr>;
                                                            })
                                                        }
                                                        <tr className={`${ebooking.dim.length ? 'd-none' : ''}`}>
                                                            <td> <div style={{ "minHeight": "20px" }}></div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className={`blue-text ${ebooking.dim.length ? 'col-md-5 col-12 ' : 'col-12'}`}>
                                            {this.buildTotalWeight(ebooking.dim)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`div-bg-grey mt-5 participants pt-4 pb-4  ${isError ? "d-none" : ""}`}>
                    <div className="container">
                        <div className="row ">
                            <div className="col-12">
                                <div className=" row ">
                                    <div className="col-12 col-md-3 mb-3 mb-md-0">
                                        <div className="display-table">
                                            <div className="display-table-cell">
                                                <h3>Participants</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className={`row m-0 review-right-column ${(ebooking.Participents.SHIPNAME != "") ? 'mb-3' : 'd-none'}`}>
                                            <div className="col-md-6 col-sm-6 col-12">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Shipping Agent</h3>
                                                    <p>
                                                        {ebooking.Participents.SHIPNAME}
                                                    </p>
                                                    <p className={`${ebooking.Participents.SHIPIATACODE.trim() ? "" : "d-none"}`}>
                                                        IATA Code: {ebooking.Participents.SHIPIATACODE}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row m-0 review-right-column">
                                            <div className="col-md-6 col-12 mb-3 mb-md-0">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignor/Shipper Details</h3>
                                                    <p className={`${(ebooking.Participents.CNORNAME.trim()) ? "" : "d-none"}`}>{ebooking.Participents.CNORNAME}</p>
                                                    <p className={`${(ebooking.Participents.CNORCOUNTRY.trim() && ebooking.Participents.CNORCITY) ? "" : "d-none"}`}>
                                                        {ebooking.Participents.CNORCOUNTRY}, {ebooking.Participents.CNORCITY}
                                                    </p>
                                                    <p className={`${(ebooking.Participents.CNORADDRESS.trim()) ? "" : "d-none"}`}>
                                                        Address: {ebooking.Participents.CNORADDRESS}
                                                    </p>
                                                    <p className={`${(ebooking.Participents.CNORPOSTALCODE.trim()) ? "" : "d-none"}`}>
                                                        Postal Code: {ebooking.Participents.CNORPOSTALCODE}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignee/Importer Details</h3>
                                                    <p className={`${(ebooking.Participents.CNEENAME.trim()) ? "" : "d-none"}`}>{ebooking.Participents.CNEENAME}</p>
                                                    <p className={`${(ebooking.Participents.CNEECOUNTRY.trim() && ebooking.CNEECITY) ? "" : "d-none"}`}>
                                                        {ebooking.Participents.CNEECOUNTRY}, {ebooking.Participents.CNEECITY}
                                                    </p>
                                                    <p className={`${(ebooking.Participents.CNEEADDRESS.trim()) ? "" : "d-none"}`}>
                                                        Address: {ebooking.Participents.CNEEADDRESS}
                                                    </p>
                                                    <p className={`${(ebooking.Participents.CNEEPOSTALCODE.trim()) ? "" : "d-none"}`}>
                                                        Postal Code: {ebooking.Participents.CNEEPOSTALCODE}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        dashboardActions: bindActionCreators(dashboardActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);