import * as ActionTypes from '../actionTypes';

const initialState = {
    data: [],
    state: "CARGO_CURRENCIES_GETTING_DATA"
};

export default function reducer(state = initialState, action) {
    switch (action.type) {   
        case ActionTypes.CARGO_CURRENCIES_DATA: {
            var newState = { ...state };
            newState['data'] = action.payload;
            newState['state'] = "CARGO_CURRENCIES_LOADED_DATA";            
            return Object.assign({}, state, newState)
        }        
    }
    return state;
}