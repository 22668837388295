import axios from 'axios';
import uuid from 'uuid';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
import BookingHelper from '../../components/ebooking/BookingHelper';
export function ebookingReset() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.EBOOKING_RESET,
            payload: {}
        })
        return { success: true }
    }
}
export function ebookingResetStep3() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.EBOOKING_RESET_STEP3,
            payload: {}
        })
        return { success: true }
    }
}

export function ebookingChangeData(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.EBOOKING_CHANGE_DATA,
            payload: inputData
        })
        return { success: true }
    }
}

export function ebookingUpdateData(ebooking) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.EBOOKING_UPDATE_DATA,
            payload: ebooking
        })
        return { success: true }
    }
}

export function getSC(awb, ord) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getSC',
            "dataPass": {
                "awbnum": awb,
                "ord": ord
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function getInstructions() {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getHandlingInstructions'
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        // let dataReturn = {success: true, data: {"handlingInstructions":[{"Code":"997","Descreption":"TNT courier"},{"Code":"999","Descreption":"General text"},{"Code":"016","Descreption":"LIVE ANIMALS"}],"Success":true,"ErrorCode":null,"ErrorDes":null}};
        return dataReturn;
    }
}

export function getParticipent(custname, type, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getParticipent',
            "dataPass": {
                "custname": custname,
                "type": type
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        if (dataReturn.success) {
            dispatch({
                type: ActionTypes.PARTICIPANT_UPDATE,
                payload: { type, data: dataReturn.data.customer }
            })
        }
        return dataReturn;
    }
}

export function getWarehouses(fport, tport) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getWarehouses',
            "dataPass": {
                "fport": fport,
                "tport": tport
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}


export function getCommodity(com) {

    return async (dispatch) => {

        var dataPass = {
            "functionName": 'getCommodities',
            "dataPass": {
                "com": com
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function submitStepOne(data, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'setPriceQuotation',
            "dataPass": data
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        if (dataReturn.success && dataReturn.data.Success && dataReturn.data.routes.length) {
            for (let i = 0; i < dataReturn.data.routes.length; i++) {
                var dStd = BookingHelper.getDate(dataReturn.data.routes[i].std, "DD/MM/YYYY HH:mm");
                var dSta = BookingHelper.getDate(dataReturn.data.routes[i].sta, "DD/MM/YYYY HH:mm");
                var wdStd = BookingHelper.getWeekday(dStd);
                var dStdSort = BookingHelper.getDate(dataReturn.data.routes[i].std, "DD/MM/YYYY HH:mm");
                var dStaSort = BookingHelper.getDate(dataReturn.data.routes[i].sta, "DD/MM/YYYY HH:mm");
                var duration = BookingHelper.getDuration(dStd, dSta);
                var flights = dataReturn.data.routes[i].flights;
                flights = flights.filter(f => f.leg != -2);
                dataReturn.data.routes[i].flights = flights;
                dataReturn.data.routes[i].uuid = uuid();
                dataReturn.data.routes[i].duration = duration;
                dataReturn.data.routes[i].wdStd = wdStd;
                dataReturn.data.routes[i].stdSort = dataReturn.data.routes[i].std ? dStdSort.format("YYYY/MM/DD HH:mm") : "";
                dataReturn.data.routes[i].staSort = dataReturn.data.routes[i].std ? dStaSort.format("YYYY/MM/DD HH:mm") : "";
                dataReturn.data.routes[i].priceSort = dataReturn.data.routes[i].price + "";
                dataReturn.data.routes[i].expresspriceSort = dataReturn.data.routes[i].expressprice + "";
            }
        }
        return dataReturn;
    }
}

export function submitSCNotification(data) {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.postMethod('scnotification', data);
        return dataReturn;
    }
}

export function setSafeCharge(data, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'setSC',
            "dataPass": data
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        var dataReturn = {};
        if (idToken != "") {
            dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
            dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        } else {
            configs = {};
            dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass, configs);
        }

        return dataReturn;
    }
}


export function createSCTransaction(data, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "dataPass": data
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        var dataReturn = {};
        if (idToken != "") {
            dataReturn = await ModelAPI.createSCMethod('create-sc-transaction-private', dataPass, configs);
        } else {
            configs = {};
            dataReturn = await ModelAPI.createSCMethod('create-sc-transaction', dataPass, configs);
        }

        return dataReturn;
    }
}

export function getWebf(scID) {

    return async (dispatch) => {

        var dataPass = {
            "functionName": 'getWebf',
            "dataPass": {
                "scID": scID
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function paymentError(scID) {

    return async (dispatch) => {

        var dataPass = {
            "functionName": 'paymentError',
            "dataPass": {
                "scID": scID
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function getPhoneZip(idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getPhoneZip',
            "dataPass": {}
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        return dataReturn;
    }
}

export function doPayment(data, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "dataPass": data
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.paymentMethod('do-payment', dataPass, configs);
        return dataReturn;
    }
}

export function doIndependentPayment(data) {
    return async (dispatch) => {
        var dataPass = {
            "dataPass": data
        };
        let dataReturn = await ModelAPI.paymentMethod('independent-payment', dataPass);
        return dataReturn;
    }
}

export function getBidMax(ord, cprof, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getbidmax',
            "dataPass": {
                cprof,
                ord
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        return dataReturn;
    }
}

export function getBidAWB(awb, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getbidawb',
            "dataPass": {
                awb
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        return dataReturn;
    }
}