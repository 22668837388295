import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import StepProgress from '../../layouts/partials/StepProgress';
import clone from 'clone';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import validator from 'validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';

/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class ParticipantsGSA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            noOptionsCustomer: "Type to find customers",
            receiving_company_options: [],
            shipping_company_options: [],
            isLoadingShipingAgent: false,
            isLoadingReceivingAgent: false,
            inputValueReceivingAgent: '',
            inputValueShippingAgent: '',
            ebooking: {
                PriceQuotation: { type: "" },
                selectedShippingReceiving: 'Shipping',
                receivingCompanyName: "",
                receivingCompanyNameSelected: null,
                receivingIATA1: "",
                receivingIATA2: "",
                shippingCompanyName: "",
                shippingCompanyNameSelected: "",
                shippingIATA1: "",
                shippingIATA2: "",
                consignorCompanyName: "",
                consignorAddress: "",
                consignorCity: "",
                consignorCountry: "",
                consignorGroundHandling: "",
                consignorPostalCode: "",
                consigneeAddress: "",
                consigneeCity: "",
                consigneeCompanyName: "",
                consigneeCountry: "",
                consigneeGroundHandling: "",
                consigneePostalCode: "",
                pickupCheck: false,
                pickupAddress: "",
                pickupCity: "",
                pickupComments: "",
                pickupCountry: "",
                pickupEmail: "",
                pickupCompanyName: "",
                pickupNameOfContact: "",
                pickupOpeningHours: "",
                pickupPhoneNumber: "",
                pickupPostalCode: "",
                deliverAddress: "",
                deliverCheck: false,
                deliverCity: "",
                deliverComments: "",
                deliverCountry: "",
                deliverEmail: "",
                deliverCompanyName: "",
                deliverNameOfContact: "",
                deliverOpeningHours: "",
                deliverPhoneNumber: "",
                deliverPostalCode: ""
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants-gsa',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            countries: [],
            isLoadingCountry: false,
            selectedCountryConsignor: null,
            selectedCountryConsignee: null,
            selectedCountryPickup: null,
            selectedCountryDelivery: null,
            showPickup: false,
            showGroundHandlingOrigin: false,
            showDelivery: false,
            showGroundHandlingDestination: false,
            showFreightForwarder: false,
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            consigneeSuggests: [],
            consignorSuggests: [],
            suggestionsReceiving: [],
            receivingSuggests: [],
            consigneeOptions: [],
            consignorOptions: [],
            selectedConsignee: null,
            selectedConsignor: null,
            editFreightForwarder: true,
            showClearConsignor: false,
            showClearConsignee: false,
            consignorGroundHandlingDisabled: false,
            consigneeGroundHandlingDisabled: false
        };
    }


    async componentDidMount() {
        //get redux data and set to state   
        var { ebooking, history, auth, paticipants } = this.props;
        var authState = auth.state;
        var t = this;
        if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        } else if (!ebooking.step_2_ok) {
            if (ebooking.PriceQuotation.pl.length > 0) {
                history.push('/ebooking/flight-pricing-pricelist');
            } else {
                switch (ebooking.PriceQuotation.type) {
                    case "WHITE":
                        history.push('/ebooking/flight-pricing-default');
                        break;
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;
                    default:
                        history.push('/ebooking/shipment');
                        break;
                }
            }
        } else if (!auth.IsGSA) {
            history.push('/ebooking/participants');
        }

        if (ebooking.selectedShippingReceiving === 'Receiving') {
            // ebooking["shippingCompanyName"] = "";
            // ebooking["shippingIATA1"] = "";
            // ebooking["shippingIATA2"] = "";
            ebooking.receivingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.receivingCompanyNameSelected = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
            ebooking.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
        } else {
            // ebooking["receivingCompanyName"] = "";
            // ebooking["receivingIATA1"] = "";
            // ebooking["receivingIATA2"] = "";
            ebooking.shippingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.shippingCompanyNameSelected = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
            ebooking.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
        }

        if (!validator.isEmpty(ebooking.shippingCompanyName) && !ebooking.shippingCompanyNameSelected) {
            ebooking.shippingCompanyName = '';
        }
        if (!validator.isEmpty(ebooking.receivingCompanyName) && !ebooking.receivingCompanyNameSelected) {
            ebooking.receivingCompanyName = '';
        }
        if (!paticipants.shipping.length) {
            this.props.ebookingActions.getParticipent(this.props.auth.custname, "SHP", this.props.auth.jwtToken);
            this.setState({ isLoadingShipingAgent: true });
            if (this.timeoutSearchShippingAgent) {
                clearTimeout(this.timeoutSearchShippingAgent);
            }
            this.timeoutSearchShippingAgent = setTimeout(function () {
                t.setState({ isLoadingShipingAgent: false });
                t.setState({ isLoadingReceivingAgent: false });
            }, 5000);
        }

        if (!paticipants.receiving.length) {
            this.props.ebookingActions.getParticipent(this.props.auth.custname, "REC", this.props.auth.jwtToken);
            this.setState({ isLoadingReceivingAgent: true });
            if (this.timeoutSearchShippingAgent) {
                clearTimeout(this.timeoutSearchShippingAgent);
            }
            this.timeoutSearchShippingAgent = setTimeout(function () {
                t.setState({ isLoadingShipingAgent: false });
                t.setState({ isLoadingReceivingAgent: false });
            }, 5000);
        }
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        const { shipping, receiving } = newProps.paticipants;
        const oldShipping = oldProps.paticipants.shipping;
        const oldReceiving = oldProps.paticipants.receiving;
        if (!oldShipping.length && shipping.length) {
            this.buildShippingOption();
        }
        if (!oldReceiving.length && receiving.length) {
            this.buildReceivingOption();
        }
    }

    buildReceivingOption = async () => {
        const { paticipants } = this.props;
        let listReceiving = [];
        paticipants.receiving.map(c => {
            listReceiving.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
        });
        this.setState({ receiving_company_options: listReceiving });
    }

    buildShippingOption = async () => {
        const { paticipants } = this.props;
        let listShipping = [];
        paticipants.shipping.map(c => {
            listShipping.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
        });
        this.setState({ shipping_company_options: listShipping });
    }


    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { ebooking } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        if (name == "shippingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors["shippingIATA"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                if (ebooking.shippingIATA1.length == 7 && ebooking.shippingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.shippingIATA1 + "" + ebooking.shippingIATA2, 'Shipping');
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "shippingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors["shippingIATA"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                if (ebooking.shippingIATA1.length == 7 && ebooking.shippingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.shippingIATA1 + "" + ebooking.shippingIATA2, 'Shipping');
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else if (name == "receivingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingCompanyName"] = "";
                if (ebooking.receivingIATA1.length == 7 && ebooking.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.receivingIATA1 + "" + ebooking.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "receivingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingCompanyName"] = "";
                if (ebooking.receivingIATA1.length == 7 && ebooking.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.receivingIATA1 + "" + ebooking.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else {
            if (!validator.isEmpty(value)) {
                errors[name] = "";
            }
        }

        this.setState({ errors });
    }

    handlerCheckIATACode = async (iataCode, checkType = "FreightForwarder") => {
        var { ebooking } = this.props;
        var { errors } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        var response = await this.props.helpActions.checkACode(iataCode);

        // console.log("response", response);
        if (response.success && response.data.length && response.data[0].reason.toUpperCase() == "VALID") {
            // console.log("checkType", checkType);

            var scanCustData = await this.props.quotesActions.scanCompany(response.data[0]['agency-name']);
            if (scanCustData.success && scanCustData.data.length) {
                // search
                // scanCustData.data        
                let findCustomer = scanCustData.data.find(c => (c.label === response.data[0]['agency-name'].toUpperCase() && !c.custname.includes('T')));
                if (findCustomer) {
                    if (checkType === 'FreightForwarder') {
                        ebooking.receivingCompanyNameSelected = findCustomer;
                        ebooking.receivingCompanyName = findCustomer.label;
                        errors.receivingCompanyName = '';
                        errors['receivingIATA'] = "";
                        errors['receivingIATA1'] = "";
                        errors['receivingIATA2'] = "";
                        if (ebooking.payingCustomerFreight && findCustomer.status.toUpperCase() !== 'ACTIVE') {
                            ebooking.payingCustomerFreight = false;
                        }
                    } else {
                        errors.shippingCompanyName = '';
                        ebooking.shippingCompanyNameSelected = findCustomer;
                        ebooking.shippingCompanyName = findCustomer.label;
                        errors['shippingIATA1'] = "";
                        errors['shippingIATA2'] = "";
                        if (ebooking.payingCustomerShipping && findCustomer.status.toUpperCase() !== 'ACTIVE') {
                            ebooking.payingCustomerShipping = false;
                        }
                    }
                } else {
                    if (checkType === 'FreightForwarder') {
                        errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
                    } else {
                        errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
                        errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
                    }
                }
            } else {
                if (checkType === 'FreightForwarder') {
                    errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
                } else {
                    errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA;
                }
            }

        } else {

            if (checkType === 'FreightForwarder') {
                errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                errors['receivingIATA1'] = "";
                errors['receivingIATA2'] = "";
            } else {
                errors['shippingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                errors['shippingIATA1'] = "";
                errors['shippingIATA2'] = "";
            }
        }
        await this.props.loadingActions.loadingChangeData(false);
        this.setState({ errors });
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { auth, ebooking } = this.props;
        var { errors, countries, editFreightForwarder, consigneeOptions, consignorOptions, showPickup, showDelivery } = this.state;
        const { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        if (name === "selectedShippingReceiving") {           
            if (ebooking["selectedShippingReceiving"] === value) {
                ebooking[name] = '';
            } else {
                ebooking[name] = value;
            }
            if (value === 'Receiving') {
                ebooking["shippingCompanyName"] = "";
                ebooking["shippingCompanyNameSelected"] = null;
                ebooking["shippingIATA1"] = "";
                ebooking["shippingIATA2"] = "";
                errors["shippingCompanyName"] = "";
                errors["shippingIATA1"] = "";
                errors["shippingIATA2"] = "";
                if (ebooking[name]) {
                    ebooking.receivingCompanyName = auth.custdes ? auth.custdes : "";
                    ebooking.receivingCompanyNameSelected = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                    ebooking.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                    ebooking.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
                } else {
                    ebooking["receivingCompanyName"] = "";
                    ebooking["receivingCompanyNameSelected"] = null;
                    ebooking["receivingIATA1"] = "";
                    ebooking["receivingIATA2"] = "";
                }
            } else {
                ebooking["receivingCompanyName"] = "";
                ebooking["receivingCompanyNameSelected"] = null;
                ebooking["receivingIATA1"] = "";
                ebooking["receivingIATA2"] = "";
                errors["receivingCompanyName"] = "";
                errors["receivingIATA1"] = "";
                errors["receivingIATA2"] = "";
                if (ebooking[name]) {
                    ebooking.shippingCompanyName = auth.custdes ? auth.custdes : "";
                    ebooking.shippingCompanyNameSelected = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "", status: auth.status ? auth.status : '' };
                    ebooking.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
                    ebooking.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
                } else {
                    ebooking["shippingCompanyName"] = "";
                    ebooking["shippingCompanyNameSelected"] = null;
                    ebooking["shippingIATA1"] = "";
                    ebooking["shippingIATA2"] = "";
                }
            }
        } else if (name === 'payingCustomer') {
            ebooking.payingCustomerShipping = false;
            ebooking.payingCustomerFreight = false;
            ebooking.payingCustomer = !ebooking.payingCustomer;
        } else if (name === 'payingCustomerShipping') {
            ebooking.payingCustomer = false;
            ebooking.payingCustomerFreight = false;
            ebooking.payingCustomerShipping = !ebooking.payingCustomerShipping;
        } else if (name === 'payingCustomerFreight') {
            ebooking.payingCustomer = false;
            ebooking.payingCustomerShipping = false;
            ebooking.payingCustomerFreight = !ebooking.payingCustomerFreight;
        } else {
            ebooking[name] = value;
        }
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        this.setState({ errors, editFreightForwarder: editFreightForwarder });

        //Change Data        
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        const { ebooking } = this.props;

        if (ebooking.selectedShippingReceiving === "Shipping" || ebooking.payingCustomerShipping) {
            if (validator.isEmpty(ebooking.shippingCompanyName)) {
                errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA1) && ebooking.shippingIATA1.length < 7) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA2) && ebooking.shippingIATA2.length < 4) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(ebooking.shippingIATA1) && !validator.isEmpty(ebooking.shippingIATA2)) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA1) && validator.isEmpty(ebooking.shippingIATA2)) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (ebooking.selectedShippingReceiving === "Receiving" || ebooking.payingCustomerFreight) {
            if (validator.isEmpty(ebooking.receivingCompanyName)) {
                errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.receivingIATA1) && ebooking.receivingIATA1.length < 7) {
                errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.receivingIATA2) && ebooking.receivingIATA2.length < 4) {
                errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(ebooking.receivingIATA1) && !validator.isEmpty(ebooking.receivingIATA2)) {
                errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.receivingIATA1) && validator.isEmpty(ebooking.receivingIATA2)) {
                errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (validator.isEmpty(ebooking.shippingCompanyName) && (!validator.isEmpty(ebooking.shippingIATA1) || !validator.isEmpty(ebooking.shippingIATA2))) {
            errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(ebooking.receivingCompanyName) && (!validator.isEmpty(ebooking.receivingIATA1) || !validator.isEmpty(ebooking.receivingIATA2))) {
            errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }


        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_3_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_3_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        window.jumpToTop();
        const { history } = this.props;
        history.push('/ebooking/review-order');
    }

    handBack = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var { ebooking, history } = this.props;
        history.push('/ebooking/flight-pricing-default');
        // switch (ebooking.PriceQuotation.type) {
        //     case "WHITE":
        //         if (ebooking.PriceQuotation.pl.length > 0) {
        //             history.push('/ebooking/flight-pricing-pricelist');
        //         } else {
        //             history.push('/ebooking/flight-pricing-default');
        //         }
        //         break;
        //     case "PL":
        //         history.push('/ebooking/flight-pricing-pricelist');
        //         break;
        //     case "PARCEL":
        //         history.push('/ebooking/flight-pricing-parcel');
        //         break;

        //     default:
        //         history.push('/ebooking/shipment');
        //         break;
        // }
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    showNoOptionCustomer = () => {
        if (this.state.noOptionsCustomer === 'Not found!') {
            return (
                <div><span>Not found!</span></div>
            );
        } else {
            return (
                <span>{this.state.noOptionsCustomer}</span>
            );
        }
    }

    handleChangeShipping = async (selectedOption) => {
        const { paticipants, ebooking, auth } = this.props;
        var { errors } = this.state;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.shippingCompanyName = '';
        errors["shippingIATA"] = "";
        errors["shippingIATA1"] = "";
        errors["shippingIATA2"] = "";
        ebooking.shippingCompanyNameSelected = selectedOption;
        ebooking.shippingCompanyName = '';
        ebooking.shippingIATA1 = '';
        ebooking.shippingIATA2 = '';
        if (selectedOption) {
            ebooking.shippingCompanyName = selectedOption.label;
            //Find iatacode
            let findParticipantShiping = paticipants.shipping.find(item => item.Custname === selectedOption.custname);
            if (findParticipantShiping) {
                ebooking.shippingIATA1 = findParticipantShiping.IataCode ? findParticipantShiping.IataCode.substr(0, 7).trim() : '';
                ebooking.shippingIATA2 = (findParticipantShiping.IataCode && findParticipantShiping.IataCode.length > 7) ? findParticipantShiping.IataCode.substr(7, findParticipantShiping.IataCode.length - 7).trim() : '';
            }
            if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
                ebooking.payingCustomerShipping = false;
            }
        }
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }


    handleChangeReceiving = async (selectedOption) => {
        const { paticipants, ebooking, auth } = this.props;
        var { errors } = this.state;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }

        errors.receivingCompanyName = '';
        errors["receivingIATA"] = "";
        errors["receivingIATA1"] = "";
        errors["receivingIATA1"] = "";
        ebooking.receivingIATA1 = '';
        ebooking.receivingIATA2 = '';
        ebooking.receivingCompanyNameSelected = selectedOption;
        ebooking.receivingCompanyName = '';
        if (selectedOption) {
            //Find iatacode
            ebooking.receivingCompanyName = selectedOption.label;
            let findParticipantReceiving = paticipants.receiving.find(item => item.Custname === selectedOption.custname);
            if (findParticipantReceiving) {
                ebooking.receivingIATA1 = findParticipantReceiving.IataCode ? findParticipantReceiving.IataCode.substr(0, 7).trim() : '';
                ebooking.receivingIATA2 = (findParticipantReceiving.IataCode && findParticipantReceiving.IataCode.length > 7) ? findParticipantReceiving.IataCode.substr(7, findParticipantReceiving.IataCode.length - 7).trim() : '';
            }
            if (selectedOption.status && selectedOption.status.toUpperCase() !== 'ACTIVE') {
                ebooking.payingCustomerFreight = false;
            }
        }
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }


    handlerSelectShippingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueShippingAgent: e });
        if (this.timeoutSearchShippingAgent) {
            clearTimeout(this.timeoutSearchShippingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchShippingAgent = setTimeout(function () {
            t.handleScanCust('ShippingAgent');
        }, 1000);
    }

    handlerSelectReceivingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueReceivingAgent: e });
        if (this.timeoutSearchShippingAgent) {
            clearTimeout(this.timeoutSearchShippingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchShippingAgent = setTimeout(function () {
            t.handleScanCust('ReceivingAgent');
        }, 1000);
    }

    handleScanCust = async (selectName) => {
        var { noOptionsCustomer, inputValueShippingAgent, inputValueReceivingAgent, shipping_company_options, receiving_company_options } = this.state;
        var keyword = '';

        switch (selectName) {
            case 'ShippingAgent':
                keyword = clone(inputValueShippingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingShipingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueShippingAgent);
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        shipping_company_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        shipping_company_options = scanCustData.data;
                    }
                    this.setState({ isLoadingShipingAgent: false, noOptionsCustomer, shipping_company_options });
                }
                break;
            case 'ReceivingAgent':
                keyword = clone(inputValueReceivingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingReceivingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueReceivingAgent);
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        receiving_company_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T') && el.status !== 'INACTIVE');
                        receiving_company_options = scanCustData.data;
                    }
                    this.setState({ isLoadingReceivingAgent: false, noOptionsCustomer, receiving_company_options });
                }
                break;
        }
    }

    clickClearPopulate = async (typePopulate) => {
        const { ebooking } = this.props;
        var { errors } = this.state;
        if (typePopulate === 'shipping') {
            ebooking.shippingIATA1 = '';
            ebooking.shippingIATA2 = '';
            ebooking.shippingCompanyName = '';
            ebooking.shippingCompanyNameSelected = null;
            errors.shippingCompanyName = '';
            errors['shippingIATA'] = "";
            errors['shippingIATA1'] = "";
            errors['shippingIATA2'] = "";
        } else {
            ebooking.receivingIATA1 = '';
            ebooking.receivingIATA2 = '';
            ebooking.receivingCompanyName = '';
            ebooking.receivingCompanyNameSelected = null;
            errors.receivingCompanyName = '';
            errors['receivingIATA'] = "";
            errors['receivingIATA1'] = "";
            errors['receivingIATA2'] = "";
        }
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }


    render() {
        let { ebooking, auth } = this.props;
        var authState = auth.state;
        const { steps, isError, errors, errorMessage,
            receiving_company_options,
            shipping_company_options,
            isLoadingShipingAgent,
            isLoadingReceivingAgent,
            inputValueReceivingAgent,
            inputValueShippingAgent }
            = this.state;
        return (
            <div className="ebooking participants">
                <StepProgress stepData={steps} progress={70} onClick={this.handClickStep} />
                <div className="container mt-5">
                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                        </div>
                    </div>
                </div>
                <div className='modal-content booking-modals bg-white'>
                    <div className="container block-form text-center">
                        <div className='modal-title'>
                            <h5 className='text-uppercase'>Before You Move On</h5>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <div
                                    className='popup-description'
                                    style={{ maxWidth: '280px' }}
                                >
                                    Please fill in the details of the parties involved with this shipment.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row text-center mt-3'>
                        <div className="col-12">
                            <span className='gsa-custname'>GSA: {this.props.auth.custdes}</span>
                        </div>
                        <div className='col-12'>
                            <div className='d-inline-block'>
                                <div className="form-check pl-2 pl-md-0">
                                    <label className={`checkbox-container`}>
                                        Paying customer
                                        <input type="checkbox" name="payingCustomer" value="payingCustomer" checked={ebooking.payingCustomer} onChange={this.handleChange} />
                                        <span className="checkmark-checkbox "></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-inline-block">
                                <div className="form-group mb-0 form-radio form-rows">
                                    <label className="radio-container">I am a shiping agent
                                        <input type="radio" name="selectedShippingReceiving" value="Shipping" checked={ebooking.selectedShippingReceiving === 'Shipping' ? true : false} onClick={this.handleChange} />
                                        <span className={`checkmark`}></span>
                                    </label>
                                    <label className="radio-container">I am a freight forwarder
                                        <input type="radio" name="selectedShippingReceiving" value="Receiving" checked={ebooking.selectedShippingReceiving === 'Receiving' ? true : false} onClick={this.handleChange} />
                                        <span className={`checkmark`}></span>
                                    </label>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="container no_padding block-form">
                            <div className="form-content no_padding">
                                <div className="row border-between">
                                    <div className="col-lg-6">
                                        <div className="container-child div_center">
                                            {/* Khong co bg xanh */}
                                            {/* Bắt đầu shipping agent */}
                                            <div className="h2-detail">Shipping Agent</div>
                                            <div>
                                                <div className="form-check pl-2 pl-md-0">
                                                    <label className={`checkbox-container`}>
                                                        Paying customer
                                                        <input type="checkbox" name="payingCustomerShipping" value="payingCustomerShipping" checked={ebooking.payingCustomerShipping} onChange={this.handleChange} />
                                                        <span className="checkmark-checkbox "></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mt-4 div_center">
                                                <div className="form-group mb-0">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="shippingCompanyName">Company Name <a style={{ paddingLeft: '15px' }} className={` ${(ebooking.shippingCompanyNameSelected && ebooking.selectedShippingReceiving === 'Receiving') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "shipping")}>Clear</a></label>

                                                            <Select
                                                                inputId="shippingCompanyName"
                                                                noOptionsMessage={this.showNoOptionCustomer}
                                                                placeholder={``}
                                                                className={`select-box huge-input ${(errors.shippingCompanyName) ? 'input-error' : ''}`}
                                                                value={ebooking.shippingCompanyNameSelected}
                                                                isSearchable={true}
                                                                isDisabled={(ebooking.selectedShippingReceiving === 'Shipping' || ebooking.shippingIATA1 || ebooking.shippingIATA2) ? true : false}
                                                                options={shipping_company_options}
                                                                isLoading={isLoadingShipingAgent}
                                                                onChange={this.handleChangeShipping}
                                                                inputValue={inputValueShippingAgent}
                                                                onInputChange={this.handlerSelectShippingTyping}
                                                            />
                                                            {/* <input type="text" className={`form-control ${(errors.shippingCompanyName) ? 'input-error' : ''}`} id="shippingCompanyName" name="shippingCompanyName" value={ebooking.shippingCompanyName} readOnly={ebooking.selectedShippingReceiving === 'Shipping' ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                            {/* <InputError error={errors.shippingCompanyName} /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <label htmlFor="shippingIATA1">IATA Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8 slash-after color-blue">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA1 || errors.shippingIATA) ? 'input-error' : ''}`} size="7" maxLength="7" id="shippingIATA1" name="shippingIATA1" value={ebooking.shippingIATA1} readOnly={(ebooking.selectedShippingReceiving === 'Shipping' || ebooking.shippingCompanyNameSelected) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                    <EbookingInputError error={errors.shippingIATA1} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA2 || errors.shippingIATA) ? 'input-error' : ''}`} size="4" maxLength="4" id="shippingIATA2" name="shippingIATA2" value={ebooking.shippingIATA2} readOnly={(ebooking.selectedShippingReceiving === 'Shipping' || ebooking.shippingCompanyNameSelected) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Kết thúc shipping agent */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="container-child div_center">
                                            {/* Khong co bg xanh */}
                                            {/* Bắt đầu shipping agent */}
                                            <div className="h2-detail">Freight Forwarder</div>
                                            <div>
                                                <div className="form-check pl-2 pl-md-0">
                                                    <label className={`checkbox-container`}>
                                                        Paying customer
                                                        <input type="checkbox" name="payingCustomerFreight" value="payingCustomerFreight" checked={ebooking.payingCustomerFreight} onChange={this.handleChange} />
                                                        <span className="checkmark-checkbox "></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mt-4 div_center">
                                                <div className="form-group mb-0">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="receivingCompanyName">Company Name <a style={{ paddingLeft: '15px' }} className={` ${(ebooking.receivingCompanyNameSelected && ebooking.selectedShippingReceiving === 'Shipping') ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "receiving")}>Clear</a></label>
                                                            <Select
                                                                inputId="receivingCompanyName"
                                                                noOptionsMessage={this.showNoOptionCustomer}
                                                                placeholder={``}
                                                                className={`select-box huge-input ${(errors.receivingCompanyName) ? 'input-error' : ''}`}
                                                                value={ebooking.receivingCompanyNameSelected}
                                                                isSearchable={true}
                                                                isDisabled={(ebooking.selectedShippingReceiving === 'Receiving' || ebooking.receivingIATA1 || ebooking.receivingIATA2) ? true : false}
                                                                options={receiving_company_options}
                                                                isLoading={isLoadingReceivingAgent}
                                                                onChange={this.handleChangeReceiving}
                                                                inputValue={inputValueReceivingAgent}
                                                                onInputChange={this.handlerSelectReceivingTyping}
                                                            />
                                                            {/* <input type="text" className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''}`} id="receivingCompanyName" name="receivingCompanyName" value={ebooking.receivingCompanyName} readOnly={ebooking.selectedShippingReceiving !== 'Shipping' ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                            {/* <InputError error={errors.receivingCompanyName} /> */}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <div className="row">
                                                                <div className="col-12 col-md-12">
                                                                    <label htmlFor="receivingIATA1">IATA Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8 slash-after color-blue">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA1 || errors.receivingIATA) ? 'input-error' : ''}`} size="7" maxLength="7" id="receivingIATA1" name="receivingIATA1" value={ebooking.receivingIATA1} readOnly={(ebooking.selectedShippingReceiving === 'Receiving' || ebooking.receivingCompanyNameSelected) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                    <EbookingInputError error={errors.receivingIATA1} />
                                                                </div>
                                                                <div className="col-4">
                                                                    <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA2 || errors.receivingIATA) ? 'input-error' : ''}`} size="4" maxLength="4" id="receivingIATA2" name="receivingIATA2" value={ebooking.receivingIATA2} readOnly={(ebooking.selectedShippingReceiving === 'Receiving' || ebooking.receivingCompanyNameSelected) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Kết thúc shipping agent */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-content mt-5">
                            <div className={`form-rows`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                        <button type="button" onClick={this.handBack} className="btn btn-back right">Back to Choose Flight</button>
                                    </div>
                                    <div className={`col-6 pl-0`}>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <button type="button" disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : false} onClick={this.handSubmit} className="btn btn-ebooking-orange">Continue to Booking</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

            </div>


        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsGSA);