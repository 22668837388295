import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
import moment from 'moment';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';

import ShipmentItem from "../../components/myaccount/element/ShipmentItem";
import clone from 'clone';
import BookingHelper from '../../components/BookingHelper';
/**
 * Validator
 */
import { bindActionCreators } from 'redux';

class ModalDashboardEditAwb extends React.Component {
    constructor(props) {
        super(props);
        const { shipmentData } = this.props;
        let shipments = clone(shipmentData);
        const oldShipments = clone(shipmentData);
        this.state = {
            errors: {},
            shipments,
            oldShipments,
            message: "",
            maxp: 10,
            ulds: {
                'HD': 'HD',
                'HDS': 'HD skid',
                'MD': 'MD',
                'MDS': 'MD skid',
                'LD': 'LD',
                'LDS': 'LD skid',
                'LOOSE': 'Loose'
            },
            maxweight: 500,
            shipmentData: null,
            showPickup: false,
            showDelivery: false,
            showFreightForwarder: false,
            showGroundHandlingOrigin: false,
            showGroundHandlingDestination: false,
            cost2: [],
        };
    }

    componentDidMount = () => {
        const { history, auth } = this.props;
    }

    handleChange = async (event) => {
        const { currentChat, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
    }

    handleClickPrint = async (e) => {

    }


    handlerPrint = (e) => {
        window.print();
    }


    buildDGClass = () => {

        const { getQuotesData } = this.props;
        var quotes = getQuotesData.quotesData;
        var classData = quotes.dg_class;
        let classValues = [];
        classData.map(c => {
            classValues.push(c.value);
        });
        if (classValues.length) {
            return (
                <p className="text-normal">
                    Class: {classValues.join(", ")}
                </p>
            );
        }
    }

    buildDGHandling = () => {

        const { getQuotesData } = this.props;
        var quotes = getQuotesData.quotesData;
        var handlingData = quotes.handling;
        if (handlingData.length) {
            return (
                <div>
                    <p className="text-normal">
                        Handling Instructions:
                    </p>
                    {
                        handlingData.map((di, i) => {
                            return (
                                <p className="text-normal" key={`di-${i}`}>
                                    {di.label}
                                </p>
                            )
                        })
                    }
                </div>
            );
        }
    }


    getFlightName = (quotes, parcel = false) => {
        var return_data = "";
        if (quotes.selected_flight_review) {
            if (parcel) {
                if (quotes.deliver_from == "Airport") {
                    return_data += quotes.deliver_from_airport + " - ";
                } else {
                    return_data += (quotes.deliver_to_airport == "TLV" ? "LGG" : "TLV") + " - ";
                }
                if (quotes.deliver_to == "Airport") {
                    return_data += quotes.deliver_to_airport;
                } else {
                    return_data += (quotes.deliver_from_airport == "TLV" ? "LGG" : "TLV") + " - ";
                }
            } else {
                return_data = quotes.selected_flight_review.fleg.fport + " - " + (quotes.selected_flight_review.tleg.tport ? quotes.selected_flight_review.tleg.tport : quotes.selected_flight_review.fleg.tport);
                if (quotes.selected_flight_review.vialeg && quotes.selected_flight_review.vialeg.tport) {
                    return_data += "( VIA " + (quotes.selected_flight_review.vialeg.fport === quotes.selected_flight_review.fleg.tport ? quotes.selected_flight_review.fleg.tport : quotes.selected_flight_review.vialeg.tport) + (quotes.selected_flight_review.vialeg.tport === quotes.selected_flight_review.tleg.fport ? ' - ' + quotes.selected_flight_review.vialeg.tport : ' - ' + quotes.selected_flight_review.tleg.fport) + " )";
                } else if (quotes.selected_flight_review.tleg.tport) {
                    return_data += "( VIA " + (quotes.selected_flight_review.tleg.fport) + " )";
                }
            }
        }

        return return_data;
    }

    getFlightDetail = (quotes, from = true) => {
        var return_data = [];
        //BOF - Doan này can mo        
        if (quotes.selected_flight_review) {
            var flight = quotes.selected_flight_review.fleg;
            if (!from && quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.fnumber) {
                flight = quotes.selected_flight_review.tleg;
            }
            var dTime = BookingHelper.getDate(flight.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
            var duration = BookingHelper.getDuration(dTime, aTime);
            return_data.push(<p key={"fnumber"}>Flight {flight.fnumber}</p>);
            return_data.push(<p key={"etd"}>ETD: {dTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"duration"}>Duration: {duration}</p>);
        }
        //EOF - Doan này can mo
        return return_data;
    }

    getTruckDetail = (quotes, from = true, ETD = true) => {
        var return_data = [];
        if (quotes.selected_flight_review) {
            if (from) {
                var flight = quotes.selected_flight_review.fleg;
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fport} - {flight.tport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            } else {
                var flight = quotes.selected_flight_review.tleg;
                if (flight && flight.fnumber) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fport} - {flight.tport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            }
        }
        return return_data;
    }

    getFlightETD = (quotes, eta = false) => {
        var return_data = "";
        if (quotes.selected_flight_review) {
            var flight = quotes.selected_flight_review.fleg;
            if (quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.fnumber) {
                flight = quotes.selected_flight_review.tleg;
            }
            var dTime = BookingHelper.getDate(flight.std, "DD/MM/YYYY HH:mm");
            if (eta) {
                dTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
            }
            return_data = dTime.format("DD/MM/YYYY HH:mm");
        }
        return return_data;
    }

    buildFlightStep = () => {
        const { getQuotesData } = this.props;
        var quotes = getQuotesData.quotesData;
        var fromCalAirport = false;
        var toCalAirport = false;

        if (quotes.deliver_from == "Airport" && quotes.deliver_from_airport.flightport == "Y") {
            fromCalAirport = true;
        }
        if (quotes.deliver_to == "Airport" && quotes.deliver_to_airport.flightport == "Y") {
            toCalAirport = true;
        }
        // parcelCase = true;
        // parcelFrom = false;
        // fromCalAirport = false;
        // toCalAirport = false;
        // console.log(parcelCase, parcelFrom, fromCalAirport, toCalAirport);
        if (fromCalAirport && toCalAirport) {
            //Flight Only
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb_air"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p>{this.getFlightName(quotes)}</p>
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    {this.getFlightDetail(quotes)}
                                </div>
                                <div className="col-4">
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (!fromCalAirport && toCalAirport) {
            //Truck - Flight 
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(quotes, true, false)}
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb_air"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes, false)}
                                    </div>
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        } else if (fromCalAirport && !toCalAirport) {
            //Flight - Truck
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb_air"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes, false)}
                                    </div>
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-6">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(quotes, false)}
                                </div>
                                <div className="col-5">
                                    <p>Address</p>
                                    <div className={`${quotes.deliverCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.deliverCompanyName}</p>
                                        {quotes.deliverAddress ? (<p>{quotes.deliverAddress}</p>) : null}
                                        <p>{quotes.deliverCity}, {quotes.deliverCountry}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            //Truck - Flight - Truck
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(quotes, true, false)}
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb_air"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes, false)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_awb"></div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-3 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(quotes, false)}
                                </div>
                                <div className="col-5 mb-3 mb-md-0">
                                    <p>Address</p>
                                    <div className={`${quotes.deliverCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.deliverCompanyName}</p>
                                        {quotes.deliverAddress ? (<p>{quotes.deliverAddress}</p>) : null}
                                        <p>{quotes.deliverCity}, {quotes.deliverCountry}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }

    render() {
        const { onShow, getQuotesData } = this.props;
        const { shipments, ulds, showFreightForwarder, showPickup, showDelivery, showGroundHandlingOrigin, showGroundHandlingDestination, cost2 } = this.state;
        if (!onShow)
            return (null);
        var parcelCase = false;
        var agreementCase = false;
        var quotes = getQuotesData.quotesData;
        return (
            <div className={`modal-dialog modal-dialog-medium modal-print quote-preview modal-dialog-centered`} role="document">
                <div className="modal-content booking-modals shadow">

                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="header-data">
                            <div className="page-title">Your Quote</div>
                            <div className="page-description">This is a draft. The final order will be sent seperately.</div>
                        </div>
                        <div className="review-print d-md-block"><a type="button" className="btn-print" onClick={this.handlerPrint}></a></div>
                        <div className="col-12">
                            {quotes.quoteID ? (<>
                                {this.buildFlightStep()}
                            </>) : null}
                            <div className="row pickup-item">
                                <div className="col-3">
                                    <div className="display-table center">
                                        <div className="display-table-cell">
                                            <div className="content">
                                                <h3>Commodity Details</h3>
                                                <p>{quotes.commodity ? quotes.commodity.label : ''}</p>
                                                <p>{quotes.sub_commodity ? quotes.sub_commodity.label : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row">
                                        <div className='col-7 mb-3 mb-md-0'>
                                            <div className="row">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className={`${quotes.unit_type.value === "uld" ? "" : "d-none"}`}>ULD Type</th>
                                                            <th scope="col">Pieces</th>
                                                            <th scope="col">Lenght</th>
                                                            <th scope="col">Width</th>
                                                            <th scope="col">Height</th>
                                                            <th scope="col">Volume (CBM)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            quotes.unit_items.map((op, i) => {
                                                                return <tr key={i}>
                                                                    <td scope="row" className={`${quotes.unit_type.value === "uld" ? "" : "d-none"}`}><span className="text-uppercase">{this.state.ulds[op.paltype]}</span></td>
                                                                    <td scope="row">{op.item_pieces}</td>
                                                                    <td>{op.item_length} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                    <td>{op.item_width} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                    <td>{op.item_height} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                    <td>{op.item_volume} <span>{quotes.unit_length.value.toLowerCase()}³</span></td>
                                                                </tr>;
                                                            })
                                                        }
                                                        <tr className={`${quotes.unit_items.length ? 'd-none' : ''}`}>
                                                            <td> <div style={{ "minHeight": "20px" }}></div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className='col-5'>
                                            <div className="display-table p-0 pr-md-3">
                                                <div className="display-table-cell table-units">
                                                    <p className="blue-text">Total Number of pieces: {quotes.unit_total.total_pieces}</p>
                                                    <p className="blue-text">Total Volume (CBM): {quotes.unit_total.total_volume}</p>
                                                    <p className="blue-text">Total Weight: {quotes.unit_total.total_weight} kg</p>
                                                    <p className="blue-text">Total Chargeable Weight: {quotes.unit_total.tcw ? parseFloat(quotes.unit_total.tcw).toFixed(1) : ""} kg</p>
                                                    <p className={`blue-text overlap ${(quotes.overlap) ? '' : 'd-none'}`}>Overlap</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${quotes.is_dangerous ? 'row pickup-item' : 'd-none'}`}>
                                <div className="col-3">
                                    <div className="display-table center">
                                        <div className="display-table-cell">
                                            <div className="content">
                                                <h3>Dangerous Goods & Special Instructions</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row">
                                        <div className={`col-12`}>
                                            <div className="row m-0 review-right-column">
                                                <p className="text-normal">
                                                    This shipment contains dangerous goods ({quotes.dg_cao_pax})
                                                            </p>
                                                {this.buildDGClass()}
                                                <p className={`text-normal ${quotes.dg_un ? '' : 'd-none'}`}>
                                                    Un's: {quotes.dg_un}
                                                </p>
                                                <p className={`text-normal ${quotes.is_lithium ? '' : 'd-none'}`}>
                                                    This shipment contains lithium batteries
                                                            </p>
                                                <p className={`text-normal ${quotes.is_shipment_unstackable ? '' : 'd-none'}`}>
                                                    This shipment is unstackable
                                                            </p>
                                                {this.buildDGHandling()}

                                                <p className={`text-normal ${quotes.additional_information ? '' : 'd-none'}`}>
                                                    Additional Information: {quotes.additional_information}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row par-item">
                                <div className="col-3">
                                    <div className="display-table center">
                                        <div className="display-table-cell">
                                            <div className="content">
                                                <h3 className="font-weight-bold">Participants</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className={`row ${(!showFreightForwarder || (showFreightForwarder && quotes.selectedShippingReceiving == "Shipping")) ? 'mb-3' : 'd-none'}`}>
                                        <div className="col-6 col-sm-6 review-right-column">
                                            <h3 className="font-weight-bold">Shipping Agent</h3>
                                            <p className={`${(quotes.shippingCompanyName) ? "" : "d-none"}`}>
                                                {quotes.shippingCompanyName}
                                            </p>
                                            <p className={`${(quotes.shippingIATA1 && quotes.shippingIATA2) ? "" : "d-none"}`}>
                                                IATA Code: {quotes.shippingIATA1}-{quotes.shippingIATA2}
                                            </p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 mb-3 review-right-column">
                                            <h3 className="font-weight-bold">Consignor/Shipper Details</h3>
                                            <p className={`${(quotes.consignorCompanyName) ? "" : "d-none"}`}>{quotes.consignorCompanyName}</p>
                                            <p className={`${(quotes.consignorCompanyName) ? "" : "d-none"}`}>
                                                {quotes.consignorCountry}, {quotes.consignorCity}
                                            </p>
                                            <p className={`${(quotes.consignorAddress) ? "" : "d-none"}`}>
                                                Address: {quotes.consignorAddress}
                                            </p>
                                            <p className={`${(quotes.consignorPostalCode) ? "" : "d-none"}`}>
                                                Postal Code: {quotes.consignorPostalCode}
                                            </p>
                                        </div>
                                        <div className="col-6 review-right-column">
                                            <h3 className="font-weight-bold">Consignee/Importer Details</h3>
                                            <p className={`${(quotes.consigneeCompanyName) ? "" : "d-none"}`}>{quotes.consigneeCompanyName}</p>
                                            <p className={`${(quotes.consigneeCompanyName) ? "" : "d-none"}`}>
                                                {quotes.consigneeCountry}, {quotes.consigneeCity}
                                            </p>
                                            <p className={`${(quotes.consigneeAddress) ? "" : "d-none"}`}>
                                                Address: {quotes.consigneeAddress}
                                            </p>
                                            <p className={`${(quotes.consigneePostalCode) ? "" : "d-none"}`}>
                                                Postal Code: {quotes.consigneePostalCode}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`row pickup-item ${parcelCase ? 'd-none' : ''}`}>
                                <div className="col-3">
                                    <div className="display-table center">
                                        <div className="display-table-cell">
                                            <div className="content">
                                                <h3 className="">
                                                    {agreementCase ? "Agreement" : "Pricings"}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Commodity Type</th>
                                                    <th scope="col">Pieces</th>
                                                    <th scope="col">Weight</th>
                                                    <th scope="col">Chagrable Weight</th>
                                                    <th scope="col">Volume (CBM)</th>
                                                    <th scope="col">Rate per kG</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white">
                                                    <td>Consolidation</td>
                                                    <td>{quotes.unit_total.total_pieces}</td>
                                                    <td>{quotes.unit_total.total_weight} <span>kg</span></td>
                                                    <td>{quotes.unit_total.tcw}  <span>kg</span></td>
                                                    <td>{quotes.unit_total.total_volume}</td>
                                                    <td>{BookingHelper.formatMoney(quotes.offer_total / quotes.unit_total.tcw)}  <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></td>
                                                    <td>{BookingHelper.formatMoney(quotes.offer_total)} <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className=" d-block d-md-none" style={{ "width": "100%" }}>
                                            <table className="table table-cost-mobile">
                                                <tbody>
                                                    <tr className="row-grey">
                                                        <td colSpan={2}>Commodity</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Consolidation</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td># of Pieces</td>
                                                        <td>Chargable Weight (kg)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{quotes.unit_total.total_pieces}</td>
                                                        <td>{quotes.unit_total.tcw}</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td>Volume (CBM)</td>
                                                        <td>Per KG rate</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{quotes.unit_total.total_volume}</td>
                                                        <td>{BookingHelper.currencySymbol(quotes.offer_currency ? quotes.offer_currency.label : '') + BookingHelper.formatMoney(quotes.offer_total)}</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td colSpan={2}>Total</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}><span>{BookingHelper.formatMoney(quotes.offer_total)}</span> {quotes.offer_currency ? quotes.offer_currency.label : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDashboardEditAwb);
