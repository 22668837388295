import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class ClaimFooter extends React.Component {
    render() {
        const { showMenuMobile, className } = this.props;
        return (
            <>

                <div className={`claim-footer footer${showMenuMobile ? ' move-right' : ''}`}>
                    <div className={`${className}`}>
                        <div className="container">
                            <div className='contact-link'>
                                <div className='contact-wrap'>
                                    <div className='text'>
                                        <h3 >NOT SURE IF IT CAN FLY? OR SIMPLY INTERESTED IN A QUICK QUOTE?​</h3>
                                    </div>
                                    <div className='link'><a href='https://challenge-group.com/contact-us/' title='Contact Us'>Contact Us</a></div>
                                </div>
                            </div>
                            <div className='content-container'>
                                <div className='footer-content p-5 p-s-0'>
                                    <div className='row text-center text-lg-left'>
                                        <div className='col-12 col-lg-3 mb-5 mb-lg-0 text-center text-lg-left'>
                                            <div className='footer-logo'>
                                                <div className='logo'>
                                                    <a href='https://challenge-group.com/contact-us/' title='Challenge Group' className="main-logo">
                                                        <img width="516" height="150" src="/images/logo_footer_challenge.png" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-3 mb-3'>
                                            <ul className='footer-links'>
                                                <li>
                                                    <a href='https://challenge-group.com/challenge-services/' title='Services'>Services</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/air-cargo-expertise/' title='Expertise'>Expertise</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/network/' title='Network'>Network</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/about-us/' title='About us'>About us</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/contact-us/' title='Contact us'>Contact us</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-12 col-lg-3 mb-3'>
                                            <ul className='footer-links'>
                                                <li>
                                                    <a href='https://www.cal-cargo.com/tracking/' title='Track & Trace'>Track & Trace</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/press/' title='Press'>Press</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/carrers/' title='Careers'>Careers</a>
                                                </li>
                                                <li>
                                                    <a href='https://online.cal-cargo.com/claim' title='Claims'>Claims</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-12 col-lg-3 mb-3'>
                                            <ul className='footer-links'>
                                                <li>
                                                    <a href='https://challenge-group.com/terms-and-conditions/' title='Terms and Conditions'>Terms and Conditions</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/privacy-notice/' title='Privacy Notice'>Privacy Notice</a>
                                                </li>
                                                <li>
                                                    <a href='https://challenge-group.com/cookies-policy/' title='Cookies policy'>Cookies policy</a>
                                                </li>
                                                <li>
                                                    <a href='https://www.linkedin.com/company/ace-aviation-group' title='Linkedin'><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default ClaimFooter;
