import React from 'react';
import { Link } from 'react-router-dom';

class Nav extends React.Component {
    handlerPrint = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.print();
    }

    render() {
        const { index } = this.props;
        let listLink = ['My Shipments', 'My Quotes', 'My Info'];

        return (
            <div className="account-nav">
                <div className="line"></div>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>{listLink[index - 1]}</span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" to="/dashboard">My Shipments</Link>
                        <Link className="dropdown-item" to="/dashboard/quotes">My Quotes</Link>
                        <Link className="dropdown-item" to="/account">My Info</Link>
                    </div>
                </div>
                <ul className="acc-menu">
                    <li className={`${index === 1 ? 'active' : ''}`}><Link to="/dashboard">My Shipments</Link></li>
                    <li className={`${index === 2 ? 'active' : ''}`}><Link to="/dashboard/quotes">My Quotes</Link></li>
                    <li className={`${index === 3 ? 'active' : ''}`}><Link to="/account">My Info</Link></li>
                </ul>
                <ul className="acc-task">
                    <li><Link to="/ebooking"><i className="i-icon i-plus"></i>New Shipment</Link></li>
                    <li><a href="https://www.cal-cargo.com/tracking/" target="_blank"><i className="i-icon i-track"></i>Track Shipment</a></li>
                    <li><a onClick={this.handlerPrint}><i className="i-icon i-print"></i>Print</a></li>
                </ul>
                <div className="clear"></div>
            </div>
        );
    }
}

export default Nav