import React from 'react';
import PropTypes from 'prop-types';

export default class Progress extends React.Component {
    constructor(props) {
        super();
    }
    
    render() {
        return (
            <div className={`progress ${this.props.showProgress ? '' : 'hide'}`}>
                <div className="progress-bar" style={{ width: this.props.loaded + '%' }}>{this.props.loaded}%</div>
            </div>
        )
    }
}

Progress.propTypes = {

}