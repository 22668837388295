import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import AppConfig from '../AppConfig';
import PropTypes from 'prop-types';
/**
 * Validator
 */
import InputError from './InputError';
import Validator from '../libs/Validator';
import * as MESSAGES from '../libs/Messages';
import Progress from './Progress';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';

class FilesUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            selectedType: '',
            loaded: 0,
            showProgress: false,
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'xls', 'xlsx', 'csv', 'ppt', 'pptx'],
            maxFileSize: 9
        }

        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    componentDidMount() {

    }

    handleUploadFile(e) {
        var selectorFiles = e.target.files;
        var file = selectorFiles[0];
        this.uploadFile(file);
        e.target.value = null;
    }

    changeProgress = (loaded, total) => {
        var loadedPer = 0;
        if (total > 0) {
            loadedPer = parseInt((loaded / total) * 100);
        }
        this.setState({ loaded: loadedPer });
    }

    makerandom = (length) => {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    uploadFile(file) {
        var reader = new FileReader();
        var eObj = this;
        eObj.props.onShowError("");
        this.props.loadingActions.loadingChangeData(true);
        reader.addEventListener('loadend', function (e) {
            var name = file.name;
            var nameSplit = name.split('.');
            var fileName = nameSplit[0];
            var filesize = file.size;
            var fileNameBucket = name;
            if (fileName.length < 65) {
                eObj.setState({ loaded: 0, showProgress: true });
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();
                fileName = fileName.replace(/[^\w]/gi, '-');
                var fileNewName = fileName + "-" + eObj.makerandom(5) + "." + ext;

                if ((eObj.state.acceptFiles.indexOf(ext) > -1) && filesize <= (eObj.state.maxFileSize * 1048576)) {
                    fileNewName = eObj.props.subFolder + '/' + fileNewName;
                    fetch(AppConfig.apiUpload + "api/service/upload-claim-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            fileNameBucket = json.body.fileName;
                            eObj.setState({ loaded: 0, showProgress: true });
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total);
                                }
                            })
                        })
                        .then(function () {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);

                            var fileData = {
                                tempFolder: eObj.props.subFolder,
                                filenameshow: fileNameBucket,
                                filename: fileNameBucket,
                                filedes: eObj.state.selectedType,
                                filesize: filesize,
                                selfie: false,
                                fileExt: ext
                            }
                            eObj.props.onSuccess(fileData, eObj.props.fileIndex);
                        }).catch(e => {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);
                            eObj.setState({ selectedFileType: false, loaded: 0, showProgress: false });
                        });
                } else {
                    eObj.setState({ loaded: 0, showProgress: false });
                    eObj.props.loadingActions.loadingChangeData(false);
                    // alert("Please check you file type and file size!");
                    eObj.props.onShowError('Selected file ' + name + ' is not supported for upload');

                }
            } else {
                eObj.setState({ loaded: 0, showProgress: false });
                eObj.props.loadingActions.loadingChangeData(false);
                // alert("Your file name too long. It should less than 80 characters!");
                eObj.props.onShowError("Your file name too long. It should less than 80 characters!");
            }
        });

        reader.readAsArrayBuffer(file);

    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() {

        return (
            <div className="row">
                <div className={`col-12 ${this.state.showProgress ? 'd-none' : ''}`}>
                    <div className={`uploading-files input-group position-relative shadow`}>
                        <div className="input-group-append">
                            <input type="file" id="file-upload" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx" className="btn btn-files" onChange={this.handleUploadFile} />
                            <div className="icon-attach"></div>
                        </div>
                        <label htmlFor="file-upload" className="custom-file-upload">Attach files</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="col-12 mt-2">
                        <Progress showProgress={this.state.showProgress} loaded={this.state.loaded} />
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
    }
}
FilesUpload.propTypes = {
    onSuccess: PropTypes.func,
    fileIndex: PropTypes.string,
    subFolder: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesUpload);