import axios from 'axios';
import AppConfig from '../AppConfig';
import * as ActionTypes from './actionTypes';
import ModelAuth from './auth';
var url_prefix = AppConfig.apiUrl;
var url_cal_prefix = AppConfig.apiCalUrl;
var url_upload_prefix = AppConfig.apiUpload;
class ModelAPI {
    static async getConfigAPI() {
        var configs = {
            headers: {
                'Authorization': await ModelAuth.getIdToken()
            }
        };
        return configs;
    }

    static async checkexistMethod(url, dataPost) {
        return new Promise((resolve, reject) => {
            axios.post(url_prefix + url, dataPost)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body });
                    } else {
                        resolve({ success: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }

    static async getMethod(url) {
        return new Promise((resolve, reject) => {
            axios.get(url_prefix + url)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body.dataResponse });
                    } else {
                        resolve({ success: false, errorCode: resultData.data.body.ErrorCode ? resultData.data.body.ErrorCode : '' });
                    }
                }).catch(function (err) {
                    resolve({ success: false, errorCode: 'APIERROR' });
                });
        });
    }

    static async getPrivateMethod(url, config) {
        return new Promise((resolve, reject) => {
            axios.get(url_prefix + url, config)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body.dataResponse });
                    } else {
                        resolve({ success: false, errorCode: resultData.data.body.ErrorCode ? resultData.data.body.ErrorCode : '' });
                    }
                }).catch(function (err) {
                    resolve({ success: false, errorCode: 'APIERROR' });
                });
        });
    }

    static async putMethod(url, dataPut) {
        return new Promise((resolve, reject) => {
            axios.put(url_prefix + url, dataPut)
                .then(function (resultData) {
                    if (resultData.data.dataResponse.success) {
                        resolve({ success: true, data: resultData.data.dataResponse });
                    } else {
                        resolve({ success: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }
    static async postMethod(url, dataPost, configs = null) {
        configs = configs ? configs : await this.getConfigAPI();
        return new Promise((resolve, reject) => {
            axios.post(url_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body.dataResponse, passedData: resultData.data.body.pasData ? resultData.data.body.pasData : {} });
                    } else {
                        resolve({ success: false, errorCode: resultData.data.body.ErrorCode ? resultData.data.body.ErrorCode : '', relogin: false, tryagain: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false, errorCode: 'APIERROR', relogin: (url === "private-api" || url === "proposals") ? true : false, tryagain: (url === "private-api" || url === "proposals") ? true : false });
                });
        });
    }
    static async postCalMethod(url, dataPost, configs= null) {
        configs = configs ? configs : await this.getConfigAPI();
        return new Promise((resolve, reject) => {
            axios.post(url_cal_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body.dataResponse, passedData: resultData.data.body.pasData ? resultData.data.body.pasData : {} });
                    } else {
                        resolve({ success: false, errorCode: resultData.data.body.ErrorCode ? resultData.data.body.ErrorCode : '', relogin: false, tryagain: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false, errorCode: 'APIERROR', relogin: url === "private-api" ? true : false, tryagain: (url === "private-api") ? true : false });
                });
        });
    }

    static async paymentMethod(url, dataPost, configs = null) {
        configs = configs ? configs : await this.getConfigAPI();
        return new Promise((resolve, reject) => {
            axios.post(url_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    if (resultData.data.body.success) {
                        resolve({ success: true, data: resultData.data.body.dataResponse, passedData: resultData.data.body.pasData ? resultData.data.body.pasData : {} });
                    } else {
                        resolve({ success: false, relogin: false, data: resultData.data.body.ErrorDes.dataResponse, tryagain: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false, data: { status_error_code: "500", status_error_details: "Connect to payment url failed." }, tryagain: (url === "private-api") ? true : false });
                });
        });
    }

    static async createSCMethod(url, dataPost, configs) {
        return new Promise((resolve, reject) => {
            axios.post(url_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    if (resultData.data.body.Success) {
                        resolve({ success: true, data: resultData.data.body });
                    } else {
                        resolve({ success: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }

    static async uploadMethod(url, file) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append('file', file)
        return new Promise((resolve, reject) => {
            axios.post(url_upload_prefix + url, formData, config)
                .then(function (resultData) {
                    if (resultData.data.success) {
                        resolve({ success: true, data: resultData.data });
                    } else {
                        resolve({ success: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }

    static async doPayment(dataPost) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return new Promise((resolve, reject) => {
            axios.post(AppConfig.paymeAPI, dataPost, config)
                .then(function (resultData) {
                    if (resultData.status == 200) {
                        resolve({ success: true, data: resultData.data });
                    } else {
                        resolve({ success: false });
                    }
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }

    static async refreshTokenOnAPI(dataReturn, dispatch, apiParams) {
        // console.log(dataReturn);
        if (!dataReturn.success && dataReturn.tryagain) {
            var newJwt = await ModelAuth.doGetSession();
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: 'jwtToken', fieldValue: newJwt.jwtToken }
            });

            ModelAuth.setIdToken(newJwt.jwtToken);
            dataReturn = await this.postMethod('private-api', apiParams);
            return dataReturn;
        }

        return dataReturn;
    }

    static async refreshTokenOnAPIProposal(dataReturn, dispatch, apiParams) {
        // console.log(dataReturn);
        if (!dataReturn.success && dataReturn.tryagain) {
            var newJwt = await ModelAuth.doGetSession();
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: 'jwtToken', fieldValue: newJwt.jwtToken }
            });

            ModelAuth.setIdToken(newJwt.jwtToken);
            dataReturn = await this.postMethod('proposals', apiParams);
            return dataReturn;
        }

        return dataReturn;
    }
    static async getUploadedFile(url, dataPost, configs) {
        configs = await this.getConfigAPI();
        return new Promise((resolve, reject) => {
            axios.post(url_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    resolve({ success: true, data: resultData.data.body });
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }

    static async getUploadedFileSales(url, dataPost, configs) {
        configs = await this.getConfigAPI();
        return new Promise((resolve, reject) => {
            axios.post(url_cal_prefix + url, dataPost, configs)
                .then(function (resultData) {
                    resolve({ success: true, data: resultData.data.body });
                }).catch(function (err) {
                    resolve({ success: false });
                });
        });
    }
}

export default ModelAPI;