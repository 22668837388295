import axios from 'axios';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
import ModelAuth from '../auth';
import uuid from 'uuid';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import config from '../../config.json';

export function setExpiredToken(v) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_CHANGE_DATA,
            payload: { fieldName: "expiredToken", fieldValue: v }
        });
        return { success: true }
    }
}

export function authGetLoginData(idToken) {
    // console.log("authGetLoginData");
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getLoginData'
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        if (dataReturn.success && dataReturn.data.Success) {
            dispatch({
                type: ActionTypes.DASHBOARD_RESET,
                payload: ""
            });
            dispatch({
                type: ActionTypes.AUTH_USER_DATA,
                payload: dataReturn.data
            });
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: "expiredToken", fieldValue: false }
            });
        } else {
            dispatch({
                type: ActionTypes.AUTH_RESET,
                payload: {}
            });
        }
        return dataReturn;
    }
}

export function authUpdateState(state) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_UPDATE_STATE,
            payload: state
        });
        return { success: true }
    }
}

export function authReset() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_RESET,
            payload: {}
        });
        dispatch({
            type: ActionTypes.PARTICIPANT_RESET,
            payload: { }
        })
        return { success: true }
    }
}

export function authChangeData(data) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_CHANGE_DATA,
            payload: data
        });
        return { success: true }
    }
}
//login
export function authLogin(username, password) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_CHANGE_DATA,
            payload: { fieldName: "user_email", fieldValue: username }
        });
        dispatch({
            type: ActionTypes.AUTH_UPDATE_STATE,
            payload: ActionTypes.LOGGING_IN
        });
        var return_data = await ModelAuth.doLogin(username, password);
        // console.log("return_data", return_data);
        if (return_data.success) {
            if (return_data.jwtToken != "") {
                dispatch({
                    type: ActionTypes.GETTING_DATA,
                    payload: return_data.jwtToken
                });
            } else {
                switch (return_data.message) {
                    case "CONFIRMATION_REQUIRED":
                        dispatch({
                            type: ActionTypes.AUTH_UPDATE_STATE,
                            payload: "CONFIRMATION_REQUIRED"
                        });
                        break;
                    case "NEW_PASSWORD_REQUIRED":
                        dispatch({
                            type: ActionTypes.AUTH_UPDATE_STATE,
                            payload: "NEW_PASSWORD_REQUIRED"
                        });
                        break;

                    default:
                        dispatch({
                            type: ActionTypes.AUTH_RESET,
                            payload: ""
                        });
                        break;
                }
            }
        } else {
            dispatch({
                type: ActionTypes.PARTICIPANT_RESET,
                payload: { }
            })
            dispatch({
                type: ActionTypes.AUTH_RESET,
                payload: ""
            });
            dispatch({
                type: ActionTypes.AUTH_UPDATE_STATE,
                payload: ActionTypes.LOGGED_OUT
            });
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: "expiredToken", fieldValue: false }
            });

        }
        return return_data;
    }
}

//get current user
export function authGetSession() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_UPDATE_STATE,
            payload: ActionTypes.LOGGING_IN
        });
        var return_data = await ModelAuth.doGetSession();
        if (return_data.success) {
            dispatch({
                type: ActionTypes.GETTING_DATA,
                payload: return_data.jwtToken
            });
        } else {
            dispatch({
                type: ActionTypes.AUTH_RESET,
                payload: ""
            });
        }
        return return_data;
    }
}

//signup
export function authSignup(username, password, attributeList) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doSignup(username, password, attributeList);
        dispatch({
            type: ActionTypes.AUTH_UPDATE_STATE,
            payload: ActionTypes.LOGGED_OUT
        });
        return return_data;
    }
}


//signup
export function checkEmailExists(email) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'checkEmailExists',
            "email": email
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return true;
    }
}

//signup - confirm
export function authConfirmRegistration(username, verifyCode, loginData) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doConfirmRegistration(username, verifyCode);
        return_data.showLoginForm = true;
        if (return_data.success) {
            dispatch({
                type: ActionTypes.AUTH_RESET,
                payload: {}
            });
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: "user_email", fieldValue: username }
            });
            dispatch({
                type: ActionTypes.AUTH_UPDATE_STATE,
                payload: ActionTypes.LOGGING_IN
            });
            return_data = await ModelAuth.doLogin(username, loginData);
            if (return_data.success && return_data.jwtToken != "") {                
                return_data.showLoginForm = false;
                dispatch({
                    type: ActionTypes.GETTING_DATA,
                    payload: return_data.jwtToken
                });
            } else {
                window.parent.postMessage("doLoginErrorMessage-Incorrect username or password", "*");
                dispatch({
                    type: ActionTypes.AUTH_RESET,
                    payload: {}
                });
                return_data.success = false;
            }
        } else {
            return_data.success = true;
        }
        return return_data;
    }
}

//signup - new password requied
export function authCompleteNewPasswordChallenge(username, newPassword) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doCompleteNewPasswordChallenge(username, newPassword);
        return return_data;
    }
}

//Signup - resend confirm code
export function authResendConfirmationCode(username) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doResendConfirmationCode(username);
        return return_data;
    }
}

//Forgot pass - Verification Code
export function authSendVerificationCode(username) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doSendVerificationCode(username);
        return return_data;
    }
}

//forgot pass - set new pass
export function authConfirmPassword(username, verificationCode, newPassword) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doConfirmPassword(username, verificationCode, newPassword);
        return return_data;
    }
}

//loged-in - change passs
export function authChangePassword(oldPassword, newPassword) {
    return async (dispatch) => {
        var return_data = await ModelAuth.doChangePassword(oldPassword, newPassword);
        return return_data;
    }
}

export function authLogout() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.CLICK_LOG_OUT,
            payload: ""
        });
        dispatch({
            type: ActionTypes.DASHBOARD_RESET,
            payload: ""
        });
        dispatch({
            type: ActionTypes.PARTICIPANT_RESET,
            payload: { }
        })
        let dataReturn = await ModelAuth.doSignout();
        return { success: true };
    };
}

export function getCustomerInfo(data_passed) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getCust',
            "dataPass": data_passed
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function requestNewAccount(data_passed) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'registerRequestNewAccount',
            "dataPass": data_passed
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function checkUserExist(username) {
    return async (dispatch) => {
        var dataPass = {
            "username": username
        };

        let dataReturn = await ModelAPI.checkexistMethod('check-user-exist', dataPass);
        return dataReturn;
    }
}

export function createCalClient(data_passed) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'setCust',
            "dataPass": data_passed
        };

        dispatch({
            type: ActionTypes.AUTH_UPDATE_STATE,
            payload: ActionTypes.LOGGING_IN
        });

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        dispatch({
            type: ActionTypes.AUTH_RESET,
            payload: {}
        });
        return dataReturn;
    }
}