import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class StepProgress extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { stepData, progress } = this.props;
        var w = 100 / stepData.length;
        var activeIndex = stepData.findIndex(item => item.current === true);
        // var progress = (activeIndex + 1) * w;        
        return (
            <div className="div-steps-progress">
                <ul className={`nav nav-pills`}>
                    {stepData.map((step, i) => {
                        if (step.validate) {
                            return (<li key={i} className={`${step.active ? 'active' : ''}`} style={{ width: w + '%' }}> <Link onClick={this.props.onClick.bind(this)} to={`${process.env.PUBLIC_URL}/${step.link}`}>{step.name}</Link></li>)
                        } else {
                            return (<li key={i} className={`${step.active ? 'active' : ''}`} style={{ width: w + '%' }}> <Link to={`${process.env.PUBLIC_URL}/${step.link}`}>{step.name}</Link></li>)
                        }
                    })}
                </ul>
                <div className="step-progress">
                    <div className="step-progress-persion" style={{ width: progress + "%" }}></div>
                </div>
            </div>
        );
    }
}

StepProgress.propTypes = {
    onClick: PropTypes.func,
};

