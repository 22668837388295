import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';
export default class ModalPaymentID extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            isErrorLogin: false,
            errors: {},
            dataForm: {
                'idnumber': ''
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.validateWithField = this.validateWithField.bind(this);
        this.handSubmitID = this.handSubmitID.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        var { dataForm } = this.state;
        dataForm.idnumber = '';
        this.setState({ dataForm });
        var txtIDNumber = ReactDOM.findDOMNode(this.refs.txtIDNumber);
        if (txtIDNumber) {
            txtIDNumber.focus();
        }
    };

    handlePressed = (e) => {
        if (e.key === "Enter") {
            this.handSubmitID();
        }
    }

    /* handler form's fields change value */
    handleChange(event) {
        var { dataForm, errors } = this.state;
        const { target, target: { name, type } } = event;
        dataForm[name] = target.value;
        errors[name] = '';
        if (name == 'idnumber') {
            dataForm[name] = dataForm[name].toLowerCase();
        }
        this.setState({ dataForm, errors });
    }

    validateWithField(event) {
        var { dataForm, errors } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        errors.idnumber = '';
        switch (target.name) {
            case 'idnumber':
                if (!validator.required(dataForm.idnumber)) {
                    errors['idnumber'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errors['idnumber'] = '';
                }
                break;
        }
        this.setState({ errors });
    }

    validate(event) {
        this.setState({ errors: {} });
        const { dataForm } = this.state;
        var errors = {};
        var isError = false;
        var validator = new Validator();
        if (!validator.required(dataForm.idnumber)) {
            errors['idnumber'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    handSubmitID(e = null) {
        var { dataForm } = this.state;
        if (this.validate()) {
            window.postMessage('{"submitedid": "' + dataForm.idnumber + '"}', '*');
            this.props.closeModal('modalPaymentID')
        }
    }

    render() {
        const { errors, dataForm } = this.state;

        return (
            <div className="modal-groups ">
                <div className="modal" id="modalPaymentID" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalPaymentID')}></button>
                            </div>
                            <div className="modal-body">
                                <div className="block-title text-white">For additional security please enter your ID Number</div>

                                <div className="form-group small-margin">
                                    <input ref="txtIDNumber" type="text" className={`form-control ${(errors.idnumber) ? 'input-error' : ''}`} name="idnumber" placeholder="Enter your ID number" maxLength={20} value={dataForm.idnumber} onKeyPress={this.handlePressed} onBlur={this.validateWithField} onChange={this.handleChange} />
                                    <InputError error={errors.idnumber} />
                                </div>
                                <div className="form-group small-margin">
                                    <button type="button" onClick={this.handSubmitID} className="btn btn-continues btn-forgot">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

