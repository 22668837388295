import * as ActionTypes from '../actionTypes';


var initialState = {
   //step 1
   already_awb: false,
   awb_number_1: '700',
   awb_number_2: '',
   t1_shipment: "PTP",
   deliver_from: 'Airport',
   deliver_from_country: '',
   deliver_from_airport: '',
   deliver_from_airport_number: 0,
   deliver_from_city: '',
   deliver_from_airport_data: { calcity: "", cityname: "" },
   deliver_from_zipcode: '',
   pickup_dropoff_date: '',
   pickup_dropoff_time_hour: '00',
   pickup_dropoff_time_minute: '00',
   pickup_dropoff_xps: '',
   deliver_to: 'Airport',
   deliver_to_country: '',
   deliver_to_airport: '',
   deliver_to_airport_number: 0,
   deliver_to_city: '',
   deliver_to_zipcode: '',
   deliver_to_airport_data: { calcity: "", cityname: "" },
   commodity_type: '',
   sub_commodity: '',
   commodity_type_name: '',
   sub_commodity_name: '',
   units_type: 'Centimeters',
   units_type_short: "CM",
   units_list: [],
   shipment_dangerous_goods: false,
   shipment_dangerous_goods_type: 'CAO',
   shipment_lithium_batteries: false,
   shipment_unstackable: false,
   units_total_num_of_pieces: '',
   units_total_weight: '',
   units_total_volume: '',
   units_total_chargable_weight: '',
   instructions: [],
   additional_information: '',
   overlap_shipment: "NonOverlap",
   overlap_shipment_check: false,
   isAwb: false,
   checkbox_term: false,
   checkbox_term_df: false,
   company_name_of_consignee: "",
   company_name_of_consignor: "",
   un: "",
   class: [],
   ParcelChoosed: {},
   PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
   DefaultChoosed: { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } },
   PriceQuotation: {
      "pl": [],
      "routes": []
   },
   page_df: 1,
   page_pl: 1,
   //step 3
   selectedShippingReceiving: 'Shipping',
   receivingCompanyName: "",
   receivingCompanyNameSelected: null,
   receivingIATA1: "",
   receivingIATA2: "",
   checkReceivingIATA: true,
   shippingCompanyName: "",
   shippingCompanyNameSelected: null,
   shippingIATA1: "",
   shippingIATA2: "",
   consignorCompanyName: "",
   consignorAddress: "",
   consignorCity: "",
   consignorCountry: "",
   consignorGroundHandling: "",
   consignorPostalCode: "",
   consigneeAddress: "",
   consigneeCity: "",
   consigneeCompanyName: "",
   consigneeCountry: "",
   consigneeGroundHandling: "",
   consigneePostalCode: "",
   pickupCheck: false,
   pickupAddress: "",
   pickupCity: "",
   pickupComments: "",
   pickupCountry: "",
   pickupEmail: "",
   pickupNameOfContact: "",
   pickupOpeningHours: "",
   pickupPhoneNumber: "",
   pickupPostalCode: "",
   deliverAddress: "",
   deliverCheck: false,
   deliverCity: "",
   deliverComments: "",
   deliverCountry: "",
   deliverEmail: "",
   deliverNameOfContact: "",
   deliverOpeningHours: "",
   deliverPhoneNumber: "",
   deliverPostalCode: "",
   passedData: {},
   step_1_ok: false,
   step_2_ok: false,
   step_3_ok: false,
   selectedSuggessCNEE: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
   selectedSuggessCNOR: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
   populateImA: false,
   populateConsignor: false,
   populateConsignee: false,
   populateDelivery: false,
   populatePickup: false,
   checkbox_term_parcel: false,
   sort_default: "FromDate",
   sort_pl: "FromDate",
   webf: "",
   docno: "",
   clearConsignee: false,
   clearConsignor: false,
   dimemtions_by: 'pieces',
   testCase: "",
   payingCustomer: false,
   payingCustomerShipping: false,
   payingCustomerFreight: false,
   duplicate: false,
   duplicateFields: {

   }
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case ActionTypes.EBOOKING_RESET: {
         var defaultState = {
            //step 1
            already_awb: false,
            awb_number_1: '700',
            awb_number_2: '',
            t1_shipment: "PTP",
            deliver_from: 'Airport',
            deliver_from_country: '',
            deliver_from_airport: '',
            deliver_from_airport_number: 0,
            deliver_from_city: '',
            deliver_from_airport_data: { calcity: "", cityname: "" },
            deliver_from_zipcode: '',
            pickup_dropoff_date: '',
            pickup_dropoff_time_hour: '00',
            pickup_dropoff_time_minute: '00',
            pickup_dropoff_xps: '',
            deliver_to: 'Airport',
            deliver_to_country: '',
            deliver_to_airport: '',
            deliver_to_airport_number: 0,
            deliver_to_city: '',
            deliver_to_zipcode: '',
            deliver_to_airport_data: { calcity: "", cityname: "" },
            commodity_type: '',
            sub_commodity: '',
            commodity_type_name: '',
            sub_commodity_name: '',
            units_type: 'Centimeters',
            units_type_short: "CM",
            units_list: [],
            shipment_dangerous_goods: false,
            shipment_dangerous_goods_type: 'CAO',
            shipment_lithium_batteries: false,
            shipment_unstackable: false,
            units_total_num_of_pieces: '',
            units_total_weight: '',
            units_total_volume: '',
            units_total_chargable_weight: '',
            instructions: [],
            additional_information: '',
            overlap_shipment: "NonOverlap",
            overlap_shipment_check: false,
            isAwb: false,
            checkbox_term: false,
            checkbox_term_df: false,
            company_name_of_consignee: "",
            company_name_of_consignor: "",
            un: "",
            class: [],
            ParcelChoosed: {},
            PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
            DefaultChoosed: { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } },
            PriceQuotation: {
               "pl": [],
               "routes": []
            },
            //step 3
            selectedShippingReceiving: 'Shipping',
            receivingCompanyName: "",
            receivingCompanyNameSelected: null,
            receivingIATA1: "",
            receivingIATA2: "",
            checkReceivingIATA: true,
            shippingCompanyName: "",
            shippingCompanyNameSelected: null,
            shippingIATA1: "",
            shippingIATA2: "",
            consignorCompanyName: "",
            consignorAddress: "",
            consignorCity: "",
            consignorCountry: "",
            consignorGroundHandling: "",
            consignorGroundHandlingData: { warhsname: "" },
            consignorPostalCode: "",
            consigneeAddress: "",
            consigneeCity: "",
            consigneeCompanyName: "",
            consigneeCountry: "",
            consigneeGroundHandling: "",
            consigneeGroundHandlingData: { warhsname: "" },
            consigneePostalCode: "",
            pickupCheck: false,
            pickupAddress: "",
            pickupCity: "",
            pickupComments: "",
            pickupCountry: "",
            pickupEmail: "",
            pickupCompanyName: "",
            pickupNameOfContact: "",
            pickupOpeningHours: "",
            pickupPhoneNumber: "",
            pickupPostalCode: "",
            deliverAddress: "",
            deliverCheck: false,
            deliverCity: "",
            deliverComments: "",
            deliverCountry: "",
            deliverEmail: "",
            deliverCompanyName: "",
            deliverNameOfContact: "",
            deliverOpeningHours: "",
            deliverPhoneNumber: "",
            deliverPostalCode: "",
            passedData: {},
            step_1_ok: false,
            step_2_ok: false,
            step_3_ok: false,
            selectedSuggessCNEE: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
            selectedSuggessCNOR: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
            populateImA: false,
            populateConsignor: false,
            populateConsignee: false,
            populateDelivery: false,
            populatePickup: false,
            checkbox_term_parcel: false,
            sort_default: "FromDate",
            sort_pl: "FromDate",
            webf: "",
            docno: "",
            clearConsignee: false,
            clearConsignor: false,
            dimemtions_by: 'pieces',
            testCase: "",
            payingCustomer: false,
            payingCustomerShipping: false,
            payingCustomerFreight: false,
            duplicate: false,
            duplicateFields: {

            }
         };
         
         return Object.assign({}, state, defaultState)
      }
      case ActionTypes.EBOOKING_CHANGE_DATA: {
         var newState = { ...state };
         newState[action.payload.fieldName] = action.payload.fieldValue;
         return Object.assign({}, state, newState)
      }
      case ActionTypes.EBOOKING_UPDATE_DATA: {
         var geted_data = action.payload;
         return Object.assign({}, geted_data)
      }
      case ActionTypes.EBOOKING_RESET_STEP3: {
         var newState = { ...state };
         newState.selectedShippingReceiving = 'Shipping';
         newState.receivingCompanyName = "";
         newState.receivingCompanyNameSelected = null;
         newState.receivingIATA1 = "";
         newState.receivingIATA2 = "";
         newState.shippingCompanyName = "";
         newState.shippingCompanyNameSelected = null;
         newState.shippingIATA1 = "";
         newState.shippingIATA2 = "";
         newState.consignorCompanyName = "";
         newState.consignorAddress = "";
         newState.consignorCity = "";
         newState.consignorCountry = "";
         newState.consignorGroundHandling = "";
         newState.consignorPostalCode = "";
         newState.consigneeAddress = "";
         newState.consigneeCity = "";
         newState.consigneeCompanyName = "";
         newState.consigneeCountry = "";
         newState.consigneeGroundHandling = "";
         newState.consigneePostalCode = "";
         newState.pickupCheck = false;
         newState.pickupAddress = "";
         newState.pickupCity = "";
         newState.pickupComments = "";
         newState.pickupCountry = "";
         newState.pickupEmail = "";
         newState.pickupCompanyName = "";
         newState.pickupNameOfContact = "";
         newState.pickupOpeningHours = "";
         newState.pickupPhoneNumber = "";
         newState.pickupPostalCode = "";
         newState.deliverAddress = "";
         newState.deliverCheck = false;
         newState.deliverCity = "";
         newState.deliverComments = "";
         newState.deliverCountry = "";
         newState.deliverEmail = "";
         newState.deliverCompanyName = "";
         newState.deliverNameOfContact = "";
         newState.deliverOpeningHours = "";
         newState.deliverPhoneNumber = "";
         newState.deliverPostalCode = "";
         newState.step_3_ok = false;
         return Object.assign({}, state, newState)
      }
   }
   return state;
}