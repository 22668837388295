import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';
import AppConfig from '../../AppConfig';
import BookingHelper from '../BookingHelper';
import clone from 'clone';

/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
class AcceptQuotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            errors: {},
            showError: false,
            showChatError: false,
            acceptStatus: ['Cust Negotiation', 'Sent to Cust', 'User Negotiation', 'Decline by Cust', MESSAGES.STATUS_CUST_NEGOTIATION, MESSAGES.STATUS_DECLINE_BY_CUST, MESSAGES.STATUS_SEND_TO_CUST, MESSAGES.STATUS_USER_NEGOTIATION],
            showCase: 0,
            secretKey: '',
            quoteID: '',
            webCode: '',
            chatID: '',
            loginData: null,
            countries: [],
            countriesZip: [],
            loadedScreen: false,
            quotes: {
                deliver_from: 'Airport',
                deliver_from_country: null,
                deliver_from_airport: null,
                deliver_from_city: '',
                deliver_from_zipcode: '',
                deliver_to: 'Airport',
                deliver_to_country: null,
                deliver_to_airport: null,
                deliver_to_city: '',
                deliver_to_zipcode: '',
                pickup_dropoff: null,
                pickupOptions: [{ value: 'drop_off', label: 'Dropoff Time (Local)' }, { value: 'pickup', label: 'Pickup Time (Local)' }],
                pickup_dropoff_date: null,
                pickup_dropoff_time: null,
                unit_length: { value: 'cm', label: 'Cm' },
                offer_price_list: [],
                offer_total: 0,
                selected_flight: null,
                receiving_company_options: [],
                dg_class: []
            }
        };

    }

    componentDidUpdate = async (oldProps) => {
        var { loadedScreen } = this.state;
        var { country, port } = this.props;
        if (country.countries.length && port.ports.length && !loadedScreen) {
            this.initScreen();
        }
    }

    componentDidMount = async () => {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    initScreen = async () => {
        this.setState({ loadedScreen: true });
        var { showError, acceptStatus, showCase, secretKey, quoteID, chatID, webCode, loginData, countries, countriesZip, quotes } = this.state;    
        secretKey = this.props.match.params.secretKey;
        quoteID = this.props.match.params.quoteID;
        webCode = this.props.match.params.webCode;
        chatID = this.props.match.params.chatID;
        await this.props.loadingActions.loadingChangeData(true);
        let getQuotesData = await this.props.quotesActions.buildQuoteData(secretKey, quoteID);
        await this.props.loadingActions.loadingChangeData(true);

        if (getQuotesData.success) {
            let phone = '';
            if (getQuotesData.success && getQuotesData.proposalData.length) {
                phone = getQuotesData.proposalData[0].phone;
            }
            let getLoginData = null;
            let websiteuser = '';
            let userStatus = '';
            let custname = '';
            if (phone) {
                await this.props.loadingActions.loadingChangeData(true);
                getLoginData = await this.props.quotesActions.getLoginData({ phone });
                await this.props.loadingActions.loadingChangeData(false);
                if (getLoginData.success && getLoginData.data.Success) {
                    websiteuser = getLoginData.data.websiteuser;
                    userStatus = getLoginData.data.status;
                    custname = getLoginData.data.custname;
                    loginData = getLoginData.data;
                }

                let firstCustNameCharacter = custname.substring(0, 1);
                if (websiteuser === "Y" && userStatus === "Active" && firstCustNameCharacter !== 'T') {
                    showCase = 1;
                } else if (websiteuser !== "Y") {
                    showCase = 2;
                } else if (userStatus === "Potential" && firstCustNameCharacter === 'T') {
                    showCase = 3;
                }
            }

            let quotesData = getQuotesData.quotesData.apiData;
            let quotesStatus = quotesData.sitestat;
            let exprires = getQuotesData.exprires;

            let checkStatus = acceptStatus.find((s) => s === quotesStatus);
            if (!exprires || !checkStatus) {
                this.setState({ showChatError: true });
            } else if (!quotesData || !custname || !phone) {
                showError = true;
            } else if (quotesStatus === 'booked') {
                showCase = 4;
            } else {
                await this.props.loadingActions.loadingChangeData(true);
                var chatItems = await this.props.negotiationActions.getChatItems({ QuoteID: quoteID, secretKey });
                if (chatItems.success && chatItems.data.data.chatData.length) {
                    let cChats = [].concat(chatItems.data.data.chatData);
                    let itmChat = cChats.find(c => c.Id === chatID);
                    if (itmChat && itmChat.status === 'Waiting') { //&& itmChat.status ==='Waiting'
                        var flightData = BookingHelper.createFlightData(getQuotesData.quotesData);
                        let offerData = BookingHelper.createOfferData(itmChat.ChatData.OfferData);
                        var offerTotal = BookingHelper.calculatorTotalNegotiation(itmChat.ChatData.OfferData, getQuotesData.quotesData.offer_currency, getQuotesData.quotesData.apiData, getQuotesData.listCurrencies);
                        var contactEmailTo = [];
                        var contactEmailCC = [];
                        getQuotesData.quotesData.selectedContacts.forEach((element, i) => {
                            if (!i) {
                                contactEmailTo.push(element.Email);
                            } else {
                                contactEmailCC.push(element.Email);
                            }
                        });

                        var emailData = {
                            titleText: '[Quote #' + getQuotesData.quotesData.CProfNum + '] You was Accepted counter',
                            quoteID: getQuotesData.quotesData.CProfNum,
                            companyName: getQuotesData.quotesData.customer ? getQuotesData.quotesData.customer.CUSTDES : '',
                            name: getQuotesData.quotesData.selectedContacts.length ? getQuotesData.quotesData.selectedContacts[0].Name : '',
                            shipIcons: flightData.shipIcons,
                            shipInfos: flightData.shipInfos,
                            totalPieces: getQuotesData.quotesData.apiData.Pieces,
                            totalVolumn: getQuotesData.quotesData.apiData.Volume,
                            totalWeight: getQuotesData.quotesData.apiData.Weight,
                            TCW: getQuotesData.quotesData.apiData.CWeight,
                            Unit: getQuotesData.quotesData.unit_length.value,
                            result: offerData,
                            totalPrice: BookingHelper.formatMoney(offerTotal.offer_total),
                            totalPriceType: getQuotesData.quotesData.cost_total_type.label,
                            Commodity: getQuotesData.quotesData.apiData.Commodity,
                            SubCommodity: getQuotesData.quotesData.apiData.SubCommodity,
                            PersionMessage: '',
                            DeclineUrl: '',
                            AcceptUrl: '',
                            RespondUrl: '',
                            HourExpires: '',
                            DetailsExpires: '',
                            DetailsHourExpires: '',
                            OfferCurrency: getQuotesData.quotesData.offer_currency.label,
                            TermUrl: '',
                            step_img: flightData.shipIcons.length + 'step',
                            CProfAU: getQuotesData.quotesData.apiData.CProfAU,
                            emails: contactEmailTo,
                            emailsCC: contactEmailCC,
                        };
                        // console.log(emailData);
                        this.props.loadingActions.loadingChangeData(true);
                        const resultAccept = await this.props.negotiationActions.acceptQuote(secretKey, quoteID, itmChat.Id, getQuotesData.quotesData.CProfNum, emailData);
                        if (resultAccept.success) {
                            localStorage.setItem('DASHBOARD_REFRESH', true);
                            const params = {
                                status: 'Approved', // Approved, Declined
                                quoteId: quoteID,
                                secretKey: secretKey
                            }
                            await this.props.negotiationActions.custAlert(params);
                            await this.props.quotesActions.quotesUpdateData(getQuotesData.quotesData);
                        }else{
                            showError = true;
                        }                        
                    } else {
                        this.setState({ showChatError: true });
                    }
                } else {
                    this.setState({ showChatError: true });
                }
            }
        } else {
            showError = true;
        }
        this.setState({ showError, showCase, loginData, secretKey, quoteID, webCode });
        await this.props.loadingActions.loadingChangeData(false);
    }

    buildShowCase = () => {
        var { showCase, secretKey, quoteID, webCode, loginData } = this.state;
        if (showCase == 1 || showCase == 2) {
            let buttonUrl = '';
            if (showCase == 1) {
                buttonUrl = "/login?back=" + base64.encode("/quotes/review/" + secretKey + "/" + quoteID);
            } else if (loginData && (loginData.cash === 'Y' || loginData.credit === 'Y')) {
                buttonUrl = "/register-step2?id=" + loginData.custname + "&code=" + loginData.webcode + "back=" + base64.encode("/quotes/review/" + secretKey + "/" + quoteID);
            } else if (loginData && loginData.cass === 'Y') {
                buttonUrl = "/login?showRegister=1&back=" + base64.encode("/quotes/review/" + secretKey + "/" + quoteID);
            }
            return (
                <div>
                    <div className="big-text">Thank You</div>
                    <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>
                        We have the detail and you are ready to book. All that left is to sign in and confirm your booking.
                                            </div>
                    <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                        This will only take a moment.
                                            </div>
                    <div className="content-buttons div_center mt-5">
                        <Link to={`${buttonUrl}`} className={`btn btn-orange mb-3`}>Confirm  Your Booking</Link>
                    </div>
                </div>
            )
        } else if (showCase == 3) {
            return (
                <div>
                    <div className="big-text">Thank You</div>
                    <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>That's it for now. We will be in touch with you shortly to complete the booking.</div>

                    <div className="content-buttons div_center mt-5">
                        <Link to={`${process.env.PUBLIC_URL}`} className={`btn btn-orange mb-3`}>Thank You</Link>
                    </div>
                </div>
            )
        } else if (showCase == 4) {
            return (
                <div>
                    <div className="big-text">GOOD NEWS</div>
                    <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>We've already  taken the time to finish registering this booking, so you're all set.</div>

                    <div className="content-buttons div_center mt-5">
                        <Link to={`${process.env.PUBLIC_URL}`} className={`btn btn-orange mb-3`}>Great, Thanks</Link>
                    </div>
                </div>
            )
        }
    }

    render() {
        var { showError, showChatError, secretKey, quoteID } = this.state;
        return (
            <div className="page-main">
                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>
                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr className={`${showChatError ? 'd-none' : ''}`}>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className={`content-text-desc div_center text-center mb-3 ${showError ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                <span className="alert alert-danger" style={{ backgroundColor: 'red', color: '#fff' }} role="alert">
                                                    Unfortunately this quote has expired or doesn’t exist
                                                </span>
                                            </div>
                                            {this.buildShowCase()}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={`${showChatError ? '' : 'd-none'}`}>
                                <td className="accept-container">
                                    <div className="content-box">
                                        <h2>
                                            This offer is no longer relevant
                                            </h2>
                                        <p>It seems this offer is no longer relevant, and so cannot
                                        be accepted here, If you would like to speak your Cal agent,
                                        click below and they will contact you shortly.
                                            </p>
                                        <Link to={`/quotes/negotiation/${secretKey}/${quoteID}`} className="btn btn-orange">Contact Us</Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        negotiationActions: bindActionCreators(negotiationActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptQuotes);