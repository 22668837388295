import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import StepProgress from '../../layouts/partials/StepProgress';
import uuid from 'uuid';
import $ from 'jquery';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';
import BookingHelper from './BookingHelper';
import Modal from 'react-modal';
import ModalBookingGSA from "../../layouts/partials/ModalBookingGSA";

var itemsPerPage = 6;
var itemsShowing = 0;
class FlightPricingDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: "",
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                checkbox_term_df: false,
                PriceQuotation: {
                    routes: [],
                    pl: []
                },
                DefaultChoosed: { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } }
            },
            breadcrumbs: [
                {
                    'link': 'ebooking',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'validate': false,
                    'current': true,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            rate: 0,
            totalCostWeight: 0,
            totalPrice: 0,
            showMore: true,
            modalIsOpen: false,
        };

        this.handChoosePrice = this.handChoosePrice.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalIsOpen: false });
            }
        });
    }

    openModal = () => {
        this.setState({ modalIsOpen: true, errorMessage: '' });
    }
    closeModal = () => {
        this.setState({ modalIsOpen: false, errorMessage: '' });
    }
    handleGSAContinue = async (gsaData) => {
        // console.log(gsaData);
        this.closeModal();
        var { history, ebooking, auth } = this.props;
        var quotesData = BookingHelper.buildQuotesData(ebooking, '', 'Draft', auth);
        quotesData.cp.Participents.SHIPNAME = gsaData.shipping_company ? gsaData.shipping_company.custname : null;
        quotesData.cp.Participents.RECNAME = gsaData.receiving_company ? gsaData.receiving_company.custname : null;
       
        if(gsaData.payingCustomerShipping && gsaData.shipping_company){
            quotesData.cp.custname = gsaData.shipping_company.custname;
        }else if(gsaData.payingCustomerFreight && gsaData.receiving_company){
            quotesData.cp.custname = gsaData.receiving_company.custname;
        }
        this.props.loadingActions.loadingChangeData(true);
        let saveQuotesDate = await this.props.quotesActions.createQuote(quotesData, auth.jwtToken);

        if (saveQuotesDate.success && saveQuotesDate.data.Success && saveQuotesDate.data.cprof) {
            const paramsAlert = {
                status: 'NewQuote',
                quoteId: saveQuotesDate.data.cprof,
                secretKey: uuid(),
                createdByName: auth.user_name,
                createdByDept: auth.custdes,
                data: {
                    shipment: ebooking.PriceQuotation.webf
                }
            }
            await this.props.negotiationActions.custAlert(paramsAlert);
            this.props.loadingActions.loadingChangeData(false);
            history.push('/ebooking/gsa-message?ref=' + saveQuotesDate.data.cprof);
        } else {
            this.props.loadingActions.loadingChangeData(false);
            this.setState({ errorMessage: MESSAGES.CREATE_QUOTE_ERROR, isError: true });
        }
    }

    sortFlight() {
        var { ebooking } = this.props;
        var flights = ebooking.PriceQuotation.routes;
        switch (ebooking.sort_default) {
            case "FromDate":
                flights = flights.sort((a, b) => { return a.stdSort.localeCompare(b.stdSort) });
                break;
            case "ToDate":
                flights = flights.sort((a, b) => { return a.staSort.localeCompare(b.staSort) });
                break;
            case "EconomyPrice":
                flights = flights.sort((a, b) => { return a.priceSort.localeCompare(b.priceSort) });
                break;
            case "ExpressPrice":
                flights = flights.sort((a, b) => { return a.expresspriceSort.localeCompare(b.expresspriceSort) });
                break;
            case "Duration":
                flights = flights.sort((a, b) => { return a.duration.localeCompare(b.duration) });
                break;
        }
        ebooking.PriceQuotation.routes = flights;
        this.setState({ ebooking });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }
    handleChange(event) {
        var { ebooking } = this.props;
        var { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        ebooking[name] = value;
        if (name == "checkbox_term_df") {
            errors["checkbox_term"] = "";
        }
        // this.props.ebookingActions.ebookingChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        this.setState({ ebooking, errors });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        if (name == "sort_default") {
            this.sortFlight();
        }
    }

    handChoosePrice = (priceType, flight, price) => {
        var { ebooking } = this.state;
        if (price > 0) {
            var flights = flight.flights;
            flights = flights.filter(f => f.leg != -2);
            flight.flights = flights;
            ebooking.DefaultChoosed.flightID = flight.uuid;
            ebooking.DefaultChoosed.priceType = priceType;
            ebooking.DefaultChoosed.price = price;
            ebooking.DefaultChoosed.flight = flight;
            this.setState({ ebooking });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "DefaultChoosed", fieldValue: ebooking.DefaultChoosed }).then(responseData => { });
            this.calPrice();
        }
    }

    calPrice = () => {
        var { ebooking } = this.props;
        var { totalPrice, totalCostWeight, rate } = this.state;
        totalCostWeight = 0;
        totalPrice = 0;
        rate = 0;
        var partPrice = 0;

        for (var i = 0; i < ebooking.DefaultChoosed.flight.Parts.length; i++) {
            var part = ebooking.DefaultChoosed.flight.Parts[i];
            partPrice += parseFloat(part.price);
        }
        // totalCostWeight = parseFloat(ebooking.units_total_weight) * parseFloat(ebooking.PriceQuotation.price);
        totalCostWeight = parseFloat(ebooking.units_total_chargable_weight) * parseFloat(ebooking.DefaultChoosed.price);
        totalPrice = totalCostWeight + partPrice;
        this.setState({ totalPrice: totalPrice, totalCostWeight: totalCostWeight });
    }

    componentDidMount() {
        //get redux data and set to state   
        var { steps } = this.state;
        var { ebooking, history, auth } = this.props;
        if (!ebooking.step_1_ok || (auth.state != 'LOGGED_IN' && auth.state != 'NEW_PASSWORD_REQUIRED' && auth.state != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        }
        if (ebooking.PriceQuotation.pl.length > 0) {
            history.push('/ebooking/flight-pricing-pricelist');
        }
        if (ebooking.PriceQuotation.type != "WHITE") {
            switch (ebooking.PriceQuotation.type) {
                case "PARCEL":
                    history.push('/ebooking/flight-pricing-parcel');
                    break;
                case "PL":
                    history.push('/ebooking/flight-pricing-pricelist');
                    break;
                default:
                    history.push('/ebooking/shipment');
                    break;
            }
        }
        if (auth.IsGSA) {
            steps[1].link = 'ebooking/participants-gsa';
        }
        this.setState({ ebooking, steps });
        this.sortFlight();
        if (ebooking.DefaultChoosed.flightID != "") {
            this.calPrice();
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        var { ebooking } = this.props;

        if (ebooking.DefaultChoosed && (ebooking.DefaultChoosed.flightID == "" || ebooking.DefaultChoosed.price <= 0)) {
            isError = true;
            errors.selectedFlight = "Please choose flight.";
        }

        if (!ebooking.checkbox_term_df) {
            isError = true;
            errors.checkbox_term = MESSAGES.ERROR_CONDITION;
        }

        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: true }).then(responseData => { });
        return true;
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        var { ebooking, history, auth } = this.props;
        ebooking.populateConsignor = ebooking.consignorCompanyName ? true : false;
        ebooking.populateConsignee = ebooking.consigneeCompanyName ? true : false;
        ebooking.populateDelivery = ebooking.deliverCountry ? true : false;
        ebooking.populatePickup = ebooking.pickupCountry ? true : false;
        ebooking.step_2_ok = true;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        window.jumpToTop();
        if (auth.IsGSA) {
            history.push('/ebooking/participants-gsa');
        } else {
            history.push('/ebooking/participants');
        }

    }

    handleShowMore = () => {
        var { ebooking } = this.state;
        ebooking.page_df++;
        this.setState({ ebooking });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "page_df", fieldValue: ebooking.page_df }).then(responseData => { });
    }

    buildParts = (data, currency) => {
        const partItems = [];
        if (data && data.flight && data.flight.Parts) {
            for (var i = 0; i < data.flight.Parts.length; i++) {
                var part = data.flight.Parts[i];
                partItems.push(
                    <div className="addition-charges-element" key={i}>
                        <div className="row">
                            <div className="col-8 addition-title">{part.partdes}</div>
                            <div className="col-4 text-right addition-value">{BookingHelper.formatMoney(part.price)} <span>{currency}</span></div>
                        </div>
                    </div>

                    // <tr key={i}>
                    //     <td>{part.partname}</td>
                    //     <td>{part.partdes}</td>
                    //     <td>{currency}{BookingHelper.formatMoney(part.price)}</td>
                    // </tr>
                );
            }
        }
        return partItems;
    }

    buildFlightItems = (routes, auth) => {
        var { ebooking } = this.props;
        var { showMore } = this.state;
        const flightItems = [];

        routes.map((op, i) => {
            var dStd = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var dTime = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var aTimeDiff = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var dTimeDiff = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            aTimeDiff.set({ hour: 0, minute: 0 });
            dTimeDiff.set({ hour: 0, minute: 0 });
            var prices = BookingHelper.getPrices(op);
            // var prices = BookingHelper.getPrices(op.flights);
            if (flightItems.length < itemsPerPage * ebooking.page_df) {
                flightItems.push(
                    <div className="col-md-6 col-lg-4" key={i}>
                        <div className={`flight-element mb-3 div_center ${(ebooking.DefaultChoosed && ebooking.DefaultChoosed.flightID == op.uuid) ? "selected" : ""}`}>
                            <div className="flight-element-content">
                                <div className="flight-name text-center">Flight {op.fnumber}</div>
                                <div className="flight-date text-center">{dStd.format("MMMM Do")}</div>
                                <div className="flight-duration mt-3 mb-3 text-center">
                                    <div className="row">
                                        <div className="col-4 text-right">{dTime.format("HH:mm")}</div>
                                        <div className="col-4 duration-line">
                                            <div className="line"></div>
                                        </div>
                                        <div className="col-4 text-left showLandingTime">
                                            {aTime.format("HH:mm")}
                                            <span className={`${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'd-none'}`}>+{aTimeDiff.diff(dTimeDiff, "days")}</span>
                                            <div className={`landingTime ${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'no-add-day'}`}>Lands: {aTime.format("MMMM Do, HH:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flight-price ${(ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "economy" && ebooking.DefaultChoosed.flightID == op.uuid) ? 'price-orange' : 'price-grey'}  ${prices[0] <= 0 ? 'd-none' : ''}`} onClick={this.handChoosePrice.bind(this, "economy", op, prices[0] / parseFloat(ebooking.units_total_chargable_weight))}>
                                    <div className="price-detail">
                                        <div className="price-name">Economy</div>
                                        <div className="price-rate d-none">{BookingHelper.formatMoney(prices[0] / parseFloat(ebooking.units_total_chargable_weight))} per kg</div>
                                    </div>
                                    <div className="price-value">
                                        {BookingHelper.formatMoney(prices[0] / parseFloat(ebooking.units_total_chargable_weight))}<span className="small_text">per KG</span>
                                        {/* {BookingHelper.formatMoney(prices[0], 0)} <span>{auth.cur}</span> */}
                                    </div>
                                    <div className="clb"></div>
                                </div>
                                <div className={`flight-price ${(ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "express" && ebooking.DefaultChoosed.flightID == op.uuid) ? 'price-orange' : 'price-blue'} ${prices[1] <= 0 ? 'd-none' : ''}`} onClick={this.handChoosePrice.bind(this, "express", op, prices[1] / parseFloat(ebooking.units_total_chargable_weight))}>
                                    <div className="price-detail">
                                        <div className="price-name">Express</div>
                                        <div className="price-rate d-none">{BookingHelper.formatMoney(prices[1] / parseFloat(ebooking.units_total_chargable_weight))} per kg</div>
                                    </div>
                                    <div className="price-value">
                                        {BookingHelper.formatMoney(prices[1] / parseFloat(ebooking.units_total_chargable_weight))}<span className="small_text">per KG</span>
                                        {/* {BookingHelper.formatMoney(prices[1], 0)} <span>{auth.cur}</span> */}
                                    </div>
                                    <div className="clb"></div>
                                </div>
                                <div className={`flight-price price-invailable ${prices[0] > 0 ? 'd-none' : ''}`}>
                                    <div className="price-detail">
                                        <div className="price-name">Economy</div>
                                        <div className="price-rate">Invailable</div>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                                <div className={`flight-price price-invailable ${prices[1] > 0 ? 'd-none' : ''}`}>
                                    <div className="price-detail">
                                        <div className="price-name">Express</div>
                                        <div className="price-rate">Invailable</div>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        itemsShowing = flightItems.length;
        return flightItems;
    }

    getPortDataBig = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.portname;
        } else {
            return "";
        }
    }
    getPortDataSmall = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.countryname;
        } else {
            return "";
        }
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        var { auth } = this.props;
        var authState = auth.state;
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, errorText, modalIsOpen }
            = this.state;

        return (

            <div className="ebooking">
                <StepProgress stepData={steps} progress={40} onClick={this.handClickStep} />


                <div className="container">
                    <h1 className="title mt-5">Step one: Getting a Quote</h1>
                </div>

                <form>
                    <div className="container block-form">
                        <h1 className="formTitle mb-2">Choose a flight</h1>
                        <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                        <div className={`form-group ${errorText != "" ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                {errorText}
                            </div>
                        </div>

                        <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                        </div>

                        <div className="flight-shipment-detail div_center text-center">
                            <div className={`row ${ebooking.DefaultChoosed.flightID ? '' : ''}`}>
                                <div className="col-4 col-lg-5">
                                    <div className="shipment-city">
                                        {ebooking.PriceQuotation.routes.length ? this.getPortDataBig(ebooking.PriceQuotation.routes[0].fromport) : ""}
                                    </div>
                                    <div className="shipment-address">
                                        {ebooking.PriceQuotation.routes.length ? this.getPortDataSmall(ebooking.PriceQuotation.routes[0].fromport) : ""}
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 shipment-plain-icon"></div>
                                <div className="col-4 col-lg-5">
                                    <div className="shipment-city">
                                        {ebooking.PriceQuotation.routes.length ? this.getPortDataBig(ebooking.PriceQuotation.routes[0].toport) : ""}
                                    </div>
                                    <div className="shipment-address">
                                        {ebooking.PriceQuotation.routes.length ? this.getPortDataSmall(ebooking.PriceQuotation.routes[0].toport) : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="shipment-quote-detail text-center mt-3">
                                <div className="shipment-quote-detail-title">My Quote Details</div>
                                <div className="shipment-quote-pieces mt-3">
                                    <div className="quote-elements">
                                        <div className="quote-element">
                                            <div className="quote-value">{ebooking.units_total_num_of_pieces}</div>
                                            <div className="quote-name">Pieces</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{ebooking.units_total_weight} <span>kg</span></div>
                                            <div className="quote-name">Actual Weight</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{ebooking.units_total_chargable_weight} <span>kg</span></div>
                                            <div className="quote-name">Chargable Weight</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{ebooking.units_total_volume}</div>
                                            <div className="quote-name">Volume (CBM)</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="list-flight mt-5">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <select name="sort_default" id="sort_default" value={ebooking.sort_default} className="sort-select" onChange={this.handleChange}>
                                        <option value="FromDate">Sort by from date</option>
                                        <option value="ToDate">Sort by to date</option>
                                        <option value="EconomyPrice">Sort by economy price</option>
                                        <option value="ExpressPrice">Sort by express price</option>
                                        <option value="Duration">Sort by duration</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center local-times">
                                    All times are local times
                                </div>
                            </div>
                            <div className={`row mb-1 mt-5 ${errors.selectedFlight ? "" : "hide"}`}>
                                <div className="col-lg-12">
                                    <div className="show-bottom-error bottom0">
                                        <InputError error={errors.selectedFlight} class="bg-red" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 flight-elements div_center mt-5">
                                <div className="row">
                                    {
                                        this.buildFlightItems(ebooking.PriceQuotation.routes, auth)
                                    }
                                </div>
                            </div>
                            <div className={`row mt-3 ${itemsShowing < ebooking.PriceQuotation.routes.length ? '' : 'd-none'}`}>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-show-more-results" onClick={this.handleShowMore}>Show More Results</button>
                                </div>
                            </div>
                        </div>

                        <div className={`addition-charges div_center mt-3 ${ebooking.DefaultChoosed.flight.Parts.length ? "" : "d-none"}`}>
                            <div className="col-12 text-center addition-title mb-3">
                                Additional Charges
                            </div>
                            <div className={`addition-charges-elements`}>
                                {
                                    this.buildParts(ebooking.DefaultChoosed, auth.cur)
                                }
                            </div>
                        </div>
                        <div className="addition-charges text-center div_center mt-3">
                            <span className="local-times form-check">I want to continue with a <a onClick={this.openModal}>Cal Agent</a> (this form will be saved)</span>
                        </div>
                    </div>

                    <div className="container no_padding">
                        <div className={`row mt-3 mb-2 ${errors.checkbox_term_parcel ? "" : "hide"}`}>
                            <div className="col-lg-12">
                                <div className="show-bottom-error bottom0">
                                    <InputError error={errors.checkbox_term_parcel} class="bg-red" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-total div_center">
                                    <div className="total-txt">Total</div>
                                    <div className="total-price">{BookingHelper.formatMoney(this.state.totalPrice)} <span>{auth.cur}</span></div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="float-left mt-2">
                                    <div className="form-check pl-2 pl-md-0">
                                        <label className={`checkbox-container`}>
                                            I have read and agree to the
                                            <a className="terms_link" href="https://www.cal-cargo.com/terms-conditions/" target="_blank">Terms of Service</a> and
                                            <a className="terms_link" href="https://www.cal-cargo.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                                            <input type="checkbox" name="checkbox_term_df" value="checkbox_term_df" checked={ebooking.checkbox_term_df} onChange={this.handleChange} />
                                            <span className={`checkmark-checkbox ${(errors.checkbox_term) ? 'input-error' : ''}`}></span>
                                        </label>
                                        <EbookingInputError error={errors.checkbox_term} class="float-left" />
                                    </div>
                                </div>
                                <div className="footer-buttons float-right pr-2 pr-md-0 mt-3 mt-md-0">
                                    <div className="btn-process">
                                        <button type="button" onClick={this.handSubmit} disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} className="ml-3 btn btn-ebooking-orange">Continue to Booking</button>
                                    </div>
                                    <div className="btn-back-shipment">
                                        <Link className="btn btn-back" to={`${process.env.PUBLIC_URL}/ebooking/shipment`}>Back to Shipment Details</Link>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalBookingGSA
                        onShow={modalIsOpen}
                        closeModal={this.closeModal}
                        onHide={this.closeModal}
                        handleGSAContinue={this.handleGSAContinue}
                    />
                </Modal>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch),
        negotiationActions: bindActionCreators(negotiationActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightPricingDefault);