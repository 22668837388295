import * as ActionTypes from '../actionTypes';

const initialState = {
    types: [],
    state: "CARGO_TYPE_GETTING_DATA"
};

export default function reducer(state = initialState, action) {
    switch (action.type) {   
        case ActionTypes.CARGO_TYPE_CHANGE_DATA: {
            var newState = { ...state };
            newState['types'] = action.payload;
            newState['state'] = "CARGO_TYPE_LOADED_DATA";            
            return Object.assign({}, state, newState)
        }        
    }
    return state;
}