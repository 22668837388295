import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Account from '../../layouts/partials/Account';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import detectPrint from "react-detect-print";
import Autosuggest from 'react-autosuggest';
import uuid from 'uuid';
import moment from 'moment';
import base64 from 'base-64';
import BookingHelper from '../ebooking/BookingHelper';
import PopperItem from './element/PopperItem';
import PopperItemConsignee from './element/PopperItemConsignee';
import PopperItemFlight from './element/PopperItemFlight';
import AppConfig from '../../AppConfig';
import clone from 'clone';
import * as Pdf from '../../libs/Pdf';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import Nav from './Nav';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as dashboardActions from '../../ninja-redux/actions/dashboardActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as authActions from '../../ninja-redux/actions/authActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';

import Modal from 'react-modal';
import ModalDashboardContact from "../../layouts/partials/ModalDashboardContact";
import ModalDashboardCantBeDone from "../../layouts/partials/ModalDashboardCantBeDone";
import ModalDashboardEditAwbDashboard from "../../layouts/partials/ModalDashboardEditAwbDashboard";
import ModalDashboardPrintAWB from "../../layouts/partials/ModalDashboardPrintAWB";
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';
import $ from 'jquery';

var c_page = 0;
class Shipments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isError: false,
            isEdit: false,
            modalContactIsOpen: false,
            modalEditIsOpen: false,
            modalCanBeDoneIsOpen: false,
            modalPrintIsOpen: false,
            errors: {},
            search: {
                awb: '',
                from: '',
                cosignee: '',
                consignor: '',
                date: 30
            },
            isShowSearchBox: false,
            expanded: {},
            data: [],
            allData: [],
            rowPerPage: 10,
            loading: false,
            lastYear: 365,
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            consigneeSuggests: [],
            consignorSuggests: [],
            s_more: false,
            shipmentData: null,
            mailData: null,
            getQuotesData: null
        };
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalContactIsOpen: false, modalPrintIsOpen: false, modalEditIsOpen: false, modalCanBeDoneIsOpen: false });
            }
        });
    }

    openContactModal = () => {
        this.setState({ modalContactIsOpen: true, modalEditIsOpen: false, modalCanBeDoneIsOpen: false, modalPrintIsOpen: false });
    }
    openEditModal = () => {
        this.setState({ modalEditIsOpen: true, modalContactIsOpen: false, modalCanBeDoneIsOpen: false, modalPrintIsOpen: false });
    }
    openCanBeDoneModal = () => {
        this.setState({ modalCanBeDoneIsOpen: true, modalContactIsOpen: false, modalEditIsOpen: false, modalPrintIsOpen: false });
    }

    openPrintModal = () => {
        this.setState({ modalCanBeDoneIsOpen: false, modalContactIsOpen: false, modalEditIsOpen: false, modalPrintIsOpen: true });
    }

    closeContactModal = () => {
        this.setState({ modalContactIsOpen: false });
    }
    closeEditModal = () => {
        this.setState({ modalEditIsOpen: false });
    }

    closePrintModal = () => {
        this.setState({ modalPrintIsOpen: false });
    }

    closeCanBeDoneModal = () => {
        this.setState({ modalCanBeDoneIsOpen: false });
    }
    handleExceedsLimitations = async (shipmentData) => {
        this.closeEditModal();
        this.openCanBeDoneModal();
    }

    handleUpdateShipmentSuccess = async (shipment) => {
        const params = { cp: { awb: shipment.awb }, Dimensions: [] };
        if (shipment.units_list && shipment.units_list.length > 0) {
            params.Dimensions = shipment.units_list.map(item => {
                const newItem = { ...{}, ...item };
                delete newItem.Cbm;
                return newItem;
            });
        }

        this.props.loadingActions.loadingChangeData(true);
        const result = await this.props.quotesActions.updateShipment(params, this.props.auth.jwtToken);
        this.props.loadingActions.loadingChangeData(false);
        if (result.success) {
            this.setState({ errorMessage: "Update shipment successfully.", isError: false });
        } else {
            this.setState({ errorMessage: "Update shipment failed.", isError: true });
        }
    }

    handleClickContact = async () => {
        this.closeCanBeDoneModal();
        this.openContactModal();
    }

    componentDidMount() {
        // var { lastYear } = this.state;
        // var currentDate = moment();
        // var startDate = moment();
        // startDate.set({ date: 1, month: 0 });
        // lastYear = currentDate.diff(startDate, 'days');
        // this.setState({ lastYear });       
        this.setState({ search: this.props.dashboard.search });
        this.checkShowLoginForm();
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA" && !localStorage.getItem('DASHBOARD_REFRESH')) {
            this.convertDataFromRedux(true);
        } else if (this.props.auth.state == "LOGGED_IN") {
            this.getData(true);
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.auth.state != "LOGGED_IN" && newProps.auth.state == "LOGGED_IN" && this.props.dashboard.state != "DASHBOARD_LOADED_DATA") {
            this.getData(this.state.isShowSearchBox);
        }

        if (oldProps.dashboard.state != "DASHBOARD_LOADED_DATA" && newProps.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            // window.parent.postMessage('show-login-form', '*');
            history.push("/login?back=" + base64.encode("/dashboard"));
        }
    }

    onClickOutside = () => {
        // this._calendar.setOpen(false);
        // this._calendar.cancelFocusInput();
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        const { search } = this.state;
        const { target: { name, value } } = event;
        search[name] = value;
        this.setState({ search });
        if (name == "date") {
            this.getData(this.state.isShowSearchBox);
        } else {
            if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
                this.convertDataFromRedux();
            }
        }
        await this.props.dashboardActions.updateFilter(search);
    }

    handleChangeDate = (v) => {
        var { search } = this.state;
        if (moment.isMoment(v)) {
            search.date = v;
        } else {
            search.date = null;
        }
        this.setState({ search });
    }

    handleOnBlurDate = (v) => {
        const { search, errors } = this.state;
        if (search.date && moment.isMoment(search.date)) {
            errors["date"] = "";
        }

        this.setState({ errors });
    }

    onChangeSugestCNEE = async (event, { newValue }) => {
        var { consigneeSuggests, search } = this.state;
        var selectedSuggessCNEE = { name: "" };
        selectedSuggessCNEE = consigneeSuggests.find(item => item.name === newValue);
        if (selectedSuggessCNEE) {
            search.cosignee = selectedSuggessCNEE.name;
        } else {
            search.cosignee = newValue;
        }
        this.setState({ search });
        await this.props.dashboardActions.updateFilter(search);
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    };

    onChangeSugestCNOR = async (event, { newValue }) => {
        var { consignorSuggests, search } = this.state;
        var selectedSuggessCNOR = { name: "" };
        selectedSuggessCNOR = consignorSuggests.find(item => item.name === newValue);
        if (selectedSuggessCNOR) {
            search.consignor = selectedSuggessCNOR.name;
        } else {
            search.consignor = newValue;
        }
        this.setState({ search });
        await this.props.dashboardActions.updateFilter(search);
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    };

    getSuggestionsCNEE = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.consigneeSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionsCNOR = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.consignorSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    onSuggestionsClearRequestedCNEE = () => {
        this.setState({
            suggestionsCNEE: []
        });
    };

    onSuggestionsClearRequestedCNOR = () => {
        this.setState({
            suggestionsCNOR: []
        });
    };

    onSuggestionsFetchRequestedCNEE = ({ value }) => {
        this.setState({
            suggestionsCNEE: this.getSuggestionsCNEE(value)
        });
    };

    onSuggestionsFetchRequestedCNOR = ({ value }) => {
        this.setState({
            suggestionsCNOR: this.getSuggestionsCNOR(value)
        });
    };

    toogleSearchBox = async (newStatus) => {
        // this.setState({ isShowSearchBox: !this.state.isShowSearchBox });
        await this.props.dashboardActions.changeOpenFilter(newStatus);
        this.onClickOutside();
    }

    dupHandle = (row) => {
        const { history } = this.props;
        var { isError, errorMessage } = this.setState;
        var { country, port } = this.props;
        isError = false;
        errorMessage = "";
        var t = this;
        // console.log(port.ports);
        // console.log(country.countries);        
        this.props.ebookingActions.ebookingReset();
        if (row.pq.AWB) {
            this.props.loadingActions.loadingChangeData(true);
            this.props.dashboardActions.getDetailsOrder(row.pq.AWB).then(response => {
                t.props.loadingActions.loadingChangeData(false);
                if (response.success && response.data.Success) {

                    var ebooking = this.props.ebooking;
                    // ebooking.already_awb = true;
                    // ebooking.awb_number_1 = row.pq.AWB.substring(0, 3);
                    // ebooking.awb_number_2 = row.pq.AWB.substring(3, row.pq.AWB.length);
                    ebooking.duplicate = true;
                    ebooking.duplicateFields = response.data.Participents;
                    ebooking.already_awb = false;
                    ebooking.awb_number_1 = "";
                    ebooking.awb_number_2 = "";
                    ebooking.t1_shipment = "";
                    if (row.pq.FromPortCode) {
                        ebooking.deliver_from = "Airport";
                        ebooking.t1_shipment = "P";
                        ebooking.deliver_from_airport = row.pq.FromPortCode;
                        var getSelectedFromP = port.ports.find(p => p.portname === ebooking.deliver_from_airport);
                        if (getSelectedFromP) {
                            ebooking.deliver_from_airport_number = getSelectedFromP.pnum;
                            ebooking.deliver_from_airport_data = getSelectedFromP;
                        }
                    } else {
                        ebooking.deliver_from = "ZipCode";
                        ebooking.t1_shipment = "D";
                        ebooking.deliver_from_zipcode = row.pq.FromZipCode + "";
                        ebooking.deliver_from_city = row.pq.FromCity;
                    }
                    ebooking.t1_shipment += "T";
                    if (row.pq.ToPortCode) {
                        ebooking.deliver_to = "Airport";
                        ebooking.t1_shipment += "P";
                        ebooking.deliver_to_airport = row.pq.ToPortCode;
                        var getSelectedToP = port.ports.find(p => p.portname === ebooking.deliver_to_airport);
                        if (getSelectedToP) {
                            ebooking.deliver_to_airport_number = getSelectedToP.pnum;
                            ebooking.deliver_to_airport_data = getSelectedToP;
                        }
                    } else {
                        ebooking.deliver_to = "ZipCode";
                        ebooking.t1_shipment += "D";
                        ebooking.deliver_to_zipcode = row.pq.ToZipCode + "";
                        ebooking.deliver_to_city = row.pq.ToCity;
                    }
                    ebooking.deliver_from_country = row.pq.FromCountryCode;
                    ebooking.deliver_to_country = row.pq.ToCountryCode;
                    ebooking.commodity_type = row.pq.Commodity + "";
                    ebooking.commodity_type_name = row.pq.Commodity + "";
                    ebooking.sub_commodity = row.pq.SubCommodity + "";
                    ebooking.sub_commodity_name = row.pq.SubCommodity + "";

                    ebooking.units_type = 'Centimeters';
                    ebooking.units_type_short = "CM";
                    if (response.data.dim.length) {
                        ebooking.units_type_short = response.data.dim[0].unitname;
                        ebooking.units_type = (ebooking.units_type_short.toUpperCase() == 'CM') ? "Centimeters" : "Inches";
                    }
                    // if (ebooking.units_type_short == "MC") ebooking.units_type_short = "CM";
                    ebooking.units_total_weight = parseFloat(row.pq.Weight);
                    ebooking.units_total_chargable_weight = 0;
                    response.data.dim.map(dim => {
                        var unit_volume = parseFloat(dim.Cbm);
                        if (dim.Height != "" && dim.Length != "" && dim.Width != "") {
                            unit_volume = parseFloat(dim.Height) * parseFloat(dim.Length) * parseFloat(dim.Width) * 0.000001;
                        }
                        // ebooking.units_total_weight += parseFloat(dim.Weight);
                        ebooking.units_list.push({
                            'unitname': ebooking.units_type_short.toUpperCase(),
                            'Pcs': parseInt(dim.Pcs),
                            'Height': parseFloat(dim.Height),
                            'Length': parseFloat(dim.Length),
                            'Width': parseFloat(dim.Width),
                            'Cbm': unit_volume.toFixed(3)
                        });
                    });

                    ebooking.units_total_num_of_pieces = 0;
                    ebooking.units_total_volume = 0;
                    // ebooking.units_total_weight = 0;
                    var overlap_shipment_check = false;
                    ebooking.units_list.forEach(u => {
                        ebooking.units_total_num_of_pieces += parseInt(u.Pcs);
                        var Cbm = parseFloat(u.Cbm) * parseInt(u.Pcs);
                        ebooking.units_total_volume += Cbm;
                        if (ebooking.units_type_short == "CM") {
                            var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                            if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                                overlap_shipment_check = true;
                            }
                        } else {
                            var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                            if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                                overlap_shipment_check = true;
                            }
                        }
                    });
                    ebooking.overlap_shipment = row.pq.Overlap ? "Overlap" : "NonOverlap";
                    ebooking.overlap_shipment_check = overlap_shipment_check;
                    ebooking.units_total_volume = ebooking.units_total_volume.toFixed(3);

                    if (ebooking.units_list.length == 0) {
                        ebooking.units_total_num_of_pieces = "";
                        ebooking.units_total_volume = "";
                        ebooking.units_total_weight = "";
                        ebooking.units_total_chargable_weight = "";
                    } else {
                        var totalVolume = ebooking.units_total_volume / 6 * 1000;
                        if (ebooking.units_type_short != "CM") {
                            totalVolume = totalVolume * 16.387064;
                        }
                        var totalChargableWeight = Math.max(totalVolume, ebooking.units_total_weight);
                        ebooking.units_total_chargable_weight = totalChargableWeight.toFixed(1);
                    }

                    ebooking.shipment_dangerous_goods = row.pq.CaoPax ? true : false;
                    ebooking.shipment_dangerous_goods_type = row.pq.CaoPax ? ((row.pq.CaoPax == "CAO" || row.pq.CaoPax == "PAX") ? row.pq.CaoPax : "CAO") : "CAO";
                    ebooking.shipment_lithium_batteries = row.pq.Litium ? true : false;
                    ebooking.shipment_unstackable = row.pq.Unstackable ? true : false;

                    if (row.pq.HandlingInstrunctionCodes) {
                        row.pq.HandlingInstrunctionCodes.map(ins => {
                            ebooking.instructions.push({
                                value: ins,
                                label: ins
                            })
                        });
                    }
                    ebooking.additional_information = row.pq.Remarks;
                    ebooking.isAwb = true;
                    ebooking.checkbox_term = false;
                    ebooking.checkbox_term_df = false;
                    ebooking.company_name_of_consignee = row.pq.CNee;
                    ebooking.company_name_of_consignor = row.pq.CNor;

                    ebooking.receivingCompanyName = response.data.Participents.RECNAME.trim() + "";
                    ebooking.receivingIATA1 = response.data.Participents.RECIATACODE.trim() ? response.data.Participents.RECIATACODE.trim().substring(0, 7) : "";
                    ebooking.receivingIATA2 = response.data.Participents.RECIATACODE.trim().substring(7, response.data.Participents.RECIATACODE.length);
                    ebooking.checkReceivingIATA = response.data.Participents.SHIPIATACODE.trim() ? true : false;
                    ebooking.shippingCompanyName = response.data.Participents.SHIPNAME.trim() + "";
                    ebooking.shippingIATA1 = response.data.Participents.SHIPIATACODE.trim() ? response.data.Participents.SHIPIATACODE.trim().substring(0, 7) : "";
                    ebooking.shippingIATA2 = response.data.Participents.SHIPIATACODE.trim().substring(7, response.data.Participents.SHIPIATACODE.trim().length);
                    // console.log(response.data.Participents.SHIPIATACODE, ebooking.shippingIATA1, ebooking.shippingIATA2);
                    ebooking.consignorCompanyName = response.data.Participents.CNORNAME ? response.data.Participents.CNORNAME : "";
                    ebooking.consignorAddress = response.data.Participents.CNORADDRESS ? response.data.Participents.CNORADDRESS : "";
                    ebooking.consignorCity = response.data.Participents.CNORCITY ? response.data.Participents.CNORCITY : "";
                    ebooking.consignorCountry = response.data.Participents.CNORCOUNTRY ? response.data.Participents.CNORCOUNTRY : "";
                    ebooking.consignorGroundHandling = response.data.Participents.CNORNUM ? response.data.Participents.CNORNUM : ""; //chu y cai nay
                    ebooking.consignorPostalCode = response.data.Participents.CNORPOSTALCODE ? response.data.Participents.CNORPOSTALCODE : "";
                    ebooking.consigneeAddress = response.data.Participents.CNEEADDRESS ? response.data.Participents.CNEEADDRESS : "";
                    ebooking.consigneeCity = response.data.Participents.CNEECITY ? response.data.Participents.CNEECITY : "";
                    ebooking.consigneeCompanyName = response.data.Participents.CNEENAME ? response.data.Participents.CNEENAME : "";
                    ebooking.consigneeCountry = response.data.Participents.CNEECOUNTRY ? response.data.Participents.CNEECOUNTRY : "";
                    ebooking.consigneeGroundHandling = response.data.Participents.CNEENUM ? response.data.Participents.CNEENUM : ""; //chu y cai nay
                    ebooking.consigneePostalCode = response.data.Participents.CNEEPOSTALCODE ? response.data.Participents.CNEEPOSTALCODE : "";
                    ebooking.pickupCheck = false;
                    ebooking.pickupAddress = response.data.Participents.PICKADDRESS ? response.data.Participents.PICKADDRESS : "";
                    ebooking.pickupCity = response.data.Participents.PICKCITY ? response.data.Participents.PICKCITY : "";
                    ebooking.pickupComments = response.data.Participents.PICKREMARKS ? response.data.Participents.PICKREMARKS : "";
                    ebooking.pickupCountry = response.data.Participents.PICKCOUNTRY ? response.data.Participents.PICKCOUNTRY : "";
                    ebooking.pickupEmail = response.data.Participents.PICKEMAIL ? response.data.Participents.PICKEMAIL : "";
                    ebooking.pickupCompanyName = response.data.Participents.PICKCOMPNAME ? response.data.Participents.PICKCOMPNAME : "";
                    ebooking.pickupNameOfContact = response.data.Participents.PICKNAME ? response.data.Participents.PICKNAME : "";
                    ebooking.pickupOpeningHours = response.data.Participents.PICKOPENHOURS ? response.data.Participents.PICKOPENHOURS : "";
                    ebooking.pickupPhoneNumber = response.data.Participents.PICKPHONE ? response.data.Participents.PICKPHONE : "";
                    ebooking.pickupPostalCode = response.data.Participents.PICKPOSTALCODE ? response.data.Participents.PICKPOSTALCODE : "";
                    ebooking.deliverAddress = response.data.Participents.DELADDRESS ? response.data.Participents.DELADDRESS : "";
                    ebooking.deliverCheck = false;
                    ebooking.deliverCity = response.data.Participents.DELCITY ? response.data.Participents.DELCITY : "";
                    ebooking.deliverComments = response.data.Participents.DELREMARKS ? response.data.Participents.DELREMARKS : "";
                    ebooking.deliverCountry = response.data.Participents.DELCOUNTRY ? response.data.Participents.DELCOUNTRY : "";
                    ebooking.deliverEmail = response.data.Participents.DELEMAIL ? response.data.Participents.DELEMAIL : "";
                    ebooking.deliverCompanyName = response.data.Participents.DELCOMPNAME ? response.data.Participents.DELCOMPNAME : "";
                    ebooking.deliverNameOfContact = response.data.Participents.DELNAME ? response.data.Participents.DELNAME : "";
                    ebooking.deliverOpeningHours = response.data.Participents.DELOPENHOURS ? response.data.Participents.DELOPENHOURS : "";
                    ebooking.deliverPhoneNumber = response.data.Participents.DELPHONE ? response.data.Participents.DELPHONE : "";
                    ebooking.deliverPostalCode = response.data.Participents.DELPOSTALCODE ? response.data.Participents.DELPOSTALCODE : "";

                    this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                    errorMessage = "";
                    this.setState({ isError, errorMessage });
                    history.push("/ebooking/shipment");
                } else {
                    isError = true;
                    errorMessage = "Get AWB Data Unsuccess!";
                    this.setState({ isError, errorMessage });
                }
            });
        } else {
            this.setState({ isError: true, errorMessage: "AWB is empty" });
        }
    }

    awbHandle = (row) => {
        const { history } = this.props;
        // history.push("/review-order/?awb=" + row.awb);
    }

    handleAttachment = (row) => {
        const { history } = this.props;
        history.push("/ebooking/attach-files/?awb=" + row.awb);
        // window.open(window.location.origin + '/ebooking/attach-files/?awb=' + row.awb, '_blank');
    }

    handlePrintPDF = async (row) => {
        var { auth, port } = this.props;
        this.props.loadingActions.loadingChangeData(true);
        // console.log(row);
        let getBidAWB = await this.props.dashboardActions.getDetailsOrder(row.pq.AWB);
        // let getBidAWB = await this.props.ebookingActions.getBidAWB(row.awb, auth.jwtToken);
        // console.log(getBidAWB);
        if (getBidAWB.success) {
            let quotes = {};
            quotes.CProfNum = row.awb;
            quotes.unit_type = { value: 'pieces', label: 'Pieces' };
            quotes.unit_length = { value: "cm", label: "Cm" };
            quotes.unit_items = [];
            if (getBidAWB.data.dim.length) {
                if (getBidAWB.data.dim[0].paltype) {
                    quotes.unit_type = { label: 'ULD', value: 'uld' };
                } else if (getBidAWB.data.dim[0].unitname.toUpperCase() !== 'CM') {
                    quotes.unit_length = { value: 'in', label: 'Inch' };
                }

                getBidAWB.data.dim.map(unit => {
                    let cbm = BookingHelper.calculatorVolume(unit.Pcs, unit.Length, unit.Width, unit.Height);
                    if (unit.paltype) {
                        var findUld = AppConfig.uldOptions.find(uld => uld.value === unit.paltype);
                        quotes.unit_items.push({ item_uld: (findUld ? findUld : null), item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                    } else {
                        quotes.unit_items.push({ item_uld: null, item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                    }
                })
            }
            quotes.unit_total = { total_pieces: row.pq.Pieces, total_volume: row.pq.Volume, total_weight: row.pq.Weight, tcw: row.pq.CWeight };


            quotes.selected_flight_review = null;
            if (row.flights && row.flights.fnumber) {
                let eta = moment(row.flights.eta, 'MMMM Do, HH:mm');
                let etd = moment(row.flights.etd, 'MMMM Do, HH:mm');
                var duration = BookingHelper.getDuration(etd, eta);
                // console.log(row.flights);
                quotes.selected_flight_review = {
                    duration,
                    fleg: {
                        duration,
                        eta: null,
                        etd: null,
                        fnumber: row.flights.fnumber,
                        fport: row.flights.from,
                        leg: '',
                        sta: eta.format('DD/MM/YY HH:mm'),
                        std: etd.format('DD/MM/YY HH:mm'),
                        tport: row.flights.to
                    },
                    sta: eta.format('DD/MM/YY HH:mm'),
                    std: etd.format('DD/MM/YY HH:mm'),
                    tleg: {
                        eta: null,
                        etd: null,
                        fnumber: null,
                        fport: null,
                        leg: null,
                        sta: null,
                        std: null,
                        tport: null
                    },
                    vialeg: {
                        eta: null,
                        etd: null,
                        fnumber: null,
                        fport: null,
                        leg: null,
                        sta: null,
                        std: null,
                        tport: null
                    },
                    wdStd: ""
                }
            }
            quotes.deliver_from = row.pq.FromZipCode ? 'ZipCode' : 'Airport';
            quotes.deliver_to = row.pq.ToZipCode ? 'ZipCode' : 'Airport';
            quotes.deliver_from_airport = {
                port: "",
                portname: "",
                calcity: "",
                cityname: "",
                country: "",
                countryname: "",
                flightport: "",
                label: "",
                pnum: "",
                port: "",
                portname: "",
                value: ""
            };
            quotes.deliver_to_airport = {
                port: "",
                portname: "",
                calcity: "",
                cityname: "",
                country: "",
                countryname: "",
                flightport: "",
                label: "",
                pnum: "",
                port: "",
                portname: "",
                value: ""
            };
            var findAirportFrom = port.ports.find(el => el.value === row.pq.FromPortCode.trim());
            if (findAirportFrom) {
                quotes.deliver_from_airport = findAirportFrom;
            }
            var findAirportTo = port.ports.find(el => el.value === row.pq.ToPortCode.trim());
            if (findAirportTo) {
                quotes.deliver_to_airport = findAirportTo;
            }
            quotes.Commodity = row.pq.Commodity ? row.pq.Commodity : "";
            quotes.SubCommodity = row.pq.SubCommodity ? row.pq.SubCommodity : "";
            quotes.shippingCompanyName = getBidAWB.data.Participents.SHIPNAME;
            const { SHIPIATACODE, RECIATACODE } = getBidAWB.data.Participents;
            quotes.shippingIATA1 = SHIPIATACODE ? SHIPIATACODE.trim().substr(0, 7) : '';
            quotes.shippingIATA2 = SHIPIATACODE ? SHIPIATACODE.trim().substr(7) : '';
            quotes.receivingCompanyName = getBidAWB.data.Participents.RECNAME;
            quotes.receivingIATA1 = RECIATACODE ? RECIATACODE.trim().substr(0, 7) : '';
            quotes.receivingIATA2 = RECIATACODE ? RECIATACODE.trim().substr(7) : '';

            quotes.consignorAddress = getBidAWB.data.Participents.CNORADDRESS;
            quotes.consignorCity = getBidAWB.data.Participents.CNORCITY;
            quotes.consignorCompanyName = row.consignor.cnor;
            quotes.consignorCountry = getBidAWB.data.Participents.CNORCOUNTRY;
            quotes.consignorPostalCode = getBidAWB.data.Participents.CNORPOSTALCODE;

            quotes.consigneeAddress = getBidAWB.data.Participents.CNEEADDRESS;
            quotes.consigneeCity = getBidAWB.data.Participents.CNEECITY;
            quotes.consigneeCompanyName = row.consignee.cnee;
            quotes.consigneeCountry = getBidAWB.data.Participents.CNEECOUNTRY;
            quotes.consigneePostalCode = getBidAWB.data.Participents.CNEEPOSTALCODE;

            quotes.pickupCity = getBidAWB.data.Participents.PICKCITY;
            quotes.pickupCompanyName = getBidAWB.data.Participents.PICKCOMPNAME;
            quotes.pickupComments = getBidAWB.data.Participents.PICKREMARKS;
            quotes.pickupAddress = getBidAWB.data.Participents.PICKADDRESS;
            quotes.pickupCountry = getBidAWB.data.Participents.PICKCOUNTRY;
            quotes.pickupNameOfContact = getBidAWB.data.Participents.PICKNAME;
            quotes.pickupEmail = getBidAWB.data.Participents.PICKEMAIL;
            quotes.pickupPhoneNumber = getBidAWB.data.Participents.PICKPHONE;
            quotes.pickupPostalCode = getBidAWB.data.Participents.PICKPOSTALCODE;

            quotes.deliverCity = getBidAWB.data.Participents.DELCITY;
            quotes.deliverCompanyName = getBidAWB.data.Participents.DELCOMPNAME;
            quotes.deliverComments = getBidAWB.data.Participents.DELREMARKS;
            quotes.deliverAddress = getBidAWB.data.Participents.DELADDRESS;
            quotes.deliverCountry = getBidAWB.data.Participents.DELCOUNTRY;
            quotes.deliverNameOfContact = getBidAWB.data.Participents.DELNAME;
            quotes.deliverEmail = getBidAWB.data.Participents.DELEMAIL;
            quotes.deliverPhoneNumber = getBidAWB.data.Participents.DELPHONE;
            quotes.deliverPostalCode = getBidAWB.data.Participents.DELPOSTALCODE;
            // if (getBidAWB.data.awbsec && getBidAWB.data.awbsec.length) {
            //     let awbsec = getBidAWB.data.awbsec[0];
            //     // quotes.consignorCompanyName = awbsec.CONSIGNORDES;
            //     quotes.consignorCountry = awbsec.CONSIGNORCOUNTRY.trim();
            //     quotes.consignorCity = awbsec.CONSIGNORCITY.trim();
            //     quotes.consignorAddress = awbsec.CONSIGNORADDRESS.trim();
            //     quotes.consignorPostalCode = awbsec.CONSIGNORZIP.trim();

            //     // quotes.consigneeCompanyName = awbsec.CONSIGNEEDES;
            //     quotes.consignorCountry = awbsec.CONSIGNEECOUNTRY.trim();
            //     quotes.consignorCity = awbsec.CONSIGNEECITY.trim();
            //     quotes.consignorAddress = awbsec.CONSIGNEEADDRESS.trim();
            //     quotes.consignorPostalCode = awbsec.CONSIGNEEZIP.trim();

            //     quotes.pickupCompanyName = awbsec.PICKNAME.trim();
            //     quotes.pickupPhoneNumber = awbsec.PICKPHONE.trim();
            //     quotes.pickupEmail = awbsec.PICKEMAIL.trim();

            //     quotes.deliverCompanyName = awbsec.DELNAME.trim();
            //     quotes.deliverPhoneNumber = awbsec.DELPHONE.trim();
            //     quotes.deliverEmail = awbsec.DELEMAIL.trim();
            // }

            Pdf.exportBookingPDF(quotes, 'cal-awb-', 'Your Booking '+row.pq.AWB);
            // this.setState({ getQuotesData });
            // this.openPrintModal();           
        } else {
            // console.log("Go to here 2");
            this.setState({ isError: true, errorMessage: MESSAGES.ERROR_FINDING_QUOTE });
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleOpenContact = async (row) => {
        let mailData = {
            order: null,
            awb: row.pq.AWB,
            FromPortCode: row.pq.FromPortCode,
            FromCity: row.pq.FromCity,
            FromCountryCode: row.pq.FromCountryCode,
            FromZipCode: row.pq.FromZipCode,
            ToCity: row.pq.ToCity,
            ToCountryCode: row.pq.ToCountryCode,
            ToPortCode: row.pq.ToPortCode,
            ToZipCode: row.pq.ToZipCode,
            ToCountryCode: row.pq.ToCountryCode,
            PhoneName: this.props.auth.user_name,
            PhoneEmail: this.props.auth.user_email,
            PhoneCompany: this.props.auth.custdes,
            rowData: row
        }
        this.setState({ mailData });
        this.openContactModal();
    }

    handleOpenEdit = async (row) => {
        // console.log(row);
        let { isError, errorMessage } = this.state;
        if (row.pq.AWB) {
            this.props.loadingActions.loadingChangeData(true);
            let response = await this.props.dashboardActions.getDetailsOrder(row.pq.AWB);
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                let ebookingData = {
                    awb: row.pq.AWB,
                    units_paltype: 'pieces',
                    units_type: 'Centimeters',
                    units_type_short: 'CM',
                    units_total_weight: 0,
                    units_total_chargable_weight: 0,
                    units_list: [],
                    units_total_num_of_pieces: 0,
                    units_total_volume: 0,
                    overlap_shipment_check: false,
                };
                ebookingData.quoteData = row.pq;
                if (response.data.dim.length) {
                    ebookingData.units_type_short = response.data.dim[0].unitname;
                    ebookingData.units_type = (ebookingData.units_type_short.toUpperCase() == 'CM') ? "Centimeters" : "Inches";
                }

                ebookingData.units_total_weight = parseFloat(row.pq.Weight);
                ebookingData.units_total_chargable_weight = 0;
                let checkPaltype = false;
                response.data.dim.map(dim => {
                    checkPaltype = dim.paltype ? true : false;
                    var unit_volume = parseFloat(dim.Cbm);
                    if (dim.Height != "" && dim.Length != "" && dim.Width != "") {
                        unit_volume = BookingHelper.calculatorVolumeNew(ebookingData.units_type_short, parseFloat(dim.Length), parseFloat(dim.Width), parseFloat(dim.Height));
                    }
                    // ebooking.units_total_weight += parseFloat(dim.Weight);
                    ebookingData.units_list.push({
                        'unitname': ebookingData.units_type_short.toUpperCase(),
                        'Pcs': parseInt(dim.Pcs),
                        'Height': parseFloat(dim.Height),
                        'Length': parseFloat(dim.Length),
                        'Width': parseFloat(dim.Width),
                        'Cbm': unit_volume.toFixed(3),
                        'paltype': dim.paltype
                    });
                });

                if (checkPaltype) {
                    ebookingData.units_paltype = 'uld';
                }

                ebookingData.units_list.forEach(u => {
                    ebookingData.units_total_num_of_pieces += parseInt(u.Pcs);
                    var Cbm = parseFloat(u.Cbm) * parseInt(u.Pcs);
                    ebookingData.units_total_volume += Cbm;
                    if (ebookingData.units_type_short == "CM") {
                        var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                        if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                            ebookingData.overlap_shipment_check = true;
                        }
                    } else {
                        var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                        if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                            ebookingData.overlap_shipment_check = true;
                        }
                    }
                });
                ebookingData.overlap_shipment = row.pq.Overlap ? "Overlap" : "NonOverlap";
                ebookingData.units_total_volume = ebookingData.units_total_volume.toFixed(3);

                if (ebookingData.units_list.length == 0) {
                    ebookingData.units_total_num_of_pieces = "";
                    ebookingData.units_total_volume = "";
                    ebookingData.units_total_weight = "";
                    ebookingData.units_total_chargable_weight = "";
                } else {
                    var totalVolume = BookingHelper.calculatorVolumeKG(ebookingData.units_total_volume);
                    var totalChargableWeight = Math.max(totalVolume, ebookingData.units_total_weight);
                    ebookingData.units_total_chargable_weight = totalChargableWeight.toFixed(1);
                }
                let mailData = {
                    awb: row.pq.AWB,
                    FromPortCode: row.pq.FromPortCode,
                    FromCity: row.pq.FromCity,
                    FromCountryCode: row.pq.FromCountryCode,
                    FromZipCode: row.pq.FromZipCode,
                    ToCity: row.pq.ToCity,
                    ToCountryCode: row.pq.ToCountryCode,
                    ToPortCode: row.pq.ToPortCode,
                    ToZipCode: row.pq.ToZipCode,
                    ToCountryCode: row.pq.ToCountryCode,
                    PhoneName: this.props.auth.user_name,
                    PhoneEmail: this.props.auth.user_email,
                    PhoneCompany: this.props.auth.custdes
                }

                this.setState({ shipmentData: ebookingData, mailData });
                this.openEditModal();
            } else {
                isError = true;
                errorMessage = "Get AWB Data Unsuccess!";
                this.setState({ isError, errorMessage });
            }
        } else {
            this.setState({ isError: true, errorMessage: "AWB is empty" });
        }
    }
    handleOpenCanBe = (row) => {
        this.openCanBeDoneModal();
    }

    handleLocation = (row) => {
        window.open('https://www.cal-cargo.com/tracking/?awb=' + row.awb, '_blank');
    }

    handleMore = () => {
        var { rowPerPage } = this.state;
        rowPerPage += 10;
        this.setState({ rowPerPage });
        // this.buildData(this.state.allData);
        window.loadTooltip();
    }

    /* Validate form */
    isValidation() {

        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }

        const { history } = this.props;
        // history.push('/ebooking-detail');
    }

    getData = (showSearchBox = true) => {
        localStorage.removeItem('DASHBOARD_REFRESH');
        var { allData, data, loading, search, rowPerPage, isShowSearchBox } = this.state;
        isShowSearchBox = showSearchBox;
        rowPerPage = 10;
        if (!loading) {
            allData = [];
            data = [];
            this.setState({ allData, data, loading: true, rowPerPage, isShowSearchBox });
            this.props.loadingActions.loadingChangeData(true);
            this.props.dashboardActions.getManagerPage({ days: search.date }, this.props.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
                this.setState({ loading: false });
            });
        }
    }

    convertDataFromRedux = (searchFromRedux = false) => {
        var { allData, data, loading, search, consignorSuggests, consigneeSuggests, isShowSearchBox } = this.state;
        if (searchFromRedux) {
            search = this.props.dashboard.search;
        }
        consignorSuggests = [];
        consigneeSuggests = [];
        allData = [];
        data = [];
        var reduxData = this.props.dashboard.data;
        // console.log('reduxData', reduxData);
        search.date = reduxData.days;
        var AWBData = reduxData.AWB;
        if (!AWBData || !AWBData.length) {
            isShowSearchBox = true;
        }
        if (AWBData) {
            for (let i = 0; i < AWBData.length; i++) {
                var element = AWBData[i];
                var checkCNee = consigneeSuggests.find(el => el.name == element.PQ.CNee);
                if (!checkCNee && element.PQ.CNee) {
                    consigneeSuggests.push({ name: element.PQ.CNee });
                }
                var checkCNOR = consignorSuggests.find(el => el.name == element.PQ.CNor);
                if (!checkCNOR && element.PQ.CNor) {
                    consignorSuggests.push({ name: element.PQ.CNor });
                }
            }
            if (search.awb) {
                AWBData = AWBData.filter(el => el.PQ.AWB.indexOf(search.awb) > -1);
            }
            if (search.from) {
                AWBData = AWBData.filter(el => {
                    if (el.PQ.FromPortCode) {
                        if (el.PQ.FromPortCode.indexOf(search.from.toUpperCase()) > -1) {
                            return el;
                        }
                    } else {
                        if (el.PQ.FromZipCode.indexOf(search.from.toUpperCase()) > -1) {
                            return el;
                        }
                    }
                });
            }
            if (search.cosignee) {
                AWBData = AWBData.filter(el => el.PQ.CNee.indexOf(search.cosignee) > -1);
            }
            if (search.consignor && AWBData) {
                AWBData = AWBData.filter(el => el.PQ.CNor.indexOf(search.consignor) > -1);
            }
        }

        this.setState({ allData, data });
        var compairDate = BookingHelper.getDate("01/01/2000 00:00", "DD/MM/YYYY HH:mm");
        if (AWBData) {
            for (let i = 0; i < AWBData.length; i++) {
                var element = AWBData[i];
                var createDate = BookingHelper.getDate(element.PQ.PDate, "DD/MM/YYYY HH:mm");
                var etd = BookingHelper.getDate(element.SECTORS.std, "DD/MM/YYYY HH:mm");
                var eta = BookingHelper.getDate(element.SECTORS.sta, "DD/MM/YYYY HH:mm");
                var percent = 0;
                if (element.PQ.ETDOrigin) {
                    var etdOriginDate = BookingHelper.getDate(element.PQ.ETDOrigin, "DD/MM/YYYY HH:mm");
                    if (moment.isMoment(etdOriginDate) && etdOriginDate > compairDate) percent = 100;
                }
                var row = {
                    awb: element.PQ.AWB,
                    type: {
                        name: element.PQ.Commodity,
                        pieces: element.PQ.Pieces,
                        weight: element.PQ.Weight,
                        cweight: element.PQ.CWeight,
                        volume: element.PQ.Volume
                    },
                    flights: {
                        fnumber: element.SECTORS.fnumber,
                        from: element.SECTORS.fromport,
                        to: element.SECTORS.toport,
                        etd: (element.SECTORS.std !== '// :' && element.SECTORS.std !== '') ? etd.format("MMMM Do, HH:mm") : '',
                        eta: (element.SECTORS.sta !== '// :' && element.SECTORS.sta !== '') ? eta.format("MMMM Do, HH:mm") : '',
                        pqfromCity: element.PQ.FromCity,
                        pqfromCountry: element.PQ.FromCountryCode,
                        pqfrom: element.PQ.FromPortCode ? element.PQ.FromPortCode : element.PQ.FromZipCode,
                        pqtoCity: element.PQ.ToCity,
                        pqtoCountry: element.PQ.ToCountryCode,
                        pqto: element.PQ.ToPortCode ? element.PQ.ToPortCode : element.PQ.ToZipCode,
                        percent: percent
                    },
                    consignee: { cnee: element.PQ.CNee, cnor: element.PQ.CNor },
                    consignor: { cnee: element.PQ.CNee, cnor: element.PQ.CNor },
                    // consignee: element.PQ.CNee,
                    // consignor: element.PQ.CNor,
                    creationDate: createDate.format("YYYYMMDD"),
                    // creationDate: createDate.format("DD MMMM, YYYY"),
                    duplicate: i,
                    pq: element.PQ,
                    sectors: element.SECTORS
                }
                allData.push(row);

            }
        }
        this.setState({ allData, consignorSuggests, consigneeSuggests, isShowSearchBox });
        this.buildData(allData, true);
    }

    buildData(allData, resetTable = false) {
        var { rowPerPage, data, s_more } = this.state;
        data = [];
        var startindex = 0;
        var endIndex = allData.length;
        // }
        for (let i = startindex; i < endIndex; i++) {
            data.push(allData[i]);
        }
        this.setState({ data });
        window.loadTooltip();

    }

    onSortedChange = () => {
        var { rowPerPage } = this.state;
        rowPerPage = 10;
        this.setState({ rowPerPage });
        window.loadTooltip();
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        const { search, isError, errors, errorMessage, data, allData, lastYear, suggestionsCNEE, suggestionsCNOR, rowPerPage, s_more, modalCanBeDoneIsOpen, modalContactIsOpen, modalEditIsOpen, modalPrintIsOpen, shipmentData, mailData } = this.state;
        let random = Math.floor((Math.random() * 10) + 1);

        // Autosuggest will pass through all these props to the input.
        var inputPropsCNEE = {
            placeholder: '',
            value: search.cosignee ? search.cosignee : "",
            onChange: this.onChangeSugestCNEE
        };
        var inputPropsCNOR = {
            placeholder: '',
            value: search.consignor ? search.consignor : "",
            onChange: this.onChangeSugestCNOR
        };

        const columns = [
            {
                Header: 'AWB',
                accessor: 'awb',
                width: 140,
                headerClassName: 'thAWB',
                className: 'tdAWB',
                Cell: row => (
                    <div>
                        <div className={`td-line ${random} ${random % 2 === 0 ? 'line-orange' : 'line-orange'}`}></div>
                        <a className={`no-underline cursor-text ${row.value.length < 13 ? '' : 'small-text'}`} href="javascript:void(0)">{row.value}</a>
                        {/* <a className={`no-underline ${row.value.length < 13 ? '' : 'small-text'}`} href="javascript:void(0)" onClick={() => this.awbHandle(row.original)}>{row.value}</a> */}
                    </div>
                )
            },
            {
                Header: 'Commodity',
                accessor: 'type',
                sortMethod: (a, b) => {
                    if (a.name === b.name) {
                        return a.name > b.name ? 1 : -1;
                    }
                    return a.name > b.name ? 1 : -1;
                },
                minWidth: 100,
                headerClassName: 'thType',
                className: 'tdType',
                Cell: props => <div className='tdType'
                >
                    <PopperItem data={props.row.type}></PopperItem>
                </div>
            },
            {
                Header: 'From / To',
                accessor: 'flights',
                sortMethod: (a, b) => {
                    if (a.pqfrom === b.pqfrom) {
                        return a.pqfrom > b.pqfrom ? 1 : -1;
                    }
                    return a.pqfrom > b.pqfrom ? 1 : -1;
                },
                width: 230,
                headerClassName: 'thFlights',
                className: 'tdFlights',
                Cell: props => <div style={{ width: '100%' }}>
                    <PopperItemFlight data={props.row.flights}></PopperItemFlight>

                </div>
            },
            {
                Header: 'Consignee',
                accessor: 'consignee',
                sortMethod: (a, b) => {
                    if (a.cnee === b.cnee) {
                        return a.cnee > b.cnee ? 1 : -1;
                    }
                    return a.cnee > b.cnee ? 1 : -1;
                },
                width: 145,
                headerClassName: 'thConsignee',
                className: 'td-consignee',
                Cell: props => <div className='td-consignee'
                >
                    <PopperItemConsignee show={'consignee'} data={props.row.consignee}></PopperItemConsignee>
                </div>
            },
            {
                Header: 'Consignor',
                accessor: 'consignor',
                sortMethod: (a, b) => {
                    if (a.cnor === b.cnor) {
                        return a.cnor > b.cnor ? 1 : -1;
                    }
                    return a.cnor > b.cnor ? 1 : -1;
                },
                width: 145,
                headerClassName: 'thConsignor',
                className: 'td-consignor',
                Cell: props => <div className='td-consignor'>
                    <PopperItemConsignee show={'cnor'} data={props.row.consignor}></PopperItemConsignee>
                </div>
            },
            {
                Header: 'Creation Date',
                accessor: 'creationDate',
                width: 140,
                headerClassName: 'thCreationDate',
                className: 'tdCreationDate',
                Cell: props => <div>
                    {BookingHelper.getDate(props.row.creationDate, "YYYYMMDD").format("DD MMMM, YYYY")}
                </div>
            },
            {
                Header: '',
                accessor: 'duplicate',
                width: 220,
                sortable: false,
                headerClassName: 'thDuplicate',
                className: 'tdDuplicate text-left',
                Cell: row => (
                    <div>
                        <button type="button" onClick={() => this.dupHandle(row.original)} className="btn-tools btn-duplicate"></button>
                        <button type="button" onClick={() => this.handleAttachment(row.original)} className="btn-tools btn-attachment"></button>
                        <button type="button" onClick={() => this.handlePrintPDF(row.original)} className="btn-tools btn-pdf"></button>
                        <button type="button" type="button" onClick={() => this.handleOpenContact(row.original)} className="btn-tools btn-chat"></button>
                        {row.original.pq && row.original.pq.changeallowed && row.original.pq.changeallowed.toUpperCase() !=='N' ? (
                            <button type="button" onClick={() => this.handleOpenEdit(row.original)} className="btn-tools btn-edit"></button>
                        ) : (
                            <button type="button" onClick={() => this.handleLocation(row.original)} className="btn-tools btn-location"></button>
                        )}
                    </div>
                ),
                // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
            }
        ];

        let expanded = {};
        expanded = data.map((item, index) => Object.assign(expanded, { index: true }));
        const SubComponent = (row) => {
            return (
                <div className={`sub-row-mobile ${random} ${random % 2 === 0 ? 'trOrange' : 'trBlue'}`}>
                    <div className="rt-summary">
                        <span>Consignee: </span> {row.original.consignee.cnee}<br />
                        <span>Consignor: </span> {row.original.consignor.cnor}
                    </div>
                    <div className="rt-footer">
                        <div className='flights'>
                            <div className="f-start">{row.original.flights.from}</div>
                            <div className="f-progress">
                                <div className="dot-from"></div>
                                <div className="dot-currrent" style={{ left: row.original.percent + '%' }}></div>
                                <div className="dot-to"></div>
                            </div>
                            <div className="f-end">{row.original.flights.to}</div>
                        </div>
                        <div className="sub-btn-group">
                            <button type="button" onClick={() => this.dupHandle(row.original)} className="btn-tools btn-duplicate"></button>
                            <button type="button" onClick={() => this.handleAttachment(row.original)} className="btn-tools btn-attachment"></button>
                            <button type="button" onClick={() => this.handlePrintPDF(row.original)} className="btn-tools btn-pdf"></button>
                            <button type="button" onClick={() => this.handleOpenContact(row.original)} className="btn-tools btn-chat"></button>
                            {row.original.pq && row.original.pq.changeallowed && row.original.pq.changeallowed.toUpperCase() !=='N' ? (
                                <button type="button" onClick={() => this.handleOpenEdit(row.original)} className="btn-tools btn-edit"></button>
                            ) : (
                                <button type="button" onClick={() => this.handleLocation(row.original)} className="btn-tools btn-location"></button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        const { isShowSearchBox } = this.state;
        var { openFilter } = this.props.dashboard;
        return (
            <div className="page-content">
                <Nav index={1} />
                {/* <h2>Printing? {JSON.stringify(this.props.printing)}</h2> */}

                <div className="container cal-form">
                    <div className="search-area">
                        <form autoComplete="off">
                            <div className="s-title text-center">All records are in local time</div>
                            <button type="button" className={`btn-search ${openFilter ? 'active' : ''}`} onClick={() => { this.toogleSearchBox(true) }}>Search</button>

                            <div className={`form-row my-account-search filter-box ${(openFilter) ? '' : ' s-hidden'}`}>
                                <button type="button" className="close-filter" onClick={() => { this.toogleSearchBox(false) }}></button>
                                <div className="form-group group-1">
                                    <label>AWB</label>
                                    <input type="text" className="form-control" name="awb" onChange={this.handleChange} value={search.awb} />
                                    <InputError error={errors.awb} />
                                </div>
                                <div className="form-group group-2">
                                    <label>From</label>
                                    <input type="text" className="form-control" name="from" onChange={this.handleChange} value={search.from} />
                                    <InputError error={errors.from} />
                                </div>
                                <div className="form-group group-3 dashboard-suggest">
                                    <label>Consignee</label>
                                    <Autosuggest
                                        className={`form-control`}
                                        suggestions={suggestionsCNEE}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNEE}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNEE}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputPropsCNEE}
                                    />
                                    <InputError error={errors.cosignee} />
                                </div>
                                <div className="form-group group-4 dashboard-suggest">
                                    <label>Consignor</label>
                                    <Autosuggest
                                        className={`form-control`}
                                        suggestions={suggestionsCNOR}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNOR}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNOR}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputPropsCNOR}
                                    />
                                    <InputError error={errors.consignor} />
                                </div>
                                <div className="form-group group-5 pr-0 myaccount">
                                    <label>Recent Activity</label>
                                    <select className="form-control" name="date" value={search.date} onChange={this.handleChange} >
                                        <option value="30">30 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="180">6 Months</option>
                                        <option value={lastYear}>1 Year</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className="mb-3">
                        <span>Showing {data.length ? (rowPerPage > data.length ? data.length : rowPerPage) : 0}/{data.length} records</span>
                        <span className="ml-3"><button type="button" className="btn btn-secondary btn-sm btn-refresh" onClick={this.getData.bind(this, this.state.isShowSearchBox)}>Refresh</button></span>
                    </div>
                    <div className={`account-table fixed-footer ${data.length ? '' : 'd-none'} ${this.props.printing ? 'printing' : ''}`}>
                        <ReactTable
                            data={data}
                            columns={columns}
                            showPagination={false}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            defaultPageSize={rowPerPage}
                            pageSize={rowPerPage}
                            minRows={0}
                            defaultSorted={[{
                                id: 'creationDate',
                                desc: true,
                            }]}
                            resizable={false}
                            expanded={expanded}
                            SubComponent={SubComponent}
                            onSortedChange={this.onSortedChange}
                        />
                    </div>
                    <div className={`text-center div-load-more ${rowPerPage < data.length ? '' : 'd-none'}`}>
                        <button className="btn btn-more" onClick={this.handleMore}>More</button>
                    </div>
                    <div className={`text-center no-row ${data.length ? 'd-none' : ''}`}>No records found</div>
                </div>
                <Modal
                    isOpen={modalContactIsOpen}
                    onRequestClose={this.closeContactModal}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardContact
                        isBooking
                        mailData={mailData}
                        onShow={modalContactIsOpen}
                        closeModal={this.closeContactModal}
                        onHide={this.closeContactModal}
                        setMsg={(msgObj) => this.setState(msgObj)}
                        history={this.props.history}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalEditIsOpen}
                    onRequestClose={this.closeEditModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardEditAwbDashboard
                        handleExceedsLimitations={this.handleExceedsLimitations}
                        handleUpdateShipmentSuccess={this.handleUpdateShipmentSuccess}
                        shipmentData={shipmentData}
                        onShow={modalEditIsOpen}
                        closeModal={this.closeEditModal}
                        onHide={this.closeEditModal}
                        setMsg={(msgObj) => this.setState(msgObj)}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalCanBeDoneIsOpen}
                    onRequestClose={this.closeCanBeDoneModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardCantBeDone
                        onShow={modalCanBeDoneIsOpen}
                        handleClickContact={this.handleClickContact}
                        closeModal={this.closeCanBeDoneModal}
                        onHide={this.closeCanBeDoneModal}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalPrintIsOpen}
                    onRequestClose={this.closePrintModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardPrintAWB
                        onShow={modalPrintIsOpen}
                        closeModal={this.closePrintModal}
                        onHide={this.closePrintModal}
                        getQuotesData={this.state.getQuotesData}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}
export default detectPrint(connect(mapStateToProps, mapDispatchToProps)(Shipments));