import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import { Link } from 'react-router-dom';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import StepProgress from '../../layouts/partials/StepProgress';
import uuid from 'uuid';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import BookingHelper from './BookingHelper';

var itemsPerPage = 6;
var itemsShowing = 0;
var allItems = 0;
class FlightPricingPricelist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: "",
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                checkbox_term: false,
                PriceQuotation: { pl: [], routes: [] },
                PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'validate': false,
                    'current': true,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            totalCostWeight: 0,
            totalPrice: 0
        };

        this.handleChangePricelist = this.handleChangePricelist.bind(this);
        this.handleChangeFlight = this.handleChangeFlight.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickStep = this.handleClickStep.bind(this);
        this.handleClickRegularBooking = this.handleClickRegularBooking.bind(this);
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    checkPrice = () => {
        var { ebooking } = this.state;
        var all_price_zero = true;
        ebooking.PriceQuotation.routes.map((op, i) => {
            var prices = BookingHelper.getPrices(op);
            if (prices[0] > 0 || prices[1] > 0) {
                all_price_zero = false;
            }
        })
        return all_price_zero;
    }

    handleClickRegularBooking = () => {
        var { history } = this.props;
        var { ebooking } = this.state;
        if (ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE") {
            if (this.checkPrice()) {
                this.setPriceQuotation();
            } else {
                ebooking.PriceQuotation.pl = [];
                this.props.ebookingActions.ebookingChangeData({ fieldName: "PriceQuotation", fieldValue: ebooking.PriceQuotation }).then(responseData => { });
                this.setState({ ebooking });
                history.push('/ebooking/flight-pricing-default');
            }
        } else if (ebooking.PriceQuotation && ebooking.PriceQuotation.type == "PL") {
            this.setPriceQuotation();
        }
    }

    setPriceQuotation = () => {

        const { history } = this.props;
        var { ebooking, errorText } = this.state;
        errorText = "";
        this.setState({ errorText });

        var sendEbooking = ebooking.passedData;
        sendEbooking.MustRide = "A";
        // history.push('/ebooking/participants');
        this.props.loadingActions.loadingChangeData(true);
        var jwtToken = this.props.auth.jwtToken;
        this.props.ebookingActions.submitStepOne(sendEbooking, jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                ebooking.PricelistChoosed.selectedPL = -1;
                ebooking.PricelistChoosed.selectedPLData = {};
                ebooking.PricelistChoosed.selectedFlight = "";
                ebooking.PricelistChoosed.selectedFlightData = {};
                ebooking.PriceQuotation = response.data;
                ebooking.passedData = response.passedData;

                if (ebooking.populateImA) {
                    ebooking.receivingCompanyName = "";
                    ebooking.receivingIATA1 = "";
                    ebooking.receivingIATA2 = "";
                    ebooking.shippingCompanyName = "";
                    ebooking.shippingIATA1 = "";
                    ebooking.shippingIATA2 = "";
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingCompanyName", fieldValue: ebooking.receivingCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingIATA1", fieldValue: ebooking.receivingIATA1 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingIATA2", fieldValue: ebooking.receivingIATA2 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingCompanyName", fieldValue: ebooking.shippingCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingIATA1", fieldValue: ebooking.shippingIATA1 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingIATA2", fieldValue: ebooking.shippingIATA2 }).then(responseData => { });
                }
                if (ebooking.populateConsignor) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consignorCompanyName = ebooking.duplicateFields.CNORNAME ? ebooking.duplicateFields.CNORNAME : "";
                        ebooking.consignorAddress = ebooking.duplicateFields.CNORADDRESS ? ebooking.duplicateFields.CNORADDRESS : "";
                        ebooking.consignorCity = ebooking.duplicateFields.CNORCITY ? ebooking.duplicateFields.CNORCITY : "";
                        ebooking.consignorCountry = ebooking.duplicateFields.CNORCOUNTRY ? ebooking.duplicateFields.CNORCOUNTRY : "";
                        ebooking.consignorPostalCode = ebooking.duplicateFields.CNORPOSTALCODE ? ebooking.duplicateFields.CNORPOSTALCODE : "";
                    } else {
                        ebooking.consignorCompanyName = "";
                        ebooking.consignorAddress = "";
                        ebooking.consignorCity = "";
                        ebooking.consignorCountry = "";
                        ebooking.consignorPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCompanyName", fieldValue: ebooking.consignorCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorAddress", fieldValue: ebooking.consignorAddress }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCity", fieldValue: ebooking.consignorCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCountry", fieldValue: ebooking.consignorCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorPostalCode", fieldValue: ebooking.consignorPostalCode }).then(responseData => { });
                }
                if (ebooking.populateConsignee) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consigneeAddress = ebooking.duplicateFields.CNEEADDRESS ? ebooking.duplicateFields.CNEEADDRESS : "";
                        ebooking.consigneeCity = ebooking.duplicateFields.CNEECITY ? ebooking.duplicateFields.CNEECITY : "";
                        ebooking.consigneeCompanyName = ebooking.duplicateFields.CNEENAME ? ebooking.duplicateFields.CNEENAME : "";
                        ebooking.consigneeCountry = ebooking.duplicateFields.CNEECOUNTRY ? ebooking.duplicateFields.CNEECOUNTRY : "";
                        ebooking.consigneePostalCode = ebooking.duplicateFields.CNEEPOSTALCODE ? ebooking.duplicateFields.CNEEPOSTALCODE : "";
                    } else {
                        ebooking.consigneeCompanyName = "";
                        ebooking.consigneeAddress = "";
                        ebooking.consigneeCity = "";
                        ebooking.consigneeCountry = "";
                        ebooking.consigneePostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCompanyName", fieldValue: ebooking.consigneeCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeAddress", fieldValue: ebooking.consigneeAddress }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCity", fieldValue: ebooking.consigneeCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCountry", fieldValue: ebooking.consigneeCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneePostalCode", fieldValue: ebooking.consigneePostalCode }).then(responseData => { });
                }

                if (ebooking.populateDelivery) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.deliverCity = ebooking.duplicateFields.DELCITY ? ebooking.duplicateFields.DELCITY : "";
                        ebooking.deliverCountry = ebooking.duplicateFields.DELCOUNTRY ? ebooking.duplicateFields.DELCOUNTRY : "";
                        ebooking.deliverPostalCode = ebooking.duplicateFields.DELPOSTALCODE ? ebooking.duplicateFields.DELPOSTALCODE : "";
                    } else {
                        ebooking.deliverCountry = "";
                        ebooking.deliverCity = "";
                        ebooking.deliverPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCountry", fieldValue: ebooking.deliverCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCity", fieldValue: ebooking.deliverCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverPostalCode", fieldValue: ebooking.deliverPostalCode }).then(responseData => { });
                }

                if (ebooking.populatePickup) {
                    if (ebooking.duplicate) {
                        ebooking.pickupCity = ebooking.duplicateFields.PICKCITY ? ebooking.duplicateFields.PICKCITY : "";
                        ebooking.pickupCountry = ebooking.duplicateFields.PICKCOUNTRY ? ebooking.duplicateFields.PICKCOUNTRY : "";
                        ebooking.pickupPostalCode = ebooking.duplicateFields.PICKPOSTALCODE ? ebooking.duplicateFields.PICKPOSTALCODE : "";
                    } else {
                        ebooking.pickupCountry = "";
                        ebooking.pickupCity = "";
                        ebooking.pickupPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCity", fieldValue: ebooking.pickupCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupPostalCode", fieldValue: ebooking.pickupPostalCode }).then(responseData => { });
                }
                ebooking.populateImA = false;

                ebooking.populateConsignor = ebooking.consignorCompanyName ? true : false;
                ebooking.populateConsignee = ebooking.consigneeCompanyName ? true : false;
                ebooking.populateDelivery = ebooking.deliverCountry ? true : false;
                ebooking.populatePickup = ebooking.pickupCountry ? true : false;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateImA", fieldValue: ebooking.pickupCopopulateImAuntry }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignor", fieldValue: ebooking.populateConsignor }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignee", fieldValue: ebooking.populateConsignee }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateDelivery", fieldValue: ebooking.populateDelivery }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populatePickup", fieldValue: ebooking.populatePickup }).then(responseData => { });

                ebooking.page_df = 1;
                ebooking.page_pl = 1;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "page_df", fieldValue: ebooking.page_df }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "page_pl", fieldValue: ebooking.page_pl }).then(responseData => { });
                ebooking.clearConsignee = false;
                ebooking.clearConsignor = false;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "clearConsignee", fieldValue: ebooking.clearConsignee }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "clearConsignor", fieldValue: ebooking.clearConsignor }).then(responseData => { });

                this.setState({ ebooking });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "PricelistChoosed", fieldValue: ebooking.PricelistChoosed }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "PriceQuotation", fieldValue: response.data }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "passedData", fieldValue: ebooking.passedData }).then(responseData => { });
                switch (response.data.type) {
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;

                    case "WHITE":
                        if (ebooking.PriceQuotation.pl.length > 0) {
                            history.push('/ebooking/flight-pricing-pricelist');
                        } else {
                            history.push('/ebooking/flight-pricing-default');
                        }
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;

                    default:
                        history.push('/ebooking/flight-pricing-ad-hoc');
                        break;
                }
            } else {
                errorText = "Quote process failed. Please try again. Thank you and sorry for the inconvenience.";
                this.setState({ errorText });
                window.scrollToTop();
            }
        });
    }

    handleChangePricelist = (priceID, priceData) => {
        var { ebooking } = this.state;
        ebooking.PricelistChoosed.selectedPL = priceID;
        ebooking.PricelistChoosed.selectedPLData = priceData;
        if (ebooking.PricelistChoosed.selectedFlight && ebooking.PricelistChoosed.selectedPLData[ebooking.PricelistChoosed.selectedFlightData.wdStd] != "Y") {
            ebooking.PricelistChoosed.selectedFlight = "";
            ebooking.PricelistChoosed.selectedFlightData = {};
        }
        // if (ebooking.populateConsignor) {
        // ebooking.consignorCompanyName = "";
        // ebooking.consignorAddress = "";
        // ebooking.consignorCity = "";
        // ebooking.consignorCountry = "";
        // ebooking.consignorPostalCode = "";
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCompanyName", fieldValue: ebooking.consignorCompanyName }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorAddress", fieldValue: ebooking.consignorAddress }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCity", fieldValue: ebooking.consignorCity }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCountry", fieldValue: ebooking.consignorCountry }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorPostalCode", fieldValue: ebooking.consignorPostalCode }).then(responseData => { });
        // }
        // if (ebooking.populateConsignee) {
        // ebooking.consigneeCompanyName = "";
        // ebooking.consigneeAddress = "";
        // ebooking.consigneeCity = "";
        // ebooking.consigneeCountry = "";
        // ebooking.consigneePostalCode = "";
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCompanyName", fieldValue: ebooking.consigneeCompanyName }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeAddress", fieldValue: ebooking.consigneeAddress }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCity", fieldValue: ebooking.consigneeCity }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCountry", fieldValue: ebooking.consigneeCountry }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneePostalCode", fieldValue: ebooking.consigneePostalCode }).then(responseData => { });
        // }
        // ebooking.populateConsignor = false;
        // ebooking.populateConsignee = false;
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignor", fieldValue: ebooking.populateConsignor }).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignee", fieldValue: ebooking.populateConsignee }).then(responseData => { });

        this.props.ebookingActions.ebookingChangeData({ fieldName: "PricelistChoosed", fieldValue: ebooking.PricelistChoosed }).then(responseData => { });

        ebooking.page_pl = 1;
        this.props.ebookingActions.ebookingChangeData({ fieldName: "page_pl", fieldValue: ebooking.page_pl }).then(responseData => { });
        this.setState({ ebooking });
    }

    handleChangeFlight = (flightData) => {
        var { ebooking } = this.state;
        var flights = flightData.flights;
        flights = flights.filter(f => f.leg != -2);
        flightData.flights = flights;
        ebooking.PricelistChoosed.selectedFlight = flightData.uuid;
        ebooking.PricelistChoosed.selectedFlightData = flightData;
        this.props.ebookingActions.ebookingChangeData({ fieldName: "PricelistChoosed", fieldValue: ebooking.PricelistChoosed }).then(responseData => { });
        this.setState({ ebooking });
    }

    componentDidMount() {
        //get redux data and set to state   
        var { ebooking, history, auth } = this.props;
        var { steps } = this.state;
        this.setState({ ebooking });
        if (!ebooking.step_1_ok || (auth.state != 'LOGGED_IN' && auth.state != 'NEW_PASSWORD_REQUIRED' && auth.state != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        }

        if (ebooking.PriceQuotation.pl.length == 0) {
            history.push('/ebooking/flight-pricing-default');
        }
        if (ebooking.PriceQuotation.type != "WHITE" && ebooking.PriceQuotation.type != "PL") {
            switch (ebooking.PriceQuotation.type) {
                case "PARCEL":
                    history.push('/ebooking/flight-pricing-parcel');
                    break;

                default:
                    history.push('/ebooking/shipment');
                    break;
            }
        }
        if (auth.IsGSA) {
            steps[1].link = 'ebooking/participants-gsa';
        }
        this.setState({ steps });
        this.sortFlight();
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    /* handler form's fields change value */
    handleChange(event) {
        var { ebooking } = this.props;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        ebooking[name] = value;
        this.setState({ ebooking });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        if (name == "sort_pl") {
            this.sortFlight();
        }
    }

    sortFlight() {
        var { ebooking } = this.props;
        var flights = ebooking.PriceQuotation.routes;
        switch (ebooking.sort_pl) {
            case "FromDate":
                flights = flights.sort((a, b) => { return a.stdSort.localeCompare(b.stdSort) });
                break;
            case "ToDate":
                flights = flights.sort((a, b) => { return a.staSort.localeCompare(b.staSort) });
                break;
            case "Duration":
                flights = flights.sort((a, b) => { return a.duration.localeCompare(b.duration) });
                break;
        }
        ebooking.PriceQuotation.routes = flights;
        this.setState({ ebooking });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;

        if (ebooking.PricelistChoosed.selectedFlight == "") {
            isError = true;
            errors.selectedFlight = "Please choose flight.";
        }
        if (ebooking.PricelistChoosed.selectedPL < 0) {
            isError = true;
            errors.selectedPL = "Please choose Pricelist.";
        }

        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: true }).then(responseData => { });
        return true;
    }


    /**
     * Handler click step
     */
    handleClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();

            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        var { ebooking, history, auth } = this.props;
        ebooking.populateConsignor = ebooking.consignorCompanyName ? true : false;
        ebooking.populateConsignee = ebooking.consigneeCompanyName ? true : false;
        ebooking.populateDelivery = ebooking.deliverCountry ? true : false;
        ebooking.populatePickup = ebooking.pickupCountry ? true : false;
        ebooking.step_2_ok = true;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        window.jumpToTop();
        if (auth.IsGSA) {
            history.push('/ebooking/participants-gsa');
        } else {
            history.push('/ebooking/participants');
        }        
    }

    buildFlightsLarge = (routes) => {
        var { ebooking } = this.props;
        var tableItems = [];
        routes.map((op, i) => {
            var via = BookingHelper.getVia(op.flights, op.toport);
            var dStd = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var wdStd = BookingHelper.getWeekday(dStd);
            var dSta = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var wdSta = BookingHelper.getWeekday(dSta);
            if (ebooking.PricelistChoosed.selectedPL < 0 || (ebooking.PricelistChoosed.selectedPL >= 0 && ebooking.PricelistChoosed.selectedPLData[op.wdStd] == "Y")) {
                tableItems.push(<tr onClick={this.handleChangeFlight.bind(this, op)} className={`${(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? "active" : "cursor-hand"}`} key={"pb-" + i}>
                    <td className="col-first"><input type="radio" name={`cb-route-lg-${i}`} onChange={() => { }} value={op.uuid} checked={(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? true : false} /> {op.fnumber}</td>
                    <td>{op.fromport}</td>
                    <td>{op.toport}</td>
                    <td>{(op.viaport != op.toport && op.viaport != op.fromport) ? op.viaport : ""}</td>
                    <td>{op.std} <br />{wdStd}</td>
                    <td>{op.sta}<br />{wdSta}</td>
                    <td>{op.duration}</td>
                </tr>);
            }
        })
        return tableItems;
    }
    buildFlightsSmall = (routes) => {
        var { ebooking } = this.props;
        var tableItems = [];
        routes.map((op, i) => {
            var via = BookingHelper.getVia(op.flights, op.toport);
            var dStd = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var wdStd = BookingHelper.getWeekday(dStd);
            var dSta = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var wdSta = BookingHelper.getWeekday(dSta);
            if (ebooking.PricelistChoosed.selectedPL < 0 || (ebooking.PricelistChoosed.selectedPL >= 0 && ebooking.PricelistChoosed.selectedPLData[op.wdStd] == "Y")) {
                tableItems.push(<tr onClick={this.handleChangeFlight.bind(this, op)} className={`${(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? "active" : "cursor-hand"}`} key={"pb-" + i}>
                    <td className="col-first"><input type="radio" name={`cb-route-sm-${i}`} onChange={() => { }} value={op.uuid} checked={(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? true : false} /> {op.fnumber}</td>
                    <td>{op.fromport}</td>
                    <td>{op.toport}</td>
                    <td>{(op.viaport != op.toport && op.viaport != op.fromport) ? op.viaport : ""}</td>
                    <td>{op.std} <br />{wdStd}</td>
                    <td>{op.sta}<br />{wdSta}</td>
                    <td>{op.duration}</td>
                </tr>);
            }
        })
        return tableItems;
    }

    handleShowMore = () => {
        var { ebooking } = this.state;
        ebooking.page_pl++;
        this.setState({ ebooking });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "page_pl", fieldValue: ebooking.page_pl }).then(responseData => { });
    }

    buildFlightItems = (routes) => {
        var { ebooking } = this.props;
        var { showMore } = this.state;
        const flightItems = [];

        allItems = 0;
        routes.map((op, i) => {
            var dStd = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var dTime = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var aTimeDiff = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var dTimeDiff = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            aTimeDiff.set({ hour: 0, minute: 0 });
            dTimeDiff.set({ hour: 0, minute: 0 });
            var prices = BookingHelper.getPrices(op);
            // var prices = BookingHelper.getPrices(op.flights);
            if (ebooking.PricelistChoosed.selectedPL >= 0 && ebooking.PricelistChoosed.selectedPLData[op.wdStd] == "Y") {
                allItems++;
            }
            if (flightItems.length < itemsPerPage * ebooking.page_pl && (ebooking.PricelistChoosed.selectedPL < 0 || (ebooking.PricelistChoosed.selectedPL >= 0 && ebooking.PricelistChoosed.selectedPLData[op.wdStd] == "Y"))) {
                flightItems.push(
                    <div className="col-md-6 col-lg-4" key={i}>
                        <div className={`flight-element flight-element-agreement mb-3 div_center ${(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? "selected" : ""}`}>
                            <div className="flight-element-content">
                                <div className="flight-name text-center">Flight {op.fnumber}</div>
                                <div className="flight-date text-center">{dStd.format("MMMM Do")}</div>
                                <div className="flight-duration mt-3 mb-3 text-center">
                                    <div className="row">
                                        <div className="col-4 text-right">{dTime.format("HH:mm")}</div>
                                        <div className="col-4 duration-line">
                                            <div className="line"></div>
                                        </div>
                                        <div className="col-4 text-left showLandingTime">
                                            {aTime.format("HH:mm")}
                                            <span className={`${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'd-none'}`}>+{aTimeDiff.diff(dTimeDiff, "days")}</span>
                                            <div className={`landingTime ${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'no-add-day'}`}>Lands: {aTime.format("MMMM Do, HH:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flight-price ${(ebooking.PricelistChoosed.selectedFlight == op.uuid) ? "price-orange" : "price-blue"}`} onClick={this.handleChangeFlight.bind(this, op)}>
                                    <div className="price-value">
                                        Select
                                    </div>
                                    <div className="clb"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                );
            }
        });
        itemsShowing = flightItems.length;
        return flightItems;
    }

    getPortDataBig = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.portname;
        } else {
            return "";
        }
    }
    getPortDataSmall = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.countryname;
        } else {
            return "";
        }
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        var authState = this.props.auth.state;
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, errorText }
            = this.state;
        return (

            <div className="ebooking">
                <StepProgress stepData={steps} progress={40} onClick={this.handClickStep} />
                <div className="container">
                    <h1 className="title mt-5">Step one: Getting a Quote</h1>
                </div>

                <form>
                    <div className="container block-form">
                        <h1 className="formTitle mb-2">Agreements</h1>
                        <div className="formDescription" style={{ "maxWidth": "700px" }}>
                            Your company has a special agreement with us which offers you special price when booking certain shipment.
                            If your shipment fits an agreement, please select it below.
                            Otherwise, you can always choose a standard booking process.
                        </div>
                        <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                        <div className={`form-group ${errorText != "" ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                {errorText}
                            </div>
                        </div>

                        <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                        </div>

                        <div className="flight-agreement-detail mt-5 div_center text-center">
                            <div className="pricelist">
                                <div className="pricelist-header">
                                    <div className="pricelist-col col1">Agreement</div>
                                    <div className="pricelist-col col2">Consignee</div>
                                    <div className="pricelist-col col3">Consignor</div>
                                    <div className="pricelist-col col4">Receiving</div>
                                    <div className="pricelist-col col5"></div>
                                </div>
                                <div className="pricelist-elements">
                                    {
                                        ebooking.PriceQuotation.pl.map((op, i) => {
                                            return (
                                                <div className="pricelist-element" key={"pbpl-" + i}>
                                                    <div className="pricelist-col col1"><span>{op.plnum}</span></div>
                                                    <div className="pricelist-col col2"><span>{op.cneedes}</span></div>
                                                    <div className="pricelist-col col3"><span>{op.cnordes}</span></div>
                                                    <div className="pricelist-col col4"><span>{op.recdes}</span></div>
                                                    <div className={`pricelist-col col5 ${(ebooking.PricelistChoosed.selectedPL == op.plnum) ? "selected" : ""}`}>
                                                        <div className="select-button" onClick={this.handleChangePricelist.bind(this, op.plnum, op)}>
                                                            Select
                                                        </div>
                                                    </div>
                                                    <div className="pricelist-col col6">
                                                        <div className={`select-button float-right ${(ebooking.PricelistChoosed.selectedPL == op.plnum) ? "selected" : ""}`} onClick={this.handleChangePricelist.bind(this, op.plnum, op)}>
                                                            Select
                                                        </div>
                                                        <div className="clb"></div>
                                                    </div>
                                                    <div className="clb"></div>
                                                </div>
                                            );
                                        })
                                    }
                                    {/* 
                                    <div className="pricelist-element">
                                        <div className="pricelist-col col1">Agreement</div>
                                        <div className="pricelist-col col2">Consignee</div>
                                        <div className="pricelist-col col3">Consignor</div>
                                        <div className="pricelist-col col4">Receiving</div>
                                        <div className="pricelist-col col5">
                                            <div className="select-button">
                                                Select
                                            </div>
                                        </div>
                                        <div className="pricelist-col col6">
                                            <div className="select-button float-right">
                                                Select
                                            </div>
                                            <div className="clb"></div>
                                        </div>
                                    </div> */}
                                </div>


                                <div className="text-right">
                                    <div className="btn-regular-booking-new">
                                        <span>Take me to Standard Booking</span> <div className="clbm"></div><a onClick={this.handleClickRegularBooking.bind(this)}>Go</a>
                                        <div className="clb"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`container block-form mt-5 ${ebooking.PricelistChoosed.selectedPL >= 0 ? '' : 'd-none'}`}>
                        <h1 className="formTitle mb-3">Choose a flight</h1>
                    </div>

                    <div className={`flight-shipment-detail mt-5 div_center text-center ${ebooking.PricelistChoosed.selectedFlightData.fromport ? '' : ''}`}>
                        <div className="row">
                            <div className="col-4 col-lg-5">
                                <div className="shipment-city">
                                    {ebooking.PriceQuotation.routes.length ? this.getPortDataBig(ebooking.PriceQuotation.routes[0].fromport) : ""}
                                </div>
                                <div className="shipment-address">
                                    {ebooking.PriceQuotation.routes.length ? this.getPortDataSmall(ebooking.PriceQuotation.routes[0].fromport) : ""}
                                </div>
                            </div>
                            <div className="col-4 col-lg-2 shipment-plain-icon"></div>
                            <div className="col-4 col-lg-5">
                                <div className="shipment-city">
                                    {ebooking.PriceQuotation.routes.length ? this.getPortDataBig(ebooking.PriceQuotation.routes[0].toport) : ""}
                                </div>
                                <div className="shipment-address">
                                    {ebooking.PriceQuotation.routes.length ? this.getPortDataSmall(ebooking.PriceQuotation.routes[0].toport) : ""}
                                </div>
                            </div>
                        </div>
                        <div className="shipment-quote-detail text-center mt-3">
                        </div>
                    </div>

                    <div className={`container list-flight mt-5 ${ebooking.PricelistChoosed.selectedPL >= 0 ? '' : 'd-none'}`}>
                        <div className="row">
                            <div className="col-12 text-center div_center">
                                <select name="sort_pl" id="sort_pl" value={ebooking.sort_pl} className="sort-select" onChange={this.handleChange}>
                                    <option value="FromDate">From Date</option>
                                    <option value="ToDate">To Date</option>
                                    <option value="Duration">Duration</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center div_center local-times">
                                All times are local times
                            </div>
                        </div>
                        <div className={`row mb-1 mt-5 ${errors.selectedFlight ? "" : "hide"}`}>
                            <div className="col-lg-12">
                                <div className="show-bottom-error bottom0">
                                    <InputError error={errors.selectedFlight} class="bg-red" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 flight-elements div_center mt-5">
                            <div className="row">
                                {
                                    this.buildFlightItems(ebooking.PriceQuotation.routes)
                                }
                            </div>
                        </div>

                        <div className={`col-12 text-center local-times mt-3 ${!itemsShowing ? "" : "d-none"}`}>
                            No flights for the selected agreement
                        </div>

                        <div className={`row mt-3 ${itemsShowing < allItems ? '' : 'd-none'}`}>
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-show-more-results" onClick={this.handleShowMore}>Show More Results</button>
                            </div>
                        </div>
                    </div>

                    <div className="container no_padding">
                        <div className={`row mt-3 mb-2 ${errors.checkbox_term_parcel ? "" : "hide"}`}>
                            <div className="col-lg-12">
                                <div className="show-bottom-error bottom0">
                                    <InputError error={errors.checkbox_term_parcel} class="bg-red" />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="footer-total div_center">
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="footer-buttons div_center">
                                    <div className="btn-process">
                                        <button type="button" onClick={this.handSubmit} disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} className="ml-3 btn btn-ebooking-orange">Continue to Booking</button>
                                    </div>
                                    <div className="btn-back-shipment">
                                        <Link className="btn btn-back" to={`${process.env.PUBLIC_URL}/ebooking/shipment`}>Back to Shipment Details</Link>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="form-group form-rows">
                        <div className="dv-form-responsive">
                            <InputError class="text-right" error={errors.selectedFlight} />
                            <InputError class="text-right" error={errors.selectedPL} />
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightPricingPricelist);