import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowSearchForm: false
        }
        this.showMobileMenu = this.showMobileMenu.bind(this);
    }

    showMobileMenu() {
        if (this.state.isShowMenu == true) {
            this.setState({ isShowMenu: false });
        } else {
            this.setState({ isShowMenu: true });
        }

        this.props.clickOnHeader(this.state.isShowMenu);
    };

    showSearchForm = () => {
        this.setState({ isShowSearchForm: true });
    };
    hideSearchForm = () => {
        this.setState({ isShowSearchForm: false });
    };
    render() {
        const { isShowMenu, isShowSearchForm } = this.state;
        return (
            <div>
                <div className={`navbar-collapse-mobile${isShowMenu ? ' open' : ''}`} >
                    <div onClick={this.showMobileMenu} className="close-menu">
                        <span>Close</span>
                    </div>
                    <ul className="navbar-nav-mobile text-uppercase ml-auto">
                        {/* <li className="nav-item">
                            <Link className="nav-link-insite js-scroll-trigger" onClick={this.showMobileMenu} to={`${process.env.PUBLIC_URL}/ebooking/step1`}>e-Booking</Link>
                            <div className="plus-button"></div>
                        </li> */}
                        <li className="nav-item">
                            <a className="open-submenu" href="#">e-Services</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/tracking/" target="_blank">Tracking</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/flights-schedule/" target="_blank">Flights Schedule</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/download/" target="_blank">Downloads</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="open-submenu" href="#">Products</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/cal-auto/" target="_blank">CAL Auto</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/pharmaceuticals/" target="_blank">Pharmaceuticals</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/live-animals/" target="_blank">Live Animals</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/perishables/" target="_blank">Perishables</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/oversize-overweight/" target="_blank">Oversize/Overweight</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/dangerous-goods/" target="_blank">Dangerous Goods</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/valuables/" target="_blank">Valuables</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/engines/" target="_blank">Engines</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/products/oil-gas/" target="_blank">Oil &amp; Gas</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="open-submenu" href="#">Services</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-air-freight/" target="_blank">Air Freight</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-ground-handling/" target="_blank">Ground Handling</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-road-feeder/" target="_blank">Road Feeder Services</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-charter/" target="_blank">Charter Services</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="open-submenu">Network</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/network/overview/" target="_blank">Overview</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/network/locations-offices-gsas/" target="_blank">Locations (Offices, GSAs)</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/network/liege-hub/" target="_blank">Liege Hub</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="open-submenu">About</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/about-the-cal-group/" target="_blank">About CAL</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/board-of-directors/" target="_blank">Board of Directors</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/news-events/" target="_blank">News &amp; Events</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/management/" target="_blank">Management</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/partners/" target="_blank">Partners</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="open-submenu">Contact</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item">
                                    <a href="#" className="nav-link-insite open-submenu-submenu has-sub js-scroll-trigger">Claims</a>
                                    <div className="plus-button-sub"></div>
                                    <ul className="sub-menu-lv2">
                                        <li className="menu-item"><Link onClick={this.showMobileMenu} className="nav-link-insite" to={`${process.env.PUBLIC_URL}/claim`}>New Claim</Link></li>
                                        <li className="menu-item"><Link onClick={this.showMobileMenu} className="nav-link-insite" to={`${process.env.PUBLIC_URL}/claim-update`}>Claim Update</Link></li>
                                    </ul>
                                </li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/contact/" target="_blank">Contact Us</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a href="#" className="open-submenu">Careers</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item">
                                    <a href="https://www.cal-cargo.com/careers/pilots/" target="_blank">Pilots</a>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
                <div className={`main-header${isShowMenu ? ' move-right' : ''}`}>
                    <div className="container header-menu">
                        <Link to={`${process.env.PUBLIC_URL}/`} className="main-logo"><img src="/images/0000332.png" /></Link>
                        <button onClick={this.showMobileMenu} className="navbar-toggler navbar-toggler-right" type="button">
                            <i className="fa fa-bars"></i>
                        </button>
                        <button onClick={this.showSearchForm} className="navbar-toggler navbar-toggler-right" type="button" >
                            <i className="fa fa-search"></i>
                        </button>
                        <div className={`search-box navbar-collapse collapse${isShowSearchForm ? ' show' : ''}`} id="searchResponsive">
                            <form className="navbar-form" role="search" >
                                <div className="input-group">
                                    <input type="text" className="search-form-control" placeholder="Search" name="q" />
                                    <div className="input-group-btn">
                                        <button className="btn-search" type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <div onClick={this.hideSearchForm} className="close-search-menu"></div>
                            </form>
                        </div>

                        <ul id="menu-main-menu" className="mega-menu">

                            <li className="menu-item menu-item-has-children">
                                <a className="nav-link js-scroll-trigger" href="#">e-Services</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/tracking/" target="_blank">Tracking</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/flights-schedule/" target="_blank">Flights Schedule</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/download/" target="_blank">Downloads</a></li>
                                </ul>
                            </li>
                            <li className="menu-item menu-item-has-children">
                                <a className="nav-link js-scroll-trigger" href="#">Products</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/cal-auto/" target="_blank">CAL Auto</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/pharmaceuticals/" target="_blank">Pharmaceuticals</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/live-animals/" target="_blank">Live Animals</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/perishables/" target="_blank">Perishables</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/oversize-overweight/" target="_blank">Oversize/Overweight</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/dangerous-goods/" target="_blank">Dangerous Goods</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/valuables/" target="_blank">Valuables</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/engines/" target="_blank">Engines</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/products/oil-gas/" target="_blank">Oil &amp; Gas</a></li>
                                </ul>
                            </li>

                            <li className="menu-item menu-item-has-children">
                                <a className="nav-link js-scroll-trigger" href="#">Services</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-air-freight/" target="_blank">Air Freight</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-ground-handling/" target="_blank">Ground Handling</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-road-feeder/" target="_blank">Road Feeder Services</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/services/services-charter/" target="_blank">Charter Services</a></li>
                                </ul>
                            </li>

                            <li className="menu-item menu-item-has-children">
                                <a href="#" className="nav-link">Network</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/network/overview/" target="_blank">Overview</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/network/locations-offices-gsas/" target="_blank">Locations (Offices, GSAs)</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/network/liege-hub/" target="_blank">Liege Hub</a></li>
                                </ul>
                            </li>

                            <li className="menu-item menu-item-has-children">
                                <a href="#" className="nav-link">About</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/about-the-cal-group/" target="_blank">About CAL</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/board-of-directors/" target="_blank">Board of Directors</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/news-events/" target="_blank">News &amp; Events</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/management/" target="_blank">Management</a></li>
                                    <li className="menu-item"><a href="https://www.cal-cargo.com/about-us/partners/" target="_blank">Partners</a></li>
                                </ul>
                            </li>


                            {/* <li id="menu-item-24" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-24">
                                <Link className="nav-link js-scroll-trigger" to={`${process.env.PUBLIC_URL}/ebooking/step1`}>e-Booking</Link>
                            </li> */}


                            <li className="menu-item menu-item-has-children">
                                <a href="#" className="nav-link">Contact</a>
                                <ul className="sub-menu">
                                    <li className="menu-item menu-item-has-children has-sub-menu">
                                        <Link className="nav-link js-scroll-trigger" to={`${process.env.PUBLIC_URL}/claim`}>Claims</Link>
                                        <ul className="sub-menu">
                                            <li className="menu-item"><Link className="nav-link" to={`${process.env.PUBLIC_URL}/claim`}>New Claim</Link></li>
                                            <li className="menu-item"><Link className="nav-link" to={`${process.env.PUBLIC_URL}/claim-update`}>Claim Update</Link></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                    {/* <Link className="nav-link js-scroll-trigger" to={`${process.env.PUBLIC_URL}/ebooking`}>E-Booking</Link> */}
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="https://www.cal-cargo.com/contact/" target="_blank">Contact Us</a>
                                    </li>
                                </ul>
                            </li>

                            <li className="menu-item menu-item-has-children">
                                <a href="#" className="nav-link">Careers</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item">
                                        <a href="https://www.cal-cargo.com/careers/pilots/" target="_blank">Pilots</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>


                        <div className="clb"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;
