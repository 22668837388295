import axios from 'axios';
import uuid from 'uuid';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
import BookingHelper from '../../components/ebooking/BookingHelper';

export function getManagerPage(data, idToken, saveRedux = true) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.DASHBOARD_CHANGE_STATE,
            payload: "DASHBOARD_GETTING_DATA"
        });
        var dataPass = {
            "functionName": 'getManagerPage',
            "dataPass": data
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        var dataReturn = {};
        dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        if (dataReturn.success == true) {
            if (saveRedux) {
                dataReturn.data.days = data.days;
                dispatch({
                    type: ActionTypes.DASHBOARD_CHANGE_DATA,
                    payload: dataReturn.data
                });
            }
        } else {
            dispatch({
                type: ActionTypes.DASHBOARD_CHANGE_DATA,
                payload: []
            });
        }
        return { success: true, data: dataReturn.data };
    }
}

export function getDetailsOrder(awb, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getManagerDetails',
            "dataPass": {
                "awbnum": awb
            }
        };
        var dataReturn = {};
        dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function resetDashboard() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.DASHBOARD_RESET,
            payload: []
        });
        return { success: true };
    }
}

export function changeOpenFilter(newStatus) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.DASHBOARD_CHANGE_FILTER_OPEN,
            payload: newStatus
        });
        return { success: true };
    }
}

export function updateFilter(newData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.DASHBOARD_CHANGE_FILTER_UPDATE,
            payload: newData
        });
        return { success: true };
    }
}