import React from 'react';

export default class EbookingInputError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { error } = this.props;
        if (error) {
            return (
                <span className={`error ${this.props.class}`}>{error}</span>
            )
        } else {
            return (null)
        }
    }
}