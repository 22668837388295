import * as ActionTypes from '../actionTypes';

const initialState = {
    data: {
        days: 30
    },
    openFilter: false,
    search: {
        awb: '',
        quote: '',
        from: '',
        cosignee: '',
        consignor: '',
        date: 30
    },
    state: "DASHBOARD_GETTING_DATA"
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.DASHBOARD_CHANGE_DATA: {
            var newState = { ...state };
            newState['data'] = action.payload;
            newState['state'] = "DASHBOARD_LOADED_DATA";
            return Object.assign({}, state, newState)
        }
        case ActionTypes.DASHBOARD_CHANGE_DATE: {
            var newState = { ...state };
            newState.data.days = action.payload;
            return Object.assign({}, state, newState)
        }
        case ActionTypes.DASHBOARD_CHANGE_STATE: {
            var newState = { ...state };
            newState['state'] = action.payload;
            return Object.assign({}, state, newState)
        }
        case ActionTypes.DASHBOARD_CHANGE_FILTER_OPEN: {
            var newState = { ...state };
            newState['openFilter'] = action.payload;
            return Object.assign({}, state, newState)
        }
        case ActionTypes.DASHBOARD_RESET: {
            var newState = { ...state };
            newState.data = {
                days: 30
            };
            newState.openFilter = false;
            newState.search = { awb: '', quote: '', from: '', cosignee: '', consignor: '', date: 30 };
            newState.state = 'DASHBOARD_GETTING_DATA';
            return Object.assign({}, state, newState)
        }
        case ActionTypes.DASHBOARD_CHANGE_FILTER_UPDATE: {
            var newState = { ...state };
            newState['search'] = action.payload;
            return Object.assign({}, state, newState)
        }
    }
    return state;
}