import React from 'react';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
/**
 * Validator
 */
import validator from 'validator';
import InputError from '../InputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';

/**
 * Uploaded file
 */
//import ClaimUploaded from './ClaimUploaded';
import SupplierUpload from './SupplierUpload';
import FileUploaded from './FileUploaded';

class SupplierUploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            isError: false,
            errorMessage: "",
            fileStartName: "A",
            uploading: [],
            uploaded: [],
            showAddButton: true,
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            maxFileSize: 9,
            maxUploadFiles: 15,
            isUploading: false,
            subFolder: ""
        };
        this.handleNewFile = this.handleNewFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.handFinish = this.handFinish.bind(this);
    }

    handleNewFile = (filedata) => {
        var { errors, uploaded, maxUploadFiles, acceptFiles, maxFileSize } = this.state;
        errors['upload_file'] = '';
        if (filedata.file) {
            if (uploaded.length < maxUploadFiles) {
                var name = filedata.file.name;
                var nameSplit = name.split('.');
                var filesize = filedata.file.size;
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();
                if ((acceptFiles.indexOf(ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                    uploaded.push({ file: filedata.file, filename: name, uploaded: false, uploadError: false, uploadProgress: 0, fileType: filedata.filedes, id: uuid() });
                } else {
                    errors['upload_file'] = "Please check your type and file size!";
                }
            } else {
                errors['upload_file'] = "You can upload maximum " + maxUploadFiles + " files.";
            }
        }

        this.setState({ errors, uploaded });
    }

    handleDeleteFile(name, i) {
        var { uploaded } = this.state;
        uploaded.splice(i, 1);
        this.setState({ uploaded });
    }

    componentDidMount() {
        var { fileStartName, showAddButton } = this.state;
        var { supplier, history } = this.props;
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var ord = parsedParams.ord;
        var awb = parsedParams.awb;

        if (!supplier.api_checked || !supplier.Dimensions.length) {
            history.push('/supplier-update/commodity/?awb=' + awb + '&ord=' + ord);
            return;
        } else if (!supplier.pickupNameOfContact) {
            history.push('/supplier-update/pickup/?awb=' + awb + '&ord=' + ord);
            return;
        } else if (!supplier.countryOfOrigine) {
            history.push('/supplier-update/special-instruction/?awb=' + awb + '&ord=' + ord);
            return;
        }
        if (awb.length > 11) {
            awb = awb.substr(0, 11);
        }
        showAddButton = true;
        fileStartName = "WEBSUP" + awb + "-";
        this.setState({ fileStartName, showAddButton });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    // Start upload files
    changeProgress = (loaded, total, fileIndex) => {
        var { uploaded } = this.state;
        var loadedPer = 0;
        if (total > 0) {
            loadedPer = parseInt((loaded / total) * 100);
        }
        uploaded[fileIndex].uploadProgress = loadedPer;
        this.setState({ uploaded });
    }

    changeFileName = (fileName, fileIndex) => {
        var { uploaded } = this.state;
        uploaded[fileIndex].filename = fileName;
        this.setState({ uploaded });
    }

    startUploadFiles = async (listFiles) => {
        var { uploaded } = this.state;
        var t = this;
        await this.asyncForEach(listFiles, async (i) => {
            if (!listFiles[i].uploaded) {
                var uploadStatus = await t.uploadFile(listFiles[i], i);
                if (uploadStatus.success) {
                    uploaded[uploadStatus.fileIndex].uploaded = true;
                    uploaded[uploadStatus.fileIndex].uploadError = false;
                    uploaded[uploadStatus.fileIndex].uploadProgress = 0;
                } else {
                    uploaded[uploadStatus.fileIndex].uploaded = false;
                    uploaded[uploadStatus.fileIndex].uploadError = true;
                    uploaded[uploadStatus.fileIndex].uploadProgress = 0;
                }
                t.setState({ uploaded });
            }
        });
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(index, array[index], array)
        }
    }

    uploadFile = (fileData, i) => {
        var { fileStartName, subFolder, acceptFiles, maxFileSize, uploaded } = this.state;
        var reader = new FileReader();
        var eObj = this;
        return new Promise((resolve, reject) => {
            reader.addEventListener('loadend', function (e) {
                var name = fileData.file.name;
                var nameSplit = name.split('.');
                var filesize = fileData.file.size;
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();

                var fileName = name.replace(/\.[^/.]+$/, "");
                fileName = fileName.replace(/[^\w]/gi, '-');
                // var fileNewName = fileStartName + fileData.fileType + '.' + ext;    
                if (fileName.length > 15) {
                    fileName = fileName.substr(0, 15);
                }
                var fileNewName = fileStartName + fileName + '.' + ext;
                if ((acceptFiles.indexOf(ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                    if (subFolder != "") {
                        fileNewName = subFolder + '/' + fileNewName;
                    } else {
                        fileNewName = fileNewName;
                    }
                    fetch(AppConfig.apiUpload + "api/service/upload-booking-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: fileData.file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            eObj.changeFileName(json.body.fileName, i);
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: fileData.file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total, i);
                                }
                            })
                        })
                        .then(function () {
                            resolve({ success: true, fileIndex: i });
                        }).catch(e => {
                            resolve({ success: false, fileIndex: i });
                        });
                } else {
                    resolve({ success: false, fileIndex: i, desc: "Please check your type and file size!" });
                }
            });
            reader.readAsArrayBuffer(fileData.file);
        });
    }

    // End upload files

    addUploadingFile = () => {
        var { uploading } = this.state;
        uploading.push(uuid());
        this.setState({ uploading });
    }

    handFinish = async (doUploadFile = true) => {
        // e.stopPropagation();
        // e.preventDefault();

        if (doUploadFile) {
            this.setState({ isUploading: true });
            await this.startUploadFiles(this.state.uploaded);
            this.setState({ isUploading: false });
        }

        var checkError = this.state.uploaded.find(f => f.uploadError);

        if (!this.state.uploaded.length || checkError == undefined) {
            window.jumpToTop();
            const { supplier, history } = this.props;

            var HandlingInstrunctionCodes = [];
            supplier.instructions.forEach(ins => {
                HandlingInstrunctionCodes.push(ins.value);
            });

            var awbSubmit = supplier.awb;
            if (awbSubmit.length > 11) {
                awbSubmit = awbSubmit.substr(0, 11);
            }
            var files = [];
            // console.log(supplier.MRN);
            supplier.MRN.map(f => {
                files.push(f.fileName);
            });
            // console.log(this.state.uploaded);
            if (doUploadFile) {
                this.state.uploaded.map(fa => {
                    files.push(fa.filename);
                });
            }

            // console.log(files);
            var hsCode = supplier.hsCode;
            if (supplier.hsCodeField) {
                hsCode.push(supplier.hsCodeField);
            }
            // var hsCodeFields = [];
            // hsCodeFields.push(supplier.hsCodeField);

            var submitData = {
                "awb": awbSubmit,
                "weight": supplier.units_total_chargable_weight,
                "DGR": supplier.shipment_dangerous_goods ? supplier.shipment_dangerous_goods_type : null,
                "Overlap": (supplier.overlap_shipment == "Overlap" || supplier.overlap_shipment_check) ? "Y" : null,
                "Litium": supplier.shipment_lithium_batteries ? "Y" : null,
                "Unstackable": supplier.shipment_unstackable ? "Y" : null,
                "un": supplier.un,
                "Hscode": hsCode,
                "files": files,
                "Eroi": supplier.EROI,
                "FromCountry": supplier.countryOfOrigine,
                "Pickupref": supplier.pickupReferenceNumber,
                "Pickcompany": supplier.pickupCompanyName,
                "Pickcountry": supplier.pickupCountry,
                "Pickcity": supplier.pickupCity,
                "Pickzip": supplier.pickupPostalCode,
                "Pickaddress": supplier.pickupAddress,
                "Contact": supplier.pickupNameOfContact,
                "Email": supplier.pickupEmail,
                "Phonenum": supplier.pickupPhoneNumber,
                "Picktime": supplier.pickup_dropoff_date ? supplier.pickup_dropoff_date.format("DD/MM/YY HH:mm") : "",
                "Pickopenhours": supplier.pickupOpeningHours,
                "Pickcomment": supplier.pickupComments,
                "class": supplier.class,
                "HandlingInstrunctionCodes": HandlingInstrunctionCodes,
                "Dimensions": supplier.Dimensions,
                "HandleComment": supplier.additional_information
            }

            this.props.loadingActions.loadingChangeData(true);
            this.props.supplierActions.supplierUpdate(submitData).then(res => {
                this.props.loadingActions.loadingChangeData(false);
                if (res.success && res.data.Success) {
                    history.push('/supplier-update/success');
                    // history.push('/supplier-update/attach-files/?awb=' + supplier.awb + '&ord=' + supplier.ord);
                } else {
                    this.setState({ errorMessage: "Update order unsuccess. Plz try again!" });
                    window.scrollToTop();
                    return false;
                }
            });
            // history.push('/supplier-update/success');
        } else {
            this.setState({ isUploading: false });
        }

    }

    handCancel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.jumpToTop();
        const { history } = this.props;
        history.push('/supplier-update/success');
        return;
    }

    render() {
        var { isError, errors, errorMessage, isUploading, uploaded, maxUploadFiles, showAddButton } = this.state;
        var { supplier } = this.props;
        return (
            <div className="page-main">
                <div className="div-head fixed text-right">
                    <span className="supplier-details-awb">Details for AWB {supplier.awb}</span>
                </div>
                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>

                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className={`alert alert-danger text-red ${(!isError || this.props.loading.isLoading) ? 'd-none' : ''}`} role="alert">
                                                {errorMessage}. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                                            </div>
                                            <div className="big-text">Attachments </div>
                                            <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "900px" }}>
                                                This is where you attach all relevant files to your order.
                                            </div>
                                            <div className="content-buttons div_center mt-5 mb-3">
                                                <SupplierUpload onSuccess={this.handleNewFile} dis={(isError || isUploading || uploaded.length >= maxUploadFiles || !showAddButton) ? true : false} />
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 form-rows div_center" style={{ "maxWidth": "400px" }}>
                                                    {
                                                        uploaded.map((file, i) => {
                                                            return <FileUploaded key={i} isUploading={isUploading} fileInfo={file} fileIndex={i} onDeleteFile={this.handleDeleteFile} />;
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="div_center text-center">
                                                <InputError error={errors.upload_file} />
                                            </div>
                                            <div className="content-buttons dv-button-footer div_center text-center mt-5">
                                                <button type="button" className={`btn btn-orange ${isUploading ? "disabled" : ""}`} disabled={(isUploading || isError || this.props.loading.isLoading) ? true : false} onClick={this.handFinish.bind(this, true)}>{isUploading ? "Checking files and uploading..." : "Upload and Finish"}</button>
                                                <button type="button" className="btn btn-lg btn-link text-white" disabled={(isUploading || this.props.loading.isLoading) ? true : false} onClick={this.handFinish.bind(this, false)}>I will send later</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierUploadFile);