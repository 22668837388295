import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import uuid from 'uuid';
import axios from 'axios';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

/**
 * Uploaded file
 */
import ClaimUploaded from './ClaimUploaded';
import ClaimUpload from './ClaimUpload';


class ClaimEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            isEdit: false,
            errors: {},
            isShowDocument: false,
            errorText: "",
            submitted: 1,      
            claim: {
                uploadFolder: '',
                uploading: [],
                ClaimNum: this.props.match.params.ClaimNum,
                ClaimFiles: [],
                upload_file_type: [],
                filestypes: [],
                email: ''
            },
            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'Claim Update'
                },
            ],
            steps: [
                {
                    'link': 'new-claim',
                    'active': false,
                    'validate': false,
                    'name': 'Basic info'
                },
                {
                    'link': 'claim-detail',
                    'active': false,
                    'validate': false,
                    'name': 'Claim details'
                },
                {
                    'link': 'claim-submit',
                    'active': true,
                    'validate': true,
                    'name': 'Submit documents'
                },
            ],
            styles: {
                'borderWidth': '2px',
                'background': '#f2f3f5',
                'borderColor': '#dbdcde',
                'borderStyle': 'dashed',
                'width': '645px',
                'height': '215px',
                'display': 'table-cell',
                'textAlign': 'center',
                'verticalAlign': 'middle',
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handSubmit = this.handSubmit.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.handleNewFile = this.handleNewFile.bind(this);
        this.handleDeleteUpload = this.handleDeleteUpload.bind(this);
        this.clickAddBtn = this.clickAddBtn.bind(this);
    }

    showDocument = () => {
        this.setState({ isShowDocument: true });
    }

    hideDocument = () => {
        this.setState({ isShowDocument: false });
    }

    gotoSuccess = () => {
        this.props.history.push('/claim-success')
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var { claim } = this.props;

        if (!claim.uploadFolder) {
            claim.uploadFolder = uuid();
            this.props.claimActions.claimChangeData({ fieldName: 'uploadFolder', fieldValue: claim.uploadFolder }).then(responseData => { });
        }

        if (!claim.ClaimFiles.length && !claim.uploading.length) {
            claim.uploading.push(uuid());
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
        }
        this.setState({ claim });
        const { history } = this.props;

        //check previuos step ok
        if (claim.ClaimNum == '' || this.props.match.params.ClaimNum != this.props.claim.ClaimNum) {
            // if (claim.ClaimNum == '') {
            history.push('/claim-update');
        }

        document.title = "Claims | Challenge Group";
    }

    /* Handle subbmit form */
    handleChange(event) {
        var { claim, errors } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        if (type === 'checkbox') {
            const check_checked = target.checked;
            value = check_checked ? 1 : 0;
            claim[name] = value;
            this.props.claimActions.claimChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        } else {

            claim[name] = value;
            this.props.claimActions.claimChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });

        }
        this.setState({ claim });
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { claim } = this.state;
        
        if (claim.ClaimFiles.length <= 0) {
            errors['upload_file'] = MESSAGES.ERROR_UPLOAD;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            this.props.claimActions.claimChangeData({ fieldName: "step_3_ok", fieldValue: false }).then(responseData => { });
            return false;
        }


        this.props.claimActions.claimChangeData({ fieldName: "step_3_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    handleDeleteFile(name, i) {
        var { claim } = this.state;
        claim.ClaimFiles.splice(i, 1);
        if (!claim.ClaimFiles.length) {
            claim.uploading.push(uuid());
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
        }
        this.setState({ claim });
        this.props.claimActions.claimChangeData({ fieldName: 'ClaimFiles', fieldValue: claim.ClaimFiles }).then(responseData => { });
    }

    handleDeleteUpload(id) {
        var { claim } = this.state;
        var result = claim.uploading.filter(w => w != id);
        claim.uploading = result;
        this.setState({ claim });
        this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
    }

    handleNewFile = (filedata, id) => {
        var { errors, claim } = this.state;
        errors['upload_file'] = '';
        claim.ClaimFiles.push(filedata);
        this.setState({ claim, errors });
        this.props.claimActions.claimChangeData({ fieldName: 'ClaimFiles', fieldValue: claim.ClaimFiles }).then(responseData => { });
        this.handleDeleteUpload(id);
    }

    handSubmit() {
        const { history } = this.props;
        let { errorText } = this.state;
        const { claim } = this.state;
        //check previuos step ok
        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        claim.ClaimAspect = [];
        claim.ClaimAmount = [];


        var submitClaim = {};
        submitClaim.name = claim.name;
        submitClaim.phonenum = claim.phonenum;
        submitClaim.awbnum = claim.awbnum;
        submitClaim.email = claim.email;
        submitClaim.claimertype = claim.claimertype;
        submitClaim.claimtype = claim.claimtype;
        submitClaim.insurance = claim.insurance;
        submitClaim.Insured = claim.Insured;
        submitClaim.NG = claim.NG;
        submitClaim.text = claim.text;
        submitClaim.ClaimAspect = claim.ClaimAspect;
        submitClaim.ClaimAmount = claim.ClaimAmount;
        submitClaim.ClaimFiles = claim.ClaimFiles;
        submitClaim.ClaimNum = claim.ClaimNum;
        submitClaim.uploadFolder = claim.uploadFolder;

        this.props.loadingActions.loadingChangeData(true);
        this.props.claimActions.updateClaim(submitClaim).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                this.props.claimActions.claimChangeData({ fieldName: "ClaimNumSuccess", fieldValue: claim.ClaimNum }).then(responseData => { });
                history.push('/claim-edit-success');
            } else {
                this.resendData(submitClaim);
            }
        });

    }

    resendData = (submitClaim) =>{        
        const { history } = this.props;
        let { errorText } = this.state;
        errorText = "";
        var {submitted, claim} = this.state;
        submitted++;
        this.setState({submitted: submitted});
        this.props.claimActions.updateClaim(submitClaim).then(response => {   
            if (response.success && response.data.Success) {
                this.props.loadingActions.loadingChangeData(false);
                this.props.claimActions.claimChangeData({ fieldName: "ClaimNumSuccess", fieldValue: claim.ClaimNum }).then(responseData => { });
                history.push('/claim-edit-success');
            } else {       
                if(submitted < 3){
                    this.resendData(submitClaim);
                }  else{
                    this.props.loadingActions.loadingChangeData(false);
                    errorText = MESSAGES.ERROR_SAVING;
                    this.setState({ errorText });
                }             
                
            }
        });
    }

    clickAddBtn(event) {
        var { claim } = this.state;
        if ((claim.ClaimFiles.length) < 15 && !claim.uploading.length) {
            claim.uploading.push(uuid());
            this.setState({ claim });
            this.props.claimActions.claimChangeData({ fieldName: 'uploading', fieldValue: claim.uploading }).then(responseData => { });
        }
    }

    render() {
        var { claim, breadcrumbs, steps, isError, errors, errorMessage, errorText } = this.state;
        return (
            <div className="container">
                <Breadcrumb breadcrumbData={breadcrumbs} />

                <h1 className="title">Claim Update</h1>

                <div className="block-form claim-form">
                    <form>
                        <h2 className="detail">Submit Documents</h2>
                        {(errorText != "") ? (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {errorText}
                                </div>
                            </div>
                        ) : (null)}
                        <a className="claim-link hide" href='javascript:void(0)' onClick={this.showDocument}>Document Submitting Guidlines </a>
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            Please upload each document as a separate file. Accepted file types: .doc, .pdf, .png, .jpeg. Max file size: 9MB.<br />
                            Select the relevant document type from the dropdown menu. You can upload maximum 15 files. You can add additional documents later, using the 'Claim Update' page.
                            <button type="button" className="close hide" onClick={this.hideDocument}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="row justify-content-between">
                            <div className="col-sm-6 col-md-7 form-rows">
                                {
                                    claim.ClaimFiles.map((file, i) => {
                                        return <ClaimUploaded key={i} fileInfo={file} fileIndex={i} onDeleteFile={this.handleDeleteFile} />;

                                    })
                                }

                                {
                                    claim.uploading.map((id, i) => {
                                        return (
                                            <ClaimUpload key={i} ClaimNum={claim.ClaimNum} fileIndex={id} subFolder={claim.uploadFolder} onSuccess={this.handleNewFile} />
                                        );
                                    })
                                }
                                <div>
                                    <a className="claim-upload-add-btn" onClick={this.clickAddBtn}></a>
                                </div>

                                <InputError error={errors.upload_file} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <div className="mb-3">
                                Please make sure you receive a confirmation email regarding your claim. If you did not receive a confirmation email, you can contact us using <a href="https://www.cal-cargo.com/contact/" target="_blank">this link</a>.
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn btn-continues" onClick={this.handSubmit}>Submit Files</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        claimActions: bindActionCreators(claimActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimEdit);