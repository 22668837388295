import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// Layout
import MainLayout from './layouts/MainLayout';
import ClaimLayout from './layouts/ClaimLayout';
import EmptyLayout from './layouts/EmptyLayout';
import QuotesLayout from './layouts/QuotesLayout';
import EbookingLayout from './layouts/EbookingLayout';
import DashBoardLayout from './layouts/DashBoardLayout';
import EbookingBlueLayout from './layouts/EbookingBlueLayout';
import NegotiationLayout from './layouts/NegotiationLayout';
import EbookingNoHeaderLayout from './layouts/EbookingNoHeaderLayout';
import SupplierLayout from './layouts/SupplierLayout';

// Route
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

//components
import HomeIndex from './components/home/HomeIndex';
import ClaimIndex from './components/claim/ClaimIndex';
import ClaimBasic from './components/claim/ClaimBasic';
import ClaimDetail from './components/claim/ClaimDetail';
import ClaimSubmit from './components/claim/ClaimSubmit';
import ClaimSuccess from './components/claim/ClaimSuccess';
import ClaimEditSuccess from './components/claim/ClaimEditSuccess';
import ClaimSearch from './components/claim/ClaimSearch';
import ClaimEdit from './components/claim/ClaimEdit';

import NewEbooking from './components/ebooking/NewEbooking';
import FlightPricingDefault from './components/ebooking/FlightPricingDefault';
import FlightPricingPricelist from './components/ebooking/FlightPricingPricelist';
import FlightPricingParcel from './components/ebooking/FlightPricingParcel';
import FlightPricingAdhoc from './components/ebooking/FlightPricingAdhoc';
import Participants from './components/ebooking/Participants';
import ParticipantsGSA from './components/ebooking/ParticipantsGSA';
import GSAMessage from './components/ebooking/GSAMessage';
import ReviewOrder from './components/ebooking/ReviewOrder';
import Payment from './components/ebooking/Payment';
import PaymentCASS from './components/ebooking/PaymentCASS';
import PaymentCredit from './components/ebooking/PaymentCredit';
import ConfirmAgreement from './components/ebooking/ConfirmAgreement';
import ConfirmCass from './components/ebooking/ConfirmCass';
import PaymentAWB from './components/ebooking/PaymentAWB';
import PaymentAWBSuccess from './components/ebooking/PaymentAWBSuccess';
import ScAWBPending from './components/ebooking/ScAWBPending';
import ScAWBSuccess from './components/ebooking/ScAWBSuccess';
import Success from './components/ebooking/Success';
import ScHanlde from './components/ebooking/ScHanlde';
import ScSuccess from './components/ebooking/ScSuccess';
import ScSuccessFinal from './components/ebooking/ScSuccessFinal';
import ScFailure from './components/ebooking/ScFailure';
import ScSubmitFailure from './components/ebooking/ScSubmitFailure';
import ScPending from './components/ebooking/ScPending';
import UploadFile from './components/ebooking/UploadFile';

import Shipments from './components/myaccount/Shipments';
import Quotes from './components/myaccount/Quotes';
import Info from './components/myaccount/Info';
import Dashboard from './components/myaccount/Dashboard';
import Reports from './components/myaccount/Reports';
import LoginPage from './components/LoginPage';
import OrderDetail from './components/OrderDetail';

import Commodity from './components/supplider/Commodity';
import Pickup from './components/supplider/Pickup';
import SpecialIntructionOne from './components/supplider/SpecialIntructionOne';
import SupplierSuccess from './components/supplider/SupplierSuccess';
import SupplierUploadFile from './components/supplider/SupplierUploadFile';

//Quotes
import AcceptQuotes from './components/quotes/AcceptQuotes';
import AcceptQuotesStep2 from './components/quotes/AcceptQuotesStep2';
import NegotiationQuotes from './components/quotes/NegotiationQuotes';
import QuotesReview from './components/quotes/QuotesReview';
import QuotesReviewGuest from './components/quotes/QuotesReviewGuest';
import QuoteChooseFlights from './components/quotes/QuoteChooseFlights';
import QuoteParticipants from './components/quotes/QuoteParticipants';
import QuoteParticipantsGSA from './components/quotes/QuoteParticipantsGSA';
import QuotePayment from './components/quotes/QuotePayment';
import TempUser from './components/quotes/TempUser';

import TestApi from './components/TestApi';
//error components
import Page404 from './components/errors/Page404';

//Redux
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from './ninja-redux/actions/authActions';
import * as helpActions from './ninja-redux/actions/helpActions';


class AppForm extends Component {

	componentDidMount() {
		//Check user loged in	
		this.props.authActions.authGetSession();	
		this.props.helpActions.helpGetCountries();	
		this.props.helpActions.helpGetCurrencies();	
		this.props.helpActions.helpGetAirports("");	
		this.props.helpActions.helpGetCargoType();	
	}

	render() {
		return (
			<Router basename={'/'}>
				<Switch>
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/`} component={LoginPage} layout={EbookingBlueLayout} />
					{/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={() => window.location = 'https://www.cal-cargo.com'} /> */}
					{/* <PublicRoute exact path={`${process.env.PUBLIC_URL}/test-api`} component={TestApi} layout={MainLayout} /> */}
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim`} component={ClaimIndex} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/new-claim`} component={ClaimBasic} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-detail`} component={ClaimDetail} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-submit`} component={ClaimSubmit} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-success`} component={ClaimSuccess} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-edit-success`} component={ClaimEditSuccess} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-update`} component={ClaimSearch} layout={ClaimLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/claim-edit/:ClaimNum`} component={ClaimEdit} layout={ClaimLayout} />

					<PublicRoute exact path={`${process.env.PUBLIC_URL}/register-step2`} component={HomeIndex} layout={MainLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking`} component={NewEbooking} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/shipment`} component={NewEbooking} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/booking-detail`} component={NewEbooking} layout={MainLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/flight-pricing-default`} component={FlightPricingDefault} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/flight-pricing-ad-hoc`} component={FlightPricingAdhoc} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/flight-pricing-parcel`} component={FlightPricingParcel} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/flight-pricing-pricelist`} component={FlightPricingPricelist} layout={EbookingLayout} />					
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/participants`} component={Participants} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/participants-gsa`} component={ParticipantsGSA} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/review-order`} component={ReviewOrder} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/confirm-cass`} component={ConfirmCass} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/confirm-agreement`} component={ConfirmAgreement} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/payment`} component={Payment} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/payment-cass`} component={PaymentCASS} layout={MainLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/payment-credit`} component={PaymentCredit} layout={MainLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/success`} component={Success} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/notification`} component={ScHanlde} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/sc-success`} component={ScSuccess} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/sc-success-final`} component={ScSuccessFinal} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/sc-pending`} component={ScPending} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/sc-failure`} component={ScFailure} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/sc-submit-failure`} component={ScSubmitFailure} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/gsa-message`} component={GSAMessage} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/payment`} component={PaymentAWB} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/sc-awb-pending`} component={ScAWBPending} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/sc-awb-success`} component={ScAWBSuccess} layout={EmptyLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/payment/success`} component={PaymentAWBSuccess} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/ebooking/attach-files`} component={UploadFile} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/login`} component={LoginPage} layout={EbookingBlueLayout} />

					<PublicRoute exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Shipments} layout={DashBoardLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/dashboard/quotes`} component={Quotes} layout={DashBoardLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/account`} component={Info} layout={EbookingLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/review-order`} component={OrderDetail} layout={EbookingLayout} />

					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/`} component={Commodity} layout={SupplierLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/commodity`} component={Commodity} layout={SupplierLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/pickup`} component={Pickup} layout={SupplierLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/special-instruction`} component={SpecialIntructionOne} layout={SupplierLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/attach-files`} component={SupplierUploadFile} layout={EbookingBlueLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/supplier-update/success`} component={SupplierSuccess} layout={EbookingBlueLayout} />

					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/accept/:secretKey/:quoteID/:webCode/:chatID`} component={AcceptQuotes} layout={EbookingBlueLayout} />
					{/* <PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/accept/confirm/:secretKey/:quoteID/:webCode`} component={AcceptQuotesStep2} layout={EbookingBlueLayoutQuotes} /> */}
					
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/review/:secretKey/:quoteID`} component={QuotesReviewGuest} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/review-order/:quoteID`} component={QuotesReview} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/participants/:quoteID`} component={QuoteParticipants} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/participants-gsa/:quoteID`} component={QuoteParticipantsGSA} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/payment/:quoteID`} component={QuotePayment} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/flights/:quoteID`} component={QuoteChooseFlights} layout={QuotesLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/negotiation/:secretKey/:cprof`} component={NegotiationQuotes} layout={NegotiationLayout} />
					<PublicRoute exact path={`${process.env.PUBLIC_URL}/quotes/temp-user`} component={TempUser} layout={EbookingBlueLayout} />

					{/* <PrivateRoute exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} layout={MainLayout} /> */}
					<PrivateRoute exact path={`${process.env.PUBLIC_URL}/account/reports`} component={Reports} layout={MainLayout} />
					<Route path="*" component={Page404} />
				</Switch>
			</Router>
		);
	}
}

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
	return {
		authActions: bindActionCreators(authActions, dispatch),
		helpActions: bindActionCreators(helpActions, dispatch)
	}
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppForm);
export default App;