import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone'
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
//import S3FileUpload from 'react-s3';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class ClaimSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'Claim Update'
                },
            ],
            steps: [
                {
                    'link': 'claim',
                    'active': false,
                    'validate': false,
                    'name': 'Basic Info'
                },
                {
                    'link': 'claim-detail',
                    'active': false,
                    'validate': true,
                    'name': 'Claim Details'
                },
                {
                    'link': 'claim-submit',
                    'active': false,
                    'validate': true,
                    'name': 'Submit Documents'
                },
            ],
            isError: false,
            isNotFound: false,
            errorMessage: null,
            isEdit: false,
            errors: {},
            search: {
                'email': '',
                'claim_id': ''
            }

        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Claims | Challenge Group"
    }

    handleChange(event) {
        const { search } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        search[name] = value;
        this.setState({ search });

    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { search } = this.state;
        if (!validator.email(search.email)) {
            errors['email'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }


        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        const { search } = this.state;

        this.props.loadingActions.loadingChangeData(true);
        this.props.claimActions.getClaim(search.email, search.claim_id).then(response => {            
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                this.setState({ isNotFound: false });
                let { history } = this.props;
                history.push('/claim-edit/' + search.claim_id);
            } else {
                this.setState({ isNotFound: true });
            }
        });

    }


    render() {
        const { search, breadcrumbs, steps, isError, isNotFound, errors, errorMessage } = this.state;

        return (
            <div className="container">
                <Breadcrumb breadcrumbData={breadcrumbs} />

                <h1 className="title">Claim Update</h1>

                <div className="block-form claim-form">
                    <form className='claim-search-form'>

                        <div className="form-group form-rows">
                            <label htmlFor="email">Email*</label>
                            <input type="email" className={`form-control ${(errors.email) ? 'input-error' : ''}`} id="email" name="email" value={search.email} onChange={this.handleChange} />
                            <InputError error={errors.email} />
                        </div>
                        <div className="form-group form-rows">
                            <label htmlFor="email">Claim#*</label>
                            <input type="text" className={`form-control ${(errors.claim_id) ? 'input-error' : ''}`} id="claim_id" name="claim_id" value={search.claim_id} onChange={this.handleChange} />
                            <InputError error={errors.claim_id} />
                        </div>
                        <div className="form-group">
                            <button type="button" className="btn btn-continues right btn-right" onClick={this.handSubmit}>Go</button>
                            <div className="clb"></div>
                        </div>

                    </form>

                </div>
                <div className="form-group">
                    <div className={`alert alert-warning alert-dismissible fade ${isNotFound ? 'show' : 'hide'}`} role="alert">
                        Claim does not exist
                            </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        claimActions: bindActionCreators(claimActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSearch);