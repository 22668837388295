import React from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from '../layouts/partials/Breadcrumb';
import fetch from 'isomorphic-fetch';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import axios from 'axios';
import * as claimActions from '../ninja-redux/actions/claimActions';
import { ENGINE_METHOD_DIGESTS } from 'constants';


class TestApi extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - Home page";
    }

    getApi = () => {
        axios.post("http://192.168.1.109:3000/api/service/call-cal-api", {
            "functionName": 'getClaim',
            "dataPass": {
                "claim": "CLM70011623301-13",
                "email": "manhhung86it@gmail.com"
            }
        }).then(res => {
            // console.log(res);
        });

    }
    postApi = () => {
        axios.post("http://192.168.1.109:3000/api/service/call-cal-api", {
            "functionName": 'setClaim',
            "dataPass": {
                "ClaimNum": "CLM70011623301-13",
                "email": "manhhung86it@gmail.com",
                "awbnum": "",
                "claimertype": "",
                "insurance": "",
                "NG": "",
                "claimtype": "",
                "ClaimFiles": [
                    {
                        "filename": "123456.pdf",
                        "filedes": "cmr"
                    },
                    {
                        "filename": "222.xls",
                        "filedes": "eAWB"
                    }
                ],
                "Insured": "",
                "text": "",
                "ClaimAspect": [],
                "ClaimAmount": []
            }
        }).then(res => {
            // console.log(res);
        });
    }

    buildMailContent = () => {
        var mess_json = {
            awb: '70053423576',
            weight: '2833.3',
            Overlap: '',
            DGR: '',
            Litium: '',
            unstackable: '',
            un: '',
            Hscode: [],
            files:
                ['WEBSUP70053423576-Cal-Cargo--MRNxpbqo.png',
                    'WEBSUP70053423576-Cal-Cargo--MRN213dp.png',
                    'WEBSUP70053423576-Cal-Cargo-Dashb-6.png'],
            Eroi: '',
            FromCountry: 'ALGERIA',
            Pickupref: '',
            Pickcompany: 'Hung Bich Jsc',
            Pickcountry: 'VIETNAM',
            Pickcity: 'Ha Noi',
            Pickzip: '70000',
            Contact: 'Manh Hung Nguyen',
            Email: 'manhhung86it@gmail.com',
            Phonenum: '985679742',
            Picktime: '26/07/19 00:00',
            Pickopenhours: '6h00 - 10h00',
            Pickcomment: 'No comments',
            Pickaddress: 'Doan Hung, Phu Tho',
            HandleComment: '',
            class: [],
            HandlingInstrunctionCodes: [],
            Dimensions:
                [{
                    unitname: 'CM',
                    Pcs: 1,
                    Height: 100,
                    Length: 100,
                    Width: 100,
                    Cbm: 1
                },
                {
                    unitname: 'CM',
                    Pcs: 2,
                    Height: 200,
                    Length: 200,
                    Width: 200,
                    Cbm: 8
                }]
        };

        var replaceKeys = {
            awb: "AWB",
            weight: "Weight",
            unstackable: "Unstackable",
            un: "UN",
            Hscode: "HS Code",
            files: "Files",
            FromCountry: "From Country",
            Pickupref: "Pickup Ref",
            Pickcompany: "Pick Company",
            Pickcountry: "Pick Country",
            Pickcity: "Pick City",
            Pickzip: "Pick Zip",
            Picktime: "Pick Time",
            Pickopenhours: "Open Hours",
            Phonenum: "Phone Number",
            Pickcomment: "Pick Comment",
            Pickaddress: "Pick Address",
            HandleComment: "Handle Comment",
            class: "Class",
            HandlingInstrunctionCodes: "Handling Instrunction Codes",
            Dimensions: "Dimensions",
            unitname: "Unit Name",
            Pcs: "Pieces",
            Cbm: "CBM"
        };
        var body_content = "";
        Object.keys(mess_json).forEach(function (k) {
            if (typeof mess_json[k] === 'string' || mess_json[k] instanceof String) {
                body_content += (replaceKeys[k] ? replaceKeys[k] : k) + ": " + mess_json[k] + "<br />";
            } else {
                var array_content = "";
                if (k === "files" || k === "Hscode" || k === "class" || k === "HandlingInstrunctionCodes") {
                    array_content += "<ul>";
                    mess_json[k].map((f, fi) => {
                        array_content += "<li>" + f + "</li>";
                    });
                    array_content += "</ul>";
                    if(!mess_json[k].length){
                        array_content = "";
                    }
                } else if (k === "Dimensions") {
                    array_content += "<ul>";
                    mess_json[k].map((d, di) => {
                        array_content += "<li>";
                        Object.keys(d).forEach(function (kd) {
                            array_content += (replaceKeys[kd] ? replaceKeys[kd] : kd) + ": " + d[kd] + ", ";
                        });
                        array_content += "</li>";
                    });
                    array_content += "</ul>";
                } else {
                    array_content = JSON.stringify(mess_json[k]);
                }
                body_content += (replaceKeys[k] ? replaceKeys[k] : k) + ": " + array_content + "<br />";
            }
        });
        return body_content;
    }

    render() {
        return (
            <div className="container">

                {this.buildMailContent()}

                <button onClick={this.getApi.bind(this)}>get Api</button>
                <button onClick={this.postApi.bind(this)}>post Api</button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, claimActions)(TestApi);
