import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

class ModalPassResetForm extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        this.passResetForm.resetFrom();
    };

    render() {
        return (
            <div className="modal-groups ">

                <div className="modal" id="modalPassReset" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="false" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalPassReset')}></button>
                            </div>
                            <PassResetForm onRef={ref => (this.passResetForm = ref)} sendVerificationCode={this.props.sendVerificationCode} setPassword={this.props.setPassword} showModal={this.props.showModal} hideModal={this.props.closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

const ModalPassReset = connect(mapStateToProps, loadingAction)(ModalPassResetForm);

export default ModalPassReset;


class PassResetForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: props.error,
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'password': '',
                'email': '',
                'code': '',
            },
            btnGetCodeText: 'Verify Email Address',
            btnSetPassText: 'Reset Password'
        };

        this.forgotHandleChange = this.forgotHandleChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePass = this.validatePass.bind(this);
        this.validatePassField = this.validatePassField.bind(this);
        this.validateEmailField = this.validateEmailField.bind(this);
        this.resetFrom = this.resetFrom.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    //reset from data
    resetFrom = () => {
        this.setState({
            error: '',
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'password': '',
                'email': '',
                'code': '',
            },
            showGetCode: true,
            resetPassSuccess: false,
            btnGetCodeText: 'Verify Email Address',
            btnSetPassText: 'Reset Password'
        });
    }

    /* handler form's fields change value */
    forgotHandleChange(event) {
        const { loginForm } = this.state;
        const { target, target: { name, type } } = event;
        loginForm[name] = target.value;
        if(name == 'email'){
            loginForm[name] = loginForm[name].toLowerCase();
        }
        this.setState({ loginForm });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.btnLoginText !== 'Set New Password') {
            this.setState({ btnLoginText: 'Set New Password' });
        }
    }

    validatePassField(event) {
        const { loginForm, errorLogins } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        switch (target.name) {
            case 'password':
                if (!validator.required(loginForm.password)) {
                    errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errorLogins['password'] = '';
                }
                break;
            case 'code':
                if (!validator.required(loginForm.code)) {
                    errorLogins['code'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errorLogins['code'] = '';
                }
                break;
        }
        this.setState({ errorLogins });
    }
    validateEmailField(event) {
        const { loginForm, errorLogins } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        switch (target.name) {
            case 'email':
                if (!validator.required(loginForm.email)) {
                    errorLogins['email'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                if (!validator.email(loginForm.email)) {
                    errorLogins['email'] = MESSAGES.ERROR_EMAIL;
                    isError = true;
                }
                if (!isError) {
                    errorLogins['email'] = '';
                }
                break;
        }
        this.setState({ errorLogins });
    }

    validateEmail(event) {
        this.setState({ errorLogins: {} });
        const { loginForm } = this.state;
        var errorLogins = {};
        var isError = false;
        var validator = new Validator();

        if (!validator.required(loginForm.email)) {
            errorLogins['email'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errorLogins });
            return false;
        }
        return true;
    }

    validatePass(event) {
        this.setState({ errorLogins: {} });
        const { loginForm } = this.state;
        var errorLogins = {};
        var isError = false;
        var validator = new Validator();

        if (!validator.required(loginForm.code)) {
            errorLogins['code'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!validator.required(loginForm.password)) {
            errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errorLogins });
            return false;
        }
        return true;
    }

    handlerGetCode = () => {
        this.setState({
            successMessage: '',
            errorMessage: '',
        });
        if (this.validateEmail()) {
            this.setState({ btnGetCodeText: 'Submitting ...' });
            this.props.sendVerificationCode(this.state.loginForm.email)
                .then(() => {
                    this.setState({ btnGetCodeText: 'Verify Email Address' });
                    this.setState({
                        // successMessage: 'Verification code sent. Please check your email.',
                        successMessage: '',
                        errorMessage: '',
                        showGetCode: false
                    })
                })
                .catch((err) => {
                    this.setState({ btnGetCodeText: 'Verify Email Address' });
                    if (err.code === 'UserNotFoundException') {
                        this.setState({ errorMessage: 'Email not found', successMessage: '' });
                    } else {
                        this.setState({ errorMessage: err.message, successMessage: '' })
                    }
                });

        }
    }

    handlerChangePass = () => {
        this.setState({
            successMessage: '',
            errorMessage: '',
        });
        if (this.validatePass()) {
            this.setState({ btnSetPassText: 'Submitting ...' });
            this.props.setPassword(this.state.loginForm.email, this.state.loginForm.code, this.state.loginForm.password)
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            successMessage: 'Password saved successfully.',
                            errorMessage: '',
                        });
                        this.setState({ btnSetPassText: 'Reset Password', resetPassSuccess: true });
                    } else {
                        this.setState({ btnSetPassText: 'Reset Password' });
                        this.setState({
                            successMessage: '',
                            errorMessage: response.message,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({ btnSetPassText: 'Reset Password' });
                    this.setState({
                        successMessage: '',
                        errorMessage: err.message,
                    });
                });

        }
    }

    showGetCode = (vl) => {
        this.setState({
            showGetCode: vl,
        });
    }
    render() {
        const { breadcrumbData, isErrorLogin, errorLogins, loginForm } = this.state;

        return (
            <div>
                <div className="modal-content">

                    <div className="modal-body">

                        <h2>Forgot Password</h2>
                        <hr />
                        <div className={`alert alert-success ${(this.state.successMessage) ? '' : 'hide'}`}>
                            {this.state.successMessage}
                        </div>
                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                            {this.state.errorMessage}
                        </div>
                        <form className={`${(this.state.showGetCode || this.state.resetPassSuccess) ? 'd-none' : ''}`}>
                            <div className="form-group small-margin">
                                A verification code was emailed to you. Please enter this code and your new password.
                            </div>
                            <div className="form-group small-margin">
                                <input type="text" className={`form-control ${(errorLogins.code) ? 'input-error' : ''}`} name="code" placeholder="Enter verification code" value={loginForm.code} onBlur={this.validatePassField} onChange={this.forgotHandleChange} />
                            </div>
                            <div className="form-group small-margin">
                                <input type="password" className={`form-control ${(errorLogins.password) ? 'input-error' : ''}`} name="password" placeholder="Enter your new password" value={loginForm.password} onBlur={this.validatePassField} onChange={this.forgotHandleChange} />
                            </div>
                            <div className="form-group small-margin">
                                <button type="button" onClick={this.handlerChangePass} className="btn btn-continues btn-forgot">{this.state.btnSetPassText}</button>
                            </div>
                        </form>
                    </div>
                    <div className={`modal-body ${(this.state.showGetCode) ? '' : 'd-none'}`}>
                        <form>
                            <div className="form-group small-margin">
                                <label htmlFor="email" className="mb-2">Please enter your email address: </label>
                                <input type="text" className={`form-control ${(errorLogins.email) ? 'input-error' : ''}`} name="email" placeholder="Email Address" value={loginForm.email} onBlur={this.validateEmailField} onChange={this.forgotHandleChange} />
                            </div>
                            <div className="form-group small-margin">
                                <button type="button" onClick={this.handlerGetCode} className="btn btn-continues btn-forgot">{this.state.btnGetCodeText}</button>
                            </div>
                        </form>
                    </div>
                    <div className={`modal-body ${(this.state.resetPassSuccess) ? '' : 'd-none'}`}>
                        <div className="form-group small-margin">
                            Your new password is saved.
                        </div>
                        <form>
                            <div className="form-group small-margin">
                                <div className="row">
                                    <div className="col-6">
                                        <button type="button" onClick={this.props.showModal.bind(this, 'modalLogin')} className="btn btn-block btn-continues-login">Login</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" onClick={this.props.hideModal.bind(this, 'modalPassReset')} className="btn btn-block btn-continues-login">Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

PassResetForm.propTypes = {
    error: PropTypes.string,
    username: PropTypes.string,
    sendVerificationCode: PropTypes.func,
    setPassword: PropTypes.func,
};
