import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
import queryString from 'query-string';
import EbookingInputError from '../EbookingInputError';
import BookingHelper from '../ebooking/BookingHelper';
/**
 * Validator
 */
import validator from 'validator';
import InputError from '../InputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';

var intervalID;
class PaymentAWB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingFields: true,
            loadingFieldsError: false,
            loadedAWB: false,
            errorMessageOther: {},
            errorMessage: null,
            isEdit: false,
            errors: {},
            costDatas: [],
            total: 0,
            paymentUrl: "",
            pricingCase: "",
            loadAWBError: false,
            gettedLink: false,
            createSCTransaction: true,
            scID: "",
            awb: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            amount: 0,
            cur: '',
            product_name: '',
            paymeToken: {},
            createToken: true,
            orderID: "",
            maxTimeLoadWebf: 90,
            errorLoadWebf: false,
            idNumber: '',
            submitedIDNumber: 0,
            maxSubmitIDNumber: 5
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.getAwb = this.getAwb.bind(this);
    }

    getAwb = async (awbNumber, ord) => {
        const { errors } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        var t = this;
        this.setState({ isError: false, errorMessage: "", loadAWBError: false, loadedAWB: false });
        t.props.ebookingActions.getSC(awbNumber, ord).then(responseSC => {
            t.props.loadingActions.loadingChangeData(false);
            // var responseSC = { 'success': true, 'data': { 'Success': true, 'amount': 100, 'cur': 'USD', 'phonenum': "12345678912", 'email': 'manhhung86it@gmail.com' } };
            if (responseSC.success && responseSC.data.Success && responseSC.data.amount > 0) {
                t.setState({ awb: awbNumber });
                //get SC form                
                var amount = parseFloat(responseSC.data.amount);
                t.setState({
                    amount: amount.toFixed(2),
                    product_name: "AWB: " + awbNumber,
                    cur: responseSC.data.cur,
                    phone: responseSC.data.phonenum,
                    email: responseSC.data.email,
                    loadAWBError: false,
                    loadedAWB: true
                });
            } else {
                t.setState({ isError: true, errorMessage: "GET Payment Information Unsuccess!", loadAWBError: true, loadedAWB: false });
            }
        });

        this.setState({ errors });
    }

    postSafeCharge = (amount, currency, token, orderID) => {
        return new Promise((resolve, reject) => {
            var scTransactionData = {
                "orderID": orderID,
                "paymeToken": token,
                "currency": currency,
                "awb": this.state.awb,
                "authcode": "",
                "amount": amount,
                "transid": "-1",
                "custname": this.props.auth.custname,
                // "custdesc": this.props.auth.custdes,
                // "custname": "11250006",
                "cust": this.props.auth.cust,
                "type": "CASH",
                "isexpress": "",
                "pl": 0,
                "parcelpl": 0,
                "parcelprice": 0,
                "parcelexpprice": 0,
                "linkcharge": "Y",
                "Participents": {
                    "SHIPNAME": "",
                    "SHIPIATACODE": "",
                    "RECNAME": "",
                    "RECIATACODE": "",
                    "CNORNUM": "",
                    "CNORNAME": "",
                    "CNORCOUNTRY": "",
                    "CNORADDRESS": "",
                    "CNORCITY": "",
                    "CNORPOSTALCODE": "",
                    "CNEENUM": "",
                    "CNEENAME": "",
                    "CNEECOUNTRY": "",
                    "CNEEADDRESS": "",
                    "CNEEPOSTALCODE": "",
                    "CNEECITY": "",
                    "PICKCOMPNAME": "",
                    "PICKNAME": "",
                    "PICKEMAIL": "",
                    "PICKPHONE": "",
                    "PICKCOUNTRY": "",
                    "PICKCITY": "",
                    "PICKADDRESS": "",
                    "PICKPOSTALCODE": "",
                    "DELCOMPNAME": "",
                    "DELNAME": "",
                    "DELEMAIL": "",
                    "DELPHONE": "",
                    "DELCOUNTRY": "",
                    "DELCITY": "",
                    "DELADDRESS": "",
                    "DELPOSTALCODE": "",
                    "DELOPENHOURS": "",
                    "DELREMARKS": "",
                    "PICKOPENHOURS": "",
                    "PICKREMARKS": ""
                },
                "PQ": {
                    "AWB": this.state.awb,
                    "MustRide": "",
                    "Plnum": ""
                },
                "routes": null
            };
            resolve({ success: true, bookingData: scTransactionData });
        });
    }

    async componentDidMount() {
        var { history } = this.props;

        window.addEventListener("message", this.handleFrameTasks);
        window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleFrameTasks);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        var { errors } = this.state;
        if (!this.state.gettedLink && ((oldProps.auth.state != "LOGGED_IN" && newProps.auth.state == "LOGGED_IN") || (oldProps.auth.state != "LOGGED_OUT" && newProps.auth.state == "LOGGED_OUT"))) {
            this.setState({ gettedLink: true });
            var params = this.props.location.search;
            const parsedParams = queryString.parse(params);
            var awbnumber = parsedParams.awb;
            var ord = parsedParams.ord;
            if (!awbnumber || validator.isEmpty(awbnumber + "") || !validator.isNumeric(awbnumber + "") || !validator.isLength(awbnumber + "", { min: 11, max: 11 })) {
                this.setState({ isError: true, errorMessage: "AWB number should be 11 characters.", showContactLink: false });
            } else if (!ord || validator.isEmpty(ord + "") || !validator.isNumeric(ord + "")) {
                this.setState({ isError: true, errorMessage: "Order ID number wrong.", showContactLink: false });
            } else {
                this.getAwb(awbnumber, ord);
            }
        }
    }

    handleFrameTasks = (e) => {
        var { history } = this.props;
        var { idNumber } = this.state;
        if (e.data === 'success-final') {
            history.push('/payment/success?scID=' + this.state.scID + "&awb=" + this.state.awb);
        } else if (e.data === 'load-cc-fields-success') {
            this.setState({ loadingFields: false, loadingFieldsError: false });
        } else if (e.data === 'load-cc-fields-unsuccess') {
            this.setState({ loadingFields: false, loadingFieldsError: true });
        } else {
            try {
                var jsonData = JSON.parse(e.data);
                if (jsonData.submitedid) {
                    idNumber = jsonData.submitedid;
                    this.setState({ idNumber });
                    this.handSubmit();
                }
            }
            catch (err) {
            }
        }
    }

    /* handler form's fields change value */
    handleChange = (event) => {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        if (name === 'firstName') {
            this.setState({ firstName: value });
            var checkFirstName = window.testFirstName(value);
            errors.firstName = !checkFirstName.isValid;
            errors.firstNameRequire = "";
        } else if (name === 'lastName') {
            this.setState({ lastName: value });
            var checkLastName = window.testLastName(value);
            errors.lastName = !checkLastName.isValid;
            errors.lastNameRequire = "";
        }
    }

    handleOnBlur = (event) => {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        if (name === 'firstName') {
            var checkFirstName = window.testFirstName(value);
            if (checkFirstName.isValid == false) {
                if (value != "") {
                    errors.firstName = !checkFirstName.isValid;
                } else {
                    errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
        } else if (name === 'lastName') {
            var checkLastName = window.testLastName(value);
            if (checkLastName.isValid == false) {
                if (value != "") {
                    errors.lastName = !checkLastName.isValid;
                } else {
                    errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
        }
        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '', errorMessageOther: {} });

        var errors = {};
        var isError = false;
        var { firstName, lastName } = this.state;
        var checkFirstName = window.testFirstName(firstName);
        if (checkFirstName.isValid == false) {
            isError = true;
            if (firstName != "") {
                errors.firstName = !checkFirstName.isValid;
                errors.firstNameRequire = "Value Invalid";
            } else {
                errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }
        var checkLastName = window.testLastName(lastName);
        if (checkLastName.isValid == false) {
            isError = true;
            if (lastName != "") {
                errors.lastName = !checkLastName.isValid;
                errors.lastNameRequire = "Value Invalid";
            } else {
                errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    componentWillUnmount() {
        if (intervalID) {
            clearInterval(intervalID);
        }
        window.cancelPayment();
        window.removeEventListener("message", this.handleFrameTasks);
    }

    getWebf = () => {
        var t = this;
        var { maxTimeLoadWebf } = this.state;
        var { history } = this.props;
        var timeLoadWebf = 0;
        intervalID = setInterval(function () {
            if (timeLoadWebf <= maxTimeLoadWebf) {
                timeLoadWebf += 5;
                t.props.ebookingActions.getWebf(t.state.orderID).then(response => {
                    if (response.success && response.data.Success && response.data.webf != undefined) {
                        t.props.loadingActions.loadingChangeData(false);
                        if (intervalID) {
                            clearInterval(intervalID);
                        }
                        // history.push('/payment/success?webf=' + response.data.webf + "&awb=" + t.state.awb);
                        history.push('/payment/success?tid=' + t.state.orderID);
                    }
                });
            } else {
                t.props.loadingActions.loadingChangeData(false);
                if (intervalID) {
                    clearInterval(intervalID);
                }
                t.props.ebookingActions.paymentError(t.state.orderID).then(response => {
                });
                t.setState({ errorLoadWebf: true });
            }
        }, 5000);
    }


    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (!this.isValidation()) {
            return false;
        }
        // this.getAwb(this.state.awb_number_1 + "" + this.state.awb_number_2);
        this.setState({ isError: false, errorMessage: "", errorMessageOther: {} });
        this.props.loadingActions.loadingChangeData(true);
        var { history, auth } = this.props;
        var orderID = uuid();
        var t = this;
        var { firstName, lastName, amount, product_name, paymeToken, createToken, cur, phone, email, idNumber, submitedIDNumber, maxSubmitIDNumber } = this.state;
        if (!paymeToken.data || createToken) {
            paymeToken = await window.submitTransaction(firstName, lastName, email, phone, orderID, cur, amount + "", idNumber);
            this.setState({ paymeToken, createToken: false });
        }
        if (paymeToken.success) {
            var createRowDB = await this.postSafeCharge(amount, cur, paymeToken.data.token, orderID);
            var dataPass = {
                "sale_price": parseInt(amount * 100),
                "currency": cur,
                "product_name": product_name,
                "transaction_id": orderID,
                "installments": 1,
                "market_fee": 0.00,
                "sale_callback_url": AppConfig.apiUrl + "notifications",
                "sale_return_url": "http://online.cal-cargo.com",
                "capture_buyer": 0,
                "buyer_key": paymeToken.data.token,
                "sale_payment_method": "credit-card",
                "language": "en",
                "bookingData": createRowDB.bookingData
            };
            this.props.ebookingActions.doIndependentPayment(dataPass).then(res => {
                this.props.loadingActions.loadingChangeData(false);
                if (res.success && res.data.payme_status === 'success') {
                    this.setState({ orderID: res.data.transaction_id, loadingFields: true });
                    this.props.loadingActions.loadingChangeData(true);
                    t.getWebf();
                } else {
                    window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
                    this.setState({ isError: true, errorMessageOther: res.data, errorMessage: "", createToken: true });
                }
            })
        } else {
            this.props.loadingActions.loadingChangeData(false);
            if (!paymeToken.error.payload) {
                paymeToken.error.payload = {
                    status_error_details: "credit card data invalid",
                    status_error_code: 'credit card invalid'
                }
            }
            // if (paymeToken.error.payload.status_error_code == 21007 && !submitedIDNumber) {
            if (paymeToken.error.payload.status_error_code == 21007 && submitedIDNumber < maxSubmitIDNumber) {
                submitedIDNumber++;
                createToken = true;
                this.setState({ createToken, submitedIDNumber });
                window.postMessage('show-id-number-form', '*');
            } else {
                this.setState({ isError: true, errorMessage: "", errorMessageOther: paymeToken.error.payload });
            }
        }
    }

    render() {
        const { paymentUrl, isError, errors, errorMessage, errorMessageOther, cur, amount, loadAWBError, loadedAWB, loadingFieldsError, loadingFields, awb, errorLoadWebf }
            = this.state;
        var { auth } = this.props;
        return (

            <div className="ebooking">
                <div className="container">
                    {/* <Notification isError={errorMessageOther ? true : false} message={errorMessageOther} /> */}
                    <div className={`form-group ${(errorLoadWebf) ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            We encountered an error while creating your airway bill. This error is logged and our team will look into it. We apologize for the inconvenience. You may <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a> at any time.
                        </div>
                    </div>
                    <div className={`form-group ${(errorMessage || loadAWBError) ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                    <div className={`form-group ${errorMessageOther.status_error_details ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Payment failed. {errorMessageOther.status_error_details ? errorMessageOther.status_error_details : ''} (error code: {errorMessageOther.status_error_code ? errorMessageOther.status_error_code : ''}). Please try again. If issue persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                </div>

                <form>
                    <div className="container block-form mb-4">
                        <h1 className="formTitle mb-1">Make a Payment</h1>
                        <h1 className="title mb-5">AWB: {awb}</h1>
                        <div className="form-content no_padding">
                            <div className="container-child div_center">
                                <div className="block-form-content mt-3 div_center form-payment">
                                    <div className={`col-12 form-group mt-4 ${loadingFieldsError ? '' : 'd-none'}`}>
                                        <div className="row">
                                            Load payment form error!
                                        </div>
                                    </div>
                                    <div className={`col-12 form-group bg-blue ${loadingFields ? 'loading' : ''} ${(loadingFieldsError || !loadedAWB || loadAWBError) ? 'd-none' : ''}`}>
                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="firstName">Card Holder First Name</label>
                                                <input type="text" className={`form-control ${(errors.firstName) ? 'text-red' : ''} ${(errors.firstNameRequire) ? 'input-error' : ''}`} id="firstName" name="firstName" value={this.state.firstName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.firstNameRequire} />
                                            </div>
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="lastName">Card Holder Last Name</label>
                                                <input type="text" className={`form-control ${(errors.lastName) ? 'text-red' : ''} ${(errors.lastNameRequire) ? 'input-error' : ''}`} id="lastName" name="lastName" value={this.state.lastName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.lastNameRequire} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-sm-12">
                                                <label htmlFor="cardNumber">Card Number</label>
                                                <div id="card-number-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-4 col-6">
                                                <label htmlFor="expiration">Expiry</label>
                                                <div id="card-expiration-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-2 col-6">
                                                <label htmlFor="CVV">CVV</label>
                                                <div id="card-cvv-container" className="form-control"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group mt-4">
                                        <div className="row">
                                            <button type="button" className={`btn btn-block btn-ebooking-orange ${(loadingFields || loadingFieldsError || !loadedAWB || loadAWBError) ? 'd-none' : ''}`} disabled={(loadingFields || loadingFieldsError || !loadedAWB || loadAWBError || amount <= 0) ? true : false} onClick={this.handSubmit}>Pay {BookingHelper.currencySymbol(cur)}{BookingHelper.formatMoney(amount)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAWB);