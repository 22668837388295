import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import config from './config.json';
import store from './ninja-redux/store';

/**
 * Redux
 */
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';


ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('ninja-team-root'));