import reducer from "./reducers";
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "redux-logger";

// const middleware = applyMiddleware(promiseMiddleware(), thunkMiddleware, loggerMiddleware);
const middleware = applyMiddleware(promiseMiddleware(), thunkMiddleware);
let store = createStore(reducer, middleware);

export default store;