import axios from 'axios';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
import moment from 'moment';

export function setUserLogedIn(user) {
    return async (dispatch) => {
        dispatch({
            type: "COGNITO_AUTHENTICATED",
            user
        });
        return { success: true }
    }
}

export function claimReset() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.CLAIM_RESET,
            payload: {}
        })
        return { success: true }
    }
}

export function claimChangeData(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.CLAIM_CHANGE_DATA,
            payload: inputData
        })
        return { success: true }
    }
}

export function getAWBData(awbnum) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getAWBData',
            "dataPass": {
                "awbnum": awbnum
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        // let dataReturn = { "success": true, "data": { dataResponse: { "success": true, "awbdata": [{ "NG": "COMPUTER PARTS LITHIUM ION BATTERIES INCOMPLIANCE WITH SECTION II OF P", "numOfPieces": 1, "weight": 44, "ATD": "06/12/2017 05:50", "ATA": "06/12/2017 09:50", "FATD": "06/12/2017 05:50" }], "ErrorCode": null, "ErrorDes": null } } };
        // console.log(dataReturn);
        if (dataReturn.success && dataReturn.data.Success) {
            var awbdata = dataReturn.data.awbdata[0];            
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "nature_of_goods", fieldValue: awbdata.NG }
            });
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "number_of_pc", fieldValue: awbdata.numOfPieces }
            });
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "weight", fieldValue: awbdata.weight }
            });
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "flight_date", fieldValue: awbdata.FATD === "01/01/1988 00:00" ? "" : awbdata.FATD }
            });
        }
        return dataReturn;
    }
}

export function getClaim(email, claimId) {

    return async (dispatch) => {

        var dataPass = {
            "functionName": 'getClaim',
            "dataPass": {
                claim: claimId,
                email: email
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        if (dataReturn.success && dataReturn.data.Success) {
            dispatch({
                type: ActionTypes.CLAIM_RESET,
                payload: {}
            });
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "ClaimNum", fieldValue: claimId }
            });
            dispatch({
                type: ActionTypes.CLAIM_CHANGE_DATA,
                payload: { fieldName: "email", fieldValue: email }
            });
        }

        return dataReturn;
    }
}


export function addClaim(data) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'setClaim',
            "dataPass": data
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        // let dataReturn = { success: true, data: { dataResponse: { "success": true, "ClaimNum": "AD1223-123", "Success": true, "ErrorCode": null, "ErrorDes": null } } };
        if (dataReturn.success && dataReturn.data.Success) {
            dispatch({
                type: ActionTypes.CLAIM_RESET,
                payload: {}
            });
        }
        return dataReturn;
    }
}



export function updateClaim(data) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'setClaim',
            "dataPass": data
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        if (dataReturn.success && dataReturn.data.Success) {
            dispatch({
                type: ActionTypes.CLAIM_RESET,
                payload: {}
            });
        }
        return dataReturn;
    }
}

export function uploadClaimFile(file) {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.uploadMethod('api/service/upload-to-s3', file);

        return dataReturn;
    }
}

export function removeUploadFile(data) {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.postMethod('api/service/remove-file', data);
        return dataReturn;
    }
}