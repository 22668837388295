import React from 'react';

export default class ModaQuoteActionError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { onShow, description } = this.props;
        if(!onShow)
          return (null);

        return (
            <div className="modal-dialog modal-dialog-small modal-dialog-centered sample-confirm" role="document">
                <div className="modal-content quotes-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            <h5>Message</h5>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description" style={{'maxWidth': '350px'}}>There are updates to this quote. Please refresh this screen before responding to the quote.</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <div className="row">
                            <div className="col-5"><button type="button" className="btn btn-cancel btn-block" onClick={this.props.handleRefresh}>Refresh</button></div>
                            <div className="col-7"><button type="button" className="btn btn-continues btn-block" onClick={this.props.handleGoToDashboard}>Return to Dashboard</button></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
