import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Account from '../../layouts/partials/Account';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import detectPrint from "react-detect-print";
import Autosuggest from 'react-autosuggest';
import uuid from 'uuid';
import moment from 'moment';
import base64 from 'base-64';
import BookingHelper from '../ebooking/BookingHelper';
import PopperItem from './element/PopperItem';
import PopperItemConsignee from './element/PopperItemConsignee';
import AppConfig from '../../AppConfig';
import clone from 'clone';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import Nav from './Nav';
import * as MESSAGES from '../../libs/Messages';
import * as Pdf from '../../libs/Pdf';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardActions from '../../ninja-redux/actions/dashboardActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as authActions from '../../ninja-redux/actions/authActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';

import Modal from 'react-modal';
import ModalDashboardUpdateQuote from "../../layouts/partials/ModalDashboardUpdateQuote";
import ModalDashboardOneMoment from "../../layouts/partials/ModalDashboardOneMoment";
import ModalDashboardContact from "../../layouts/partials/ModalDashboardContact";
import ModalDashboardPrintAWB from "../../layouts/partials/ModalDashboardPrintAWB";
import ModalChatCounter from '../../layouts/partials/ModalChatCounter';

/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';
import $ from 'jquery';
import Swal from 'sweetalert2';
const swalCustom = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-continues btn-block',
        cancelButton: 'btn btn-cancel btn-block"',
    },
    buttonsStyling: false,
});

var c_page = 0;
class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            search: {
                quote: '',
                from: '',
                cosignee: '',
                consignor: '',
                date: 30
            },
            isShowSearchBox: false,
            modalEditIsOpen: false,
            modalOneMomentIsOpen: false,
            modalContactIsOpen: false,
            modalPrintIsOpen: false,
            expanded: {},
            data: [],
            allData: [],
            rowPerPage: 10,
            loading: false,
            lastYear: 365,
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            consigneeSuggests: [],
            consignorSuggests: [],
            s_more: false,
            shipmentData: null,
            upadtedShipmentData: null,
            mailData: null,
            quotes: {},
            getQuotesData: null,
            disableEditStatus: ['decline', 'canceled', 'cancel', 'creating quote', 'booked'],
            selectedQuote: {},
            modalCounterIsOpen: false,
            itmChat: {},
            listCommodity: []
        };
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalContactIsOpen: false, modalPrintIsOpen: false, modalEditIsOpen: false, modalOneMomentIsOpen: false });
            }
        });
    }

    openContactModal = () => {
        this.setState({ modalContactIsOpen: true, modalEditIsOpen: false, modalOneMomentIsOpen: false, modalPrintIsOpen: false });
    }
    openEditModal = () => {
        this.setState({ modalEditIsOpen: true, modalContactIsOpen: false, modalOneMomentIsOpen: false, modalPrintIsOpen: false });
    }
    openOneMomentModal = () => {
        this.setState({ modalOneMomentIsOpen: true, modalContactIsOpen: false, modalEditIsOpen: false, modalPrintIsOpen: false });
    }
    openPrintModal = () => {
        this.setState({ modalOneMomentIsOpen: false, modalContactIsOpen: false, modalEditIsOpen: false, modalPrintIsOpen: true });
    }

    closeContactModal = () => {
        this.setState({ modalContactIsOpen: false });
    }
    closeEditModal = () => {
        this.setState({ modalEditIsOpen: false });
    }

    closeOneMomentModal = () => {
        this.setState({ modalOneMomentIsOpen: false });
    }
    closePrintModal = () => {
        this.setState({ modalPrintIsOpen: false });
    }

    getSecretKey = async (row) => {
        let quotesData = {};
        this.props.loadingActions.loadingChangeData(true);
        // var startDate = moment();
        // startDate.subtract(12, 'months');
        // var toDate = moment();
        // toDate.add(5, 'days');
        // const result = await this.props.quotesActions.getCprof({
        //     fromdate: startDate.format('DD/MM/YY'),
        //     todate: toDate.format('DD/MM/YY'),
        //     CPROF: row.CProfAU, SIM: 'N', userb: 0
        // });        
        // if (result.success && result.data.Success && result.data.cp.length) {
        //     quotesData = result.data.cp.find(item => item.CProfAU === row.CProfAU);
        // }
        // if (!quotesData) {
        //     return { success: false, secretKey: '', quoteID: '' };
        // }
        // console.log(row);
        // console.log(quotesData);
        let secretKey;
        quotesData = row.fullData;
        const quoteID = row.CProfAU;
        const propalsal = await this.props.quotesActions.getProposal(quoteID, this.props.auth.jwtToken);
        if (propalsal.success) {
            secretKey = propalsal.data.secretKey;
        } else {
            const { phone, enddate, userb } = quotesData;
            const proposalParams = {
                quoteID: `${quoteID}`,
                phone,
                expriseDate: enddate ? moment(enddate, 'DD/MM/YY HH:mm').format('YYYYMMDDHHmmss') : '',
                quoteData: JSON.stringify(quotesData),
                userb
            };
            const response = await this.props.quotesActions.createProposal(proposalParams, this.props.auth.jwtToken);
            if (!response.success) {
                this.setState({
                    isError: true,
                    errorMessage: MESSAGES.ERROR_CREATE_PROPOSAL_FAILED
                });

                this.props.loadingActions.loadingChangeData(false);
                return false;
            } else {
                secretKey = response.data.secretKey;
            }
        }
        this.props.loadingActions.loadingChangeData(false);
        // console.log(secretKey, quoteID, row);
        return { success: true, secretKey, quoteID };
    }

    handleExceedsLimitations = async (shipmentData) => {
        this.setState({ upadtedShipmentData: shipmentData });
        this.closeEditModal();
        this.openOneMomentModal();
    }

    handleAgreeLimited = async () => {
        let { upadtedShipmentData } = this.state;
        this.closeOneMomentModal();
        this.handleUpdateShipmentSuccess(upadtedShipmentData, true);
    }

    handleUpdateShipmentSuccess = async (shipmentData, changeStatus = false) => {
        var { selectedQuote } = this.state;
        var { auth } = this.props;
        this.props.loadingActions.loadingChangeData(true);

        if (changeStatus) {
            let secretKeyData = await this.getSecretKey(selectedQuote);
            if (secretKeyData.success) {
                const paramsAlert = {
                    status: MESSAGES.STATUS_CUSTOMER_CHANGE_SHIPMENT,
                    quoteId: selectedQuote.CProfAU,
                    secretKey: secretKeyData.secretKey,
                    createdByName: auth.user_name,
                    createdByDept: auth.custdes,
                    data: shipmentData
                }
                await this.props.negotiationActions.custAlert(paramsAlert);
                this.setState({ errorMessage: "Shipment details was sent to your sales person successfully.", isError: false });
            } else {
                this.setState({ errorMessage: "Shipment details sent to your sale person unsuccessfully.", isError: true });
            }
            this.props.loadingActions.loadingChangeData(false);
        } else {
            let getQuotesData = await this.props.quotesActions.buildQuoteData(null, shipmentData.quoteID, true);
            if (getQuotesData.success) {
                let quotes = getQuotesData.quotesData;
                let quoteData = await this.props.quotesActions.buildQuoteSaveData(quotes);

                let Dimensions = [];
                let uld = [];
                if (shipmentData.units_paltype === "pieces") {
                    for (let i = 0; i < shipmentData.units_list.length; i++) {
                        let un = shipmentData.units_list[i];
                        let item_length = un.Length !== '' ? parseFloat(un.Length).toFixed(2) : '';
                        let item_width = un.Width !== '' ? parseFloat(un.Width).toFixed(2) : '';
                        let item_height = un.Height !== '' ? parseFloat(un.Height).toFixed(2) : '';
                        let item_volume = un.Cbm !== '' ? parseFloat(un.Cbm).toFixed(2) : '';
                        let item_pieces = un.Pcs !== '' ? parseInt(un.Pcs) : '';
                        if (item_length !== '' || item_width !== '' || item_height !== '' || item_volume !== '') {
                            Dimensions.push({ "Height": item_height, "Width": item_width, "Length": item_length, "Pcs": item_pieces, "unitname": un.unitname, "Weight": item_volume, "paltype": un.item_uld ? un.item_uld.value : "" });
                        }
                    }
                } else {
                    for (let i = 0; i < shipmentData.units_list.length; i++) {
                        let un = shipmentData.units_list[i];
                        let item_pieces = un.Pcs !== '' ? parseInt(un.Pcs) : '';
                        let uldType = un.item_uld ? un.item_uld.value : null;
                        if (uldType && item_pieces !== '') {
                            uld.push({ "Pcs": parseInt(item_pieces), "paltype": uldType });
                        }
                    }
                }

                quoteData.Dimensions = Dimensions;
                quoteData.uld = uld;
                quoteData.cp.Weight = shipmentData.units_total_weight;
                quoteData.cp.CWeight = shipmentData.units_total_chargable_weight;
                quoteData.cp.Volume = shipmentData.units_total_volume;
                quoteData.cp.Pieces = shipmentData.units_total_num_of_pieces;

                if (shipmentData.consignee_company) {
                    quoteData.cp.Participents.CNEENAME = shipmentData.consignee_company.custname ? shipmentData.consignee_company.custname : null;
                } else {
                    quoteData.cp.Participents.CNEENAME = null;
                }
                if (shipmentData.consignor_company) {
                    quoteData.cp.Participents.CNORNAME = shipmentData.consignor_company.custname ? shipmentData.consignor_company.custname : null;
                } else {
                    quoteData.cp.Participents.CNORNAME = null;
                }
                let saveData = await this.props.quotesActions.setcprofbid(quoteData, this.props.auth.jwtToken);
                if (saveData.success) {
                    // let expriresTime = selectedQuote.enddate ? moment(selectedQuote.enddate, 'DD/MM/YYYY HH:mm') : moment().add(2, 'days');
                    // if (changeStatus) {
                    //     let currentDate = moment();
                    //     let statusData = {
                    //         "cporf": parseInt(shipmentData.quoteID),
                    //         "status": MESSAGES.STATUS_REVIVE,
                    //         "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
                    //         "statusres": quotes.apiData.statchangeres,
                    //         "userb": quotes.apiData.userb,
                    //         "dept": quotes.apiData.dept,
                    //         "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
                    //     };
                    //     await this.props.quotesActions.updateQuoteStatus(statusData, this.props.auth.jwtToken);
                    //     let secretKeyData = await this.getSecretKey(selectedQuote);
                    //     if (secretKeyData.success) {
                    //         const paramsAlert = {
                    //             status: 'Revive', // Approved, Declined
                    //             quoteId: selectedQuote.CProfAU,
                    //             secretKey: secretKeyData.secretKey
                    //         }
                    //         await this.props.negotiationActions.custAlert(paramsAlert);
                    //     }
                    // }

                    this.props.loadingActions.loadingChangeData(true);
                    this.setState({ errorMessage: "Update shippemnt successfully.", isError: false });
                    this.getData(true);
                } else {
                    this.props.loadingActions.loadingChangeData(false);
                    this.setState({ errorMessage: "Update shippemnt unsuccessfully.", isError: true });
                }
                this.props.loadingActions.loadingChangeData(false);
            } else {
                this.props.loadingActions.loadingChangeData(false);
                this.setState({ errorMessage: "Update shippemnt unsuccessfully.", isError: true });
            }

        }
    }

    handleClickContactPopup = async () => {
        var { selectedQuote } = this.state;
        // console.log(selectedQuote);
        let mailData = {
            order: selectedQuote.CProfAU,
            awb: '',
            FromPortCode: selectedQuote.fullData.FromPortCode ? selectedQuote.fullData.FromPortCode : '',
            FromCity: selectedQuote.fullData.FromCity ? selectedQuote.fullData.FromCity : '',
            FromCountryCode: selectedQuote.fullData.FromCountryCode ? selectedQuote.fullData.FromCountryCode : '',
            FromZipCode: selectedQuote.fullData.FromZipCode ? selectedQuote.fullData.FromZipCode : '',
            ToCity: selectedQuote.fullData.ToCity ? selectedQuote.fullData.ToCity : '',
            ToCountryCode: selectedQuote.fullData.ToCountryCode ? selectedQuote.fullData.ToCountryCode : '',
            ToPortCode: selectedQuote.fullData.ToPortCode ? selectedQuote.fullData.ToPortCode : '',
            ToZipCode: selectedQuote.fullData.ToZipCode ? selectedQuote.fullData.ToZipCode : '',
            ToCountryCode: selectedQuote.fullData.ToCountryCode ? selectedQuote.fullData.ToCountryCode : '',
            PhoneName: this.props.auth.user_name,
            PhoneEmail: this.props.auth.user_email,
            PhoneCompany: this.props.auth.custdes
        }
        this.setState({ mailData });
        this.closeOneMomentModal();
        this.openContactModal();
    }

    handlePrintPDF = async (row) => {
        this.setState({ errorMessage: '' });
        // console.log(row);
        this.props.loadingActions.loadingChangeData(true);
        let getQuotesData = await this.props.quotesActions.buildQuoteData(null, row.CProfAU, true);        
        if (!getQuotesData.success) {
            this.props.loadingActions.loadingChangeData(false);
            this.setState({
                isError: true,
                errorMessage: MESSAGES.ERROR_GET_DATA_FAILED
            });
            return;
        }

        const { quotesData } = getQuotesData;
        // console.log(quotesData);
        Pdf.exportBookingPDF(quotesData, 'cal-quote-', 'Your Quote #' + row.CProfNum, BookingHelper.formatMoney(quotesData.offer_total), quotesData.offer_currency ? quotesData.offer_currency.label : '');
        this.props.loadingActions.loadingChangeData(false);
    }

    handleOpenContact = async (row) => {
        this.setState({ errorMessage: '', selectedQuote: row });
        this.props.loadingActions.loadingChangeData(true);
        let secretKey = await this.getSecretKey(row);
        if (!secretKey.success) {
            this.props.loadingActions.loadingChangeData(false);
            this.setState({ isError: true, errorMessage: MESSAGES.ERROR_QUOTE_DATA_NOT_FOUND });
            return false;
        }
        this.props.history.push(`/quotes/negotiation/${secretKey.secretKey}/${secretKey.quoteID}`);
        this.props.loadingActions.loadingChangeData(false);
    }

    handleOpenEdit = async (row) => {
        this.setState({ errorMessage: '', selectedQuote: row, upadtedShipmentData: null });
        let { isError, errorMessage } = this.state;

        // console.log(row);
        var startDate = moment();
        startDate.subtract(12, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        this.props.loadingActions.loadingChangeData(true);
        const result = await this.props.quotesActions.getCprof({
            fromdate: startDate.format('DD/MM/YY'),
            todate: toDate.format('DD/MM/YY'),
            CPROF: row.CProfAU, SIM: 'N', userb: 0
        });
        this.props.loadingActions.loadingChangeData(false);
        let quotesData = {};
        if (result.success && result.data.Success && result.data.cp.length) {
            quotesData = result.data.cp.find(item => item.CProfAU === row.CProfAU);
        }
        // console.log("quotesData", quotesData);
        if (!quotesData || !quotesData.Participents) {
            this.props.loadingActions.loadingChangeData(false);
            this.setState({ isError: true, errorMessage: MESSAGES.ERROR_QUOTE_DATA_NOT_FOUND });
            return false;
        }

        // console.log(quotesData);
        let nowTime = moment();
        nowTime = nowTime.subtract(2, 'days');
        let expriresTime = moment(quotesData.enddate, 'DD/MM/YY HH:mm');
        expriresTime.add(59, 'seconds');
        this.props.loadingActions.loadingChangeData(true);
        let getCProfDetails = await this.props.quotesActions.getCProfDetails({ CPROF: row.CProfAU });
        this.props.loadingActions.loadingChangeData(false);

        // console.log('result', result);
        // console.log(getCProfDetails);
        if (getCProfDetails.success && getCProfDetails.data.Success) {

            if (getCProfDetails.data.leg && getCProfDetails.data.leg.length) {
                expriresTime = moment(getCProfDetails.data.leg[0].std, 'DD/MM/YY HH:mm');
                expriresTime.add(59, 'seconds');
            }

            let ebookingData = {
                quoteID: row.CProfAU,
                consigneeCompanyName: quotesData.Participents.CNEENAME,
                consignorCompanyName: quotesData.Participents.CNORNAME,
                units_paltype: 'pieces',
                units_type: 'Centimeters',
                units_type_short: 'CM',
                units_total_weight: quotesData.Weight,
                units_total_chargable_weight: quotesData.CWeight,
                units_list: [],
                units_total_num_of_pieces: quotesData.Pieces,
                units_total_volume: quotesData.Volume,
                overlap_shipment_check: false,
            };

            ebookingData.quoteData = row.fullData;

            if (getCProfDetails.data.Dimensions.length) {
                ebookingData.units_type_short = getCProfDetails.data.Dimensions[0].unitname;
                ebookingData.units_type = (ebookingData.units_type_short.toUpperCase() == 'CM') ? "Centimeters" : "Inches";
            }

            // // ebookingData.units_total_weight = parseFloat(row.pq.Weight);
            // ebookingData.units_total_chargable_weight = 0;
            let checkPaltype = false;
            getCProfDetails.data.Dimensions.map(dim => {
                checkPaltype = dim.paltype ? true : false;
                var unit_volume = parseFloat(dim.Cbm);
                if (dim.Height != "" && dim.Length != "" && dim.Width != "") {
                    unit_volume = BookingHelper.calculatorVolumeNew(ebookingData.units_type_short, parseFloat(dim.Length), parseFloat(dim.Width), parseFloat(dim.Height));
                }
                // ebooking.units_total_weight += parseFloat(dim.Weight);
                ebookingData.units_list.push({
                    'unitname': ebookingData.units_type_short.toUpperCase(),
                    'Pcs': parseInt(dim.Pcs),
                    'Height': parseFloat(dim.Height),
                    'Length': parseFloat(dim.Length),
                    'Width': parseFloat(dim.Width),
                    'Cbm': unit_volume.toFixed(3),
                    'paltype': dim.paltype
                });
            });

            if (checkPaltype) {
                ebookingData.units_paltype = 'uld';
            }

            // if (ebookingData.units_list.length == 0) {
            //     ebookingData.units_total_num_of_pieces = "";
            //     ebookingData.units_total_volume = "";
            //     ebookingData.units_total_weight = "";
            //     ebookingData.units_total_chargable_weight = "";
            // } else {
            //     var totalVolume = BookingHelper.calculatorVolumeKG(ebookingData.units_total_volume);
            //     var totalChargableWeight = Math.max(totalVolume, ebookingData.units_total_weight);
            //     ebookingData.units_total_chargable_weight = totalChargableWeight.toFixed(1);
            // }

            if (expriresTime && moment.isMoment(expriresTime) && expriresTime >= nowTime) {
                this.setState({ shipmentData: ebookingData });
                this.openEditModal();
            } else {
                isError = true;
                errorMessage = "Can't edit this Quote because expired";
                this.setState({ isError, errorMessage });
            }

        } else {
            isError = true;
            errorMessage = "Get AWB Data Unsuccess!";
            this.setState({ isError, errorMessage });
        }
    }

    handleClickQuoteNumber = async (row) => {
        var { history } = this.props;
        let status = row.status.trim();
        status = status.toUpperCase();
        if (status && AppConfig.acceptBookStatus.includes(status)) {
            await this.props.quotesActions.quotesReset();
            history.push('/quotes/flights/' + row.CProfAU);
        }
        return;
    }

    handleOpenMoment = (row) => {
        this.openOneMomentModal();
    }

    componentDidMount() {
        // var { lastYear } = this.state;
        // var currentDate = moment();
        // var startDate = moment();
        // startDate.set({ date: 1, month: 0 });
        // lastYear = currentDate.diff(startDate, 'days');
        // this.setState({ lastYear });
        this.setState({ search: this.props.dashboard.search });
        this.checkShowLoginForm();
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA" && !localStorage.getItem('DASHBOARD_REFRESH')) {
            this.convertDataFromRedux(true);
        } else if (this.props.auth.state == "LOGGED_IN") {
            this.getData(true);
        }
        this.onClickOutside();

        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.auth.state != "LOGGED_IN" && newProps.auth.state == "LOGGED_IN" && this.props.dashboard.state != "DASHBOARD_LOADED_DATA") {
            this.getData(this.state.isShowSearchBox);
        }

        if (oldProps.dashboard.state != "DASHBOARD_LOADED_DATA" && newProps.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    onClickOutside = () => {
        // this._calendar.setOpen(false);
        // this._calendar.cancelFocusInput();
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        const { search } = this.state;
        const { target: { name, value } } = event;
        search[name] = value;
        this.setState({ search });
        if (name == "date") {
            this.getData(this.state.isShowSearchBox);
        } else {
            if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
                this.convertDataFromRedux();
            }
        }
        await this.props.dashboardActions.updateFilter(search);
    }

    handleChangeDate = (v) => {
        var { search } = this.state;
        if (moment.isMoment(v)) {
            search.date = v;
        } else {
            search.date = null;
        }
        this.setState({ search });
    }

    handleOnBlurDate = (v) => {
        const { search, errors } = this.state;
        if (search.date && moment.isMoment(search.date)) {
            errors["date"] = "";
        }

        this.setState({ errors });
    }

    onChangeSugestCNEE = async (event, { newValue }) => {
        var { consigneeSuggests, search } = this.state;
        var selectedSuggessCNEE = { name: "" };
        selectedSuggessCNEE = consigneeSuggests.find(item => item.name === newValue);
        if (selectedSuggessCNEE) {
            search.cosignee = selectedSuggessCNEE.name;
        } else {
            search.cosignee = newValue;
        }
        this.setState({ search });
        await this.props.dashboardActions.updateFilter(search);
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    };

    onChangeSugestCNOR = async (event, { newValue }) => {
        var { consignorSuggests, search } = this.state;
        var selectedSuggessCNOR = { name: "" };
        selectedSuggessCNOR = consignorSuggests.find(item => item.name === newValue);
        if (selectedSuggessCNOR) {
            search.consignor = selectedSuggessCNOR.name;
        } else {
            search.consignor = newValue;
        }
        this.setState({ search });
        await this.props.dashboardActions.updateFilter(search);
        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    };

    getSuggestionsCNEE = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.consigneeSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionsCNOR = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.consignorSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    onSuggestionsClearRequestedCNEE = () => {
        this.setState({
            suggestionsCNEE: []
        });
    };

    onSuggestionsClearRequestedCNOR = () => {
        this.setState({
            suggestionsCNOR: []
        });
    };

    onSuggestionsFetchRequestedCNEE = ({ value }) => {
        this.setState({
            suggestionsCNEE: this.getSuggestionsCNEE(value)
        });
    };

    onSuggestionsFetchRequestedCNOR = ({ value }) => {
        this.setState({
            suggestionsCNOR: this.getSuggestionsCNOR(value)
        });
    };

    toogleSearchBox = async (newStatus) => {
        // this.setState({ isShowSearchBox: !this.state.isShowSearchBox });
        await this.props.dashboardActions.changeOpenFilter(newStatus);
        this.onClickOutside();
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            // window.parent.postMessage('show-login-form', '*');
            history.push("/login?back=" + base64.encode("/dashboard/quotes"));
        }
    }

    awbHandle = (row) => {
        console.log('awbHandle', row);
    }

    handleLocation = (row) => {
        console.log('handleLocation', row);
    }

    handleMore = () => {
        var { rowPerPage } = this.state;
        rowPerPage += 10;
        this.setState({ rowPerPage });
        window.loadTooltip();
        // this.buildData(this.state.allData);
    }

    /* Validate form */
    isValidation() {

        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }

        const { history } = this.props;
        // history.push('/ebooking-detail');
    }

    getData = (showSearchBox = true) => {
        localStorage.removeItem('DASHBOARD_REFRESH');
        var { allData, data, loading, search, rowPerPage, isShowSearchBox } = this.state;
        isShowSearchBox = showSearchBox;
        rowPerPage = 10;
        if (!loading) {
            allData = [];
            data = [];
            this.setState({ allData, data, loading: true, rowPerPage, isShowSearchBox });
            this.props.loadingActions.loadingChangeData(true);
            this.props.dashboardActions.getManagerPage({ days: search.date }, this.props.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
                this.setState({ loading: false });
            });
        }
    }

    convertDataFromRedux = (searchFromRedux = false) => {
        var { allData, data, loading, search, consignorSuggests, consigneeSuggests, isShowSearchBox } = this.state;
        if (searchFromRedux) {
            search = this.props.dashboard.search;
        }
        consignorSuggests = [];
        consigneeSuggests = [];
        allData = [];
        data = [];
        var reduxData = this.props.dashboard.data;
        // console.log('reduxData', reduxData);
        search.date = reduxData.days;
        var PQData = reduxData.PQ;
        if (!PQData || !PQData.length) {
            isShowSearchBox = true;
        }
        if (PQData) {
            for (let i = 0; i < PQData.length; i++) {
                var element = PQData[i];
                var checkCNee = consigneeSuggests.find(el => el.name == element.CNee);
                if (!checkCNee && element.CNee) {
                    consigneeSuggests.push({ name: element.CNee });
                }
                var checkCNOR = consignorSuggests.find(el => el.name == element.CNor);
                if (!checkCNOR && element.CNor) {
                    consignorSuggests.push({ name: element.CNor });
                }
            }
            if (search.quote) {
                PQData = PQData.filter(el => el.CProfNum.indexOf(search.quote.toUpperCase()) > -1);
            }

            if (search.from) {
                PQData = PQData.filter(el => {
                    if (el.FromPortCode) {
                        if (el.FromPortCode.indexOf(search.from.toUpperCase()) > -1) {
                            return el;
                        }
                    } else {
                        if (el.FromZipCode.indexOf(search.from.toUpperCase()) > -1) {
                            return el;
                        }
                    }
                });
            }

            // if (search.from) {
            //     PQData = PQData.filter(el => el.FromPortCode.indexOf(search.from.toUpperCase()) > -1);
            // }
            if (search.cosignee) {
                PQData = PQData.filter(el => el.CNee.indexOf(search.cosignee) > -1);
            }
            if (search.consignor) {
                PQData = PQData.filter(el => el.CNor.indexOf(search.consignor) > -1);
            }
        }

        var compairDate = BookingHelper.getDate("01/01/2000 00:00", "DD/MM/YYYY HH:mm");
        this.setState({ allData, data });
        if (PQData) {
            for (let i = 0; i < PQData.length; i++) {
                var element = PQData[i];
                var createDate = BookingHelper.getDate(element.PDate, "DD/MM/YYYY HH:mm");
                var percent = 0;
                if (element.ETDOrigin) {
                    var etdOriginDate = BookingHelper.getDate(element.ETDOrigin, "DD/MM/YYYY HH:mm");
                    if (moment.isMoment(etdOriginDate) && etdOriginDate > compairDate) percent = 100;
                }
                var row = {
                    CProfNum: element.CProfNum,
                    CProfAU: element.CProfAU,
                    PDate: element.PDate,
                    type: {
                        name: element.Commodity,
                        pieces: element.Pieces,
                        weight: element.Weight,
                        cweight: element.CWeight,
                        volume: element.Volume
                    },
                    flights: {
                        from: element.FromPortCode ? element.FromPortCode : element.FromZipCode,
                        to: element.ToPortCode ? element.ToPortCode : element.ToZipCode,
                        percent: element.ETDOrigin ? 100 : 0
                    },
                    consignee: { cnee: element.CNee, cnor: element.CNor },
                    consignor: { cnee: element.CNee, cnor: element.CNor },
                    creationDate: createDate.format("YYYYMMDD"),
                    fullData: element,
                    // creationDate: createDate.format("DD MMMM, YYYY"),
                    status: element.status,
                }
                allData.push(row);
            }
        }

        this.setState({ allData, consignorSuggests, consigneeSuggests, isShowSearchBox });
        this.buildData(allData, true);
    }

    buildData(allData, resetTable = false) {
        // this.setState({ errorMessage: '' });
        var { rowPerPage, data, s_more } = this.state;
        data = [];
        var startindex = 0;
        var endIndex = allData.length;
        for (let i = startindex; i < endIndex; i++) {
            data.push(allData[i]);
        }
        this.setState({ data });
        window.loadTooltip();
    }

    onSortedChange = () => {
        this.setState({ errorMessage: '' });
        var { rowPerPage } = this.state;
        rowPerPage = 10;
        this.setState({ rowPerPage });
        window.loadTooltip();
    }

    handleCompleteConfirm = async (data) => {
        this.setState({ errorMessage: '' });
        await this.setState({ selectedQuote: data });
        // swalCustom.fire({
        //     icon: 'warning',
        //     title: 'Confirm',
        //     html: `Are you sure complete booking this quote?`,
        //     showCloseButton: true,
        //     showCancelButton: 'Cancel',
        //     confirmButtonText: 'Confirm',
        //     reverseButtons: true
        // })
        //     .then((result) => {
        //         if (result.value) {
        //             this.handleComplete();
        //         }
        //     });
        // console.log('data', data);
        this.handleComplete();
    }

    handleComplete = async (data) => {
        const { selectedQuote } = this.state;
        // console.log('selectedQuote', selectedQuote);
        let secretKeyData = await this.getSecretKey(selectedQuote);
        if (secretKeyData.success) {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_SUCCESS });
            this.props.history.push(`/quotes/review/${secretKeyData.secretKey}/${secretKeyData.quoteID}`);
        } else {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_UNSUCCESS });
        }

        this.props.loadingActions.loadingChangeData(false);
    }

    handleReviveConfirm = (data) => {
        this.setState({ selectedQuote: data, errorMessage: '' });
        swalCustom.fire({
            icon: 'warning',
            title: 'Confirm',
            html: `Are you sure revive this quote?`,
            showCloseButton: true,
            showCancelButton: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    this.handleRevive();
                }
            });
    }

    handleRevive = async (data) => {
        const { selectedQuote } = this.state;
        let currentDate = moment();
        let expriresTime = selectedQuote.enddate ? moment(selectedQuote.enddate, 'DD/MM/YYYY HH:mm') : moment().add(2, 'days');
        let statusData = {
            "cporf": parseInt(selectedQuote.CProfAU),
            "status": MESSAGES.STATUS_REVIVE,
            "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
            "statusres": 'faceapp123',
            "userb": null,
            "dept": "IMP",
            "expirydate": expriresTime && moment.isMoment(expriresTime) ? expriresTime.format('DD/MM/YY') : ''
        };
        this.props.loadingActions.loadingChangeData(true);
        let saveQuotes = await this.props.quotesActions.updateQuoteStatus(statusData, this.props.auth.jwtToken);
        if (saveQuotes.success && saveQuotes.data.Success) {
            this.setState({ isError: false, errorMessage: MESSAGES.DO_ACCTION_SUCCESS });
            let secretKeyData = await this.getSecretKey(selectedQuote);
            if (secretKeyData.success) {
                const paramsAlert = {
                    status: 'Revive', // Approved, Declined
                    quoteId: selectedQuote.CProfAU,
                    secretKey: secretKeyData.secretKey
                }
                await this.props.negotiationActions.custAlert(paramsAlert);
            }
            this.getData();
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleAcceptConfirm = (data) => {
        this.setState({ selectedQuote: data, errorMessage: '' });
        swalCustom.fire({
            icon: 'warning',
            title: 'Confirm',
            html: `Are you sure accept item?`,
            showCloseButton: true,
            showCancelButton: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    this.handleAcceptAction();
                }
            });
    }

    createFlightData = (quotes, selectedFlight) => {
        let shipIcons, shipInfos;
        let dStd = selectedFlight
            ? moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm')
            : moment();
        let dSta = selectedFlight
            ? moment(
                selectedFlight.tleg.sta
                    ? selectedFlight.tleg.sta
                    : selectedFlight.fleg.sta,
                'DD/MM/YY HH:mm'
            )
            : moment();

        let pickupDropoffDate = quotes.ETDOrigin
            ? moment(quotes.ETDOrigin, 'DD/MM/YY HH:mm')
            : moment();

        var fromCalAirport = false;
        var toCalAirport = false;

        if (
            (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'PTD') &&
            quotes.deliver_from_airport &&
            quotes.deliver_from_airport.flightport == 'Y'
        ) {
            fromCalAirport = true;
        }
        if (
            (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'DTP') &&
            quotes.deliver_to_airport &&
            quotes.deliver_to_airport.flightport == 'Y'
        ) {
            toCalAirport = true;
        }

        if (fromCalAirport && toCalAirport) {
            let startDate = '';
            let startDateTime = '';
            let endDate = '';
            let endDateTime = '';
            if (selectedFlight) {
                startDate = dStd.isValid() ? dStd.format('MMMM Do') : '';
                if (startDate) {
                    startDateTime = dStd.isValid() ? dStd.format('HH:mm') : '';
                } else {
                    startDate = dStd.isValid() ? dStd.format('HH:mm') : '';
                }
            }

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('MMMM Do') : '';
                endDateTime = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            shipIcons = ['airplane'];
            shipInfos = [
                {
                    width: '30%',
                    label: quotes.FromPortCode,
                    date: startDate,
                    time: startDateTime,
                    align: 'left',
                    line: false,
                },
                {
                    width: '40%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '30%',
                    label: quotes.ToPortCode,
                    date: endDate,
                    time: endDateTime,
                    align: 'right',
                    line: false,
                },
            ];
        } else if (fromCalAirport && !toCalAirport) {
            let startDate = '';
            let startDateTime = '';
            let endDate = '';
            let endDateTime = '';
            if (selectedFlight) {
                startDate = dStd.isValid() ? dStd.format('MMMM Do') : '';
                if (startDate) {
                    startDateTime = dStd.isValid() ? dStd.format('HH:mm') : '';
                } else {
                    startDate = dStd.isValid() ? dStd.format('HH:mm') : '';
                }
            }

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('MMMM Do') : '';
                endDateTime = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            let endPortLabel = '';
            if (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'DTP') {
                endPortLabel =
                    'Airport ' +
                    (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '');
            } else {
                endPortLabel = 'Zip ' + (quotes.ToZipCode ? quotes.ToZipCode : '');
            }

            shipIcons = ['airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: quotes.FromPortCode,
                    date: startDate,
                    time: startDateTime,
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight
                        ? selectedFlight.tleg.leg
                            ? selectedFlight.tleg.tport
                            : selectedFlight.fleg.tport
                        : '',
                    date: selectedFlight && dSta.isValid() ? dSta.format('MMMM Do') : '',
                    time: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '40%',
                    label: endPortLabel,
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        } else if (!fromCalAirport && toCalAirport) {
            let startPortLabel = '';
            if (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'PTD') {
                startPortLabel =
                    'Airport ' +
                    (quotes.deliver_from_airport
                        ? quotes.deliver_from_airport.label
                        : '');
            } else {
                startPortLabel =
                    'Zip ' + (quotes.FromZipCode ? quotes.FromZipCode : '');
            }

            shipIcons = ['truck', 'airplane'];
            shipInfos = [
                {
                    width: '40%',
                    label: startPortLabel,
                    date: '',
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? selectedFlight.fleg.fport : '',
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : '',
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: quotes.ToPortCode,
                    date: selectedFlight && dSta.isValid() ? dSta.format('MMMM Do') : '',
                    time: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    align: 'right',
                    line: false,
                },
            ];
        } else {
            let startPortLabel = '';
            if (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'PTD') {
                startPortLabel =
                    'Airport ' +
                    (quotes.deliver_from_airport
                        ? quotes.deliver_from_airport.label
                        : '');
            } else {
                startPortLabel =
                    'Zip ' + (quotes.FromZipCode ? quotes.FromZipCode : '');
            }
            let endPortLabel = '';
            if (quotes.SaleTypeCode === 'PTP' || quotes.SaleTypeCode === 'DTP') {
                endPortLabel =
                    'Airport ' +
                    (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '');
            } else {
                endPortLabel = 'Zip ' + (quotes.ToZipCode ? quotes.ToZipCode : '');
            }
            shipIcons = ['truck', 'airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: startPortLabel,
                    date: '',
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? selectedFlight.fleg.fport : '',
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : '',
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight
                        ? selectedFlight.tleg.leg
                            ? selectedFlight.tleg.tport
                            : selectedFlight.fleg.tport
                        : '',
                    date: selectedFlight && dSta.isValid() ? dSta.format('MMMM Do') : '',
                    time: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: endPortLabel,
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        }
        return {
            shipIcons,
            shipInfos,
        };
    };

    handleAcceptAction = async () => {
        let { selectedQuote } = this.state;
        // console.log(`Acept Quote`, selectedQuote);
        // selectedQuote.CProfAU = '187039';
        this.props.loadingActions.loadingChangeData(true);
        // selectedQuote.CProfAU = 187039;
        var startDate = moment();
        startDate.subtract(12, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        const result = await this.props.quotesActions.getCprof({
            fromdate: startDate.format('DD/MM/YY'),
            todate: toDate.format('DD/MM/YY'),
            CPROF: selectedQuote.CProfAU, SIM: 'N', userb: 0
        });
        const getCProfDetails = await this.props.quotesActions.getCProfDetails({
            CPROF: selectedQuote.CProfAU
        });
        let chatItemData = {
            ChatData: {
                OfferData: [],
                TextData: ""
            }
        };

        let emailData = {};
        let sendTo = [];
        let sendCC = [];
        let unitTotal = {
            total_pieces: '',
            total_volume: '',
            total_weight: '',
            tcw: '',
            total_volume_kg: ''
        }

        this.props.loadingActions.loadingChangeData(false);
        // console.log(result);
        // console.log(getCProfDetails);
        let unitLength = { value: 'cm', label: 'Cm' };
        let offer_price_list = [];
        let offerData = [];
        let quotesData = null;
        if (result.success && result.data.Success && result.data.cp.length) {
            quotesData = result.data.cp.find(item => item.CProfAU + "" === selectedQuote.CProfAU + "");
            if (quotesData) {
                sendTo = [quotesData.contactemail];

                unitTotal = {
                    total_pieces: quotesData.Pieces,
                    total_volume: BookingHelper.roundOff3Decimal(quotesData.Volume, 3),
                    total_weight: BookingHelper.roundOff3Decimal(quotesData.Weight, 3),
                    tcw: quotesData.CWeight,
                    total_volume_kg: BookingHelper.calculatorVolumeKG(parseFloat(quotesData.Volume)),
                };

                let firstOfferCalculation = null;
                if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                    firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                    firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                } else if (quotesData.calcmethod.trim()) {
                    firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                }

                // var firstPrice = parseFloat(quotesData.Price);
                offer_price_list.push({ subcom: quotesData.SubCommodity, subcomdes: "", calc: firstOfferCalculation ? firstOfferCalculation.value : quotesData.calcmethod, curr: quotesData.currency, price: quotesData.Price, isUpdated: false });
                offerData.push({ item: quotesData.SubCommodity, method: firstOfferCalculation ? firstOfferCalculation.value : quotesData.calcmethod, currency: quotesData.currency, price: quotesData.Price });
            }
        }
        var selectedFlight = null;
        if (getCProfDetails.success && getCProfDetails.data.Success) {
            for (let n = 0; n < getCProfDetails.data.phone.length; n++) {
                const selectedPhone = getCProfDetails.data.phone[n];
                let checkExist = sendCC.find(
                    (contact) => contact === selectedPhone.email
                );
                if (!checkExist) {
                    sendCC.push(selectedPhone.email);
                }
            }

            if (!getCProfDetails.data.uld.length && getCProfDetails.data.Dimensions.length) {
                let unit = getCProfDetails.data.Dimensions[0];
                if (unit.unitname === 'CM') {
                    unitLength = { value: 'cm', label: 'Cm' };
                } else {
                    unitLength = { value: 'in', label: 'Inch' };
                }
            }

            for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                const cpElement = getCProfDetails.data.cp[i];
                let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                    methodSelected = { value: "Per Unit", label: "Per Unit" };
                } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                    methodSelected = { value: "Per ULD", label: "Per ULD" };
                }
                offer_price_list.push({ subcom: cpElement.subcom, subcomdes: "", calc: methodSelected ? methodSelected.value : cpElement.calc, curr: cpElement.curr, price: cpElement.price, isUpdated: false });
                offerData.push({ item: cpElement.subcom, method: methodSelected ? methodSelected.value : cpElement.calc, currency: cpElement.currency, price: cpElement.price })
            }

            if (getCProfDetails.data.leg.length) {
                selectedFlight = {
                    fleg: getCProfDetails.data.leg[0],
                    vialeg: {
                        leg: 0,
                        fnumber: null,
                        fport: null,
                        tport: null,
                        std: null,
                        sta: null,
                    },
                    tleg: {
                        leg: 0,
                        fnumber: null,
                        fport: null,
                        tport: null,
                        std: null,
                        sta: null,
                    },
                };
                if (getCProfDetails.data.leg.length == 2) {
                    selectedFlight.tleg = getCProfDetails.data.leg[1];
                }
                if (getCProfDetails.data.leg.length > 2) {
                    selectedFlight.vialeg = getCProfDetails.data.leg[2];
                }
            }

            //get flight data
            if (selectedFlight && quotesData) {
                var passData = {};
                var pickup_dropoff_time =
                    quotesData.ETDOrigin.trim() !== '' &&
                        quotesData.ETDOrigin.trim() !== '01/01/88 00:00'
                        ? moment(quotesData.ETDOrigin, 'DD/MM/YY HH:mm')
                        : null;

                var checkDataReady = true;
                if (pickup_dropoff_time) {
                    let toDate = clone(pickup_dropoff_time);
                    toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                    passData.fromdate = pickup_dropoff_time.format('DD/MM/YY');
                    passData.todate = toDate.format('DD/MM/YY');
                } else {
                    checkDataReady = false;
                }

                if (
                    quotesData.SaleTypeCode === 'PTP' ||
                    quotesData.SaleTypeCode === 'PTD'
                ) {
                    if (!quotesData.FromPortCode) {
                        checkDataReady = false;
                    } else {
                        passData.fromport = quotesData.FromPortCode;
                        passData.fcountry = quotesData.FromCountryCode;
                    }
                } else {
                    if (quotesData.FromCountryCode && quotesData.FromZipCode) {
                        passData.fromport = quotesData.FromZipCode;
                        passData.fcountry = quotesData.FromCountryCode;
                    } else {
                        checkDataReady = false;
                    }
                }

                if (
                    quotesData.SaleTypeCode === 'PTP' ||
                    quotesData.SaleTypeCode === 'DTP'
                ) {
                    if (!quotesData.FromPortCode) {
                        checkDataReady = false;
                    } else {
                        passData.toport = quotesData.ToPortCode;
                        passData.tcountry = quotesData.ToCountryCode;
                    }
                } else {
                    if (quotesData.ToCountryCode && quotesData.ToZipCode) {
                        passData.toport = quotesData.ToZipCode;
                        passData.tcountry = quotesData.ToCountryCode;
                    } else {
                        checkDataReady = false;
                    }
                }

                if (checkDataReady) {
                    var getFlightData = await this.props.quotesActions.getBibFlights(
                        passData
                    );
                    if (getFlightData.success && getFlightData.data.Success) {
                        var list_flight = getFlightData.data.fl;
                        if (list_flight.length) {
                            for (let i = 0; i < list_flight.length; i++) {
                                const flightData = list_flight[i];
                                if (
                                    selectedFlight.fleg.leg === flightData.fleg.leg &&
                                    selectedFlight.tleg.leg === flightData.tleg.leg &&
                                    selectedFlight.vialeg.leg === flightData.vialeg.leg
                                ) {
                                    selectedFlight = flightData;
                                }
                            }
                        }
                    }
                }


                quotesData.deliver_from_airport = null;
                quotesData.deliver_to_airport = null;
                if (this.props.port.ports.length) {
                    if (quotesData.FromPortCode.trim()) {
                        var findAirportFrom = this.props.port.ports.find(
                            (el) => el.value === quotesData.FromPortCode.trim()
                        );
                        if (findAirportFrom) {
                            quotesData.deliver_from_airport = findAirportFrom;
                        }
                    }
                    if (quotesData.ToPortCode.trim()) {
                        var findAirportTo = this.props.port.ports.find(
                            (el) => el.value === quotesData.ToPortCode.trim()
                        );
                        if (findAirportTo) {
                            quotesData.deliver_to_airport = findAirportTo;
                        }
                    }
                }
            }
        }
        chatItemData.ChatData.OfferData = offer_price_list;

        // console.log('quotesData', quotesData);
        // console.log('selectedFlight', selectedFlight);
        let flightData = this.createFlightData(quotesData, selectedFlight);

        // console.log('flightData', flightData);

        if (quotesData) {
            emailData = {
                mailTitle: 'Your quote is approved #' + quotesData.CProfNum,
                pdfTitle: "Your quote is approved #" + quotesData.CProfNum,
                titleText: "Your quote is approved #" + quotesData.CProfNum,
                CProfNum: quotesData.CProfNum,
                companyName: this.props.auth.custname,
                name: this.props.auth.user_name,
                shipIcons: flightData.shipIcons,
                shipInfos: flightData.shipInfos,
                totalPieces: quotesData.Pieces,
                totalVolumn: BookingHelper.roundOff3Decimal(quotesData.Volume, 3),
                totalWeight: BookingHelper.roundOff3Decimal(quotesData.Weight, 3),
                TCW: BookingHelper.roundOff3Decimal(quotesData.CWeight, 3),
                Unit: unitLength.label,
                result: offerData,
                totalPrice: BookingHelper.formatMoney(0),
                totalPriceType: 'Total',
                Commodity: quotesData.Commodity,
                SubCommodity: quotesData.SubCommodity,
                PersionMessage: (getCProfDetails.data.quoteTempData && getCProfDetails.data.quoteTempData.PersionMessage) ? getCProfDetails.data.quoteTempData.PersionMessage : '',
                DeclineUrl: '',
                AcceptUrl: '',
                RespondUrl: '',
                HourExpires: '',
                DetailsExpires: '',
                DetailsHourExpires: '',
                OfferCurrency: quotesData.currency,
                TermUrl: '',
                step_img: flightData.shipIcons.length + 'step',
                CProfAU: quotesData.CProfAU,
                ConfirmUrl: '',
                emails: sendTo,
                emailsCC: sendCC,
            };
        } else {
            emailData = {
                mailTitle: 'Your quote is approved #' + selectedQuote.CProfNum,
                pdfTitle: "Your quote is approved #" + selectedQuote.CProfNum,
                titleText: "Your quote is approved #" + selectedQuote.CProfNum,
                CProfNum: selectedQuote.CProfNum,
                companyName: this.props.auth.custname,
                name: this.props.auth.user_name,
                shipIcons: [],
                shipInfos: [],
                totalPieces: '',
                totalVolumn: '',
                totalWeight: '',
                TCW: '',
                Unit: '',
                result: [],
                totalPrice: BookingHelper.formatMoney(0),
                totalPriceType: 'Total',
                Commodity: '',
                SubCommodity: '',
                PersionMessage: '',
                DeclineUrl: '',
                AcceptUrl: '',
                RespondUrl: '',
                HourExpires: '',
                DetailsExpires: '',
                DetailsHourExpires: '',
                OfferCurrency: '',
                TermUrl: '',
                step_img: '1step',
                CProfAU: selectedQuote.CProfAU,
                ConfirmUrl: '',
                emails: [],
                emailsCC: [],
            };
        }

        let saveQuotes = await this.props.quotesActions.acceptQuote({ quoteID: selectedQuote.CProfAU, CProfNum: selectedQuote.CProfNum, companyName: this.props.auth.custdes, name: this.props.auth.user_name, chatItemData, emailData }, this.props.auth.jwtToken);
        if (saveQuotes.success && saveQuotes.data.Success) {
            this.setState({ isError: false, errorMessage: MESSAGES.DO_ACCTION_SUCCESS });
            const paramsAlert = {
                status: 'Approved', // Approved, Declined
                quoteId: selectedQuote.CProfAU,
                secretKey: saveQuotes.data.secretKey
            }
            await this.props.negotiationActions.custAlert(paramsAlert);
            this.getData();
        } else {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_UNSUCCESS });
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleDeclineConfirm = (data) => {
        this.setState({ selectedQuote: data, errorMessage: '' });
        swalCustom.fire({
            icon: 'warning',
            title: 'Confirm',
            html: `Are you sure decline this Quotes?`,
            showCloseButton: true,
            showCancelButton: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    this.handleDeclineAction();
                }
            });
    }

    handleDeclineAction = async () => {
        const { selectedQuote } = this.state;
        // console.log(`Decline Quote`, selectedQuote);
        this.props.loadingActions.loadingChangeData(true);
        // selectedQuote.CProfAU = 187039;
        var startDate = moment();
        startDate.subtract(12, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        const result = await this.props.quotesActions.getCprof({
            fromdate: startDate.format('DD/MM/YY'),
            todate: toDate.format('DD/MM/YY'),
            CPROF: selectedQuote.CProfAU, SIM: 'N', userb: 0
        });
        const getCProfDetails = await this.props.quotesActions.getCProfDetails({
            CPROF: selectedQuote.CProfAU
        });
        let chatItemData = {
            ChatData: {
                OfferData: [],
                TextData: ""
            }
        };

        let offer_price_list = [];
        if (result.success && result.data.Success && result.data.cp.length) {
            let quotesData = result.data.cp.find(item => item.CProfAU + "" === selectedQuote.CProfAU + "");
            if (quotesData) {
                let firstOfferCalculation = null;
                if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                    firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                    firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                } else if (quotesData.calcmethod.trim()) {
                    firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                }

                // var firstPrice = parseFloat(quotesData.Price);
                offer_price_list.push({ subcom: quotesData.SubCommodity, subcomdes: "", calc: firstOfferCalculation ? firstOfferCalculation.value : quotesData.calcmethod, curr: quotesData.currency, price: quotesData.Price, isUpdated: false });
            }
        }
        if (getCProfDetails.success && getCProfDetails.data.Success) {
            for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                const cpElement = getCProfDetails.data.cp[i];
                let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                    methodSelected = { value: "Per Unit", label: "Per Unit" };
                } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                    methodSelected = { value: "Per ULD", label: "Per ULD" };
                }
                offer_price_list.push({ subcom: cpElement.subcom, subcomdes: "", calc: methodSelected ? methodSelected.value : cpElement.calc, curr: cpElement.curr, price: cpElement.price, isUpdated: false });
            }
        }
        chatItemData.ChatData.OfferData = offer_price_list;
        let saveQuotes = await this.props.quotesActions.declineQuote({ quoteID: selectedQuote.CProfAU, CProfNum: selectedQuote.CProfNum, companyName: this.props.auth.custdes, name: this.props.auth.user_name, chatItemData }, this.props.auth.jwtToken);
        if (saveQuotes.success && saveQuotes.data.Success) {
            // console.log('saveQuotes', saveQuotes);
            this.setState({ isError: false, errorMessage: MESSAGES.DO_ACCTION_SUCCESS });
            const paramsAlert = {
                status: 'Declined', // Approved, 
                quoteId: selectedQuote.CProfAU,
                secretKey: saveQuotes.data.secretKey
            }
            await this.props.negotiationActions.custAlert(paramsAlert);
            this.getData();
        } else {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_UNSUCCESS });
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleCounterForm = async (data) => {
        this.setState({ selectedQuote: data, errorMessage: '' });
        // data.CProfAU = 187039;
        var startDate = moment();
        startDate.subtract(12, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        this.props.loadingActions.loadingChangeData(true);
        const result = await this.props.quotesActions.getCprof({
            fromdate: startDate.format('DD/MM/YY'),
            todate: toDate.format('DD/MM/YY'),
            CPROF: data.CProfAU, SIM: 'N', userb: 0
        });
        const getCProfDetails = await this.props.quotesActions.getCProfDetails({
            CPROF: data.CProfAU
        });
        let chatItemData = {
            ChatData: {
                OfferData: []
            },
            TextData: ""
        };

        let offer_price_list = [];
        if (result.success && result.data.Success && result.data.cp.length) {
            let quotesData = result.data.cp.find(item => item.CProfAU + "" === data.CProfAU + "");
            if (quotesData) {
                let firstOfferCalculation = null;
                if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                    firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                    firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                } else if (quotesData.calcmethod.trim()) {
                    firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                }
                offer_price_list.push({ subcom: quotesData.SubCommodity, subcomdes: "", calc: firstOfferCalculation ? firstOfferCalculation.value : quotesData.calcmethod, curr: quotesData.currency, price: quotesData.Price, isUpdated: false });
            }
        }
        if (getCProfDetails.success && getCProfDetails.data.Success) {
            for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                const cpElement = getCProfDetails.data.cp[i];
                let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);

                if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                    methodSelected = { value: "Per Unit", label: "Per Unit" };
                } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                    methodSelected = { value: "Per ULD", label: "Per ULD" };
                }
                offer_price_list.push({ subcom: cpElement.subcom, subcomdes: "", calc: methodSelected ? methodSelected.value : cpElement.calc, curr: cpElement.curr, price: cpElement.price, isUpdated: false });
            }
        }
        if (offer_price_list.length) {
            chatItemData.ChatData.OfferData = offer_price_list;
            // console.log(chatItemData);
            this.setState({
                itmChat: chatItemData,
                modalCounterIsOpen: true
            });
        } else {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_UNSUCCESS });
        }

        this.props.loadingActions.loadingChangeData(false);
    }

    handleCounter = async (message, currentChat) => {
        const { selectedQuote } = this.state;
        // selectedQuote.CProfAU = 187039;
        this.setState({ modalCounterIsOpen: false });
        this.props.loadingActions.loadingChangeData(true);
        var startDate = moment();
        startDate.subtract(12, 'months');
        var toDate = moment();
        toDate.add(5, 'days');
        const result = await this.props.quotesActions.getCprof({
            fromdate: startDate.format('DD/MM/YY'),
            todate: toDate.format('DD/MM/YY'),
            CPROF: selectedQuote.CProfAU, SIM: 'N', userb: 0
        });
        const getCProfDetails = await this.props.quotesActions.getCProfDetails({
            CPROF: selectedQuote.CProfAU
        });
        let chatItemData = {
            ChatData: {
                OfferData: [],
                TextData: ""
            }
        };

        let offer_price_list = [];
        if (result.success && result.data.Success && result.data.cp.length) {
            let quotesData = result.data.cp.find(item => item.CProfAU + "" === selectedQuote.CProfAU + "");
            if (quotesData) {
                let firstOfferCalculation = null;
                if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                    firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                    firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                } else if (quotesData.calcmethod.trim()) {
                    firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                }

                // var firstPrice = parseFloat(quotesData.Price);
                offer_price_list.push({ subcom: quotesData.SubCommodity, subcomdes: "", calc: firstOfferCalculation ? firstOfferCalculation.value : quotesData.calcmethod, curr: quotesData.currency, price: quotesData.Price, isUpdated: false });
            }
        }
        if (getCProfDetails.success && getCProfDetails.data.Success) {
            for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                const cpElement = getCProfDetails.data.cp[i];
                let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                    methodSelected = { value: "Per Unit", label: "Per Unit" };
                } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                    methodSelected = { value: "Per ULD", label: "Per ULD" };
                }
                offer_price_list.push({ subcom: cpElement.subcom, subcomdes: "", calc: methodSelected ? methodSelected.value : cpElement.calc, curr: cpElement.curr, price: cpElement.price, isUpdated: false });
            }
        }
        chatItemData.ChatData.OfferData = offer_price_list;

        let saveQuotes = await this.props.quotesActions.counterQuote({ quoteID: selectedQuote.CProfAU, CProfNum: selectedQuote.CProfNum, companyName: this.props.auth.custdes, name: this.props.auth.user_name, chatItemData, message, chatData: currentChat }, this.props.auth.jwtToken);
        // console.log('saveQuotes', saveQuotes);
        if (saveQuotes.success && saveQuotes.data.Success) {
            // console.log('saveQuotes', saveQuotes);
            this.setState({ isError: false, errorMessage: MESSAGES.DO_ACCTION_SUCCESS });
            const paramsAlert = {
                status: 'Countered', // Approved, Declined
                quoteId: selectedQuote.CProfAU,
                secretKey: saveQuotes.data.secretKey
            }
            await this.props.negotiationActions.custAlert(paramsAlert);
            this.getData();
        } else {
            this.setState({ isError: true, errorMessage: MESSAGES.DO_ACCTION_UNSUCCESS });
        }
        this.props.loadingActions.loadingChangeData(false);
    }


    checkQuoteExpired = (enddate) => {
        let nowTime = moment();
        nowTime = nowTime.subtract(2, 'days');

        let expriresTime = null;
        // console.log("enddate", enddate);
        if (enddate) {
            expriresTime = moment(enddate, 'DD/MM/YY HH:mm');
            expriresTime.add(59, 'seconds');
        }

        let checkExpired = false;
        if (expriresTime && moment.isMoment(expriresTime) && expriresTime >= nowTime) {
            checkExpired = true;
        }
        return checkExpired;
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        const { search, isError, errors, errorMessage, data, allData, lastYear, suggestionsCNEE, suggestionsCNOR,
            rowPerPage, s_more, modalOneMomentIsOpen, modalEditIsOpen, modalCounterIsOpen, itmChat,
            modalContactIsOpen, modalPrintIsOpen, mailData, shipmentData, getQuotesData } = this.state;
        let random = Math.floor((Math.random() * 10) + 1);

        var inputPropsCNEE = {
            placeholder: '',
            value: search.cosignee ? search.cosignee : "",
            onChange: this.onChangeSugestCNEE
        };
        var inputPropsCNOR = {
            placeholder: '',
            value: search.consignor ? search.consignor : "",
            onChange: this.onChangeSugestCNOR
        };

        const columns = [
            {
                Header: 'Quote #',
                accessor: 'CProfNum',
                width: 145,
                headerClassName: 'thAWB',
                className: 'tdAWB',
                Cell: row => {
                    let checkAllow = true;
                    let status = row.original.status.trim();
                    status = status.toUpperCase();
                    if (!status || !AppConfig.acceptBookStatus.includes(status)) {
                        checkAllow = false;
                    }
                    return (
                        <div>
                            <div className={`td-line ${random} ${random % 2 === 0 ? 'line-orange' : 'line-orange'}`}></div>
                            {/* {row.original.pq && row.original.pq.changeallowed ? ( */}
                            <a className={`${checkAllow ? 'has-underline' : 'no-underline cursor-text'}`} href="javascript:void(0)" onClick={() => this.handleClickQuoteNumber(row.original)}>{row.value}</a>
                            {/* ) : ( */}
                            {/* <a className="no-underline cursor-text" href="javascript:void(0)">{row.value}</a> */}
                            {/* )} */}

                        </div>
                    )
                }
            },
            {
                Header: 'Commodity',
                accessor: 'type',
                sortMethod: (a, b) => {
                    if (a.name === b.name) {
                        return a.name > b.name ? 1 : -1;
                    }
                    return a.name > b.name ? 1 : -1;
                },
                width: 130,
                headerClassName: 'thType',
                className: 'tdType',
                Cell: props => <div className='tdType'>
                    <PopperItem data={props.row.type}></PopperItem>
                </div>
            },
            {
                Header: 'From / To',
                accessor: 'flights',
                sortMethod: (a, b) => {
                    if (a.from === b.from) {
                        return a.from > b.from ? 1 : -1;
                    }
                    return a.from > b.from ? 1 : -1;
                },
                width: 270,
                headerClassName: 'thFlights',
                className: 'tdFlights',
                Cell: props => <div className='flights'>
                    <div className={`f-start ${props.row.flights.from.length > 4 ? "small-text" : ""}`}>{props.row.flights.from}</div>
                    <div className="f-progress">
                        <div className="dot-from"></div>
                        <div className="dot-currrent" style={{ left: props.row.flights.percent + '%' }}></div>
                        <div className="dot-to"></div>
                    </div>
                    <div className={`f-end ${props.row.flights.to.length > 4 ? "small-text" : ""}`}>{props.row.flights.to}</div>
                </div>
            },
            {
                Header: 'Consignee',
                accessor: 'consignee',
                sortMethod: (a, b) => {
                    if (a.cnee === b.cnee) {
                        return a.cnee > b.cnee ? 1 : -1;
                    }
                    return a.cnee > b.cnee ? 1 : -1;
                },
                width: 135,
                headerClassName: 'thConsignee',
                className: 'td-consignee',
                Cell: props => <div className='flights'
                >
                    <PopperItemConsignee show={'consignee'} data={props.row.consignee}></PopperItemConsignee>
                </div>
            },
            {
                Header: 'Consignor',
                accessor: 'consignor',
                sortMethod: (a, b) => {
                    if (a.cnor === b.cnor) {
                        return a.cnor > b.cnor ? 1 : -1;
                    }
                    return a.cnor > b.cnor ? 1 : -1;
                },
                width: 135,
                headerClassName: 'thConsignor',
                className: 'td-consignor',
                Cell: props => <div className='flights'>
                    <PopperItemConsignee show={'cnor'} data={props.row.consignor}></PopperItemConsignee>
                </div>
            },
            {
                Header: 'Creation Date',
                accessor: 'creationDate',
                width: 140,
                headerClassName: 'thCreationDate',
                className: 'tdCreationDate',
                Cell: props => <div>
                    {BookingHelper.getDate(props.row.creationDate, "YYYYMMDD").format("DD MMMM, YYYY")}
                </div>
            },
            {
                Header: 'Status',
                accessor: 'status',
                // width: 125,
                sortable: false,
                headerClassName: 'thStatus',
                className: 'tdStatus',
                Cell: row => (
                    <div className="lb-status">{row.value}</div>
                ),
                // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
            },
            {
                Header: '',
                accessor: 'duplicate',
                width: 150,
                sortable: false,
                headerClassName: 'thDuplicate',
                className: 'tdDuplicate text-left',
                Cell: row => {
                    let checkAllow = true;
                    let status = row.original.status.trim();
                    status = status.toLowerCase();
                    if (!status || AppConfig.disableEditStatus.includes(status)) {
                        checkAllow = false;
                    }

                    let checkExpired = false;
                    if (row.original.fullData && row.original.fullData.enddate) {
                        checkExpired = this.checkQuoteExpired(row.original.fullData.enddate);
                    }

                    // console.log("checkExpired", checkExpired);
                    return (
                        <div>
                            <button type="button" onClick={() => this.handlePrintPDF(row.original)} className="btn-tools btn-pdf"></button>
                            <button type="button" onClick={() => this.handleOpenContact(row.original)} className="btn-tools btn-chat"></button>
                            {checkAllow && checkExpired ? (
                                <button type="button" onClick={() => this.handleOpenEdit(row.original)} className="btn-tools btn-edit"></button>
                            ) : (
                                <button type="button" className="btn-tools btn-edit disabled"></button>
                            )}
                        </div>
                    )
                }
                // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
            }
        ];

        let expanded = {};
        expanded = data.map((item, index) => Object.assign(expanded, { index: true }));
        const SubComponent = (row) => {
            let checkAllow = true;
            let status = row.original.status.trim();
            status = status.toLowerCase();
            let statusUpper = status.toUpperCase();

            if (!status || AppConfig.disableEditStatus.includes(status)) {
                checkAllow = false;
            }

            let checkExpired = false;
            if (row.original.fullData && row.original.fullData.enddate) {
                checkExpired = this.checkQuoteExpired(row.original.fullData.enddate);
            }
            return (
                <>
                    <div className={`sub-row-mobile ${random} ${random % 2 === 0 ? 'trOrange' : 'trBlue'}`}>
                        <div className="rt-summary">
                            <span>Consignee: </span> {row.original.consignee.cnee}<br />
                            <span>Consignor: </span> {row.original.consignor.cnor}
                        </div>
                        <div className="rt-footer">
                            <div className='flights'>
                                <div className="f-start">{row.original.flights.from}</div>
                                <div className="f-progress">
                                    <div className="dot-from"></div>
                                    <div className="dot-currrent" style={{ left: row.original.flights.percent + '%' }}></div>
                                    <div className="dot-to"></div>
                                </div>
                                <div className="f-end">{row.original.flights.to}</div>
                            </div>
                            <div className="sub-btn-group">
                                <div className="lb-status">{row.original.status}</div>
                                <button type="button" onClick={() => this.handlePrintPDF(row.original)} className="btn-tools btn-pdf"></button>
                                <button type="button" onClick={() => this.handleOpenContact(row.original)} className="btn-tools btn-chat"></button>
                                {checkAllow && checkExpired ? (
                                    <button type="button" onClick={() => this.handleOpenEdit(row.original)} className="btn-tools btn-edit"></button>
                                ) : (
                                    <button type="button" className="btn-tools btn-edit disabled"></button>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* {(((status === 'DECLINE' || status === 'DECLINE BY CUST') && !expired) || status === 'APPROVED' || status === 'APPROVED BY USER' || status === 'APPROVED BY CUST' || status === 'SENT' || status === 'SENT TO CUST' || status === 'READY FOR REVIEW' || status === 'CUST NEGOTIATION' || status === 'IN NEGOTIATION' || status === 'USER NEGOTIATION' || status === 'REVIVE QUOTE' || status === 'REVIVE') ? ( */}
                    {(['DECLINE', 'DECLINE BY CUST'].includes(statusUpper) && checkExpired) ||
                        [
                            'APPROVED',
                            'APPROVED BY AGENT',
                            'APPROVED BY CUSTOMER',
                            'READY FOR REVIEW',
                            'CUST NEGOTIATION',
                            'IN NEGOTIATION',
                            'USER NEGOTIATION',
                        ].includes(statusUpper) ? (
                        <div className="sub-btn-group">
                            {(statusUpper === 'DECLINE' || statusUpper === 'DECLINE BY CUST') ? (
                                <button type="button" onClick={() => this.handleReviveConfirm(row.original)}>Revive</button>
                            ) : (
                                <>
                                    {
                                        (AppConfig.acceptBookStatus.includes(statusUpper)) ? (
                                            <button type="button" onClick={() => this.handleCompleteConfirm(row.original)}>Complete Booking</button>
                                        ) : (
                                            <>
                                                <button type="button" onClick={() => this.handleAcceptConfirm(row.original)}>Accept</button>
                                                <button type="button" onClick={() => this.handleDeclineConfirm(row.original)}>Decline</button>
                                                <button type="button" onClick={() => this.handleCounterForm(row.original)}>Counter</button>
                                            </>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    ) : null}
                </>
            );
        }
        const { isShowSearchBox } = this.state;
        var { openFilter } = this.props.dashboard;
        return (
            <div className="page-content my-quotes">
                <Nav index={2} />
                <div className="container cal-form">
                    <div className="search-area">
                        <form autoComplete="off">
                            <div className="s-title text-center">All records are in local time</div>
                            <button type="button" className={`btn-search ${openFilter ? 'active' : ''}`} onClick={() => { this.toogleSearchBox(true) }}>Search</button>

                            <div className={`form-row my-account-search filter-box ${(openFilter) ? '' : ' s-hidden'}`}>
                                <button type="button" className="close-filter" onClick={() => { this.toogleSearchBox(false) }}></button>
                                <div className="form-group group-1">
                                    <label>Quote #</label>
                                    <input type="text" className="form-control" name="quote" onChange={this.handleChange} value={search.quote} />
                                    <InputError error={errors.quote} />
                                </div>
                                <div className="form-group group-2">
                                    <label>From</label>
                                    <input type="text" className="form-control" name="from" onChange={this.handleChange} value={search.from} />
                                    <InputError error={errors.from} />
                                </div>
                                <div className="form-group group-3 dashboard-suggest">
                                    <label>Consignee</label>
                                    <Autosuggest
                                        className={`form-control`}
                                        suggestions={suggestionsCNEE}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNEE}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNEE}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputPropsCNEE}
                                    />
                                    <InputError error={errors.cosignee} />
                                </div>
                                <div className="form-group group-4 dashboard-suggest">
                                    <label>Consignor</label>
                                    <Autosuggest
                                        className={`form-control`}
                                        suggestions={suggestionsCNOR}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNOR}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNOR}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputPropsCNOR}
                                    />
                                    <InputError error={errors.consignor} />
                                </div>
                                <div className="form-group group-5 pr-0 myaccount">
                                    <label>Recent Activity</label>
                                    <select className="form-control" name="date" value={search.date} onChange={this.handleChange} >
                                        <option value="30">30 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="180">6 Months</option>
                                        <option value={lastYear}>1 Year</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className="mb-3">
                        <span>Showing {data.length ? (rowPerPage > data.length ? data.length : rowPerPage) : 0}/{data.length} records</span>
                        <span className="ml-3"><button type="button" className="btn btn-secondary btn-sm btn-refresh" onClick={this.getData.bind(this, this.state.isShowSearchBox)}>Refresh</button></span>
                    </div>
                    <div className={`account-table fixed-footer ${data.length ? '' : 'd-none'}`}>
                        <ReactTable
                            data={data}
                            columns={columns}
                            showPagination={false}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            minRows={0}
                            defaultPageSize={rowPerPage}
                            pageSize={rowPerPage}
                            defaultSorted={[{
                                id: 'creationDate',
                                desc: true,
                            }]}
                            resizable={false}
                            expanded={expanded}
                            SubComponent={SubComponent}
                            onSortedChange={this.onSortedChange}
                        />
                    </div>
                    <div className={`text-center div-load-more ${rowPerPage < data.length ? '' : 'd-none'}`}>
                        <button type="button" className="btn btn-more" onClick={this.handleMore}>More</button>
                    </div>
                    <div className={`text-center no-row ${data.length ? 'd-none' : ''}`}>No records found</div>
                </div>
                <Modal
                    isOpen={modalContactIsOpen}
                    onRequestClose={this.closeContactModal}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardContact
                        isQuote
                        mailData={mailData}
                        onShow={modalContactIsOpen}
                        closeModal={this.closeContactModal}
                        onHide={this.closeContactModal}
                        setMsg={(msgObj) => this.setState(msgObj)}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalCounterIsOpen}
                    itmChat={itmChat}
                    onRequestClose={() => this.setState({ modalCounterIsOpen: false })}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalChatCounter
                        onShow={modalCounterIsOpen}
                        itmChat={itmChat}
                        closeModal={() => this.setState({ modalCounterIsOpen: false })}
                        handleCounter={this.handleCounter}
                        onHide={() => this.setState({ modalCounterIsOpen: false })}
                    />
                </Modal>
                <Modal
                    isOpen={modalOneMomentIsOpen}
                    onRequestClose={this.closeOneMomentModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardOneMoment
                        onShow={modalOneMomentIsOpen}
                        handleClickCommit={this.handleAgreeLimited}
                        closeModal={this.closeOneMomentModal}
                        onHide={this.closeOneMomentModal}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalEditIsOpen}
                    onRequestClose={this.closeEditModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardUpdateQuote
                        handleExceedsLimitations={this.handleExceedsLimitations}
                        handleUpdateShipmentSuccess={this.handleUpdateShipmentSuccess}
                        shipmentData={shipmentData}
                        onShow={modalEditIsOpen}
                        closeModal={this.closeEditModal}
                        onHide={this.closeEditModal}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        negotiationActions: bindActionCreators(negotiationActions, dispatch)
    }
}
export default detectPrint(connect(mapStateToProps, mapDispatchToProps)(Quotes));