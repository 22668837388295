import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Validator
 */
import validator from 'validator';
import * as MESSAGES from '../../../libs/Messages';
import ReactTooltip from "react-tooltip";

export default class PopperItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            styles: { popper: {}, arrow: {} },
            attributes: { popper: {} },
            tooltipID: uuidv4()
        }
    }


    componentDidMount() {
        window.loadTooltip();
    }
    render() {
        return (
            <div className="text-center">
                <span data-tip data-for={this.state.tooltipID}>{this.props.data.name}</span>
                <ReactTooltip id={this.state.tooltipID} aria-haspopup='true' place="bottom" effect="float" role='example'>
                    <p>{this.props.data.pieces} Pieces </p><p><b>Actual Weight: </b> {this.props.data.cweight}kg</p><p><b>Weight: </b> {this.props.data.cweight}kg</p><p><b>Volume: </b> {this.props.data.volume}</p>
                </ReactTooltip>
            </div>
        );
    }
}

PopperItem.propTypes = {
    data: PropTypes.object
};