import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';
import clone from 'clone';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import BookingHelper from '../ebooking/BookingHelper';
import EbookingInputError from '../../components/EbookingInputError';
import InputError from '../InputError';
import validator from 'validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ActionTypes from '../../ninja-redux/actionTypes';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';

import Modal from 'react-modal';
import ModalDashboardOneMoment from "../../layouts/partials/ModalDashboardOneMoment";
import ModalDashboardEditAwb from "../../layouts/partials/ModalDashboardEditAwb";
import $ from 'jquery';
import AppConfig from '../../AppConfig';

class QuotesReviewGuest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secretKey: '',
            quoteID: '',
            errorMessage: null,
            isEdit: false,
            errorText: '',
            errors: {},
            loadedScreen: false,
            modalEditIsOpen: false,
            modalOneMomentIsOpen: false,
            steps: [
                {
                    'link': 'quotes/flights',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Flights'
                },
                {
                    'link': 'quotes/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'quotes/review',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            ulds: {
                'HD': 'HD',
                'HDS': 'HD skid',
                'MD': 'MD',
                'MDS': 'MD skid',
                'LD': 'LD',
                'LDS': 'LD skid',
                'LOOSE': 'Loose'
            },
            shipmentData: null,
            showPickup: false,
            showDelivery: false,
            showFreightForwarder: false,
            showGroundHandlingOrigin: false,
            showGroundHandlingDestination: false,
            cost2: [],
            cost2Row: 0,
            total: 0,
            totalExtra: 0,
            isError: false,
            disableEditStatus: ['decline', 'canceled', 'cancel', 'creating quote', 'booked'],
            showingPopup: false,
            showingAgreement: false
        };
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalEditIsOpen: false, modalOneMomentIsOpen: false });
            }
        });
    }

    openOneMomentModal = () => {
        this.setState({ modalOneMomentIsOpen: true, modalEditIsOpen: false });
    }
    openEditModal = () => {
        this.setState({ modalEditIsOpen: true, modalOneMomentIsOpen: false });
    }

    closeEditModal = () => {
        this.setState({ modalEditIsOpen: false });
    }

    closeOneMomentModal = () => {
        this.setState({ modalOneMomentIsOpen: false });
    }

    handleExceedsLimitations = async (shipmentData) => {
        this.closeEditModal();
        this.openOneMomentModal();
    }

    checkEditEnable = () => {
        var { quotes } = this.props;
        let checkAllow = true;
        let status = quotes.status;
        status = status.toLowerCase();
        if (!status || AppConfig.disableEditStatus.includes(status)) {
            checkAllow = false;
        }

        let checkExpired = true;
        let nowTime = moment();
        let expriresTime = null;
        expriresTime = clone(quotes.expriresTime);
        if (!expriresTime || !moment.isMoment(expriresTime) || expriresTime <= nowTime) {
            checkExpired = false;
        }

        if (checkAllow && checkExpired) {
            return true;
        }
        return false;
    }

    handleUpdateShipmentSuccess = async (shipments) => {
        var { quotes } = this.props;
        // console.log(quotes);
        let unitItems = [];
        let newUnitItems = [];
        shipments.units_list.map(item => {
            newUnitItems.push({
                item_pieces: item.Pcs,
                item_length: item.Length,
                item_width: item.Width,
                item_height: item.Height,
                item_volume: item.Cbm,
                item_uld: item.paltype,
                edit: item.edit
            });
        });

        quotes.unit_items = newUnitItems;
        quotes.overlap = shipments.overlap_shipment_check;
        quotes.unit_total.total_weight = shipments.units_total_weight;
        quotes.unit_total.total_volume = shipments.units_total_volume;
        quotes.unit_total.total_pieces = shipments.units_total_num_of_pieces;
        quotes.unit_total.tcw = shipments.units_total_chargable_weight;
        quotes.unit_type = shipments.units_paltype === 'pieces' ? { value: 'pieces', label: 'Pieces' } : { label: 'ULD', value: 'uld' };
        quotes.unit_length = shipments.units_type === 'CM' ? { value: 'cm', label: 'Cm' } : { value: 'in', label: 'Inch' };

        if (this.checkEditEnable()) {
            this.props.loadingActions.loadingChangeData(true);
            let quoteData = await this.props.quotesActions.buildQuoteSaveData(quotes);
            let saveData = await this.props.quotesActions.setcprofbid(quoteData, this.props.auth.jwtToken);
            if (saveData.success && saveData.data.Success && saveData.data.cprof) {
                await this.props.quotesActions.quotesUpdateData(quotes);
                this.setState({ errorMessage: "Shipment updated successfully.", isError: false });
            } else {
                this.setState({ errorMessage: "Shipment updated failed.", isError: true });
            }
        } else {
            this.setState({ errorMessage: "Your Quote can not update because expired.", isError: true });
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    handleClickCommit = async () => {
        this.closeOneMomentModal();
        var { quotes } = this.props;

        let currentDate = moment();
        let statusData = {
            "cporf": parseInt(quotes.CProfAU),
            "status": MESSAGES.STATUS_REVIVE,
            "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
            "statusres": quotes.apiData.statchangeres,
            "userb": quotes.apiData.userb,
            "dept": quotes.apiData.dept,
            "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
        };
        this.props.loadingActions.loadingChangeData(true);
        let saveQuotes = await this.props.quotesActions.updateQuoteStatus(statusData, this.props.auth.jwtToken);
        if (saveQuotes.success && saveQuotes.data.Success) {
            this.setState({ showSuccess: true });

            const paramsAlert = {
                status: 'Revive', // Approved, Declined
                quoteId: quotes.CProfAU,
                secretKey: quotes.secretKey
            }
            await this.props.negotiationActions.custAlert(paramsAlert);
        }
        this.props.loadingActions.loadingChangeData(false);
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { quotes } = this.props;
        var { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (target.type == 'checkbox') ? target.checked : target.value;
        quotes[name] = value;
        if (name == "checkbox_term_df") {
            errors["checkbox_term"] = "";
        }
        this.props.quotesActions.quotesUpdateData(quotes);
    }
    componentDidUpdate = async (oldProps) => {
        var { loadedScreen } = this.state;
        var { country, port, currencies } = this.props;
        if (country.countries.length && port.ports.length && currencies.data.length && !loadedScreen) {
            this.initScreen();
        }
    }

    componentDidMount() {
        localStorage.removeItem('SHOW_VALIDATE');
        var { showError, quoteID, steps } = this.state;
        var { quotes, auth } = this.props;
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        quoteID = this.props.match.params.quoteID;
        var secretKey = this.props.match.params.secretKey;
        steps = steps.map((step, i) => {
            if (i < 2) {
                step.link += '/' + quoteID;
            } else {
                step.link += '/' + secretKey + '/' + quoteID;
            }

            return step;
        })
        if (auth.IsGSA) {
            steps[1].link = 'quotes/participants-gsa/' + quoteID;
        }
        this.setState({ steps });
    }

    handClickStep = async () => {

    }

    handleClickEditFlight = async () => {
        const { history, auth, quotes } = this.props;
        history.push('/quotes/flights/' + quotes.CProfAU);
    }

    handleClickEditPaticipants = async () => {
        const { history, auth, quotes } = this.props;
        if (auth.IsGSA) {
            history.push('/quotes/participants-gsa/' + quotes.CProfAU);
        } else {
            history.push('/quotes/participants/' + quotes.CProfAU);
        }
    }

    handleClickEditShipmentsUnit = async () => {
        var { quotes } = this.props;
        if (this.checkEditEnable()) {
            let unitItems = [];
            quotes.unit_items.map(item => {
                unitItems.push({
                    Pcs: item.item_pieces,
                    Length: item.item_length,
                    Width: item.item_width,
                    Height: item.item_height,
                    Cbm: item.item_volume,
                    paltype: item.item_uld,
                    edit: item.edit
                });
            });
            let ebookingData = {
                awb: quotes.awb_number_1 + quotes.awb_number_2,
                order: quotes.CProfAU,
                units_paltype: quotes.unit_type.value,
                units_type: quotes.unit_length.label.toUpperCase(),
                units_type_short: quotes.unit_length.label.toUpperCase(),
                units_total_weight: quotes.unit_total.total_weight,
                units_total_chargable_weight: quotes.unit_total.tcw,
                units_list: unitItems,
                units_total_num_of_pieces: quotes.unit_total.total_pieces,
                units_total_volume: quotes.unit_total.total_volume,
                overlap_shipment_check: quotes.overlap,
            };

            this.setState({ shipmentData: ebookingData });
            this.openEditModal();
        } else {
            this.setState({ errorMessage: "Your Quote can not update because expired.", isError: true });
        }

    }

    initScreen = async () => {
        localStorage.removeItem('SHOW_VALIDATE');
        this.setState({ loadedScreen: true });
        var { secretKey, quoteID, steps, editFreightForwarder, showFreightForwarder, showPickup, showDelivery, showGroundHandlingOrigin, showGroundHandlingDestination, selectedCountryConsignee, selectedCountryConsignor, selectedCountryDelivery, selectedCountryPickup, showClearConsignor, showClearConsignee } = this.state;
        var { quotes, auth, history } = this.props;
        secretKey = this.props.match.params.secretKey;
        quoteID = this.props.match.params.quoteID;
        await this.props.loadingActions.loadingChangeData(true);
        if (!quotes || quotes.quoteID !== quoteID) {
            await this.props.loadingActions.loadingChangeData(true);
            await this.props.quotesActions.quotesReset();
            await this.props.loadingActions.loadingChangeData(true);
            let getQuotesData = await this.props.quotesActions.buildQuoteData(secretKey, quoteID, auth.state === "LOGGED_IN" ? true : false);
            await this.props.loadingActions.loadingChangeData(false);

            if (getQuotesData.success) {
                getQuotesData.quotesData.reviewQuote = true;
                await this.props.loadingActions.loadingChangeData(true);
                await this.props.quotesActions.quotesUpdateData(getQuotesData.quotesData);
                quotes = getQuotesData.quotesData;
            } else {
                this.setState({ errorText: 'showContactLink' })
            }
        } else {
            quotes.secretKey = this.props.match.params.secretKey;
            await this.props.loadingActions.loadingChangeData(true);
            await this.props.quotesActions.quotesUpdateData(quotes);
        }

        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toLowerCase();
        if (!quotes.status || this.state.disableEditStatus.includes(status)) {
            this.setState({ errorText: 'showContactLink' })
            return;
        }

        if (quotes.deliver_to_airport && quotes.deliver_to_airport.value === "TLV") {
            showFreightForwarder = true;
        }
        if (quotes.deliver_from == "ZipCode") {
            showPickup = true;
        } else {
            showGroundHandlingOrigin = true;
        }
        if (quotes.deliver_to == "ZipCode") {
            showDelivery = true;
        } else {
            showGroundHandlingDestination = true;
        }

        this.setState({
            showPickup,
            showDelivery,
            showFreightForwarder,
            showGroundHandlingOrigin,
            showGroundHandlingDestination,
            selectedCountryConsignee,
            selectedCountryConsignor,
            selectedCountryDelivery,
            selectedCountryPickup,
            editFreightForwarder,
            showClearConsignor,
            showClearConsignee
        });
        await this.props.loadingActions.loadingChangeData(false);
    }

    handlerPrint = function (e) {
        window.print();
    }

    getFlightName = (quotes, parcel = false) => {
        var return_data = "";
        if (quotes.selected_flight_review) {
            if (parcel) {
                if (quotes.deliver_from == "Airport") {
                    return_data += quotes.deliver_from_airport + " - ";
                } else {
                    return_data += (quotes.deliver_to_airport == "TLV" ? "LGG" : "TLV") + " - ";
                }
                if (quotes.deliver_to == "Airport") {
                    return_data += quotes.deliver_to_airport;
                } else {
                    return_data += (quotes.deliver_from_airport == "TLV" ? "LGG" : "TLV") + " - ";
                }
            } else {
                return_data = quotes.selected_flight_review.fleg.fport + " - " + (quotes.selected_flight_review.tleg.tport ? quotes.selected_flight_review.tleg.tport : quotes.selected_flight_review.fleg.tport);
                if (quotes.selected_flight_review.vialeg && quotes.selected_flight_review.vialeg.tport) {
                    return_data += "( VIA " + (quotes.selected_flight_review.vialeg.fport === quotes.selected_flight_review.fleg.tport ? quotes.selected_flight_review.fleg.tport : quotes.selected_flight_review.vialeg.tport) + (quotes.selected_flight_review.vialeg.tport === quotes.selected_flight_review.tleg.fport ? ' - ' + quotes.selected_flight_review.vialeg.tport : ' - ' + quotes.selected_flight_review.tleg.fport) + " )";
                } else if (quotes.selected_flight_review.tleg.tport) {
                    return_data += "( VIA " + (quotes.selected_flight_review.tleg.fport) + " )";
                }
            }
        }

        return return_data;
    }

    getFlightDetail = (quotes, from = true) => {
        var return_data = [];
        //BOF - Doan này can mo        
        if (quotes.selected_flight_review) {
            var flight = quotes.selected_flight_review.fleg;
            if (!from && quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.fnumber) {
                flight = quotes.selected_flight_review.tleg;
            }
            var dTime = BookingHelper.getDate(flight.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
            var duration = BookingHelper.getDuration(dTime, aTime);
            return_data.push(<p key={"fnumber"}>Flight {flight.fnumber}</p>);
            return_data.push(<p key={"etd"}>ETD: {dTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"duration"}>Duration: {duration}</p>);
        }
        //EOF - Doan này can mo
        return return_data;
    }

    getTruckDetail = (quotes, from = true, ETD = true) => {
        var return_data = [];
        if (quotes.selected_flight_review) {
            if (from) {
                var flight = quotes.selected_flight_review.fleg;
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fport} - {flight.tport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            } else {
                var flight = quotes.selected_flight_review.tleg;
                if (flight && flight.fnumber) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fport} - {flight.tport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            }
        }
        return return_data;
    }

    getFlightETD = (quotes, eta = false) => {
        var return_data = "";
        if (quotes.selected_flight_review) {
            var flight = quotes.selected_flight_review.fleg;
            if (quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.fnumber) {
                flight = quotes.selected_flight_review.tleg;
            }
            var dTime = BookingHelper.getDate(flight.std, "DD/MM/YYYY HH:mm");
            if (eta) {
                dTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
            }
            return_data = dTime.format("DD/MM/YYYY HH:mm");
        }
        return return_data;
    }

    buildFlightStep = () => {
        var { quotes, auth } = this.props;
        var authState = auth.state;
        var parcelCase = false;
        var parcelFrom = false;
        var fromCalAirport = false;
        var toCalAirport = false;

        if (quotes.deliver_from == "Airport" && quotes.deliver_from_airport.flightport == "Y") {
            fromCalAirport = true;
        }
        if (quotes.deliver_to == "Airport" && quotes.deliver_to_airport.flightport == "Y") {
            toCalAirport = true;
        }
        // parcelCase = true;
        // parcelFrom = false;
        // fromCalAirport = false;
        // toCalAirport = false;
        // console.log(parcelCase, parcelFrom, fromCalAirport, toCalAirport);
        if (fromCalAirport && toCalAirport) {
            //Flight Only
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    {/* <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p> */}
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes)}
                                    </div>
                                    {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit" onClick={this.handleClickEditFlight}></button>) : null}
                                </div>

                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <p>{quotes.deliver_from == "Airport" ? "Dropoff" : "Pickup"}</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else if (!fromCalAirport && toCalAirport) {
            //Truck - Flight 
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(quotes, true, false)}
                                </div>

                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <p>Pickup</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes, false)}
                                    </div>
                                    {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit" onClick={this.handleClickEditFlight}></button>) : null}
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else if (fromCalAirport && !toCalAirport) {
            //Flight - Truck
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes)}
                                    </div>
                                    {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit" onClick={this.handleClickEditFlight}></button>) : null}
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p>Comments</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(quotes, false)}
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${quotes.deliverCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.deliverCompanyName}</p>
                                        {quotes.deliverAddress ? (<p>{quotes.deliverAddress}</p>) : null}
                                        <p>{quotes.deliverCity}, {quotes.deliverCountry}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            //Truck - Flight - Truck
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(quotes, true, false)}
                                </div>

                                <div className="col-md-5 col-12 mb-3 mb-md-0">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${quotes.pickupCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.pickupCompanyName}</p>
                                        {quotes.pickupAddress ? (<p>{quotes.pickupAddress}</p>) : null}
                                        <p>{quotes.pickupCity}, {quotes.pickupCountry}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    {/* <p>Pickup</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(quotes)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(quotes.PriceQuotation && quotes.PriceQuotation.type == "WHITE" && !quotes.PriceQuotation.pl.length && quotes.DefaultChoosed && quotes.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(quotes)}</p>
                                    <div>
                                        {this.getFlightDetail(quotes, false)}
                                    </div>
                                    {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit" onClick={this.handleClickEditFlight}></button>) : null}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(quotes, false)}
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${quotes.deliverCompanyName != "" ? "" : "d-none"} `}>
                                        <p>{quotes.deliverCompanyName}</p>
                                        {quotes.deliverAddress ? (<p>{quotes.deliverAddress}</p>) : null}
                                        <p>{quotes.deliverCity}, {quotes.deliverCountry}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }

    buildDGClass = () => {
        var { quotes } = this.props;
        var classData = quotes.dg_class;
        let classValues = [];
        classData.map(c => {
            classValues.push(c.value);
        });
        if (classValues.length) {
            return (
                <p className="text-normal">
                    Class: {classValues.join(", ")}
                </p>
            );
        }
    }

    buildMustBeDelivery = () => {
        var { quotes } = this.props;
        let returnElements = [];
        if (quotes && quotes.handling) {
            quotes.handling.map(hl => {
                if (hl.value === "MBD") {
                    returnElements.push(<div key={`delivertill`}>
                        <p className="text-normal">{hl.label}{hl.Delivertill && moment.isMoment(moment(hl.Delivertill)) ? ' by ' + hl.Delivertill.format('MMMM Do, YYYY') : ''}</p>
                    </div>)
                }
            });
        }
        return returnElements;
    }

    buildDGHandling = () => {
        var { quotes } = this.props;
        var handlingData = quotes.handling;
        if (handlingData.length) {
            return (
                <div>
                    <p className="text-normal">
                        Handling Instructions:
                    </p>
                    {
                        handlingData.map((di, i) => {
                            if (di.value !== "MBD") {
                                return (
                                    <p className="text-normal" key={`di-${i}`}>
                                        {di.label}
                                    </p>
                                )
                            }
                        })
                    }
                </div>
            );
        }
    }

    isValidationStep2 = () => {
        let returnData = true;
        const { showPickup, showDelivery, showFreightForwarder, showGroundHandlingOrigin, showGroundHandlingDestination } = this.state;
        var { quotes, auth } = this.props;


        if (auth.IsGSA) {
            if (quotes.selectedShippingReceiving === "Shipping") {

                if (validator.isEmpty(quotes.shippingCompanyName)) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.shippingIATA1) && quotes.shippingIATA1.length < 7) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.shippingIATA2) && quotes.shippingIATA2.length < 4) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.shippingIATA1) && !validator.isEmpty(quotes.shippingIATA2)) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.shippingIATA1) && validator.isEmpty(quotes.shippingIATA2)) {
                    returnData = false;
                }
            }

            if (quotes.selectedShippingReceiving === "Receiving") {
                if (validator.isEmpty(quotes.receivingCompanyName)) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.receivingIATA1) && quotes.receivingIATA1.length < 7) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.receivingIATA2) && quotes.receivingIATA2.length < 4) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.receivingIATA1) && !validator.isEmpty(quotes.receivingIATA2)) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.receivingIATA1) && validator.isEmpty(quotes.receivingIATA2)) {
                    returnData = false;
                }
            }

            if (validator.isEmpty(quotes.shippingCompanyName) && (!validator.isEmpty(quotes.shippingIATA1) || !validator.isEmpty(quotes.shippingIATA2))) {
                returnData = false;
            }

            if (validator.isEmpty(quotes.receivingCompanyName) && (!validator.isEmpty(quotes.receivingIATA1) || !validator.isEmpty(quotes.receivingIATA2))) {
                returnData = false;
            }
        } else {
            if (quotes.already_awb) {
                var error_awb = false;
                if (quotes.awb_number_1 == "") {
                    returnData = false;
                    error_awb = true;
                }
                if (quotes.awb_number_2 == "" && !error_awb) {
                    returnData = false;
                    error_awb = true;
                }
                if (quotes.awb_number_1 != "" && quotes.awb_number_2 != "" && !quotes.isAwb && !error_awb) {
                    returnData = false;
                }
            }

            if (validator.isEmpty(quotes.consigneeCompanyName + "")) {
                returnData = false;
            }

            if (!quotes.consignor_company && validator.isEmpty(quotes.consignorAddress + "")) {
                returnData = false;
            }

            if (!quotes.consignorCity && validator.isEmpty(quotes.consignorCity + "") && !quotes.consignor_company) {
                returnData = false;
            }
            if (validator.isEmpty(quotes.consignorCountry + "") && !quotes.consignor_company) {
                returnData = false;
            }
            if (!quotes.consignor_company && validator.isEmpty(quotes.consignorPostalCode + "")) {
                returnData = false;
            }

            if (validator.isEmpty(quotes.consignorCompanyName + "")) {
                returnData = false;
            }
            if (!quotes.consignee_company && validator.isEmpty(quotes.consigneeAddress + "")) {
                returnData = false;
            }
            if (!quotes.consigneeCity && validator.isEmpty(quotes.consigneeCity + "") && !quotes.consignee_company) {
                returnData = false;
            }
            if (validator.isEmpty(quotes.consigneeCountry + "") && !quotes.consignee_company) {
                returnData = false;
            }
            if (!quotes.consignee_company && validator.isEmpty(quotes.consigneePostalCode + "")) {
                returnData = false;
            }


            if (!showFreightForwarder || (showFreightForwarder && quotes.selectedShippingReceiving === "Shipping")) {
                if (validator.isEmpty(quotes.shippingCompanyName + "") && !quotes.populateImA) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.shippingIATA1 + "") && quotes.shippingIATA1.length < 7) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.shippingIATA2 + "") && quotes.shippingIATA2.length < 4) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.shippingIATA1 + "") && !validator.isEmpty(quotes.shippingIATA2 + "")) {
                    returnData = false;
                }
                if (!validator.isEmpty(quotes.shippingIATA1 + "") && validator.isEmpty(quotes.shippingIATA2 + "")) {
                    returnData = false;
                }
            }

            if (showFreightForwarder && quotes.selectedShippingReceiving === "Receiving") {
                if (validator.isEmpty(quotes.receivingCompanyName + "") && !quotes.populateImA) {
                    returnData = false;
                }
            }

            if (!validator.isEmpty(quotes.receivingIATA1 + "") && quotes.receivingIATA1.length < 7) {
                returnData = false;
            }
            if (!validator.isEmpty(quotes.receivingIATA2 + "") && quotes.receivingIATA2.length < 4) {
                returnData = false;
            }
            if (validator.isEmpty(quotes.receivingIATA1 + "") && !validator.isEmpty(quotes.receivingIATA2 + "")) {
                returnData = false;
            }
            if (!validator.isEmpty(quotes.receivingIATA1 + "") && validator.isEmpty(quotes.receivingIATA2 + "")) {
                returnData = false;
            }

            if (quotes.receivingIATA1.length == 7 && quotes.receivingIATA2.length == 4 && !quotes.checkReceivingIATA) {
                returnData = false;
            }

            if (showPickup) {
                if ((quotes.pickupCountry == undefined || quotes.pickupCountry == "") && !quotes.airline) {
                    returnData = false;
                }

                if ((quotes.pickupCity == undefined || quotes.pickupCity == "") && !quotes.airline) {
                    returnData = false;
                }

                if ((quotes.pickupPostalCode == undefined || quotes.pickupPostalCode == "") && !quotes.airline) {
                    returnData = false;
                }

                if (validator.isEmpty(quotes.pickupEmail + "") || !validator.isEmail(quotes.pickupEmail + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.pickupNameOfContact + "")) {
                    returnData = false;
                }
                if (!quotes.pickupCompanyName || validator.isEmpty(quotes.pickupCompanyName + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.pickupOpeningHours + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.pickupPhoneNumber + "")) {
                    returnData = false;
                }
            }

            if (showDelivery) {
                if ((quotes.deliverCity == undefined || quotes.deliverCity == "") && !quotes.gsa) {
                    returnData = false;
                }
                if ((quotes.deliverCountry == undefined || quotes.deliverCountry == "") && !quotes.gsa) {
                    returnData = false;
                }

                if ((quotes.deliverPostalCode == undefined || quotes.deliverPostalCode == "") && !quotes.gsa) {
                    returnData = false;
                }

                if (validator.isEmpty(quotes.deliverEmail + "") || !validator.isEmail(quotes.deliverEmail + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.deliverNameOfContact + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.deliverCompanyName + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.deliverOpeningHours + "")) {
                    returnData = false;
                }
                if (validator.isEmpty(quotes.deliverPhoneNumber + "")) {
                    returnData = false;
                }
            }

            if (showGroundHandlingOrigin && (quotes.consignorGroundHandling == "" || quotes.consignorGroundHandling == undefined)) {
                returnData = false;
            }

            if (showGroundHandlingDestination && (quotes.consigneeGroundHandling == "" || quotes.consigneeGroundHandling == undefined)) {
                returnData = false;
            }
        }

        return returnData;
    }

    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var { quotes, history, auth } = this.props;

        if (!quotes.checkbox_term_df) {
            isError = true;
            errors.checkbox_term = MESSAGES.ERROR_CONDITION;
        }

        let nowTime = moment();
        // nowTime = nowTime.subtract(2, 'days');        
        let expriresTime = null;
        expriresTime = clone(quotes.expriresTime);
        // if(quotes.selected_flight_review){
        //     expriresTime = moment(quotes.selected_flight_review.fleg.std, 'DD/MM/YYYY HH:mm');
        // }else{
        //     expriresTime = clone(quotes.expriresTime);
        // }

        if (!expriresTime || !moment.isMoment(expriresTime) || expriresTime <= nowTime) {
            isError = true;
            this.setState({ errorMessage: 'Your Quote can not update because expired.' });
        }

        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toUpperCase();
        if (!quotes.status || !AppConfig.acceptBookStatus.includes(status)) {
            isError = true;
            this.setState({ errorMessage: 'You can book this Quote', errorText: 'showContactLink' });
            // this.setState({ errorText: 'showContactLink' })
        }

        if (!this.isValidationStep2()) {
            isError = true;
            localStorage.setItem('SHOW_VALIDATE', true);
            if (auth.IsGSA) {
                history.push('/quotes/participants-gsa/' + quotes.CProfAU);
            } else {
                history.push('/quotes/participants/' + quotes.CProfAU);
            }
            return false;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        this.props.quotesActions.quotesUpdateData(quotes);
        return true;
    }


    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        var { quotes, auth, history } = this.props;
        if (auth.cass != "") {
            // history.push('/ebooking/payment-cass');
            this.setState({ showingPopup: true, showingAgreement: false });
            // this.submitBookData("CASS");
        } else if (auth.credit != "") {
            this.submitBookData("CREDIT");
        } else {
            window.jumpToTop();
            history.push('/quotes/payment/' + quotes.CProfAU);
        }
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    closePopup = (e) => {
        this.setState({ showingPopup: false, showingAgreement: false });
    }

    popupClickSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { history, auth } = this.props;
        if (auth.credit != "") {
            // history.push('/ebooking/payment-credit');
            this.submitBookData("CREDIT");
        } else if (auth.cass != "") {
            // history.push('/ebooking/payment-cass');
            this.submitBookData("CASS");
        } else {
            window.jumpToTop();
            history.push('/ebooking/payment');
        }
    }

    submitBookData = async () => {
        var { quotes, auth, history } = this.props;
        let scTransactionData = await this.props.quotesActions.buildSCData(quotes);
        await this.props.loadingActions.loadingChangeData(true);
        //add status data
        let currentDate = moment();
        let statusData = {
            "cporf": parseInt(quotes.CProfAU),
            "status": MESSAGES.STATUS_BOOKED,
            "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
            "statusres": quotes.apiData.statchangeres,
            "userb": quotes.apiData.userb,
            "dept": quotes.apiData.dept,
            "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
        };
        scTransactionData.statusData = statusData;
        let response = await this.props.ebookingActions.setSafeCharge(scTransactionData, auth.jwtToken);
        await this.props.loadingActions.loadingChangeData(false);
        if (response.success && response.data.Success) {
            // let currentDate = moment();
            // let statusData = {
            //     "cporf": parseInt(quotes.CProfAU),
            //     "status": MESSAGES.STATUS_BOOKED,
            //     "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
            //     "statusres": quotes.apiData.statchangeres,
            //     "userb": quotes.apiData.userb,
            //     "dept": quotes.apiData.dept,
            //     "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
            // };
            // let saveQuotesStat = await this.props.quotesActions.updateQuoteStatus(statusData, auth.jwtToken);
            const paramsAlert = {
                status: 'waitingbooking', // Approved, Declined
                quoteId: quotes.CProfAU,
                secretKey: quotes.secretKey
            }
            await this.props.negotiationActions.custAlert(paramsAlert);

            localStorage.setItem('DASHBOARD_REFRESH', true);

            await this.props.ebookingActions.ebookingChangeData({ fieldName: "docno", fieldValue: response.data.docno }).then(responseData => { });
            await this.props.ebookingActions.ebookingChangeData({ fieldName: "webf", fieldValue: response.data.webf }).then(responseData => { });
            window.jumpToTop();
            history.push('/ebooking/success');
        } else if (!response.success && response.relogin) {
            this.setState({ errorMessage: "Session expired.", isError: true });
            window.jumpToTop();
        } else {
            this.setState({ errorMessage: "Booking Error. Plz try again.", isError: true });
            window.scrollToTop();
        }
    }

    render() {
        var { steps,
            modalEditIsOpen,
            modalOneMomentIsOpen,
            shipmentData,
            errorMessage,
            showPickup,
            showDelivery,
            showFreightForwarder,
            showGroundHandlingOrigin,
            showGroundHandlingDestination,
            cost2,
            errorText,
            isError,
            errors,
            showingPopup,
            showingAgreement
        } = this.state;
        var { quotes, auth } = this.props;
        var authState = auth.state;
        var parcelCase = false;
        var agreementCase = false;

        let expired = false;
        let nowTime = moment();
        // nowTime = nowTime.subtract(2, 'days');        
        let expriresTime = null;
        expriresTime = quotes.expriresTime;
        // if(quotes.selected_flight_review){
        //     expriresTime = moment(quotes.selected_flight_review.fleg.std, 'DD/MM/YYYY HH:mm');
        // }else{
        //     expriresTime = quotes.expriresTime;
        // }

        if (!expriresTime || !moment.isMoment(expriresTime) || expriresTime <= nowTime) {
            expired = true;
            // console.log("vao day 1");
        }

        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toUpperCase();
        let checkStatus = true;
        // console.log("status", status);
        if (!quotes.status || !AppConfig.acceptBookStatus.includes(status)) {
            checkStatus = false;
        }

        // console.log(nowTime.format('DD/MM/YYYY'));
        // console.log(quotes.expriresTime ? quotes.expriresTime.format('DD/MM/YYYY') : '');
        // console.log(expired);    
        // console.log("Check disabled", quotes.selected_flight_review, quotes.checkbox_term_df, !expired, checkStatus, this.isValidationStep2());
        return (
            <div className={`ebooking review-order ${showingPopup ? 'showing-popup' : ''} quote-preview`}>
                <StepProgress stepData={steps} progress={100} onClick={this.handClickStep} />

                <div className="mt-3 mt-md-5 mb-3 mb-md-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<>
                                    <div className="page-title">Your Quote Proposal </div>
                                    <div className="page-description">This proposal expires on {quotes.expriresTime ? quotes.expriresTime.format('MMMM Do, YYYY') + ' at ' + quotes.expriresTime.format('HH:mm') : ''}</div>
                                </>) : (<>
                                    <div className="page-title">Review Order</div>
                                    <div className="page-description">This is a draft. The final order will be sent seperately.</div>
                                </>)}
                                <div className="review-print d-none d-md-block">
                                    <a type="button" className="btn-print" onClick={this.handlerPrint}></a>
                                </div>
                            </div>
                        </div>
                        {auth.IsGSA ? (
                            <div className='modal-content booking-modals bg-white'>
                                <div className='row'>
                                    <div className="col-12 text-center">
                                        <span className='gsa-custname'>GSA: {auth.custdes}</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {(!quotes.selected_flight_review || !this.isValidationStep2()) ? (
                        <div className={`container mt-3`}>
                            <div className="row">
                                <div className="col-12">
                                    <Notification isError={true} message={'Some information is missing, please complete flight and participants details before continue'} hideMessage={this.hideMessage} />
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className={`container ${errorMessage ? 'mt-3' : 'd-none'}`}>
                        <div className="row">
                            <div className="col-12">
                                <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className={`form-group ${(errorText != "" && errorText != "showContactLink") ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                {errorText}
                            </div>
                        </div>

                        <div className={`form-group mt-3 ${errorText == "showContactLink" ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                            </div>
                        </div>
                    </div>

                    {quotes.quoteID ? (<>
                        <div className="container">
                            <div className="row">
                                {this.buildFlightStep()}
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row commodity-item mt-5">
                                        <div className="col-12 col-md-3 mb-3 mb-md-0">
                                            <div className="display-table">
                                                <div className="display-table-cell">
                                                    <h3>Commodity Details</h3>
                                                    <p>{quotes.commodity ? quotes.commodity.label : ''}</p>
                                                    <p>{quotes.sub_commodity ? quotes.sub_commodity.label : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-9">
                                            <div className="row">
                                                <div className={`col-12 col-md-7`}>
                                                    <div className="row review-right-column">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className={`${quotes.unit_type.value === "uld" ? "" : "d-none"}`}>ULD Type</th>
                                                                    <th scope="col">Pieces</th>
                                                                    <th scope="col">Length</th>
                                                                    <th scope="col">Width</th>
                                                                    <th scope="col">Height</th>
                                                                    <th scope="col">Volume (CBM)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    quotes.unit_items.map((op, i) => {
                                                                        return <tr key={i}>
                                                                            <td scope="row" className={`${quotes.unit_type.value === "uld" ? "" : "d-none"}`}><span className="text-uppercase">{this.state.ulds[op.paltype]}</span></td>
                                                                            <td scope="row">{op.item_pieces}</td>
                                                                            <td>{op.item_length} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                            <td>{op.item_width} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                            <td>{op.item_height} <span>{quotes.unit_length.value.toLowerCase()}</span></td>
                                                                            <td>{op.item_volume} <span>{quotes.unit_length.value.toLowerCase()}³</span></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                <tr className={`${quotes.unit_items.length ? 'd-none' : ''}`}>
                                                                    <td> <div style={{ "minHeight": "20px" }}></div></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit commodity" onClick={this.handleClickEditShipmentsUnit}></button>) : null}
                                                    </div>
                                                </div>
                                                <div className={`blue-text ${quotes.unit_items.length ? 'col-12 col-md-5 ' : 'col-12 col-md-5'}`}>
                                                    <div className={`row review-right-column`}>
                                                        <div className="display-table p-0 pl-md-3 pr-md-3">
                                                            <div className="display-table-cell table-units">
                                                                <p>Total Number of pieces: {quotes.unit_total.total_pieces}</p>
                                                                <p>Total Volume (CBM): {quotes.unit_total.total_volume}</p>
                                                                <p>Total Weight: {quotes.unit_total.total_weight} kg</p>
                                                                <p>Total Chargeable Weight: {quotes.unit_total.tcw ? parseFloat(quotes.unit_total.tcw).toFixed(1) : ""} kg</p>
                                                                <p className={`overlap ${(quotes.overlap) ? '' : 'd-none'}`}>Overlap</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${quotes.is_dangerous ? '' : 'd-none'}`}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row commodity-item mt-5">
                                            <div className="col-12 col-md-3 mb-3 mb-md-0">
                                                <div className="display-table">
                                                    <div className="display-table-cell">
                                                        <h3>Dangerous Goods & Special Instructions</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className={`col-md-12 col-12`}>
                                                        <div className="row m-0 review-right-column">
                                                            {this.buildMustBeDelivery()}
                                                            <p className="text-normal">
                                                                This shipment contains dangerous goods ({quotes.dg_cao_pax})
                                                            </p>
                                                            {this.buildDGClass()}
                                                            <p className={`text-normal ${quotes.dg_un ? '' : 'd-none'}`}>
                                                                Un's: {quotes.dg_un}
                                                            </p>
                                                            <p className={`text-normal ${quotes.is_lithium ? '' : 'd-none'}`}>
                                                                This shipment contains lithium batteries
                                                            </p>
                                                            <p className={`text-normal ${quotes.is_shipment_unstackable ? '' : 'd-none'}`}>
                                                                This shipment is unstackable
                                                            </p>
                                                            {this.buildDGHandling()}

                                                            <p className={`text-normal ${quotes.additional_information ? '' : 'd-none'}`}>
                                                                Additional Information: {quotes.additional_information}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="div-bg-grey mt-5 participants pt-4 pb-4">
                            <div className="container">

                                <div className="row">
                                    <div className="col-12 col-md-3 mb-3">
                                        <div className="display-table">
                                            <div className="display-table-cell">
                                                <h3>Participants</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className={`row m-0 review-right-column mb-3`}>
                                            <div className={`col-md-6 ${(quotes.shippingCompanyName.trim() !== '') ? '' : 'd-none'}`}>
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Shipping Agent</h3>
                                                    <p className={`${(quotes.shippingCompanyName.trim()) ? "" : "d-none"}`}>
                                                        {quotes.shippingCompanyName}
                                                    </p>
                                                    <p className={`${(quotes.shippingIATA1.trim() && quotes.shippingIATA2.trim()) ? "" : "d-none"}`}>
                                                        IATA Code: {quotes.shippingIATA1}-{quotes.shippingIATA2}
                                                    </p>
                                                    {quotes.payingCustomerShipping ? (
                                                        <p className={`text-orange`}>
                                                            Paying Customer
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className={`col-md-6 ${(quotes.receivingCompanyName.trim() !== '') ? '' : 'd-none'}`}>
                                                <div className="row">
                                                    <h3 className="font-weight-bold">{auth.IsGSA ? 'Freight Forwarder' : 'Receiving Agent/Notify'}</h3>
                                                    <p className={`${(quotes.receivingCompanyName.trim()) ? "" : "d-none"}`}>
                                                        {quotes.receivingCompanyName}
                                                    </p>
                                                    <p className={`${(quotes.receivingIATA1.trim() && quotes.receivingIATA2.trim()) ? "" : "d-none"}`}>
                                                        IATA Code: {quotes.receivingIATA1}-{quotes.receivingIATA2}
                                                    </p>
                                                    {quotes.payingCustomerFreight ? (
                                                        <p className={`text-orange`}>
                                                            Paying Customer
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignor/Shipper Details</h3>
                                                    <p className={`${(quotes.consignorCompanyName) ? "" : "d-none"}`}>{quotes.consignorCompanyName}</p>
                                                    <p className={`${(quotes.consignorCompanyName) ? "" : "d-none"}`}>
                                                        {quotes.consignorCountry}, {quotes.consignorCity}
                                                    </p>
                                                    <p className={`${(quotes.consignorAddress) ? "" : "d-none"}`}>
                                                        Address: {quotes.consignorAddress}
                                                    </p>
                                                    <p className={`${(quotes.consignorPostalCode) ? "" : "d-none"}`}>
                                                        Postal Code: {quotes.consignorPostalCode}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignee/Importer Details</h3>
                                                    <p className={`${(quotes.consigneeCompanyName) ? "" : "d-none"}`}>{quotes.consigneeCompanyName}</p>
                                                    <p className={`${(quotes.consigneeCompanyName) ? "" : "d-none"}`}>
                                                        {quotes.consigneeCountry}, {quotes.consigneeCity}
                                                    </p>
                                                    <p className={`${(quotes.consigneeAddress) ? "" : "d-none"}`}>
                                                        Address: {quotes.consigneeAddress}
                                                    </p>
                                                    <p className={`${(quotes.consigneePostalCode) ? "" : "d-none"}`}>
                                                        Postal Code: {quotes.consigneePostalCode}
                                                    </p>
                                                    {(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? (<button className="btn btn-review-edit" onClick={this.handleClickEditPaticipants}></button>) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`container mt-5 ${parcelCase ? 'd-none' : ''}`}>
                            <div className="row">
                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                    <div className="display-table">
                                        <div className="display-table-cell">
                                            <h3 className="">
                                                {agreementCase ? "Agreement" : "Pricings"}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className={`row m-0 review-right-column`}>
                                        <div className=" d-none d-md-block" style={{ "width": "100%" }}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Commodity Type</th>
                                                        <th scope="col">Pieces</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Chargeable Weight</th>
                                                        <th scope="col">Volume (CBM)</th>
                                                        <th scope="col">Rate per KG</th>
                                                        <th scope="col">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="bg-white">
                                                        <td>Consolidation</td>
                                                        <td>{quotes.unit_total.total_pieces}</td>
                                                        <td>{quotes.unit_total.total_weight} <span>kg</span></td>
                                                        <td>{quotes.unit_total.tcw}  <span>kg</span></td>
                                                        <td>{quotes.unit_total.total_volume}</td>
                                                        <td>{BookingHelper.formatMoney(quotes.offer_total / quotes.unit_total.tcw)}  <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></td>
                                                        <td>{BookingHelper.formatMoney(quotes.offer_total)} <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className=" d-block d-md-none" style={{ "width": "100%" }}>
                                            <table className="table table-cost-mobile">
                                                <tbody>
                                                    <tr className="row-grey">
                                                        <td colSpan={2}>Commodity</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Consolidation</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td># of Pieces</td>
                                                        <td>Chargable Weight (kg)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{quotes.unit_total.total_pieces}</td>
                                                        <td>{quotes.unit_total.tcw}</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td>Volume (CBM)</td>
                                                        <td>Per KG rate</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{quotes.unit_total.total_volume}</td>
                                                        <td>{BookingHelper.currencySymbol(quotes.offer_currency ? quotes.offer_currency.label : '') + BookingHelper.formatMoney(quotes.offer_total)}</td>
                                                    </tr>
                                                    <tr className="row-grey">
                                                        <td colSpan={2}>Total</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}><span>{BookingHelper.formatMoney(quotes.offer_total)}</span> {quotes.offer_currency ? quotes.offer_currency.label : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={`row m-0 review-right-column ${cost2.length ? "" : "d-none"}`}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Additional Charges</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    // this.buildParts(cost2, quotes.offer_currency ? quotes.offer_currency.label : '')
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container no_padding mt-5">
                            <div className={`row mt-3 mb-2 ${errors.checkbox_term_parcel ? "" : "hide"}`}>
                                <div className="col-lg-12">
                                    <div className="show-bottom-error bottom0">
                                        <InputError error={errors.checkbox_term_parcel} class="bg-red" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-check pl-md-0">
                                        <label className={`checkbox-container`}>
                                            I have read and agree to the
                                            <a className="terms_link" href="https://www.cal-cargo.com/terms-conditions/" target="_blank">Terms of Service</a> and
                                            <a className="terms_link" href="https://www.cal-cargo.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                                            <input type="checkbox" name="checkbox_term_df" value="checkbox_term_df" checked={quotes.checkbox_term_df} onChange={this.handleChange} />
                                            <span className={`checkmark-checkbox ${(errors.checkbox_term) ? 'input-error' : ''}`}></span>
                                        </label>
                                        <EbookingInputError error={errors.checkbox_term} class="float-left" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="footer-total div_center">
                                        <div className="total-txt">Total</div>
                                        <div className="total-price">{BookingHelper.formatMoney(quotes.offer_total)} <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></div>
                                        {/* <div className="total-price"></div> */}
                                        <div className="clb"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <div className="footer-buttons float-right pr-2 pr-md-0 mt-3 mt-md-0">
                                        <div className="btn-process">
                                            <button type="button" disabled={((auth.state === 'LOGGED_IN' || auth.state === 'NEW_PASSWORD_REQUIRED' || auth.state === 'EMAIL_VERIFICATION_REQUIRED') && quotes.selected_flight_review && quotes.checkbox_term_df && !expired && checkStatus && this.isValidationStep2()) ? false : true} onClick={this.handSubmit} className="ml-3 btn btn-ebooking-orange">Confirm Order</button>
                                        </div>
                                        <div className="btn-back-shipment">
                                            <Link className="btn btn-back" to={`${process.env.PUBLIC_URL}/quotes/participants/${quotes.CProfAU}`}>Back to Participants</Link>
                                        </div>
                                        <div className="clb"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>) : null}

                </div>
                <div className={`popup-ebooking ${showingPopup ? '' : 'd-none'}`}>
                    <div className="modal-dialog modal-dialog-xlarge bg_blue">
                        <div className="modal-content">
                            <div className="modal-main">
                                <div className="modal-header">
                                    <button type="button" onClick={this.closePopup} className="button-back">Back to previous page</button>
                                </div>
                                <div className="modal-body">

                                    <table className="table-popup-content">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="div_center text-center" style={{ "maxWidth": "700px" }}>
                                                        <div className={`form-group ${errorMessage ? '' : 'd-none'}`}>
                                                            <div className="alert alert-danger text-red" role="alert">
                                                                There was an error in the process. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="page-content text-center">
                                                        <div className="big-text">Payment Confirmation</div>
                                                        <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                                            Dear {auth.IsGSA && !quotes.payingCustomer ? auth.user_name + ', ' + (auth.custdes ? auth.custdes.replace(auth.cassname, "") : "") : 'Customer'},
                                                        </div>
                                                        {!auth.IsGSA || (auth.IsGSA && !quotes.payingCustomer) ? (
                                                            <div className={`content-text-desc div_center mt-4`} style={{ "maxWidth": "500px" }}>
                                                                Your order is being processed as we speak. The paying customer is {(!quotes.selectedShippingReceiving || quotes.selectedShippingReceiving === 'Shipping') ? quotes.shippingCompanyName : quotes.receivingCompanyName}. The payment amount of <span className="text-orange text-uppercase">{BookingHelper.formatMoney(quotes.offer_total)} {quotes.offer_currency ? quotes.offer_currency.label : ''}</span> will be charged via CASS {auth.cassname} to IATA CODE - <span className="text-orange text-uppercase">{auth.iatacode.substring(0, 4)}-{auth.iatacode.substring(4, auth.iatacode.length)}</span>.
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className={`content-text-desc div_center mt-4 ${(showingPopup && showingAgreement) ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                                    Your order is being processed as we speak. The payment amount will be charged according to your agreement with CAL.
                                                                </div>
                                                                <div className={`content-text-desc div_center mt-4 ${(showingPopup && !showingAgreement) ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                                    Your order is being processed as we speak. The payment amount of <span className="text-orange text-uppercase">{BookingHelper.formatMoney(quotes.offer_total)} {quotes.offer_currency ? quotes.offer_currency.label : ''}</span> will be charged via CASS {auth.cassname} to IATA CODE - <span className="text-orange text-uppercase">{auth.iatacode.substring(0, 4)}-{auth.iatacode.substring(4, auth.iatacode.length)}</span>.
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="content-text-desc div_center mt-4" style={{ "maxWidth": "500px" }}>
                                                            Currency exchange to EUR will be based on the exchange rate on the date of the flight.
                                                        </div>
                                                        <div className="content-buttons div_center mt-5">
                                                            <button className="btn btn-orange" onClick={this.popupClickSubmit}>Confirm Payment</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal
                    isOpen={modalEditIsOpen}
                    onRequestClose={this.closeEditModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardEditAwb
                        handleExceedsLimitations={this.handleExceedsLimitations}
                        handleUpdateShipmentSuccess={this.handleUpdateShipmentSuccess}
                        shipmentData={shipmentData}
                        onShow={modalEditIsOpen}
                        closeModal={this.closeEditModal}
                        onHide={this.closeEditModal}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
                <Modal
                    isOpen={modalOneMomentIsOpen}
                    onRequestClose={this.closeOneMomentModal}
                    onAfterOpen={this.onAfterOpen}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalDashboardOneMoment
                        onShow={modalOneMomentIsOpen}
                        handleClickCommit={this.handleClickCommit}
                        closeModal={this.closeOneMomentModal}
                        onHide={this.closeOneMomentModal}
                    // handleConfirm={this.handleModalConfirm}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        negotiationActions: bindActionCreators(negotiationActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesReviewGuest);