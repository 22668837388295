import React from 'react';

export default class ModalShowMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { onShow, message, error } = this.props;
        if (!onShow)
            return (null);

        return (
            <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                <div className="modal-content quotes-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                            {error? (
                                <h5 className="text-danger">Error</h5>
                            ) : (
                                <h5 className="">Message</h5>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className={`popup-description ${error?'text-danger':''}`} style={{ 'maxWidth': '350px' }}>{message}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <div className="row">
                            <div className="col-12"><button type="button" className="btn btn-continues btn-block" onClick={this.props.closeModal}>OK</button></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
