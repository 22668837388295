import moment from 'moment';
import validator from 'validator';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const BookingHelper = {
    getVia: function (flights, toPort) {
        var via = "";
        flights.map((o, i) => {
            if (i && o.fromport != toPort) {
                if (via == "") {
                    via += o.fromport;
                } else {
                    via += " - " + o.fromport;
                }
            }
        })
        return via;
    },

    getDate: function (dateString, format) {
        var d = moment(dateString, format);
        return d;
    },

    getWeekday: function (d) {
        if (d.isValid()) {
            var wd = d.format("e");
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return days[wd];
        } else {
            return "";
        }
    },

    getDuration: function (d1, d2) {
        if (d1.isValid() && d2.isValid()) {
            var difference_ms = d2.valueOf() - d1.valueOf();
            var one_minute = 1000 * 60;
            var difference_m = difference_ms / one_minute;
            var return_h = Math.floor(difference_m / 60);
            if (return_h < 10) {
                return_h = "0" + return_h;
            }
            var return_m = difference_m % 60;
            if (return_m < 10) {
                return_m = "0" + return_m;
            }
            return return_h + ":" + return_m;
        }
        return "";
    },

    getDurationNew: function (n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes;
    },

    formatMoney: function (amount, decimalCount = 2, decimal = ".", thousands = ",") {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        var amount_floor = Math.floor(amount);
        var amount_decimal = amount - amount_floor;
        // if (amount_decimal == 0) {
        //     decimalCount = 0;
        // }
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    },

    getPrices: function (flights) {
        var prices = [];
        var eco_price = flights.price;
        var express_price = flights.expressprice;
        // flights.map((o, i) => {
        //     eco_price += o.price;
        //     express_price += o.expressprice;
        // })

        prices = [eco_price, express_price];
        return prices;
    },

    currencySymbol: function (iso3) {
        iso3 += "";
        iso3 = iso3.toUpperCase();
        switch (iso3) {
            case 'USD': return '$';
            case 'EUR': return '€';
            case 'NIS': return '₪';
            default: return iso3 + " ";
        }
    },

    getOfferCostTotal: function (offer_type, total, unit_total) {
        total = parseFloat(total);
        var offer_total = 0;
        if (offer_type === 'totals') {
            offer_total = total;
        } else if (offer_type === 'Per Kg CHW' && parseFloat(unit_total.tcw) > 0) {
            offer_total = total / parseFloat(unit_total.tcw);
        } else if (offer_type === 'per_actual' && parseFloat(unit_total.total_weight) > 0) {
            offer_total = total / parseFloat(unit_total.total_weight);
        } else if (offer_type === 'per_volume' && parseFloat(unit_total.total_volume) > 0) {
            offer_total = total / parseFloat(unit_total.total_volume);
        }
        return offer_total > 0 ? offer_total.toFixed(2) : 0;
    },
    calculatorTotalNegotiation: function (offers, currency, quoteData, currencies) {

        // console.log('calculatorTotalNegotiation', currencies);
        var returnValue = { offer_total: 0 };
        if (currency) {
            // console.log(offers, currency, quoteData);
            var offer_total = 0;
            offers.forEach(item => {
                if (item.calc && item.curr && (!validator.isEmpty(item.price + "") || validator.isNumeric(item.price + ""))) {
                    var findOfferCurrency = currencies.find(c => c.label.toUpperCase() === item.curr);
                    if (findOfferCurrency) {
                        let itemCurrencyRate = (findOfferCurrency.value / currency.value);
                        let price = 0;
                        if (item.calc === 'Lump Sum') {
                            price = parseFloat(item.price) * itemCurrencyRate;
                        } else if (item.calc === 'Per Kg CHW') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseFloat(quoteData.CWeight);
                        } else if (item.calc === 'Per Kg ACW') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseFloat(quoteData.Weight);
                        } else if (item.calc === 'Per Unit' || item.calc === 'Per ULD') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseInt(quoteData.Pieces);
                        }
                        offer_total += price;
                    }
                }
            });
            returnValue = { offer_total: (offer_total > 0 ? offer_total.toFixed(2) : 0) };
        }
        return returnValue;
    },
    calculatorTotalDownload: function (offers, currency, quoteData, currencies) {
        var returnValue = { offer_total: 0 };
        if (currency) {
            // console.log(offers, currency, quoteData);
            var offer_total = 0;
            offers.forEach(item => {
                if (item.calculation_method && item.currency && (!validator.isEmpty(item.price + "") || validator.isNumeric(item.price + ""))) {
                    var findOfferCurrency = currencies.find(c => c.label.toUpperCase() === item.currency.label);
                    if (findOfferCurrency) {
                        let itemCurrencyRate = (findOfferCurrency.value / currency.value);
                        let price = 0;
                        if (item.calculation_method.value === 'Lump Sum') {
                            price = parseFloat(item.price) * itemCurrencyRate;
                        } else if (item.calculation_method.value === 'Per Kg CHW') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseFloat(quoteData.CWeight);
                        } else if (item.calculation_method.value === 'Per Kg ACW') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseFloat(quoteData.Weight);
                        } else if (item.calculation_method.value === 'Per Unit' || item.calculation_method.value === 'Per ULD') {
                            price = parseFloat(item.price) * itemCurrencyRate * parseInt(quoteData.Pieces);
                        }
                        offer_total += price;
                    }
                }
            });
            returnValue = { offer_total: (offer_total > 0 ? offer_total.toFixed(2) : 0) };
        }
        return returnValue;
    },
    calculatorTotal: function (offers, offer_total_type, prices, cost_total_type, sub_total_type, unit_total, currency) {

        // console.log(offers, offer_total_type, prices, cost_total_type, sub_total_type, unit_total, currency);

        var returnValue = { offer_total: 0, cost_total: 0, cost_total_2: 0 };
        if (currency) {
            var offer_total = 0;
            offers.forEach(item => {
                if (item.calculation_method && item.currency && (!validator.isEmpty(item.price + "") || validator.isNumeric(item.price + ""))) {
                    let itemCurrencyRate = (item.currency.value / currency.value);
                    let price = 0;

                    if (item.calculation_method.value === 'Lump Sum') {
                        // console.log('vao day 1');
                        price = parseFloat(item.price) * itemCurrencyRate;
                    } else if (item.calculation_method.value === 'Per Kg CHW') {
                        // console.log('vao day 2', item.price, item.currency, itemCurrencyRate, unit_total.tcw);
                        price = parseFloat(item.price) * itemCurrencyRate * parseFloat(unit_total.tcw);
                    } else if (item.calculation_method.value === 'Per Kg ACW') {
                        // console.log('vao day 3', item.price, itemCurrencyRate, unit_total.total_weight);
                        price = parseFloat(item.price) * itemCurrencyRate * parseFloat(unit_total.total_weight);
                    } else if (item.calculation_method.value === 'Per Unit' || item.calculation_method.value === 'Per ULD') {
                        // console.log('vao day 4');
                        price = parseFloat(item.price) * itemCurrencyRate * parseInt(unit_total.total_pieces);
                    }
                    // console.log("price", price);
                    offer_total += price;
                }
            });

            if (offer_total_type.value === 'Per Kg CHW' && parseFloat(unit_total.tcw) > 0) {
                offer_total = offer_total / parseFloat(unit_total.tcw);
            } else if (offer_total_type.value === 'per_actual' && parseFloat(unit_total.total_weight) > 0) {
                offer_total = offer_total / parseFloat(unit_total.total_weight);
            } else if (offer_total_type.value === 'per_volume' && parseFloat(unit_total.total_volume) > 0) {
                offer_total = offer_total / parseFloat(unit_total.total_volume);
            }
            offer_total = offer_total > 0 ? offer_total.toFixed(2) : 0;

            var price_total = 0;
            prices.forEach(item => {
                if (item.calculation_method && item.currency && (!validator.isEmpty(item.price + "") || validator.isNumeric(item.price + ""))) {
                    let itemCurrencyRate = (item.currency.value / currency.value);
                    let price = 0;
                    if (item.calculation_method.value === 'Lump Sum') {
                        price = parseFloat(item.price) * itemCurrencyRate;
                    } else if (item.calculation_method.value === 'Per Kg CHW') {
                        price = parseFloat(item.price) * itemCurrencyRate * parseFloat(unit_total.tcw);
                    } else if (item.calculation_method.value === 'Per Kg ACW') {
                        price = parseFloat(item.price) * itemCurrencyRate * parseFloat(unit_total.total_weight);
                    } else if (item.calculation_method.value === 'Per Unit' || item.calculation_method.value === 'Per ULD') {
                        price = parseFloat(item.price) * itemCurrencyRate * parseInt(unit_total.total_pieces);
                    }
                    price_total += price;
                }
            });
            if (cost_total_type.value === 'Per Kg CHW' && parseFloat(unit_total.tcw) > 0) {
                price_total = price_total / parseFloat(unit_total.tcw);
            } else if (cost_total_type.value === 'per_actual' && parseFloat(unit_total.total_weight) > 0) {
                price_total = price_total / parseFloat(unit_total.total_weight);
            } else if (cost_total_type.value === 'per_volume' && parseFloat(unit_total.total_volume) > 0) {
                price_total = price_total / parseFloat(unit_total.total_volume);
            }
            price_total = price_total > 0 ? price_total.toFixed(2) : 0;

            var sub_total = offer_total - price_total;
            if (sub_total_type.value === 'Per Kg CHW' && parseFloat(unit_total.tcw) > 0) {
                sub_total = sub_total / parseFloat(unit_total.tcw);
            } else if (sub_total_type.value === 'per_actual' && parseFloat(unit_total.total_weight) > 0) {
                sub_total = sub_total / parseFloat(unit_total.total_weight);
            } else if (sub_total_type.value === 'per_volume' && parseFloat(unit_total.total_volume) > 0) {
                sub_total = sub_total / parseFloat(unit_total.total_volume);
            }
            sub_total = sub_total != 0 ? sub_total.toFixed(2) : 0;
            returnValue = { offer_total, cost_total: price_total, cost_total_2: sub_total };
        }
        return returnValue;
    },
    calculatorVolumeNew: function (unitLength, l, w, h) {
        var cbm = "";
        if (((!validator.isEmpty(l + "") && validator.isNumeric(l + ""))) && ((!validator.isEmpty(w + "") && validator.isNumeric(w + ""))) && ((!validator.isEmpty(h + "") && validator.isNumeric(h + "")))) {
            if (unitLength === 'cm' || unitLength === 'CM') {
                cbm = (parseFloat(l) * parseFloat(w) * parseFloat(h)) / 1000000;
            } else {
                cbm = ((parseFloat(l) * 2.54) * (parseFloat(w) * 2.54) * (parseFloat(h) * 2.54)) / 1000000;
            }
            // cbm = cbm.toFixed(3) + "";
        }
        return cbm;
    },
    calculatorVolume: function (p, l, w, h) {
        var cbm = "";
        if (((!validator.isEmpty(p + "") && validator.isInt(p + ""))) && ((!validator.isEmpty(l + "") && validator.isNumeric(l + ""))) && ((!validator.isEmpty(w + "") && validator.isNumeric(w + ""))) && ((!validator.isEmpty(h + "") && validator.isNumeric(h + "")))) {
            cbm = parseInt(p) * parseFloat(l) * parseFloat(w) * parseFloat(h) * 0.000001;
            // cbm = cbm.toFixed(3) + "";
        }
        return cbm;
    },
    calculatorVolumeKG: function (total_volume) {
        var kg = 0;
        if (total_volume) {
            kg = (total_volume / 6) * 1000;
            // kg = kg.toFixed(3);
        }
        return kg;
    },
    roundOff: function (number, precision) {
        number += "";
        if (number) {
            return number.replace(/^(\d+.?\d{0,5})\d*$/, "$1");
        } else {
            return number;
        }
    },
    roundOff3Decimal: function (number, precision) {
        number += "";
        if (number) {
            return number.replace(/^(\d+.?\d{0,3})\d*$/, "$1");
        } else {
            return number;
        }
    },
    getParticipentItem: function (customerType, quotes) {
        let typeValue = null;
        let returnValue = [];
        switch (customerType) {
            case 'ShippingAgent':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.SHIPNAME ? quotes.apiData.Participents.SHIPNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Shipping Agent</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'ReceivingAgent':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.RECNAME ? quotes.apiData.Participents.RECNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Receiving Agent</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'Consignee':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.CNEENAME ? quotes.apiData.Participents.CNEENAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Consignee / Importer Company Name</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'Consignor':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.CNORNAME ? quotes.apiData.Participents.CNORNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Consignor / Shipper Company Name</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'Airline':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.PICKCOMPNAME ? quotes.apiData.Participents.PICKCOMPNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Airline</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'GSA':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.DELNAME ? quotes.apiData.Participents.DELNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">GSA</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
            case 'FeederGSA':
                typeValue = quotes.apiData && quotes.apiData.Participents && quotes.apiData && quotes.apiData.Participents.DELCOMPNAME ? quotes.apiData.Participents.DELCOMPNAME.trim() : null;
                if (typeValue) {
                    returnValue.push(
                        <li key={uuidv4()} className="text-bold">Feeder GSA</li>
                    );
                    returnValue.push(<li key={uuidv4()}>{typeValue}</li>);
                }
                break;
        }

        return returnValue;
    },
    createFlightData: function (quotes) {
        let selectedFlight = quotes.selected_flight;
        let shipIcons, shipInfos;
        let dStd = selectedFlight
            ? moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm')
            : moment();
        let dSta = selectedFlight
            ? moment(
                selectedFlight.tleg.sta
                    ? selectedFlight.tleg.sta
                    : selectedFlight.fleg.sta,
                'DD/MM/YY HH:mm'
            )
            : moment();

        var fromCalAirport = false;
        var toCalAirport = false;

        if (quotes.deliver_from === 'Airport' && quotes.deliver_from_airport && quotes.deliver_from_airport.flightport == "Y") {
            fromCalAirport = true;
        }
        if (quotes.deliver_to === 'Airport' && quotes.deliver_to_airport && quotes.deliver_to_airport.flightport == "Y") {
            toCalAirport = true;
        }
        // console.log(fromCalAirport, toCalAirport, quotes.selected_flight);
        if (fromCalAirport && toCalAirport) {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport : (quotes.deliver_to_airport ? quotes.deliver_to_airport.portname : "");

            let endDate = '';
            let endDateTime = '';

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('MMMM Do') : '';
                endDateTime = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            shipIcons = ['airplane'];
            shipInfos = [
                {
                    width: '30%',
                    label: startPort,
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : "",
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '40%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '30%',
                    label: toPort,
                    date: endDate,
                    time: endDateTime,
                    align: 'right',
                    line: false,
                },
            ];
        } else if (fromCalAirport && !toCalAirport) {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : (quotes.deliver_from_airport ? quotes.deliver_from_airport.portname : "");
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';
            let startDate = '';
            let startDateTime = '';
            let endDate = '';
            let endDateTime = '';
            if (selectedFlight) {
                startDate = dStd.isValid() ? dStd.format('MMMM Do') : "";
                if (startDate) {
                    startDateTime = dStd.isValid() ? dStd.format('HH:mm') : '';
                } else {
                    startDate = dStd.isValid() ? dStd.format('HH:mm') : '';
                }
            }

            if (selectedFlight) {
                endDate = dSta.isValid() ? dSta.format('MMMM Do') : '';
                endDateTime = dSta.isValid() ? dSta.format('HH:mm') : '';
            }

            shipIcons = ['airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: startPort,
                    date: startDate,
                    time: startDateTime,
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight
                        ? selectedFlight.tleg.leg
                            ? selectedFlight.tleg.tport
                            : selectedFlight.fleg.tport
                        : '',
                    date: endDate,
                    time: endDateTime,
                    align: 'center',
                    line: true,
                },
                {
                    width: '40%',
                    label: toPort,
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        } else if (!fromCalAirport && toCalAirport) {
            shipIcons = ['truck', 'airplane'];
            shipInfos = [
                {
                    width: '40%',
                    label: startPort,
                    date: '',
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: selectedFlight ? selectedFlight.fleg.fport : '',
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : '',
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: toPort,
                    date: selectedFlight && dSta.isValid() ? dSta.format('MMMM Do') : '',
                    time: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    align: 'right',
                    line: false,
                },
            ];
        } else {
            var startPort = quotes.selected_flight ? quotes.selected_flight.fleg.fport : "";
            var toPort = quotes.selected_flight ? (quotes.selected_flight.tleg.tport ? quotes.selected_flight.tleg.tport : quotes.selected_flight.fleg.tport) : '';
            shipIcons = ['truck', 'airplane', 'truck'];
            shipInfos = [
                {
                    width: '20%',
                    label: (quotes.deliver_from === 'Airport' ? ("Airport " + (quotes.deliver_from_airport ? quotes.deliver_from_airport.label : '')) : (quotes.deliver_from_zipcode ? 'Zip ' + quotes.deliver_from_zipcode : '')),
                    date: '',
                    time: '',
                    align: 'left',
                    line: false,
                },
                {
                    width: '20%',
                    label: startPort,
                    date: selectedFlight && dStd.isValid() ? dStd.format('MMMM Do') : '',
                    time: selectedFlight && dStd.isValid() ? dStd.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: selectedFlight ? 'Flight ' + selectedFlight.fleg.fnumber : '',
                    date: '',
                    time: '',
                    align: 'center',
                    line: false,
                },
                {
                    width: '20%',
                    label: toPort,
                    date: selectedFlight && dSta.isValid() ? dSta.format('MMMM Do') : '',
                    time: selectedFlight && dSta.isValid() ? dSta.format('HH:mm') : '',
                    align: 'center',
                    line: true,
                },
                {
                    width: '20%',
                    label: (quotes.deliver_to === 'Airport' ? ("Airport " + (quotes.deliver_to_airport ? quotes.deliver_to_airport.label : '')) : (quotes.deliver_to_zipcode ? 'Zip ' + quotes.deliver_to_zipcode : '')),
                    date: '',
                    time: '',
                    align: 'right',
                    line: false,
                },
            ];
        }
        return {
            shipIcons,
            shipInfos,
        };
    },
    createOfferData: function (offerItems) {
        let offers = [];
        for (let i = 0; i < offerItems.length; i++) {
            const element = offerItems[i];
            offers.push({
                item: element.subcom,
                method: element.calc,
                currency: element.curr,
                price: element.price,
            });
        }

        return offers;
    },
    createOfferDataDownload: function (offerItems) {
        let offers = [];
        for (let i = 0; i < offerItems.length; i++) {
            const element = offerItems[i];
            offers.push({
                item: element.calculation_method && element.calculation_method.label ? element.calculation_method.label : '',
                method: element.calculation_method && element.calculation_method.label ? element.calculation_method.label : '',
                currency: element.currency ? element.currency.label : '',
                price: element.price,
            });
        }
        return offers;
    }
}

export default BookingHelper;