import * as ActionTypes from '../actionTypes';

const initialState = {
    
};

export default function reducer(state = initialState, action) {
    switch (action.type) {   

    }
    return state;
}