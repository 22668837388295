import React from 'react';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
/**
 * Validator
 */
import validator from 'validator';
import InputError from '../InputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';


class PaymentAWBSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            webf: "",
            awb: "",
            showAttachButton: false
        };

        this.handClickSubmit = this.handClickSubmit.bind(this);
    }

    handClickSubmit = (e) => {
        const { history } = this.props;
        history.push('/ebooking');
    }

    componentDidMount() {
        var { history } = this.props;
        var { awb } = this.state;
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var scID = parsedParams.scID;
        var awb_param = parsedParams.awb;
        // var webf = parsedParams.webf;
        var webf = parsedParams.tid;
        if (awb_param != undefined && awb_param != "") {
            this.setState({ awb: awb_param });
        }
        if (webf != undefined && webf != "") {
            this.setState({ showAttachButton: true, webf: webf });
        } else {
            this.setState({ showAttachButton: false, webf: "" });
        }
        // if (scID) {
        //     this.getWebf(scID);
        // }

        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    getWebf = (scID) => {
        var { ebooking } = this.props;
        var t = this;
        this.props.loadingActions.loadingChangeData(true);
        setTimeout(function () {
            t.props.ebookingActions.getWebf(scID).then(response => {
                t.props.loadingActions.loadingChangeData(false);
                if (response.success && response.data.Success && response.data.webf != undefined) {
                    t.setState({ showAttachButton: true, webf: response.data.webf, docno: response.data.docno });
                }
            });
        }, 3000);
    }

    render() {
        return (

            <div className="ebooking">

                <form>
                    <div className="container success-page block-form mb-4">
                        <h1 className="formTitle mb-4">Payment Successful</h1>
                        <p className=''>Thank you for your payment. A confirmation will be emailed to you shortly. For your records, your transaction ID is <strong>{this.state.webf}</strong></p>                       
                        <div className="">
                            <a href="https://www.cal-cargo.com/" className={`btn btn-ebooking-orange`}>Back to homepage</a>
                        </div>
                    </div>

                </form>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAWBSuccess);