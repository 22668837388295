import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class Account extends React.Component {
        constructor(props) {
		super(props);
	}
    render() {
        const {  accountData } = this.props;
        
        return (
            <div className="accounts">
                    {accountData.map((account, i) => {     
                        if(account.active){
                            return (<div key={i} className="account active">
                                        {i+1}. {account.name}
                                    </div>) 
                        }else{
                            return (<div key={i} className="account">
                                    <Link className="validated" to={`${process.env.PUBLIC_URL}/${account.link}`}>{i+1}.<span> {account.name}</span></Link>
                                </div>) 
                         
                        }
                                           
                     })}
                    
            </div>
        );
    }
}

Account.propTypes = {
    onClick: PropTypes.func,
};

