import * as ActionTypes from '../actionTypes';

const initialState = {
    countries: [],
    state: "COUNTRY_GETTING_DATA"
};

export default function reducer(state = initialState, action) {
    switch (action.type) {   
        case ActionTypes.COUNTRY_CHANGE_DATA: {
            var newState = { ...state };
            newState['countries'] = action.payload;
            newState['state'] = "COUNTRY_LOADED_DATA";            
            return Object.assign({}, state, newState)
        }        
    }
    return state;
}