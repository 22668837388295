import React from 'react';
import StepProgress from '../../layouts/partials/StepProgress';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import EbookingInputError from '../EbookingInputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import * as authActions from '../../ninja-redux/actions/authActions';

let stateLoginChanged = false;
// var a = 0;
class Commodity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            errors: {},
            showAddForm: false,
            unit_number_of_pieces: "",
            unit_length: "",
            unit_width: "",
            unit_height: "",
            unit_volume: "",
        };
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUnit = this.handleChangeUnit.bind(this);
        this.validateUnit = this.validateUnit.bind(this);
        this.addUnit = this.addUnit.bind(this);
        this.removeAddedUnit = this.removeAddedUnit.bind(this);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        if (this.props.match.path === "/supplier-update" || this.props.match.path === "/supplier-update/") {
            this.props.supplierActions.supplierReset().then(res => { });
        }
        var { supplier, history } = this.props;
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var ord = parsedParams.ord;
        var awb = parsedParams.awb;
        if (awb != undefined && awb != "" && ord != undefined && ord != "" && !this.props.supplier.api_checked) {
            ord = ord.trim();
            if (ord.length != parsedParams.ord.length) {
                history.push('/supplier-update/?awb=' + awb + '&ord=' + ord);
            }
            this.props.supplierActions.supplierChangeData({ fieldName: "awb", fieldValue: awb }).then(res => { });
            this.props.supplierActions.supplierChangeData({ fieldName: "ord", fieldValue: ord }).then(res => { });
            this.checkAwb(awb, ord);
        }
        this.setState({
            unit_number_of_pieces: supplier.unit_number_of_pieces,
            unit_length: supplier.unit_length,
            unit_width: supplier.unit_width,
            unit_height: supplier.unit_height,
            unit_volume: supplier.unit_volume,
        });
    }

    checkAwb = async (awbNumber, orderNumber) => {
        this.props.supplierActions.checkAWB({ awbnum: awbNumber, ord: orderNumber });
    }

    showAddForm = (v) => {
        this.setState({ showAddForm: v });
    }

    handleChangeUnit(event) {
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        this.state[name] = value;
        this.validateUnit();
    }

    validateUnit = async (event) => {
        var { errors, unit_height, unit_number_of_pieces, unit_length, unit_width } = this.state;
        var validator = new Validator();
        var error = false;
        errors["unit_number_of_pieces"] = "";
        errors["unit_height"] = "";
        errors["unit_length"] = "";
        errors["unit_width"] = "";
        if (!validator.number(unit_number_of_pieces) || parseInt(unit_number_of_pieces) < 1) {
            error = true;
            errors["unit_number_of_pieces"] = "Input Number";
        }
        if (!validator.currency(unit_height) || parseFloat(unit_height) <= 0) {
            error = true;
            errors["unit_height"] = "Input Number";
        }
        if (!validator.currency(unit_length) || parseFloat(unit_length) <= 0) {
            error = true;
            errors["unit_length"] = "Input Number";
        }
        if (!validator.currency(unit_width) || parseFloat(unit_width) <= 0) {
            error = true;
            errors["unit_width"] = "Input Number";
        }
        this.setState({ errors });
        await this.calculatorVolume();
    }

    calculatorVolume = async () => {
        var validator = new Validator();
        var { supplier } = this.props;
        var { unit_height, unit_length, unit_width, unit_volume, unit_number_of_pieces } = this.state;
        if (unit_height != "" && unit_length != "" && unit_width != "") {
            unit_volume = unit_height * unit_length * unit_width * 0.000001;
            supplier.unit_height = unit_height;
            supplier.unit_length = unit_length;
            supplier.unit_width = unit_width;
            supplier.unit_volume = unit_volume.toFixed(3);
            this.setState({ "unit_volume": unit_volume.toFixed(3) });
        } else {
            supplier.unit_volume = "";
        }

        var totalNumOfPieces = 0;
        var totalVolume = 0;

        supplier.Dimensions.forEach(u => {
            totalNumOfPieces += parseInt(u.Pcs);
            totalVolume += parseFloat(u.Cbm);
        });


        if (validator.number(unit_number_of_pieces) && parseInt(unit_number_of_pieces) > 0) {
            totalNumOfPieces += parseInt(unit_number_of_pieces);
            supplier.unit_number_of_pieces = unit_number_of_pieces;
        }

        if (supplier.unit_volume !== "" && parseFloat(supplier.unit_volume) > 0) {
            totalVolume += parseFloat(supplier.unit_volume) * parseInt(supplier.unit_number_of_pieces);
        }

        if (totalNumOfPieces < 1) {
            supplier.units_total_num_of_pieces = "";
        } else {
            supplier.units_total_num_of_pieces = totalNumOfPieces;
        }
        if (totalVolume <= 0) {
            supplier.units_total_volume = "";
        } else {
            supplier.units_total_volume = totalVolume.toFixed(3);
        }

        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    addUnit = (event) => {
        var { errors, unit_height, unit_number_of_pieces, unit_length, unit_width, unit_volume, showAddPiecesForm } = this.state;
        var { supplier } = this.props;
        var validator = new Validator();
        var error = false;
        errors["unit_number_of_pieces"] = "";
        errors["unit_height"] = "";
        errors["unit_length"] = "";
        errors["unit_width"] = "";
        errors["overlap_shipment"] = "";
        errors["Dimensions"] = "";
        if (unit_number_of_pieces == "" || !validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0) {
            error = true;
            errors["unit_number_of_pieces"] = "Input Number";
        }
        if (unit_height == "" || !validator.currency(unit_height) || parseFloat(unit_height) <= 0) {
            error = true;
            errors["unit_height"] = "Input Number";
        }
        if (unit_length == "" || !validator.currency(unit_length) || parseFloat(unit_length) <= 0) {
            error = true;
            errors["unit_length"] = "Input Number";
        }
        if (unit_width == "" || !validator.currency(unit_width) || parseFloat(unit_width) <= 0) {
            error = true;
            errors["unit_width"] = "Input Number";
        }
        this.setState({ errors });
        if (!error) {
            supplier.Dimensions.push({
                'unitname': supplier.units_type_short.toUpperCase(),
                'Pcs': parseInt(unit_number_of_pieces),
                'Height': parseFloat(unit_height),
                'Length': parseFloat(unit_length),
                'Width': parseFloat(unit_width),
                'Cbm': parseFloat(unit_volume)
            });

            var overlap_shipment_check = false;
            supplier.Dimensions.forEach(u => {
                if (supplier.units_type_short == "CM") {
                    var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                    if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                        overlap_shipment_check = true;
                    }
                } else {
                    var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                    if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                        overlap_shipment_check = true;
                    }
                }
            });
            supplier.overlap_shipment_check = overlap_shipment_check;
            supplier.unit_number_of_pieces = "";
            supplier.unit_height = "";
            supplier.unit_length = "";
            supplier.unit_width = "";
            supplier.unit_volume = "";
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            this.setState({ unit_number_of_pieces: '', unit_height: '', unit_length: '', unit_width: '', unit_volume: '', showAddForm: false });
            this.calculatorUnitTotal();
        }
    }

    removeAddedUnit = (removeIndex) => {
        const { errors } = this.state;
        var { supplier } = this.props;
        supplier.Dimensions.splice(removeIndex, 1);
        var overlap_shipment_check = false;

        supplier.Dimensions.forEach(u => {
            if (supplier.units_type_short == "CM") {
                var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                    overlap_shipment_check = true;
                }
            } else {
                var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                    overlap_shipment_check = true;
                }
            }
        });
        supplier.overlap_shipment_check = overlap_shipment_check;
        errors["overlap_shipment"] = "";
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        this.setState({ errors });
        this.calculatorUnitTotal();
    }

    calculatorUnitTotal = () => {
        var { errors } = this.state;
        var { supplier } = this.props;
        if (supplier.Dimensions.length > 0) {
            supplier.units_total_num_of_pieces = 0;
            supplier.units_total_volume = 0;
            supplier.Dimensions.forEach(u => {
                supplier.units_total_num_of_pieces += parseInt(u.Pcs);
                var Cbm = parseFloat(u.Cbm) * parseInt(u.Pcs);
                supplier.units_total_volume += Cbm;
            });
            supplier.units_total_volume = supplier.units_total_volume.toFixed(3);
        } else {
            supplier.units_total_num_of_pieces = "";
            supplier.units_total_volume = "";
        }

        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        errors["units_total_num_of_pieces"] = "";
        errors["units_total_volume"] = "";
        this.setState({ errors });
        this.calculatorTotalChargableWeight(supplier.units_total_weight);
    }

    calculatorTotalChargableWeight = (totalWeight) => {
        var { errors } = this.state;
        var { supplier } = this.props;
        var totalChargableWeight = "";
        var validator = new Validator();
        if (supplier.Dimensions.length > 0) {
            var totalVolume = parseFloat(supplier.units_total_volume) / 6 * 1000;
            if (supplier.units_type_short != "CM") {
                totalVolume = totalVolume * 16.387064;
            }
            if (totalWeight != "" && validator.currency(totalWeight)) {
                totalWeight = parseFloat(totalWeight);

                totalChargableWeight = Math.max(totalVolume, totalWeight);
                supplier.units_total_chargable_weight = totalChargableWeight.toFixed(1);
            } else {
                supplier.units_total_chargable_weight = "";
            }
        } else {
            if (totalWeight != "" && validator.currency(totalWeight) && supplier.units_total_volume != "" && validator.currency(supplier.units_total_volume)) {
                totalWeight = parseFloat(totalWeight);
                var totalVolume = parseFloat(supplier.units_total_volume) / 6 * 1000;
                if (supplier.units_type_short != "CM") {
                    totalVolume = totalVolume * 16.387064;
                }
                totalChargableWeight = Math.max(totalVolume, totalWeight);
                supplier.units_total_chargable_weight = totalChargableWeight.toFixed(1);
            } else {
                supplier.units_total_chargable_weight = "";
            }
        }
        errors['units_total_chargable_weight'] = "";
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        this.setState({ errors });
    }

    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { supplier } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var validator = new Validator();
        if (name == "units_total_num_of_pieces") {
            if (value != "" && validator.number(value)) {
                errors["units_total_num_of_pieces"] = "";
            }
        }
        if (name == "units_total_volume") {
            if (value != "" && validator.currency(value)) {
                errors["units_total_volume"] = "";
            }
        }
        if (name == "units_total_weight") {
            if (value != "" && validator.currency(value)) {
                errors["units_total_weight"] = "";
                var currentWeight = { ...supplier.units_total_weight };
                var newWeight = parseFloat(supplier.units_total_weight).toFixed(1);
                if (currentWeight != newWeight) {
                    supplier.units_total_weight = newWeight;
                    this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
                    this.calculatorTotalChargableWeight(newWeight);

                }
            }
        }
        if (name == "units_total_chargable_weight") {
            if (value == "" || (value != "" && validator.currency(value))) {
                errors["units_total_chargable_weight"] = "";
            }
        }
        if (name == "overlap_shipment") {
            errors["overlap_shipment"] = "";
        }

        this.setState({ errors });
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var { supplier } = this.props;
        if (name === "units_type") {
            if (value === "Centimeters") {
                supplier["units_type_short"] = "CM";
            } else {
                supplier["units_type"] = "CM";
                supplier["units_type_short"] = "IN";
            }
            supplier["units_type"] = value;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            await this.unitTypeChange();
        } else if (name == "units_total_weight") {
            supplier[name] = value;
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
            this.calculatorTotalChargableWeight(value);
        } else if (name == "overlap_shipment") {
            errors["overlap_shipment"] = "";
            if (value) {
                value = "Overlap";
            } else {
                value = "NonOverlap";
            }
            supplier[name] = value;
            this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        } else {
            supplier[name] = value;
            this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        }
        errors[name] = "";
        this.setState({ errors });
    }

    unitTypeChange = async () => {
        var { supplier } = this.props;
        if (supplier.Dimensions.length > 0) {
            for (let i = 0; i < supplier.Dimensions.length; i++) {
                supplier.Dimensions[i].unitname = supplier.units_type_short;
            }
            this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
        }
        return true;
    }

    /* Validate form */
    isValidation() {
        var { unit_height, unit_number_of_pieces, unit_length, unit_width, unit_volume } = this.state;
        this.setState({ errors: {}, errorMessage: '' });
        var { supplier } = this.props;
        var errors = {};
        var isError = false;
        var validator = new Validator();

        if ((unit_height || unit_length || unit_width) && unit_number_of_pieces === "") {
            isError = true;
            errors["unit_number_of_pieces"] = "Input Number";
            errors["Dimensions"] = "Please enter data";
        }
        if ((unit_number_of_pieces || unit_length || unit_width) && unit_height === "") {
            isError = true;
            errors["unit_height"] = "Input Number";
            errors["Dimensions"] = "Please enter data";
        }
        if ((unit_height || unit_number_of_pieces || unit_width) && unit_length === "") {
            isError = true;
            errors["unit_length"] = "Input Number";
            errors["Dimensions"] = "Please enter data";
        }
        if ((unit_height || unit_length || unit_number_of_pieces) && unit_width === "") {
            isError = true;
            errors["unit_width"] = "Input Number";
            errors["Dimensions"] = "Please enter data";
        }

        if (unit_number_of_pieces !== "" && (!validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0)) {
            isError = true;
            errors["unit_number_of_pieces"] = "Input Number";
        }
        if (unit_height !== "" && (!validator.currency(unit_height) || parseFloat(unit_height) <= 0)) {
            isError = true;
            errors["unit_height"] = "Input Number";
        }
        if (unit_length !== "" && (!validator.currency(unit_length) || parseFloat(unit_length) <= 0)) {
            isError = true;
            errors["unit_length"] = "Input Number";
        }
        if (unit_width !== "" && (!validator.currency(unit_width) || parseFloat(unit_width) <= 0)) {
            isError = true;
            errors["unit_width"] = "Input Number";
        }

        if (!supplier.Dimensions.length) {
            errors["Dimensions"] = "Please enter dimensions";
            isError = true;
        }
        if (supplier.Dimensions.length && (supplier.units_total_num_of_pieces == "" || supplier.units_total_num_of_pieces <= 0 || !validator.number(supplier.units_total_num_of_pieces))) {
            errors['units_total_num_of_pieces'] = "Required";
            isError = true;
        }
        if (supplier.units_total_weight == "" || supplier.units_total_weight <= 0 || !validator.currency(supplier.units_total_weight)) {
            errors['units_total_weight'] = "Required";
            isError = true;
        }
        if (supplier.units_total_chargable_weight != "" && !validator.currency(supplier.units_total_chargable_weight)) {
            errors['units_total_chargable_weight'] = "Number only";
            isError = true;
        }

        if (supplier.units_total_volume == "" || parseFloat(supplier.units_total_volume) <= 0 || !validator.currency(supplier.units_total_volume)) {
            errors['units_total_volume'] = "Required";
            isError = true;
        }
        if (supplier.overlap_shipment == "" && !supplier.overlap_shipment_check) {
            errors['overlap_shipment'] = "Is this an overlap shipment?";
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
        }

        return isError;
    }

    checkDimensions = async () => {
        var { unit_height, unit_number_of_pieces, unit_length, unit_width, unit_volume } = this.state;
        var { supplier } = this.props;
        if (unit_number_of_pieces || unit_height || unit_width || unit_length) {
            this.addUnit();
        }
        var { supplier } = this.props;
        supplier.unit_number_of_pieces = "";
        supplier.unit_height = "";
        supplier.unit_length = "";
        supplier.unit_width = "";
        supplier.unit_volume = "";
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        await this.checkDimensions();
        const { history, supplier } = this.props;

        if (this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }

        window.scrollToTop();
        history.push('/supplier-update/special-instruction/?awb=' + supplier.awb + '&ord=' + supplier.ord);
    }

    render() {
        var { errors, errorMessage, showAddForm } = this.state;
        var { supplier, loading } = this.props;
        return (
            <div className="ebooking supplier">
                <form autoComplete="off" className="form-block">
                    <div className="header-menu">
                        <div className="row">
                            <div className="col-6 text-left">
                                <a href="https://www.cal-cargo.com/" className="main-logo"><img src="/images/0000332.png" /></a>
                            </div>
                            <div className="col-6 text-right">
                                <span className="supplier-details-awb">Details for AWB {supplier.awb}</span>
                            </div>
                        </div>
                    </div>
                    <div className="container block-form mt-5">
                        <div className="form-content">
                            <div className="content-title-medium text-center text-blue mt-3" style={{ "maxWidth": "420px", "margin": "0px auto" }}>Complete Details</div>
                            <div className="content-text-desc text-center" style={{ "maxWidth": "565px", "margin": "0px auto" }}>Fill in the following details to update your details and get your shippment going.</div>
                        </div>
                    </div>

                    <div className={`container block-form mt-3 ${(supplier.api_checked || loading.isLoading) ? 'd-none' : ''}`}>
                        <div className="form-content">
                            <div className={`form-group mt-3`}>
                                <div className="alert alert-danger" role="alert">
                                    Order details not found.  If issue persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container block-form mt-5">
                        <h1 className="formTitle mb-3">Shipment Details</h1>
                        <div className="form-content no_padding">
                            <div className="form-rows ebooking-deliver">
                                <div className="row">
                                    <div className="col-md-11 div_center">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="form-rows">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="form-group col-12 form-radio form-rows">
                                                                    <label className="radio-container">Centimeters
                                                                    <input type="radio" name="units_type" value="Centimeters" checked={supplier.units_type == "Centimeters"} onChange={this.handleChange} />
                                                                        <span className={`checkmark ${(errors.units_type) ? 'input-error' : ''}`}></span>
                                                                    </label>
                                                                    <label className="radio-container">Inches
                                                                    <input type="radio" name="units_type" value="Inches" checked={supplier.units_type == "Inches"} onChange={this.handleChange} />
                                                                        <span className={`checkmark ${(errors.units_type) ? 'input-error' : ''}`}></span>
                                                                    </label>
                                                                    <div className="clb"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`form-rows`}>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="form-group col-12">
                                                                    <table className="table-list-pieces">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className="text-center ip-col">Pieces</th>
                                                                                <th scope="col" className="text-center ip-col">Length</th>
                                                                                <th scope="col" className="text-center ip-col">Width</th>
                                                                                <th scope="col" className="text-center ip-col">Height</th>
                                                                                <th scope="col" className="text-center">Volume (CBM)</th>
                                                                                <th style={{ "width": "30px" }} scope="col" className="text-center last"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                supplier.Dimensions.map((op, i) => {
                                                                                    return <tr key={i} className="no-empty">
                                                                                        <td scope="row"><p><span>{op.Pcs}</span></p></td>
                                                                                        <td><p><span>{op.Length}</span> <span className="un">{op.unitname.toLowerCase()}</span></p></td>
                                                                                        <td><p><span>{op.Width}</span> <span className="un">{op.unitname.toLowerCase()}</span></p></td>
                                                                                        <td><p><span>{op.Height}</span> <span className="un">{op.unitname.toLowerCase()}</span></p></td>
                                                                                        <td><p><span>{op.Cbm}</span> <span className="un">{op.unitname.toLowerCase()}³</span></p></td>
                                                                                        <td className="last"><span><button type="button" className="btn btnRemovePiece btn-block" onClick={this.removeAddedUnit.bind(this, i)}></button></span></td>
                                                                                    </tr>;
                                                                                })
                                                                            }

                                                                            <tr className={`${showAddForm ? 'd-none' : 'd-none'}`}>
                                                                                <td colSpan={5}>
                                                                                    <button type="button" className="btn-add-more-unit" onClick={this.showAddForm.bind(this, true)}>Add More</button>
                                                                                </td>
                                                                                <th style={{ "width": "30px" }} scope="col" className="text-center last"></th>
                                                                            </tr>
                                                                            <tr className={`${showAddForm ? '' : ''}`}>
                                                                                <td>
                                                                                    <span><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_number_of_pieces) ? 'input-error' : ''}`} name="unit_number_of_pieces" id="unit_number_of_pieces" autoComplete={"off"} value={this.state.unit_number_of_pieces} onChange={this.handleChangeUnit} /></span>
                                                                                </td>
                                                                                <td>
                                                                                    <span><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_length) ? 'input-error' : ''}`} id="unit_length" name="unit_length" autoComplete={"off"} maxLength={7} value={this.state.unit_length} onChange={this.handleChangeUnit} /></span>
                                                                                </td>
                                                                                <td>
                                                                                    <span><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_width) ? 'input-error' : ''}`} id="unit_width" name="unit_width" autoComplete={"off"} maxLength={7} value={this.state.unit_width} onChange={this.handleChangeUnit} /></span>
                                                                                </td>
                                                                                <td>
                                                                                    <span><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_height) ? 'input-error' : ''}`} id="unit_height" name="unit_height" autoComplete={"off"} maxLength={7} value={this.state.unit_height} onChange={this.handleChangeUnit} /></span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="bg-grey">{this.state.unit_volume} {(supplier.units_type_short && this.state.unit_volume != "") ? supplier.units_type_short.toLowerCase() + "³" : ""}</span>
                                                                                </td>
                                                                                <td className="last">
                                                                                    <span className="bg-orange"><button type="button" className="btn btn-ebooking-orange" onClick={this.addUnit}><i className="playAdd"></i></button></span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className={`${errors.Dimensions ? "" : "d-none"}`}>
                                                                        <InputError error={errors.Dimensions} className="bg-red" class="text-center" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-rows">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="row">
                                                                <div className="form-group col-12 form-radio form-rows">
                                                                    <h2 className="totalTitle">Your Total</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-rows">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <table className="table_piece_total">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="units_total_num_of_pieces">Total Number of Pieces</label>
                                                                        </td>
                                                                        <td style={{ "width": "70px" }}>
                                                                            <input type="text" className={`form-control error-full-radius ${(errors.units_total_num_of_pieces) ? 'input-error' : ''}`} id="units_total_num_of_pieces" name="units_total_num_of_pieces" value={supplier.units_total_num_of_pieces ? supplier.units_total_num_of_pieces : ""} readOnly={supplier.Dimensions && supplier.Dimensions.length ? true : true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                            {/* <InputError error={errors.units_total_num_of_pieces} /> */}
                                                                        </td>
                                                                        <td style={{ "width": "20px" }}>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="units_total_volume">Total Volume</label>
                                                                        </td>
                                                                        <td style={{ "width": "70px" }}>
                                                                            <input type="text" className={`form-control error-full-radius ${(errors.units_total_volume) ? 'input-error' : ''}`} id="units_total_volume" name="units_total_volume" value={supplier.units_total_volume ? supplier.units_total_volume : ""} readOnly={supplier.Dimensions && supplier.Dimensions.length ? true : true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                            {/* <InputError error={errors.units_total_volume} /> */}
                                                                        </td>
                                                                        <td style={{ "width": "20px" }}>
                                                                            <label className="un-label text-right">{supplier.units_type_short ? supplier.units_type_short.toLowerCase() : ""}³</label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="units_total_weight">Total Weight</label>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" className={`form-control error-full-radius ${(errors.units_total_weight) ? 'input-error' : ''}`} id="units_total_weight" name="units_total_weight" value={supplier.units_total_weight >= 0 ? supplier.units_total_weight : ""} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                        </td>
                                                                        <td style={{ "width": "20px" }}>
                                                                            <label className="un-label text-right">kg</label>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <label htmlFor="units_total_chargable_weight">Total Chargeable Weight</label>
                                                                        </td>
                                                                        <td style={{ "width": "70px" }}>
                                                                            <input type="text" className={`form-control error-full-radius ${(errors.units_total_chargable_weight) ? 'input-error' : ''}`} id="units_total_chargable_weight" name="units_total_chargable_weight" maxLength={10} value={supplier.units_total_chargable_weight ? supplier.units_total_chargable_weight : ""} readOnly={true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                        </td>
                                                                        <td style={{ "width": "20px" }}>
                                                                            <label className="un-label text-right">kg</label>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group pt-2">
                                                                <label className={`checkbox-container pdl-20 ${(supplier.overlap_shipment_check) ? 'disabled' : ''}`}>Overlap shipment
                                                                            <input type="checkbox" name="overlap_shipment" value="Overlap" disabled={(supplier.overlap_shipment_check) ? true : false} checked={(supplier.overlap_shipment == "Overlap" || supplier.overlap_shipment_check) ? true : false} onChange={this.handleChange} />
                                                                    <span className={`checkmark-checkbox ${(errors.overlap_shipment) ? 'input-error' : ''}`}></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`show-bottom-error ${errors.overlap_shipment ? "" : "hide"}`}>
                                                    <InputError error={errors.overlap_shipment} class="bg-red" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container block-form mt-5">
                        <div className="dv-button-footer div_center text-center">
                            <button type="button" className="btn btn-lg btn-orange" disabled={!supplier.api_checked} onClick={this.handSubmit}>Next Step</button>
                            <button type="button" className="btn btn-lg btn-link just-text">Three steps left</button>
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Commodity);