import React from 'react';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
/**
 * Validator
 */
import InputError from './InputError';
import EbookingInputError from './EbookingInputError';
import Validator from '../libs/Validator';
import Notification from './Notification';
import * as MESSAGES from '../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../ninja-redux/actions/ebookingActions';
import * as claimActions from '../ninja-redux/actions/claimActions';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as helpActions from '../ninja-redux/actions/helpActions';
import * as authActions from '../ninja-redux/actions/authActions';

import queryString from 'query-string';


class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: '',
                password: ''
            },
            isError: false,
            errorMessage: null,
            errors: {},
            backUrl: "",
            hasPopup: false
        };
        this.backToHome = this.backToHome.bind(this);
    }
    backToHome = () => {
        // var { history } = this.props;
        // history.push('/');
        window.location = "https://www.cal-cargo.com/";
    }
    componentDidMount() {
        var { history, auth } = this.props;
        var { backUrl } = this.state;
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        backUrl = parsedParams.back;
        var showRegister = parsedParams.showRegister;
        if (!backUrl && backUrl == undefined) {
            backUrl = "/dashboard";
        } else {
            backUrl = base64.decode(backUrl);
        }
        if (!showRegister && showRegister == undefined) {
            showRegister = false;
        } else {
            showRegister = true;
            this.handlerShowPopup('show-register-form');
        }

        this.setState({ backUrl });
        var authState = auth.state;
        if (authState == 'LOGGED_IN' || authState == 'NEW_PASSWORD_REQUIRED' || authState == 'EMAIL_VERIFICATION_REQUIRED') {
            history.push(this.state.backUrl);
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";        
        window.RenderRecaptchRegister();
        this.timerID = setInterval(
            () => this.checkHashPopup(),
            500
        );
        window.addEventListener("message", this.handleFrameTasks);
    }

    handleFrameTasks = (e) => {
        var messageData = e.data + "";
        if (messageData.indexOf("doLoginErrorMessage-") >= 0) {   
            this.setState({ errorMessage: messageData.replace("doLoginErrorMessage-", "") });
        }
    }

    checkHashPopup = () => {
        var { hasPopup } = this.state;
        hasPopup = window.checkHasModalShowing();
        this.setState({ hasPopup });
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleFrameTasks);
        if (this.timerID) {
            clearInterval(this.timerID);
        }
    }

    componentWillReceiveProps(nextProps) {
        var { history } = this.props;
        if (nextProps.auth.state === "LOGGED_IN" || nextProps.auth.state === "NEW_PASSWORD_REQUIRED" || nextProps.auth.state === "EMAIL_VERIFICATION_REQUIRED") {
            history.push(this.state.backUrl);
        }
    }

    handleChange = (event) => {
        let { target: { name, value } } = event;
        const { user, errors } = this.state;
        user[name] = value;
        errors[name] = "";
        if (name == 'email') {
            user[name] = value.toLowerCase();
        }
        this.setState({ user, errors });
    }

    /* Validate form */
    isValidation() {
        var { errorMessage } = this.state;
        errorMessage = "";
        this.setState({ errors: {}, errorMessage });
        var errors = {};
        var isError = false;
        var validator = new Validator();

        const { user } = this.state;
        if (!validator.email(user.email)) {
            errors['email'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }
        if (!validator.required(user.password)) {
            errors['password'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        //Bỏ validate captcha
        if (!window.getVerifiedRegister()) {
            errorMessage = MESSAGES.ERROR_CAPTCHA;
            isError = true;
        }
        if (isError) {
            this.setState({ errors, errorMessage });
            return false;
        }
        return true;
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        const { history } = this.props;
        const { user } = this.state;

        window.parent.postMessage("bacKUrl-" + base64.encode(""), '*');
        // window.parent.postMessage("loginKey-" + base64.encode(user.password), '*');
        window.parent.postMessage("loginKey-" + window.btoa(encodeURIComponent(user.password)), "*");
        this.props.authActions.authLogin(user.email, user.password).then(api_response => {
            if (api_response.success) {
                window.parent.postMessage("bacKUrl-" + base64.encode(this.state.backUrl), '*');
                // history.push(this.state.backUrl);
            } else {
                this.setState({ errorMessage: api_response.message });
            }
            if (api_response.mustRefresh) {
                window.location.reload();
            }
        }).catch((err) => {
            this.props.loadingActions.loadingChangeData(false);
            this.setState({ errorMessage: err.message });
        });
    }

    handlerShowPopup = (popupName) => {
        var { hasPopup } = this.state;
        hasPopup = true;
        this.setState({ hasPopup });
        window.parent.postMessage(popupName, '*');
    }

    render() {
        const { errors, user, errorMessage, hasPopup } = this.state;

        return (
            <div className="page-main">
                <div className="div-head">
                    <button type="button" className="button-back" onClick={this.backToHome}>Back Home</button>
                </div>
                <div className={`page-container login-page ${hasPopup ? 'd-none' : ''}`}>
                    <div className="page-content text-center">
                        <div className="login-box">
                            <div className="big-text">Login</div>
                            <div className="content-text-desc div_center">Welcome back.</div>
                            <div className="form-box">
                                <div className={`form-group ${errorMessage ? "" : "hide"}`}>
                                    <InputError error={errorMessage} class="bg-red" />
                                </div>

                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" name="email" value={user.email} onChange={this.handleChange} className={`form-control ${(errors.email) ? 'input-error' : ''}`} />
                                    <EbookingInputError error={errors.email} />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" name="password" onChange={this.handleChange} className={`form-control ${(errors.password) ? 'input-error' : ''}`} />
                                    <EbookingInputError error={errors.password} />
                                </div>
                                <div className="clb">
                                    <div className="forgot-link text-right">
                                        <a href="javascript:void(0);" onClick={this.handlerShowPopup.bind(this, "show-reset-pass-form")}>Forgot Password</a>
                                    </div>
                                    <div className="clb"></div>
                                </div>

                                <div className="form-group mt-3">
                                    <div className="re-captcha register div_center mb-0">
                                        <div id="RecaptchaRegister"></div>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                            <div className="content-buttons div_center mt-3 mt-md-5">
                                <button type="submit" className="btn btn-orange" onClick={this.handSubmit}>Enter</button>
                            </div>
                            <div className="login-note mt-3 mb-1">Don't have an account? <a href="javascript:void(0);" onClick={this.handlerShowPopup.bind(this, "show-register-form")}>Sign Up</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);