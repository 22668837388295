import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
import queryString from 'query-string';
import EbookingInputError from '../EbookingInputError';
import BookingHelper from '../ebooking/BookingHelper';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import validator from 'validator';
import InputError from '../InputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as negotiationActions from '../../ninja-redux/actions/negotiationActions';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';

var intervalID;
class QuotePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingFields: true,
            loadingFieldsError: false,
            loadedAWB: true,
            errorMessageOther: {},
            errorMessage: null,
            isEdit: false,
            errors: {},
            costDatas: [],
            total: 0,
            paymentUrl: "",
            pricingCase: "",
            loadAWBError: false,
            gettedLink: false,
            createSCTransaction: true,
            scID: "",
            awb: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            amount: 0,
            cur: '',
            product_name: '',
            paymeToken: {},
            createToken: true,
            orderID: "",
            maxTimeLoadWebf: 90,
            errorLoadWebf: false,
            idNumber: '',
            submitedIDNumber: 0,
            maxSubmitIDNumber: 5,
            steps: [
                {
                    'link': 'quotes/flights',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Flights'
                },
                {
                    'link': 'quotes/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'quotes/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ]
        };
    }

    postSafeCharge = async (amount, currency, token, orderID) => {
        var { quotes } = this.props;
        let scTransactionData = await this.props.quotesActions.buildSCData(quotes);
        scTransactionData.orderID = orderID;
        scTransactionData.paymeToken = token;
        return scTransactionData;
    }

    async componentDidMount() {
        var { steps, product_name, firstName, lastName, email, phone, amount, cur } = this.state;
        var { history, quotes, auth } = this.props;
        var authState = auth.state;
        // console.log(quotes);
        // this.setState({ firstName: 'dfdfdf', lastName: 'dfdfdf', email:'hungnm@gmail.com', phone:'09845343434', amount:100, cur:'USD', product_name:"sdfdfd" })
        let quoteID = this.props.match.params.quoteID;
        if (quotes.CProfAU || authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') {
            product_name = 'Payment for ' + quotes.CProfNum;
            amount = quotes.offer_total;
            cur = quotes.offer_currency.label;
            email = auth.user_email;
            phone = auth.phonenum;
            steps = steps.map((step, i) => {
                if (quotes.secretKey) {
                    if (i < 2) {
                        step.link += '/' + quoteID;
                    } else {
                        step.link = 'quotes/review/' + quotes.secretKey + '/' + quoteID;
                    }
                } else {
                    step.link += '/' + quoteID;
                }
                return step;
            })

            if (auth.IsGSA) {
                steps[1].link = 'quotes/participants-gsa/' + quoteID;
            }
            this.setState({ steps, firstName, lastName, email, phone, amount, cur, product_name });
        } else {
            history.push('/quotes/flights/' + quoteID);
            return;
        }

        window.addEventListener("message", this.handleFrameTasks);
        window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleFrameTasks);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
    }

    handleFrameTasks = (e) => {
        var { history } = this.props;
        var { idNumber } = this.state;
        // console.log('handleFrameTasks', e);
        if (e.data === 'success-final') {
            history.push('/payment/success?scID=' + this.state.scID + "&awb=" + this.state.awb);
        } else if (e.data === 'load-cc-fields-success') {
            this.setState({ loadingFields: false, loadingFieldsError: false });
        } else if (e.data === 'load-cc-fields-unsuccess') {
            this.setState({ loadingFields: false, loadingFieldsError: true });
        } else {
            try {
                var jsonData = JSON.parse(e.data);
                if (jsonData.submitedid) {
                    idNumber = jsonData.submitedid;
                    this.setState({ idNumber });
                    this.handSubmit();
                }
            }
            catch (err) {
            }
        }
    }

    /* handler form's fields change value */
    handleChange = (event) => {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        if (name === 'firstName') {
            this.setState({ firstName: value });
            var checkFirstName = window.testFirstName(value);
            errors.firstName = !checkFirstName.isValid;
            errors.firstNameRequire = "";
        } else if (name === 'lastName') {
            this.setState({ lastName: value });
            var checkLastName = window.testLastName(value);
            errors.lastName = !checkLastName.isValid;
            errors.lastNameRequire = "";
        }
    }

    handleOnBlur = (event) => {
        const { errors } = this.state;
        const { target, target: { name, type } } = event;
        let value = (type === 'checkbox') ? target.checked : target.value;
        value = value.trim();
        if (name === 'firstName') {
            var checkFirstName = window.testFirstName(value);
            if (checkFirstName.isValid == false) {
                if (value != "") {
                    errors.firstName = !checkFirstName.isValid;
                } else {
                    errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
            this.setState({ firstName: value });
        } else if (name === 'lastName') {
            var checkLastName = window.testLastName(value);
            if (checkLastName.isValid == false) {
                if (value != "") {
                    errors.lastName = !checkLastName.isValid;
                } else {
                    errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
                }
            }
            this.setState({ lastName: value });
        }
        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '', errorMessageOther: {} });

        var errors = {};
        var isError = false;
        var { firstName, lastName } = this.state;
        var checkFirstName = window.testFirstName(firstName.trim());
        if (checkFirstName.isValid == false) {
            isError = true;
            if (firstName != "") {
                errors.firstName = !checkFirstName.isValid;
                errors.firstNameRequire = "Value Invalid";
            } else {
                errors.firstNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }
        var checkLastName = window.testLastName(lastName.trim());
        if (checkLastName.isValid == false) {
            isError = true;
            if (lastName != "") {
                errors.lastName = !checkLastName.isValid;
                errors.lastNameRequire = "Value Invalid";
            } else {
                errors.lastNameRequire = MESSAGES.ERROR_REQUIRED;
            }
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    componentWillUnmount() {
        if (intervalID) {
            clearInterval(intervalID);
        }
        window.cancelPayment();
        window.removeEventListener("message", this.handleFrameTasks);
    }

    getWebf = () => {
        var t = this;
        var { maxTimeLoadWebf } = this.state;
        var timeLoadWebf = 0;
        var { history } = this.props;
        intervalID = setInterval(function () {
            if (timeLoadWebf <= maxTimeLoadWebf) {
                timeLoadWebf += 5;
                t.props.ebookingActions.getWebf(t.state.orderID).then(response => {
                    if (response.success && response.data.Success && response.data.webf != undefined) {
                        t.props.loadingActions.loadingChangeData(false);
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "docno", fieldValue: response.data.docno }).then(responseData => { });
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "webf", fieldValue: response.data.webf }).then(responseData => { });
                        if (intervalID) {
                            clearInterval(intervalID);
                        }
                        history.push('/ebooking/success');
                    }
                });
            } else {
                t.props.loadingActions.loadingChangeData(false);
                if (intervalID) {
                    clearInterval(intervalID);
                }
                t.props.ebookingActions.paymentError(t.state.orderID).then(response => {
                });
                t.setState({ errorLoadWebf: true });
            }
        }, 5000);
    }


    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (!this.isValidation()) {
            return false;
        }
        // return false;
        // this.getAwb(this.state.awb_number_1 + "" + this.state.awb_number_2);
        this.setState({ isError: false, errorMessage: "", errorMessageOther: {} });
        this.props.loadingActions.loadingChangeData(true);
        var { history, auth, quotes } = this.props;
        var orderID = uuid();
        var t = this;
        var { firstName, lastName, amount, product_name, paymeToken, createToken, cur, phone, email, idNumber, submitedIDNumber, maxSubmitIDNumber } = this.state;
        if (!paymeToken.data || createToken) {
            paymeToken = await window.submitTransaction(firstName, lastName, email, phone, orderID, cur, amount + "", idNumber);
            this.setState({ paymeToken, createToken: false });
        }
        if (paymeToken.success) {
            var createRowDB = await this.postSafeCharge(amount, cur, paymeToken.data.token, orderID);

            var dataPass = {
                "sale_price": parseInt(amount * 100),
                "currency": cur,
                "product_name": product_name,
                "transaction_id": orderID,
                "installments": 1,
                "market_fee": 0.00,
                "sale_callback_url": AppConfig.apiUrl + "notifications",
                "sale_return_url": "http://online.cal-cargo.com",
                "capture_buyer": 0,
                "buyer_key": paymeToken.data.token,
                "sale_payment_method": "credit-card",
                "language": "en",
                "bookingData": createRowDB
            };

            //add status data
            let currentDate = moment();
            let statusData = {
                "cporf": parseInt(quotes.CProfAU),
                "status": MESSAGES.STATUS_BOOKED,
                "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
                "statusres": quotes.apiData.statchangeres,
                "userb": quotes.apiData.userb,
                "dept": quotes.apiData.dept,
                "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
            };
            dataPass.statusData = statusData;
            let paymentStatus = await this.props.ebookingActions.doPayment(dataPass, auth.jwtToken);

            this.props.loadingActions.loadingChangeData(false);
            if (paymentStatus.success && paymentStatus.data.payme_status === 'success') {
                localStorage.setItem('DASHBOARD_REFRESH', true);
                // let currentDate = moment();
                // let statusData = {
                //     "cporf": parseInt(quotes.CProfAU),
                //     "status": MESSAGES.STATUS_BOOKED,
                //     "statuscr": currentDate.utc().format('DD/MM/YY HH:mm'),
                //     "statusres": quotes.apiData.statchangeres,
                //     "userb": quotes.apiData.userb,
                //     "dept": quotes.apiData.dept,
                //     "expirydate": quotes.expriresTime && moment.isMoment(quotes.expriresTime) ? quotes.expriresTime.format('DD/MM/YY') : ''
                // };
                // let saveQuotesStat = await this.props.quotesActions.updateQuoteStatus(statusData, auth.jwtToken);
                const paramsAlert = {
                    status: 'waitingbooking', // Approved, Declined
                    quoteId: quotes.CProfAU,
                    secretKey: quotes.secretKey
                }
                await this.props.negotiationActions.custAlert(paramsAlert);
                this.setState({ orderID: paymentStatus.data.transaction_id, loadingFields: true });
                this.props.loadingActions.loadingChangeData(true);
                this.getWebf();
            } else {
                window.createPaymeFrom(AppConfig.paymeMerchantAPIKey, AppConfig.testMode);
                this.setState({ isError: true, errorMessageOther: paymentStatus.data, errorMessage: "", createToken: true });
            }
        } else {

            this.props.loadingActions.loadingChangeData(false);
            if (!paymeToken.error.payload) {
                paymeToken.error.payload = {
                    status_error_details: "credit card data invalid",
                    status_error_code: 'credit card invalid'
                }
            }
            // if (paymeToken.error.payload.status_error_code == 21007 && !submitedIDNumber) {
            if (paymeToken.error.payload.status_error_code == 21007 && submitedIDNumber < maxSubmitIDNumber) {
                submitedIDNumber++;
                createToken = true;
                this.setState({ createToken, submitedIDNumber });
                window.postMessage('show-id-number-form', '*');
            } else {
                this.setState({ isError: true, errorMessage: "", errorMessageOther: paymeToken.error.payload });
            }
        }
    }

    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { paymentUrl, isError, errors, errorMessage, errorMessageOther, cur, amount, loadAWBError, loadedAWB, loadingFieldsError, loadingFields, awb, errorLoadWebf, steps }
            = this.state;
        var { auth } = this.props;
        return (

            <div className="ebooking">
                <StepProgress stepData={steps} progress={100} onClick={this.handClickStep} />

                <div className="container">
                    {/* <Notification isError={errorMessageOther ? true : false} message={errorMessageOther} /> */}
                    <div className={`form-group ${(errorLoadWebf) ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            We encountered an error while creating your airway bill. This error is logged and our team will look into it. We apologize for the inconvenience. You may <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a> at any time.
                        </div>
                    </div>
                    <div className={`form-group ${(errorMessage || loadAWBError) ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                    <div className={`form-group ${errorMessageOther.status_error_details ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Payment failed. {errorMessageOther.status_error_details ? errorMessageOther.status_error_details : ''} (error code: {errorMessageOther.status_error_code ? errorMessageOther.status_error_code : ''}). Please try again. If issue persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                </div>

                <form>
                    <div className="container block-form mb-4">
                        <h1 className="formTitle mb-1">Payment</h1>
                        <div className="form-content no_padding">
                            <div className="container-child div_center">
                                <div className="block-form-content mt-3 div_center form-payment">
                                    <div className={`col-12 form-group mt-4 ${loadingFieldsError ? '' : 'd-none'}`}>
                                        <div className="row">
                                            Load payment form error!
                                        </div>
                                    </div>
                                    <div className={`col-12 form-group bg-blue ${loadingFields ? 'loading' : ''} ${(loadingFieldsError || !loadedAWB || loadAWBError) ? 'd-none' : ''}`}>
                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="firstName">Card Holder First Name</label>
                                                <input type="text" className={`form-control ${(errors.firstName) ? 'text-red' : ''} ${(errors.firstNameRequire) ? 'input-error' : ''}`} id="firstName" name="firstName" value={this.state.firstName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.firstNameRequire} />
                                            </div>
                                            <div className="form-group form-rows col-md-6 col-12">
                                                <label htmlFor="lastName">Card Holder Last Name</label>
                                                <input type="text" className={`form-control ${(errors.lastName) ? 'text-red' : ''} ${(errors.lastNameRequire) ? 'input-error' : ''}`} id="lastName" name="lastName" value={this.state.lastName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.lastNameRequire} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group form-rows col-md-6 col-sm-12">
                                                <label htmlFor="cardNumber">Card Number</label>
                                                <div id="card-number-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-4 col-6">
                                                <label htmlFor="expiration">Expiry</label>
                                                <div id="card-expiration-container" className="form-control"></div>
                                            </div>
                                            <div className="form-group form-rows col-md-2 col-6">
                                                <label htmlFor="CVV">CVV</label>
                                                <div id="card-cvv-container" className="form-control"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group mt-4">
                                        <div className="row">
                                            <button type="button" className={`btn btn-block btn-ebooking-orange ${(loadingFields || loadingFieldsError || !loadedAWB || loadAWBError) ? 'd-none' : ''}`} disabled={(loadingFields || loadingFieldsError || !loadedAWB || loadAWBError || amount <= 0) ? true : false} onClick={this.handSubmit}>Pay {BookingHelper.currencySymbol(cur)}{BookingHelper.formatMoney(amount)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        negotiationActions: bindActionCreators(negotiationActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotePayment);