import React from 'react';
import { Redirect, Route } from 'react-router-dom';

var isAuth = true;
const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  )} />
);

export default PublicRoute;
