import React from 'react';
import queryString from 'query-string';
import base64 from 'base-64';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as authActions from '../ninja-redux/actions/authActions';
import * as helpActions from '../ninja-redux/actions/helpActions';

class NegotiationLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowLoading: false,  
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.handleFrameTasks);
    }

    componentDidUpdate(oldProps) {
        var { history } = this.props.children.props;
        const newProps = this.props;
        if (oldProps.auth.state != "GETTING_DATA" && newProps.auth.state === "GETTING_DATA") {
            this.props.loadingActions.loadingChangeData(true);
            this.props.authActions.authGetLoginData(newProps.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
            });
        }

        if (oldProps.auth.state != "CLICK_LOG_OUT" && newProps.auth.state == "CLICK_LOG_OUT") {
            // history.push("/login");
        }    
    }


    handleFrameTasks = (e) => {
    }

    render() {
        var { loading, auth, country, port } = this.props;      
        return (
            <div>
                <div className={`ajax-loading ${(loading.isLoading || country.state === 'COUNTRY_GETTING_DATA' || port.state === 'PORT_GETTING_DATA') ? 'show' : 'hide'}`} >
                    <div className="loader">
                        <div className="loader__figure"></div>
                        <p className="loader__label">Loading</p>
                    </div>
                </div>
                <div className={`ebooking`}  >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    auth: state.auth,
    country: state.country,
    port: state.port
});

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationLayout);