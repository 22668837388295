import React from 'react';
import { Link } from 'react-router-dom';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authCompleteNewPasswordChallenge } from '../../ninja-redux/actions/authActions';

class HeaderForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowSearchForm: false,
            isShowLinkDashBoard: true,
            isBackToQuote: false
        }
        this.showMobileMenu = this.showMobileMenu.bind(this);
    }

    componentDidMount() {
        // console.log(this.props);
        // console.log(window.location.pathname);
        let isShowLinkDashBoard = (window.location.pathname.includes('dashboard') ? false : true);
        let isBackToQuote = (window.location.pathname.includes('quotes') ? true : false);
        this.setState({ isShowLinkDashBoard, isBackToQuote });
    }

    showMobileMenu() {
        if (this.state.isShowMenu == true) {
            this.setState({ isShowMenu: false });
        } else {
            this.setState({ isShowMenu: true });
        }

        this.props.clickOnHeader(this.state.isShowMenu);
    };

    showSearchForm = () => {
        this.setState({ isShowSearchForm: true });
    };
    hideSearchForm = () => {
        this.setState({ isShowSearchForm: false });
    };
    render() {
        const { isShowMenu, isShowSearchForm } = this.state;
        return (
            <div>
                <div className={`navbar-collapse-mobile${isShowMenu ? ' open' : ''}`} >
                    <div onClick={this.showMobileMenu} className="close-menu">
                        <span>Close</span>
                    </div>
                    <ul className="navbar-nav-mobile text-uppercase ml-auto">
                        {/* <li className="nav-item">
                            <Link className="nav-link-insite js-scroll-trigger" onClick={this.showMobileMenu} to={`${process.env.PUBLIC_URL}/ebooking/step1`}>e-Booking</Link>
                            <div className="plus-button"></div>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="open-submenu" href="#">e-Services</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item"><a href="https://www.cal-cargo.com/tracking/" target="_blank">Tracking</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/flights-schedule/" target="_blank">Flights Schedule</a></li>
                                <li className="menu-item"><a href="https://www.cal-cargo.com/e-services/download/" target="_blank">Downloads</a></li>
                            </ul>
                        </li> */}

                        <li className="nav-item">
                            <Link to={`${process.env.PUBLIC_URL}/dashboard/`}>Back to dashboard</Link>
                        </li>
                        <li className={`nav-item ${((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false) ? 'd-none' : ''}`}>
                            <a href="javascript:void(0)" onClick={this.props.showLoginForm.bind(this, "modalLogin")} className="login btn btn-outline-secondary" id="btn-login-popup">Log In</a>
                        </li>
                        <li className={`nav-item ${((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false) ? 'd-none' : ''}`}>
                            <a href="javascript:void(0)" onClick={this.props.showLoginForm.bind(this, "modalRegister")} className="register btn btn-primary">Sign Up</a>
                        </li>
                        <li className={`nav-item dropdown ${((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false) ? '' : 'd-none'}`}>
                            <a href="#" className="open-submenu">{this.props.auth.user_name ? this.props.auth.user_name : "Unknow Name"}</a>
                            <div className="plus-button"></div>
                            <ul className="sub-menu">
                                <li className="menu-item">
                                    <LogoutButton onClick={this.props.onClickLogout} />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={`ebooking main-header${isShowMenu ? ' move-right' : ''}`}>
                    <div className="header-menu">
                        <a href="https://www.cal-cargo.com/" className="main-logo"><img src="/images/0000332.png" /></a>
                        <button onClick={this.showMobileMenu} className="navbar-toggler navbar-toggler-right" type="button">
                            <i className="fa fa-bars"></i>
                        </button>

                        <ul id="menu-main-menu" className="mega-menu navbar">
                            {
                                this.state.isShowLinkDashBoard ? (
                                    <li className="nav-item">
                                        <Link to={`${process.env.PUBLIC_URL}/dashboard/${this.state.isBackToQuote ? 'quotes' : ''}`}>Back to {this.state.isBackToQuote ? 'quotes' : 'dashboard'}</Link>
                                    </li>
                                ) : null
                            }

                            <li className={`nav-item dropdown ${((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false) ? '' : 'hide'}`}>
                                <a href="#" className="customer nav-item nav-link dropdown-toggle mr-md-2" id="bd-versions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.props.auth.user_name ? this.props.auth.user_name + ", " : ""}{this.props.auth.custdes ? this.props.auth.custdes.replace(this.props.auth.cassname, "") : ""}</a>
                                <div className="dropdown-menu">
                                    <LogoutButton onClick={this.props.onClickLogout} />
                                    {/* <div role="separator" className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#two">two</a>
                                    <div role="separator" className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="#three">three</a> */}
                                </div>
                            </li>
                            <li className={`nav-item header-buttons ${((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false) ? 'hide' : ''}`}>
                                <a href="javascript:void(0)" onClick={this.props.showLoginForm.bind(this, "modalLogin")} className="login btn btn-outline-secondary" id="btn-login-popup">Log In</a>
                                <a href="javascript:void(0)" onClick={this.props.showLoginForm.bind(this, "modalRegister")} className="register btn btn-primary">Sign Up</a>
                                <div className="login-form-bg hide"></div>
                                <div className="login-form hide" id="frmPopupLogin">
                                    <div className="triangle"></div>
                                    <form>
                                        <div className="form-group small-margin">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="form-control" autoComplete={uuid()} id="ipLoginEmail" name="email" value="" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" autoComplete={uuid()} className="form-control" id="ipLoginPassword" name="password" value="" />
                                            <a href="javascript:void(0)" className="forgot-pass">Forgot password</a>
                                        </div>
                                        <div className="login-buttons">
                                            <button type="button" className="btn-login btn btn-warning">Log In</button>
                                        </div>
                                        <div className="register">Not a member yet? Do it <a href="#">  here</a></div>
                                    </form>
                                </div>
                            </li>
                        </ul>

                        <div className="clb"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});


const Header = connect(mapStateToProps, null)(HeaderForm);
export default Header;

const LogoutButton = ({ onClick }) => (
    <a onClick={onClick} className="btnLogout dropdown-item">LOGOUT</a>
);
LogoutButton.propTypes = {
    onClick: PropTypes.func,
};
