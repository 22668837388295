import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import AppConfig from '../../AppConfig';
import PropTypes from 'prop-types';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import * as MESSAGES from '../../libs/Messages';
import Progress from '../Progress';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class ClaimUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            selectedType: '',
            loaded: 0,
            showProgress: false,
            fileTypes: ['Master Airwaybill', 'Formal Claim', 'Packing List', 'Repairs/Parts Replacement Bill', 'Settlement Draft',
                'Subrogation Receipt', 'Pictures', 'Invoice', 'Preliminary Claim', 'Damage/Weight Specification',
                'House Airwaybill', 'Session of Right', 'Proof of Delivery', 'Formal Notice', 'Other'],
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            maxFileSize: 9
        }

        this.onChangeType = this.onChangeType.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    componentDidMount() {

    }

    onChangeType = (event) => {
        var { target, target: { name, type } } = event;
        var value = target.value;
        this.setState({ selectedType: value });
    }

    handleUploadFile(e) {
        var selectorFiles = e.target.files;
        var file = selectorFiles[0];
        this.uploadFile(file);
        e.target.value = null;
    }

    changeProgress = (loaded, total) => {
        var loadedPer = 0;
        if (total > 0) {
            loadedPer = parseInt((loaded / total) * 100);
        }
        this.setState({ loaded: loadedPer });
    }
    makerandom = (length) => {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    uploadFile(file) {
        var reader = new FileReader();
        var eObj = this;

        this.props.loadingActions.loadingChangeData(true);
        reader.addEventListener('loadend', function (e) {
            var name = file.name;
            var nameSplit = name.split('.');
            var fileName = nameSplit[0];
            var filesize = file.size;
            var fileNameBucket = name;
            if (fileName.length < 80) {
                eObj.setState({ loaded: 0, showProgress: true });
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();
                fileName = fileName.replace(/[^\w]/gi, '-');
                //var fileNewName = eObj.props.ClaimNum + "-" + fileName + '-' + moment().format('YYYYMMDDHHmmss') + '.' + ext; 
                var fileNewName = fileName + "-" + eObj.makerandom(5) + "." + ext;
                // var fileNewName = eObj.makerandom(5) +"_"+ name;

                // console.log(filesize, (eObj.state.maxFileSize * 1048576));
                if ((eObj.state.acceptFiles.indexOf(ext) > -1) && filesize <= (eObj.state.maxFileSize * 1048576)) {
                    fileNewName = eObj.props.subFolder + '/' + fileNewName;
                    fetch(AppConfig.apiUpload + "api/service/upload-claim-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            fileNameBucket = json.body.fileName;
                            eObj.setState({ loaded: 0, showProgress: true });
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total);
                                }
                            })
                        })
                        .then(function () {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);

                            var fileData = {
                                filenameshow: name,
                                filename: fileNameBucket,
                                filedes: eObj.state.selectedType,
                                filesize: filesize
                            }
                            eObj.props.onSuccess(fileData, eObj.props.fileIndex);
                        }).catch(e => {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);
                            eObj.setState({ selectedFileType: false, loaded: 0, showProgress: false });
                        });
                } else {
                    eObj.setState({ loaded: 0, showProgress: false });
                    eObj.props.loadingActions.loadingChangeData(false);
                    alert("Please check your type and file size!");

                }
            } else {
                eObj.setState({ loaded: 0, showProgress: false });
                eObj.props.loadingActions.loadingChangeData(false);
                alert("Your file name too long. It should less than 80 characters!");
            }
        });

        reader.readAsArrayBuffer(file);

    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    renderOptions = () => {
        var options = [];
        this.state.fileTypes.map((item, i) => {
            options.push(<option key={i} value={item}>{item}</option>);
        })
        return options;
    }

    render() {

        return (
            <div className="claim-upload-element">
                <div className="row">
                    <div className="col-6 dv-file-type">
                        <select name="fileType" onChange={this.onChangeType}>
                            <option value=""></option>
                            {this.renderOptions()}
                        </select>
                    </div>
                    <div className="col-6 dv-choose-file">
                        <label className={`upload-file  ${this.state.selectedType ? ' ' : 'disabled'}`}>Choose File
                            <input type="file" name="upload" accept="application/pdf, image/png, image/jpeg, application/msword" disabled={this.state.selectedType ? false : true} onChange={this.handleUploadFile} />
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-2">
                        <Progress showProgress={this.state.showProgress} loaded={this.state.loaded} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}
ClaimUpload.propTypes = {
    onSuccess: PropTypes.func,
    fileIndex: PropTypes.string,
    subFolder: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimUpload);