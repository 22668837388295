import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            webf: "",
            docno: "",
            showAttachButton: false,
            breadcrumbs: [
                {
                    'link': 'ebooking',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking',
                    'active': false,
                    'validate': false,
                    'name': 'Shipment Details'
                },
                {
                    'link': 'ebooking/flight-pricing-default',
                    'active': false,
                    'validate': true,
                    'name': 'Choose Flight'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'validate': true,
                    'name': 'Participants'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'validate': true,
                    'name': 'Review Order'
                },
                {
                    'link': 'ebooking/payment',
                    'active': false,
                    'validate': true,
                    'name': 'Payment'
                }
            ]
        };

        this.handleChange = this.handleChange.bind(this);
        this.isValidationWithField = this.isValidationWithField.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handClickSubmit = this.handClickSubmit.bind(this);
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { target, target: { name, type } } = event;
        const value = (target.type == 'checkbox') ? target.checked : target.value;
    }

    handClickSubmit = (e) => {
        const { history } = this.props;
        history.push('/ebooking');
    }

    componentDidMount() {
        //get redux data and set to state   
        var { ebooking } = this.props;
        // console.log(ebooking);
        // this.setState({ ebooking });
        var cloneEbooking = { ...ebooking };
        if (cloneEbooking.webf == "") {
            var params = this.props.location.search;
            const parsedParams = queryString.parse(params);
            var scID = parsedParams.scID;
            if (scID) {
                this.getWebf(scID);
            }
        } else {
            this.setState({ showAttachButton: true, webf: cloneEbooking.webf, docno: cloneEbooking.docno });
        }
        this.props.ebookingActions.ebookingReset().then(res => { });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    getWebf = (scID) => {
        var t = this;
        this.props.loadingActions.loadingChangeData(true);
        setTimeout(function () {
            t.props.ebookingActions.getWebf(scID).then(response => {
                t.props.loadingActions.loadingChangeData(false);
                if (response.success && response.data.Success && response.data.webf != undefined) {
                    t.setState({ showAttachButton: true, webf: response.data.webf, docno: response.data.docno });
                }
            });
        }, 12000);
    }


    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });
        var errors = {};
        return true;
    }

    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }
    isValidationWithField(event) {
        const { errors } = this.state;
        const { target } = event;
        var value = target.value;
        this.setState({ errors });
    }

    render() {
        var { webf, docno } = this.state;
        return (
            <div className="page-main">

                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>
                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className="big-text">Well Done!</div>
                                            <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>
                                                Your booking is complete.
                                            </div>
                                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                                Your reference number is <span className="text-orange">{docno}</span>.
                                            </div>
                                            <div className="content-text-desc div_center" style={{ "maxWidth": "500px" }}>
                                                An email has been sent to you with all the details.
                                            </div>
                                            <div className="content-text-desc div_center mt-4" style={{ "maxWidth": "500px" }}>
                                                If you have any documents you wish to attach to this shipment, you can do so here.
                                            </div>
                                            <div className="content-buttons div_center mt-5">
                                                <Link type="button" to={`${process.env.PUBLIC_URL}/ebooking/attach-files?webf=${webf}`} className={`btn btn-attact mr-3  mb-3 ${this.state.showAttachButton ? "" : "d-none"}`}>Attach Files</Link>
                                                <Link to={`${process.env.PUBLIC_URL}/dashboard/`} className={`btn btn-orange mb-3`}>Back to Dashboard</Link>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Success);