
// Valitions Errors
export const ERROR_EMAIL = "Please enter a valid email.";
export const ERROR_EMAIL_EXISTS = "Email already exists.";
export const ERROR_REQUIRED = "This is a required field.";
// export const ERROR_PASS_RULE = "Min 8 characters, least one digit, lower case, upper case, special character.";
export const ERROR_PASS_RULE = "Password should be at least 6 characters long";
export const ERROR_AWB_NUMBER = "AWB number not valid number, check and try again.";
export const ERROR_AWB_NUMBER2 = "AWB number should be 3 and 8 characters.";
export const ERROR_AWB_NUMBER2_ONLY = "AWB number should be 8 characters.";
// export const ERROR_MINLENGTH = "Min length is 8 characters.";
export const ERROR_MINLENGTH = "Min length is 6 characters.";
export const ERROR_EXLENGTH = "Exact length is ${length} characters.";
export const ERROR_CONFIRM_PASS = "Password does not match the confirm password.";
export const ERROR_NUMBER = "Please enter a valid number.";
export const ERROR_CURRENCY = "Please enter a valid currency format.";
export const ERROR_WEIGHT = "Please enter a valid weight format.";
export const ERROR_CLAIM_ASPECTS = "At least one aspect information below is required";
export const ERROR_CLAIM_AMOUNTS = "At least one more of the amounts (under Claim Amount) should be entered";
export const ERROR_CLAIM_AMOUNTS_ALL = "At least one more of the amounts should be entered";
export const ERROR_CONDITION = "Please agree to the terms of use";
export const ERROR_SAVING = " Error when saving.";
export const ERROR_UPLOAD = "You must upload at least 1 file .";
export const ERROR_TAG_FILE_TYPE = "Please tag the file before uploading";
export const ERROR_CAPTCHA = "Please confirm you are not a robot.";
export const ERROR_REQUIRED_NUMBER = "Insert a number";
export const ERROR_CLIENT_NUMBER_WRONG = "Client Number ID is wrong.";
export const ERROR_COMPANY_IATA = "IATA number not found. Please clear IATA field or enter a valid number.";
export const ERROR_COMPANY_IATA_1 = "7 characters.";
export const ERROR_COMPANY_IATA_2 = "4 characters.";
export const ERROR_PORT_SAME = "Please choose a different airport.";
export const ERROR_ZIP_SAME = "Please choose a different zip code.";
export const ERROR_PARCEL = "Plz choose a delivery type.";

// Negotiation
export const SUCCESS_QUOTE_SEND_EMAIL_TO_CLIENT = "Send email to client is successful.";
export const SUCCESS_QUOTE_CHAT = "Your message has been sent and we will get back to you shortly";
export const ERROR_QUOTE_SEND_EMAIL_TO_CLIENT = "Send email to client failed. Please try again.";
export const ERROR_DELETE_EMAIL_FAILED = "Delete email failed";
export const SUCCESS_DENIDE_OFFER = "This offer decline success";
export const ERROR_DENIDE_OFFER = "This offer decline failed";
export const SUCCESS_COUNTER_OFFER = "Counter offer was sent successfully";
export const ERROR_COUNTER_OFFER = "Counter failed";
export const ERROR_COUNTER_EMPTY = "Price can't be empty. Plz try again!";
export const TEXT_OFFRER_ITEM_ACCEPTED = "The proposal was accepted. We sent email with further details. Thank you";
export const TEXT_OFFRER_ITEM_DECLINE = "Thank you for the response. We hope to have you with us again soon.";

//Quotes STATUS
export const STATUS_REVIVE = 'Revive';
export const STATUS_APPROVED_BY_CUST = 'Approved by Customer'; //Approved by Cust => Approved by Customer
export const STATUS_APPROVED_BY_USER = 'Approved by Agent'; //Approved by User => Approved by Agent
export const STATUS_CUST_NEGOTIATION = 'Waiting for Agent'; //Cust Negotiation => Waiting for Agent
export const STATUS_DECLINE_BY_CUST = 'Decline by Customer'; //Decline by Cust => Decline by Customer
export const STATUS_SEND_TO_CUST = 'Sent to Customer'; //Sent to Cust => Sent to Customer
export const STATUS_USER_NEGOTIATION = 'Waiting for Customer';//User Negotiation => Waiting for Customer
export const STATUS_WAITING_FOR_BOOKING = 'Waiting for Agent';
export const STATUS_BOOKED = 'Booked';
export const STATUS_CUSTOMER_CHANGE_SHIPMENT = 'Customer change shipment';
export const ERROR_FINDING_QUOTE = 'Quote not found';//User Negotiation => Waiting for Customer

export const ERROR_SEND_EMAIL_EMPTY_LIST_EMAIL = 'Email not found.';
export const SUCCESS_SEND_EMAIL_US = 'Email is sent successfully.';
export const ERROR_SEND_EMAIL_US = 'Email is sent unsuccessfully.';
export const ERROR_QUOTE_DATA_NOT_FOUND = 'Quote data not found.';
export const ERROR_UPDATE_BOOKING_ERROE = 'Update quotes error.';
export const ERROR_GET_DATA_FAILED = 'Get data failed.';
export const ERROR_CREATE_PROPOSAL_FAILED = 'Create proposal failed.';
export const DO_ACCTION_SUCCESS = 'Action has been completed successfully'
export const DO_ACCTION_UNSUCCESS = 'Action failed'
export const CREATE_QUOTE_ERROR = 'Save Quote details is unsuccessfully.'
