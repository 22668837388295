import React from 'react';

export default class Page404 extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount(){
    	document.title = "Cargo Air lines - Air Cargo Service - Page Not Found"
  	}

	render() {
		return (
			<div>
				404 Page
			</div>
		);
	}
}