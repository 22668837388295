import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip';
import '../../css/react-tooltip.css';


class ScSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }    

    componentDidMount() {       
        window.parent.postMessage('componentDidMount success', '*'); 
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var { ebooking, history } = this.props;

        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);

        this.props.loadingActions.loadingChangeData(true);

        var routeFlight = {};
        var isexpress = "";
        var pl = 0;
        var parcelprice = 0.0;
        var parcelexpprice = 0.0;
        if ((ebooking.PriceQuotation.type == "WHITE" || ebooking.PriceQuotation.type == "PL") && ebooking.PriceQuotation.pl.length > 0) {
            routeFlight = ebooking.PricelistChoosed.selectedFlightData;
            pl = ebooking.PricelistChoosed.selectedPLData.plnum;
        } else if (ebooking.PriceQuotation.type == "WHITE") {
            routeFlight = ebooking.DefaultChoosed.flight;
            isexpress = ebooking.DefaultChoosed.priceType == "express" ? "Y" : "";
        } else if (ebooking.PriceQuotation.type == "PARCEL") {
            routeFlight = ebooking.DefaultChoosed.flight;
            isexpress = ebooking.ParcelChoosed.priceType == "expressprice" ? "Y" : "";
            parcelprice = ebooking.PriceQuotation.price;
            parcelexpprice = ebooking.PriceQuotation.expressprice;
        }

        var bookData = {
            "currency": parsedParams.currency,
            // "awb": "70060424674",
            "awb": this.props.auth.iatacode,
            "authcode": parsedParams.AuthCode,
            "amount": parsedParams.totalAmount,
            "transid": parsedParams.TransactionID,
            "custname": this.props.auth.custname,
            // "custname": "11250006",
            "cust": this.props.auth.cust,
            // "cust": 0,
            "type": "CASH",
            "isexpress": isexpress,
            "pl": pl,
            "parcelpl": 0,
            "parcelprice": parcelprice,
            "parcelexpprice": parcelexpprice,
            "Participents": {
                "SHIPNAME": ebooking.shippingCompanyName,
                "SHIPIATACODE": ebooking.shippingIATA1 + ebooking.shippingIATA2,
                "RECNAME": ebooking.receivingCompanyName,
                "RECIATACODE": ebooking.receivingIATA1 + ebooking.receivingIATA2,
                "CNORNUM": "",
                "CNORNAME": ebooking.consignorCompanyName,
                "CNORCOUNTRY": ebooking.consignorCountry,
                "CNORADDRESS": ebooking.consignorAddress,
                "CNORCITY": ebooking.consignorCity,
                "CNORPOSTALCODE": ebooking.consignorPostalCode,
                "CNEENUM": "",
                "CNEENAME": ebooking.consigneeCompanyName,
                "CNEECOUNTRY": ebooking.consigneeCountry,
                "CNEEADDRESS": ebooking.consigneeAddress,
                "CNEEPOSTALCODE": ebooking.consigneePostalCode,
                "CNEECITY": ebooking.consigneeCity,
                "PICKCOMPNAME": ebooking.pickupCompanyName,
                "PICKNAME": ebooking.pickupNameOfContact,
                "PICKEMAIL": ebooking.pickupEmail,
                "PICKPHONE": ebooking.pickupPhoneNumber,
                "PICKCOUNTRY": ebooking.pickupCountry,
                "PICKCITY": ebooking.pickupCity,
                "PICKADDRESS": ebooking.pickupAddress,
                "PICKPOSTALCODE": ebooking.pickupPostalCode,
                "DELCOMPNAME": ebooking.deliverCompanyName,
                "DELNAME": ebooking.deliverNameOfContact,
                "DELEMAIL": ebooking.deliverEmail,
                "DELPHONE": ebooking.deliverPhoneNumber,
                "DELCOUNTRY": ebooking.deliverCountry,
                "DELCITY": ebooking.deliverCity,
                "DELADDRESS": ebooking.deliverAddress,
                "DELPOSTALCODE": ebooking.deliverPostalCode,
            },
            "PriceQuotation": ebooking.passedData,
            "routes": routeFlight
        };

        history.push('/ebooking/sc-success-final');
        // this.props.ebookingActions.setSafeCharge(bookData, this.props.auth.jwtToken).then(response => {
        //     this.props.loadingActions.loadingChangeData(false);
        //     if (response.success && response.data.Success) {
        //         history.push('/ebooking/sc-success-final');
        //     }else{
        //         history.push('/ebooking/sc-submit-failure');
        //     }
        // });
    }

    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, costDatas, cost2, total }
            = this.state;      

        return (
            <div className="container ebooking">                
                Processing...
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScSuccess);