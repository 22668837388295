import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import AppConfig from '../../AppConfig';
import PropTypes from 'prop-types';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import * as MESSAGES from '../../libs/Messages';
import Progress from '../Progress';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class BookingUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            selectedType: '',
            loaded: 0,
            showProgress: false,
            fileTypes: ['AWB', 'Mandate', 'Invoice', 'HS Code', 'Packing List',
                'Phyio', 'Euro 1', 'ATA Carnet', 'Valid Exporter decleration/stamp', 'Other'],
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            maxFileSize: 9
        }

        this.onChangeType = this.onChangeType.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    componentDidMount() {

    }

    onChangeType = (selectedType, event) => {
        selectedType = selectedType.replace("/", "-");
        this.setState({ selectedType: selectedType });
        this.inputElement.click();
    }

    handleUploadFile(e) {
        var selectorFiles = e.target.files;
        var file = selectorFiles[0];
        if(file){
            this.props.onSuccess({ file, filedes: this.state.selectedType });
        }
        
        // this.uploadFile(file);
        // e.target.value = null;
    }

    changeProgress = (loaded, total) => {
        var loadedPer = 0;
        if (total > 0) {
            loadedPer = parseInt((loaded / total) * 100);
        }
        this.setState({ loaded: loadedPer });
    }

    uploadFile(file) {
        var reader = new FileReader();
        var eObj = this;

        this.props.loadingActions.loadingChangeData(true);
        reader.addEventListener('loadend', function (e) {
            var name = file.name;
            var nameSplit = name.split('.');
            var fileName = nameSplit[0];
            var filesize = file.size;
            if (fileName.length < 80) {
                eObj.setState({ loaded: 0, showProgress: true });
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();
                fileName = fileName.replace(/[^\w]/gi, '');
                //var fileNewName = eObj.props.ClaimNum + "-" + fileName + '-' + moment().format('YYYYMMDDHHmmss') + '.' + ext;                
                var fileNewName = eObj.props.fileStartName + eObj.state.selectedType + '.' + ext;

                // console.log(filesize, (eObj.state.maxFileSize * 1048576));
                if ((eObj.state.acceptFiles.indexOf(ext) > -1) && filesize <= (eObj.state.maxFileSize * 1048576)) {
                    if (eObj.props.subFolder != "") {
                        fileNewName = eObj.props.subFolder + '/' + fileNewName;
                    } else {
                        fileNewName = fileNewName;
                    }

                    fetch(AppConfig.apiUpload + "api/service/upload-booking-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            eObj.setState({ loaded: 0, showProgress: true });
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total);
                                }
                            })
                        })
                        .then(function () {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);

                            var fileData = {
                                filename: name,
                                filedes: eObj.state.selectedType,
                                filesize: filesize
                            }
                            eObj.props.onSuccess(fileData, eObj.props.fileIndex);
                        }).catch(e => {
                            eObj.setState({ loaded: 0, showProgress: false });
                            eObj.props.loadingActions.loadingChangeData(false);
                            eObj.setState({ selectedFileType: false, loaded: 0, showProgress: false });
                        });
                } else {
                    eObj.setState({ loaded: 0, showProgress: false });
                    eObj.props.loadingActions.loadingChangeData(false);
                    alert("Please check your type and file size!");

                }
            } else {
                eObj.setState({ loaded: 0, showProgress: false });
                eObj.props.loadingActions.loadingChangeData(false);
                alert("Your file name too long. It should less than 80 characters!");
            }
        });
        reader.readAsArrayBuffer(file);
    }

    renderOptions = () => {
        var options = [];
        this.state.fileTypes.map((item, i) => {
            options.push(<option key={i} value={item}>{item}</option>);
        })
        return options;
    }

    render() {

        return (
            <div className={`${this.props.class ? this.props.class : ''}`}>
                <div className="div_center text-center" style={{ "maxWidth": "250px" }}>
                    <div className="dropdown dropdown attact-file-button">
                        <button className="btn btn-attact file-types" type="button" id="dropdownMenuButton" disabled={this.props.dis} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Attach a file
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                this.state.fileTypes.map((file_type, i) => {
                                    return (
                                        <a className="dropdown-item" key={i} onClick={this.onChangeType.bind(this, file_type)}>{file_type}</a>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="dv-choose-file div_center text-center d-none" style={{ "maxWidth": "150px" }}>
                    <label className={`btn btn-attact ${this.props.dis ? 'disabled' : ''}`}>Attach a file
                            <input type="file" ref={input => this.inputElement = input} name="upload" accept="application/pdf, image/png, image/jpeg, application/msword" disabled={this.props.dis} onChange={this.handleUploadFile} />
                    </label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}
BookingUpload.propTypes = {
    onSuccess: PropTypes.func,
    fileIndex: PropTypes.string,
    subFolder: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingUpload);