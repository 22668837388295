import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import config from '../config.json';
class ModelAuth {
    //login
    static async doLogin(username, password) {
        return new Promise((resolve, reject) => {
            var authenticationData = {
                Username: username,
                Password: password,
            };
            var authenticationDetails = new AuthenticationDetails(authenticationData);
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    var accessToken = result.getIdToken().getJwtToken();
                    //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                    AWS.config.region = config.region;
                    var loginConfigs = {};
                    loginConfigs['cognito-idp.' + config.region + '.amazonaws.com/' + config.userPool] = result.getIdToken().getJwtToken();
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: config.identityPool, // your identity pool id here
                        Logins: loginConfigs
                    });

                    //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                    AWS.config.credentials.refresh((error) => {
                        if (error) {
                            cognitoUser = userPool.getCurrentUser();
                            if (cognitoUser != null) {
                                cognitoUser.getSession(function (err, session) {
                                    if (err) {
                                        resolve({ success: false, message: err.message || JSON.stringify(err) });
                                        cognitoUser.signOut();
                                        return;
                                    }                 
                                    resolve({ success: true, message: "", jwtToken: session.getIdToken().getJwtToken() });                                    
                                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                        IdentityPoolId: config.identityPool, // your identity pool id here
                                        Logins: loginConfigs
                                    });
                                });
                            } else {
                                resolve({ success: false, message: "Login failed!", jwtToken: "", mustRefresh: true });
                            }

                            // resolve({ success: false, message: "Login failed!", jwtToken: "", mustRefresh: true });
                            // if(error =="Error: Logins don't match. Please include at least one valid login for this identity or identity pool." || error =="Logins don't match. Please include at least one valid login for this identity or identity pool."){                                
                            //     resolve({ success: false, message: "Login failed!", jwtToken: "", mustRefresh: true });
                            // }else{
                            //     resolve({ success: false, message: "Login failed!", jwtToken: "", mustRefresh: false });
                            // }                            
                        } else {
                            // console.log('Successfully logged!', accessToken);                               
                            resolve({ success: true, message: "", jwtToken: accessToken });
                        }
                    });
                },

                onFailure: function (err) {
                    // alert(err.message || JSON.stringify(err));                    
                    switch (err.code) {
                        case "UserNotConfirmedException":
                            resolve({ success: true, message: "CONFIRMATION_REQUIRED", jwtToken: "", mustRefresh: false });
                            break;
                        case "NewPasswordRequired":
                            resolve({ success: true, message: "NEW_PASSWORD_REQUIRED", jwtToken: "", mustRefresh: false });
                            break;
                        default:
                            resolve({ success: false, message: err.message || JSON.stringify(err), jwtToken: "", mustRefresh: false });
                            break;
                    }
                },

            });
        });
    }

    //signup
    static async doSignup(username, password, attributeList) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            userPool.signUp(username, password, attributeList, null, function (err, result) {
                if (err) {
                    resolve({ success: false, message: err.message || JSON.stringify(err) });
                    return;
                }
                var cognitoUser = result.user;
                resolve({ success: true, message: "", username: cognitoUser.getUsername() });
            });
        });
    }

    //signup - confirm
    static async doConfirmRegistration(username, verifyCode) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmRegistration(verifyCode, true, function (err, result) {
                if (err) {
                    resolve({ success: false, message: err.message || JSON.stringify(err) });
                    return;
                }
                resolve({ success: true, message: 'call result: ' + result });
            });
        });
    }

    //signup - new password requied
    static async doCompleteNewPasswordChallenge(username, newPassword) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: () => {
                    resolve({ success: true, message: "success", userData: cognitoUser });
                },
                onFailure: error => {
                    resolve({ success: false, message: error.message });
                },
                mfaRequired: () => {
                    resolve({ success: false, message: "mfaRequired" });
                },
                newPasswordRequired: () => {
                    resolve({ success: false, message: "newPasswordRequired" });
                },
            });
        });
    }

    //Signup - resend confirm code
    static async doResendConfirmationCode(username) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.resendConfirmationCode(function (err, result) {
                if (err) {
                    resolve({ success: false, message: err.message || JSON.stringify(err) });
                    return;
                }
                resolve({ success: true, message: 'call result: ' + result });
            });
        });
    }

    //Forgot pass - Verification Code
    static async doSendVerificationCode(username) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    // successfully initiated reset password request
                    resolve({ success: true, message: 'CodeDeliveryData from forgotPassword: ' + data });
                },
                onFailure: function (err) {
                    resolve({ success: false, message: err.message || JSON.stringify(err) });
                }
            });
        });
    }

    //forgot pass - set new pass
    static async doConfirmPassword(username, verificationCode, newPassword) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);
            var userData = {
                Username: username,
                Pool: userPool
            };
            var cognitoUser = new CognitoUser(userData);
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess() {
                    resolve({ success: true, message: 'Password confirmed!' });
                },
                onFailure(err) {
                    resolve({ success: false, message: "Password not confirmed!" });
                }
            });
        });
    }

    //get current user
    static async doGetSession() {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);

            var cognitoUser = userPool.getCurrentUser();
            if (cognitoUser != null) {
                cognitoUser.getSession(function (err, session) {
                    if (err) {
                        resolve({ success: false, message: err.message || JSON.stringify(err) });
                        cognitoUser.signOut();
                        return;
                    }
                    // console.log('session validity: ' + session.isValid());
                    // console.log("session tocken: ", session.getAccessToken().getJwtToken());                    
                    resolve({ success: true, message: "", jwtToken: session.getIdToken().getJwtToken() });
                    var loginConfigs = {};
                    loginConfigs['cognito-idp.' + config.region + '.amazonaws.com/' + config.userPool] = session.getIdToken().getJwtToken();
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: config.identityPool, // your identity pool id here
                        Logins: loginConfigs
                    });

                });
            } else {
                resolve({ success: false, message: "", jwtToken: "" });
            }
        });
    }

    //logedin - change password
    static async doChangePassword(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);

            var cognitoUser = userPool.getCurrentUser();
            if (cognitoUser != null) {
                cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
                    if (err) {
                        resolve({ success: false, message: err.message || JSON.stringify(err) });
                        return;
                    }
                    resolve({ success: true, message: 'call result: ' + result });
                });
            } else {
                resolve({ success: false, message: "Session expired. Please login before proceeding. Thanks." });
            }
        });
    }

    //sign out
    static async doSignout() {
        return new Promise((resolve, reject) => {
            var poolData = {
                UserPoolId: config.userPool, // Your user pool id here
                ClientId: config.clientId // Your client id here
            };
            var userPool = new CognitoUserPool(poolData);

            var cognitoUser = userPool.getCurrentUser();
            if (cognitoUser != null) {
                cognitoUser.signOut();
                resolve({ success: true, message: "", jwtToken: "" });
            } else {
                resolve({ success: true, message: "", jwtToken: "" });
            }
        });
    }

    static setIdToken(token) {
        return new Promise((resolve, reject) => {
            localStorage.setItem('CAL-CARGO-TOKEN', token);
            resolve('success');
        });
    }

    static getIdToken() {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('CAL-CARGO-TOKEN') || '';
            resolve(token);
        });
    }

    static removeIdToken() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('CAL-CARGO-TOKEN');
            resolve('success');
        });
    }
}

export default ModelAuth;