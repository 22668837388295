import React from 'react';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
import AppConfig from '../../AppConfig';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
/**
 * Validator
 */
import validator from 'validator';
import InputError from '../InputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as claimActions from '../../ninja-redux/actions/claimActions';

/**
 * Uploaded file
 */
//import ClaimUploaded from './ClaimUploaded';
import BookingUpload from './BookingUpload';
import BookingUploaded from './BookingUploaded';

class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            isError: false,
            errorMessage: "",
            fileStartName: "WEB-",
            uploading: [],
            uploaded: [],
            showAddButton: true,
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            maxFileSize: 9,
            maxUploadFiles: 15,
            isUploading: false,
            subFolder: ""
        };
        this.handleNewFile = this.handleNewFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }

    handleNewFile = (filedata) => {
        var { errors, uploaded, maxUploadFiles, acceptFiles, maxFileSize } = this.state;
        errors['upload_file'] = '';
        if (uploaded.length < maxUploadFiles) {
            var name = filedata.file.name;
            var nameSplit = name.split('.');
            var filesize = filedata.file.size;
            var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
            ext = ext.toLowerCase();
            if ((acceptFiles.indexOf(ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                uploaded.push({ file: filedata.file, uploaded: false, uploadError: false, uploadProgress: 0, fileType: filedata.filedes });
            } else {
                errors['upload_file'] = "Please check your type and file size!";
            }
        } else {
            errors['upload_file'] = "You can upload maximum " + maxUploadFiles + " files.";
        }
        this.setState({ errors, uploaded });
    }

    handleDeleteFile(name, i) {
        var { uploaded } = this.state;
        uploaded.splice(i, 1);
        if (!uploaded.length) {
            this.addUploadingFile();
        }
        this.setState({ uploaded });
    }

    componentDidMount() {
        var { fileStartName, isError, errorMessage, uploaded, showAddButton } = this.state;
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var webf = parsedParams.webf;
        var awb = parsedParams.awb;
        if (awb != undefined && awb != "") {
            this.getAwb(awb);
        } else if (webf != undefined && webf != "") {
            showAddButton = true;
            fileStartName = "WEB" + webf + "-";
            this.setState({ fileStartName, showAddButton });
            this.addUploadingFile();
        } else {
            isError = true;
            errorMessage = "Not found AWB or webf!";
            this.setState({ isError, errorMessage, fileStartName: "" });
        }
        // uploaded.push({ file: { name: "sdfd", size: 234 }, uploaded: false, uploadError: true, uploadProgress: 0 });
        // fileStartName = "WEB" + awb + "-";
        this.setState({ fileStartName, uploaded, showAddButton });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        localStorage.setItem('DASHBOARD_REFRESH', true);
    }

    getAwb = async (awbNumber) => {
        var { isError, errorMessage, fileStartName, showAddButton } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        var t = this;
        isError = false;
        this.setState({ isError: isError, errorMessage: "" });
        this.props.claimActions.getAWBData(awbNumber).then(response => {
            t.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.awbdata.length > 0) {
                showAddButton = true;
                fileStartName = "WEB" + awbNumber + "-";
                errorMessage = "";
                this.setState({ isError, errorMessage, fileStartName, showAddButton });
                t.addUploadingFile();
            } else {
                isError = true;
                errorMessage = "Get AWB Data Unsuccess!";
                this.setState({ isError, errorMessage, fileStartName });
            }
        });
    }

    // Start upload files
    changeProgress = (loaded, total, fileIndex) => {
        var { uploaded } = this.state;
        var loadedPer = 0;
        if (total > 0) {
            loadedPer = parseInt((loaded / total) * 100);
        }
        uploaded[fileIndex].uploadProgress = loadedPer;
        this.setState({ uploaded });
    }
    startUploadFiles = async (listFiles) => {
        var { uploaded } = this.state;
        var t = this;
        await this.asyncForEach(listFiles, async (i) => {
            var uploadStatus = await t.uploadFile(listFiles[i], i);
            if (uploadStatus.success) {
                uploaded[uploadStatus.fileIndex].uploaded = true;
                uploaded[uploadStatus.fileIndex].uploadError = false;
                uploaded[uploadStatus.fileIndex].uploadProgress = 0;
            } else {
                uploaded[uploadStatus.fileIndex].uploaded = false;
                uploaded[uploadStatus.fileIndex].uploadError = true;
                uploaded[uploadStatus.fileIndex].uploadProgress = 0;
            }
            t.setState({ uploaded });
        });
    }

    asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(index, array[index], array)
        }
    }

    uploadFile = (fileData, i) => {
        var { fileStartName, subFolder, acceptFiles, maxFileSize, uploaded } = this.state;
        var reader = new FileReader();
        var eObj = this;
        return new Promise((resolve, reject) => {
            reader.addEventListener('loadend', function (e) {
                var name = fileData.file.name;
                var nameSplit = name.split('.');
                var filesize = fileData.file.size;
                var ext = nameSplit[nameSplit.length - 1] ? nameSplit[nameSplit.length - 1] : '';
                ext = ext.toLowerCase();
                var fileNewName = fileStartName + fileData.fileType + '.' + ext;
                if ((acceptFiles.indexOf(ext) > -1) && filesize <= (maxFileSize * 1048576)) {
                    if (subFolder != "") {
                        fileNewName = subFolder + '/' + fileNewName;
                    } else {
                        fileNewName = fileNewName;
                    }
                    fetch(AppConfig.apiUpload + "api/service/upload-booking-file", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: fileNewName,
                            type: fileData.file.type
                        })
                    })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            return axios.request({
                                method: "PUT",
                                url: json.body.uploadURL,
                                data: fileData.file,
                                onUploadProgress: (p) => {
                                    eObj.changeProgress(p.loaded, p.total, i);
                                }
                            })
                        })
                        .then(function () {
                            resolve({ success: true, fileIndex: i });
                        }).catch(e => {
                            resolve({ success: false, fileIndex: i });
                        });
                } else {
                    resolve({ success: false, fileIndex: i, desc: "Please check your type and file size!" });
                }
            });
            reader.readAsArrayBuffer(fileData.file);
        });
    }

    // End upload files

    addUploadingFile = () => {
        var { uploading } = this.state;
        uploading.push(uuid());
        this.setState({ uploading });
    }

    handFinish = async (e) => {
        e.stopPropagation();
        e.preventDefault();


        this.setState({ isUploading: true });
        await this.startUploadFiles(this.state.uploaded);
        var checkError = this.state.uploaded.find(f => f.uploadError);

        if (!this.state.uploaded.length || checkError == undefined) {
            window.jumpToTop();
            const { history } = this.props;
            history.push('/dashboard');
        } else {
            this.setState({ isUploading: false });
        }

    }
    render() {
        var { isError, errors, errorMessage, isUploading, uploaded, maxUploadFiles, showAddButton } = this.state;
        return (
            <div className="page-main">
                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block'}}>

                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className={`alert alert-danger text-red ${(!isError || this.props.loading.isLoading) ? 'd-none' : ''}`} role="alert">
                                                {errorMessage}. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                                            </div>
                                            <div className="big-text">Attachments </div>
                                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "900px" }}>
                                                This is where you attach all relevant files to your order.
                                            </div>
                                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                                Please upload each document as a separate file.
                                            </div>
                                            <div className="content-text-desc div_center" style={{ "maxWidth": "700px" }}>
                                                Accepted file types: .doc, .pdf, .png, .jpeg. Max file size: 9MB.
                                            </div>
                                            <div className="content-text-desc div_center" style={{ "maxWidth": "620px" }}>
                                                Select the relevant document type from the dropdown menu. You can upload maximum 15 files.
                                            </div>
                                            <div className="content-buttons div_center mt-5 mb-3">
                                                <BookingUpload onSuccess={this.handleNewFile} dis={(isError || isUploading || uploaded.length >= maxUploadFiles || !showAddButton) ? true : false} />
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 form-rows div_center" style={{ "maxWidth": "400px" }}>
                                                    {
                                                        uploaded.map((file, i) => {
                                                            return <BookingUploaded key={i} isUploading={isUploading} fileInfo={file} fileIndex={i} onDeleteFile={this.handleDeleteFile} />;
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="div_center text-center">
                                                <InputError error={errors.upload_file} />
                                            </div>
                                            <div className="content-buttons div_center mt-5">
                                                <button type="button" className={`btn btn-orange ${isUploading ? "disabled" : ""}`} disabled={(isUploading || isError) ? true : false} onClick={this.handFinish}>{isUploading ? "Checking files and uploading..." : "Upload and return to dashboard"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);