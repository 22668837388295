import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class ScSuccessFinal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }    

    componentDidMount() {        
        window.parent.postMessage('success-final', '*');
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";        
    }

    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, costDatas, cost2, total }
            = this.state;      

        return (
            <div className="container ebooking">                
                Congratulation! Your booking was set - a details compilation link was sent yor supplier, 
                we will notify you in the coming days of any changes if some are made
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScSuccessFinal);