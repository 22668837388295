import * as ActionTypes from '../actionTypes';

const initialState = {
    isLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOADING_CHANGE_DATA: {
            var newState = { ...state };
            newState.isLoading = action.payload;
            return Object.assign({}, state, newState)
        }
        return initialState;
    }
    return state;
}