import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import clone from 'clone';
/**
 * Validator
 */
import { bindActionCreators } from 'redux';

class ModalDashboardCantBeDone extends React.Component {
    constructor(props) {
        super(props);
        const { itmChat } = this.props;
        let currentChat = clone(itmChat);
        this.state = {
            errors: {},
            currentChat,
            message: ""
        };

    }

    componentDidMount = () => {
    }

    handleChange = async (event) => {
        const { currentChat, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        // value = value.replace(/[^0-9.]/g, "");
        // var strArray = name.split("_");
        // let key = strArray[1];
        // if (currentChat.ChatData.OfferData[key].price !== value) {
        //     currentChat.ChatData.OfferData[key].isUpdated = true;
        // }
        // currentChat.ChatData.OfferData[key].price = value;
        // if (value === '' || parseFloat(value) <= 0) {
        //     errors[name] = 'Invalid';
        // } else {
        //     errors[name] = '';
        // }
        // this.setState({ currentChat, errors });
    }

    handleTextChange = (event) => {
        var { target } = event;
        this.setState({ message: target.value });

    }

    handleSend = async () => {
        const { currentChat, message } = this.state;
        this.props.handleCounter(message, currentChat);
    }

    handleOnBlur = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        this.setState({ errors });
    }

    handleClickContact = async (event) =>{
        this.props.handleClickContact();
    }

    render() {
        const { onShow, description, itmChat } = this.props;
        const { currentChat, message } = this.state;
        var { errors } = this.state;
        if (!onShow)
            return (null);

        return (
            <div className="modal-dialog modal-dialog-medium modal-dialog-centered" role="document">
                <div className="modal-content booking-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <h5 className="text-uppercase">THIS CAN'T BE DONE HERE</h5>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description" style={{ 'maxWidth': '330px' }}>This changes you made are significant and can't be done automatically. You can send a message to your Cal agent</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer two-buttons no-border">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-7">
                                    <button type="button" className="btn btn-block btn-white" onClick={this.props.closeModal}>Never mind. Undo changes</button>
                                </div>
                                <div className="col-5">
                                    <button type="button" className="btn btn-block btn-orange" onClick={this.handleClickContact}>Contact Cal Agent</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDashboardCantBeDone);
