import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import BookingHelper from './BookingHelper';


class ConfirmAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnContinue: "Checkout",
            errorMessage: null,
            isEdit: false,
            errors: {},
            deliveryType: "",
            ebooking: {
                selectedShippingReceiving: 'Shipping',
                receivingCompanyName: "",
                receivingIATA1: "",
                receivingIATA2: "",
                shippingCompanyName: "",
                shippingIATA1: "",
                shippingIATA2: "",
                consignorCompanyName: "",
                consignorAddress: "",
                consignorCity: "",
                consignorCountry: "",
                consignorGroundHandling: "",
                consignorGroundHandlingData: { warhsname: "" },
                consignorPostalCode: "",
                consigneeAddress: "",
                consigneeCity: "",
                consigneeCompanyName: "",
                consigneeCountry: "",
                consigneeGroundHandling: "",
                consigneeGroundHandlingData: { warhsname: "" },
                consigneePostalCode: "",
                pickupCheck: false,
                pickupAddress: "",
                pickupCity: "",
                pickupComments: "",
                pickupCountry: "",
                pickupEmail: "",
                pickupNameOfContact: "",
                pickupOpeningHours: "",
                pickupPhoneNumber: "",
                pickupPostalCode: "",
                deliverAddress: "",
                deliverCheck: false,
                deliverCity: "",
                deliverComments: "",
                deliverCountry: "",
                deliverEmail: "",
                deliverNameOfContact: "",
                deliverOpeningHours: "",
                deliverPhoneNumber: "",
                deliverPostalCode: "",
                deliver_from_airport_data: { calcity: "", cityname: "" },
                deliver_to_airport_data: { calcity: "", cityname: "" },
                ParcelChoosed: {},
                PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
                PriceQuotation: {
                    "pl": [],
                    "routes": []
                },
                instructions: [],
                units_list: []
            },
            costDatas: [],
            cost2: [],
            cost2Row: 0,
            total: 0,
            totalExtra: 0,
            pricingCase: "",
            showPickup: false,
            showDelivery: false,
            showFreightForwarder: false,
            showGroundHandlingOrigin: false,
            showGroundHandlingDestination: false,
            totalCostWeight: 0
        };
    }

    componentDidMount() {
        // console.log(this.props);
        //get redux data and set to state   
        var { costDatas, pricingCase, cost2, total, totalExtra, cost2Row, totalCostWeight, deliveryType } = this.state;
        var { ebooking, history, auth } = this.props;
        if (this.props.auth.cash == "") {
            this.setState({ btnContinue: "Book Now" });
        }
        var authState = auth.state;
        // if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
        //     history.push('/ebooking/shipment');
        // } else if (!ebooking.step_2_ok) {
        //     if (ebooking.PriceQuotation.pl.length > 0) {
        //         history.push('/ebooking/flight-pricing-pricelist');
        //     } else {
        //         switch (ebooking.PriceQuotation.type) {
        //             case "WHITE":
        //                 history.push('/ebooking/flight-pricing-default');
        //                 break;
        //             case "PARCEL":
        //                 history.push('/ebooking/flight-pricing-parcel');
        //                 break;
        //             case "PL":
        //                 history.push('/ebooking/flight-pricing-pricelist');
        //                 break;
        //             default:
        //                 history.push('/ebooking/shipment');
        //                 break;
        //         }
        //     }
        // } else if (!ebooking.step_3_ok) {
        //     history.push('/ebooking/participants');
        // }
        // total = 0;
        // switch (ebooking.PriceQuotation.type) {
        //     case "PARCEL":
        //         pricingCase = "PARCEL";
        //         total = ebooking.ParcelChoosed.total;
        //         deliveryType = ebooking.ParcelChoosed.priceType == "price" ? "economy" : "express";
        //         break;
        //     case "WHITE":
        //         if (ebooking.PriceQuotation.pl.length > 0) {
        //             pricingCase = "PRICELIST";
        //             deliveryType = "PRICELIST";
        //         } else {
        //             deliveryType = ebooking.DefaultChoosed.priceType;
        //             pricingCase = "WHITE";
        //             var partPrice = 0;
        //             for (var i = 0; i < ebooking.DefaultChoosed.flight.Parts.length; i++) {
        //                 var part = ebooking.DefaultChoosed.flight.Parts[i];
        //                 partPrice += parseFloat(part.price);
        //                 cost2.push({
        //                     extra: part.partname,
        //                     description: part.partdes,
        //                     total: part.price
        //                 });
        //             }
        //             totalCostWeight = parseFloat(ebooking.units_total_chargable_weight) * parseFloat(ebooking.DefaultChoosed.price);
        //             total = totalCostWeight + partPrice;
        //         }
        //         break;
        //     case "PL":
        //         deliveryType = "PRICELIST";
        //         pricingCase = "PRICELIST";
        //         break;
        //     default:
        //         history.push('/ebooking/shipment');
        //         break;
        // }
        total = total.toFixed(2);

        this.setState({ ebooking, costDatas: costDatas, pricingCase, cost2: cost2, total, totalExtra, totalCostWeight: totalCostWeight });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;

        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        const { history } = this.props;
        // history.push('/ebooking/payment');
        if (this.props.auth.credit != "") {
            // history.push('/ebooking/payment-credit');
            this.submitBookData("CREDIT");
        } else if (this.props.auth.cass != "") {
            // history.push('/ebooking/payment-cass');
            this.submitBookData("CASS");
        } else {
            window.jumpToTop();
            history.push('/ebooking/payment');
        }
    }

    submitBookData = (cashType) => {
        var { history, auth } = this.props;
        var { ebooking, errorMessage, total } = this.state;
        var routeFlight = {};
        var isexpress = "";
        var pl = 0;
        var parcelprice = 0.0;
        var parcelexpprice = 0.0;
        if ((ebooking.PriceQuotation.type == "WHITE" || ebooking.PriceQuotation.type == "PL") && ebooking.PriceQuotation.pl.length > 0) {
            routeFlight = ebooking.PricelistChoosed.selectedFlightData;
            delete routeFlight.uuid;
            delete routeFlight.duration;
            delete routeFlight.priceSort;
            delete routeFlight.expresspriceSort;
            pl = ebooking.PricelistChoosed.selectedPLData.plnum;
        } else if (ebooking.PriceQuotation.type == "WHITE") {
            routeFlight = ebooking.DefaultChoosed.flight;
            delete routeFlight.uuid;
            delete routeFlight.duration;
            delete routeFlight.priceSort;
            delete routeFlight.expresspriceSort;
            isexpress = ebooking.DefaultChoosed.priceType == "express" ? "Y" : "";
        } else if (ebooking.PriceQuotation.type == "PARCEL") {
            routeFlight = null;
            isexpress = ebooking.ParcelChoosed.priceType == "expressprice" ? "Y" : "";
            parcelprice = ebooking.PriceQuotation.price;
            parcelexpprice = ebooking.PriceQuotation.expressprice;
        }

        if (pl != 0 && pl != "0") {
            ebooking.passedData.Plnum = pl;
        } else {
            ebooking.passedData.Plnum = "";
        }
        ebooking.passedData.AWB = ebooking.already_awb ? ebooking.awb_number_1 + "" + ebooking.awb_number_2 : "";
        ebooking.passedData.MustRide = isexpress;
        var bookData = {
            "currency": auth.cur,
            "authcode": "",
            "amount": total,
            "transid": "-1",
            "custname": this.props.auth.custname,
            "cust": this.props.auth.cust,
            "type": cashType,
            "isexpress": isexpress,
            "pl": pl,
            "parcelpl": 0,
            "parcelprice": parcelprice,
            "parcelexpprice": parcelexpprice,
            "Participents": {
                "SHIPNAME": ebooking.shippingCompanyName,
                "SHIPIATACODE": ebooking.shippingIATA1 + ebooking.shippingIATA2,
                "RECNAME": ebooking.receivingCompanyName,
                "RECIATACODE": ebooking.receivingIATA1 + ebooking.receivingIATA2,
                "CNORNUM": "",
                "CNORNAME": ebooking.consignorCompanyName,
                "CNORCOUNTRY": ebooking.consignorCountry,
                "CNORADDRESS": ebooking.consignorAddress,
                "CNORCITY": ebooking.consignorCity,
                "CNORPOSTALCODE": ebooking.consignorPostalCode,
                "CNEENUM": "",
                "CNEENAME": ebooking.consigneeCompanyName,
                "CNEECOUNTRY": ebooking.consigneeCountry,
                "CNEEADDRESS": ebooking.consigneeAddress,
                "CNEEPOSTALCODE": ebooking.consigneePostalCode,
                "CNEECITY": ebooking.consigneeCity,
                "PICKCOMPNAME": ebooking.pickupCompanyName,
                "PICKNAME": ebooking.consignorGroundHandlingData.warhsname != "" ? ebooking.consignorGroundHandlingData.warhsname : ebooking.pickupNameOfContact,
                "PICKEMAIL": ebooking.pickupEmail,
                "PICKPHONE": ebooking.pickupPhoneNumber,
                "PICKCOUNTRY": ebooking.pickupCountry,
                "PICKCITY": ebooking.pickupCity,
                "PICKADDRESS": ebooking.pickupAddress,
                "PICKPOSTALCODE": ebooking.pickupPostalCode,
                "DELCOMPNAME": ebooking.deliverCompanyName,
                "DELNAME": ebooking.consigneeGroundHandlingData.warhsname != "" ? ebooking.consigneeGroundHandlingData.warhsname : ebooking.deliverNameOfContact,
                "DELEMAIL": ebooking.deliverEmail,
                "DELPHONE": ebooking.deliverPhoneNumber,
                "DELCOUNTRY": ebooking.deliverCountry,
                "DELCITY": ebooking.deliverCity,
                "DELADDRESS": ebooking.deliverAddress,
                "DELPOSTALCODE": ebooking.deliverPostalCode,
                "DELOPENHOURS": ebooking.deliverOpeningHours,
                "DELREMARKS": ebooking.deliverComments,
                "PICKOPENHOURS": ebooking.pickupOpeningHours,
                "PICKREMARKS": ebooking.pickupComments
            },
            "PQ": ebooking.passedData,
            "routes": routeFlight
        };

        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.setSafeCharge(bookData, this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                this.props.ebookingActions.ebookingChangeData({ fieldName: "docno", fieldValue: response.data.docno }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "webf", fieldValue: response.data.webf }).then(responseData => { });
                window.jumpToTop();
                history.push('/ebooking/success');
            } else if (!response.success && response.relogin) {
                this.setState({ errorMessage: "Session expired.", isError: true });
                window.jumpToTop();
            } else {
                this.setState({ errorMessage: "Booking Error. Plz try again.", isError: true });
                window.scrollToTop();
            }
        });
    }

    render() {
        var { auth, ebooking } = this.props;
        var authState = auth.state;
        const { deliveryType, showGroundHandlingOrigin, showGroundHandlingDestination, breadcrumbs, steps, isError, errors, errorMessage, dataDetails, costDatas, cost2, total, totalExtra, dataBooks, pricingCase, showDelivery, showPickup, showFreightForwarder }
            = this.state;

        return (
            <div className="page-main">
                <div className="div-head">
                    <button type="button" className="button-back">Back to previous page</button>
                </div>
                <div className="page-container">
                    <div className="div-table-cell">
                        <div className="page-content text-center">
                            <div className="big-text">Payment Confirmation</div>
                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                Dear {auth.custname}, {auth.custdes}
                            </div>

                            {!auth.IsGSA || (auth.IsGSA && !ebooking.payingCustomer) ? (
                                <div className={`content-text-desc div_center mt-4`} style={{ "maxWidth": "500px" }}>
                                    Your order is being processed as we speak. The paying customer is {ebooking.selectedShippingReceiving === 'Shipping' ? ebooking.shippingCompanyName : ebooking.receivingCompanyName}. The payment amount of <span className="text-orange text-uppercase">{BookingHelper.formatMoney(total)} {auth.cur}</span> will be charged via CASS {auth.cassname} to IATA CODE - <span className="text-orange text-uppercase">{auth.iatacode.substring(0, 4)}-{auth.iatacode.substring(4, auth.iatacode.length)}</span>.
                                </div>
                            ) : (
                                <div className="content-text-desc div_center mt-4" style={{ "maxWidth": "500px" }}>
                                    Your order is being processed as we speak. The payment amount will be charged according to your agreement with CAL.
                                </div>
                            )}
                            <div className="content-text-desc div_center mt-4" style={{ "maxWidth": "500px" }}>
                                Currency exchange to EUR will be based on the exchange rate on the date of the flight.
                            </div>
                            <div className="content-buttons div_center mt-5">
                                <button className="btn btn-orange">Confirm Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAgreement);