import * as ActionTypes from '../actionTypes';


var initialState = {
   //step 1
   secretKey: '',
   quoteID: '',
   routes: [],
   reviewQuote: false,
   selected_flight: null,
   selected_flight_review: null,
   offer_total: 0,
   cost_total: 0,
   cost_total_2: 0,
   unit_total: { total_pieces: 0, total_weight: 0, tcw: 0, total_volume: 0 },
   expriresTime: null,
   already_awb: false,
   awb_number_1: '700',
   awb_number_2: '',
   t1_shipment: "PTP",
   deliver_from: 'Airport',
   deliver_from_country: '',
   deliver_from_airport: '',
   deliver_from_airport_number: 0,
   deliver_from_city: '',
   deliver_from_airport_data: { calcity: "", cityname: "" },
   deliver_from_zipcode: '',
   pickup_dropoff_date: '',
   pickup_dropoff_time_hour: '00',
   pickup_dropoff_time_minute: '00',
   pickup_dropoff_xps: '',
   deliver_to: 'Airport',
   deliver_to_country: '',
   deliver_to_airport: '',
   deliver_to_airport_number: 0,
   deliver_to_city: '',
   deliver_to_zipcode: '',
   deliver_to_airport_data: { calcity: "", cityname: "" },
   commodity_type: '',
   sub_commodity: '',
   commodity_type_name: '',
   sub_commodity_name: '',
   units_type: 'Centimeters',
   units_type_short: "CM",
   unit_items: [],
   shipment_dangerous_goods: false,
   shipment_dangerous_goods_type: 'CAO',
   shipment_lithium_batteries: false,
   shipment_unstackable: false,
   units_total_num_of_pieces: '',
   units_total_weight: '',
   units_total_volume: '',
   units_total_chargable_weight: '',
   instructions: [],
   additional_information: '',
   overlap_shipment: "NonOverlap",
   overlap_shipment_check: false,
   isAwb: false,
   checkbox_term: false,
   checkbox_term_df: false,
   company_name_of_consignee: "",
   company_name_of_consignor: "",
   un: "",
   class: [],
   ParcelChoosed: {},
   PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
   DefaultChoosed: { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } },
   page_df: 1,
   page_pl: 1,
   //step 3
   selectedShippingReceiving: 'Shipping',
   receivingCompanyName: "",
   receivingIATA1: "",
   receivingIATA2: "",
   checkReceivingIATA: true,
   shippingCompanyName: "",
   shippingIATA1: "",
   shippingIATA2: "",
   consignorCompanyName: "",
   consignorAddress: "",
   consignorCity: "",
   consignorCountry: "",
   consignorGroundHandling: "",
   consignorPostalCode: "",
   consigneeAddress: "",
   consigneeCity: "",
   consigneeCompanyName: "",
   consigneeCountry: "",
   consigneeGroundHandling: "",
   consigneePostalCode: "",
   pickupCheck: false,
   pickupAddress: "",
   pickupCity: "",
   pickupComments: "",
   pickupCountry: "",
   pickupEmail: "",
   pickupNameOfContact: "",
   pickupOpeningHours: "",
   pickupPhoneNumber: "",
   pickupPostalCode: "",
   deliverAddress: "",
   deliverCheck: false,
   deliverCity: "",
   deliverComments: "",
   deliverCountry: "",
   deliverEmail: "",
   deliverNameOfContact: "",
   deliverOpeningHours: "",
   deliverPhoneNumber: "",
   deliverPostalCode: "",
   passedData: {},
   step_1_ok: false,
   step_2_ok: false,
   step_3_ok: false,
   selectedSuggessCNEE: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
   selectedSuggessCNOR: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
   populateImA: false,
   populateConsignor: false,
   populateConsignee: false,
   populateDelivery: false,
   populatePickup: false,
   checkbox_term_parcel: false,
   sort_default: "FromDate",
   sort_pl: "FromDate",
   webf: "",
   docno: "",
   clearConsignee: false,
   clearConsignor: false,
   dimemtions_by: 'pieces',
   testCase: "",
   duplicate: false,
   duplicateFields: {
   },
   shipmentData: null,
   payingCustomer: false,
   payingCustomerShipping: false,
   payingCustomerFreight: false,
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case ActionTypes.QUOTES_RESET: {
         var defaultState = {
            //step 1
            secretKey: '',
            quoteID: '',
            routes: [],
            reviewQuote: false,
            unit_total: { total_pieces: 0, total_weight: 0, tcw: 0, total_volume: 0 },
            selected_flight: null,
            selected_flight_review: null,
            offer_total: 0,
            cost_total: 0,
            cost_total_2: 0,
            expriresTime: null,
            already_awb: false,
            awb_number_1: '700',
            awb_number_2: '',
            t1_shipment: "PTP",
            deliver_from: 'Airport',
            deliver_from_country: '',
            deliver_from_airport: '',
            deliver_from_airport_number: 0,
            deliver_from_city: '',
            deliver_from_airport_data: { calcity: "", cityname: "" },
            deliver_from_zipcode: '',
            pickup_dropoff_date: '',
            pickup_dropoff_time_hour: '00',
            pickup_dropoff_time_minute: '00',
            pickup_dropoff_xps: '',
            deliver_to: 'Airport',
            deliver_to_country: '',
            deliver_to_airport: '',
            deliver_to_airport_number: 0,
            deliver_to_city: '',
            deliver_to_zipcode: '',
            deliver_to_airport_data: { calcity: "", cityname: "" },
            commodity_type: '',
            sub_commodity: '',
            commodity_type_name: '',
            sub_commodity_name: '',
            units_type: 'Centimeters',
            units_type_short: "CM",
            unit_items: [],
            shipment_dangerous_goods: false,
            shipment_dangerous_goods_type: 'CAO',
            shipment_lithium_batteries: false,
            shipment_unstackable: false,
            units_total_num_of_pieces: '',
            units_total_weight: '',
            units_total_volume: '',
            units_total_chargable_weight: '',
            instructions: [],
            additional_information: '',
            overlap_shipment: "NonOverlap",
            overlap_shipment_check: false,
            isAwb: false,
            checkbox_term: false,
            checkbox_term_df: false,
            company_name_of_consignee: "",
            company_name_of_consignor: "",
            un: "",
            class: [],
            ParcelChoosed: {},
            PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
            DefaultChoosed: { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } },
            //step 3
            selectedShippingReceiving: 'Shipping',
            receivingCompanyName: "",
            receivingIATA1: "",
            receivingIATA2: "",
            checkReceivingIATA: true,
            shippingCompanyName: "",
            shippingIATA1: "",
            shippingIATA2: "",
            consignorCompanyName: "",
            consignorAddress: "",
            consignorCity: "",
            consignorCountry: "",
            consignorGroundHandling: "",
            consignorGroundHandlingData: { warhsname: "" },
            consignorPostalCode: "",
            consigneeAddress: "",
            consigneeCity: "",
            consigneeCompanyName: "",
            consigneeCountry: "",
            consigneeGroundHandling: "",
            consigneeGroundHandlingData: { warhsname: "" },
            consigneePostalCode: "",
            pickupCheck: false,
            pickupAddress: "",
            pickupCity: "",
            pickupComments: "",
            pickupCountry: "",
            pickupEmail: "",
            pickupCompanyName: "",
            pickupNameOfContact: "",
            pickupOpeningHours: "",
            pickupPhoneNumber: "",
            pickupPostalCode: "",
            deliverAddress: "",
            deliverCheck: false,
            deliverCity: "",
            deliverComments: "",
            deliverCountry: "",
            deliverEmail: "",
            deliverCompanyName: "",
            deliverNameOfContact: "",
            deliverOpeningHours: "",
            deliverPhoneNumber: "",
            deliverPostalCode: "",
            passedData: {},
            step_1_ok: false,
            step_2_ok: false,
            step_3_ok: false,
            selectedSuggessCNEE: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
            selectedSuggessCNOR: { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" },
            populateImA: false,
            populateConsignor: false,
            populateConsignee: false,
            populateDelivery: false,
            populatePickup: false,
            checkbox_term_parcel: false,
            sort_default: "FromDate",
            sort_pl: "FromDate",
            webf: "",
            docno: "",
            clearConsignee: false,
            clearConsignor: false,
            dimemtions_by: 'pieces',
            testCase: "",
            duplicate: false,
            duplicateFields: {
            },
            shipmentData: null,
            payingCustomer: false,
            payingCustomerShipping: false,
            payingCustomerFreight: false,
         };

         return Object.assign({}, state, defaultState)
      }
      case ActionTypes.QUOTES_UPDATE_DATA: {
         var geted_data = action.payload;
         return Object.assign({}, geted_data)
      }
   }
   return state;
}