import React from 'react';
import NavHeader from './partials/NavHeader';
import Header from './ebooking/Header';
import Footer from './ebooking/Footer';
import Slider from './partials/Slider';
import ModalLogin from './partials/ModalLogin';
import ModalRegister from './partials/ModalRegister';
import ModalPassRequired from './partials/ModalPassRequired';
import ModalPassReset from './partials/ModalPassReset';
import ModalConfirm from './partials/ModalConfirm';
import ModalEmailConfirm from './partials/ModalEmailConfirm';
import ModalPaymentID from './partials/ModalPaymentID';
import queryString from 'query-string';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as authActions from '../ninja-redux/actions/authActions';
import * as helpActions from '../ninja-redux/actions/helpActions';

var pagesMustLogin = ["/ebooking/flight-pricing-ad-hoc",
    "/ebooking/booking-detail",
    "/ebooking/flight-pricing-default",
    "/ebooking/flight-pricing-parcel",
    "/ebooking/flight-pricing-pricelist",
    "/ebooking/participants",
    "/ebooking/review-order",
    "/ebooking/payment"];

class QuotesLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowLoading: false,
            modalId: "",
            loginData: "",
            calCode: "",
            iataCode: ""
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.handleFrameTasks);
    }

    componentDidUpdate(oldProps) {
        var { history } = this.props.children.props;
        const newProps = this.props;
        if (oldProps.auth.state != "GETTING_DATA" && newProps.auth.state === "GETTING_DATA") {
            this.props.loadingActions.loadingChangeData(true);
            this.props.authActions.authGetLoginData(newProps.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
            });
        }

        // if (oldProps.auth.state != "CLICK_LOG_OUT" && newProps.auth.state == "CLICK_LOG_OUT") {
        //     history.push("/login");
        // }

        if (oldProps.auth.state != "LOGGED_IN" && newProps.auth.state == "LOGGED_IN") {
            this.hideModal("modalConfirm");
        }
    }

    handleFrameTasks = (e) => {
        var { history } = this.props;
        if (e.data === 'show-login-form') {
            this.props.authActions.authReset();
            this.showModalAuth("modalLogin");
        } else if (e.data === 'show-id-number-form') {
            this.showModalAuth("modalPaymentID");
        }
    }

    getLoginData = (data) => {
        this.setState({ loginData: data });
    }

    headerCallback = (value) => {
        if (value == true) {
            this.setState({ isShowMenu: false });
        } else {
            this.setState({ isShowMenu: true });
        }
    }

    loadingCallback = (value) => {
        if (value == true) {
            this.setState({ isShowLoading: false });
        } else {
            this.setState({ isShowLoading: true });
        }
    }

    showModalAuth = (modalName, closeAll = true) => {
        if (closeAll) {
            window.hideAllModals();
        }
        window.showModals(modalName);
        switch (modalName) {
            case 'modalLogin':
                if (this.modalLogin) {
                    this.modalLogin.onShowModal();
                }
                break;
            case 'modalRegister':
                if (this.modalRegister) {
                    this.modalRegister.onShowModal();
                }
                break;
            case 'modalPassReset':
                if (this.modalPassReset) {
                    this.modalPassReset.onShowModal();
                }
                break;
            case 'modalPassRequired':
                if (this.modalPassRequired) {
                    this.modalPassRequired.onShowModal();
                }
                break;
            case 'modalConfirm':
                if (this.modalConfirm) {
                    this.modalConfirm.onShowModal();
                }
                break;
            case 'modalEmailConfirm':
                if (this.modalEmailConfirm) {
                    this.modalEmailConfirm.onShowModal();
                }
                break;
            case 'modalPaymentID':
                if (this.modalPaymentID) {
                    this.modalPaymentID.onShowModal();
                }
                break;
        }
    };

    hideModal(modal) {
        window.hideModals(modal);
    };

    componentWillReceiveProps(nextProps) {
        var { history } = this.props.children.props;
        const oldProps = this.props;
        if (nextProps.auth.state === "NEW_PASSWORD_REQUIRED") {
            this.showModalAuth('modalPassRequired', !window.checkModalIsShowing('modalPassRequired'));
        }
        if (nextProps.auth.state === "CONFIRMATION_REQUIRED") {
            this.showModalAuth('modalConfirm', !window.checkModalIsShowing('modalConfirm'));
        }
        if (nextProps.auth.state === "EMAIL_VERIFICATION_REQUIRED") {
            this.showModalAuth('modalEmailConfirm', !window.checkModalIsShowing('modalEmailConfirm'));
        }
        if ((nextProps.auth.state === "LOGGED_IN" || oldProps.auth.state != "LOGGED_IN") && this.state.backUrl != "") {
            window.hideAllModals();
            history.push(this.state.backUrl);
            this.setState({ backUrl: '' });
        }
    }

    render() {
        var { loading, auth, country, port } = this.props;
        const { isShowMenu, modalId } = this.state;
        return (
            <div>
                <div className={`ajax-loading ${(loading.isLoading || auth.state === 'LOGGING_IN' || auth.state === 'GETTING_DATA' || auth.state === 'AUTHENTICATED' || country.state === 'COUNTRY_GETTING_DATA' || port.state === 'PORT_GETTING_DATA') ? 'show' : 'hide'}`} >
                    {/* <div className={`ajax-loading ${(loading.isLoading || auth.state === 'LOGGING_IN' || auth.state === 'GETTING_DATA' || auth.state === 'AUTHENTICATED') ? 'show' : 'hide'}`} > */}
                    <div className="loader">
                        <div className="loader__figure"></div>
                        <p className="loader__label">Loading</p>
                    </div>
                </div>
                {/* <NavHeader showLoginForm={this.showModalAuth} history={this.props.children.props.history} onClickLogout={this.props.authActions.authLogout} /> */}
                <Header clickOnHeader={this.headerCallback} showLoginForm={this.showModalAuth} history={this.props.children.props.history} onClickLogout={this.props.authActions.authLogout} />
                <div className={`ebooking main-page${isShowMenu ? ' move-right' : ''}`}  >
                    {this.props.children}
                </div>
                <ModalLogin onRef={ref => (this.modalLogin = ref)} onSubmitLogin={this.getLoginData} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} onSubmit={this.props.authActions.authLogin} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalRegister onRef={ref => (this.modalRegister = ref)} calCode={this.state.calCode} iataCode={this.state.iataCode} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} getCustomerData={this.props.authActions.getCustomerInfo} registerUser={this.props.authActions.authSignup} createCalClient={this.props.authActions.createCalClient} requestAccount={this.props.authActions.requestNewAccount} checkUserExist={this.props.authActions.checkUserExist} country={this.props.country} checkACode={this.props.helpActions.checkACode} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalPassRequired onRef={ref => (this.modalPassRequired = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} auth={auth} onSubmit={this.props.authActions.authCompleteNewPasswordChallenge} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalPassReset onRef={ref => (this.modalPassReset = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} sendVerificationCode={this.props.authActions.authSendVerificationCode} setPassword={this.props.authActions.authConfirmPassword} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalConfirm onRef={ref => (this.modalConfirm = ref)} loginData={this.state.loginData} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} auth={auth} onSubmit={this.props.authActions.authConfirmRegistration} onResend={this.props.authActions.authResendConfirmationCode} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalEmailConfirm onRef={ref => (this.modalEmailConfirm = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalPaymentID onRef={ref => (this.modalPaymentID = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <Footer showMenuMobile={isShowMenu} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    auth: state.auth,
    country: state.country,
    port: state.port
});

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesLayout);