import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import * as MESSAGES from '../../libs/Messages';
import Progress from '../Progress';


export default class SupplierUploaded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
        }
    }

    componentDidMount() {

    }

    convertByte = (filesize) => {
        var return_value = '';
        if (filesize < 1000000) {
            return_value = Math.floor(parseInt(filesize) / 1000) + 'KB';
        } else {
            return_value = Math.floor(parseInt(filesize) / 1000000) + 'MB';
        }
        return return_value;
    }

    render() {

        return (
            <div className={`row`}>
                <div className="form-group col-12 col-md-9 col-lg-6">
                    <div className="mrn-table">
                        <div className="mrn-cell" style={{"minWidth": "50px"}}></div>
                        <div className="mrn-cell">
                            <div className={`claim-uploaded-element bg-white ${this.props.fileInfo.uploaded ? 'success' : ''} ${this.props.fileInfo.uploadError ? 'error' : ''}`}>
                                <div className="progress_bg" style={{ "width": this.props.fileInfo.uploadProgress + "%" }}></div>
                                <div className="row">
                                    <div className="col-10 cut-text text-left">{this.props.fileInfo.file.name}</div>
                                    {/* <div className="col-2">{this.convertByte(this.props.fileInfo.file.size)}</div> */}
                                    <div className="col-2 text-right">
                                        <a className={`claim-upload-cancel-btn ${(!this.props.fileInfo.uploadError && !this.props.fileInfo.uploaded) ? 'd-none' : ''}`} onClick={this.props.onDeleteFile.bind(this, this.props.fileInfo.id)}></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SupplierUploaded.propTypes = {
    onDeleteFile: PropTypes.func
};