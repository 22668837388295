import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


const PriRoute = ({ component: Component, layout: Layout, state, ...rest }) => {

  switch (state) {
    case "LOGGING_IN":
    case "LOGGED_IN":
      return (
        <Route {...rest} render={props => (
          (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        )} />
      );
    default:
      return (
        <Route {...rest} render={props => (
          (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        )} />
      );
  }
}

const mapStateToProps = state => {
  return state;
};

const PrivateRoute = connect(mapStateToProps, null)(PriRoute);

export default PrivateRoute;
