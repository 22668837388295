//CLAIM
export const CLAIM_RESET = 'CLAIM_RESET';
export const CLAIM_CHANGE_DATA = 'CLAIM_CHANGE_DATA';
export const CLAIM_UPDATE_DATA = 'CLAIM_UPDATE_DATA';
export const LOADING_CHANGE_DATA = 'LOADING_CHANGE_DATA';

//EBOOKING
export const EBOOKING_RESET = 'EBOOKING_RESET';
export const EBOOKING_RESET_STEP3 = 'EBOOKING_RESET_STEP3';
export const EBOOKING_CHANGE_DATA = 'EBOOKING_CHANGE_DATA';
export const EBOOKING_UPDATE_DATA = 'EBOOKING_UPDATE_DATA';

//SUPPLIER
export const SUPPLIER_RESET = 'SUPPLIER_RESET';
export const SUPPLIER_CHANGE_DATA = 'SUPPLIER_CHANGE_DATA';
export const SUPPLIER_UPDATE_DATA = 'SUPPLIER_UPDATE_DATA';

//AUTH
export const SET_STATE = 'SET_STATE';
export const SET_CONFIRM_EMAIL = 'SET_CONFIRM_EMAIL';
export const LOGGED_OUT = 'LOGGED_OUT';
export const CLICK_LOG_OUT = 'CLICK_LOG_OUT';
export const LOGGED_FAIL = 'LOGGED_FAIL';
export const AUTHENTICATED = 'AUTHENTICATED';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGGED_IN = 'LOGGED_IN';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const MFA_REQUIRED = 'MFA_REQUIRED';
export const EMAIL_VERIFICATION_REQUIRED = 'EMAIL_VERIFICATION_REQUIRED';
export const CONFIRMATION_REQUIRED = 'CONFIRMATION_REQUIRED';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_CHANGE_DATA = 'AUTH_CHANGE_DATA';
export const AUTH_USER_DATA = 'AUTH_USER_DATA';
export const EBOOKING_UPDATE_STATE = 'EBOOKING_UPDATE_STATE';
export const AUTH_UPDATE_STATE = 'AUTH_UPDATE_STATE';
export const GETTING_DATA = 'GETTING_DATA';

//COUNTRY
export const COUNTRY_CHANGE_DATA = 'COUNTRY_CHANGE_DATA';
//PORT
export const PORT_CHANGE_DATA = 'PORT_CHANGE_DATA';
//DASHBOARD
export const DASHBOARD_CHANGE_DATA = 'DASHBOARD_CHANGE_DATA';
export const DASHBOARD_CHANGE_DATE = 'DASHBOARD_CHANGE_DATE';
export const DASHBOARD_CHANGE_STATE = 'DASHBOARD_CHANGE_STATE';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_CHANGE_FILTER_OPEN = 'DASHBOARD_CHANGE_FILTER_OPEN';
export const DASHBOARD_CHANGE_FILTER_UPDATE = 'DASHBOARD_CHANGE_FILTER_UPDATE';

//CARGO TYPE
export const CARGO_TYPE_CHANGE_DATA = 'CARGO_TYPE_CHANGE_DATA';

export const CARGO_CURRENCIES_DATA = 'CARGO_CURRENCIES_DATA';
export const CARGO_CURRENCIES_GETTING_DATA = 'CARGO_CURRENCIES_GETTING_DATA';

//QUOTES
export const QUOTES_RESET = 'QUOTES_RESET';
export const QUOTES_UPDATE_DATA = 'QUOTES_UPDATE_DATA';

//Participant
export const PARTICIPANT_UPDATE = 'PARTICIPANT_UPDATE';
export const PARTICIPANT_RESET = 'PARTICIPANT_RESET';

