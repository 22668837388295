import React from 'react';
import queryString from 'query-string';
import base64 from 'base-64';

import ModalRegister from './partials/ModalRegister';
import ModalPassRequired from './partials/ModalPassRequired';
import ModalPassReset from './partials/ModalPassReset';
import ModalConfirm from './partials/ModalConfirm';
import ModalEmailConfirm from './partials/ModalEmailConfirm';


/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../ninja-redux/actions/loadingAction';
import * as authActions from '../ninja-redux/actions/authActions';
import * as helpActions from '../ninja-redux/actions/helpActions';

var pagesMustLogin = ["/ebooking/flight-pricing-ad-hoc",
    "/ebooking/booking-detail",
    "/ebooking/flight-pricing-default",
    "/ebooking/flight-pricing-parcel",
    "/ebooking/flight-pricing-pricelist",
    "/ebooking/participants",
    "/ebooking/review-order",
    "/ebooking/payment"];

class EbookingBlueLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowLoading: false,
            modalId: "",
            loginData: "",
            calCode: "",
            iataCode: "",
            backUrl: "",
        }

        this.showModalAuth = this.showModalAuth.bind(this);
    }

    componentDidMount() {
        window.addEventListener("message", this.handleFrameTasks);
    }

    componentDidUpdate(oldProps) {
        var { history } = this.props.children.props;
        const newProps = this.props;
        if (oldProps.auth.state != "GETTING_DATA" && newProps.auth.state === "GETTING_DATA") {           
            this.props.loadingActions.loadingChangeData(true);
            this.props.authActions.authGetLoginData(newProps.auth.jwtToken).then(response => {
                this.props.loadingActions.loadingChangeData(false);
            });
        }

        if (oldProps.auth.state != "CLICK_LOG_OUT" && newProps.auth.state == "CLICK_LOG_OUT") {
            // history.push("/login");
        }
    }

    handleFrameTasks = (e) => {
        var messageData = e.data + "";
        if (messageData.indexOf("loginKey-") >= 0) {
            // var loginKey = base64.decode(messageData.replace("loginKey-", ""));
            var loginKey =window.atob(messageData.replace("loginKey-", ""));
            this.setState({ loginData: loginKey });
        } else if (messageData.indexOf("bacKUrl-") >= 0) {
            this.setState({ backUrl: base64.decode(messageData.replace("bacKUrl-", "")) });
        } else {
            switch (messageData) {
                case "show-register-form":
                    this.showModalAuth("modalRegister");
                    break;

                case "show-reset-pass-form":
                    this.showModalAuth("modalPassReset");
                    break;
            }
        }
    }

    getLoginData = (data) => {
        this.setState({ loginData: data });
    }

    showModalAuth(modalName, closeAll = true) {
        if (closeAll) {
            window.hideAllModals();
        }
        window.showModals(modalName);
        switch (modalName) {
            case 'modalRegister':
                if (this.modalRegister) {
                    this.modalRegister.onShowModal();
                }
                break;
            case 'modalPassReset':
                if (this.modalPassReset) {
                    this.modalPassReset.onShowModal();
                }
                break;
            case 'modalPassRequired':
                if (this.modalPassRequired) {
                    this.modalPassRequired.onShowModal();
                }
                break;
            case 'modalConfirm':
                if (this.modalConfirm) {
                    this.modalConfirm.onShowModal();
                }
                break;
            case 'modalEmailConfirm':
                if (this.modalEmailConfirm) {
                    this.modalEmailConfirm.onShowModal();
                }
                break;
        }
    };

    hideModal(modal) {
        window.hideModals(modal);
    };

    componentWillReceiveProps(nextProps) {
        var { history } = this.props.children.props;
        const oldProps = this.props;
        if (nextProps.auth.state === "NEW_PASSWORD_REQUIRED") {
            this.showModalAuth('modalPassRequired', !window.checkModalIsShowing('modalPassRequired'));
        }
        if (nextProps.auth.state === "CONFIRMATION_REQUIRED") {
            this.showModalAuth('modalConfirm', !window.checkModalIsShowing('modalConfirm'));
        }
        if (nextProps.auth.state === "EMAIL_VERIFICATION_REQUIRED") {
            this.showModalAuth('modalEmailConfirm', !window.checkModalIsShowing('modalEmailConfirm'));
        }
        if ((nextProps.auth.state === "LOGGED_IN" || oldProps.auth.state != "LOGGED_IN") && this.state.backUrl != "") {
            window.hideAllModals();
            history.push(this.state.backUrl);
            this.setState({backUrl: ''});
        }
    }

    render() {
        var { loading, auth, country, port } = this.props;       
        const { isShowMenu, modalId } = this.state;
        return (
            <div>
                <div className={`ajax-loading ${(loading.isLoading || auth.state === 'LOGGING_IN' || auth.state === 'GETTING_DATA' || auth.state === 'AUTHENTICATED' || country.state === 'COUNTRY_GETTING_DATA' || port.state === 'PORT_GETTING_DATA') ? 'show' : 'hide'}`} >
                    <div className="loader">
                        <div className="loader__figure"></div>
                        <p className="loader__label">Loading</p>
                    </div>
                </div>
                <div className={`ebooking blue-page`}  >
                    {this.props.children}
                </div>
                <ModalRegister onRef={ref => (this.modalRegister = ref)} calCode={this.state.calCode} iataCode={this.state.iataCode} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} getCustomerData={this.props.authActions.getCustomerInfo} registerUser={this.props.authActions.authSignup} createCalClient={this.props.authActions.createCalClient} requestAccount={this.props.authActions.requestNewAccount} checkUserExist={this.props.authActions.checkUserExist} country={this.props.country} checkACode={this.props.helpActions.checkACode} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalPassRequired onRef={ref => (this.modalPassRequired = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} auth={auth} onSubmit={this.props.authActions.authCompleteNewPasswordChallenge} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalPassReset onRef={ref => (this.modalPassReset = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} sendVerificationCode={this.props.authActions.authSendVerificationCode} setPassword={this.props.authActions.authConfirmPassword} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalConfirm onRef={ref => (this.modalConfirm = ref)} loginData={this.state.loginData} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} auth={auth} onSubmit={this.props.authActions.authConfirmRegistration} onResend={this.props.authActions.authResendConfirmationCode} closeModal={this.hideModal} showModal={this.showModalAuth} />
                <ModalEmailConfirm onRef={ref => (this.modalEmailConfirm = ref)} loadingChangeData={this.props.loadingActions.loadingChangeData} history={this.props.children.props.history} closeModal={this.hideModal} showModal={this.showModalAuth} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.loading,
    auth: state.auth,
    country: state.country,
    port: state.port
});

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EbookingBlueLayout);