import React from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import fetch from 'isomorphic-fetch';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import axios from 'axios';
import * as claimActions from '../../ninja-redux/actions/claimActions';


class HomeIndex extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // var now = new Date();
        // var tpsText = 'tps';
        // var nowMonth = now.getUTCMonth()+1;
        // if (nowMonth < 10) {
        // 	nowMonth = "0" + nowMonth;
        // }
        // var dateUtc = now.getUTCDate() + "" + nowMonth + now.getUTCFullYear();
        // console.log(dateUtc);


        // this.props.removeUploadFile({ filename: "getawbdata-910f2448b18f47cdbf16c5a9b1ac87e6.png" }).then(res => {
        // 	console.log(res);
        // }).catch(e => {
        // 	console.log(e);
        // });
        // axios.post("http://127.0.0.1:3000/api/service/setclaim", {
        //     "awbnum": "70011623301",
        //     "claimertype": "",
        //     "insurance": "",
        //     "email": "manhhung86it@gmail.com",
        //     "NG": "dsfdsf",
        //     "claimtype": "",
        //     "Insured": "",
        //     "text": "",
        //     "ClaimFiles": [],
        //     "ClaimAspect":
        //         [{
        //             "aspectcode": "23",
        //             "quant": 0,
        //             "weight": 0
        //         },
        //         {
        //             "aspectcode": "12",
        //             "quant": 0,
        //             "weight": 0
        //         }],
        //     "ClaimAmount":
        //         [{
        //             "amountcodename": "12",
        //             "amount": 0,
        //             "currcode": "USD"
        //         },
        //         {
        //             "amountcodename": "12",
        //             "amount": 0,
        //             "currcode": "USD"
        //         }]
        // }).then(res => {
        //     console.log(res);
        // });


		/*fetch("http://127.0.0.1:3000/api/login", {
			method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({description: "kakaak"})
        });*/
        document.title = "Cargo Air lines - Air Cargo Service - Home page"
    }

    render() {
        return (
            <div className="container">


            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, claimActions)(HomeIndex);
