import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Account from '../../layouts/partials/Account';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import base64 from 'base-64';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import validator from 'validator';
import Notification from '../Notification';
import Nav from './Nav';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardActions from '../../ninja-redux/actions/dashboardActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as authActions from '../../ninja-redux/actions/authActions';
import * as helpActions from '../../ninja-redux/actions/helpActions';

/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            user: {
                companyName: "",
                iataCode1: "",
                iataCode2: "",
                vatCode: "",
                fullName: "",
                title: "",
                email: "",
                phoneNumber: "",
                country: "",
                city: "",
                address: "",
                zipCode: ""
            },
            loadedData: false,
            isLoadingCountry: false,
            countries: [],
            iataChanged: false,
            checkedIATA: true,
            selectedCountry: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnBlurSelect = this.handleOnBlurSelect.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    componentDidMount() {
        this.checkShowLoginForm();
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        var countries = [];
        this.props.country.countries.forEach(c => {
            var country = { value: c.country, label: c.countryname };
            countries.push(country);
        });
        this.setState({ countries });


        if (this.props.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        } else if (this.props.auth.state == "LOGGED_IN") {
            this.getData();
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    componentDidUpdate(oldProps) {
        var { user, selectedCountry } = this.state;
        const newProps = this.props;
        if (oldProps.country.state != "COUNTRY_LOADED_DATA" && newProps.country.state == "COUNTRY_LOADED_DATA") {
            selectedCountry = null;
            var countries = [];
            var isLoadingCountry = false;
            newProps.country.countries.forEach(c => {
                // if (c.forwebsite) {
                    var country = { value: c.country, label: c.countryname };
                    countries.push(country);
                // }
            });
            if (user.country) {
                var findCountry = countries.find(c => c.label == user.country);
                if (findCountry) {
                    selectedCountry = findCountry;
                }
            }
            this.setState({ countries, isLoadingCountry, selectedCountry });
        }

        if (oldProps.auth.state != "LOGGED_IN" && newProps.auth.state == "LOGGED_IN" && this.props.dashboard.state != "DASHBOARD_LOADED_DATA") {
            this.getData();
        }

        if (oldProps.dashboard.state != "DASHBOARD_LOADED_DATA" && newProps.dashboard.state == "DASHBOARD_LOADED_DATA") {
            this.convertDataFromRedux();
        }
    }

    getData = () => {      
        this.props.loadingActions.loadingChangeData(true);
        this.props.dashboardActions.getManagerPage({ days: 30 }, this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);            
        });
    }

    convertDataFromRedux = () => {
        var { user, loadedData, selectedCountry, countries } = this.state;
        var reduxData = this.props.dashboard.data;
        selectedCountry = null;
        user = {
            companyName: reduxData.phone.Custdes ? reduxData.phone.Custdes : "",
            iataCode1: reduxData.phone.IataCode ? reduxData.phone.IataCode.substr(0, 7).trim() : "",
            iataCode2: (reduxData.phone.IataCode && reduxData.phone.IataCode.length > 7) ? reduxData.phone.IataCode.substr(7, reduxData.phone.IataCode.length - 7).trim() : "",
            vatCode: reduxData.phone.VatCode ? reduxData.phone.VatCode : "",
            fullName: reduxData.phone.FullName ? reduxData.phone.FullName : "",
            title: reduxData.phone.Postion ? reduxData.phone.Postion : "",
            email: reduxData.phone.Email ? reduxData.phone.Email : "",
            phoneNumber: reduxData.phone.PhoneNumber ? reduxData.phone.PhoneNumber : "",
            country: reduxData.phone.Country ? reduxData.phone.Country : "",
            city: reduxData.phone.city ? reduxData.phone.city : "",
            address: reduxData.phone.Address ? reduxData.phone.Address : "",
            zipCode: reduxData.phone.Zip ? reduxData.phone.Zip : ""
        };
        if (user.country) {
            var findCountry = countries.find(c => c.label == user.country);
            if (findCountry) {
                selectedCountry = findCountry;
            }
        }
        this.setState({ loadedData, user, selectedCountry });
    }

    handleChange = (event) => {
        let { target: { name, value } } = event;
        var { user, errors, iataChanged, checkedIATA } = this.state;
        user[name] = value;
        errors[name] = "";
        if (name == "iataCode1" || name == "iataCode2") {
            iataChanged = true;
            checkedIATA = false;
        }
        this.setState({ user, errors, iataChanged, checkedIATA });
    }

    handleChangeCountry = (selectedCountry) => {
        var { user, errors } = this.state;
        user["country"] = selectedCountry.label;
        errors["country"] = "";
        this.setState({ user, selectedCountry, errors });
    }

    handleOnBlur = (event) => {
        var { errors, user, checkedIATA } = this.state;
        var { ebooking } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (name == "iataCode1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors.iataCode = "";
                if (user.iataCode1.length == 7 && user.iataCode2.length == 4 && !checkedIATA) {
                    this.handlerCheckIATACode(user.iataCode1 + "" + user.iataCode2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "iataCode2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors.iataCode = "";
                if (user.iataCode1.length == 7 && user.iataCode2.length == 4 && !checkedIATA) {
                    this.handlerCheckIATACode(user.iataCode1 + "" + user.iataCode2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else if (name == "email") {
            if (!validator.isEmpty(value) && validator.isEmail(value)) {
                errors[name] = "";
            }
        } else {
            if (!validator.isEmpty(value)) {
                errors[name] = "";
            }
        }
        this.setState({ errors });
    }

    handleOnBlurSelect = (event) => {
        var { errors, selectedCountry } = this.state;
        var { target, target: { id, name, type } } = event;
        if (id == "country") {
            if (selectedCountry) {
                errors["country"] = "";
            }
        }

        this.setState({ errors });
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            // window.parent.postMessage('show-login-form', '*');
            history.push("/login?back=" + base64.encode("/account"));
        }
    }

    handlerCheckIATACode(iataCode) {
        var { errors, iataChanged, checkedIATA } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        this.props.helpActions.checkACode(iataCode).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.length && response.data[0].reason.toUpperCase() == "VALID") {
                errors.iataCode1 = "";
                errors.iataCode2 = "";
                errors.iataCode = "";
                checkedIATA = true;
            } else {
                errors.iataCode = MESSAGES.ERROR_COMPANY_IATA;
                checkedIATA = false;
            }
            this.setState({ errors, iataChanged, checkedIATA });
        });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });
        var isError = false;
        var { user, checkedIATA, errors } = this.state;
        errors = {};

        if (validator.isEmpty(user.companyName)) {
            errors.companyName = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.iataCode1) || (!validator.isEmpty(user.iataCode1) && user.iataCode1.length != 7)) {
            errors.iataCode1 = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }
        if (validator.isEmpty(user.iataCode2) || (!validator.isEmpty(user.iataCode2) && user.iataCode2.length != 4)) {
            errors.iataCode2 = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }

        if (!validator.isEmpty(user.iataCode1) && !validator.isEmpty(user.iataCode2) && !checkedIATA) {
            errors.iataCode = MESSAGES.ERROR_COMPANY_IATA;
            isError = true;
        }

        if (validator.isEmpty(user.vatCode)) {
            errors.vatCode = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.fullName)) {
            errors.fullName = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.title)) {
            errors.title = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.email) || !validator.isEmail(user.email)) {
            errors.email = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.phoneNumber)) {
            errors.phoneNumber = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (user.country == undefined || user.country == "") {
            errors.country = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.city)) {
            errors.city = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.address)) {
            errors.address = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(user.zipCode)) {
            errors.zipCode = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    handleCancel = () => {
        console.log('handleCancel');
    }

    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        // if (!this.isValidation()) {
        //     return false;
        // }
        // const { history } = this.props;
        // const { user } = this.state;

        // console.log('LOGIN DATA: ', user);
        // history.push('/ebooking/payment');        
    }

    render() {
        const { isError, errors, errorMessage, user, countries, selectedCountry, loadedData } = this.state;

        return (
            <div className="page-content">
                <Nav index={3} />
                <div className="container cal-form">
                    <div className="my-info">
                        <div className="row">
                            <div className="col-4 col-md-5 cal-col-title">
                                <div className="group-title">Company Details</div>
                            </div>
                            <div className="col-8 col-md-4 col-right">
                                <div className="form-group group-first">
                                    <label>Company Name</label>
                                    <input type="text" name="companyName" autoComplete={uuid()} maxLength={48} value={user.companyName} onChange={this.handleChange} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.companyName) ? 'input-error' : ''}`} />
                                    <InputError error={errors.companyName} />
                                </div>
                                <div className="form-group IATA-Code">
                                    <label>IATA Code</label>
                                    <div className="clear"></div>
                                    <input type="text" name="iataCode1" autoComplete={uuid()} maxLength={7} value={user.iataCode1} onChange={this.handleChange} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control IATACode1 ${(errors.iataCode1 || errors.iataCode) ? 'input-error' : ''}`} />
                                    <span>-</span>
                                    <input type="text" name="iataCode2" autoComplete={uuid()} maxLength={4} value={user.iataCode2} onChange={this.handleChange} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control IATACode2 ${(errors.iataCode2 || errors.iataCode) ? 'input-error' : ''}`} />
                                    <div className="clear"></div>
                                    <span className="IATA-des">What's a IATA code?</span>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group">
                                    <label>VAT Code</label>
                                    <input type="text" name="vatCode" autoComplete={uuid()} maxLength={16} value={user.vatCode} onChange={this.handleChange} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.vatCode) ? 'input-error' : ''}`} />
                                    <InputError error={errors.vatCode} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-5 cal-col-title">
                                <div className="group-title">Contact Information</div>
                            </div>
                            <div className="col-8 col-md-4 col-right">
                                <div className="form-group group-first">
                                    <label>Full Name</label>
                                    <input type="text" name="fullName" onChange={this.handleChange} autoComplete={uuid()} maxLength={37} value={user.fullName} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.fullName) ? 'input-error' : ''}`} />
                                    <InputError error={errors.fullName} />
                                </div>
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" name="title" onChange={this.handleChange} autoComplete={uuid()} maxLength={32} value={user.title} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.title) ? 'input-error' : ''}`} />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" onChange={this.handleChange} autoComplete={uuid()} maxLength={48} value={user.email} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.email) ? 'input-error' : ''}`} />
                                    <InputError error={errors.email} />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" name="phoneNumber" onChange={this.handleChange} autoComplete={uuid()} maxLength={20} value={user.phoneNumber} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.phoneNumber) ? 'input-error' : ''}`} />
                                    <InputError error={errors.phoneNumber} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-5 cal-col-title">
                                <div className="group-title">Address</div>
                            </div>
                            <div className="col-8 col-md-4 col-right myaccount-select">
                                <div className="form-group group-first">
                                    <label>Country</label>
                                    <Select
                                        inputId="country"
                                        className={`select-box huge-input ${(errors.country) ? 'input-error' : ''}`}
                                        value={selectedCountry}
                                        isSearchable={true}
                                        isDisabled={!loadedData}
                                        onChange={this.handleChangeCountry}
                                        options={countries}
                                        isLoading={this.state.isLoadingCountry}
                                        onBlur={this.handleOnBlurSelect}
                                    />
                                    <InputError error={errors.country} />
                                </div>
                                <div className="form-group">
                                    <label>City</label>
                                    <input type="text" name="city" autoComplete={uuid()} maxLength={40} onChange={this.handleChange} value={user.city} onBlur={this.handleOnBlur} readOnly={!loadedData} className={`form-control ${(errors.city) ? 'input-error' : ''}`} />
                                    <InputError error={errors.city} />
                                </div>
                                <div className="form-group">
                                    <label>Address</label>
                                    <input type="text" name="address" autoComplete={uuid()} maxLength={150} onChange={this.handleChange} onBlur={this.handleOnBlur} value={user.address} readOnly={!loadedData} className={`form-control ${(errors.address) ? 'input-error' : ''}`} />
                                    <InputError error={errors.address} />
                                </div>
                                <div className="form-group">
                                    <label>Zip Code</label>
                                    <input type="text" name="zipCode" autoComplete={uuid()} maxLength={10} onChange={this.handleChange} onBlur={this.handleOnBlur} value={user.zipCode} readOnly={!loadedData} className={`form-control ${(errors.zipCode) ? 'input-error' : ''}`} />
                                    <InputError error={errors.zipCode} />
                                </div>
                            </div>
                        </div>
                        <div className="content-buttons mt-5 offset-0 offset-md-4 d-none">
                            <button type="button" className="btn btn-cancel" onClick={this.handleCancel}>Cancel</button>
                            <button type="submit" className="btn btn-orange" onClick={this.handSubmit}>Enter</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);