import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';

/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class TempUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            errors: {},
            showError: false,
            acceptStatus: ['Cust Negotiation', 'Sent to Cust', 'User Negotiation', 'Decline by Cust', MESSAGES.STATUS_CUST_NEGOTIATION, MESSAGES.STATUS_DECLINE_BY_CUST, MESSAGES.STATUS_SEND_TO_CUST, MESSAGES.STATUS_USER_NEGOTIATION],
            showCase: 0,
            secretKey: '',
            quoteID: '',
            webCode: '',
            loginData: null
        };

    }

    componentDidMount = async () => {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    buildShowCase = () => {
        return (
            <div>
                <div className="big-text">Thank You</div>
                <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>
                    We have the detail and you are ready to book. All that left is to sign in and confirm your booking.
                                            </div>
                <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                    This will only take a moment.
                                            </div>
                <div className="content-buttons div_center mt-5">
                    <Link to={`${process.env.PUBLIC_URL}`} className={`btn btn-orange mb-3`}>Confirm Your Booking</Link>
                </div>
            </div>
        )
    }

    render() {
        var { showError } = this.state;
        return (
            <div className="page-main">

                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>
                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="">
                                        <div className="page-content text-center">
                                            <div className="big-text">Thank You</div>
                                            <div className="content-text-desc div_center mt-3" style={{ "maxWidth": "500px" }}>
                                                We have the detail and you are ready to book. All that left is to sign in and confirm your booking.
                                            </div>
                                            <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                                This will only take a moment.
                                            </div>
                                            <div className="content-buttons div_center mt-5">
                                                <Link to={`${process.env.PUBLIC_URL}`} className={`btn btn-orange mb-3`}>Confirm Your Booking</Link>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        quotesActions: bindActionCreators(quotesActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TempUser);