import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import clone from 'clone';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import * as ActionTypes from '../../ninja-redux/actionTypes';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import validator from 'validator';
// import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import * as helpActions from '../../ninja-redux/actions/helpActions';
let stateLoginChanged = false;
class QuotesParticipants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secretKey: '',
            quoteID: '',
            errorText: '',
            errorMessage: null,
            isError: false,
            isEdit: false,
            errors: {},
            loadedScreen: false,
            noOptionsCustomer: "Type to find customers",
            steps: [
                {
                    'link': 'quotes/flights',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Flights'
                },
                {
                    'link': 'quotes/participants',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'quotes/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            shipmentData: null,
            countries: [],
            isLoadingCountry: false,
            selectedCountryConsignor: null,
            selectedCountryConsignee: null,
            selectedCountryPickup: null,
            selectedCountryDelivery: null,
            showPickup: false,
            showGroundHandlingOrigin: false,
            showDelivery: false,
            showGroundHandlingDestination: false,
            showFreightForwarder: false,
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            consigneeSuggests: [],
            consignorSuggests: [],
            suggestionsReceiving: [],
            receivingSuggests: [],
            consigneeOptions: [],
            consignorOptions: [],
            selectedConsignee: null,
            selectedConsignor: null,
            editFreightForwarder: true,
            showClearConsignor: false,
            showClearConsignee: false,
            consignorGroundHandlingDisabled: false,
            consigneeGroundHandlingDisabled: false,
            loadedScreen: false,
            inputValueShippingAgent: '',
            inputValueReceivingAgent: '',
            inputValueAirline: '',
            inputValueGSA: '',
            inputValueFeederGSA: '',
            inputValueConsignee: '',
            inputValueConsignor: '',
            isLoadingShipingAgent: false,
            isLoadingReceivingAgent: false,
            isLoadingConsignee: false,
            isLoadingConsignor: false,
            isLoadingAirline: false,
            isLoadingGSA: false,
            isLoadingFeederGSA: false,
            disableEditStatus: ['decline', 'canceled', 'cancel', 'creating quote', 'booked'],
        };
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { errors, countries, editFreightForwarder, consigneeOptions, consignorOptions, showPickup, showDelivery } = this.state;
        const { target, target: { name, type } } = event;
        const value = (target.type == 'checkbox') ? target.checked : target.value;
        var { quotes, auth } = this.props;
        if (name == "already_awb") {
            if (value == false) {
                quotes["awb_number_1"] = "700";
                quotes["awb_number_2"] = "";
                errors["awb_number"] = "";
                errors["awb_number_1"] = "";
                errors["awb_number_2"] = "";
            } else {
                quotes["awb_number_1"] = "700";
            }
            quotes[name] = value;
        } else if (name == 'awb_number_1' || name == 'awb_number_2') {
            quotes[name] = value;
            errors[name] = "";
            errors["awb_number"] = "";
        } else if (name == "checkShipping") {
            quotes["selectedShippingReceiving"] = "Shipping";
            quotes["receivingCompanyName"] = "";
            quotes["receivingIATA1"] = "";
            quotes["receivingIATA2"] = "";
            errors["receivingCompanyName"] = "";
            errors["receivingIATA1"] = "";
            errors["receivingIATA2"] = "";

            quotes.receiving_company = null;
            quotes.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" };
            quotes.shipping_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" }];
            quotes.shippingCompanyName = auth.custdes ? auth.custdes : "";
            quotes.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            quotes.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            editFreightForwarder = true;
        } else if (name == "checkReceiving") {
            quotes["selectedShippingReceiving"] = "Receiving";
            quotes["shippingCompanyName"] = "";
            quotes["shippingIATA1"] = "";
            quotes["shippingIATA2"] = "";
            errors["shippingCompanyName"] = "";
            errors["shippingIATA1"] = "";
            errors["shippingIATA2"] = "";

            quotes.shipping_company = null;
            quotes.receivingCompanyName = auth.custdes ? auth.custdes : "";
            quotes.receiving_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" };
            quotes.receiving_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" }];
            quotes.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            quotes.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            editFreightForwarder = false;
        } else if (name == "deliverCheck") {
            if (value) {
                quotes.deliverAddress = quotes.consigneeAddress;
                quotes.deliverCompanyName = quotes.consigneeCompanyName;
                quotes.deliverCountry = quotes.consigneeCountry;
                quotes.deliverCity = quotes.consigneeCity;
                quotes.deliverPostalCode = quotes.consigneePostalCode;
                quotes.gsa = quotes.consignee_company
                quotes.gsa_options = quotes.consignee_company_options
                errors["deliverAddress"] = "";
                errors["deliverCompanyName"] = "";
                errors["deliverCountry"] = "";
                errors["deliverCity"] = "";
                errors["deliverPostalCode"] = "";
                var selectedCountryDelivery = this.state.selectedCountryConsignee;
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            } else {
                if (showDelivery) {
                    quotes.deliverCountry = quotes.deliver_to_country;
                    quotes.deliverCity = quotes.deliver_to_city;
                    quotes.deliverPostalCode = quotes.deliver_to_zipcode;
                    quotes.populatePickup = true;
                }
                quotes.gsa = null
                quotes.gsa_options = []
                quotes.deliverCompanyName = "";
                quotes.deliverNameOfContact = "";
                quotes.deliverAddress = "";

                var selectedCountryDelivery = null;
                var findCountry = countries.find(c => c.label == quotes.deliverCountry);
                if (findCountry) {
                    selectedCountryDelivery = findCountry;
                }
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            }
            quotes[name] = value;
            await this.props.quotesActions.quotesUpdateData(quotes);

        } else if (name == "pickupCheck") {
            if (value) {
                quotes.pickupAddress = quotes.consignorAddress;
                quotes.pickupCompanyName = quotes.consignorCompanyName;
                quotes.pickupCountry = quotes.consignorCountry;
                quotes.pickupCity = quotes.consignorCity;
                quotes.pickupPostalCode = quotes.consignorPostalCode;
                quotes.airline = quotes.consignor_company
                quotes.airline_options = quotes.consignor_company_options
                errors["pickupAddress"] = "";
                errors["pickupCompanyName"] = "";
                errors["pickupCountry"] = "";
                errors["pickupCity"] = "";
                errors["pickupPostalCode"] = "";
                var selectedCountryPickup = this.state.selectedCountryConsignor;
                this.setState({ selectedCountryPickup: selectedCountryPickup });
            } else {
                if (showPickup) {
                    quotes.pickupCountry = quotes.deliver_from_country;
                    quotes.pickupCity = quotes.deliver_from_city;
                    quotes.pickupPostalCode = quotes.deliver_from_zipcode;
                    quotes.populateDelivery = true;
                }
                quotes.airline = null
                quotes.airline_options = []
                quotes.pickupCompanyName = "";
                quotes.pickupNameOfContact = "";
                quotes.pickupAddress = "";
                var selectedCountryPickup = null;
                var findCountry = countries.find(c => c.label == quotes.pickupCountry);
                if (findCountry) {
                    selectedCountryPickup = findCountry;
                }
                // console.log('vao day 1');
                this.setState({ selectedCountryPickup: selectedCountryPickup });
            }

            quotes[name] = value;
        } else if (name == "receivingIATA1") {
            errors["receivingIATA1"] = "";
            errors["receivingIATA"] = "";
            quotes[name] = value;
        } else if (name == "receivingIATA2") {
            errors["receivingIATA2"] = "";
            errors["receivingIATA"] = "";
            quotes[name] = value;
        } else if (name == "consigneeGroundHandling") {
            errors["consigneeGroundHandling"] = "";
            errors["deliverPostalCode"] = "";
            errors["deliverCountry"] = "";
            errors["deliverCity"] = "";
            errors["deliverAddress"] = "";

            quotes[name] = value;
            quotes.consigneeGroundHandlingData = { warhsname: "" };

            var findOption = consigneeOptions.find(x => x.value === value);
            if (findOption) {
                quotes.consigneeGroundHandlingData = findOption;
            }
            this.choosedConsigneeWarehouse();
        } else if (name == "consignorGroundHandling") {
            errors["consignorGroundHandling"] = "";
            errors["pickupPostalCode"] = "";
            errors["pickupCountry"] = "";
            errors["pickupCity"] = "";
            errors["pickupAddress"] = "";
            quotes[name] = value;
            quotes.consignorGroundHandlingData = { warhsname: "" };
            var findOption = consignorOptions.find(x => x.value === value);
            if (findOption) {
                quotes.consignorGroundHandlingData = findOption;
            }
            this.choosedConsignorWarehouse();
        } else {
            if (quotes.pickupCheck) {
                switch (name) {
                    case "consignorCity":
                        quotes.pickupCity = value;
                        break;
                    case "consignorAddress":
                        quotes.pickupAddress = value;
                        break;
                    case "consignorCompanyName":
                        quotes.pickupCompanyName = value;
                        break;
                    case "consignorPostalCode":
                        quotes.pickupPostalCode = value;
                        break;
                }
            }
            if (quotes.deliverCheck) {
                switch (name) {
                    case "consigneeCity":
                        quotes.deliverCity = value;
                        break;
                    case "consigneeAddress":
                        quotes.deliverAddress = value;
                        break;
                    case "consigneeCompanyName":
                        quotes.deliverCompanyName = value;
                        break;
                    case "consigneePostalCode":
                        quotes.deliverPostalCode = value;
                        break;
                }
            }
            quotes[name] = value;
            errors[name] = "";
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
        this.setState({ editFreightForwarder, errors });
    }


    handleChangePickupCountry = async (selectedCountryPickup) => {
        var { errors } = this.state
        var { quotes } = this.props;
        quotes["pickupCountry"] = selectedCountryPickup.label;
        errors['pickupCountry'] = '';
        this.setState({ selectedCountryPickup, errors });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeDeliveryCountry = async (selectedCountryDelivery) => {
        var { errors } = this.state
        var { quotes } = this.props;
        quotes["deliverCountry"] = selectedCountryDelivery.label;
        errors['deliverCountry'] = '';
        this.setState({ selectedCountryDelivery, errors });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeConsigneeCountry = async (selectedCountryConsignee) => {
        var { errors } = this.state
        var { quotes } = this.props;
        quotes["consigneeCountry"] = selectedCountryConsignee.label;
        errors['consigneeCountry'] = '';
        if (quotes.deliverCheck) {
            quotes.deliverCountry = quotes.consigneeCountry;
            this.setState({ selectedCountryDelivery: selectedCountryConsignee });
        }
        this.setState({ selectedCountryConsignee, errors });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeConsignorCountry = async (selectedCountryConsignor) => {
        var { errors } = this.state
        var { quotes } = this.props;
        quotes["consignorCountry"] = selectedCountryConsignor.label;
        errors['consignorCountry'] = '';
        if (quotes.pickupCheck) {
            quotes.pickupCountry = quotes.consignorCountry;
            this.setState({ selectedCountryPickup: selectedCountryConsignor });
        }
        this.setState({ selectedCountryConsignor, errors });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    componentDidUpdate = async (oldProps) => {
        var quoteID = this.props.match.params.quoteID;
        var { history } = this.props;
        const newProps = this.props;
        var { loadedScreen } = this.state;
        var { country, port, currencies } = this.props;
        if(newProps.auth.state ==='LOGGED_IN' && newProps.auth.IsGSA){
            history.push('/quotes/participants-gsa/' + quoteID);  
            return;
        }
        if (country.countries.length && port.ports.length && currencies.data.length && !loadedScreen) {
            this.initScreen();
        }
        if (newProps.auth.state != "LOGGED_IN") {
            stateLoginChanged = false;
        }   
    }

    componentDidMount() {
        var { showError, quoteID, steps } = this.state;
        var { quotes, auth, history } = this.props;
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        quoteID = this.props.match.params.quoteID;
        steps = steps.map((step, i) => {
            if (quotes.secretKey) {
                if (i < 2) {
                    step.link += '/' + quoteID;
                } else {
                    step.link = 'quotes/review/' + quotes.secretKey + '/' + quoteID;
                }
            } else {
                step.link += '/' + quoteID;
            }
            return step;
        })

        this.setState({ steps });

        // if (auth.IsGSA) {
        //     history.push('/quotes/participants-gsa/' + quoteID);   
        // }

        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var quoteID = this.props.match.params.quoteID;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED') && !auth.expiredToken && !window.checkHasModalShowing()) {
            history.push("/login?back=" + base64.encode("/quotes/participants/" + quoteID));
        }
    }

    handClickStep = async () => {

    }

    initScreen = async () => {
        this.setState({ loadedScreen: true });
        var { secretKey, quoteID, steps, editFreightForwarder, showFreightForwarder, showPickup, showDelivery, showGroundHandlingOrigin, showGroundHandlingDestination, selectedCountryConsignee, selectedCountryConsignor, selectedCountryDelivery, selectedCountryPickup, showClearConsignor, showClearConsignee, inputValueAirline, inputValueGSA, inputValueConsignee, inputValueConsignor } = this.state;
        var { quotes, auth, history } = this.props;
        quoteID = this.props.match.params.quoteID;
        if (!quotes || quotes.quoteID !== quoteID) {
            await this.props.quotesActions.quotesReset();
            await this.props.loadingActions.loadingChangeData(true);
            let getQuotesData = await this.props.quotesActions.buildQuoteData(secretKey, quoteID, true);
            await this.props.loadingActions.loadingChangeData(false);
            if (getQuotesData.success) {
                await this.props.quotesActions.quotesUpdateData(getQuotesData.quotesData);
                quotes = getQuotesData.quotesData;
            } else {
                this.setState({ errorText: 'showContactLink' })
            }
        }

        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toLowerCase();
        if (!quotes.status || this.state.disableEditStatus.includes(status)) {
            this.setState({ errorText: 'showContactLink' })
            return;
        }

        if(!quotes.selectedShippingReceiving){
            quotes.selectedShippingReceiving = 'Shipping';
            await this.props.quotesActions.quotesUpdateData(quotes);
        }
        if (quotes.selectedShippingReceiving === 'Shipping' && !quotes.shipping_company) {
            quotes.shipping_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" };
            quotes.shipping_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" }];
            quotes.shippingCompanyName = auth.custdes ? auth.custdes : "";
            // console.log("auth.iatacode", auth.iatacode);
            quotes.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            quotes.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            editFreightForwarder = true;
            await this.props.quotesActions.quotesUpdateData(quotes);
        } else if (quotes.selectedShippingReceiving === 'Receiving') {
            quotes.receiving_company = { value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" };
            quotes.receiving_company_options = [{ value: auth.cust ? auth.cust : "", custname: auth.custname ? auth.custname : "", label: auth.custdes ? auth.custdes : "" }];
            quotes.receivingCompanyName = auth.custdes ? auth.custdes : "";
            quotes.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            quotes.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            editFreightForwarder = true;
            await this.props.quotesActions.quotesUpdateData(quotes);
        }

        if (quotes.deliver_to_airport && quotes.deliver_to_airport.value === "TLV") {
            showFreightForwarder = true;
        }
        if (quotes.deliver_from == "ZipCode") {
            showPickup = true;
        } else {
            showGroundHandlingOrigin = true;
        }
        if (quotes.deliver_to == "ZipCode") {
            showDelivery = true;
        } else {
            showGroundHandlingDestination = true;
        }
        var countries = [];
        this.props.country.countries.forEach(c => {
            var country = { value: c.country, label: c.countryname };
            if (c.countryname == quotes.consigneeCountry) {
                selectedCountryConsignee = country;
            }
            if (c.countryname == quotes.consignorCountry) {
                selectedCountryConsignor = country;
            }
            if (c.countryname == quotes.deliverCountry) {
                selectedCountryDelivery = country;
            }
            if (c.countryname == quotes.pickupCountry) {
                // console.log('vao day');
                selectedCountryPickup = country;
            }
            countries.push(country);
        });

        // showPickup = true;
        // showDelivery = true;
        // showFreightForwarder = true;
        // showGroundHandlingOrigin = false;
        // showGroundHandlingDestination = false;

        if (!quotes.consignee_company) {
            inputValueConsignee = quotes.consigneeCompanyName;
        }
        if (!quotes.consignor_company) {
            inputValueConsignor = quotes.consignorCompanyName;
        }
        if (!quotes.airline) {
            inputValueAirline = quotes.pickupCompanyName;
        }
        if (!quotes.gsa) {
            inputValueGSA = quotes.deliverCompanyName;
        }

        this.setState({
            inputValueConsignee,
            inputValueConsignor,
            inputValueAirline,
            inputValueGSA,
            showPickup,
            showDelivery,
            showFreightForwarder,
            showGroundHandlingOrigin,
            showGroundHandlingDestination,
            countries: countries,
            selectedCountryConsignee,
            selectedCountryConsignor,
            selectedCountryDelivery,
            selectedCountryPickup,
            editFreightForwarder,
            showClearConsignor,
            showClearConsignee
        });
        await this.props.loadingActions.loadingChangeData(true);
        if ((showGroundHandlingOrigin || showGroundHandlingDestination) && (quotes.deliver_from_airport_number != 0 || quotes.deliver_to_airport_number != 0)) {
            this.getWarehouses(quotes.deliver_from_airport_number, quotes.deliver_to_airport_number);
        }
        // console.log(quotes);
        await this.props.loadingActions.loadingChangeData(false);


        if (localStorage.getItem('SHOW_VALIDATE')) {
            localStorage.removeItem('SHOW_VALIDATE');
            this.isValidation();
        }
    }

    onChangeSugestCNEE = async (event, { newValue }) => {
        var { errors, selectedCountryConsignee, showClearConsignee, selectedCountryDelivery } = this.state;
        var { quotes } = this.props;
        quotes.consigneeCompanyName = newValue;
        errors["consigneeCompanyName"] = "";
        errors["consigneeAddress"] = "";
        errors["consigneeCity"] = "";
        errors["consigneeCountry"] = "";
        errors["consigneePostalCode"] = "";
        var selectedOption = this.state.consigneeSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            quotes.clearConsignee = false;
            quotes.consigneeAddress = selectedOption[0].address;
            quotes.consigneeCity = selectedOption[0].cityname;
            quotes.consigneeCountry = selectedOption[0].countryname;
            quotes.consigneePostalCode = selectedOption[0].zipcode;
            quotes.populateConsignee = true;
            showClearConsignee = true;
            var findCountry = this.state.countries.find(c => c.label == quotes.consigneeCountry);
            if (findCountry) {
                selectedCountryConsignee = findCountry;
            }
        }

        if (quotes.deliverCheck) {
            quotes.deliverCompanyName = newValue;
            quotes.deliverAddress = quotes.consigneeAddress;
            quotes.deliverCity = quotes.consigneeCity;
            quotes.deliverCountry = quotes.consigneeCountry;
            quotes.deliverPostalCode = quotes.consigneePostalCode;
            selectedCountryDelivery = selectedCountryConsignee;
        }

        this.setState({
            errors, selectedCountryConsignee: selectedCountryConsignee, showClearConsignee: showClearConsignee, selectedCountryDelivery: selectedCountryDelivery
        });
        await this.props.quotesActions.quotesUpdateData(quotes);
    };

    onChangeSugestCNOR = async (event, { newValue }) => {
        var { errors, selectedCountryConsignor, showClearConsignor, selectedCountryPickup } = this.state;
        var { quotes } = this.props;
        quotes.consignorCompanyName = newValue;
        errors["consignorCompanyName"] = "";
        errors["consignorAddress"] = "";
        errors["consignorCity"] = "";
        errors["consignorCountry"] = "";
        errors["consignorPostalCode"] = "";
        var selectedOption = this.state.consignorSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            quotes.clearConsignor = false;
            quotes.consignorAddress = selectedOption[0].address;
            quotes.consignorCity = selectedOption[0].cityname;
            quotes.consignorCountry = selectedOption[0].countryname;
            quotes.consignorPostalCode = selectedOption[0].zipcode;
            quotes.populateConsignor = true;
            showClearConsignor = true;
            var findCountry = this.state.countries.find(c => c.label == quotes.consignorCountry);
            if (findCountry) {
                selectedCountryConsignor = findCountry;
            }
        }
        if (quotes.pickupCheck) {
            quotes.pickupCompanyName = quotes.consignorCompanyName;
            quotes.pickupAddress = quotes.consignorAddress;
            quotes.pickupCity = quotes.consignorCity;
            quotes.pickupCountry = quotes.consignorCountry;
            quotes.pickupPostalCode = quotes.consignorPostalCode;
            selectedCountryPickup = selectedCountryConsignor;
            // console.log('vao day 2');
        }

        this.setState({
            errors, selectedCountryConsignor: selectedCountryConsignor, showClearConsignor: showClearConsignor, selectedCountryPickup: selectedCountryPickup
        });
        await this.props.quotesActions.quotesUpdateData(quotes);
    };

    onChangeSugestReceiving = async (event, { newValue }) => {
        var { errors } = this.state;
        var { quotes } = this.props;
        quotes.receivingCompanyName = newValue;
        errors["receivingCompanyName"] = "";

        var selectedOption = this.state.receivingSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            quotes.receivingIATA1 = selectedOption[0].iata ? selectedOption[0].iata.substr(0, 7).trim() : '';
            quotes.receivingIATA2 = (selectedOption[0].iata && selectedOption[0].iata.length > 7) ? selectedOption[0].iata.substr(7, selectedOption[0].iata.length - 7).trim() : '';
        }

        this.setState({
            errors
        });
        await this.props.quotesActions.quotesUpdateData(quotes);
    };

    onSuggestionsFetchRequestedCNEE = ({ value }) => {
        this.setState({
            suggestionsCNEE: this.getSuggestionsCNEE(value)
        });
    };

    onSuggestionsFetchRequestedCNOR = ({ value }) => {
        this.setState({
            suggestionsCNOR: this.getSuggestionsCNOR(value)
        });
    };

    onSuggestionsFetchRequestedReceiving = ({ value }) => {
        this.setState({
            suggestionsReceiving: this.getSuggestionsReceiving(value)
        });
    };

    onSuggestionsClearRequestedCNEE = () => {
        this.setState({
            suggestionsCNEE: []
        });
    };

    onSuggestionsClearRequestedCNOR = () => {
        this.setState({
            suggestionsCNOR: []
        });
    };

    onSuggestionsClearRequestedReceiving = () => {
        this.setState({
            suggestionsReceiving: []
        });
    };

    getParticipentREC = () => {
        var { receivingSuggests } = this.state;
        receivingSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "REC", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    receivingSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ receivingSuggests });
        });
    }

    getParticipentCNEE = () => {
        var { consigneeSuggests } = this.state;
        consigneeSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNEE", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consigneeSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consigneeSuggests });
        });
    }

    getParticipentCNOR = () => {
        var { consignorSuggests } = this.state;
        consignorSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNOR", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consignorSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consignorSuggests });
        });
    }

    getWarehouses = async (fport, tport) => {
        var { quotes } = this.props;
        var { consigneeOptions, consignorOptions, consigneeGroundHandlingDisabled, consignorGroundHandlingDisabled } = this.state;
        consigneeOptions = [];
        consignorOptions = [];
        this.props.loadingActions.loadingChangeData(true);
        var t = this;
        if (fport != 0) {
            let response = await this.props.ebookingActions.getWarehouses(fport, 0);
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.warehouse.map(wh => {
                    consignorOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                });
                consignorOptions = consignorOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
            }

            quotes.consignorGroundHandlingData = { warhsname: "" };
            var findOption = consignorOptions.find(x => x.value === quotes['consignorGroundHandling']);
            if (findOption) {
                quotes.consignorGroundHandlingData = findOption;
            } else {
                quotes['consignorGroundHandling'] = "";
            }
            // await this.props.quotesActions.quotesUpdateData(quotes);
            // this.choosedConsignorWarehouse();

            consignorGroundHandlingDisabled = false;
            if (consignorOptions.length == 1) {
                consignorGroundHandlingDisabled = true;
                quotes.consignorGroundHandling = consignorOptions[0].value;
                quotes.consignorGroundHandlingData = consignorOptions[0];
            } else if (consignorOptions.length > 1 && quotes.duplicate && quotes.duplicateFields.PICKNAME && quotes['consignorGroundHandling'] === "") {
                consignorOptions.map(co => {
                    if (co.warhsname === quotes.duplicateFields.PICKNAME) {
                        quotes.consignorGroundHandling = co.value;
                        quotes.consignorGroundHandlingData = co;
                    }
                })
            }

            this.setState({ consigneeOptions, consignorOptions, consignorGroundHandlingDisabled });
            if (tport != 0) {
                this.props.loadingActions.loadingChangeData(true);
                let responseTo = await this.props.ebookingActions.getWarehouses(0, tport);
                this.props.loadingActions.loadingChangeData(false);
                if (responseTo.success && responseTo.data.Success) {
                    responseTo.data.warehouse.map(wh => {
                        consigneeOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                    });
                    consigneeOptions = consigneeOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
                }

                quotes.consigneeGroundHandlingData = { warhsname: "" };
                var findOption = consignorOptions.find(x => x.value === quotes['consigneeGroundHandling']);
                if (findOption) {
                    quotes.consigneeGroundHandlingData = findOption;
                } else {
                    quotes['consigneeGroundHandling'] = "";
                }
                consigneeGroundHandlingDisabled = false;
                if (consigneeOptions.length == 1) {
                    consigneeGroundHandlingDisabled = true;
                    quotes.consigneeGroundHandling = consigneeOptions[0].value;
                    quotes.consigneeGroundHandlingData = consigneeOptions[0];
                } else if (quotes.deliver_to_airport && quotes.deliver_to_airport.value == "TLV") {
                    var mamanIndex = consigneeOptions.findIndex(x => x.value == "MAMAN");
                    if (mamanIndex >= 0) {
                        quotes.consigneeGroundHandling = consigneeOptions[mamanIndex].value;
                        quotes.consigneeGroundHandlingData = consigneeOptions[mamanIndex];
                    }
                }

                this.setState({ consigneeOptions, consigneeGroundHandlingDisabled });
            }
            this.props.quotesActions.quotesUpdateData(quotes);
            this.choosedConsignorWarehouse(true);
        } else if (tport != 0) {
            this.props.loadingActions.loadingChangeData(true);
            let responseTo = await this.props.ebookingActions.getWarehouses(0, tport);
            this.props.loadingActions.loadingChangeData(false);
            if (responseTo.success && responseTo.data.Success) {
                responseTo.data.warehouse.map(wh => {
                    consigneeOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                });
                consigneeOptions = consigneeOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
                quotes.consigneeGroundHandlingData = { warhsname: "" };
                var findOption = consignorOptions.find(x => x.value === quotes['consigneeGroundHandling']);
                if (findOption) {
                    quotes.consigneeGroundHandlingData = findOption;
                    // t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: quotes.consigneeGroundHandlingData }).then(responseData => { });
                } else {
                    quotes['consigneeGroundHandling'] = "";
                    // t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: quotes.consigneeGroundHandling }).then(responseData => { });
                }
                consigneeGroundHandlingDisabled = false;
                if (consigneeOptions.length == 1) {
                    consigneeGroundHandlingDisabled = true;
                    quotes.consigneeGroundHandling = consigneeOptions[0].value;
                    quotes.consigneeGroundHandlingData = consigneeOptions[0];
                } else if (quotes.deliver_to_airport && quotes.deliver_to_airport.value == "TLV") {
                    var mamanIndex = consigneeOptions.findIndex(x => x.value == "MAMAN");
                    if (mamanIndex >= 0) {
                        quotes.consigneeGroundHandling = consigneeOptions[mamanIndex].value;
                        quotes.consigneeGroundHandlingData = consigneeOptions[mamanIndex];
                    }
                }
            }
            this.setState({ consigneeOptions, consignorOptions, consigneeGroundHandlingDisabled });
            this.props.quotesActions.quotesUpdateData(quotes);
            this.choosedConsigneeWarehouse();
        }
    }

    choosedConsigneeWarehouse = async () => {
        var { selectedCountryDelivery } = this.state;
        var { quotes } = this.props;
        selectedCountryDelivery = null;
        quotes.deliverCity = quotes.consigneeGroundHandlingData.city ? quotes.consigneeGroundHandlingData.city : "";
        quotes.deliverCountry = quotes.consigneeGroundHandlingData.country ? quotes.consigneeGroundHandlingData.country : "";
        quotes.deliverAddress = quotes.consigneeGroundHandlingData.address ? quotes.consigneeGroundHandlingData.address : "";
        quotes.deliverPostalCode = quotes.consigneeGroundHandlingData.zip ? quotes.consigneeGroundHandlingData.zip : "";
        quotes.populateDelivery = true;
        await this.props.quotesActions.quotesUpdateData(quotes);
        // console.log("quotes.deliverCountry", quotes.deliverCountry);
        var findCountry = this.state.countries.find(c => c.label === quotes.deliverCountry);
        if (findCountry) {
            selectedCountryDelivery = findCountry;
        }
        this.setState({ selectedCountryDelivery });
    }

    choosedConsignorWarehouse = async (runConsignee = false) => {
        var { selectedCountryPickup } = this.state;
        var { quotes } = this.props;
        selectedCountryPickup = null;
        quotes.pickupCity = quotes.consignorGroundHandlingData.city ? quotes.consignorGroundHandlingData.city : "";
        quotes.pickupCountry = quotes.consignorGroundHandlingData.country ? quotes.consignorGroundHandlingData.country : "";
        quotes.pickupAddress = quotes.consignorGroundHandlingData.address ? quotes.consignorGroundHandlingData.address : "";
        quotes.pickupPostalCode = quotes.consignorGroundHandlingData.zip ? quotes.consignorGroundHandlingData.zip : "";
        quotes.populatePickup = true;

        var findCountry = this.state.countries.find(c => c.label === quotes.pickupCountry);
        if (findCountry) {
            selectedCountryPickup = findCountry;
        }
        // console.log('vao day 3', quotes.pickupCountry);
        await this.props.quotesActions.quotesUpdateData(quotes);
        this.setState({ selectedCountryPickup });
        if (runConsignee) {
            this.choosedConsigneeWarehouse();
        }
    }


    clickClearPopulate = (typePopulate) => {
        var { selectedCountryConsignee, selectedCountryConsignor, showClearConsignee, showClearConsignor } = this.state;
        var { quotes } = this.props;
        if (typePopulate == "consignor") {
            quotes.clearConsignor = true;
            quotes.consignorCompanyName = "";
            quotes.consignorAddress = "";
            quotes.consignorCity = "";
            quotes.consignorCountry = "";
            quotes.consignorPostalCode = "";
            quotes.populateConsignor = false;
            showClearConsignor = false;
            selectedCountryConsignor = null;
            quotes.consignor_company = null;
            quotes.consignor_company_options = [];
            if (quotes.pickupCheck) {
                quotes.pickupCountry = quotes.consignorCountry;
                quotes.pickupCity = quotes.consignorCity;
                quotes.pickupPostalCode = quotes.consignorPostalCode;
                quotes.pickupAddress = quotes.consignorAddress;
                quotes.pickupCompanyName = quotes.consignorCompanyName;
                var selectedCountryPickup = selectedCountryConsignor;
                quotes.airline = null;
                this.setState({ selectedCountryPickup: selectedCountryPickup });
                // console.log('vao day 4');
            }
        } else if (typePopulate == "consignee") {
            quotes.clearConsignee = true;
            quotes.consigneeCompanyName = "";
            quotes.consigneeAddress = "";
            quotes.consigneeCity = "";
            quotes.consigneeCountry = "";
            quotes.consigneePostalCode = "";
            quotes.populateConsignee = false;
            showClearConsignee = false;
            selectedCountryConsignee = null;
            quotes.consignee_company = null
            quotes.consignee_company_options = [];
            if (quotes.deliverCheck) {
                quotes.deliverCountry = quotes.consigneeCountry;
                quotes.deliverCity = quotes.consigneeCity;
                quotes.deliverPostalCode = quotes.consigneePostalCode;
                quotes.deliverAddress = quotes.consigneeAddress;
                quotes.deliverCompanyName = quotes.consigneeCompanyName;
                var selectedCountryDelivery = selectedCountryConsignee;
                quotes.gsa = null
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            }
        }
        this.setState({ selectedCountryConsignee: selectedCountryConsignee, selectedCountryConsignor: selectedCountryConsignor, showClearConsignor: showClearConsignor, showClearConsignee: showClearConsignee });
        this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
    }

    isValidationAwb = () => {
        const { errors } = this.state;
        var { quotes } = this.props;
        var isError = false;
        if (quotes.awb_number_1 != '' && quotes.awb_number_2 != '') {
            if (validator.isEmpty(quotes.awb_number_1)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }
            if (!validator.isNumeric(quotes.awb_number_1)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }

            if (!validator.isLength(quotes.awb_number_1, { min: 3, max: 3 })) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }

            if (validator.isEmpty(quotes.awb_number_2)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }

            if (!validator.isNumeric(quotes.awb_number_2)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }

            if (!validator.isLength(quotes.awb_number_2, { min: 8, max: 8 })) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }
        }
        if (!isError) {
            errors['awb_number'] = '';
            if (quotes.awb_number_2 != "" && quotes.awb_number_1 != "") {
                this.getAwb();
            }
        }

        this.setState({ errors });
    }

    getAwb = async () => {
        const { errors } = this.state;
        var { quotes } = this.props;
        var awbNumber = quotes.awb_number_1 + '' + quotes.awb_number_2;
        this.props.loadingActions.loadingChangeData(true);
        var jwtToken = this.props.auth.jwtToken;
        this.props.helpActions.checkAllocations(awbNumber, jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success && response.data.res == "Y") {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = "";
                quotes.isAwb = true;
            } else {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                quotes.isAwb = false;
            }
            this.props.quotesActions.quotesUpdateData(quotes);
            this.setState({ errors });
        });
    }

    /* Validate form */
    isValidation = async () => {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        const { showPickup, showDelivery, showFreightForwarder, showGroundHandlingOrigin, showGroundHandlingDestination } = this.state;
        var { quotes } = this.props;
        if (quotes.already_awb) {
            var error_awb = false;
            if (quotes.awb_number_1 == "") {
                errors['awb_number_1'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
                error_awb = true;
            }
            if (quotes.awb_number_2 == "" && !error_awb) {
                errors['awb_number_2'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
                error_awb = true;
            }
            if (quotes.awb_number_1 != "" && quotes.awb_number_2 != "" && !quotes.isAwb && !error_awb) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }
        }

        if (validator.isEmpty(quotes.consigneeCompanyName + "")) {
            errors['consigneeCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!quotes.consignor_company && validator.isEmpty(quotes.consignorAddress + "")) {
            errors['consignorAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!quotes.consignorCity && validator.isEmpty(quotes.consignorCity + "") && !quotes.consignor_company) {
            errors['consignorCity'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(quotes.consignorCountry + "") && !quotes.consignor_company) {
            errors['consignorCountry'] = "Required";
            isError = true;
        }
        if (!quotes.consignor_company && validator.isEmpty(quotes.consignorPostalCode + "")) {
            errors['consignorPostalCode'] = "Required";
            isError = true;
        }

        if (validator.isEmpty(quotes.consignorCompanyName + "")) {
            errors['consignorCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!quotes.consignee_company && validator.isEmpty(quotes.consigneeAddress + "")) {
            errors['consigneeAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!quotes.consigneeCity && validator.isEmpty(quotes.consigneeCity + "") && !quotes.consignee_company) {
            errors['consigneeCity'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(quotes.consigneeCountry + "") && !quotes.consignee_company) {
            errors['consigneeCountry'] = "Required";
            isError = true;
        }
        if (!quotes.consignee_company && validator.isEmpty(quotes.consigneePostalCode + "")) {
            errors['consigneePostalCode'] = "Required";
            isError = true;
        }


        if (!showFreightForwarder || (showFreightForwarder && quotes.selectedShippingReceiving == "Shipping")) {
            if (validator.isEmpty(quotes.shippingCompanyName + "") && !quotes.populateImA) {
                errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.isEmpty(quotes.shippingIATA1 + "") && quotes.shippingIATA1.length < 7) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.shippingIATA2 + "") && quotes.shippingIATA2.length < 4) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(quotes.shippingIATA1 + "") && !validator.isEmpty(quotes.shippingIATA2 + "")) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(quotes.shippingIATA1 + "") && validator.isEmpty(quotes.shippingIATA2 + "")) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (showFreightForwarder && quotes.selectedShippingReceiving != "Shipping") {
            if (validator.isEmpty(quotes.receivingCompanyName + "") && !quotes.populateImA) {
                errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }

        if (!validator.isEmpty(quotes.receivingIATA1 + "") && quotes.receivingIATA1.length < 7) {
            errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }
        if (!validator.isEmpty(quotes.receivingIATA2 + "") && quotes.receivingIATA2.length < 4) {
            errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }
        if (validator.isEmpty(quotes.receivingIATA1 + "") && !validator.isEmpty(quotes.receivingIATA2 + "")) {
            errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }
        if (!validator.isEmpty(quotes.receivingIATA1 + "") && validator.isEmpty(quotes.receivingIATA2 + "")) {
            errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }

        if (quotes.receivingIATA1.length == 7 && quotes.receivingIATA2.length == 4 && !quotes.checkReceivingIATA) {
            errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
            isError = true;
        }

        if (showPickup) {
            if ((quotes.pickupCountry == undefined || quotes.pickupCountry == "") && !quotes.airline) {
                errors['pickupCountry'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if ((quotes.pickupCity == undefined || quotes.pickupCity == "") && !quotes.airline) {
                errors['pickupCity'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if ((quotes.pickupPostalCode == undefined || quotes.pickupPostalCode == "") && !quotes.airline) {
                errors['pickupPostalCode'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (validator.isEmpty(quotes.pickupEmail + "") || !validator.isEmail(quotes.pickupEmail + "")) {
                errors['pickupEmail'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }
            if (validator.isEmpty(quotes.pickupNameOfContact + "")) {
                errors['pickupNameOfContact'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!quotes.pickupCompanyName || validator.isEmpty(quotes.pickupCompanyName + "")) {
                errors['pickupCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(quotes.pickupOpeningHours + "")) {
                errors['pickupOpeningHours'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(quotes.pickupPhoneNumber + "")) {
                errors['pickupPhoneNumber'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }

        if (showDelivery) {
            if ((quotes.deliverCity == undefined || quotes.deliverCity == "") && !quotes.gsa) {
                errors['deliverCity'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if ((quotes.deliverCountry == undefined || quotes.deliverCountry == "") && !quotes.gsa) {
                errors['deliverCountry'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if ((quotes.deliverPostalCode == undefined || quotes.deliverPostalCode == "") && !quotes.gsa) {
                errors['deliverPostalCode'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (validator.isEmpty(quotes.deliverEmail + "") || !validator.isEmail(quotes.deliverEmail + "")) {
                errors['deliverEmail'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }
            if (validator.isEmpty(quotes.deliverNameOfContact + "")) {
                errors['deliverNameOfContact'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(quotes.deliverCompanyName + "")) {
                errors['deliverCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(quotes.deliverOpeningHours + "")) {
                errors['deliverOpeningHours'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(quotes.deliverPhoneNumber + "")) {
                errors['deliverPhoneNumber'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }

        if (showGroundHandlingOrigin && (quotes.consignorGroundHandling == "" || quotes.consignorGroundHandling == undefined)) {
            errors['consignorGroundHandling'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (showGroundHandlingDestination && (quotes.consigneeGroundHandling == "" || quotes.consigneeGroundHandling == undefined)) {
            errors['consigneeGroundHandling'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        // console.log(errors);
        if (isError) {
            this.setState({ errors });
            quotes.step_3_ok = false;
            await this.props.quotesActions.quotesUpdateData(quotes);
            return false;
        }
        quotes.step_3_ok = true;
        await this.props.quotesActions.quotesUpdateData(quotes);
        return true;
    }

    handBack = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var { quotes, history } = this.props;
        history.push('/quotes/flights/' + quotes.CProfAU);
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        var checkError = await this.isValidation();
        if (!checkError) {
            window.scrollToFirstError();
            return false;
        }
        window.jumpToTop();
        var { quotes, history } = this.props;
        if (quotes.secretKey) {
            history.push('/quotes/review/' + quotes.secretKey + '/' + quotes.CProfAU);
        } else {
            history.push('/quotes/review-order/' + quotes.CProfAU);
        }
    }

    handlerShowLoginForm = (e) => {
        stateLoginChanged = true;
        window.parent.postMessage('show-login-form', '*');
    }

    handleClickShowNewCustomerForm = async () => {

    }

    filterOption = (option, inputValue) => {
        // Do no filtering, just return all options
        inputValue = inputValue.toUpperCase();
        let keywords = inputValue.split(" ");
        let { label, value } = option;

        // label = label.toUpperCase();
        // if (keywords.some(v => label.includes(v))) {
        //     return true;
        // }
        // return false;
        return true;
    };

    showNoOptionCustomer = () => {
        if (this.state.noOptionsCustomer === 'Not found!') {
            return (
                // <div><span>Not found!</span><span className="optionAddCustomer" onClick={this.handleClickShowNewCustomerForm}>Add new</span></div>
                <div><span>Not found!</span></div>
            );
        } else {
            return (
                <span>{this.state.noOptionsCustomer}</span>
            );
        }
    }

    handleChangeShipping = async (selectedOption) => {
        var { errors } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.shippingCompanyName = '';
        quotes.shippingCompanyName = '';
        quotes.shipping_company = selectedOption;
        if (selectedOption) {
            quotes.shippingCompanyName = selectedOption.label;
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeReceiving = async (selectedOption) => {
        var { errors } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.receivingCompanyName = '';
        quotes.receivingCompanyName = '';
        quotes.receiving_company = selectedOption;
        if (selectedOption) {
            quotes.receivingCompanyName = selectedOption.label;
        }
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeConsignor = async (selectedOption) => {
        var { errors, selectedCountryConsignor, showClearConsignor, selectedCountryPickup } = this.state;
        var { quotes } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        // console.log('selectedOption', selectedOption);
        errors.consignorCompanyName = '';
        errors["consignorAddress"] = "";
        errors["consignorCity"] = "";
        errors["consignorCountry"] = "";
        errors["consignorPostalCode"] = "";
        quotes.consignorCompanyName = '';
        quotes.consignor_company = selectedOption;
        if (selectedOption) {
            quotes.consignorCompanyName = selectedOption.label;
            quotes.clearConsignor = false;
            if (selectedOption.dtType && selectedOption.dtType === 'api') {
                quotes.consignorAddress = selectedOption.address;
                quotes.consignorPostalCode = selectedOption.zipcode;
                quotes.consignorCity = selectedOption.cityname;
                quotes.consignorCountry = selectedOption.countryname;
            } else {
                quotes.consignorCity = selectedOption.CITY ? selectedOption.CITY : selectedOption.city;
                quotes.consignorCountry = selectedOption.COUNTRY ? selectedOption.COUNTRY : selectedOption.country;
                quotes.consignorAddress = '';
                quotes.consignorPostalCode = '';
            }
            quotes.populateConsignor = true;
            showClearConsignor = true;
            var findCountry = this.state.countries.find(c => c.label == quotes.consignorCountry);
            if (findCountry) {
                selectedCountryConsignor = findCountry;
            }
        }
        if (quotes.pickupCheck) {
            errors["pickupCompanyName"] = "";
            errors["pickupAddress"] = "";
            errors["pickupCity"] = "";
            errors["pickupCountry"] = "";
            errors["pickupPostalCode"] = "";

            quotes.pickupCompanyName = quotes.consignorCompanyName;
            quotes.pickupAddress = quotes.consignorAddress;
            quotes.pickupCity = quotes.consignorCity;
            quotes.pickupCountry = quotes.consignorCountry;
            quotes.pickupPostalCode = quotes.consignorPostalCode;
            selectedCountryPickup = selectedCountryConsignor;
            quotes.airline = quotes.consignor_company;
            quotes.airline_options = quotes.consignor_company_options;
            // console.log('vao da 5');
        }

        this.setState({
            inputValueConsignor: '', errors, selectedCountryConsignor: selectedCountryConsignor, showClearConsignor: showClearConsignor, selectedCountryPickup: selectedCountryPickup
        });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeConsignee = async (selectedOption) => {
        var { errors, selectedCountryConsignee, showClearConsignee, selectedCountryDelivery } = this.state;
        var { quotes } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.consigneeCompanyName = '';
        errors["consigneeAddress"] = "";
        errors["consigneeCity"] = "";
        errors["consigneeCountry"] = "";
        errors["consigneePostalCode"] = "";
        quotes.consigneeCompanyName = '';
        quotes.consignee_company = selectedOption;
        if (selectedOption) {
            quotes.consigneeCompanyName = selectedOption.label;

            quotes.clearConsignee = false;
            if (selectedOption.dtType && selectedOption.dtType === 'api') {
                quotes.consigneeAddress = selectedOption.address;
                quotes.consigneePostalCode = selectedOption.zipcode;
                quotes.consigneeCity = selectedOption.cityname;
                quotes.consigneeCountry = selectedOption.countryname;
            } else {
                quotes.consigneeCity = selectedOption.CITY ? selectedOption.CITY : selectedOption.city;
                quotes.consigneeCountry = selectedOption.COUNTRY ? selectedOption.COUNTRY : selectedOption.country;
                quotes.consigneeAddress = '';
                quotes.consigneePostalCode = '';
            }
            quotes.populateConsignee = true;
            showClearConsignee = true;
            var findCountry = this.state.countries.find(c => c.label == quotes.consigneeCountry);
            if (findCountry) {
                selectedCountryConsignee = findCountry;
            }
        }
        if (quotes.deliverCheck) {
            errors["deliverCompanyName"] = "";
            errors["deliverAddress"] = "";
            errors["deliverCity"] = "";
            errors["deliverCountry"] = "";
            errors["deliverPostalCode"] = "";

            quotes.deliverCompanyName = quotes.consigneeCompanyName;
            quotes.deliverAddress = quotes.consigneeAddress;
            quotes.deliverCity = quotes.consigneeCity;
            quotes.deliverCountry = quotes.consigneeCountry;
            quotes.deliverPostalCode = quotes.consigneePostalCode;
            selectedCountryDelivery = selectedCountryConsignee;
            quotes.gsa = quotes.consignee_company;
            quotes.gsa_options = quotes.consignee_company_options;
        }

        this.setState({
            inputValueConsignee: '', errors, selectedCountryConsignee, showClearConsignee, selectedCountryDelivery
        });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeAirline = async (selectedOption) => {
        var { errors, selectedCountryPickup } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.pickupCompanyName = '';
        errors.pickupAddress = '';
        errors.pickupPostalCode = '';
        errors.pickupCity = '';
        errors.pickupCountry = '';

        quotes.pickupCompanyName = '';
        quotes.airline = selectedOption;
        if (selectedOption) {
            quotes.pickupCompanyName = selectedOption.label;
            if (selectedOption.dtType && selectedOption.dtType === 'api') {
                quotes.pickupAddress = selectedOption.address;
                quotes.pickupPostalCode = selectedOption.zipcode;
                quotes.pickupCity = selectedOption.cityname;
                quotes.pickupCountry = selectedOption.countryname;
            } else {
                quotes.pickupCity = selectedOption.city;
                quotes.pickupCountry = selectedOption.country;
                quotes.pickupAddress = '';
                quotes.pickupPostalCode = '';

            }
            var findCountry = this.state.countries.find(c => c.label == quotes.pickupCountry);
            if (findCountry) {
                selectedCountryPickup = findCountry;
            }
        } else {
            quotes.pickupCompanyName = '';
        }
        this.setState({ selectedCountryPickup, inputValueAirline: '' });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handleChangeGSA = async (selectedOption) => {
        var { errors, selectedCountryDelivery } = this.state;
        var { quotes, global } = this.props;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        errors.deliverCompanyName = '';
        errors.deliverAddress = '';
        errors.deliverPostalCode = '';
        errors.deliverCity = '';
        errors.deliverCountry = '';
        quotes.gsa = selectedOption;
        if (selectedOption) {
            quotes.deliverCompanyName = selectedOption.label;
            if (selectedOption.dtType && selectedOption.dtType === 'api') {
                quotes.deliverAddress = selectedOption.address;
                quotes.deliverPostalCode = selectedOption.zipcode;
                quotes.deliverCity = selectedOption.cityname;
                quotes.deliverCountry = selectedOption.countryname;
            } else {
                quotes.deliverCity = selectedOption.city;
                quotes.deliverCountry = selectedOption.country;
                quotes.deliverAddress = '';
                quotes.deliverPostalCode = '';

            }
            var findCountry = this.state.countries.find(c => c.label == quotes.deliverCountry);
            if (findCountry) {
                selectedCountryDelivery = findCountry;
            }
        }
        this.setState({ selectedCountryDelivery, inputValueGSA: '' });
        await this.props.quotesActions.quotesUpdateData(quotes);
    }

    handlerSelectShippingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueShippingAgent: e });
        if (this.timeoutSearchShippingAgent) {
            clearTimeout(this.timeoutSearchShippingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchShippingAgent = setTimeout(function () {
            t.handleScanCust('ShippingAgent');
        }, 1000);
    }

    handlerSelectGSATyping = (e, reason) => {
        if (reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close") {
            if (reason.action === "input-blur") {
                let { quotes } = this.props;
                quotes.deliverCompanyName = this.state.inputValueGSA;
                this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
            }
            return;
        }

        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueGSA: e });
        if (this.inputValueGSA) {
            clearTimeout(this.inputValueGSA);
        }
        this.setState({ noOptionsCustomer });
        this.inputValueGSA = setTimeout(function () {
            t.handleScanCust('GSA');
        }, 1000);
    }

    handlerSelectReceivingTyping = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueReceivingAgent: e });
        if (this.timeoutSearchReceivingAgent) {
            clearTimeout(this.timeoutSearchReceivingAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchReceivingAgent = setTimeout(function () {
            t.handleScanCust('ReceivingAgent');
        }, 1000);
    }

    handlerSelectConsignorTyping = (e, reason) => {
        if (reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close") {
            if (reason.action === "input-blur") {
                let { quotes } = this.props;
                quotes.consignorCompanyName = this.state.inputValueConsignor;
                this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
            }
            return;
        }
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueConsignor: e });
        if (this.timeoutSearchConsignorAgent) {
            clearTimeout(this.timeoutSearchConsignorAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchConsignorAgent = setTimeout(function () {
            t.handleScanCust('Consignor');
        }, 1000);
    }

    handlerSelectConsigneeTyping = (e, reason) => {
        if (reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close") {
            if (reason.action === "input-blur") {
                let { quotes } = this.props;
                quotes.consigneeCompanyName = this.state.inputValueConsignee;
                this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
            }
            return;
        }
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueConsignee: e });
        if (this.timeoutSearchConsigneeAgent) {
            clearTimeout(this.timeoutSearchConsigneeAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchConsigneeAgent = setTimeout(function () {
            t.handleScanCust('Consignee');
        }, 1000);
    }

    handlerSelectAirlineTyping = (e, reason) => {
        if (reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close") {
            if (reason.action === "input-blur") {
                let { quotes } = this.props;
                quotes.pickupCompanyName = this.state.inputValueAirline;
                this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
            }
            return;
        }

        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueAirline: e });
        if (this.timeoutSearchAirlineAgent) {
            clearTimeout(this.timeoutSearchAirlineAgent);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchAirlineAgent = setTimeout(function () {
            t.handleScanCust('Airline');
        }, 1000);
    }


    handleScanCust = async (selectName) => {
        var { noOptionsCustomer, inputValueShippingAgent, inputValueReceivingAgent, inputValueAirline, inputValueGSA, inputValueFeederGSA, inputValueConsignee, inputValueConsignor, receivingSuggests, consigneeSuggests, consignorSuggests } = this.state;
        var keyword = '';

        switch (selectName) {
            case 'ShippingAgent':
                keyword = clone(inputValueShippingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingShipingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueShippingAgent);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        quotes.shipping_company_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        quotes.shipping_company_options = scanCustData.data;
                    }
                    this.setState({ isLoadingShipingAgent: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'ReceivingAgent':
                keyword = clone(inputValueReceivingAgent);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingReceivingAgent: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueReceivingAgent);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        quotes.receiving_company_options = quotes.receivingParticipants;
                        if (!quotes.receiving_company_options || !quotes.receiving_company_options.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        // scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        quotes.receiving_company_options = quotes.receivingParticipants.concat(scanCustData.data);
                    }
                    this.setState({ isLoadingReceivingAgent: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'Consignee':
                keyword = clone(inputValueConsignee);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingConsignee: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueConsignee);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        quotes.consignee_company_options = quotes.consigneeParticipants;
                        if (!quotes.consignee_company_options || !quotes.consignee_company_options.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        // scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        // console.log(scanCustData.data);
                        quotes.consignee_company_options = quotes.consigneeParticipants.concat(scanCustData.data);
                    }
                    this.setState({ isLoadingConsignee: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'Consignor':
                keyword = clone(inputValueConsignor);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingConsignor: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueConsignor);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        quotes.consignor_company_options = quotes.consignorParticipants;
                        if (!quotes.consignor_company_options || !quotes.consignor_company_options.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        // scanCustData.data = scanCustData.data.filter(el => !el.custname.includes('T'));
                        // console.log(scanCustData.data);
                        quotes.consignor_company_options = quotes.consignorParticipants.concat(scanCustData.data);
                    }
                    this.setState({ isLoadingConsignor: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'Airline':
                keyword = clone(inputValueAirline);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingAirline: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueAirline);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        quotes.airline_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        quotes.airline_options = scanCustData.data;
                    }
                    this.setState({ isLoadingAirline: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'GSA':
                keyword = clone(inputValueGSA);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find customers";
                    this.setState({ isLoadingGSA: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(inputValueGSA);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        noOptionsCustomer = "Not found!";
                        quotes.gsa_options = [];
                    } else {
                        scanCustData.data = scanCustData.data.map(el => {
                            el.dtType = 'dynamo';
                            return el;
                        });
                        quotes.gsa_options = scanCustData.data;
                    }
                    this.setState({ isLoadingGSA: false, noOptionsCustomer });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            // case 'FeederGSA':
            //     keyword = clone(inputValueFeederGSA);
            //     keyword = keyword.trim();
            //     if (keyword.length > 2) {
            //         var noOptionsFeederGSA = "Type to find customers";
            //         this.setState({ isLoadingFeederGSA: true });
            //         var scanCustData = await this.props.mettingActions.scanCustQuote(inputValueFeederGSA, false, true);
            //         var { quotes } = this.props;
            //         if (!scanCustData.success || !scanCustData.data.length) {
            //             noOptionsFeederGSA = "Not found!";
            //             quotes.feeder_gsa_options = [];
            //         } else {
            //             quotes.feeder_gsa_options = scanCustData.data;
            //         }
            //         this.setState({ isLoadingFeederGSA: false, noOptionsFeederGSA });
            //         quotes.haveChanged = true;
            //         await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
            //     }
            //     break;
        }

    }

    render() {
        var { steps,
            errors,
            showFreightForwarder,
            showClearConsignor,
            showPickup,
            showDelivery,
            showClearConsignee,
            showGroundHandlingDestination,
            showGroundHandlingOrigin,
            consigneeGroundHandlingDisabled,
            consignorGroundHandlingDisabled,
            consigneeOptions,
            consignorOptions,
            suggestionsReceiving,
            suggestionsCNEE,
            suggestionsCNOR,
            modalEditIsOpen,
            modalOneMomentIsOpen,
            shipmentData,
            isError,
            errorMessage,
            errorText,
            suggestionsCNEE,
            suggestionsCNOR,
            editFreightForwarder,
            selectedCountryPickup } = this.state;
        var { quotes } = this.props;

        // Autosuggest will pass through all these props to the input.
        var inputPropsCNEE = {
            placeholder: '',
            value: quotes.consigneeCompanyName ? quotes.consigneeCompanyName : "",
            onChange: this.onChangeSugestCNEE,
            disabled: quotes.populateConsignee,
            readOnly: quotes.populateConsignee
        };
        var inputPropsCNOR = {
            placeholder: '',
            value: quotes.consignorCompanyName ? quotes.consignorCompanyName : "",
            onChange: this.onChangeSugestCNOR,
            disabled: quotes.populateConsignor,
            readOnly: quotes.populateConsignor
        };
        var inputPropsREC = {
            placeholder: '',
            value: quotes.receivingCompanyName ? quotes.receivingCompanyName : "",
            onChange: this.onChangeSugestReceiving,
            disabled: !editFreightForwarder,
            readOnly: !editFreightForwarder
        };

        // showDelivery = true;
        // showPickup = true;
        // console.log(quotes);
        // console.log(selectedCountryPickup);
        // console.log('airline_option', quotes.airline_options);
        // console.log('airline', quotes.airline);
        // showDelivery = true;
        // console.log(quotes);
        return (
            <div className="ebooking review-order  quote-preview">
                <StepProgress stepData={steps} progress={70} onClick={this.handClickStep} />

                <div className="container">
                    <h1 className="title mt-5">Step two: Booking</h1>
                </div>

                <form>
                    <div className="container block-form">
                        <h1 className="formTitle mb-2">The Parties Involved</h1>
                        <div className="formDescription" style={{ "maxWidth": "600px" }}>
                            Please fill in the details of the parties involved with this shipment.
                        </div>

                        <div className={`form-group ${(errorText != "" && errorText != "showContactLink") ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                {errorText}
                            </div>
                        </div>

                        <div className={`form-group mt-3 ${this.state.showProceeding ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                        </div>

                        <div className={`form-group mt-3 ${errorText == "showContactLink" ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                            </div>
                        </div>
                    </div>

                    <div className="container no_padding block-form mt-4">

                        <div className="form-content no_padding">
                            <div className={`row ${showFreightForwarder ? "" : "d-none"}`}>
                                <div className="col-12">
                                    <div className="container-child div_center">
                                        <div className="form-group form-rows">
                                            <label className={`checkbox-container inline-elements ${(errors.shipper) ? 'input-error' : ''}`}>I am a shipping agent
										        <input type="checkbox" tabIndex="9" name="checkShipping" value="1" checked={(quotes.selectedShippingReceiving == "Shipping") ? true : false} onChange={this.handleChange} />
                                                <span className="checkmark-radio"></span>
                                            </label>
                                            <div className={`clb mt-2 d-block d-md-none`}></div>
                                            <label className="checkbox-container inline-elements ml-3 d-none d-md-block"> &nbsp;</label>
                                            <label className={`checkbox-container inline-elements ${(errors.shipper) ? 'input-error' : ''}`}>I am a freight forwarder
                                                <input type="checkbox" tabIndex="9" name="checkReceiving" value="1" checked={(quotes.selectedShippingReceiving == "Receiving") ? true : false} onChange={this.handleChange} />
                                                <span className="checkmark-radio"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-between mt-5">
                                <div className="col-lg-6">
                                    <div className="container-child div_center">
                                        {/* Khong co bg xanh */}
                                        {/* Bắt đầu shipping agent */}
                                        <div className="h2-detail">Shipping Agent</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <label htmlFor="shippingCompanyName">Company Name<span className={`red ${showFreightForwarder ? "d-none" : "d-none d-none"}`}>*</span></label>

                                                        <Select
                                                            inputId="shippingCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input`}
                                                            value={quotes.shipping_company}
                                                            isSearchable={true}
                                                            isDisabled={((showFreightForwarder && quotes.selectedShippingReceiving !== "Shipping") || quotes.populateImA) ? true : false}
                                                            options={quotes.shipping_company_options}
                                                            isLoading={this.state.isLoadingShipingAgent}
                                                            onChange={this.handleChangeShipping}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueShippingAgent}
                                                            onInputChange={this.handlerSelectShippingTyping}
                                                        // filterOption={this.filterOption}
                                                        />
                                                        {/* <input type="text" className={`form-control ${(errors.shippingCompanyName) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && quotes.selectedShippingReceiving !== "Shipping") || quotes.populateImA) ? true : false} id="shippingCompanyName" name="shippingCompanyName" value={quotes.shippingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <InputError error={errors.shippingCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <label htmlFor="shippingIATA1">IATA Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8 slash-after color-blue">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA1) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && quotes.selectedShippingReceiving !== "Shipping") || quotes.populateImA) ? true : false} size="7" maxLength="7" id="shippingIATA1" name="shippingIATA1" onBlur={this.handleOnBlur} value={quotes.shippingIATA1} onChange={this.handleChange} />
                                                                <EbookingInputError error={errors.shippingIATA1} />
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA2) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && quotes.selectedShippingReceiving !== "Shipping") || quotes.populateImA) ? true : false} size="4" maxLength="4" id="shippingIATA2" name="shippingIATA2" onBlur={this.handleOnBlur} value={quotes.shippingIATA2} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Kết thúc shipping agent */}
                                    </div>
                                    <div className="container-child div_center">
                                        <div className="h2-detail mt-5">Consignor/Shipper Details <a className={` ${quotes.consignor_company ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "consignor")}>Clear</a></div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className={`form-group form-rows col-12 col-md-10 ${(errors.consignorCompanyName) ? 'input-error' : ''}`}>
                                                        <label htmlFor="consignorCompanyName">Company Name<span className={`red ${(quotes.populateConsignor || !quotes.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.consignorCompanyName) ? 'input-error' : ''}`} readOnly={quotes.populateConsignor ? true : false} id="consignorCompanyName" name="consignorCompanyName" value={quotes.consignorCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}

                                                        <Select
                                                            inputId="consignorCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input ${(errors.consignorCompanyName) ? 'input-error' : ''}`}
                                                            value={quotes.consignor_company}
                                                            isSearchable={true}
                                                            isDisabled={quotes.consignor_company ? true : false}
                                                            options={quotes.consignor_company_options}
                                                            isLoading={this.state.isLoadingConsignor}
                                                            onChange={this.handleChangeConsignor}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueConsignor}
                                                            onInputChange={this.handlerSelectConsignorTyping}
                                                        // filterOption={this.filterOption}
                                                        />
                                                        <EbookingInputError error={errors.consignorCompanyName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-bg-small">
                                        <div className="container-child div_center">
                                            <div className="block-form-content mt-3 div_center">
                                                <div className="form-group bg-blue p-3">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorCountry">Country<span className={`red ${(quotes.consignor_company) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <Select
                                                                inputId="consignorCountry"
                                                                className={`select-box huge-input ${(errors.consignorCountry) ? 'input-error' : ''}`}
                                                                value={this.state.selectedCountryConsignor}
                                                                isSearchable={true}
                                                                isDisabled={quotes.consignor_company ? true : false}
                                                                onChange={this.handleChangeConsignorCountry}
                                                                options={this.state.countries}
                                                                isLoading={this.state.isLoadingCountry}
                                                                onBlur={this.handleOnBlurSelect}
                                                            />
                                                            <EbookingInputError error={errors.consignorCountry} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorCity">City<span className={`red ${quotes.consignor_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consignorCity) ? 'input-error' : ''}`} readOnly={quotes.consignor_company ? true : false} id="consignorCity" name="consignorCity" value={quotes.consignorCity} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                            <EbookingInputError error={errors.consignorCity} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorPostalCode">Postal Code<span className={`red ${quotes.consignor_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consignorPostalCode) ? 'input-error' : ''}`} readOnly={quotes.consignor_company ? true : false} id="consignorPostalCode" name="consignorPostalCode" value={quotes.consignorPostalCode} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                            <EbookingInputError error={errors.consignorPostalCode} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="consignorAddress">Address<span className={`red ${quotes.consignor_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input className={`form-control ${(errors.consignorAddress) ? 'input-error' : ''}`} readOnly={quotes.consignor_company ? true : false} maxLength={80} id="consignorAddress" name="consignorAddress" value={quotes.consignorAddress} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            <EbookingInputError error={errors.consignorAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-child div_center">
                                        <h2 className={`h2-detail mt-5 mb-1`}>{quotes.deliver_from == "Airport" ? "Dropoff Address" : "Pickup Details"}</h2>
                                        <div className="formDescription">{quotes.deliver_from == "Airport" ? "Dropoff from airport, country." : "Pickup from city, country, zipcode."}</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group mb-2 ${showPickup ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12">
                                                        <label className="checkbox-container pdl-20">Pickup address is the same as the consignor address
                                                            <input type="checkbox" name="pickupCheck" value="pickupCheck" checked={quotes.pickupCheck} disabled={!showPickup} onChange={this.handleChange} />
                                                            <span className={`checkmark-checkbox ${(errors.pickupCheck) ? 'input-error' : ''}`}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`mb-2 ${(showPickup || (!showDelivery && !showPickup)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "32px" }}></div>
                                            <div className={`form-group ${(showGroundHandlingOrigin) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="consignorGroundHandling">Ground Handling Warehouse<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <select className={`form-control ${(errors.consignorGroundHandling) ? 'input-error' : ''}`} name="consignorGroundHandling" disabled={(!showGroundHandlingOrigin || consignorGroundHandlingDisabled) ? true : false} value={quotes.consignorGroundHandling} onChange={this.handleChange} >
                                                            <option value="" />
                                                            {
                                                                consignorOptions.map((op, i) => {
                                                                    return <option key={"consignee" + i} value={op.value}>{op.value}</option>;
                                                                })
                                                            }
                                                        </select>
                                                        <EbookingInputError error={errors.consignorGroundHandling} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${(showGroundHandlingOrigin || (!showGroundHandlingDestination && !showGroundHandlingOrigin) || (showPickup && !showDelivery && !showGroundHandlingOrigin && showGroundHandlingDestination)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "78px" }}></div>
                                            <div className={`form-group`}>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupCompanyName">Company Name<span className={`red ${(quotes.pickupCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.pickupCompanyName) ? 'input-error' : ''}`} maxLength={48} id="pickupCompanyName" name="pickupCompanyName" readOnly={quotes.pickupCheck} value={quotes.pickupCompanyName ? quotes.pickupCompanyName : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}

                                                        <Select
                                                            inputId="pickupCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input ${(errors.pickupCompanyName) ? 'input-error' : ''}`}
                                                            value={quotes.airline ? quotes.airline : null}
                                                            isSearchable={true}
                                                            isDisabled={quotes.pickupCheck}
                                                            options={quotes.airline_options ? quotes.airline_options : []}
                                                            isLoading={this.state.isLoadingAirline}
                                                            onChange={this.handleChangeAirline}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueAirline}
                                                            onInputChange={this.handlerSelectAirlineTyping}
                                                        // filterOption={this.filterOption}
                                                        />
                                                        <EbookingInputError error={errors.pickupCompanyName} />
                                                    </div>
                                                </div>
                                                {/* <div className={`row ${(showPickup || (!showPickup && !showDelivery)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "79px" }}></div> */}
                                                <div className="row">
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupCountry">Country<span className={`red ${(quotes.pickupCheck || quotes.populatePickup) ? "d-none" : "rm-dnone"}`}>*</span></label>

                                                        <Select
                                                            inputId="pickupCountry"
                                                            className={`select-box huge-input ${(errors.pickupCountry) ? 'input-error' : ''}`}
                                                            value={this.state.selectedCountryPickup}
                                                            isSearchable={true}
                                                            isDisabled={quotes.pickupCheck || quotes.populatePickup || quotes.airline}
                                                            onChange={this.handleChangePickupCountry}
                                                            options={this.state.countries}
                                                            isLoading={this.state.isLoadingCountry}
                                                            onBlur={this.handleOnBlurSelect}
                                                        />
                                                        <EbookingInputError error={errors.pickupCountry} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupCity">City<span className={`red ${(quotes.pickupCheck || quotes.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupCity) ? 'input-error' : ''}`} id="pickupCity" name="pickupCity" value={quotes.pickupCity} readOnly={(quotes.pickupCheck || quotes.populatePickup || quotes.airline) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                        <EbookingInputError error={errors.pickupCity} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupPostalCode">Postal Code<span className={`red ${(quotes.pickupCheck || quotes.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupPostalCode) ? 'input-error' : ''}`} id="pickupPostalCode" name="pickupPostalCode" value={quotes.pickupPostalCode} readOnly={(quotes.pickupCheck || quotes.populatePickup || quotes.airline) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                        <EbookingInputError error={errors.pickupPostalCode} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupAddress">Address<span className={`red ${(quotes.pickupCheck || quotes.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input className={`form-control ${(errors.pickupAddress) ? 'input-error' : ''}`} maxLength={80} id="pickupAddress" name="pickupAddress" value={quotes.pickupAddress} readOnly={(quotes.pickupCheck || quotes.populatePickup || quotes.airline) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupAddress} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupNameOfContact">Name of Contact<span className={`red ${(quotes.pickupCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupNameOfContact) ? 'input-error' : ''}`} maxLength={48} id="pickupNameOfContact" name="pickupNameOfContact" value={quotes.pickupNameOfContact} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupNameOfContact} />
                                                    </div>
                                                </div>
                                                {/* <hr className={`split-fields ${(showPickup) ? "" : "d-none"}`} /> */}
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupEmail">Email<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="Email" className={`form-control ${(errors.pickupEmail) ? 'input-error' : ''}`} id="pickupEmail" name="pickupEmail" value={quotes.pickupEmail} maxLength={48} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupEmail} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupPhoneNumber">Phone Number<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupPhoneNumber) ? 'input-error' : ''}`} id="pickupPhoneNumber" name="pickupPhoneNumber" maxLength={20} value={quotes.pickupPhoneNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupPhoneNumber} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12">
                                                        <label htmlFor="pickupOpeningHours">Opening Hours<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <textarea rows={4} className={`form-control ${(errors.pickupOpeningHours) ? 'input-error' : ''}`} id="pickupOpeningHours" name="pickupOpeningHours" value={quotes.pickupOpeningHours} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupOpeningHours} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 mb-0">
                                                        <label htmlFor="pickupComments">Comments</label>
                                                        <textarea rows={4} className={`form-control ${(errors.pickupComments) ? 'input-error' : ''}`} id="pickupComments" name="pickupComments" value={quotes.pickupComments} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupComments} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 last-column">
                                    <div className="container-child div_center">
                                        {/* Khong co bg xanh */}
                                        {/* Bắt đầu shipping agent */}
                                        <div className={`h2-detail ${(showFreightForwarder) ? "" : "d-none d-md-block disable-block"}`}>Receiving Agent/Notify</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group ${(showFreightForwarder) ? "" : "d-none d-md-block disable-block"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <label htmlFor="receivingCompanyName">Company Name<span className={`red d-none rm-dnone`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''} ${editFreightForwarder ? "d-none" : ""}`} readOnly={true} id="receivingCompanyName" name="receivingCompanyName" value={quotes.receivingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <Select
                                                            inputId="receivingCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input ${(errors.receivingCompanyName) ? 'input-error' : ''}`}
                                                            value={quotes.receiving_company}
                                                            isSearchable={true}
                                                            isDisabled={!editFreightForwarder}
                                                            options={quotes.receiving_company_options}
                                                            isLoading={this.state.isLoadingReceivingAgent}
                                                            onChange={this.handleChangeReceiving}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueReceivingAgent}
                                                            onInputChange={this.handlerSelectReceivingTyping}
                                                        // filterOption={this.filterOption}
                                                        />
                                                        {/* <Autosuggest
                                                            className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''} ${editFreightForwarder ? "" : "d-none"}`}
                                                            suggestions={suggestionsReceiving}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedReceiving}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequestedReceiving}
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            inputProps={inputPropsREC}
                                                            disabled={!showFreightForwarder}
                                                        /> */}
                                                        <InputError error={errors.receivingCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <label htmlFor="receivingIATA1">IATA Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8 slash-after">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA1 || errors.receivingIATA) ? 'input-error' : ''}`} disabled={!showFreightForwarder} readOnly={!editFreightForwarder} size="7" maxLength="7" id="receivingIATA1" name="receivingIATA1" value={quotes.receivingIATA1} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                <EbookingInputError error={errors.receivingIATA1} />
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA2 || errors.receivingIATA) ? 'input-error' : ''}`} disabled={!showFreightForwarder} readOnly={!editFreightForwarder} size="4" maxLength="4" id="receivingIATA2" name="receivingIATA2" value={quotes.receivingIATA2} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Kết thúc shipping agent */}
                                    </div>

                                    <div className="container-child div_center">
                                        <div className={`h2-detail mt-5 ${showFreightForwarder ? "" : ""}`}>Consignee/Importer Details <a className={` ${quotes.consignee_company ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "consignee")}>Clear</a></div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className={`form-group form-rows col-12 col-md-10 ${(errors.consigneeCompanyName) ? 'input-error' : ''}`}>
                                                        <label htmlFor="consigneeCompanyName">Company Name<span className={`red ${(quotes.populateConsignee || !quotes.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.consigneeCompanyName) ? 'input-error' : ''}`} readOnly={quotes.populateConsignee ? true : false} id="consigneeCompanyName" name="consigneeCompanyName" value={quotes.consigneeCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <Select
                                                            inputId="consigneeCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input ${(errors.consigneeCompanyName) ? 'input-error' : ''}`}
                                                            value={quotes.consignee_company ? quotes.consignee_company : null}
                                                            isSearchable={true}
                                                            isDisabled={quotes.consignee_company ? true : false}
                                                            options={quotes.consignee_company_options ? quotes.consignee_company_options : []}
                                                            isLoading={this.state.isLoadingConsignee}
                                                            onChange={this.handleChangeConsignee}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueConsignee}
                                                            onInputChange={this.handlerSelectConsigneeTyping}
                                                        // filterOption={this.filterOption}
                                                        />
                                                        <EbookingInputError error={errors.consigneeCompanyName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="blue-bg-small">
                                        <div className="container-child div_center">
                                            <div className="block-form-content mt-3 div_center">
                                                <div className="form-group bg-blue p-3">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneeCountry">Country<span className={`red ${quotes.consignee_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <Select
                                                                inputId="consigneeCountry"
                                                                className={`select-box huge-input ${(errors.consigneeCountry) ? 'input-error' : ''}`}
                                                                value={this.state.selectedCountryConsignee}
                                                                isSearchable={true}
                                                                isDisabled={quotes.consignee_company ? true : false}
                                                                onChange={this.handleChangeConsigneeCountry}
                                                                options={this.state.countries}
                                                                isLoading={this.state.isLoadingCountry}
                                                                onBlur={this.handleOnBlurSelect}
                                                            />
                                                            <EbookingInputError error={errors.consigneeCountry} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneeCity">City<span className={`red ${(quotes.consignee_company) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consigneeCity) ? 'input-error' : ''}`} readOnly={(quotes.consignee_company) ? true : false} id="consigneeCity" name="consigneeCity" value={quotes.consigneeCity} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                            <EbookingInputError error={errors.consigneeCity} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneePostalCode">Postal Code<span className={`red ${quotes.consignee_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consigneePostalCode) ? 'input-error' : ''}`} readOnly={(quotes.consignee_company) ? true : false} id="consigneePostalCode" name="consigneePostalCode" value={quotes.consigneePostalCode} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                            <EbookingInputError error={errors.consigneePostalCode} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="consigneeAddress">Address<span className={`red ${quotes.consignee_company ? "d-none" : "d-none rm-dnone"}`}>*</span></label>

                                                            <input className={`form-control ${(errors.consigneeAddress) ? 'input-error' : ''}`} readOnly={(quotes.consignee_company) ? true : false} maxLength={80} id="consigneeAddress" name="consigneeAddress" value={quotes.consigneeAddress} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            <EbookingInputError error={errors.consigneeAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-child div_center">
                                        <h2 className={`h2-detail mt-5 mb-1`}>{quotes.deliver_to == "ZipCode" ? "Delivery Details " : "Delivery Address"}</h2>
                                        <div className="formDescription">{quotes.deliver_to == "Airport" ? "Delivery to airport, country." : "Delivery to city, country, zipcode."}</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group mb-2 ${(showDelivery) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12">
                                                        <label className="checkbox-container pdl-20">Delivery address is the same as the consignee address
                                                            <input type="checkbox" name="deliverCheck" value="deliverCheck" disabled={!showDelivery} checked={quotes.deliverCheck} onChange={this.handleChange} />
                                                            <span className={`checkmark-checkbox ${(errors.deliverCheck) ? 'input-error' : ''}`}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`mb-2 ${(showDelivery || (!showDelivery && !showPickup)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "32px" }}></div>
                                            <div className={`form-group ${(showGroundHandlingDestination) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12 col-md-10">
                                                        <label htmlFor="consigneeGroundHandling">Ground Handling Warehouse<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <select className={`form-control ${(errors.consigneeGroundHandling) ? 'input-error' : ''}`} name="consigneeGroundHandling" disabled={(!showGroundHandlingDestination || consigneeGroundHandlingDisabled) ? true : false} value={quotes.consigneeGroundHandling} onChange={this.handleChange} >
                                                            <option value="" />
                                                            {
                                                                consigneeOptions.map((op, i) => {
                                                                    return <option key={"consignee" + i} value={op.value}>{op.value}</option>;
                                                                })
                                                            }
                                                        </select>
                                                        <EbookingInputError error={errors.consigneeGroundHandling} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${(showGroundHandlingDestination || (!showGroundHandlingDestination && !showGroundHandlingOrigin) || (!showPickup && showDelivery && showGroundHandlingOrigin && !showGroundHandlingDestination)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "78px" }}></div>

                                            <div className={`form-group`}>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverCompanyName">Company Name<span className={`red ${(quotes.deliverCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.deliverCompanyName) ? 'input-error' : ''}`} maxLength={48} id="deliverCompanyName" name="deliverCompanyName" value={quotes.deliverCompanyName ? quotes.deliverCompanyName : ""} readOnly={quotes.deliverCheck} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}

                                                        <Select
                                                            inputId="deliverCompanyName"
                                                            noOptionsMessage={this.showNoOptionCustomer}
                                                            placeholder={``}
                                                            className={`select-box huge-input ${(errors.deliverCompanyName) ? 'input-error' : ''}`}
                                                            value={quotes.gsa ? quotes.gsa : null}
                                                            isSearchable={true}
                                                            isDisabled={quotes.deliverCheck}
                                                            options={quotes.gsa_options ? quotes.gsa_options : []}
                                                            isLoading={this.state.isLoadingGSA}
                                                            onChange={this.handleChangeGSA}
                                                            // onBlur={this.handleOnBlurSelect}
                                                            // onFocus={this.handleOnFocusSelect}
                                                            inputValue={this.state.inputValueGSA}
                                                            onInputChange={this.handlerSelectGSATyping}
                                                        // filterOption={this.filterOption}
                                                        />


                                                        <EbookingInputError error={errors.deliverCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverCountry">Country<span className={`red ${(quotes.deliverCheck || quotes.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <Select
                                                            inputId="deliverCountry"
                                                            className={`select-box huge-input ${(errors.deliverCountry) ? 'input-error' : ''}`}
                                                            value={this.state.selectedCountryDelivery}
                                                            isSearchable={true}
                                                            isDisabled={(quotes.deliverCheck || quotes.populateDelivery || quotes.gsa) ? true : false}
                                                            onChange={this.handleChangeDeliveryCountry}
                                                            options={this.state.countries}
                                                            isLoading={this.state.isLoadingCountry}
                                                            onBlur={this.handleOnBlurSelect}
                                                        />
                                                        <EbookingInputError error={errors.deliverCountry} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverCity">City<span className={`red ${(quotes.deliverCheck || quotes.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverCity) ? 'input-error' : ''}`} id="deliverCity" name="deliverCity" value={quotes.deliverCity} readOnly={(quotes.deliverCheck || quotes.populateDelivery || quotes.gsa) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                        <EbookingInputError error={errors.deliverCity} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverPostalCode">Postal Code<span className={`red ${(quotes.deliverCheck || quotes.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverPostalCode) ? 'input-error' : ''}`} id="deliverPostalCode" name="deliverPostalCode" value={quotes.deliverPostalCode} readOnly={(quotes.deliverCheck || quotes.populateDelivery || quotes.gsa) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                        <EbookingInputError error={errors.deliverPostalCode} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverAddress">Address<span className={`red ${(quotes.deliverCheck || quotes.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input className={`form-control ${(errors.deliverAddress) ? 'input-error' : ''}`} maxLength={80} id="deliverAddress" name="deliverAddress" value={quotes.deliverAddress} onBlur={this.handleOnBlur} readOnly={(quotes.deliverCheck || quotes.populateDelivery || quotes.gsa) ? true : false} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverAddress} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverNameOfContact">Name of Contact<span className={`red ${(quotes.deliverCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverNameOfContact) ? 'input-error' : ''}`} maxLength={48} id="deliverNameOfContact" name="deliverNameOfContact" value={quotes.deliverNameOfContact} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverNameOfContact} />
                                                    </div>
                                                </div>
                                                {/* <hr className={`split-fields ${(showDelivery) ? "" : "d-none"}`} /> */}
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverEmail">Email<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="Email" className={`form-control ${(errors.deliverEmail) ? 'input-error' : ''}`} id="deliverEmail" name="deliverEmail" value={quotes.deliverEmail} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverEmail} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverPhoneNumber">Phone Number<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverPhoneNumber) ? 'input-error' : ''}`} id="deliverPhoneNumber" name="deliverPhoneNumber" value={quotes.deliverPhoneNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverPhoneNumber} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12">
                                                        <label htmlFor="deliverOpeningHours">Opening Hours<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <textarea rows={4} className={`form-control ${(errors.deliverOpeningHours) ? 'input-error' : ''}`} id="deliverOpeningHours" name="deliverOpeningHours" value={quotes.deliverOpeningHours} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverOpeningHours} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 mb-0">
                                                        <label htmlFor="deliverComments">Comments</label>
                                                        <textarea rows={4} className={`form-control ${(errors.deliverComments) ? 'input-error' : ''}`} id="deliverComments" name="deliverComments" value={quotes.deliverComments} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverComments} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="form-content">
                            {/* <div className={`form-rows mt-4`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                    </div>
                                    <div className={`col-12 col-md-6 checkawb pl-0`}>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label className="checkbox-container">I already have an AWB
                                                    <input type="checkbox" name="already_awb" id="already_awb" value="already_awb" checked={quotes.already_awb ? quotes.already_awb : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.already_awb) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={`${(!quotes.already_awb) ? "d-none" : "form-rows mt-3"}`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                        <label htmlFor="awb_number_1" className={`text-right`}>AWB Number</label>
                                    </div>
                                    <div className={`col-6 pl-0`}>
                                        <div className="row">
                                            <div className="col-4 slash-after color-blue">
                                                <input type="text" className={`form-control error-full-radius ${(errors.awb_number_1) ? 'input-error' : ''}`} size="3" maxLength="3" id="awb_number_1" readOnly={quotes.already_awb ? true : true} name="awb_number_1" value={quotes.awb_number_1 ? quotes.awb_number_1 : ""} onBlur={this.isValidationAwb} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-8">
                                                <input type="text" className={`form-control ${(errors.awb_number_2 || errors.awb_number) ? 'input-error' : ''}`} size="8" maxLength="8" id="awb_number_2" name="awb_number_2" readOnly={quotes.already_awb ? !quotes.already_awb : true} value={quotes.awb_number_2 ? quotes.awb_number_2 : ""} onBlur={this.isValidationAwb} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.awb_number_2} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                            <div className={`form-rows mt-5`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                        <button type="button" onClick={this.handBack} className="btn btn-back right">Back to Choose Flight</button>
                                    </div>
                                    <div className={`col-6 pl-0`}>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <button type="button" onClick={this.handSubmit} className="btn btn-ebooking-orange">Finish & Review Order</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesParticipants);