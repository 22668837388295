import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

class ModalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.onShowModal = this.onShowModal.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        this.loginForm.resetFrom();
    };

    render() {
        return (
            <div className="modal-groups ">
                <div className="modal" id="modalLogin" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalLogin')}></button>
                            </div>
                            <LoginForm onRef={ref => (this.loginForm = ref)} onSubmit={this.props.onSubmit} onSubmitLogin={this.props.onSubmitLogin} loadingChangeData={this.props.loadingChangeData} history={this.props.history} showModal={this.props.showModal} hideModal={this.props.closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

const ModalLogin = connect(mapStateToProps, loadingAction)(ModalLoginForm);

export default ModalLogin;

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'email': '',
                'password': ''
            },
            isCaptchaCallback: false,
            btnLoginText: 'Log In'
        };

        this.loginHandleChange = this.loginHandleChange.bind(this);
        this.validateLogin = this.validateLogin.bind(this);
        this.validateLoginWithField = this.validateLoginWithField.bind(this);
        this.resetFrom = this.resetFrom.bind(this);
        this.handLoginSubmit = this.handLoginSubmit.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    //reset from data
    resetFrom = () => {
        window.RenderRecaptchLogin();
        this.setState({
            errorMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'email': '',
                'password': ''
            },
            isCaptchaCallback: false,
            btnLoginText: 'Log In'
        });
    }

    /* handler form's fields change value */
    loginHandleChange(event) {
        const { loginForm } = this.state;
        const { target, target: { name, type } } = event;
        loginForm[name] = target.value;
        if(name == 'email'){
            loginForm[name] = loginForm[name].toLowerCase();
        }
        this.setState({ loginForm });
    }

    handLoginSubmit = () => {
        this.setState({ errorMessage: '' });
        const { history } = this.props;
        if (this.validateLogin()) {    
            this.props.onSubmitLogin(this.state.loginForm.password);        
            this.props.onSubmit(this.state.loginForm.email, this.state.loginForm.password).then(api_response => {   
                if(api_response.success){
                    this.props.hideModal('modalLogin');
                }else{
                    this.setState({ errorMessage: api_response.message });
                }                
                if(api_response.mustRefresh){
                    window.location.reload();
                }
            }).catch((err) => {
                this.props.loadingChangeData(false);
                this.setState({ errorMessage: err.message });
            });
        }
    }


    validateLoginWithField(event) {
        const { loginForm, errorLogins } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        switch (target.name) {
            case 'email':
                if (!validator.required(loginForm.email)) {
                    errorLogins['email'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                if (!validator.email(loginForm.email)) {
                    errorLogins['email'] = MESSAGES.ERROR_EMAIL;
                    isError = true;
                }
                if (!isError) {
                    errorLogins['email'] = '';
                }
                break;
            case 'password':
                if (!validator.required(loginForm.password)) {
                    errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errorLogins['password'] = '';
                }
                break;
        }
        this.setState({ errorLogins });
    }

    validateLogin(event) {
        this.setState({ errorLogins: {} });
        const { loginForm } = this.state;
        var errorLogins = {};
        var isError = false;
        var validator = new Validator();

        if (!validator.required(loginForm.email)) {
            errorLogins['email'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!validator.email(loginForm.email)) {
            errorLogins['email'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }
        if (!validator.required(loginForm.password)) {
            errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!window.getVerifiedLogin()) {
            errorLogins['captcha'] = MESSAGES.ERROR_CAPTCHA;
            isError = true;
        }
        if (isError) {
            this.setState({ errorLogins });
            return false;
        }
        return true;
    }

    render() {
        const { isErrorLogin, errorLogins, loginForm } = this.state;

        return (
            <div>
                <div className="modal-body">
                    <h2>Login</h2>
                    <hr />
                    <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                        {this.state.errorMessage}
                    </div>
                    <form>
                        <div className="form-group small-margin">
                            <label htmlFor="email">Email</label>
                            <input type="email" className={`form-control ${(errorLogins.email) ? 'input-error' : ''}`} name="email" value={loginForm.email} onBlur={this.validateLoginWithField} onChange={this.loginHandleChange} />
                            <InputError error={errorLogins.email} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className={`form-control ${(errorLogins.password) ? 'input-error' : ''}`} name="password" value={loginForm.password} onBlur={this.validateLoginWithField} onChange={this.loginHandleChange} />
                            <InputError error={errorLogins.password} />
                            <a href="javascript:void(0)" onClick={this.props.showModal.bind(this, 'modalPassReset')} className="forgot-pass">Forgot password</a>
                        </div>
                        <div>
                            <div className="re-captcha">
                                <InputError error={errorLogins.captcha} />
                                <div id="RecaptchaLogin"></div>
                            </div>
                            <div className="clb"></div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group">
                        <div className="register">Not a member yet? <a href="javascript:void(0)" onClick={this.props.showModal.bind(this, 'modalRegister')}>Register</a></div>
                        <button type="button" onClick={this.handLoginSubmit} className="btn btn-continues btn-login">{this.state.btnLoginText}</button>
                        <div className="clb"></div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func,
    clearCache: PropTypes.func,
    username: PropTypes.string,
    error: PropTypes.string,
    email: PropTypes.string,
    showRegisterFrom: PropTypes.func,
    showForgotForm: PropTypes.func
};
