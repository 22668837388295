import axios from 'axios';
import uuid from 'uuid';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
export function supplierReset() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.SUPPLIER_RESET,
            payload: {}
        })
        return { success: true }
    }
}

export function supplierChangeData(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.SUPPLIER_CHANGE_DATA,
            payload: inputData
        })
        return { success: true }
    }
}

export function supplierUpdateData(supplier) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.SUPPLIER_UPDATE_DATA,
            payload: supplier
        })
        return { success: true }
    }
}

export function checkAWB(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.LOADING_CHANGE_DATA,
            payload: true
        })
          
        var dataPass = {
            "functionName": 'checkawb',
            "dataPass": inputData
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        dispatch({
            type: ActionTypes.LOADING_CHANGE_DATA,
            payload: false
        })  

        if(dataReturn.success && dataReturn.data.Success){
            dispatch({
                type: ActionTypes.SUPPLIER_CHANGE_DATA,
                payload: { fieldName: 'api_checked', fieldValue: true }
            })
        }        
        
        return dataReturn
    }
}

export function supplierUpdate(inputData) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.LOADING_CHANGE_DATA,
            payload: true
        })
          
        var dataPass = {
            "functionName": 'supplierUpdate',
            "dataPass": inputData
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        dispatch({
            type: ActionTypes.LOADING_CHANGE_DATA,
            payload: false
        })    
        return dataReturn; 
    }
}