import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import uuid from 'uuid';

/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

/**
 * Redux actions
 */
import { bindActionCreators } from 'redux';
import * as authActions from '../../ninja-redux/actions/authActions';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
];

class ModalRegisterFrom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isError: false,
            iataChanged: false,
            calCodeChanged: false,
            calClientNumberChanged: false,
            btnRegisterText: 'Register',
            errorMessage: '',
            successMessage: '',
            errors: {},
            company_edit: false,
            wrong_number: false,
            selectedCountry: null,
            showHeaderLinks: true,
            cusID: "",
            registerForm: {
                'have_yet': 'no',
                'cal_code': '',
                'Custname': '',
                'Cust': 0,
                'company_iata_1': '',
                'company_iata_2': '',
                'company_client_number': '',
                'company_name': '',
                'company_vat_code': '',
                'company_country': '',
                'company_city': '',
                'company_address': '',
                'company_type_gsa': null,
                'company_type_shipper': null,
                'company_type_other': null,
                'company_zipcode': '',
                'company_currency': "USD",
                'account_name': '',
                'account_email': '',
                'account_password': '',
                'account_password_confirm': '',
                'account_position': '',
                'account_phone': '',
                'condition': 'no',
            },
            currentStep: "step-1",
            captchaScale: 1,
            countries: [],
            cassCountries: [],
            cassCurrency: false,
            checkedIATA: true,
            vatCodeEdit: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.validateWithField = this.validateWithField.bind(this);
        this.handSubmit = this.handSubmit.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
        this.onShowLoginPage = this.onShowLoginPage.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.checkingExistCustomer = this.checkingExistCustomer.bind(this);
        this.chooseRegisterType = this.chooseRegisterType.bind(this);
        this.handleClickPrevious = this.handleClickPrevious.bind(this);
        this.handleChangeStep = this.handleChangeStep.bind(this);
    }

    handleChangeStep = async () => {
        var { registerForm, errors, currentStep, isError, checkedIATA, company_edit, vatCodeEdit } = this.state;
        var validator = new Validator();
        var nextStep = "";
        isError = false;
        errors = {}
        if (currentStep == 'step-2-a') {
            nextStep = "step-5";
            if (!company_edit && !validator.required(registerForm.company_name)) {
                errors['company_name'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!company_edit && !validator.required(registerForm.company_country)) {
                errors['company_country'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if ((!company_edit || vatCodeEdit) && !validator.required(registerForm.company_vat_code)) {
                errors['company_vat_code'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (registerForm.company_iata_1 && registerForm.company_iata_1.length < 7) {
                errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }

            if (registerForm.company_iata_2 && registerForm.company_iata_2.length < 4) {
                errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }

            if (registerForm.company_iata_1 == "" && registerForm.company_iata_2 != "") {
                errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }

            if (registerForm.company_iata_1 != "" && registerForm.company_iata_2 == "") {
                errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }

            if ((registerForm.company_iata_1 || registerForm.company_iata_2) && !checkedIATA && !errors['company_iata_1'] && !errors['company_iata_2']) {
                errors['company_iata'] = MESSAGES.ERROR_COMPANY_IATA;
                isError = true;
            }
            if (!company_edit) {
                nextStep = 'step-3';
            }
        } else if (currentStep == 'step-2-b') {
            nextStep = "step-4";
            if (!company_edit && !validator.required(registerForm.company_name)) {
                errors['company_name'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if ((!company_edit || vatCodeEdit) && !validator.required(registerForm.company_vat_code)) {
                errors['company_vat_code'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (registerForm.company_iata_1 && registerForm.company_iata_1.length < 7) {
                errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                errors['company_iata'] = "IATA code must be 11 characters";
                isError = true;
            }

            if (registerForm.company_iata_2 && registerForm.company_iata_2.length < 4) {
                errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                errors['company_iata'] = "IATA code must be 11 characters";
                isError = true;
            }

            if (registerForm.company_iata_1 == "" && registerForm.company_iata_2 != "") {
                errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }

            if (registerForm.company_iata_1 != "" && registerForm.company_iata_2 == "") {
                errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }

            // if (registerForm.company_iata_1 == "") {
            //     errors['company_iata_1'] = MESSAGES.ERROR_REQUIRED;
            //     errors['company_iata'] = "Required";
            //     isError = true;
            // }

            // if (registerForm.company_iata_2 == "") {
            //     errors['company_iata_2'] = MESSAGES.ERROR_REQUIRED;
            //     errors['company_iata'] = "Required";
            //     isError = true;
            // }

            if ((registerForm.company_iata_1 || registerForm.company_iata_2) && !checkedIATA && !errors['company_iata_1'] && !errors['company_iata_2']) {
                errors['company_iata'] = MESSAGES.ERROR_COMPANY_IATA;
                isError = true;
            }
            if (company_edit) {
                nextStep = 'step-5';
            }
        } else if (currentStep == 'step-3') {
            nextStep = "step-8";
            if (!validator.required(registerForm.account_email)) {
                errors['account_email'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.email(registerForm.account_email)) {
                errors['account_email'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }
            if (registerForm.account_email.length > 48) {
                errors['account_email'] = "Max length 48 characters";
                isError = true;
            }
        } else if (currentStep == 'step-4') {
            nextStep = "step-5";
            if (!company_edit && !validator.required(registerForm.company_country)) {
                errors['company_country'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!company_edit && !validator.required(registerForm.company_city)) {
                errors['company_city'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!company_edit && !validator.required(registerForm.company_address)) {
                errors['company_address'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!company_edit && !validator.required(registerForm.company_zipcode)) {
                errors['company_zipcode'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        } else if (currentStep == 'step-5') {
            nextStep = "step-6";
            if (!validator.required(registerForm.company_type_shipper)) {
                errors['company_type'] = "At the moment we only offer this service to Freight Forwarders and Shipping Agents";
                isError = true;
            }
            if (!validator.required(registerForm.account_name)) {
                errors['account_name'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.required(registerForm.account_email)) {
                errors['account_email'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }else if (!validator.email(registerForm.account_email)) {
                errors['account_email'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }else{
                this.props.loadingChangeData(true);
                var emailExist = await this.props.authActions.checkUserExist(registerForm.account_email);    
                this.props.loadingChangeData(false);
                if(!emailExist.success || (emailExist.data && emailExist.data.userexist)){
                    errors['account_email'] = MESSAGES.ERROR_EMAIL_EXISTS;
                    isError = true;
                }
            }

            if (registerForm.account_email.length > 48) {
                errors['account_email'] = "Max length 48 characters";
                isError = true;
            }
            if (!validator.required(registerForm.account_position)) {
                errors['account_position'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }

            if (!validator.required(registerForm.account_phone)) {
                errors['account_phone'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }else if(!validator.number(registerForm.account_phone) || !validator.minLength(registerForm.account_phone, 7)){
                errors['account_phone'] = "Minimum 7 digitals";
                isError = true;
            }
        } else if (currentStep == 'step-6') {
            nextStep = "step-7";

            if (!validator.required(registerForm.account_password) || !validator.minLength(registerForm.account_password, 6)) {
                errors['account_password'] = MESSAGES.ERROR_PASS_RULE;
                isError = true;
            }
            if (!validator.required(registerForm.account_password_confirm) || !validator.minLength(registerForm.account_password_confirm, 6)) {
                errors['account_password_confirm'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            } else if (registerForm.account_password_confirm != registerForm.account_password) {
                errors['account_password_confirm'] = MESSAGES.ERROR_CONFIRM_PASS;
                isError = true;
            }

            if (registerForm.condition != 'Y') {
                errors['condition'] = MESSAGES.ERROR_CONDITION;
                isError = true;
            }
        }

        if (isError) {
            nextStep = currentStep;
        }
        if (!isError && (nextStep == 'step-7' || nextStep == 'step-8')) {
            this.setState({ errors, isError });
            if (nextStep == 'step-7') {
                //register
                this.submitData();
            } else if (nextStep == 'step-8') {
                //sent data to cal api
                this.sendDataToEmail();
            }
        } else {
            this.setState({ errors, isError, currentStep: nextStep });
        }

    }

    handleClickPrevious = () => {
        var { registerForm, errors, currentStep, company_edit } = this.state;
        if (registerForm.have_yet == "Y") {
            switch (currentStep) {
                case "step-2-a":
                    currentStep = "step-1";
                    break;
                case "step-3":
                    currentStep = "step-2-a";
                    break;
                case "step-5":
                    currentStep = "step-2-a";
                    break;
                case "step-6":
                    currentStep = "step-5";
                    break;
                default:
                    currentStep = "step-1";
                    break;
            }
        } else {
            switch (currentStep) {
                case "step-2-b":
                    currentStep = "step-1";
                    break;
                case "step-4":
                    currentStep = "step-2-b";
                    break;
                case "step-5":
                    if (!company_edit) {
                        currentStep = "step-4";
                    } else {
                        currentStep = "step-2-b";
                    }
                    break;
                case "step-6":
                    currentStep = "step-5";
                    break;
                default:
                    currentStep = "step-1";
                    break;
            }
        }
        this.setState({ registerForm, errors, currentStep, errorMessage: "" });
        if (currentStep == "step-1") {
            this.onShowModal();
        }
    }

    submitData = () => {
        this.setState({ errorMessage: '' });
        var { registerForm, errors, currentStep, isError, checkedIATA, errorMessage, isError } = this.state;
        isError = false;
        errors = {};
        errorMessage = "";
        isError = false;
        this.props.loadingChangeData(true);
        var obj = this;
        //check user exist
        this.props.checkUserExist(this.state.registerForm.account_email).then(response => {
            if (response.data.success && response.data.userexist) {
                obj.props.loadingChangeData(false);
                errorMessage = "EMAIL_EXIST";
                obj.setState({ errors, errorMessage, isError: true });
            } else if (response.data.success == true && response.data.userexist == false) {
                var data_pass_cal = {
                    "Cust": registerForm.Cust,
                    "Custname": registerForm.Custname,
                    "Custdes": registerForm.company_name,
                    "Agreetec": "",
                    "IsGsa": registerForm.company_type_gsa,
                    "IataCode": registerForm.company_iata_1 + registerForm.company_iata_2,
                    "Country": registerForm.company_country,
                    "city": registerForm.company_city,
                    "Address": registerForm.company_address,
                    "Zip": registerForm.company_zipcode,
                    "VatCode": registerForm.company_vat_code,
                    "IsShipper": (registerForm.have_yet == "Y" || registerForm.company_type_shipper == "Y") ? "Y" : null,
                    "FullName": registerForm.account_name,
                    "Email": registerForm.account_email,
                    "Postion": registerForm.account_position,
                    "PhoneNumber": registerForm.account_phone,
                    "Cur": registerForm.company_currency,
                    "calCode": registerForm.company_currency
                };

                this.props.loadingChangeData(true);
                obj.props.createCalClient(data_pass_cal).then(response_cal => {
                    if (response_cal.success) {
                        var attributeList = [
                            { Name: 'email', Value: registerForm.account_email },
                            { Name: 'name', Value: registerForm.account_name },
                            { Name: 'custom:calphone', Value: response_cal.data.Phone }
                        ];
                        this.props.registerUser(registerForm.account_email, registerForm.account_password, attributeList).then(response_reg => {
                            obj.props.loadingChangeData(false);
                            if (response_reg.success) {
                                obj.setState({
                                    isError: false,
                                    errorMessage: '',
                                    currentStep: 'step-7'
                                });
                            } else {
                                obj.setState({
                                    isError: true,
                                    errorMessage: response_reg.message,
                                });
                            }
                        }).catch(err => {
                            obj.props.loadingChangeData(false);
                            obj.setState({
                                isError: true,
                                errorMessage: "Register unsuccess!",
                            });
                        });
                    } else {
                        obj.props.loadingChangeData(false);
                        errorMessage = "Create user unsuccess";
                        isError = true;
                        obj.setState({ errors, errorMessage, isError });
                    }
                }).catch(error => {
                    obj.props.loadingChangeData(false);
                    errorMessage = "Create user unsuccess";
                    isError = true;
                    obj.setState({ errors, errorMessage, isError });
                });
            } else {
                obj.props.loadingChangeData(false);
                errorMessage = "Can not check account exist. Plz try again.";
                isError = true;
                obj.setState({ errors, errorMessage, isError });
            }
        }).catch(error => {
            obj.props.loadingChangeData(false);
            errorMessage = "Can not check account exist. Plz try again.";
            isError = true;
            obj.setState({ errors, errorMessage, isError });
        });
    }

    sendDataToEmail = () => {
        this.setState({ errorMessage: '' });
        var { registerForm, errors, isError, errorMessage, isError } = this.state;
        isError = false;
        errors = {};
        errorMessage = "";
        isError = false;
        this.props.loadingChangeData(true);
        var obj = this;
        //send request
        var dataPassed = {
            company: registerForm.company_name,
            country: registerForm.company_country,
            vat: registerForm.company_vat_code,
            email: registerForm.account_email
        }
        this.props.requestAccount(dataPassed).then(response => {
            obj.props.loadingChangeData(false);
            if (response.success) {
                obj.setState({
                    isError: false,
                    errorMessage: '',
                    currentStep: 'step-8'
                });
            } else {
                errorMessage = "Send request unsuccess. Plz try again.";
                isError = true;
                obj.setState({ errors, errorMessage, isError });
            }
        }).catch(error => {
            obj.props.loadingChangeData(false);
            errorMessage = "Send request unsuccess. Plz try again.";
            isError = true;
            obj.setState({ errors, errorMessage, isError });
        });
    }

    chooseRegisterType = (type) => {
        var { registerForm, errors, currentStep } = this.state;
        errors = [];
        registerForm.have_yet = type;
        if (type == "Y") {
            currentStep = "step-2-a";
        } else {
            currentStep = "step-2-b";
        }
        this.setState({ registerForm, errors, currentStep });
    }

    handleChangeCountry = (selectedCountry) => {
        var { registerForm, errors } = this.state;
        registerForm.company_country = selectedCountry.label;
        errors["company_country"] = "";
        this.checkCASSCurrency("");
        this.setState({ registerForm, selectedCountry, errors });
    }

    getCassCurrency = () => {
        this.setState({ iataChanged: false });
        var { registerForm, selectedCountry, errors, checkedIATA } = this.state;
        var t = this;
        var company_iata = registerForm.company_iata_1 + "" + registerForm.company_iata_2;
        errors["company_iata"] = "";
        if (company_iata != "") {
            this.props.loadingChangeData(true);
            this.props.checkACode(company_iata).then(iataResponse => {
                this.props.loadingChangeData(false);
                if (iataResponse.success && iataResponse.data.length && iataResponse.data[0].reason.toUpperCase() == "VALID") {
                    var country_name = iataResponse.data[0].country;
                    country_name = country_name.trim().toUpperCase();
                    var findCountry = t.state.countries.find(c => c.label == country_name);
                    if (findCountry) {
                        selectedCountry = findCountry;
                    }
                    if(selectedCountry && selectedCountry.iatastasion && selectedCountry.iatastasion.trim() !== ""){
                        if (selectedCountry.label) {
                            registerForm.company_country = selectedCountry.label;
                            registerForm.company_city = iataResponse.data[0].city;
                            registerForm.company_address = iataResponse.data[0].address;
                            registerForm.company_zipcode = iataResponse.data[0]['postal-code'];
                            registerForm.company_name = iataResponse.data[0]['agency-name'];
                            registerForm.company_vat_code = "";
                            errors.company_address = "";
                            errors.company_vat_code = "";
                            errors.company_name = "";
                            errors.company_city = "";
                            errors.company_country = "";
                            errors.company_zipcode = "";
                            errors.company_ = "";
                            t.checkCASSCurrency(selectedCountry.label);
                            t.setState({ registerForm, selectedCountry: selectedCountry, errors, checkedIATA: true, company_edit: true });
                        } else {
                            t.checkCASSCurrency("");
                            t.setState({ checkedIATA: true, company_edit: true });
                        }
                    }else{
                        t.checkCASSCurrency("");
                        errors["company_iata"] = "Unfortunately! we are not CASS IATA/CASS CNS member in your country";
                        this.setState({ errors, checkedIATA: false, company_edit: false });
                    }                   
                    
                } else {
                    t.checkCASSCurrency("");
                    errors["company_iata"] = MESSAGES.ERROR_COMPANY_IATA;
                    this.setState({ errors, checkedIATA: false, company_edit: false });
                }
            });
        }

        this.setState({ registerForm, errors });
    }

    checkCASSCurrency = (country_name) => {
        var { registerForm, cassCurrency } = this.state;
        cassCurrency = false;
        if (country_name != "") {
            country_name = country_name.toUpperCase();
            var findCountry = this.state.cassCountries.find(c => c.name === country_name);
            if (findCountry) {
                cassCurrency = true;
                registerForm.company_currency = findCountry.currency;
            }
        }
        if (cassCurrency == false) {
            if (this.companyCurrency) {
                registerForm.company_currency = this.companyCurrency.value;
            } else {
                registerForm.company_currency = "USD";
            }
        }
        this.setState({ registerForm, cassCurrency: cassCurrency });
    }

    updateDimensions = () => {
        var recaptchaW = $('#RecaptchaRegister').width();
        var rootRecaptchaW = $('#rootReCapchaRegister').width();
        if (recaptchaW > 0) {
            var ratioW = rootRecaptchaW / recaptchaW;
            if (ratioW > 1) {
                ratioW = 1;
            }
            this.setState({ captchaScale: ratioW });
        } else {
            this.setState({ captchaScale: 1 });
        }
    }

    componentDidMount() {
        this.props.onRef(this);        
        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if ((oldProps.country.state != "COUNTRY_LOADED_DATA" && newProps.country.state == "COUNTRY_LOADED_DATA") || (newProps.country.state == "COUNTRY_LOADED_DATA" && !this.state.countries.length)) {
            var countries = [];
            var cassCountries = [];
            newProps.country.countries.forEach(c => {
                countries.push({ value: c.country, label: c.countryname, iatastasion: c.iatastasion });
                if (c.cass != "") {
                    cassCountries.push({ name: c.countryname, currency: c.cass });
                }
            });
            this.setState({ countries: countries, cassCountries: cassCountries });

            if (this.state.cusID && this.state.registerForm.cal_code) {
                this.checkCompleteRegisterData();
            }
        }
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowLoginPage() {
        var {history} = this.props;
        this.props.closeModal('modalRegister');
        history.push("/login");
    }

    onShowModal(calCode = "", cusID = "", showHeaderLinks = true) {
        var currentStep = "step-1";
        if (cusID) {
            currentStep = "step-5";
        }
        //reset captcha
        // window.RenderRecaptchRegister();
        //reset from data       
        this.setState({
            isError: false,
            currentStep: currentStep,
            checkedIATA: true,
            btnRegisterText: 'Register',
            errorMessage: '',
            successMessage: '',
            errors: {},
            cusID: cusID,
            company_edit: false,
            wrong_number: false,
            cassCurrency: false,
            selectedCountry: null,
            showHeaderLinks: showHeaderLinks,
            vatCodeEdit: true,
            registerForm: {
                'have_yet': 'no',
                'company_iata_1': "",
                'company_iata_2': "",
                'cal_code': calCode,
                'Custname': '',
                'Cust': 0,
                'company_client_number': '',
                'company_name': '',
                'company_vat_code': '',
                'company_country': '',
                'company_city': '',
                'company_address': '',
                'company_type_gsa': null,
                'company_type_shipper': null,
                'company_type_other': null,
                'company_zipcode': '',
                'company_currency': "USD",
                'account_name': '',
                'account_email': '',
                'account_password': '',
                'account_password_confirm': '',
                'account_position': '',
                'account_phone': '',
                'condition': 'no',
            }
        });
        this.updateDimensions();
        if (cusID && calCode && this.state.countries.length) {
            this.checkCompleteRegisterData();
        }
    };

    /* handler form's fields change value */
    handleChange(event) {
        var { registerForm, errors } = this.state;
        var wrong_number = this.state.wrong_number;
        const { target, target: { name, type } } = event;
        if (type === 'text' || type === 'email' || type === 'password' || type === 'radio' || type === 'select-one') {
            registerForm[name] = target.value;
            if (name == "have_yet") {
                this.checkCASSCurrency("");
                wrong_number = false;
                errors = [];
                registerForm['Cust'] = 0;
                registerForm['Custname'] = "";
                this.setState({ errorMessage: '' });
                if (target.value == 'no') {
                    registerForm['company_client_number'] = "";
                    registerForm['cal_code'] = "";
                    this.setState({ company_edit: false });
                } else {
                    this.setState({ company_edit: true });
                    registerForm['company_name'] = "";
                    registerForm['company_vat_code'] = "";
                    registerForm['company_country'] = "";
                    registerForm['company_type_gsa'] = null;
                    registerForm['company_type_shipper'] = null;
                    registerForm['company_type_other'] = null;
                    registerForm['company_city'] = "";
                    registerForm['company_address'] = "";
                    registerForm['company_zipcode'] = "";
                }
                if (registerForm.have_yet == "Y" || registerForm.company_type_shipper == "Y") {
                    errors['company_type'] = "";
                }
                this.setState({ calCodeChanged: false, calClientNumberChanged: false, checkedIATA: true });
                if (target.value != "Y" && registerForm.company_iata_1.length == 7 && registerForm.company_iata_2.length == 4) {
                    this.getCassCurrency();
                }
            } else if (name === "company_iata_1" || name === "company_iata_2") {
                errors["company_iata"] = "";
                registerForm['Custname'] = "";
                this.setState({ iataChanged: true, checkedIATA: false, company_edit: false });
                this.checkCASSCurrency("");
            } else if (name == "cal_code") {
                this.setState({ calCodeChanged: true });
            } else if (name == "account_email") {
                registerForm[name] = registerForm[name].toLowerCase();
            } else if (name == "company_client_number") {
                this.setState({ calClientNumberChanged: true });
            }
        } else {
            var check_checked = target.checked;
            if (check_checked) {
                registerForm[name] = "Y";
                if (name == "company_type_gsa") {
                    registerForm['company_type_shipper'] = null;
                    registerForm['company_type_other'] = null;
                } else if (name == "company_type_shipper") {
                    errors['company_type'] = "";
                    registerForm['company_type_gsa'] = null;
                    registerForm['company_type_other'] = null;
                } else if (name == "company_type_other") {
                    registerForm['company_type_gsa'] = null;
                    registerForm['company_type_shipper'] = null;
                } else if (name == "condition") {
                    errors["condition"] = "";
                }
            } else {
                registerForm[name] = null;
            }
            this.onClickCheckbox(name);
        }
        this.setState({ registerForm, errors, wrong_number: wrong_number });
    }

    onClickCheckbox = (name) => {
        var { registerForm, errors } = this.state;
        var isError = false;
        var validator = new Validator();
        switch (name) {
            case 'have_yet':
                if (registerForm.have_yet === 'Y' && (registerForm.company_client_number === '' || registerForm.cal_code === '')) {
                    if (registerForm.company_client_number === '') {
                        errors['company_client_number'] = MESSAGES.ERROR_REQUIRED;
                    }
                    if (registerForm.cal_code === '') {
                        errors['cal_code'] = MESSAGES.ERROR_REQUIRED;
                    }

                    isError = true;
                } else {
                    errors['company_client_number'] = '';
                    errors['cal_code'] = '';
                }
                break;
        }
        this.setState({ errors });
    }

    validateWithField(event) {
        var { registerForm, errors, company_edit } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        switch (target.name) {
            case 'company_iata_1':
                if (registerForm.company_iata_1 && registerForm.company_iata_1.length < 7) {
                    errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                    isError = true;
                }
                if (!isError) {
                    errors['company_iata_1'] = '';
                    if (registerForm.company_iata_2 == "") {
                        errors['company_iata_2'] = '';
                    }
                }
                registerForm['company_iata_1'] = registerForm['company_iata_1'].trim();
                this.setState({ registerForm });
                if (this.state.iataChanged && registerForm.company_iata_2.length == 4 && registerForm.company_iata_1.length == 7) {
                    this.checkingExistCustomer();
                }
                break;
            case 'company_iata_2':
                if (registerForm.company_iata_2 && registerForm.company_iata_2.length < 4) {
                    errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                    isError = true;
                }
                registerForm['company_iata_2'] = registerForm['company_iata_2'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['company_iata_2'] = '';
                    if (registerForm.company_iata_1 == "") {
                        errors['company_iata_1'] = '';
                    }
                }
                if (this.state.iataChanged && registerForm.company_iata_2.length == 4 && registerForm.company_iata_1.length == 7) {
                    this.checkingExistCustomer();
                }
                break;
            case 'cal_code':
                if (registerForm.have_yet == "Y" && !validator.required(registerForm.cal_code)) {
                    errors['cal_code'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                registerForm['cal_code'] = registerForm['cal_code'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['cal_code'] = '';
                }
                this.checkingExistCustomer();
                break;
            case 'company_client_number':
                if (registerForm.have_yet == "Y" && !validator.required(registerForm.company_client_number)) {
                    errors['company_client_number'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                registerForm['company_client_number'] = registerForm['company_client_number'].trim();
                this.setState({ registerForm });
                this.checkingExistCustomer();
                if (!isError) {
                    errors['company_client_number'] = '';
                }
                break;
            case 'company_name':
            case 'company_vat_code':
            case 'company_country':
            case 'company_city':
            case 'company_address':
            case 'company_zipcode':
                var valiate_value = registerForm.company_name;
                if (target.name == 'company_vat_code') {
                    valiate_value = registerForm.company_vat_code;
                    registerForm['company_vat_code'] = registerForm['company_vat_code'].trim();
                } else if (target.name == 'company_country') {
                    valiate_value = registerForm.company_country;
                    registerForm['company_country'] = registerForm['company_country'].trim();
                } else if (target.name == 'company_city') {
                    valiate_value = registerForm.company_city;
                    registerForm['company_city'] = registerForm['company_city'].trim();
                } else if (target.name == 'company_address') {
                    valiate_value = registerForm.company_address;
                    registerForm['company_address'] = registerForm['company_address'].trim();
                } else if (target.name == 'company_zipcode') {
                    valiate_value = registerForm.company_zipcode;
                    registerForm['company_zipcode'] = registerForm['company_zipcode'].trim();
                }
                if (!company_edit && !validator.required(valiate_value)) {
                    errors[target.name] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                this.setState({ registerForm });
                if (!isError) {
                    errors[target.name] = '';
                }
                break;
            case 'account_name':
                if (!validator.required(registerForm.account_name)) {
                    errors['account_name'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                registerForm['account_name'] = registerForm['account_name'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_name'] = '';
                }
                break;
            case 'account_email':
                registerForm['account_email'] = registerForm['account_email'].trim();
                if (!validator.email(registerForm.account_email)) {
                    errors['account_email'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                if (registerForm.account_email.length > 48) {
                    errors['account_email'] = "Max length 48 characters";
                    isError = true;
                }
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_email'] = '';
                }
                break;
            case 'account_password':
                if (!validator.required(registerForm.account_password) || !validator.minLength(registerForm.account_password, 6)) {
                    errors['account_password'] = MESSAGES.ERROR_PASS_RULE;
                    isError = true;
                }
                registerForm['account_password'] = registerForm['account_password'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_password'] = '';
                }
                break;
            case 'account_password_confirm':
                if (!validator.required(registerForm.account_password_confirm) || !validator.minLength(registerForm.account_password_confirm, 6)) {
                    errors['account_password_confirm'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                } else if (registerForm.account_password_confirm != registerForm.account_password) {
                    errors['account_password_confirm'] = MESSAGES.ERROR_CONFIRM_PASS;
                    isError = true;
                }
                registerForm['account_password_confirm'] = registerForm['account_password_confirm'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_password_confirm'] = '';
                }
                break;
            case 'account_position':
                if (!validator.required(registerForm.account_position)) {
                    errors['account_position'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                registerForm['account_position'] = registerForm['account_position'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_position'] = '';
                }
                break;
            case 'account_phone':
                if (!validator.required(registerForm.account_phone)) {
                    errors['account_phone'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
                registerForm['account_phone'] = registerForm['account_phone'].trim();
                this.setState({ registerForm });
                if (!isError) {
                    errors['account_phone'] = '';
                }
                break;

            case 'condition':
                if (registerForm.condition != 'Y') {
                    errors['condition'] = MESSAGES.ERROR_CONDITION;
                    isError = true;
                }

                if (!isError) {
                    errors['condition'] = '';
                }
                break;
        }
        this.setState({ errors });
    }

    validate() {
        this.setState({ errors: {} });
        const { registerForm } = this.state;
        var company_edit = this.state.company_edit;
        var errors = {};
        var isError = false;
        var validator = new Validator();

        if (!this.state.checkedIATA) {
            errors['company_iata'] = MESSAGES.ERROR_COMPANY_IATA;
            isError = true;
        }
        if (registerForm.company_iata_1 && registerForm.company_iata_1.length < 7) {
            errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }

        if (registerForm.company_iata_2 && registerForm.company_iata_2.length < 4) {
            errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }

        if (registerForm.company_iata_1 == "" && registerForm.company_iata_2 != "") {
            errors['company_iata_1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }

        if (registerForm.company_iata_1 != "" && registerForm.company_iata_2 == "") {
            errors['company_iata_2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }

        if (registerForm.have_yet == "Y" && !validator.required(registerForm.cal_code)) {
            errors['cal_code'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (registerForm.have_yet == "Y" && !validator.required(registerForm.company_client_number)) {
            errors['company_client_number'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_name)) {
            errors['company_name'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_vat_code)) {
            errors['company_vat_code'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_country)) {
            errors['company_country'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_city)) {
            errors['company_city'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_address)) {
            errors['company_address'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (registerForm.have_yet == "no" && !validator.required(registerForm.company_type_shipper)) {
            errors['company_type'] = "At the moment we only offer this service to Freight Forwarders and Shipping Agents";
            isError = true;
        }
        if (company_edit == false && !validator.required(registerForm.company_zipcode)) {
            errors['company_zipcode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!validator.required(registerForm.account_name)) {
            errors['account_name'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!validator.required(registerForm.account_password) || !validator.minLength(registerForm.account_password, 6)) {
            errors['account_password'] = MESSAGES.ERROR_PASS_RULE;
            isError = true;
        }
        if (!validator.required(registerForm.account_password_confirm) || !validator.minLength(registerForm.account_password_confirm, 6)) {
            errors['account_password_confirm'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        } else if (registerForm.account_password_confirm != registerForm.account_password) {
            errors['account_password_confirm'] = MESSAGES.ERROR_CONFIRM_PASS;
            isError = true;
        }
        if (!validator.required(registerForm.account_email)) {
            errors['account_email'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!validator.email(registerForm.account_email)) {
            errors['account_email'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }

        if (registerForm.account_email.length > 48) {
            errors['account_email'] = "Max length 48 characters";
            isError = true;
        }

        if (!validator.required(registerForm.account_position)) {
            errors['account_position'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!validator.required(registerForm.account_phone)) {
            errors['account_phone'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }else if(!validator.number(registerForm.account_phone) || !validator.minLength(registerForm.account_phone, 7)){
            errors['account_phone'] = "Minimum 7 digitals";
            isError = true;
        }

        if (registerForm.condition != 'Y') {
            errors['condition'] = MESSAGES.ERROR_CONDITION;
            isError = true;
        }

        //check captch
        // if (!window.getVerifiedRegister()) {
        //     errors['captcha'] = MESSAGES.ERROR_CAPTCHA;
        //     isError = true;
        // }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }


    checkCompleteRegisterData = () => {
        this.setState({ errorMessage: '' });
        var { registerForm, selectedCountry, checkedIATA, cusID, errors } = this.state;
        if (registerForm.cal_code != "" && cusID != "") {
            this.props.loadingChangeData(true);
            this.props.getCustomerData({ custcode: registerForm.cal_code, custname: cusID }).then(response => {
                this.props.loadingChangeData(false);
                if (response.success && response.data.Success && response.data.customer.Cust > 0) {
                    checkedIATA = true;
                    registerForm['company_name'] = response.data.customer.Custdes;
                    registerForm['Custname'] = response.data.customer.Custname;
                    registerForm['company_country'] = response.data.customer.countryname;
                    registerForm['company_city'] = response.data.customer.cityname ? response.data.customer.cityname : "";
                    registerForm['company_address'] = response.data.customer.address ? response.data.customer.address : "";
                    registerForm['company_zipcode'] = response.data.customer.zipcode ? response.data.customer.zipcode : "";
                    registerForm['Cust'] = response.data.customer.Cust ? response.data.customer.Cust : 0;
                    var findCountry = this.state.countries.find(c => c.label == response.data.customer.countryname);
                    if (findCountry) {
                        selectedCountry = findCountry;
                    }
                    this.setState({ errors, company_edit: true, registerForm, checkedIATA, selectedCountry });
                    if (response.data.customer.cur != "") {
                        registerForm.company_currency = response.data.customer.cur;
                        this.setState({ registerForm, cassCurrency: true });
                    } else {
                        this.checkCASSCurrency(selectedCountry.label ? selectedCountry.label : "");
                    }
                } else {
                    this.setState({ currentStep: "step-9" });
                }
            });
        }
    }

    checkingExistCustomer = (errorStep = "") => {
        this.setState({ errorMessage: '' });
        var validator = new Validator();
        var { registerForm, selectedCountry, checkedIATA, vatCodeEdit } = this.state;
        var errors = {};
        var company_edit = false;
        registerForm['Custname'] = "";
        vatCodeEdit = false;
        this.setState({ vatCodeEdit });
        if (registerForm.company_iata_1.trim() != "" && registerForm.company_iata_2.trim() != "") {
            this.setState({ calClientNumberChanged: false, iataChanged: false, calCodeChanged: false });
            this.props.loadingChangeData(true);
            this.props.getCustomerData({ iatacode: registerForm.company_iata_1 + registerForm.company_iata_2 }).then(response => {
                this.props.loadingChangeData(false);
                if (response.success && response.data.Success && response.data.customer.Cust > 0) {
                    registerForm['Custname'] = response.data.customer.Custname;
                    this.setState({ registerForm });
                    if (response.data.customer.IataCode == (registerForm.company_iata_1 + registerForm.company_iata_2)) {
                        checkedIATA = true;
                        registerForm['company_name'] = response.data.customer.Custdes;
                        registerForm['company_country'] = response.data.customer.countryname;
                        registerForm['company_city'] = response.data.customer.cityname ? response.data.customer.cityname : "";
                        registerForm['company_address'] = response.data.customer.address ? response.data.customer.address : "";
                        registerForm['company_zipcode'] = response.data.customer.zipcode ? response.data.customer.zipcode : "";
                        registerForm['company_vat_code'] = "";
                        var findCountry = this.state.countries.find(c => c.label == response.data.customer.countryname);
                        if (findCountry) {
                            selectedCountry = findCountry;
                        }
                        company_edit = true;
                        this.setState({ errors, company_edit, registerForm, checkedIATA, selectedCountry });
                        if (response.data.customer.cur != "") {
                            registerForm.company_currency = response.data.customer.cur;
                            this.setState({ registerForm, cassCurrency: true });
                        } else {
                            this.checkCASSCurrency(selectedCountry.label ? selectedCountry.label : "");
                        }
                    } else {
                        registerForm['company_name'] = "";
                        registerForm['company_country'] = "";
                        registerForm['company_city'] = "";
                        registerForm['company_address'] = "";
                        registerForm['company_zipcode'] = "";
                        registerForm['company_vat_code'] = "";
                        this.setState({ registerForm });
                        this.getCassCurrency();
                    }
                } else if (errorStep) {
                    this.setState({ currentStep: errorStep });
                } else {
                    registerForm['company_name'] = "";
                    registerForm['company_country'] = "";
                    registerForm['company_city'] = "";
                    registerForm['company_address'] = "";
                    registerForm['company_zipcode'] = "";
                    registerForm['company_vat_code'] = "";
                    vatCodeEdit = true;
                    this.setState({ registerForm, vatCodeEdit });
                    this.getCassCurrency();
                }
            });
        }
    }

    handSubmit() {
        if (this.validate()) {
            this.submitData();
        }
    }

    render() {
        const { errors, registerForm, currentStep, showHeaderLinks, vatCodeEdit, countries } = this.state;
        return (
            <div className="modal-groups ">
                <div className="modal" id="modalRegister" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-xlarge bg_blue" role="document">
                        <div className={`modal-content`}>
                            <div className="modal-main">
                                <div className="modal-header">
                                    <button type="button" className={`btn-modal-back ${(currentStep == 'step-1' || currentStep == 'step-7' || currentStep == 'step-8' || (!showHeaderLinks && currentStep != 'step-6')) ? '' : 'd-none'}`} onClick={this.props.closeModal.bind(this, 'modalRegister')}>Back To Main Page</button>
                                    <button type="button" className={`btn-modal-back ${(currentStep != 'step-1' && currentStep != 'step-7' && currentStep != 'step-8' && currentStep != 'step-9') ? '' : 'd-none'} ${(!showHeaderLinks && currentStep != 'step-6') ? 'd-none' : ''}`} onClick={this.handleClickPrevious.bind(this)}>Back To Previous Page</button>
                                    <div className="clb"></div>
                                </div>
                                <div className="modal-body">
                                    <table className="table-popup-content">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className={`register-step step-1 ${currentStep == "step-1" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-big text-center">
                                                                Sign Up
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '580px', margin: "0 auto" }}>
                                                                Before we start, we need to know if this is your first booking with CAL Cargo (online or not)
                                            </div>
                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button className="btn-signup mb-3" onClick={this.chooseRegisterType.bind(this, 'no')}>I'm new</button>
                                                                <button className="btn-signup btn-orange ml-3" onClick={this.chooseRegisterType.bind(this, 'Y')}>I‘ve booked with you before</button>
                                                            </div>
                                                            <div className="step-content-text-desc text-center mt-5 mb-3" style={{ maxWidth: '580px', margin: "0 auto" }}>
                                                                You must be a shipping agent  or forwarding agent in order to sign up
                                                </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-2-a ${currentStep == "step-2-a" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-medium text-center">
                                                                Great to see you again
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '665px', margin: "0 auto" }}>
                                                                Please fill in these details so you can find you in our books. If you don't have a IATA code, you can leave that field blank
                                            </div>
                                                            <div className="step-content-elements text-center mt-5" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-12 element-col`}>
                                                                        <div className={`register-element`}>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <label htmlFor="company_iata_1">IATA Code</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-8 slash-after">
                                                                                    <input tabIndex="3" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_iata || errors.company_iata_1) ? 'input-error' : ''}`} name="company_iata_1" maxLength={7} value={registerForm.company_iata_1} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                                    <div className="help text-center"><a href="#">What's a IATA code?</a></div>
                                                                                    <InputError error={errors.company_iata_1} />
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <input tabIndex="4" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_iata || errors.company_iata_2) ? 'input-error' : ''}`} name="company_iata_2" maxLength={4} value={registerForm.company_iata_2} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                                    <InputError error={errors.company_iata_2} />
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <InputError error={errors.company_iata} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-4 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_name">Company Name</label>
                                                                            <input tabIndex="5" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_name) ? 'input-error' : ''}`} name="company_name" maxLength={48} readOnly={this.state.company_edit} value={registerForm.company_name} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={`col-md-4 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_country">Country</label>
                                                                            <Select
                                                                                className={`select-box ${(errors.company_country) ? 'input-error' : ''}`}
                                                                                value={this.state.selectedCountry}
                                                                                isSearchable={true}
                                                                                isDisabled={this.state.company_edit}
                                                                                onBlur={this.validateWithField}
                                                                                onChange={this.handleChangeCountry}
                                                                                options={countries}
                                                                            />
                                                                            <InputError error={errors.company_country} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={`col-md-4 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_vat_code">VAT Code</label>
                                                                            <input tabIndex="7" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_vat_code) ? 'input-error' : ''}`} name="company_vat_code" readOnly={this.state.company_edit && !vatCodeEdit} maxLength={16} value={registerForm.company_vat_code} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_vat_code} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button tabIndex="8" className="btn-signup btn-orange mb-3" onClick={this.handleChangeStep.bind(this)}>Done</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-2-b ${currentStep == "step-2-b" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-medium text-center">
                                                                Registration
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '665px', margin: "0 auto" }}>
                                                                Fill in these details to sign up and begin making shipments. If you don't have a IATA code, you can leave that section blank
                                            </div>
                                                            <div className="step-content-elements text-center mt-5" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-md-6 element-col mb-3`}>
                                                                        <div className={`register-element`}>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <label htmlFor="company_iata_1">IATA Code</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-8 slash-after">
                                                                                    <input tabIndex="9" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_iata || errors.company_iata_1) ? 'input-error' : ''}`} name="company_iata_1" maxLength={7} value={registerForm.company_iata_1} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                                    <div className="help text-center"><a href="#">What's a IATA code?</a></div>
                                                                                    {/* <InputError error={errors.company_iata_1} /> */}
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <input tabIndex="10" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_iata || errors.company_iata_2) ? 'input-error' : ''}`} name="company_iata_2" maxLength={4} value={registerForm.company_iata_2} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                                    {/* <InputError error={errors.company_iata_2} /> */}
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <InputError error={errors.company_iata} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`col-md-6 element-col mb-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_name">Company Name</label>
                                                                            <input tabIndex="11" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_name) ? 'input-error' : ''}`} name="company_name" maxLength={48} readOnly={this.state.company_edit} value={registerForm.company_name} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_name} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">

                                                                    <div className={`col-md-6 element-col mb-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_vat_code">VAT Code</label>
                                                                            <input tabIndex="12" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_vat_code) ? 'input-error' : ''}`} name="company_vat_code" readOnly={this.state.company_edit && !vatCodeEdit} maxLength={16} value={registerForm.company_vat_code} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_vat_code} />
                                                                        </div>
                                                                    </div>
                                                                    <div className={`col-md-6 element-col`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_currency">Currency</label>
                                                                            <span className={`form-control currency ${this.state.cassCurrency ? "" : "d-none"}`}>{registerForm.company_currency}</span>
                                                                            <select tabIndex="13" className={`form-control currency ${this.state.cassCurrency ? "d-none" : ""}`} disabled={this.state.company_edit} name="company_currency" value={registerForm.company_currency} onChange={this.handleChange} ref={ref => { this.companyCurrency = ref }} >
                                                                                <option value="USD">USD</option>
                                                                                <option value="EUR">EUR</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button tabIndex="14" className="btn-signup btn-orange mb-3" onClick={this.handleChangeStep.bind(this)}>Next Step</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-3 ${currentStep == "step-3" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-text-desc text-center">
                                                                Wait a minute
                                            </div>
                                                            <div className="step-content-title-medium text-center mt-3" style={{ maxWidth: '420px', margin: "0 auto" }}>
                                                                We Need to do This in Person
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '445px', margin: "0 auto" }}>
                                                                We're having trouble finding you. We need some human help.
                                            </div>
                                                            <div className="step-content-elements text-center mt-4" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_email">Contact Email</label>
                                                                            <input tabIndex="25" autoComplete={uuid()} type="email" className={`form-control ${(errors.account_email) ? 'input-error' : ''}`} name="account_email" maxLength={48} value={registerForm.account_email} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_email} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="step-content-text-desc text-center mt-4" style={{ maxWidth: '490px', margin: "0 auto" }}>
                                                                All your information has been saved and we will get back to you in person very shortly.
                                            </div>

                                                            <div className="step-content-buttons text-center mt-4">
                                                                <button tabIndex="16" className="btn-signup btn-orange" onClick={this.handleChangeStep.bind(this)}>OK, Thanks</button>
                                                                <div><button tabIndex="17" autoComplete={uuid()} className="btn-modal-back-dashboard mt-2 mb-3" onClick={this.props.closeModal.bind(this, 'modalRegister')}>Back to Dashboard</button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-4 ${currentStep == "step-4" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-medium text-center">
                                                                Address
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '665px', margin: "0 auto" }}>
                                                                Where can we find you?
                                            </div>
                                                            <div className="step-content-elements text-center mt-5" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_country">Country</label>
                                                                            <Select
                                                                                className={`select-box ${(errors.company_country) ? 'input-error' : ''}`}
                                                                                value={this.state.selectedCountry}
                                                                                isSearchable={true}
                                                                                isDisabled={this.state.company_edit}
                                                                                onBlur={this.validateWithField}
                                                                                onChange={this.handleChangeCountry}
                                                                                options={countries}
                                                                            />
                                                                            <InputError error={errors.company_country} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_city">City</label>
                                                                            <input tabIndex="19" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_city) ? 'input-error' : ''}`} name="company_city" readOnly={this.state.company_edit} maxLength={40} value={registerForm.company_city} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_city} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_address">Address</label>
                                                                            <input tabIndex="20" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_address) ? 'input-error' : ''}`} name="company_address" readOnly={this.state.company_edit} maxLength={150} value={registerForm.company_address} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_address} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="company_zipcode">Zip Code</label>
                                                                            <input tabIndex="21" autoComplete={uuid()} type="text" className={`form-control ${(errors.company_zipcode) ? 'input-error' : ''}`} name="company_zipcode" readOnly={this.state.company_edit} maxLength={10} value={registerForm.company_zipcode} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.company_zipcode} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button tabIndex="22" className="btn-signup btn-orange mb-3" onClick={this.handleChangeStep.bind(this)}>Next Step</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-5 ${currentStep == "step-5" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            {this.state.errorMessage}
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-medium text-center">
                                                                Contact Information
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '665px', margin: "0 auto" }}>
                                                                How to we get in touch with you?
                                            </div>
                                                            <div className="step-content-elements text-center mt-5" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_name">Full Name</label>
                                                                            <input tabIndex="23" autoComplete={uuid()} type="text" className={`form-control ${(errors.account_name) ? 'input-error' : ''}`} name="account_name" maxLength={37} value={registerForm.account_name} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_name} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_position">Title</label>
                                                                            <input tabIndex="24" autoComplete={uuid()} type="text" className={`form-control ${(errors.account_position) ? 'input-error' : ''}`} name="account_position" maxLength={32} value={registerForm.account_position} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_position} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_email">Email</label>
                                                                            <input tabIndex="25" autoComplete={uuid()} type="email" className={`form-control ${(errors.account_email) ? 'input-error' : ''}`} name="account_email" maxLength={48} value={registerForm.account_email} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_email} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_phone">Phone Number</label>
                                                                            <input tabIndex="26" autoComplete={uuid()} type="text" className={`form-control huge-input ${(errors.account_phone) ? 'input-error' : ''}`} name="account_phone" maxLength={20} value={registerForm.account_phone} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_phone} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element checkbox`}>
                                                                            <label className={`checkbox-container color-dark  ${(errors.shipper) ? 'input-error' : ''}`}>
                                                                                I am a shipping agent or forwarding agent
                                                                <input type="checkbox" tabIndex="27" name="company_type_shipper" id="company_type_shipper" value="1" checked={(registerForm.company_type_shipper == "Y") ? true : false} onChange={this.handleChange} />
                                                                                <span className="checkmark-checkbox"></span>
                                                                            </label>
                                                                            <div>
                                                                                <InputError error={errors.company_type} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button className="btn-signup btn-orange mb-3" onClick={this.handleChangeStep.bind(this)}>Next Step</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-6 ${currentStep == "step-6" ? "" : "d-none"}`}>
                                                        <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                                                            <div className={`text-danger ${this.state.errorMessage != "EMAIL_EXIST" ? 'd-none' : ''}`}>
                                                                Email already exists. Please <a href="javascript:void(0)" onClick={this.onShowLoginPage}>login</a> or <a href="javascript:void(0)" onClick={this.props.showModal.bind(this, 'modalPassReset')}>recover password</a>.
                                            </div>
                                                            <div className={`text-danger ${this.state.errorMessage != "EMAIL_EXIST" ? '' : 'd-none'}`}>
                                                                Registration failed. Please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a> if problem persists.
                                            </div>
                                                        </div>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-medium text-center">
                                                                Almost Done
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '680px', margin: "0 auto" }}>
                                                                We're happy to see {registerForm.company_name ? registerForm.company_name.toUpperCase() : "you"} joining our online system. All that's left is to choose your password, and we can get to work.
                                            </div>
                                                            <div className="step-content-elements text-center mt-5" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_password">Choose Password</label>
                                                                            <input tabIndex="28" autoComplete={uuid()} type="password" className={`form-control huge-input ${(errors.account_password) ? 'input-error' : ''}`} name="account_password" minLength={8} maxLength={20} value={registerForm.account_password} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_password} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element`}>
                                                                            <label htmlFor="account_password_confirm">Retype Password</label>
                                                                            <input tabIndex="29" autoComplete={uuid()} type="Password" className={`form-control huge-input ${(errors.account_password_confirm) ? 'input-error' : ''}`} name="account_password_confirm" minLength={8} maxLength={20} value={registerForm.account_password_confirm} onBlur={this.validateWithField} onChange={this.handleChange} />
                                                                            <InputError error={errors.account_password_confirm} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className={`col-md-12 element-col mt-3`}>
                                                                        <div className={`register-element checkbox`}>
                                                                            <label className={`checkbox-container color-dark fullwidth ${(errors.condition) ? 'input-error' : ''}`}>
                                                                                I have read and agree to the
                                                                <a className="terms_link" href="https://www.cal-cargo.com/terms-conditions/" target="_blank">Terms of Service</a> and
                                                                <a className="terms_link" href="https://www.cal-cargo.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                                                                <input type="checkbox" tabIndex="30" name="condition" checked={(registerForm.condition === "Y") ? true : false} onChange={this.handleChange} value="1" />
                                                                                <span className="checkmark-checkbox"></span>
                                                                            </label>
                                                                            <InputError error={errors.checkbox_term} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button tabIndex="31" className="btn-signup btn-orange mb-3" onClick={this.handleChangeStep.bind(this)}>Done</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-7 ${currentStep == "step-7" ? "" : "d-none"}`}>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-big text-center">
                                                                Success! You are registered!
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '580px', margin: "0 auto" }}>
                                                                We emailed you a verification code, please have a copy of this code available before your first login
                                            </div>
                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button className="btn-signup btn-orange mb-3" onClick={this.onShowLoginPage}>Login</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-8 ${currentStep == "step-8" ? "" : "d-none"}`}>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-big text-center">
                                                                Thank You!
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '580px', margin: "0 auto" }}>
                                                                A CAL Cargo representative will contact you soon to complete your registration
                                            </div>
                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button className="btn-signup btn-orange mb-3" onClick={this.props.closeModal.bind(this, 'modalRegister')}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`register-step step-9 ${currentStep == "step-9" ? "" : "d-none"}`}>
                                                        <div className={`step-content`}>
                                                            <div className="step-content-title-big text-center">
                                                                Something's Wrong
                                            </div>
                                                            <div className="step-content-text-desc text-center mt-3" style={{ maxWidth: '700px', margin: "0 auto" }}>
                                                                Please try again later. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                                            </div>
                                                            <div className="step-content-buttons text-center mt-5">
                                                                <button className="btn-signup btn-orange mb-3" onClick={this.props.closeModal.bind(this, 'modalRegister')}>Close</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    }
}

const ModalRegister = connect(mapStateToProps, mapDispatchToProps)(ModalRegisterFrom);

export default ModalRegister;