// "use strict";

class Validator {
    email(value) {
      var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (filter.test(value)) {
          return true;
      } else {
          return false;
      }
    }
  
    required(value) {
      if(value  === null || value.toString().trim() === '') {
        return false;
      }
      return true;
    }
  
    minLength(value, length) {
      if(value && value.length >= length) {
        return true;
      }
      return false;
    }
  
    maxLength(value, length) {
      if(value && value.length <= length) {
        return true;
      }
      return false;
    }

    exlength(value, length) {
      if(value && value.length == length) {
        return true;
      }
      return false;
    }
  
    match(value1, value2) {
      if(value1 === value2) {
        return true;
      }
  
      return false;
    }
  
    number(value) {
      if(!value || value == '')
        return true;
      var regex = /^\d+$/;
      return (regex.test(value));
    }

    currency(value){
      if(!value || value == '')
        return true;
      var regex = /^[0-9]\d*(?:\.\d{0,4})?$/;

      return (regex.test(value));
    }
    password(value){      
      if(!value || value == '')
        return true;
      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;

      return (regex.test(value));
    }
    weight(value){
      if(!value || value == '')
        return true;
        var regex = /^[1-9]\d*(?:\.\d{0,3})?$/;
      return (regex.test(value));
    }
  }
  
  export default Validator;
  