import ModelAPI from '../api';
import * as ActionTypes from '../actionTypes';

export function helpGetCountries() {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.getMethod('getting-data?type=countries');
        if (dataReturn.success == true) {
            dispatch({
                type: ActionTypes.COUNTRY_CHANGE_DATA,
                payload: dataReturn.data.data
            });
            return dataReturn.data.data;
        } else {
            dispatch({
                type: ActionTypes.COUNTRY_CHANGE_DATA,
                payload: []
            });
            return [];
        }
    }
}

export function helpGetCargoType() {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.getMethod('getting-data?type=cargo_type');
        if (dataReturn.success == true) {
            dispatch({
                type: ActionTypes.CARGO_TYPE_CHANGE_DATA,
                payload: dataReturn.data.data
            });
            return dataReturn.data.data;
        } else {
            dispatch({
                type: ActionTypes.CARGO_TYPE_CHANGE_DATA,
                payload: []
            });
            return [];
        }
    }
}

export function helpGetCurrencies() {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.getMethod('getting-data?type=currencies');
        if (dataReturn.success == true) {
            var currencies = [];
            dataReturn.data.data.map(currency => {
                if (currency.exchange > 0 && currency.code && currency.code !== '$') {
                    currencies.push({ value: currency.exchange, label: currency.code });
                }
            });

            var nis = currencies.find(item => item.label === "NIS");
            var usd = currencies.find(item => item.label === "USD");
            var eur = currencies.find(item => item.label === "EUR");
            currencies = currencies.filter(item => (item.label !== "NIS" && item.label !== "USD" && item.label !== "EUR"));
            if (eur) {
                currencies.unshift(eur);
            }
            if (usd) {
                currencies.unshift(usd);
            }
            if (nis) {
                currencies.unshift(nis);
            }

            dispatch({
                type: ActionTypes.CARGO_CURRENCIES_DATA,
                payload: currencies
            });
            return currencies;
        } else {
            dispatch({
                type: ActionTypes.CARGO_CURRENCIES_DATA,
                payload: []
            });
            return [];
        }
    }
}

export function helpGetAirports(country) {
    return async (dispatch) => {
        var link = 'getting-data?type=airports';
        if (country != "") {
            link += '&country=' + country;
        }
        let dataReturn = await ModelAPI.getMethod(link);
        if (dataReturn.success == true) {
            if (country == "") {
                var ports = [];
                dataReturn.data.data.map(port => {
                    port.value = port.portname;
                    port.label = port.portname + ", " + port.cityname;
                    port.pnum = port.port;
                    ports.push(port);
                });

                dispatch({
                    type: ActionTypes.PORT_CHANGE_DATA,
                    payload: ports
                });
            }
            return dataReturn.data.data;
        } else {
            if (country == "") {
                dispatch({
                    type: ActionTypes.PORT_CHANGE_DATA,
                    payload: []
                });
            }
            return [];
        }
    }
}

export function checkACode(iataCode) {
    return async (dispatch) => {
        let dataReturn = await ModelAPI.getMethod('check-a-code?iatacode=' + iataCode);
        // dataReturn = {
        //     success: true, data: [
        //         {
        //             "code": "12345678",
        //             "last-data-refresh": "201605110616",
        //             "reason": "VALID",
        //             "agency-name": "SAMPLE AGENCY",
        //             "approval-date": "6-10-2005",
        //             "agency-class": "PASSENGER",
        //             "site-type": "Branch Office",
        //             "address": "33 MAIN STREET",
        //             "city": "MONTREAL",
        //             "postal-code": "H0H 1H2",
        //             "state": "Quebec",
        //             "country": "SWEDEN",
        //             "phone-number": "1 514 874 0202"
        //         }
        //     ]
        // }
        if (dataReturn.success == true) {
            return dataReturn;
        } else {
            return [];
        }
    }
}

export function checkAllocations(awb, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'checkAllocations',
            "dataPass": {
                "awb": awb
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', dataPass, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, dataPass);
        return dataReturn;
    }
}