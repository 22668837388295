import React from 'react';
import StepProgress from '../../layouts/partials/StepProgress';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
import uuid from 'uuid';
import validator from 'validator';
import queryString from 'query-string';
import AppConfig from '../../AppConfig';
/**
 * Validator
 */
import InputError from '../InputError';
import EbookingInputError from '../EbookingInputError';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as supplierActions from '../../ninja-redux/actions/supplierActions';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import * as authActions from '../../ninja-redux/actions/authActions';

class Pickup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            errors: {},
            countries: [],
            isLoadingCountry: false,
            selectedCountryPickup: null,
            validateETD: true
        };
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnBlurSelect = this.handleOnBlurSelect.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClearDate = this.handleClearDate.bind(this);
        this.handleOnBlurDate = this.handleOnBlurDate.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        var { supplier } = this.props;
        if (oldProps.country != undefined && newProps.country != undefined && oldProps.country.state != "COUNTRY_LOADED_DATA" && newProps.country.state == "COUNTRY_LOADED_DATA") {
            var selectedCountryPickup = null;
            var countries = [];
            var isLoadingCountry = false;
            newProps.country.countries.forEach(c => {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                countries.push(country);
                if (c.countryname == supplier.pickupCountry) {
                    selectedCountryPickup = country;
                    this.setState({ selectedCountryPickup });
                }
            });
            this.setState({ countries, isLoadingCountry });
        }

    }

    componentWillUnmount() {
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var countries = [];
        var isLoadingCountry = true;
        var selectedCountryPickup = null;
        var { supplier, history } = this.props;
        this.setState({ validateETD: AppConfig.validateETD });
        if (this.props.country.state == "COUNTRY_LOADED_DATA") {
            isLoadingCountry = false;
            this.props.country.countries.forEach(c => {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                countries.push(country);
                if (c.countryname == supplier.pickupCountry) {
                    selectedCountryPickup = country;
                }
            });
            this.setState({ countries, isLoadingCountry, selectedCountryPickup });
        }
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var ord = parsedParams.ord;
        var awb = parsedParams.awb;
        if (!supplier.api_checked || !supplier.Dimensions.length) {
            history.push('/supplier-update/commodity/?awb=' + awb + '&ord=' + ord);
        } else if (!supplier.countryOfOrigine) {
            history.push('/supplier-update/special-instruction/?awb=' + awb + '&ord=' + ord);
        }
    }
    onClickOutside = () => {
        this._calendar.setOpen(false);
        this._calendar.cancelFocusInput();
    }

    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { supplier } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        this.setState({ errors });
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var { supplier } = this.props;

        if (name == "pickup_dropoff_time_hour") {
            if (moment.isMoment(supplier.pickup_dropoff_date)) {
                supplier.pickup_dropoff_date.set({ hour: value, minute: supplier.pickup_dropoff_time_minute });
                supplier[name] = value;
                this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
                this.handleOnBlurDate();
            } else {
                supplier[name] = value;
                this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
            }
        } else if (name == "pickup_dropoff_time_minute") {
            if (moment.isMoment(supplier.pickup_dropoff_date)) {
                supplier.pickup_dropoff_date.set({ hour: supplier.pickup_dropoff_time_hour, minute: value });
                supplier[name] = value;
                this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
                this.handleOnBlurDate();
            } else {
                supplier[name] = value;
                this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
            }
        } else {
            supplier[name] = value;
            this.props.supplierActions.supplierChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        }
        errors[name] = "";
        this.setState({ errors });
    }

    handleOnBlurSelect = (event) => {
        var { errors, selectedCountryPickup } = this.state;
        var { target, target: { id, name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (id == "pickupCountry") {
            if (selectedCountryPickup) {
                errors["pickupCountry"] = "";
            }
        }
        this.setState({ errors });
    }

    handleChangeCountryPickup = (selectedCountryPickup) => {
        var { supplier } = this.props;
        supplier["pickupCountry"] = selectedCountryPickup.labelhiden ? selectedCountryPickup.labelhiden : "";
        this.setState({ selectedCountryPickup });
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }

    /* Handle clear date field value */
    handleClearDate = () => {

    }

    /* Handler date fields change value */
    handleChangeDate = (v) => {
        var { supplier } = this.props;
        if (moment.isMoment(v)) {
            supplier.pickup_dropoff_date = v;
            supplier.pickup_dropoff_date.set({ hour: supplier.pickup_dropoff_time_hour, minute: supplier.pickup_dropoff_time_minute });
        } else {
            supplier.pickup_dropoff_date = null;
        }
        this.props.supplierActions.supplierUpdateData(supplier).then(responseData => { });
    }


    handleOnBlurDate = (v) => {
        const { errors, validateETD } = this.state;
        var { supplier } = this.props;
        var dateNow = moment(new Date);
        var datePickup = moment(new Date);
        datePickup.add(1, 'months');
        if (supplier.pickup_dropoff_date) {
            if (validateETD) {
                if (moment.isMoment(supplier.pickup_dropoff_date) && supplier.pickup_dropoff_date >= dateNow && supplier.pickup_dropoff_date <= datePickup) {
                    errors["pickup_dropoff_date"] = "";
                    errors["pickup_dropoff_date_max"] = "";
                } else if (supplier.pickup_dropoff_date < dateNow || supplier.pickup_dropoff_date > datePickup) {
                    errors["pickup_dropoff_date"] = "";
                    errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + datePickup.format("DD/MM HH:mm");
                }

            } else {
                //Dev
                if (moment.isMoment(supplier.pickup_dropoff_date)) {
                    errors["pickup_dropoff_date"] = "";
                }
            }
        } else {
            errors["pickup_dropoff_date"] = MESSAGES.ERROR_REQUIRED;
        }

        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });
        var { validateETD } = this.state;
        var { supplier } = this.props;
        var errors = {};
        var isError = false;

        var dateNow = moment(new Date);
        var datePickup = moment(new Date);
        datePickup.add(1, 'months');

        if (supplier.pickup_dropoff_date) {
            if (validateETD) {
                //Prod               
                if (moment.isMoment(supplier.pickup_dropoff_date) && supplier.pickup_dropoff_date >= dateNow && supplier.pickup_dropoff_date <= datePickup) {
                    errors["pickup_dropoff_date"] = "";
                    errors["pickup_dropoff_date_max"] = "";
                } else if (supplier.pickup_dropoff_date < dateNow || supplier.pickup_dropoff_date > datePickup) {
                    errors["pickup_dropoff_date"] = "";
                    errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + datePickup.format("DD/MM HH:mm");
                    isError = true;
                }
            } else {
                //Dev
                if (!moment.isMoment(supplier.pickup_dropoff_date)) {
                    errors['pickup_dropoff_date'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
            }
        } else {
            errors["pickup_dropoff_date"] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }


        if (supplier.pickupCity === undefined || supplier.pickupCity === "") {
            errors['pickupCity'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (supplier.pickupCountry === undefined || supplier.pickupCountry === "") {
            errors['pickupCountry'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (supplier.pickupPostalCode == undefined || supplier.pickupPostalCode == "") {
            errors['pickupPostalCode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (validator.isEmpty(supplier.pickupEmail) || !validator.isEmail(supplier.pickupEmail)) {
            errors['pickupEmail'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }
        if (validator.isEmpty(supplier.pickupAddress)) {
            errors['pickupAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(supplier.pickupNameOfContact)) {
            errors['pickupNameOfContact'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(supplier.pickupCompanyName)) {
            errors['pickupCompanyName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(supplier.pickupOpeningHours)) {
            errors['pickupOpeningHours'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(supplier.pickupPhoneNumber)) {
            errors['pickupPhoneNumber'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
        }
        return isError;
    }


    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        const { supplier, history } = this.props;
        if (this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }

        // var HandlingInstrunctionCodes = [];
        // supplier.instructions.forEach(ins => {
        //     HandlingInstrunctionCodes.push(ins.value);
        // });

        // var awbSubmit = supplier.awb;
        // if (awbSubmit.length > 11) {
        //     awbSubmit = awbSubmit.substr(0, 11);
        // }
        // var hsCodeFields = [];
        // hsCodeFields.push(supplier.hsCodeField);
        // var submitData = {
        //     "awb": awbSubmit,
        //     "weight": supplier.units_total_chargable_weight,
        //     "DGR": supplier.shipment_dangerous_goods ? "Y" : null,
        //     "Overlap": (supplier.overlap_shipment == "Overlap" || supplier.overlap_shipment_check) ? "Y" : null,
        //     "Litium": supplier.shipment_lithium_batteries ? "Y" : null,
        //     "Unstackable": supplier.shipment_unstackable ? "Y" : null,
        //     "un": supplier.un,
        //     "Hscode": supplier.hsCode.length ? supplier.hsCode : hsCodeFields,
        //     "Eroi": supplier.EROI,
        //     "FromCountry": supplier.countryOfOrigine,
        //     "Pickupref": supplier.pickupReferenceNumber,
        //     "Pickcompany": supplier.pickupCompanyName,
        //     "Pickcountry": supplier.pickupCountry,
        //     "Pickcity": supplier.pickupCity,
        //     "Pickzip": supplier.pickupPostalCode,
        //     "Contact": supplier.pickupNameOfContact,
        //     "Email": supplier.pickupEmail,
        //     "Phonenum": supplier.pickupPhoneNumber,
        //     "Picktime": supplier.pickup_dropoff_date ? supplier.pickup_dropoff_date.format("DD/MM/YY HH:mm") : "",
        //     "Pickopenhours": supplier.pickupOpeningHours,
        //     "Pickcomment": supplier.pickupComments,
        //     "class": supplier.class,
        //     "HandlingInstrunctionCodes": HandlingInstrunctionCodes,
        //     "Dimensions": supplier.Dimensions
        // }

        // this.props.supplierActions.supplierUpdate(submitData).then(res => {
        //     if (res.success && res.data.Success) {
        //         // history.push('/supplier-update/success');
        //         history.push('/supplier-update/attach-files/?awb=' + supplier.awb + '&ord=' + supplier.ord);
        //     } else {
        //         this.setState({ errorMessage: "Update order unsuccess. Plz try again!" });
        //         window.scrollToTop();
        //         return false;
        //     }
        // });

        history.push('/supplier-update/attach-files/?awb=' + supplier.awb + '&ord=' + supplier.ord);
    }

    renderHours() {
        const hours = [];
        for (var i = 0; i < 24; i++) {
            var h = i;
            if (h < 10) {
                h = "0" + h;
            } else {
                h = "" + h;
            }
            hours.push(<option key={h} value={h}>{h}</option>);
        }
        return hours;
    }

    renderMinutes() {
        const minites = [];
        for (var i = 0; i < 60; i = i + 5) {
            var m = i;
            if (m < 10) {
                m = "0" + m;
            } else {
                m = "" + m;
            }
            minites.push(<option key={m} value={m}>{m}</option>);
        }
        return minites;
    }

    addDays(dirtyAmount) {
        // var dateReturn = moment().add(dirtyAmount, 'days');
        var dateReturn = moment().add(dirtyAmount, 'months');
        return dateReturn;
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        var { errors, errorMessage, countries, isLoadingCountry, selectedCountryPickup, validateETD } = this.state;
        var { supplier } = this.props;

        return (
            <div className="ebooking supplier">
                <form autoComplete="off" className="form-block">
                    <div className="header-menu">
                        <div className="row">
                            <div className="col-6 text-left">
                                <Link to={'/supplier-update/special-instruction/?awb=' + supplier.awb + '&ord=' + supplier.ord} className="supplier-link-back">Dangerous Goods & Special Instructions</Link>
                            </div>
                            <div className="col-6 text-right">
                                <span className="supplier-details-awb">Details for AWB {supplier.awb}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`container block-form mt-3 ${supplier.api_checked ? 'd-none' : ''}`}>
                        <div className="form-content">
                            <div className={`form-group mt-3`}>
                                <div className="alert alert-danger" role="alert">
                                    We not found information of this order. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`container block-form mt-3 ${errorMessage ? '' : 'd-none'}`}>
                        <div className="form-content">
                            <Notification isError={errorMessage ? true : false} message={errorMessage} hideMessage={this.hideMessage}/>
                        </div>
                    </div>
                    <div className="container block-form mt-5 mb-5">
                        <h2 className="formTitle">Pickup Details</h2>
                        <div className="formDescription">
                            Pickup from city, country, zip code.
                    </div>
                        <div className="form-content no_padding mt-5">
                            <div className={`form-group`}>
                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupCompanyName">Company Name</label>
                                        <input type="text" className={`form-control ${(errors.pickupCompanyName) ? 'input-error' : ''}`} maxLength={48} id="pickupCompanyName" name="pickupCompanyName" value={supplier.pickupCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupCompanyName} />
                                    </div>
                                </div>
                                {/* <div className={`row ${(showPickup || (!showPickup && !showDelivery)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "79px" }}></div> */}
                                <div className="row">
                                    <div className="form-group form-rows col-md-3 col-sm-12">
                                        <label htmlFor="pickupCountry">Country</label>
                                        <Select
                                            placeholder="Choose Country"
                                            inputId="pickupCountry"
                                            autoComplete="off"
                                            className={`select-box huge-input ${(errors.pickupCountry) ? 'input-error' : ''}`}
                                            value={selectedCountryPickup}
                                            isSearchable={true}
                                            isDisabled={false}
                                            onChange={this.handleChangeCountryPickup}
                                            options={countries}
                                            isLoading={isLoadingCountry}
                                            onBlur={this.handleOnBlurSelect}
                                        />
                                        <EbookingInputError error={errors.pickupCountry} />
                                    </div>
                                    <div className="form-group form-rows col-md-3 col-sm-12">
                                        <label htmlFor="pickupCity">City</label>
                                        <input type="text" className={`form-control ${(errors.pickupCity) ? 'input-error' : ''}`} id="pickupCity" name="pickupCity" value={supplier.pickupCity} maxLength={30} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupCity} />
                                    </div>
                                    <div className="form-group form-rows col-md-3 col-sm-12">
                                        <label htmlFor="pickupPostalCode">Postal Code</label>
                                        <input type="text" className={`form-control ${(errors.pickupPostalCode) ? 'input-error' : ''}`} id="pickupPostalCode" name="pickupPostalCode"  maxLength={30} value={supplier.pickupPostalCode} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupPostalCode} />
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupAddress">Address</label>
                                        <input type="text" className={`form-control ${(errors.pickupAddress) ? 'input-error' : ''}`} maxLength={255} id="pickupAddress" name="pickupAddress" value={supplier.pickupAddress} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupAddress} />
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupNameOfContact">Name of Contact</label>
                                        <input type="text" className={`form-control ${(errors.pickupNameOfContact) ? 'input-error' : ''}`} maxLength={48} id="pickupNameOfContact" name="pickupNameOfContact" value={supplier.pickupNameOfContact} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupNameOfContact} />
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupEmail">Email</label>
                                        <input type="Email" className={`form-control ${(errors.pickupEmail) ? 'input-error' : ''}`} id="pickupEmail" name="pickupEmail" maxLength={48} value={supplier.pickupEmail} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupEmail} />
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupPhoneNumber">Phone Number</label>
                                        <input type="text" className={`form-control ${(errors.pickupPhoneNumber) ? 'input-error' : ''}`} id="pickupPhoneNumber" name="pickupPhoneNumber" maxLength={20} value={supplier.pickupPhoneNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupPhoneNumber} />
                                    </div>
                                </div>

                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-9">
                                        <label htmlFor="pickupOpeningHours">Opening Hours</label>
                                        <textarea rows={4} className={`form-control ${(errors.pickupOpeningHours) ? 'input-error' : ''}`} id="pickupOpeningHours" name="pickupOpeningHours" value={supplier.pickupOpeningHours} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupOpeningHours} />
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-6">
                                        <label htmlFor="pickupPhoneNumber">Pickup Reference Number</label>
                                        <input type="text" className={`form-control ${(errors.pickupReferenceNumber) ? 'input-error' : ''}`} id="pickupReferenceNumber" name="pickupReferenceNumber" value={supplier.pickupReferenceNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupReferenceNumber} />
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className="form-group form-rows col-12 col-md-9">
                                        <label htmlFor="pickupComments">Comments</label>
                                        <textarea rows={4} className={`form-control ${(errors.pickupComments) ? 'input-error' : ''}`} id="pickupComments" name="pickupComments" value={supplier.pickupComments} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                        <EbookingInputError error={errors.pickupComments} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="blue-bg">
                        <div className="container block-form pt-5 pb-5">


                            <div className="form-content no_padding">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-rows">
                                            <h2 className="formTitle mb-3">Pickup Time (Local)</h2>
                                            <div className="formDescription text-white" style={{ 'maxWidth': '370px' }}>
                                                The date and time your cargo will be ready for pickup (local time).
                                        </div>
                                        </div>
                                        {/* <div className="form-rows">
                                            <div className="row mt-5 mb-2">
                                                <div className="form-group col-12 text-center">
                                                    <a href="https://www.cal-cargo.com/e-services/flights-schedule/" target="_blank" className="link_flight_scedule">View Flight Schedule</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">

                                        <div className="form-rows">
                                            <div className="row">
                                                <div className="col-12 col-md-10">
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="weight" className="text-white">Pickup Date</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        <div className="form-group col-12">
                                                            <DatePicker
                                                                ref={(c) => this._calendar = c}
                                                                name="pickup_dropoff_date"
                                                                id="pickup_dropoff_date"
                                                                className={`form-control ${(errors.pickup_dropoff_date || errors.pickup_dropoff_date_max) ? 'input-error' : ''}`}
                                                                key="pickup_dropoff_date"
                                                                selected={supplier.pickup_dropoff_date ? supplier.pickup_dropoff_date : null}
                                                                isClearable={true}
                                                                minDate={validateETD ? moment() : null}
                                                                maxDate={validateETD ? this.addDays(1) : null}
                                                                // maxDate={this.addDays(ebooking.deliver_from == "Airport" ? 21 : 22)}                                                                        
                                                                onChange={this.handleChangeDate}
                                                                onBlur={this.handleOnBlurDate}
                                                                disabledKeyboardNavigation
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                dateFormat="DD/MM/YYYY"
                                                                placeholderText="DD/MM/YYYY"
                                                                onClickOutside={this.onClickOutside}
                                                            // autoComplete={uuid()}
                                                            />
                                                            {/* <small className="note-text">disclaimer about RPD</small> */}
                                                            <InputError error={errors.instructions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-rows">
                                            <div className="row">
                                                <div className="col-12 col-md-10">
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="weight" className="text-white">Time</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group max80 col-12">
                                                            <select className={`form-control inline-elements ${errors.pickup_dropoff_date_max ? 'input-error' : ''}`} style={{ width: '50px' }} name="pickup_dropoff_time_hour" value={supplier.pickup_dropoff_time_hour} onChange={this.handleChange} >
                                                                {
                                                                    this.renderHours()
                                                                }
                                                            </select>
                                                            <label className={`inline-elements`}>:</label>
                                                            <select className={`form-control inline-elements ${errors.pickup_dropoff_date_max ? 'input-error' : ''}`} style={{ width: '50px' }} name="pickup_dropoff_time_minute" value={supplier.pickup_dropoff_time_minute} onChange={this.handleChange} >
                                                                {
                                                                    this.renderMinutes()
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <InputError error={errors.pickup_dropoff_date_max} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container block-form mt-5">
                        <div className="dv-button-footer div_center text-center">
                            <button type="button" className="btn btn-lg btn-orange" disabled={!supplier.api_checked} onClick={this.handSubmit}>Next Step</button>
                            <button type="button" className="btn btn-lg btn-link just-text">One steps left</button>
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        supplierActions: bindActionCreators(supplierActions, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pickup);