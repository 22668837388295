import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";

class ClaimHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            isShowSearchForm: false
        }
        this.showMobileMenu = this.showMobileMenu.bind(this);
    }

    showMobileMenu() {
        if (this.state.isShowMenu == true) {
            this.setState({ isShowMenu: false });
        } else {
            this.setState({ isShowMenu: true });
        }

        this.props.clickOnHeader(this.state.isShowMenu);
    };

    showSearchForm = () => {
        this.setState({ isShowSearchForm: true });
    };
    hideSearchForm = () => {
        this.setState({ isShowSearchForm: false });
    };
    render() {
        const { isShowMenu, isShowSearchForm } = this.state;
        return (
            <>
                <div className='site-header'>
                    <div className={`navbar-collapse-mobile${isShowMenu ? ' open' : ''}`} >
                        <div onClick={this.showMobileMenu} className="close-menu">
                            <span>Close</span>
                        </div>
                        <ul className="navbar-nav-mobile text-uppercase ml-auto">
                            {/* <li className="nav-item">
                            <Link className="nav-link-insite js-scroll-trigger" onClick={this.showMobileMenu} to={`${process.env.PUBLIC_URL}/ebooking/step1`}>e-Booking</Link>
                            <div className="plus-button"></div>
                        </li> */}
                            <li className="nav-item">
                                <a className="open-submenu" href="#">Services</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://challenge-group.com/services/air-cargo/" target="_blank">Air Cargo</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/services/logistic/" target="_blank">Logistics</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/services/technics/" target="_blank">Technics</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="open-submenu" href="https://challenge-group.com/air-cargo-expertise/">Expertise</a>                                
                            </li>

                            <li className="nav-item">
                                <a className="open-submenu" href="#">Subsidiaries</a>
                                <div className="plus-button"></div>
                                <ul className="sub-menu">
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-airlines-il/" target="_blank">Challenge Airlines IL</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-airlines-be/" target="_blank">Challenge Airlines BE</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-airlines-mt/" target="_blank">Challenge Airlines MT</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-air-cargo/" target="_blank">Challenge Air Cargo</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-aviation/" target="_blank">Challenge Aviation</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-handling/" target="_blank">Challenge Handling</a></li>
                                    <li className="menu-item"><a href="https://challenge-group.com/expert/challenge-logistics/" target="_blank">Challenge Logistics</a></li>
                                    <li className="menu-item"><a href="https://challenge-technic.com/" target="_blank">Challenge Technics</a></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a href="https://challenge-group.com/network/" className="open-submenu">Network</a>                               
                            </li>

                            <li className="nav-item">
                                <a href="https://challenge-group.com/about-us/" className="open-submenu">About Us</a>                                
                            </li>

                            <li className="nav-item">
                                <a href="https://challenge-group.com/contact-us/" className="open-submenu">Contact Us</a>                                
                            </li>
                        </ul>
                    </div>
                    <div className={`main-header${isShowMenu ? ' move-right' : ''}`}>
                        <div className="container">
                            <div className='content-container'>
                                <div className='p-2'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div className='logo'>
                                                <a href='https://challenge-group.com' title='Challenge Group' className="main-logo"><img src="/images/logo_Challenge.svg" /></a>
                                            </div>
                                        </div>
                                        <div className='col-9'>
                                            <button onClick={this.showMobileMenu} className="navbar-toggler navbar-toggler-right d-block d-md-none" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                                            </button>
                                            <ul className="top-menu d-none d-md-flex">
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-airlines-il/" title='Challenge Airlines IL'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Airlines IL</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-airlines-be/" title='Challenge Airlines BE'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Airlines BE</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-airlines-mt/" title='Challenge Airlines MT'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Airlines MT</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-air-cargo/" title='Challenge Air Cargo'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Air Cargo</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-aviation/" title='Challenge Aviation'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Aviation</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-handling/" title='Challenge Handing'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Handing</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-group.com/expert/challenge-logistics/" title='Challenge Logistics'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Logistics</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>
                                                <li className="menu-item">
                                                    <a className="nav-link" href="https://challenge-technic.com/" title='Challenge Technic'>
                                                        <div className='menu-item-content'>
                                                            <div className='menu-populated'>
                                                                <div className='menu-heading'>Challenge</div>
                                                                <div className='menu-heading-sub'>Technic</div>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </li>


                                            </ul>

                                            <div className="clb"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='menu-main d-none d-md-flex'>
                    <div className="container">
                        <div className='content-container pl-0 pr-0'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='menu-innter'>
                                        <ul className='challenge-menu'>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/challenge-services/' title='Services'>
                                                    <div className='menu-item-wraper'>
                                                        <div>Services</div>
                                                        <div class="dropdown-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className='sub-mega-menu'>
                                                    <div className='sub-mega-menu-item'>
                                                        <div className='sub-menu-section'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <a href='https://challenge-group.com/services/air-cargo/' title='Air Cargo'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/ico_air_cargo.svg" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'>Air Cargo</div>
                                                                        </div>
                                                                    </a>

                                                                </div>
                                                                <div className='col-4'>
                                                                    <a href='https://challenge-group.com/services/technics/' className='send-menu' title='Technics'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/ico_technics.svg" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'>Technics</div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <a href='https://challenge-group.com/services/logistic/' title='Logistics'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/ico_logistic.svg" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'>Logistics</div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/air-cargo-expertise/' title='Expertise'>
                                                    <div className='menu-item-wraper'>Expertise</div>
                                                </a>
                                            </li>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/network/' title='Network'>
                                                    <div className='menu-item-wraper'>Network</div>
                                                </a>
                                            </li>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/about-us/' title='About us'>
                                                    <div className='menu-item-wraper'>About us</div>
                                                </a>
                                            </li>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/contact-us/' title='Contact us'>
                                                    <div className='menu-item-wraper'>Contact us</div>
                                                </a>
                                            </li>
                                            <li className='menu-item'>
                                                <a className='top-menu-link' href='https://challenge-group.com/challenge-services/' title='Services'>
                                                    <div className='menu-item-wraper'>
                                                        <div>Tools</div>
                                                        <div class="dropdown-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className='sub-mega-menu'>
                                                    <div className='sub-mega-menu-item'>
                                                        <div className='sub-menu-section'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <a href='https://www.challenge-group.com/flight-schedule/' title='Flight Schedule'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/ico_air_cargo.svg" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'>Flight Schedule</div>
                                                                        </div>
                                                                    </a>

                                                                </div>
                                                                <div className='col-4'>
                                                                    <a href='https://www.challenge-group.com/tracking/' className='send-menu' title='Tracking'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/tracking-number-1.png" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'>Tracking</div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <a href='https://claims.challenge-group.com/claim' title='Claim'>
                                                                        <div className='text-center'>
                                                                            <div className='icon'>
                                                                                <img width="96" height="81" src="/images/icon-1.png" class="attachment-large size-large wp-image-263" alt="Cargo Icon" />
                                                                            </div>
                                                                            <div className='name'><a href='https://claims.challenge-group.com/claim' className='inline-link'>New Claim</a> / <a href='https://claims.challenge-group.com/claim-update' className='inline-link'>Claim Update</a></div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ClaimHeader;
