import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Validator
 */
import validator from 'validator';
import * as MESSAGES from '../../../libs/Messages';
import ReactTooltip from "react-tooltip";

export default class PopperItemFlight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            styles: { popper: {}, arrow: {} },
            attributes: { popper: {} },
            tooltipID: uuidv4()
        }
    }


    componentDidMount() {
        window.loadTooltip();
    }
    render() {
        return (
            < >
                <div data-tip data-for={this.state.tooltipID}>
                    <div className="flights">
                        <div className={`f-start ${this.props.data.pqfrom.length > 4 ? 'small-text' : ''}`}>{this.props.data.pqfrom}</div>
                        <div className="f-progress">
                            <div className="dot-from"></div>
                            <div className="dot-currrent" style={{ left: this.props.data.percent + '%' }}></div>
                            <div className="dot-to"></div>
                        </div>
                        <div className={`f-end ${this.props.data.pqto.length > 4 ? 'small-text' : ''}`}>{this.props.data.pqto}</div>
                    </div>
                </div>
                <ReactTooltip id={this.state.tooltipID} aria-haspopup='true' place="bottom" effect="float" role='example'>
                    <p><b>Flight: </b> {this.props.data.fnumber}</p><p><b>From: </b>{this.props.data.pqfromCity} ({this.props.data.from})</p><p><b>To: </b>{this.props.data.pqtoCity} ({this.props.data.to})</p><p><b>EDT: </b> {this.props.data.etd}</p>
                </ReactTooltip>
            </>
        );
    }
}

PopperItemFlight.propTypes = {
    data: PropTypes.object
};