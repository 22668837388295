import {combineReducers} from "redux";
import resume from "./resume";
import claim from "./claim";
import ebooking from "./ebooking";
import loading from "./loading";
import auth from "./auth";
import country from "./country";
import port from "./port";
import dashboard from "./dashboard";
import supplier from "./supplier";
import cargotype from "./cargotype";
import currencies from "./currencies";
import quotes from "./quotes";
import paticipants from "./paticipants";

export default combineReducers({
    resume,
    claim,
    ebooking,
    loading,
    auth,
    country,
    port,
    dashboard,
    supplier,
    cargotype,
    quotes,
    currencies,
    paticipants
})