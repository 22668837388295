import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Account from '../../layouts/partials/Account';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as authActions from '../../ninja-redux/actions/authActions';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},

            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'My Account'
                },
            ],
            steps: [
                {
                    'link': 'account/shipments',
                    'active': false,
                    'name': 'My Shipments'
                },
                {
                    'link': 'dashboard/quotes',
                    'active': false,
                    'name': 'My Quotes'
                },
                {
                    'link': 'account',
                    'active': false,
                    'name': 'My Info'
                },
                {
                    'link': 'account/reports',
                    'active': true,
                    'name': 'My Reports'
                }
            ],
            datas: [],
        };

    }

    componentDidMount() {
        this.checkShowLoginForm();
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            // window.parent.postMessage('show-login-form', '*');
            history.push("/login?back=" + base64.encode("/account/reports"));
        }
    }

    render() {
        const { breadcrumbs, steps, isError, errors, errorMessage }
            = this.state;



        return (

            <div className="container myaccount">
                <Breadcrumb breadcrumbData={breadcrumbs} />

                <h1 className="title">
                    My Account
                <span className="account">Customer #: CAL879233</span>
                </h1>

                <Account accountData={steps} />

                <Notification isError={isError} message={errorMessage} />

                <div className="col-md-8 col-12 report-groups">
                    <div className="row header">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">Uploading date <i class="fa fa-long-arrow-down" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name A</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">2 Sep 17 / 8:14 </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="download">Download</span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name A</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">2 Sep 17 / 8:14 </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="download">Download</span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name A</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">2 Sep 17 / 8:14 </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="download">Download</span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name A</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">2 Sep 17 / 8:14 </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="download">Download</span>
                            </div>
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col-6">
                            <div className="row">
                                <span>Report Name A</span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="upload-date">2 Sep 17 / 8:14 </span>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <span className="download">Download</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contactus-group">
                    <button type="button" className="btn btn-continues">Contact us</button>
                    <div className="clb"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);