import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import * as dashboardActions from '../../ninja-redux/actions/dashboardActions';
import BookingHelper from './BookingHelper';
import EbookingInputError from '../EbookingInputError';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class ReviewOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnContinue: "Checkout",
            errorMessage: null,
            isEdit: false,
            errors: {},
            deliveryType: "",
            ebooking: {
                selectedShippingReceiving: 'Shipping',
                receivingCompanyName: "",
                receivingIATA1: "",
                receivingIATA2: "",
                shippingCompanyName: "",
                shippingIATA1: "",
                shippingIATA2: "",
                consignorCompanyName: "",
                consignorAddress: "",
                consignorCity: "",
                consignorCountry: "",
                consignorGroundHandling: "",
                consignorGroundHandlingData: { warhsname: "" },
                consignorPostalCode: "",
                consigneeAddress: "",
                consigneeCity: "",
                consigneeCompanyName: "",
                consigneeCountry: "",
                consigneeGroundHandling: "",
                consigneeGroundHandlingData: { warhsname: "" },
                consigneePostalCode: "",
                pickupCheck: false,
                pickupAddress: "",
                pickupCity: "",
                pickupComments: "",
                pickupCountry: "",
                pickupEmail: "",
                pickupNameOfContact: "",
                pickupOpeningHours: "",
                pickupPhoneNumber: "",
                pickupPostalCode: "",
                deliverAddress: "",
                deliverCheck: false,
                deliverCity: "",
                deliverComments: "",
                deliverCountry: "",
                deliverEmail: "",
                deliverNameOfContact: "",
                deliverOpeningHours: "",
                deliverPhoneNumber: "",
                deliverPostalCode: "",
                deliver_from_airport_data: { calcity: "", cityname: "" },
                deliver_to_airport_data: { calcity: "", cityname: "" },
                ParcelChoosed: {},
                PricelistChoosed: { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} },
                PriceQuotation: {
                    "pl": [],
                    "routes": []
                },
                instructions: [],
                units_list: []
            },
            dataBooks: [],
            dataDetails: [],
            costDatas: [],
            cost2: [],
            cost2Row: 0,
            total: 0,
            totalExtra: 0,
            pricingCase: "",
            showPickup: false,
            showDelivery: false,
            showFreightForwarder: false,
            showGroundHandlingOrigin: false,
            showGroundHandlingDestination: false,
            totalCostWeight: 0,
            showingPopup: false,
            showingAgreement: false,
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'validate': false,
                    'current': true,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': true,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            ulds: {
                'HD': 'HD',
                'HDS': 'HD skid',
                'MD': 'MD',
                'MDS': 'MD skid',
                'LD': 'LD',
                'LDS': 'LD skid',
                'LOOSE': 'Loose'
            }
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClearDate = this.handleClearDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleBook = this.handleBook.bind(this);
        this.handlerPrint = this.handlerPrint.bind(this);
    }

    /* Handle clear date field value */
    handleClearDate = () => {
        const { ebooking } = this.state;
        ebooking.flight_date = '';
        this.setState({ ebooking });
    }

    /* Handler date fields change value */
    handleChangeDate = (v) => {
        const { ebooking } = this.state;
        // ebooking.dropoff_date = v.format('YYYY/MM/DD');
        this.setState({ ebooking });
    }

    handlerPrint = function (e) {
        window.print();
    }

    componentDidMount() {
        // console.log(this.props);
        //get redux data and set to state           
        var { dataDetails, dataBooks, costDatas, pricingCase, cost2, total, totalExtra, cost2Row, totalCostWeight, deliveryType, steps } = this.state;
        var { ebooking, history, auth } = this.props;
        // console.log(JSON.stringify(ebooking));
        if (this.props.auth.cash == "") {
            this.setState({ btnContinue: "Book Now" });
        }
        var authState = auth.state;
        //BOF - Doan này can mo
        if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        } else if (!ebooking.step_2_ok) {
            if (ebooking.PriceQuotation.pl.length > 0) {
                history.push('/ebooking/flight-pricing-pricelist');
            } else {
                switch (ebooking.PriceQuotation.type) {
                    case "WHITE":
                        history.push('/ebooking/flight-pricing-default');
                        break;
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;
                    default:
                        history.push('/ebooking/shipment');
                        break;
                }
            }
        } else if (!ebooking.step_3_ok) {
            if (auth.IsGSA) {
                history.push('/ebooking/participants-gsa');
            } else {
                history.push('/ebooking/participants');
            }

        }
        total = 0;
        switch (ebooking.PriceQuotation.type) {
            case "PARCEL":
                pricingCase = "PARCEL";
                total = ebooking.ParcelChoosed.total;
                deliveryType = ebooking.ParcelChoosed.priceType == "price" ? "economy" : "express";
                break;

            case "WHITE":
                if (ebooking.PriceQuotation.pl.length > 0) {
                    pricingCase = "PRICELIST";
                    deliveryType = "PRICELIST";
                } else {
                    deliveryType = ebooking.DefaultChoosed.priceType;
                    pricingCase = "WHITE";
                    var partPrice = 0;
                    for (var i = 0; i < ebooking.DefaultChoosed.flight.Parts.length; i++) {
                        var part = ebooking.DefaultChoosed.flight.Parts[i];
                        partPrice += parseFloat(part.price);
                        cost2.push({
                            extra: part.partname,
                            description: part.partdes,
                            total: part.price
                        });
                    }

                    totalCostWeight = parseFloat(ebooking.units_total_chargable_weight) * parseFloat(ebooking.DefaultChoosed.price);
                    total = totalCostWeight + partPrice;
                }
                break;
            case "PL":
                deliveryType = "PRICELIST";
                pricingCase = "PRICELIST";
                break;
            default:
                history.push('/ebooking/shipment');
                break;
        }
        total = total.toFixed(2);

        ebooking.units_list.map((unit, i) => {
            dataDetails.push({
                number_unit: unit.Pcs,
                length: unit.Length + unit.unitname.toLowerCase(),
                width: unit.Width + unit.unitname.toLowerCase(),
                height: unit.Height + unit.unitname.toLowerCase(),
                volume: unit.Cbm + unit.unitname.toLowerCase() + "³"
            });
        });

        var showPickup = false;
        var showDelivery = false;
        var showFreightForwarder = false;
        var showGroundHandlingOrigin = false;
        var showGroundHandlingDestination = false;

        if (ebooking.deliver_from == "ZipCode") {
            showPickup = true;
        }

        if (ebooking.deliver_from == "Airport") {
            showGroundHandlingOrigin = true;
        }

        if (ebooking.deliver_to == "ZipCode") {
            showDelivery = true;
        }
        if (ebooking.deliver_to == "Airport") {
            showGroundHandlingDestination = true;
        }

        if (ebooking.deliver_to_airport == "TLV") {
            showFreightForwarder = true;
        }
        if (auth.IsGSA) {
            steps[1].link = 'ebooking/participants-gsa';
        }
        this.setState({ steps, deliveryType: deliveryType, showPickup: showPickup, showDelivery: showDelivery, showFreightForwarder: showFreightForwarder, showGroundHandlingOrigin: showGroundHandlingOrigin, showGroundHandlingDestination: showGroundHandlingDestination, ebooking, dataDetails: dataDetails, costDatas: costDatas, pricingCase, cost2: cost2, total, totalExtra, totalCostWeight: totalCostWeight });
        //EOF - Doan này can mo
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    /* handler form's fields change value */
    async handleChange(event) {
        const { ebooking, errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        ebooking[name] = value;
        if (name == "checkbox_term_df") {
            errors["checkbox_term"] = "";
        }
        this.setState({ ebooking, errors });
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;
        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    isValidationWithField(event) {
        const { ebooking, errors } = this.state;
        var validator = new Validator();
        const { target } = event;
        var value = target.value;
        var isError = false;
        switch (target.name) {
        }
    }

    handleBook() {
        // console.log('book');
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        const { history } = this.props;
        var { ebooking } = this.props;

        if (ebooking.PriceQuotation && ebooking.PriceQuotation.pl.length > 0) {
            this.setState({ showingPopup: true, showingAgreement: true });
        } else if (this.props.auth.cass != "") {
            // history.push('/ebooking/payment-cass');
            this.setState({ showingPopup: true, showingAgreement: false });
            // this.submitBookData("CASS");
        } else if (this.props.auth.credit != "") {
            this.submitBookData("CREDIT");
        } else {
            window.jumpToTop();
            history.push('/ebooking/payment');
        }
        // history.push('/ebooking/payment');
    }

    popupClickSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { history } = this.props;
        if (this.props.auth.credit != "") {
            // history.push('/ebooking/payment-credit');
            this.submitBookData("CREDIT");
        } else if (this.props.auth.cass != "") {
            // history.push('/ebooking/payment-cass');
            this.submitBookData("CASS");
        } else {
            window.jumpToTop();
            history.push('/ebooking/payment');
        }
    }

    closePopup = (e) => {
        this.setState({ showingPopup: false, showingAgreement: false });
    }

    submitBookData = (cashType) => {
        var { history, auth } = this.props;
        var { ebooking, errorMessage, total } = this.state;
        this.setState({ errorMessage: "" });
        var routeFlight = {};
        var isexpress = "";
        var pl = 0;
        var parcelprice = 0.0;
        var parcelexpprice = 0.0;
        if ((ebooking.PriceQuotation.type == "WHITE" || ebooking.PriceQuotation.type == "PL") && ebooking.PriceQuotation.pl.length > 0) {
            routeFlight = ebooking.PricelistChoosed.selectedFlightData;
            delete routeFlight.uuid;
            delete routeFlight.duration;
            delete routeFlight.priceSort;
            delete routeFlight.expresspriceSort;
            pl = ebooking.PricelistChoosed.selectedPLData.plnum;
        } else if (ebooking.PriceQuotation.type == "WHITE") {
            routeFlight = ebooking.DefaultChoosed.flight;
            delete routeFlight.uuid;
            delete routeFlight.duration;
            delete routeFlight.priceSort;
            delete routeFlight.expresspriceSort;
            isexpress = ebooking.DefaultChoosed.priceType == "express" ? "Y" : "";
        } else if (ebooking.PriceQuotation.type == "PARCEL") {
            routeFlight = null;
            isexpress = ebooking.ParcelChoosed.priceType == "expressprice" ? "Y" : "";
            parcelprice = ebooking.PriceQuotation.price;
            parcelexpprice = ebooking.PriceQuotation.expressprice;
            pl = (ebooking.PriceQuotation.pl && ebooking.PriceQuotation.pl.length) ? ebooking.PriceQuotation.pl[0].plnum : 0;
        }

        if (pl != 0 && pl != "0") {
            ebooking.passedData.Plnum = pl;
        } else {
            ebooking.passedData.Plnum = "";
        }
        ebooking.passedData.AWB = ebooking.already_awb ? ebooking.awb_number_1 + "" + ebooking.awb_number_2 : "";
        ebooking.passedData.MustRide = isexpress;
        var bookData = {
            "currency": auth.cur,
            // "awb": "70060424674",            
            "authcode": "",
            "amount": total,
            "transid": "-1",
            "custname": this.props.auth.custname,
            // "custname": "11250006",
            "cust": this.props.auth.cust,
            // "cust": 0,
            "type": cashType,
            "isexpress": isexpress,
            "pl": pl,
            "parcelpl": 0,
            "parcelprice": parcelprice,
            "parcelexpprice": parcelexpprice,
            "Participents": {
                "SHIPNAME": ebooking.shippingCompanyName,
                "SHIPIATACODE": ebooking.shippingIATA1 + ebooking.shippingIATA2,
                "RECNAME": ebooking.receivingCompanyName,
                "RECIATACODE": ebooking.receivingIATA1 + ebooking.receivingIATA2,
                "CNORNUM": "",
                "CNORNAME": ebooking.consignorCompanyName,
                "CNORCOUNTRY": ebooking.consignorCountry,
                "CNORADDRESS": ebooking.consignorAddress,
                "CNORCITY": ebooking.consignorCity,
                "CNORPOSTALCODE": ebooking.consignorPostalCode,
                "CNEENUM": "",
                "CNEENAME": ebooking.consigneeCompanyName,
                "CNEECOUNTRY": ebooking.consigneeCountry,
                "CNEEADDRESS": ebooking.consigneeAddress,
                "CNEEPOSTALCODE": ebooking.consigneePostalCode,
                "CNEECITY": ebooking.consigneeCity,
                "PICKCOMPNAME": ebooking.pickupCompanyName ? ebooking.pickupCompanyName : "",
                "PICKNAME": (ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.warhsname && ebooking.consignorGroundHandlingData.warhsname != "") ? ebooking.consignorGroundHandlingData.warhsname : ebooking.pickupNameOfContact,
                "PICKEMAIL": ebooking.pickupEmail,
                "PICKPHONE": ebooking.pickupPhoneNumber,
                "PICKCOUNTRY": ebooking.pickupCountry,
                "PICKCITY": ebooking.pickupCity,
                "PICKADDRESS": ebooking.pickupAddress,
                "PICKPOSTALCODE": ebooking.pickupPostalCode,
                "DELCOMPNAME": ebooking.deliverCompanyName,
                "DELNAME": (ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.warhsname && ebooking.consigneeGroundHandlingData.warhsname != "") ? ebooking.consigneeGroundHandlingData.warhsname : ebooking.deliverNameOfContact,
                "DELEMAIL": ebooking.deliverEmail,
                "DELPHONE": ebooking.deliverPhoneNumber,
                "DELCOUNTRY": ebooking.deliverCountry,
                "DELCITY": ebooking.deliverCity,
                "DELADDRESS": ebooking.deliverAddress,
                "DELPOSTALCODE": ebooking.deliverPostalCode,
                "DELOPENHOURS": ebooking.deliverOpeningHours,
                "DELREMARKS": ebooking.deliverComments,
                "PICKOPENHOURS": ebooking.pickupOpeningHours,
                "PICKREMARKS": ebooking.pickupComments
            },
            "PQ": ebooking.passedData,
            "routes": routeFlight
        };

        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.setSafeCharge(bookData, this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                this.props.dashboardActions.resetDashboard();
                this.props.ebookingActions.ebookingChangeData({ fieldName: "docno", fieldValue: response.data.docno }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "webf", fieldValue: response.data.webf }).then(responseData => { });
                window.jumpToTop();
                history.push('/ebooking/success');
            } else if (!response.success && response.relogin) {
                this.setState({ errorMessage: "Session expired.", isError: true });
                window.jumpToTop();
            } else {
                this.setState({ errorMessage: "Booking Error. Plz try again.", isError: true });
                window.scrollToTop();
            }
        });
    }

    buildInstructions = (data) => {
        const partItems = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                var part = data[i];
                partItems.push(<span key={i}>#{part.value} - {part.label};</span>);
            }
        }
        return partItems;
    }

    buildParts = (data, currency) => {
        const partItems = [];
        for (var i = 0; i < data.length; i++) {
            var part = data[i];
            partItems.push(<tr key={i}>
                <td>{part.extra}</td>
                <td>{part.description}</td>
                <td>{BookingHelper.formatMoney(part.total)}<span>{currency}</span></td>
            </tr>);
        }
        return partItems;
    }

    getFlightName = (ebooking, parcel = false) => {
        var return_data = "";
        if (parcel) {
            if (ebooking.deliver_from == "Airport") {
                return_data += ebooking.deliver_from_airport + " - ";
            } else {
                return_data += (ebooking.deliver_to_airport == "TLV" ? "LGG" : "TLV") + " - ";
            }
            if (ebooking.deliver_to == "Airport") {
                return_data += ebooking.deliver_to_airport;
            } else {
                return_data += (ebooking.deliver_from_airport == "TLV" ? "LGG" : "TLV") + " - ";
            }
        } else {
            if (ebooking.PriceQuotation.pl.length) {
                return_data = ebooking.PricelistChoosed.selectedFlightData.fromport + " - " + ebooking.PricelistChoosed.selectedFlightData.toport;
                if (ebooking.PricelistChoosed.selectedFlightData.viaport && ebooking.PricelistChoosed.selectedFlightData.viaport != ebooking.PricelistChoosed.selectedFlightData.fromport && ebooking.PricelistChoosed.selectedFlightData.viaport != ebooking.PricelistChoosed.selectedFlightData.toport) {
                    return_data += "( VIA " + ebooking.PricelistChoosed.selectedFlightData.viaport + " )";
                }
            } else {
                return_data = ebooking.DefaultChoosed.flight.fromport + " - " + ebooking.DefaultChoosed.flight.toport;
                if (ebooking.DefaultChoosed.flight.viaport && ebooking.DefaultChoosed.flight.viaport != ebooking.DefaultChoosed.flight.fromport && ebooking.DefaultChoosed.flight.viaport != ebooking.DefaultChoosed.flight.toport) {
                    return_data += "( VIA " + ebooking.DefaultChoosed.flight.viaport + " )";
                }
            }
        }

        return return_data;
    }

    getFlightDetail = (ebooking, from = true) => {
        var return_data = [];
        //BOF - Doan này can mo
        if (ebooking.PriceQuotation.pl.length) {
            var flight = ebooking.PricelistChoosed.selectedFlightData.flights[0];
            if (!from) {
                flight = ebooking.PricelistChoosed.selectedFlightData.flights[ebooking.PricelistChoosed.selectedFlightData.flights.length - 1];
            }
            var dTime = BookingHelper.getDate(ebooking.PricelistChoosed.selectedFlightData.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(ebooking.PricelistChoosed.selectedFlightData.sta, "DD/MM/YYYY HH:mm");
            var duration = BookingHelper.getDurationNew(flight.duration);
            return_data.push(<p key={"fnumber"}>Flight {ebooking.PricelistChoosed.selectedFlightData.fnumber}</p>);
            return_data.push(<p key={"etd"}>ETD: {dTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"duration"}>Duration: {duration}</p>);
        } else {
            var flight = ebooking.DefaultChoosed.flight.flights[0];
            if (!from) {
                flight = ebooking.DefaultChoosed.flight.flights[ebooking.DefaultChoosed.flight.flights.length - 1];
            }
            var dTime = BookingHelper.getDate(ebooking.DefaultChoosed.flight.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(ebooking.DefaultChoosed.flight.sta, "DD/MM/YYYY HH:mm");
            var duration = BookingHelper.getDurationNew(flight.duration);
            return_data.push(<p key={"fnumber"}>Flight {ebooking.DefaultChoosed.flight.fnumber}</p>);
            return_data.push(<p key={"etd"}>ETD: {dTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
            return_data.push(<p key={"duration"}>Duration: {duration}</p>);
        }
        //EOF - Doan này can mo
        return return_data;
    }

    getFlightETD = (ebooking, eta = false) => {
        var return_data = "";
        if (ebooking.PriceQuotation.pl.length) {
            var dTime = BookingHelper.getDate(eta ? ebooking.PricelistChoosed.selectedFlightData.sta : ebooking.PricelistChoosed.selectedFlightData.finalstd, "DD/MM/YYYY HH:mm");
            return_data = dTime.format("DD/MM/YYYY HH:mm");

        } else {
            var dTime = BookingHelper.getDate(eta ? ebooking.DefaultChoosed.flight.sta : ebooking.DefaultChoosed.flight.finalstd, "DD/MM/YYYY HH:mm");
            return_data = dTime.format("DD/MM/YYYY HH:mm");
        }
        return return_data;
    }

    // getFlightETD = (ebooking, from = true, eta = false) => {
    //     var return_data = "";
    //     if (ebooking.PriceQuotation.pl.length) {
    //         var flight = ebooking.PricelistChoosed.selectedFlightData.flights[0];
    //         if (!from) {
    //             flight = ebooking.PricelistChoosed.selectedFlightData.flights[ebooking.PricelistChoosed.selectedFlightData.flights.length - 1];
    //         }
    //         var dTime = BookingHelper.getDate(eta ? flight.sta : flight.std, "DD/MM/YYYY HH:mm");
    //         return_data = dTime.format("DD/MM/YYYY HH:mm");
    //     } else {
    //         var flight = ebooking.DefaultChoosed.flight.flights[0];
    //         if (!from) {
    //             flight = ebooking.DefaultChoosed.flight.flights[ebooking.DefaultChoosed.flight.flights.length - 1];
    //         }
    //         var dTime = BookingHelper.getDate(eta ? flight.sta : flight.std, "DD/MM/YYYY HH:mm");
    //         return_data = dTime.format("DD/MM/YYYY HH:mm");
    //     }
    //     return return_data;
    // }

    getTruckDetail = (ebooking, from = true, ETD = true) => {
        var return_data = [];
        if (ebooking.PriceQuotation.pl.length) {
            if (from) {
                var flight = ebooking.PricelistChoosed.selectedFlightData.flights[0];
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fromport} - {flight.toport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            } else {
                var flight = ebooking.PricelistChoosed.selectedFlightData.flights[ebooking.PricelistChoosed.selectedFlightData.flights.length - 1];
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fromport} - {flight.toport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            }
        } else {
            if (from) {
                var flight = ebooking.DefaultChoosed.flight.flights[0];
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fromport} - {flight.toport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            } else {
                var flight = ebooking.DefaultChoosed.flight.flights[ebooking.DefaultChoosed.flight.flights.length - 1];
                if (flight) {
                    var aTime = BookingHelper.getDate(flight.sta, "DD/MM/YYYY HH:mm");
                    return_data.push(<p key={"truck-etd"}>{flight.fromport} - {flight.toport}</p>);
                    if (ETD)
                        return_data.push(<p key={"truck-eta"}>ETA: {aTime.format("DD/MM/YY HH:mm")}</p>);
                }
            }
        }
        return return_data;
    }

    buildFlightStep = () => {
        var { ebooking } = this.props;
        var parcelCase = false;
        var parcelFrom = false;
        var fromCalAirport = false;
        var toCalAirport = false;
        if ((ebooking.PriceQuotation && ebooking.PriceQuotation.type == "PARCEL")) {
            parcelCase = true;
            if (ebooking.deliver_to_airport == "TLV") {
                parcelFrom = false;
            } else {
                parcelFrom = true;
            }
        }
        if (ebooking.deliver_from == "Airport" && ebooking.deliver_from_airport_data.flightport == "Y") {
            fromCalAirport = true;
        }
        if (ebooking.deliver_to == "Airport" && ebooking.deliver_to_airport_data.flightport == "Y") {
            toCalAirport = true;
        }
        // parcelCase = true;
        // parcelFrom = false;
        // fromCalAirport = false;
        // toCalAirport = false;
        // console.log(parcelCase, parcelFrom, fromCalAirport, toCalAirport);
        if (parcelCase && parcelFrom) {
            //Flight - Truck
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p>{this.getFlightName(ebooking, true)}</p>
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3>&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${ebooking.consignorGroundHandlingData != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.label ? ebooking.consignorGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    {/* <h3>&nbsp;</h3> */}
                                    {/* <p>Pickup</p> */}
                                    <p className="blue-text font-weight-bold">In order to expedite processing, please send all the required document: an exporter invoice with a valid declaration, packing list or any other document required by customs</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    <p>ZIP: {ebooking.deliverPostalCode}</p>
                                    <p>{ebooking.deliverAddress}</p>
                                    <p>{ebooking.deliverCity}, {ebooking.deliverCountry}</p>
                                    <p className={` ${ebooking.deliverOpeningHours ? "" : "d-none"}`}>Open hours</p>
                                    <p>{ebooking.deliverOpeningHours}</p>
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Contact Details</p>
                                    <p>{ebooking.deliverCompanyName}</p>
                                    <p>{ebooking.deliverNameOfContact}</p>
                                    <p>{ebooking.deliverEmail}</p>
                                    <p>{ebooking.deliverPhoneNumber}</p>
                                    <p className={` ${ebooking.deliverComments ? "" : "d-none"}`}>{ebooking.deliverComments}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    {/* <h3>&nbsp;</h3> */}
                                    {/* <p>Pickup</p> */}
                                    <p className="blue-text font-weight-bold">After completing your booking, you will have the option to attach documents.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        } else if (parcelCase && !parcelFrom) {
            //Truck - Flight
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    <p>ZIP: {ebooking.pickupPostalCode}</p>
                                    <p>{ebooking.pickupAddress}</p>
                                    <p>{ebooking.pickupCity}, {ebooking.pickupCountry}</p>
                                    <p className={` ${ebooking.pickupOpeningHours ? "" : "d-none"}`}>Open hours</p>
                                    <p>{ebooking.pickupOpeningHours}</p>
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Contact Details</p>
                                    <p>{ebooking.pickupCompanyName}</p>
                                    <p>{ebooking.pickupNameOfContact}</p>
                                    <p>{ebooking.pickupEmail}</p>
                                    <p>{ebooking.pickupPhoneNumber}</p>
                                    <p className={` ${ebooking.pickupComments ? "" : "d-none"}`}>{ebooking.pickupComments}</p>
                                </div>
                                <div className="col-md-4 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    {/* <p>Pickup</p> */}
                                    <p className="blue-text font-weight-bold">CAL will contact your suplier with the email you provided in order to complete the information and documents, and schedule the pickup.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p>{this.getFlightName(ebooking, true)}</p>
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${ebooking.consigneeGroundHandling != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.label ? ebooking.consigneeGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.address ? ebooking.consigneeGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.city ? ebooking.consigneeGroundHandlingData.city : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.country ? ebooking.consigneeGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else if (fromCalAirport && toCalAirport) {
            //Flight Only
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE" && !ebooking.PriceQuotation.pl.length && ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(ebooking)}</p>
                                    <div>
                                        {this.getFlightDetail(ebooking)}
                                    </div>
                                </div>

                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${ebooking.consignorGroundHandlingData != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.label ? ebooking.consignorGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : ""}, {ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <p>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"}</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(ebooking)}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else if (!fromCalAirport && toCalAirport) {
            //Truck - Flight 
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(ebooking, true, false)}
                                </div>

                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${ebooking.consignorGroundHandlingData != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.label ? ebooking.consignorGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : ""}, {ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <p>Pickup</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(ebooking)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE" && !ebooking.PriceQuotation.pl.length && ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(ebooking)}</p>
                                    <div>
                                        {this.getFlightDetail(ebooking, false)}
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${ebooking.consigneeGroundHandling != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.label ? ebooking.consigneeGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.address ? ebooking.consigneeGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.city ? ebooking.consigneeGroundHandlingData.city : ""}, {ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.country ? ebooking.consigneeGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            );
        } else if (fromCalAirport && !toCalAirport) {
            //Flight - Truck
            return (
                <div className="col-12">
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE" && !ebooking.PriceQuotation.pl.length && ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(ebooking)}</p>
                                    <div>
                                        {this.getFlightDetail(ebooking)}
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${ebooking.consignorGroundHandlingData != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.label ? ebooking.consignorGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : ""}, {ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p>Comments</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(ebooking)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(ebooking, false)}
                                </div>
                                <div className="col-md-5 col-12">
                                    <p>Address</p>
                                    <div className={`${ebooking.consigneeGroundHandling != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.label ? ebooking.consigneeGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.address ? ebooking.consigneeGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.city ? ebooking.consigneeGroundHandlingData.city : ""}, {ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.country ? ebooking.consigneeGroundHandlingData.country : ""}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            //Truck - Flight - Truck
            return (
                <div className="col-12">

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Pickup From</h3>
                                    {this.getTruckDetail(ebooking, true, false)}
                                </div>

                                <div className="col-md-5 col-12 mb-3 mb-md-0">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${ebooking.consignorGroundHandlingData != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.label ? ebooking.consignorGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : ""}, {ebooking.consignorGroundHandlingData && ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : ""}</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    {/* <p>Pickup</p> */}
                                    <p>&nbsp;</p>
                                    <p className="blue-text font-weight-bold">Shipment should be at Warehouse no later than {this.getFlightETD(ebooking)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted_air">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <h3 className="font-weight-bold">Flight Details</h3>
                                    <p className={`font-weight-bold ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE" && !ebooking.PriceQuotation.pl.length && ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType == "express") ? '' : 'd-none'}`}>Express</p>
                                    <p>{this.getFlightName(ebooking)}</p>
                                    <div>
                                        {this.getFlightDetail(ebooking, false)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row pickup-item">
                        <div className="col-3 challenge_acepted">
                        </div>
                        <div className="col-9 border-right-5">
                            <div className="row">
                                <div className="col-md-3 col-12 mb-3 mb-md-0">
                                    <h3 className="font-weight-bold">Deliver To</h3>
                                    {this.getTruckDetail(ebooking, false)}
                                </div>
                                <div className="col-md-5 col-12">
                                    {/* <h3 className="font-weight-bold">&nbsp;</h3> */}
                                    <p>Address</p>
                                    <div className={`${ebooking.consigneeGroundHandling != "" ? "" : "d-none"} `}>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.label ? ebooking.consigneeGroundHandlingData.label : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.address ? ebooking.consigneeGroundHandlingData.address : ""}</p>
                                        <p>{ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.city ? ebooking.consigneeGroundHandlingData.city : ""}, {ebooking.consigneeGroundHandlingData && ebooking.consigneeGroundHandlingData.country ? ebooking.consigneeGroundHandlingData.country : ""}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }

    buildDGClass = () => {
        var { ebooking } = this.props;
        var classData = ebooking.class;
        if (classData.length) {
            return (
                <p className="text-normal">
                    Class: {classData.join(", ")}
                </p>
            );
        }
    }

    buildDGHandling = () => {
        var { ebooking } = this.props;
        var handlingData = ebooking.instructions;
        if (handlingData.length) {
            return (
                <div>
                    <p className="text-normal">
                        Handling Instructions:
                    </p>
                    {
                        handlingData.map((di, i) => {
                            return (
                                <p className="text-normal" key={`di-${i}`}>
                                    {di.label}
                                </p>
                            )
                        })
                    }
                </div>
            );
        }
    }

    render() {
        const { deliveryType, showingPopup, showingAgreement, steps, ebooking, isError, errors, errorMessage, dataDetails, costDatas, cost2, total, totalExtra, dataBooks, pricingCase, showDelivery, showPickup, showFreightForwarder }
            = this.state;
        var { auth } = this.props;
        var authState = auth.state;

        var parcelCase = false;
        var agreementCase = false;
        if ((ebooking.PriceQuotation && ebooking.PriceQuotation.type == "PARCEL")) {
            parcelCase = true;
            agreementCase = false;
        } else if (ebooking.PriceQuotation && ebooking.PriceQuotation.pl.length > 0) {
            agreementCase = true;
            parcelCase = false;
        } else {
            agreementCase = false;
            parcelCase = false;
        }

        return (

            <div className={`ebooking review-order ${showingPopup ? 'showing-popup' : ''}`}>
                <StepProgress stepData={steps} progress={100} onClick={this.handClickStep} />

                <div className="container">
                    {/* <Notification isError={isError} message={errorMessage} /> */}
                    <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                        </div>
                    </div>
                    <div className={`form-group ${errorMessage ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            There was an error in the process. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                        </div>
                    </div>
                </div>


                <div className="container mt-3 mt-md-5 mb-3 mb-md-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="page-title">Review Order</div>
                            <div className="page-description">This is a draft. The final order will be sent seperately.</div>
                            <div className={`page-description ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "PARCEL") ? "" : "d-none"}`}>{deliveryType == "economy" ? "Parcel service - delivery within 5 to 7 working days" : "Parcel Express service - delivery within 3 to 5 working days"}</div>
                            <div className="review-print d-none d-md-block">
                                <a type="button" className="btn-print" onClick={this.handlerPrint}></a>
                            </div>
                        </div>
                    </div>
                    {auth.IsGSA ? (
                        <div className='modal-content booking-modals bg-white'>
                            <div className='row text-center mt-3'>
                                <div className="col-12">
                                    <span className='gsa-custname'>GSA: {auth.custdes}</span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="container">
                    <div className="row">
                        {this.buildFlightStep()}
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row commodity-item mt-5">
                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                    <div className="display-table">
                                        <div className="display-table-cell">
                                            <h3>Commodity Details</h3>
                                            <p>{ebooking.commodity_type_name}</p>
                                            <p>{ebooking.sub_commodity_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="row">
                                        <div className={`${ebooking.units_list.length ? 'col-md-7 col-12' : 'col-12 d-none'}`}>
                                            <div className="row m-0 review-right-column">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className={`${ebooking.dimemtions_by === "ULD" ? "" : "d-none"}`}>ULD Type</th>
                                                            <th scope="col">Pieces</th>
                                                            <th scope="col">Lenght</th>
                                                            <th scope="col">Width</th>
                                                            <th scope="col">Height</th>
                                                            <th scope="col">Volume (CBM)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            ebooking.units_list.map((op, i) => {
                                                                return <tr key={i}>
                                                                    <td scope="row" className={`${ebooking.dimemtions_by === "ULD" ? "" : "d-none"}`}><span className="text-uppercase">{this.state.ulds[op.paltype]}</span></td>
                                                                    <td scope="row">{op.Pcs}</td>
                                                                    <td>{op.Length} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Width} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Height} <span>{op.unitname.toLowerCase()}</span></td>
                                                                    <td>{op.Cbm} <span>{op.unitname.toLowerCase()}³</span></td>
                                                                </tr>;
                                                            })
                                                        }
                                                        <tr className={`${ebooking.units_list.length ? 'd-none' : ''}`}>
                                                            <td> <div style={{ "minHeight": "20px" }}></div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className={`blue-text ${ebooking.units_list.length ? 'col-md-5 col-12 ' : 'col-12'}`}>
                                            <div className={`row m-0 review-right-column`}>
                                                <div className="display-table p-0 pl-md-3 pr-md-3">
                                                    <div className="display-table-cell table-units">
                                                        <p>Total Number of pieces: {ebooking.units_total_num_of_pieces}</p>
                                                        <p>Total Volume (CBM): {ebooking.units_total_volume}</p>
                                                        <p>Total Weight: {ebooking.units_total_weight} kg</p>
                                                        <p>Total Chargeable Weight: {ebooking.units_total_chargable_weight ? parseFloat(ebooking.units_total_chargable_weight).toFixed(1) : ""} kg</p>
                                                        <p className={`overlap ${(ebooking.overlap_shipment != 'NonOverlap' || ebooking.overlap_shipment_check) ? '' : 'd-none'}`}>Overlap</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`container ${ebooking.shipment_dangerous_goods ? '' : 'd-none'}`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row commodity-item mt-5">
                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                    <div className="display-table">
                                        <div className="display-table-cell">
                                            <h3>Dangerous Goods & Special Instructions</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="row">
                                        <div className={`col-md-12 col-12`}>
                                            <div className="row m-0 review-right-column">
                                                <p className="text-normal">
                                                    This shipment contains dangerous goods ({ebooking.shipment_dangerous_goods_type})
                                                </p>
                                                {this.buildDGClass()}
                                                <p className={`text-normal ${ebooking.un ? '' : 'd-none'}`}>
                                                    Un's: {ebooking.un}
                                                </p>
                                                <p className={`text-normal ${ebooking.shipment_lithium_batteries ? '' : 'd-none'}`}>
                                                    This shipment contains lithium batteries
                                                </p>
                                                <p className={`text-normal ${ebooking.shipment_unstackable ? '' : 'd-none'}`}>
                                                    This shipment is unstackable
                                                </p>
                                                {this.buildDGHandling()}

                                                <p className={`text-normal ${ebooking.additional_information ? '' : 'd-none'}`}>
                                                    Additional Information: {ebooking.additional_information}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="div-bg-grey mt-5 participants pt-4 pb-4">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12">
                                <div className=" row ">
                                    <div className="col-12 col-md-3 mb-3 mb-md-0">
                                        <div className="display-table">
                                            <div className="display-table-cell">
                                                <h3>Participants</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className={`row m-0 review-right-column mb-3`}>
                                            <div className={`col-md-6 ${(ebooking.shippingCompanyName.trim() !== '') ? '' : 'd-none'}`}>
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Shipping Agent</h3>
                                                    <p className={`${(ebooking.shippingCompanyName.trim()) ? "" : "d-none"}`}>
                                                        {ebooking.shippingCompanyName}
                                                    </p>
                                                    <p className={`${(ebooking.shippingIATA1.trim() && ebooking.shippingIATA2.trim()) ? "" : "d-none"}`}>
                                                        IATA Code: {ebooking.shippingIATA1}-{ebooking.shippingIATA2}
                                                    </p>
                                                    {ebooking.payingCustomerShipping ? (
                                                        <p className={`text-orange`}>
                                                            Paying Customer
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className={`col-md-6 ${(ebooking.receivingCompanyName.trim() !== '') ? '' : 'd-none'}`}>
                                                <div className="row">
                                                    <h3 className="font-weight-bold">{auth.IsGSA ? 'Freight Forwarder' : 'Receiving Agent/Notify'}</h3>
                                                    <p className={`${(ebooking.receivingCompanyName.trim()) ? "" : "d-none"}`}>
                                                        {ebooking.receivingCompanyName}
                                                    </p>
                                                    <p className={`${(ebooking.receivingIATA1.trim() && ebooking.receivingIATA2.trim()) ? "" : "d-none"}`}>
                                                        IATA Code: {ebooking.receivingIATA1}-{ebooking.receivingIATA2}
                                                    </p>
                                                    {ebooking.payingCustomerFreight ? (
                                                        <p className={`text-orange`}>
                                                            Paying Customer
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0 review-right-column">
                                            <div className="col-md-6 col-12 mb-3 mb-md-0">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignor/Shipper Details</h3>
                                                    <p className={`${(ebooking.consignorCompanyName.trim()) ? "" : "d-none"}`}>{ebooking.consignorCompanyName}</p>
                                                    <p className={`${(ebooking.consignorCountry.trim() && ebooking.consignorCity) ? "" : "d-none"}`}>
                                                        {ebooking.consignorCountry}, {ebooking.consignorCity}
                                                    </p>
                                                    <p className={`${(ebooking.consignorAddress.trim()) ? "" : "d-none"}`}>
                                                        Address: {ebooking.consignorAddress}
                                                    </p>
                                                    <p className={`${(ebooking.consignorPostalCode.trim()) ? "" : "d-none"}`}>
                                                        Postal Code: {ebooking.consignorPostalCode}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-12">
                                                <div className="row">
                                                    <h3 className="font-weight-bold">Consignee/Importer Details</h3>
                                                    <p className={`${(ebooking.consigneeCompanyName.trim()) ? "" : "d-none"}`}>{ebooking.consigneeCompanyName}</p>
                                                    <p className={`${(ebooking.consigneeCountry.trim() && ebooking.consigneeCity) ? "" : "d-none"}`}>
                                                        {ebooking.consigneeCountry}, {ebooking.consigneeCity}
                                                    </p>
                                                    <p className={`${(ebooking.consigneeAddress.trim()) ? "" : "d-none"}`}>
                                                        Address: {ebooking.consigneeAddress}
                                                    </p>
                                                    <p className={`${(ebooking.consigneePostalCode.trim()) ? "" : "d-none"}`}>
                                                        Postal Code: {ebooking.consigneePostalCode}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`container mt-5 ${parcelCase ? 'd-none' : ''}`}>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-3 mb-md-0">
                            <div className="display-table">
                                <div className="display-table-cell">
                                    {/* <h3 className="d-none d-md-block">
                                        Pricings /
                                    </h3>
                                    <h3 className="d-none d-md-block">
                                        Agreement
                                    </h3>
                                    <h3 className="d-block d-md-none">
                                        Pricings / Agreement
                                    </h3> */}
                                    <h3 className="">
                                        {agreementCase ? "Agreement" : "Pricings"}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className={`row m-0 review-right-column ${(ebooking.PriceQuotation && ebooking.PriceQuotation.pl.length > 0) ? '' : 'd-none'}`}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="19%">Agreement #</th>
                                            <th scope="col" width="27%">Consignee</th>
                                            <th scope="col" width="27%">Consignor</th>
                                            <th scope="col" width="27%">Recieving Agent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(ebooking.PricelistChoosed && ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.plnum) ? ebooking.PricelistChoosed.selectedPLData.plnum : ""}</td>
                                            <td>{(ebooking.PricelistChoosed && ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.cneedes) ? ebooking.PricelistChoosed.selectedPLData.cneedes : ""}</td>
                                            <td>{(ebooking.PricelistChoosed && ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.cnordes) ? ebooking.PricelistChoosed.selectedPLData.cnordes : ""}</td>
                                            <td>{(ebooking.PricelistChoosed && ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.recdes) ? ebooking.PricelistChoosed.selectedPLData.recdes : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={`row m-0 review-right-column ${(ebooking.PriceQuotation && ebooking.PriceQuotation.type == "WHITE" && ebooking.DefaultChoosed && ebooking.DefaultChoosed.priceType) ? '' : 'd-none'}`}>
                                <div className=" d-none d-md-block" style={{ "width": "100%" }}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Commodity Type</th>
                                                <th scope="col">Pieces</th>
                                                <th scope="col">Weight</th>
                                                <th scope="col">Chagrable Weight</th>
                                                <th scope="col">Volume (CBM)</th>
                                                <th scope="col">Rate per kG</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-white">
                                                <td>Consolidation</td>
                                                <td>{ebooking.units_total_num_of_pieces}</td>
                                                <td>{ebooking.units_total_weight} <span>kg</span></td>
                                                <td>{ebooking.units_total_chargable_weight}  <span>kg</span></td>
                                                <td>{ebooking.units_total_volume}</td>
                                                <td>{(ebooking.DefaultChoosed && ebooking.DefaultChoosed.price) ? BookingHelper.formatMoney(ebooking.DefaultChoosed.price) : ""}  <span>{auth.cur}</span></td>
                                                <td>{BookingHelper.formatMoney(this.state.totalCostWeight)} <span>{auth.cur}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className=" d-block d-md-none" style={{ "width": "100%" }}>
                                    <table className="table table-cost-mobile">
                                        <tbody>
                                            <tr className="row-grey">
                                                <td colSpan={2}>Commodity</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>Consolidation</td>
                                            </tr>
                                            <tr className="row-grey">
                                                <td># of Pieces</td>
                                                <td>Chargable Weight (kg)</td>
                                            </tr>
                                            <tr>
                                                <td>{ebooking.units_total_num_of_pieces}</td>
                                                <td>{ebooking.units_total_chargable_weight}</td>
                                            </tr>
                                            <tr className="row-grey">
                                                <td>Volume (CBM)</td>
                                                <td>Per KG rate</td>
                                            </tr>
                                            <tr>
                                                <td>{ebooking.units_total_volume}</td>
                                                <td>{(ebooking.DefaultChoosed && ebooking.DefaultChoosed.price) ? BookingHelper.currencySymbol(auth.cur) + BookingHelper.formatMoney(ebooking.DefaultChoosed.price) : ""}</td>
                                            </tr>
                                            <tr className="row-grey">
                                                <td colSpan={2}>Total</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}><span>{BookingHelper.formatMoney(this.state.totalCostWeight)}</span> {auth.cur}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`row m-0 review-right-column ${cost2.length ? "" : "d-none"}`}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Additional Charges</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.buildParts(cost2, auth.cur)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container no_padding mt-5">
                    <div className="row">
                        <div className="col-12 col-md-3">
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="footer-total div_center" style={{ "minWidth": "100%" }}>
                                <div className={`total-txt ${pricingCase != "PRICELIST" ? "" : "d-none"}`}>Total</div>
                                <div className={`total-price ${pricingCase != "PRICELIST" ? "" : "d-none"}`}>{BookingHelper.formatMoney(total)} <span>{auth.cur}</span></div>
                                <div className="clb"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-12">
                            <div className="footer-buttons pr-2 pr-md-0 float-right">
                                <div className="btn-process">
                                    <button type="button" onClick={this.handSubmit} disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} className="ml-3 btn btn-ebooking-orange">Continue to Booking</button>
                                </div>
                                <div className="btn-back-shipment">
                                    <Link className="btn btn-back" to={`${process.env.PUBLIC_URL}/ebooking/participants${auth.IsGSA ? '-gsa' : ''}`}>Back to Shipment Details</Link>
                                </div>
                                <div className="clb"></div>
                            </div>
                            <div className="clb"></div>


                        </div>
                    </div>
                </div>

                <div className={`popup-ebooking ${showingPopup ? '' : 'd-none'}`}>
                    <div className="modal-dialog modal-dialog-xlarge bg_blue">
                        <div className="modal-content">
                            <div className="modal-main">
                                <div className="modal-header">
                                    <button type="button" onClick={this.closePopup} className="button-back">Back to previous page</button>
                                </div>
                                <div className="modal-body">

                                    <table className="table-popup-content">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="div_center text-center" style={{ "maxWidth": "700px" }}>
                                                        {/* <Notification isError={isError} message={errorMessage} /> */}
                                                        <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                                                            <div className="alert alert-danger text-red" role="alert">
                                                                Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                                                            </div>
                                                        </div>
                                                        <div className={`form-group ${errorMessage ? '' : 'd-none'}`}>
                                                            <div className="alert alert-danger text-red" role="alert">
                                                                There was an error in the process. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="page-content text-center">
                                                        <div className="big-text">Payment Confirmation</div>
                                                        <div className="content-text-desc div_center mt-5" style={{ "maxWidth": "500px" }}>
                                                            Dear {auth.IsGSA && !ebooking.payingCustomer ? auth.user_name + ', ' + (auth.custdes ? auth.custdes.replace(auth.cassname, "") : "") : 'Customer'},
                                                        </div>
                                                        {!auth.IsGSA || (auth.IsGSA && !ebooking.payingCustomer) ? (
                                                            <div className={`content-text-desc div_center mt-4`} style={{ "maxWidth": "500px" }}>
                                                                Your order is being processed as we speak. The paying customer is {(!ebooking.selectedShippingReceiving || ebooking.selectedShippingReceiving === 'Shipping') ? ebooking.shippingCompanyName : ebooking.receivingCompanyName}. The payment amount of <span className="text-orange text-uppercase">{BookingHelper.formatMoney(total)} {auth.cur}</span> will be charged via CASS {auth.cassname} to IATA CODE - <span className="text-orange text-uppercase">{auth.iatacode.substring(0, 4)}-{auth.iatacode.substring(4, auth.iatacode.length)}</span>.
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className={`content-text-desc div_center mt-4 ${(showingPopup && showingAgreement) ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                                    Your order is being processed as we speak. The payment amount will be charged according to your agreement with CAL.
                                                                </div>
                                                                <div className={`content-text-desc div_center mt-4 ${(showingPopup && !showingAgreement) ? '' : 'd-none'}`} style={{ "maxWidth": "500px" }}>
                                                                    Your order is being processed as we speak. The payment amount of <span className="text-orange text-uppercase">{BookingHelper.formatMoney(total)} {auth.cur}</span> will be charged via CASS {auth.cassname} to IATA CODE - <span className="text-orange text-uppercase">{auth.iatacode.substring(0, 4)}-{auth.iatacode.substring(4, auth.iatacode.length)}</span>.
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="content-text-desc div_center mt-4" style={{ "maxWidth": "500px" }}>
                                                            Currency exchange to EUR will be based on the exchange rate on the date of the flight.
                                                        </div>
                                                        <div className="content-buttons div_center mt-5">
                                                            <button className="btn btn-orange" onClick={this.popupClickSubmit}>Confirm Payment</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        dashboardActions: bindActionCreators(dashboardActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrder);