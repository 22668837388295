import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import queryString from 'query-string';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import '../../css/react-tooltip.css';


class ScAWBPending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        var { history } = this.props;
        history.push('/ebooking/sc-success-final');
        // var params = this.props.location.search;
        // const parsedParams = queryString.parse(params);
        // console.log("parsedParams", parsedParams);
        // this.props.loadingActions.loadingChangeData(true);        
        // var custname = "", cust = -1;
        // if (this.props.auth.state == "LOGGED_IN") {           
        //     custname = this.props.auth.custname;
        //     cust = this.props.auth.cust;
        // }
        // var bookData = {
        //     "currency": parsedParams.currency,
        //     "awb": parsedParams.customData,
        //     "authcode": parsedParams.AuthCode,
        //     "amount": parsedParams.totalAmount,
        //     "transid": parsedParams.TransactionID,
        //     "custname": custname,
        //     "cust": cust,
        //     "type": "CASH",
        //     "isexpress": "",
        //     "pl": 0,
        //     "parcelpl": 0,
        //     "parcelprice": 0,
        //     "parcelexpprice": 0,
        //     "Participents": {
        //         "SHIPNAME": "",
        //         "SHIPIATACODE": "",
        //         "RECNAME": "",
        //         "RECIATACODE": "",
        //         "CNORNUM": "",
        //         "CNORNAME": "",
        //         "CNORCOUNTRY": "",
        //         "CNORADDRESS": "",
        //         "CNORCITY": "",
        //         "CNORPOSTALCODE": "",
        //         "CNEENUM": "",
        //         "CNEENAME": "",
        //         "CNEECOUNTRY": "",
        //         "CNEEADDRESS": "",
        //         "CNEEPOSTALCODE": "",
        //         "CNEECITY": "",
        //         "PICKNAME": "",
        //         "PICKEMAIL": "",
        //         "PICKPHONE": "",
        //         "PICKCOUNTRY": "",
        //         "PICKCITY": "",
        //         "PICKADDRESS": "",
        //         "PICKPOSTALCODE": "",
        //         "DELNAME": "",
        //         "DELEMAIL": "",
        //         "DELPHONE": "",
        //         "DELCOUNTRY": "",
        //         "DELCITY": "",
        //         "DELADDRESS": "",
        //         "DELPOSTALCODE": "",
        //     },
        //     "PriceQuotation": {},
        //     "routes": {}
        // };

        // this.props.ebookingActions.setSafeCharge(bookData, this.props.auth.jwtToken).then(response => {
        //     this.props.loadingActions.loadingChangeData(false);
        //     if (response.success && response.data.Success) {
        //         history.push('/ebooking/sc-success-final');
        //     } else {
        //         history.push('/ebooking/sc-submit-failure');
        //     }
        // });
    }

    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, costDatas, cost2, total }
            = this.state;

        return (
            <div className="container ebooking">
                Processing...
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScAWBPending);