import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import clone from 'clone';
/**
 * Validator
 */
import { bindActionCreators } from 'redux';

class ModalChatCounter extends React.Component {
    constructor(props) {
        super(props);
        const { itmChat } = this.props;
        let currentChat = clone(itmChat);
        this.state = {
            errors: {},
            currentChat,
            message: ""
        };

    }

    componentDidMount = () => {
        let { currentChat } = this.state;
    }

    handleChange = async (event) => {
        const { currentChat, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        value = value.replace(/[^0-9.]/g, "");
        var strArray = name.split("_");
        let key = strArray[1];
        if (currentChat.ChatData.OfferData[key].price !== value) {
            currentChat.ChatData.OfferData[key].isUpdated = true;
        }
        currentChat.ChatData.OfferData[key].price = value;
        if (value === '' || parseFloat(value) <= 0) {
            errors[name] = 'Invalid';
        } else {
            errors[name] = '';
        }
        this.setState({ currentChat, errors });
    }

    handleTextChange = (event) => {
        var { target } = event;
        this.setState({ message: target.value });

    }

    handleCounter = async () => {
        const { currentChat, message } = this.state;
        this.props.handleCounter(message, currentChat);
    }

    handleOnBlur = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        this.setState({ errors });
    }

    render() {
        const { onShow, description, itmChat } = this.props;
        let { currentChat, message } = this.state;
        var { errors } = this.state;
        if (!onShow)
            return (null);
        return (
            <div className="modal-dialog modal-dialog-medium modal-dialog-centered" role="document">
                <div className="modal-content quotes-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title modal-counter">
                            <h5 className="text-uppercase">Your Counter Offer</h5>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description modal-counter" style={{ 'maxWidth': '320px' }}>Type in your counter offer so we can understand what price you were thinking of.</div>
                            </div>
                        </div>
                        <div className="counter-items mt-4 pl-4 pr-4">
                            {currentChat.ChatData ? (                                
                                    currentChat.ChatData.OfferData.map((offer, i) => {                                       
                                        return (
                                            <div key={i} className="quote-flex display-flex mt-2 shadow">
                                                <div className="input-group-quote negotiation-popup no-radius group-pr-1 bg-grey">
                                                    <label>{offer.subcom}</label>
                                                </div>
                                                <div className="input-group-quote negotiation-popup no-radius group-pr-2 bg-grey">
                                                    <label>{offer.calc}</label>
                                                </div>
                                                <div className="input-group-quote negotiation-popup no-radius group-pr-3 bg-grey">
                                                    <label>{offer.curr}</label>
                                                </div>
                                                <div className="input-group-quote negotiation-popup no-radius group-pr-4">
                                                    <input type="text" className={`input-value ${errors['price_' + i] ? 'input-error' : ''}`} name={`price_${i}`} maxLength={9} value={offer.price} onChange={this.handleChange}></input>
                                                </div>
                                            </div>
                                        )
                                    })
                                
                            ) : null}


                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="pl-4 pr-4">
                                    <textarea className="form-control shadow" style={{ width: "100%", height: "100px", borderRadius: '10px', padding: '10px 20px' }} name="message" value={message} onChange={this.handleTextChange} placeholder="What else do we need to know?"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6"><span className="btn-clear cursor-pointer text-white" onClick={this.props.closeModal}>Cancel</span></div>
                                <div className="col-6 text-right"><span className="btn-done" onClick={this.handleCounter}>Create</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChatCounter);
