import React from 'react';
import PropTypes from 'prop-types';

class CurrencySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currencies: [
                {
                    name: 'USD',
                    key: 'USD',
                },
                {
                    name: 'Euro',
                    key: 'EUR',
                }
            ],
            current_value: 'USD'

        };
        this.selectOnChange = this.selectOnChange.bind(this);
    }

    selectOnChange = (event) => {
        const { target, target: { name, type } } = event;
        var value = target.value;
        this.setState({ current_value: value });        
        this.props.onChange(name, value);
    }

    render() {
        return (
            <select className={this.props.className} id={this.props.id} value={this.props.value} name={this.props.name} onChange={this.selectOnChange}>
                {this.state.currencies.map((currency, i) => {
                    return (<option key={i} value={currency.key}>{currency.name}</option>)
                })}

            </select>
        )
    }
}

CurrencySelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string
};

export default CurrencySelect;