import axios from 'axios';
import uuid from 'uuid';
import * as ActionTypes from '../actionTypes';
import ModelAPI from '../api';
import ModelAuth from '../auth';
import moment from 'moment';
import BookingHelper from '../../components/BookingHelper';
import AppConfig from '../../AppConfig';
import store from '../store';
import clone from 'clone';
import * as ebookingActions from './ebookingActions';

export function getCProfDetails(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getcprofdet',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getDC(typeDC = "C") {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getdc',
            method: 'GET',
            dataPass: { dc: typeDC }
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getBidExp(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getbidexp',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);

        return dataReturn;
    }
}

export function getCommodity(com) {

    return async (dispatch) => {

        var dataPass = {
            "functionName": 'getCommodities',
            "dataPass": {
                "com": com
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function scanCompany(k) {
    return async (dispatch, getState) => {
        var dataPass = {
            "functionName": 'scanCompany',
            "dataPass": k
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);

        if (dataReturn.success && dataReturn.data.length) {
            let searchRs = dataReturn.data;
            k = k.toUpperCase();
            searchRs = searchRs.filter(item => {
                var lastChar = item.label.substr(item.label.length - 2).toUpperCase(); // => "Tabs1"      
                return (lastChar !== "-C");
            }).sort((a, b) => {
                a.label = a.label.toUpperCase();
                b.label = b.label.toUpperCase();
                var lastCharA = a.label.substr(a.label.length - 2);
                var lastCharB = b.label.substr(b.label.length - 2);
                var index1 = a.label.indexOf(k + " ");
                var index2 = b.label.indexOf(k + " ");
                if (lastCharA === "-T" && lastCharB !== "-T") {
                    return -1;
                }

                if (index1 !== 0 && index2 === 0) {
                    return -1;
                } else if (index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var index1 = a.label.indexOf(k);
                var index2 = b.label.indexOf(k);
                if (index1 !== 0 && index2 === 0) {
                    return -1;
                } else if (index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var flag1 = a.label.includes(" " + k + " ");
                var flag2 = b.label.includes(" " + k + " ");
                if (index1 !== 0 && !flag1 && flag2) {
                    return -1;
                } else if (index1 !== 0 && flag1 && flag2 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var flag3 = a.label.includes(k);
                var flag4 = b.label.includes(k);
                if (index1 !== 0 && !flag1 && !flag3 && flag4) {
                    return -1;
                } else if (index1 !== 0 && !flag1 && flag3 && flag4 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                return 0;
            });

            dataReturn.data = searchRs.reverse();
        }

        return dataReturn;
    }
}

export function scanCust(k) {
    return async (dispatch, getState) => {
        var dataPass = {
            "functionName": 'scancust',
            "dataPass": k
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);

        if (dataReturn.success && dataReturn.data.length) {
            let searchRs = dataReturn.data;
            k = k.toUpperCase();
            searchRs = searchRs.filter(item => {
                var lastChar = item.label.substr(item.label.length - 2).toUpperCase(); // => "Tabs1"      
                return (lastChar !== "-C");
            }).sort((a, b) => {
                a.label = a.label.toUpperCase();
                b.label = b.label.toUpperCase();
                var lastCharA = a.label.substr(a.label.length - 2);
                var lastCharB = b.label.substr(b.label.length - 2);
                var index1 = a.label.indexOf(k + " ");
                var index2 = b.label.indexOf(k + " ");
                if (lastCharA === "-T" && lastCharB !== "-T") {
                    return -1;
                }

                if (index1 !== 0 && index2 === 0) {
                    return -1;
                } else if (index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var index1 = a.label.indexOf(k);
                var index2 = b.label.indexOf(k);
                if (index1 !== 0 && index2 === 0) {
                    return -1;
                } else if (index1 === 0 && index2 === 0 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var flag1 = a.label.includes(" " + k + " ");
                var flag2 = b.label.includes(" " + k + " ");
                if (index1 !== 0 && !flag1 && flag2) {
                    return -1;
                } else if (index1 !== 0 && flag1 && flag2 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                var flag3 = a.label.includes(k);
                var flag4 = b.label.includes(k);
                if (index1 !== 0 && !flag1 && !flag3 && flag4) {
                    return -1;
                } else if (index1 !== 0 && !flag1 && flag3 && flag4 && lastCharA === "-T" && lastCharB === "-T") {
                    return -1;
                }

                return 0;
            });

            dataReturn.data = searchRs.reverse();
        }

        return dataReturn;
    }
}

export function getBibFlights(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getbidflights',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getParticipent(custname, type, idToken) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getParticipent',
            "dataPass": {
                "custname": custname,
                "type": type
            }
        };
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass, configs);
        return dataReturn;
    }
}

export function getCurrencies() {
    return async (dispatch) => {
        var link = 'getting-data?type=currencies';
        let dataReturn = await ModelAPI.getMethod(link);
        if (dataReturn.success == true) {
            var currencies = [];
            dataReturn.data.data.map(currency => {
                if (currency.exchange > 0 && currency.code && currency.code !== '$') {
                    currencies.push({ value: currency.exchange, label: currency.code });
                }
            });

            var nis = currencies.find(item => item.label === "NIS");
            var usd = currencies.find(item => item.label === "USD");
            var eur = currencies.find(item => item.label === "EUR");
            currencies = currencies.filter(item => (item.label !== "NIS" && item.label !== "USD" && item.label !== "EUR"));
            if (eur) {
                currencies.unshift(eur);
            }
            if (usd) {
                currencies.unshift(usd);
            }
            if (nis) {
                currencies.unshift(nis);
            }
            dispatch({
                type: ActionTypes.CARGO_CURRENCIES_DATA,
                payload: currencies
            });
            return currencies;
        } else {
            dispatch({
                type: ActionTypes.CARGO_CURRENCIES_DATA,
                payload: []
            });
            return [];
        }
    }
}

export function quotesReset() {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.QUOTES_RESET,
            payload: {}
        })
        return { success: true }
    }
}

export function quotesUpdateData(quotes) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.QUOTES_UPDATE_DATA,
            payload: quotes
        })
        return { success: true }
    }
}

export function getDynamoProposal(secretkey) {
    return async (dispatch) => {
        var dataPass = {
            "functionName": 'getDynamoProposal',
            "dataPass": {
                "secretKey": secretkey
            }
        };
        let dataReturn = await ModelAPI.postMethod('call-cal-api', dataPass);
        return dataReturn;
    }
}

export function getCprof(params = null) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getcprof',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function getLoginData(params = null) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'getlogindata',
            method: 'GET',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function saveQuotesData(params, idToken) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'setcprofbid',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };

        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function updateQuoteStatus(params, idToken) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'updbidstat',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };

        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function sendEmail(params) {
    return async (dispatch, getState) => {
        var apiParams = {
            functionName: 'sendemail',
            dataPass: params
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function buildQuoteData(secretKey, quoteID, loggedIn = false) {
    var startDate = moment();
    startDate.subtract(12, 'months');
    var toDate = moment();
    toDate.add(5, 'days');

    return async (dispatch, getState) => {
        return new Promise(async (resolve) => {
            let getCProf = await this.getCprof({ fromdate: startDate.format('DD/MM/YY'), todate: toDate.format('DD/MM/YY'), CPROF: quoteID, SIM: 'N', userb: 0 });
            let getProposal = loggedIn ? [] : await this.getDynamoProposal(secretKey);
            let country = store.getState().country;
            let port = store.getState().port;
            let cachedCurrencies = store.getState().currencies;
            // console.log(cachedCurrencies);
            let quotes = {};
            let custname = '';
            let quotesData = null;
            let quotesStatus = '';
            let exprires = false;
            let customersData = [];
            let loadCustomers;

            let expriresTime = null;
            if (getCProf.success && getCProf.data.Success && getCProf.data.cp.length) {
                for (let cpi = 0; cpi < getCProf.data.cp.length; cpi++) {
                    const cp = getCProf.data.cp[cpi];
                    if (cp.CProfAU + "" === quoteID + "") {
                        quotesData = getCProf.data.cp[cpi];
                        quotesStatus = quotesData.sitestat;
                        let currentTime = moment();
                        expriresTime = moment(quotesData.enddate, 'DD/MM/YY HH:mm');
                        if (currentTime <= expriresTime) {
                            exprires = true;
                        }
                    }
                }
            }
            if (quotesData) {
                let getDCC = await this.getDC('P');
                let listCurrencies = cachedCurrencies.data;
                if (!cachedCurrencies.data.length || cachedCurrencies.state === ActionTypes.CARGO_CURRENCIES_GETTING_DATA) {
                    listCurrencies = await this.getCurrencies();
                }

                let countries = [];
                let countriesZip = [];
                country.countries.forEach(c => {
                    var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                    var countryZip = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                    if (c.airportonly) {
                        countryZip.label = c.countryname + " (airport only)";
                    }
                    countriesZip.push(countryZip);
                    countries.push(country);
                });
                quotes.secretKey = secretKey;
                quotes.quoteID = quoteID;
                quotes.apiData = quotesData;
                quotes.expriresTime = expriresTime;
                quotes.customer = null;
                quotes.customerTypes = [];
                quotes.subCommodityOptions = [];
                quotes.shippment_option = { value: 'PTP', label: 'PTP' };
                quotes.deliver_from = quotesData.FromZipCode.trim() ? 'ZipCode' : 'Airport';
                quotes.deliver_from_country = null;
                quotes.deliver_from_airport = null;
                quotes.deliver_from_city = quotesData.FromCity.trim();
                quotes.deliver_from_zipcode = quotesData.FromZipCode.trim();
                quotes.deliver_to = quotesData.ToZipCode.trim() ? 'ZipCode' : 'Airport';
                quotes.deliver_to_country = null;
                quotes.deliver_to_airport = null;
                quotes.deliver_to_city = quotesData.ToCity.trim();;
                quotes.deliver_to_zipcode = quotesData.ToZipCode.trim();
                quotes.pickup_dropoff = null;
                quotes.pickupOptions = [{ value: 'drop_off', label: 'Dropoff Time (Local)' }, { value: 'pickup', label: 'Pickup Time (Local)' }];
                quotes.pickup_dropoff_date = null;
                quotes.pickup_dropoff_time = null;
                //Step 2           
                quotes.selectedShippingReceiving = 'Shipping';
                quotes.receivingCompanyName = "";
                quotes.receivingIATA1 = "";
                quotes.receivingIATA2 = "";
                quotes.checkReceivingIATA = true;
                quotes.shippingCompanyName = "";
                quotes.shippingIATA1 = "";
                quotes.shippingIATA2 = "";
                quotes.populateImA = false;
                quotes.populateConsignor = false;
                quotes.populateConsignee = false;
                quotes.populateDelivery = false;
                quotes.populatePickup = false;
                quotes.consignorCompanyName = '';
                quotes.consignorAddress = '';
                quotes.consignorCity = '';
                quotes.consignorCountry = '';
                quotes.consignorGroundHandling = '';
                quotes.consignorGroundHandlingData = { warhsname: "" };
                quotes.consignorPostalCode = '';
                quotes.consigneeAddress = '';
                quotes.consigneeCity = '';
                quotes.consigneeCompanyName = '';
                quotes.consigneeCountry = '';
                quotes.consigneeGroundHandling = '';
                quotes.consigneeGroundHandlingData = { warhsname: "" };
                quotes.consigneePostalCode = '';
                quotes.pickupCheck = false;
                quotes.pickupAddress = '';
                quotes.pickupCity = '';
                quotes.pickupComments = '';
                quotes.pickupCountry = '';
                quotes.pickupEmail = '';
                quotes.pickupCompanyName = '';
                quotes.pickupNameOfContact = '';
                quotes.pickupOpeningHours = '';
                quotes.pickupPhoneNumber = '';
                quotes.pickupPostalCode = '';
                quotes.deliverAddress = '';
                quotes.deliverCheck = '';
                quotes.deliverCity = '';
                quotes.deliverComments = '';
                quotes.deliverCountry = '';
                quotes.deliverEmail = '';
                quotes.deliverCompanyName = '';
                quotes.deliverNameOfContact = '';
                quotes.deliverOpeningHours = '';
                quotes.deliverPhoneNumber = '';
                quotes.deliverPostalCode = '';

                quotes.pickupCheck = false;
                quotes.deliverCheck = false;
                quotes.pickupAddress = '';
                quotes.pickupCity = '';
                quotes.pickupComments = '';
                quotes.pickupCountry = '';
                quotes.pickupEmail = '';
                quotes.pickupCompanyName = '';
                quotes.pickupNameOfContact = '';
                quotes.pickupOpeningHours = '';
                quotes.pickupPhoneNumber = '';
                quotes.pickupPostalCode = '';
                quotes.deliverAddress = '';
                quotes.deliverCity = '';
                quotes.deliverComments = '';
                quotes.deliverCountry = '';
                quotes.deliverEmail = '';
                quotes.deliverCompanyName = '';
                quotes.deliverNameOfContact = '';
                quotes.deliverOpeningHours = '';
                quotes.deliverPhoneNumber = '';
                quotes.deliverPostalCode = '';
                quotes.deliver_from_airport_number = 0;
                quotes.deliver_to_airport_number = 0;
                quotes.offer_total_type = { label: "Totals", value: 'totals' };
                quotes.cost_total_type = { label: "Totals", value: 'totals' };
                quotes.cost_total_type_2 = { label: "Totals", value: 'totals' };
                quotes.dg_class = [];
                quotes.page_df = 1;
                quotes.checkbox_term_df = false;
                quotes.receivingParticipants = [];
                quotes.consigneeParticipants = [];
                quotes.consignorParticipants = [];
                quotes.shipping_company = null;
                quotes.receiving_company = null;
                quotes.consignor_company = null;
                quotes.consignee_company = null;
                quotes.airline = null;
                quotes.gsa = null;
                if (listCurrencies.length) {
                    let getCProfDetails = await this.getCProfDetails({ CPROF: quoteID });
                    if (getCProfDetails.success && getCProfDetails.data.Success) {
                        let SHIPNAME = quotesData.Participents.SHIPNAME.trim();
                        let SHIPIATACODE = quotesData.Participents.SHIPIATACODE ? quotesData.Participents.SHIPIATACODE.trim() : '';
                        let RECNAME = quotesData.Participents.RECNAME.trim();
                        let RECIATACODE = quotesData.Participents.RECIATACODE ? quotesData.Participents.RECIATACODE.trim() : '';
                        let CNORNAME = quotesData.Participents.CNORNAME.trim();
                        let CNEENAME = quotesData.Participents.CNEENAME.trim();
                        let PICKCOMPNAME = quotesData.Participents.PICKCOMPNAME.trim();
                        let DELNAME = quotesData.Participents.DELNAME.trim();
                        let DELCOMPNAME = quotesData.Participents.DELCOMPNAME.trim();

                        quotes.consignorCompanyName = quotesData.Participents.CNORNAME.trim();
                        quotes.consignorAddress = quotesData.Participents.CNORADDRESS ? quotesData.Participents.CNORADDRESS.trim() : '';
                        quotes.consignorCity = quotesData.Participents.CNORCITY ? quotesData.Participents.CNORCITY.trim() : '';
                        quotes.consignorCountry = quotesData.Participents.CNORCOUNTRY ? quotesData.Participents.CNORCOUNTRY.trim() : '';
                        quotes.consignorPostalCode = quotesData.Participents.CNORPOSTALCODE ? quotesData.Participents.CNORPOSTALCODE.trim() : '';

                        quotes.consigneeCompanyName = quotesData.Participents.CNEENAME.trim();
                        quotes.consigneeAddress = quotesData.Participents.CNEEADDRESS ? quotesData.Participents.CNEEADDRESS.trim() : '';
                        quotes.consigneeCity = quotesData.Participents.CNEECITY ? quotesData.Participents.CNEECITY.trim() : '';
                        quotes.consigneeCountry = quotesData.Participents.CNEECOUNTRY ? quotesData.Participents.CNEECOUNTRY.trim() : '';
                        quotes.consigneePostalCode = quotesData.Participents.CNEEPOSTALCODE ? quotesData.Participents.CNEEPOSTALCODE.trim() : '';

                        quotes.pickupCompanyName = quotesData.Participents.PICKCOMPNAME.trim();
                        quotes.pickupAddress = quotesData.Participents.PICKADDRESS ? quotesData.Participents.PICKADDRESS.trim() : '';
                        quotes.pickupCity = quotesData.Participents.PICKCITY ? quotesData.Participents.PICKCITY.trim() : '';
                        quotes.pickupComments = quotesData.Participents.PICKREMARKS ? quotesData.Participents.PICKREMARKS.trim() : '';
                        quotes.pickupCountry = quotesData.Participents.PICKCOUNTRY ? quotesData.Participents.PICKCOUNTRY.trim() : '';
                        quotes.pickupEmail = quotesData.Participents.PICKEMAIL ? quotesData.Participents.PICKEMAIL.trim() : '';
                        quotes.pickupOpeningHours = quotesData.Participents.PICKOPENHOURS ? quotesData.Participents.PICKOPENHOURS : '';
                        quotes.pickupPhoneNumber = quotesData.Participents.PICKPHONE ? quotesData.Participents.PICKPHONE.trim() : '';
                        quotes.pickupPostalCode = quotesData.Participents.PICKPOSTALCODE ? quotesData.Participents.PICKPOSTALCODE.trim() : '';
                        quotes.pickupNameOfContact = quotesData.Participents.PICKNAME ? quotesData.Participents.PICKNAME.trim() : '';

                        quotes.deliverCompanyName = quotesData.Participents.DELCOMPNAME.trim();
                        quotes.deliverAddress = quotesData.Participents.DELADDRESS ? quotesData.Participents.DELADDRESS.trim() : '';
                        quotes.deliverCity = quotesData.Participents.DELCITY ? quotesData.Participents.DELCITY.trim() : '';
                        quotes.deliverComments = quotesData.Participents.DELREMARKS ? quotesData.Participents.DELREMARKS.trim() : '';
                        quotes.deliverCountry = quotesData.Participents.DELCOUNTRY ? quotesData.Participents.DELCOUNTRY.trim() : '';
                        quotes.deliverEmail = quotesData.Participents.DELEMAIL ? quotesData.Participents.DELEMAIL.trim() : '';
                        quotes.deliverOpeningHours = quotesData.Participents.DELOPENHOURS ? quotesData.Participents.DELOPENHOURS.trim() : '';
                        quotes.deliverPhoneNumber = quotesData.Participents.DELPHONE ? quotesData.Participents.DELPHONE.trim() : '';
                        quotes.deliverPostalCode = quotesData.Participents.DELPOSTALCODE ? quotesData.Participents.DELPOSTALCODE.trim() : '';
                        quotes.deliverNameOfContact = quotesData.Participents.DELNAME ? quotesData.Participents.DELNAME.trim() : '';
                        quotes.isAwb = quotesData.AWB ? true : false;
                        quotes.awb_number_1 = quotesData.AWB ? quotesData.AWB.substr(0, 3).trim() : '700';
                        quotes.awb_number_2 = (quotesData.AWB && quotesData.AWB.length > 3) ? quotesData.AWB.substr(3, quotesData.AWB.length - 3).trim() : '';
                        //step 1        
                        quotes.status = quotesData.sitestat;
                        quotes.usercode = quotesData.usercode;
                        quotes.showEditIcon = true;
                        quotes.CProfAU = quotesData.CProfAU;
                        quotes.CProfNum = quotesData.CProfNum;
                        quotes.express_delivery = quotesData.MustRide.trim() === 'Y' ? true : false;
                        let CUSTNAME = quotesData.custname.trim();
                        if (CUSTNAME) {
                            let checkSearched = customersData.find(cd => cd.name === CUSTNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.scanCust(quotesData.custname.trim());
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: CUSTNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CUSTNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        findCustomer.dtType = 'dynamo';
                                        quotes.customer = findCustomer;
                                    }
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === CUSTNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    findCustomer.dtType = 'dynamo';
                                    quotes.customer = findCustomer;
                                }
                            }
                        }

                        quotes.offer_currency = null;
                        if (quotes.customer && quotes.customer.CURRENCY) {
                            quotes.offer_currency = listCurrencies.find(c => c.label === quotes.customer.CURRENCY);
                        } else {
                            quotes.offer_currency = listCurrencies.find(c => c.label === AppConfig.defaultCurrency);
                        }
                        quotes.shippment_option = quotesData.SaleTypeCode.trim() ? { value: quotesData.SaleTypeCode.trim(), label: quotesData.SaleTypeCode.trim() } : null;
                        quotes.deliver_from_country = { value: quotesData.FromCountryCode.trim(), label: quotesData.FromCountryCode.trim() }; //check again
                        quotes.deliver_from_airport = { value: quotesData.FromPortCode.trim(), label: quotesData.FromPortCode.trim() }; //check again
                        quotes.deliver_from_city = quotesData.FromCity.trim();
                        quotes.deliver_from_zipcode = quotesData.FromZipCode.trim();
                        quotes.deliver_to_country = { value: quotesData.ToCountryCode.trim(), label: quotesData.ToCountryCode.trim() }; //check again
                        quotes.deliver_to_airport = { value: quotesData.ToPortCode.trim(), label: quotesData.ToPortCode.trim() }; //check again
                        quotes.deliver_to_city = quotesData.ToCity.trim();
                        quotes.deliver_to_zipcode = quotesData.ToZipCode.trim();
                        quotes.pickup_dropoff = (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'PTD') ? { value: 'drop_off', label: 'Drop Off' } : { value: 'pickup', label: 'Pickup' };
                        quotes.pickup_dropoff_time = (quotesData.ETDOrigin.trim() !== '' && quotesData.ETDOrigin.trim() !== '01/01/88 00:00') ? moment(quotesData.ETDOrigin, 'DD/MM/YY HH:mm') : null;
                        quotes.pickup_dropoff_date = (quotesData.ETDOrigin.trim() !== '' && quotesData.ETDOrigin.trim() !== '01/01/88 00:00') ? moment(quotesData.ETDOrigin, 'DD/MM/YY HH:mm') : null;
                        quotes.t1 = quotesData.T1 ? true : false;

                        if (countries.length) {
                            var findCountryFrom = null;
                            if (quotesData.FromPortCode.trim()) {
                                findCountryFrom = countries.find(c => c.labelhiden === quotes.deliver_from_country.value);
                            } else {
                                findCountryFrom = countriesZip.find(c => c.labelhiden === quotes.deliver_from_country.value);
                            }
                            if (findCountryFrom) quotes.deliver_from_country = findCountryFrom;

                            var findCountryTo = null;
                            if (quotesData.ToPortCode.trim()) {
                                findCountryTo = countries.find(c => c.labelhiden === quotes.deliver_to_country.value);
                            } else {
                                findCountryTo = countriesZip.find(c => c.labelhiden === quotes.deliver_to_country.value);
                            }
                            if (findCountryTo) quotes.deliver_to_country = findCountryTo;
                        }

                        if (port.ports.length) {
                            if (quotesData.FromPortCode.trim()) {
                                var findAirportFrom = port.ports.find(el => el.value === quotesData.FromPortCode.trim());
                                if (findAirportFrom) {
                                    quotes.deliver_from_airport = findAirportFrom;
                                    quotes.deliver_from_airport_number = findAirportFrom.pnum;
                                }
                            }
                            if (quotesData.ToPortCode.trim()) {
                                var findAirportTo = port.ports.find(el => el.value === quotesData.ToPortCode.trim());
                                if (findAirportTo) {
                                    quotes.deliver_to_airport = findAirportTo;
                                    quotes.deliver_to_airport_number = findAirportTo.pnum;
                                }
                            }
                        }
                        quotes.overlap = quotesData.Overlap.trim() === 'Y' ? true : false;

                        quotes.unit_length = { value: 'cm', label: 'Cm' };

                        var selectedFlight = null;
                        // console.log(getCProfDetails.data.leg);
                        if (getCProfDetails.data.leg.length) {                           
                            getCProfDetails.data.leg = getCProfDetails.data.leg.sort((a,b) => {
                                const timeA = moment(a.eta, "DD/MM/YY H:mm").unix();
                                const timeB = moment(b.eta, "DD/MM/YY H:mm").unix();
                                return timeA - timeB;
                            });

                            selectedFlight = {
                                "fleg": getCProfDetails.data.leg[0],
                                "vialeg": { "leg": 0, "fnumber": null, "fport": null, "tport": null, "std": null, "sta": null },
                                "tleg": { "leg": 0, "fnumber": null, "fport": null, "tport": null, "std": null, "sta": null }
                            };
                            if (getCProfDetails.data.leg.length == 2) {
                                selectedFlight.tleg = getCProfDetails.data.leg[1];
                            }
                            if (getCProfDetails.data.leg.length > 2) {
                                selectedFlight.vialeg = getCProfDetails.data.leg[2];
                            }
                            var list_flights = [selectedFlight];
                            quotes.list_flight = list_flights;
                        }
                        quotes.selected_flight = selectedFlight;
                        quotes.selected_flight_review = null;
                        quotes.unit_items = [];
                        if (getCProfDetails.data.uld.length) {
                            quotes.unit_type = { label: 'ULD', value: 'uld' };
                            getCProfDetails.data.uld.forEach(unit => {
                                quotes.unit_length = { value: 'cm', label: 'Cm' };
                                var findUld = AppConfig.uldOptions.find(uld => uld.value === unit.paltype);
                                var itemUnit = { item_uld: (findUld ? findUld : null), item_pieces: unit.Pcs, item_length: '', item_width: '', item_height: '', item_volume: '', edit: false };
                                if (findUld && findUld.value === 'HD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "300";
                                } else if (findUld && findUld.value === 'HDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "300";
                                } else if (findUld && findUld.value === 'LD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "160";
                                } else if (findUld && findUld.value === 'LDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "160";
                                } else if (findUld && findUld.value === 'MD') {
                                    itemUnit.item_length = "317";
                                    itemUnit.item_width = "244";
                                    itemUnit.item_height = "240";
                                } else if (findUld && findUld.value === 'MDS') {
                                    itemUnit.item_length = "120";
                                    itemUnit.item_width = "100";
                                    itemUnit.item_height = "240";
                                }
                                itemUnit.item_volume = BookingHelper.calculatorVolume(itemUnit.item_pieces, itemUnit.item_length, itemUnit.item_width, itemUnit.item_height);
                                quotes.unit_items.push(itemUnit);
                            })
                        } else {
                            quotes.unit_type = { value: 'pieces', label: 'Pieces' };
                            getCProfDetails.data.Dimensions.forEach(unit => {
                                if (unit.unitname === 'CM') {
                                    quotes.unit_length = { value: 'cm', label: 'Cm' };
                                } else {
                                    quotes.unit_length = { value: 'in', label: 'Inch' };
                                }
                                let cbm = BookingHelper.calculatorVolume(unit.Pcs, unit.Length, unit.Width, unit.Height);
                                if (unit.paltype) {
                                    var findUld = AppConfig.uldOptions.find(uld => uld.value === unit.paltype);
                                    quotes.unit_items.push({ item_uld: (findUld ? findUld : null), item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                                } else {
                                    quotes.unit_items.push({ item_uld: null, item_pieces: unit.Pcs, item_length: unit.Length, item_width: unit.Width, item_height: unit.Height, item_volume: cbm, edit: false });
                                }
                            })
                        }

                        quotes.unit_total = { total_pieces: quotesData.Pieces, total_volume: quotesData.Volume, total_weight: quotesData.Weight, tcw: quotesData.CWeight };
                        getCProfDetails.data.cls.forEach(cla => {
                            var findClass = AppConfig.classOptions.find(cl => cl.value === cla.text);
                            if (findClass) {
                                quotes.dg_class.push(findClass);
                            }
                        })
                        let internal_text = getCProfDetails.data.ic.join(" ");
                        internal_text = internal_text.replace(/<br?\/?>/g, "\n");
                        internal_text = internal_text.replace(/(<([^>]+)>)/gi, ""); //remove html tags
                        quotes.internal_text = internal_text;

                        let uns = "";
                        for (let i = 0; i < getCProfDetails.data.un.length; i++) {
                            const un = getCProfDetails.data.un[i];
                            if (i < getCProfDetails.data.un.length - 1) {
                                uns += un.text + "\n";
                            } else {
                                uns += un.text;
                            }
                        }
                        quotes.dg_un = uns;
                        let additional_information = "";
                        for (let i = 0; i < getCProfDetails.data.add.length; i++) {
                            const addElement = getCProfDetails.data.add[i];
                            additional_information += addElement.text;

                        }
                        quotes.additional_information = additional_information;

                        quotes.handling = [];
                        for (let i = 0; i < getCProfDetails.data.hs.length; i++) {
                            const hsElement = getCProfDetails.data.hs[i];

                            if (hsElement.Delivertill && hsElement.Delivertill !== '' && hsElement.Delivertill !== '01/01/88 00:00') {
                                hsElement.DelivertillDate = moment(hsElement.Delivertill, 'DD/MM/YY HH:mm');
                            }

                            quotes.handling.push({ value: hsElement.Code, label: hsElement.Descreption, Delivertill: hsElement.DelivertillDate ? hsElement.DelivertillDate : null });
                        }

                        quotes.commodity = quotesData.Commodity.trim() ? { label: quotesData.Commodity.trim(), value: quotesData.Commodity.trim() } : null;
                        quotes.sub_commodity = quotesData.SubCommodity.trim() ? { label: quotesData.SubCommodity.trim(), value: quotesData.SubCommodity.trim() } : null;

                        if (quotes.commodity) {
                            var commodities = [];
                            var subCommodities = [];
                            var getCommodities = await this.getCommodity(0);
                            if (getCommodities.success && getCommodities.data.Comodities) {
                                commodities = getCommodities.data.Comodities;
                            }

                            let findSelectedCommodity = commodities.find(c => (c.PartName === quotes.commodity.label || c.PartDes === quotes.commodity.label));
                            if (findSelectedCommodity) {
                                quotes.commodity = { au: findSelectedCommodity.PartAU, label: findSelectedCommodity.PartDes, value: findSelectedCommodity.PartName };
                                if (quotes.sub_commodity) {
                                    var findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                    if (findSelectedSubCommodity) {
                                        quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                    } else {
                                        quotes.subCommodityOptions = [];
                                        var loadCommodites = await this.getCommodity(quotes.commodity.au);
                                        if (loadCommodites.success && loadCommodites.data.Success) {
                                            quotes.subCommodityOptions = loadCommodites.data.Comodities;
                                            subCommodities = quotes.subCommodityOptions;
                                        }
                                        findSelectedSubCommodity = subCommodities.find(c => (c.PartDes === quotes.sub_commodity.value || c.value === quotes.sub_commodity.value));
                                        if (findSelectedSubCommodity) {
                                            quotes.sub_commodity = { au: findSelectedSubCommodity.PartAU, label: findSelectedSubCommodity.PartDes, value: findSelectedSubCommodity.PartName };
                                        } else {
                                            if (quotes.subCommodityOptions.length == 1) {
                                                quotes.sub_commodity = { au: quotes.subCommodityOptions[0].PartAU, label: quotes.subCommodityOptions[0].PartDes, value: quotes.subCommodityOptions[0].PartName };;
                                            } else {
                                                quotes.sub_commodity = null;
                                            }
                                        }
                                    }
                                }
                            } else {
                                quotes.commodity = null;
                                quotes.sub_commodity = null;
                                quotes.subCommodityOptions = [];
                            }
                        } else {
                            quotes.commodity = null;
                            quotes.sub_commodity = null;
                            quotes.subCommodityOptions = [];
                        }
                        quotes.offer_price_list = [];
                        let firstOfferCalculation = null;
                        if (quotesData.calcmethod.toUpperCase() === 'PER UNIT') {
                            firstOfferCalculation = { value: "Per Unit", label: "Per Unit" };
                        } else if (quotesData.calcmethod.toUpperCase() === 'PER ULD') {
                            firstOfferCalculation = { value: "Per ULD", label: "Per ULD" };
                        } else if (quotesData.calcmethod.trim()) {
                            firstOfferCalculation = AppConfig.totalVolumeOptions.find(co => co.label.toUpperCase() === quotesData.calcmethod.toUpperCase());
                        }
                        let firstCurrencySelected = listCurrencies.find(c => c.label === quotesData.currency);
                        var firstPrice = parseFloat(quotesData.Price);
                        if (firstPrice > 0) {
                            quotes.offer_price_list.push({ items: (quotes.sub_commodity ? { value: quotes.sub_commodity.value, label: quotes.sub_commodity.value } : null), calculation_method: firstOfferCalculation, currency: firstCurrencySelected ? firstCurrencySelected : null, price: quotesData.Price, edit: false });
                        }

                        //get bid exp
                        let getBidExp = await this.getBidExp({ cprof: quoteID });
                        if (getBidExp.success && getBidExp.data.det.length) {
                            getBidExp.data.det.forEach(det => {
                                let checkOptionExist = getDCC.data.Comodities.find(dc => dc.PartName === det.subcom);
                                if (!checkOptionExist) {
                                    getDCC.data.Comodities.push({ PartAU: null, PartDes: det.subcomdes, PartName: det.subcom, label: det.subcom, value: det.subcom });
                                }
                            });
                        }

                        for (let i = 0; i < getCProfDetails.data.cp.length; i++) {
                            const cpElement = getCProfDetails.data.cp[i];
                            let itemSelected = getDCC.data.Comodities.find(po => po.PartName === cpElement.subcom);
                            let methodSelected = AppConfig.totalVolumeOptions.find(po => po.label === cpElement.calc);
                            let currencySelected = listCurrencies.find(c => c.label === cpElement.curr);
                            if (cpElement.calc.toUpperCase() === 'PER UNIT') {
                                methodSelected = { value: "Per Unit", label: "Per Unit" };
                            } else if (cpElement.calc.toUpperCase() === 'PER ULD') {
                                methodSelected = { value: "Per ULD", label: "Per ULD" };
                            }
                            quotes.offer_price_list.push({ items: itemSelected ? { label: itemSelected.PartName, value: itemSelected.PartName } : null, calculation_method: methodSelected ? methodSelected : null, currency: currencySelected ? currencySelected : null, price: cpElement.price, edit: true });
                        }

                        quotes.cost_price_list = [];

                        quotes.proposal_expises = (quotesData.enddate.trim() !== '' && quotesData.enddate.trim() !== '01/01/88 00:00') ? moment(quotesData.enddate, 'DD/MM/YY HH:mm') : moment().add(2, 'day');
                        if (!quotes.offer_price_list.length) {
                            quotes.offer_price_list.push({ items: null, calculation_method: null, currency: null, price: '', edit: true });
                        }

                        quotes.offer_all_in = quotesData.allin.trim() === 'Y' ? true : false;
                        let findCalcmethod = AppConfig.chargableOptions.find(co => co.label === quotesData.calcmethod);
                        quotes.cost_total_type_2 = findCalcmethod ? findCalcmethod : { label: "Totals", value: 'totals' };

                        //Step 4            
                        quotes.is_dangerous = (quotesData.Litium.trim() === 'Y' || getCProfDetails.data.cls.length) ? true : false;
                        quotes.is_lithium = quotesData.Litium.trim() === 'Y' ? true : false;
                        quotes.is_shipment_unstackable = quotesData.Unstackable.trim() === 'Y' ? true : false;
                        quotes.dg_cao_pax = quotesData.caopax.trim() ? 'PAX' : 'CAO'

                        if (SHIPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === SHIPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.scanCust(SHIPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: SHIPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomerShipname = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === SHIPNAME.toUpperCase()
                                    });
                                    if (findCustomerShipname) {
                                        findCustomerShipname.dtType = 'dynamo';
                                        quotes.shipping_company = findCustomerShipname;
                                        quotes.shipping_company_options = [findCustomerShipname];
                                        quotes.shippingCompanyName = findCustomerShipname.label;
                                    }
                                }

                            } else {
                                let findCustomerShipname = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === SHIPNAME.toUpperCase()
                                });
                                if (findCustomerShipname) {
                                    findCustomerShipname.dtType = 'dynamo';
                                    quotes.shipping_company = findCustomerShipname;
                                    quotes.shipping_company_options = [findCustomerShipname];
                                    quotes.shippingCompanyName = findCustomerShipname.label;
                                }
                            }
                            if (SHIPNAME && quotes.customer && SHIPNAME.toUpperCase() === quotes.customer.label.toUpperCase()) {
                                quotes.selectedShippingReceiving = 'Shipping';
                                quotes["shippingCompanyName"] = SHIPNAME;
                                quotes["shippingIATA1"] = SHIPIATACODE ? SHIPIATACODE.substr(0, 7).trim() : '';
                                quotes["shippingIATA2"] = (SHIPIATACODE && SHIPIATACODE.length > 7) ? SHIPIATACODE.substr(7, SHIPIATACODE.length - 7).trim() : '';
                            }

                        }

                        quotes.receiving_company_options = [];
                        if (RECNAME && quotes.customer) {
                            var getREC = await this.getParticipent({ custname: quotes.customer ? quotes.customer.CUSTNAME : '', type: 'REC' });
                            if (getREC.success && getREC.data.Success) {
                                getREC.data.customer.map(c => {
                                    quotes.receiving_company_options.push({ value: c.Cust, label: c.Custdes, dtType: 'api', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.receivingParticipants.push({ value: c.Cust, label: c.Custdes, dtType: 'api', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findCustomerRec = quotes.receiving_company_options.find(c => c.labelhiden.toUpperCase() === RECNAME.toUpperCase());
                            if (findCustomerRec) {
                                quotes.receiving_company = findCustomerRec;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === SHIPNAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.scanCust(RECNAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: RECNAME,
                                            data: loadCustomers.data.data
                                        });
                                        findCustomerRec = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === RECNAME.toUpperCase()
                                        });
                                        if (findCustomerRec) {
                                            findCustomerRec.dtType = 'dynamo';
                                            quotes.receiving_company = findCustomerRec;
                                            quotes.receiving_company_options = [findCustomerRec];
                                            quotes.receivingCompanyName = findCustomerRec.label;
                                        }
                                    }
                                } else {
                                    findCustomerRec = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === RECNAME.toUpperCase()
                                    });
                                    if (findCustomerRec) {
                                        findCustomerRec.dtType = 'dynamo';
                                        quotes.receiving_company = findCustomerRec;
                                        quotes.receiving_company_options = [findCustomerRec];
                                        quotes.receivingCompanyName = findCustomerRec.label;
                                    }
                                }
                            }

                            if (RECNAME && quotes.customer && RECNAME.toUpperCase() === quotes.customer.label.toUpperCase() && (!quotes.deliver_to_airport || !quotes.deliver_to_airport.value === "TLV")) {
                                quotes.selectedShippingReceiving = 'Receiving';
                                quotes["receivingCompanyName"] = RECNAME;
                                quotes["receivingIATA1"] = RECIATACODE ? RECIATACODE.substr(0, 7).trim() : '';
                                quotes["receivingIATA2"] = (RECIATACODE && RECIATACODE.length > 7) ? RECIATACODE.substr(7, RECIATACODE.length - 7).trim() : '';
                                quotes["shippingCompanyName"] = '';
                                quotes["receivingIATA1"] = '';
                                quotes["receivingIATA2"] = '';
                            }
                        }
                        quotes.consignee_company_options = [];
                        quotes.consignor_company_options = [];
                        if (CNEENAME && quotes.customer) {
                            var getCNEE = await this.getParticipent({ custname: quotes.customer.CUSTNAME, type: 'CNEE' });
                            if (getCNEE.success && getCNEE.data.Success) {
                                getCNEE.data.customer.map(c => {
                                    quotes.consignee_company_options.push({ value: c.Cust, label: c.Custdes, dtType: 'dynamo', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.consigneeParticipants.push({ value: c.Cust, label: c.Custdes, dtType: 'dynamo', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findConsignee = quotes.consignee_company_options.find(c => c.labelhiden === CNEENAME);
                            if (findConsignee) {
                                quotes.consignee_company = findConsignee;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === CNEENAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.scanCust(CNEENAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: CNEENAME,
                                            data: loadCustomers.data.data
                                        });
                                        findConsignee = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === CNEENAME.toUpperCase()
                                        });
                                        if (findConsignee) {
                                            findConsignee.dtType = 'dynamo';
                                            quotes.consignee_company = findConsignee;
                                            quotes.consignee_company_options = [findConsignee];
                                        }
                                    }
                                } else {
                                    findConsignee = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CNEENAME.toUpperCase()
                                    });
                                    if (findConsignee) {
                                        findConsignee.dtType = 'dynamo';
                                        quotes.consignee_company = findConsignee;
                                        quotes.consignee_company_options = [findConsignee];
                                    }
                                }
                                if (quotes.consignee_company) {
                                    quotes.consigneeCountry = quotes.consigneeCountry ? quotes.consigneeCountry : (quotes.consignee_company.COUNTRY ? quotes.consignee_company.COUNTRY : quotes.consignee_company.country);
                                    quotes.consigneeCity = quotes.consigneeCity ? quotes.consigneeCity : (quotes.consignee_company.CITY ? quotes.consignee_company.CITY : quotes.consignee_company.city);
                                }
                            }
                        }
                        if (CNORNAME && quotes.customer) {
                            var getCNOR = await this.getParticipent({ custname: quotes.customer.CUSTNAME, type: 'CNOR' });
                            if (getCNOR.success && getCNOR.data.Success) {
                                getCNOR.data.customer.map(c => {
                                    quotes.consignor_company_options.push({ value: c.Cust, label: c.Custdes, dtType: 'dynamo', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                    quotes.consignorParticipants.push({ value: c.Cust, label: c.Custdes, dtType: 'dynamo', labelhiden: c.Custdes, custname: c.Custname, cust: c.Cust, currency: "", country: c.countryname, city: c.cityname, contacts: [] });
                                });
                            }

                            let findConsignor = quotes.consignor_company_options.find(c => c.labelhiden === CNORNAME);
                            if (findConsignor) {
                                quotes.consignor_company = findConsignor;
                            } else {
                                let checkSearched = customersData.find(cd => cd.name === CNORNAME);
                                if (!checkSearched) {
                                    loadCustomers = await this.scanCust(CNORNAME);
                                    if (loadCustomers.success && loadCustomers.data.data.length) {
                                        customersData.push({
                                            name: CNORNAME,
                                            data: loadCustomers.data.data
                                        });
                                        findConsignor = loadCustomers.data.data.find(c => {
                                            let currentCustName = c.CUSTDES.trim();
                                            return currentCustName.toUpperCase() === CNORNAME.toUpperCase()
                                        });
                                        if (findConsignor) {
                                            findConsignor.dtType = 'dynamo';
                                            quotes.consignor_company = findConsignor;
                                            quotes.consignor_company_options = [findConsignor];
                                        }
                                    }
                                } else {
                                    findConsignor = checkSearched.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === CNORNAME.toUpperCase()
                                    });
                                    if (findConsignor) {
                                        findConsignor.dtType = 'dynamo';
                                        quotes.consignor_company = findConsignor;
                                        quotes.consignor_company_options = [findConsignor];
                                    }
                                }

                            }
                            if (quotes.consignor_company) {
                                quotes.consignorCountry = quotes.consignorCountry ? quotes.consignorCountry : (quotes.consignor_company.COUNTRY ? quotes.consignor_company.COUNTRY : quotes.consignor_company.country);
                                quotes.consignorCity = quotes.consignorCity ? quotes.consignorCity : (quotes.consignor_company.CITY ? quotes.consignor_company.CITY : quotes.consignor_company.city);
                            }
                        }
                        if (PICKCOMPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === PICKCOMPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.scanCust(PICKCOMPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: PICKCOMPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => c.CUSTDES.toUpperCase() === PICKCOMPNAME.toUpperCase());
                                    if (findCustomer) {
                                        findCustomer.dtType = 'dynamo';
                                        quotes.airline = findCustomer;
                                        quotes.airline_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: PICKCOMPNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => c.CUSTDES.toUpperCase() === PICKCOMPNAME.toUpperCase());
                                if (findCustomer) {
                                    findCustomer.dtType = 'dynamo';
                                    quotes.airline = findCustomer;
                                    quotes.airline_options = [findCustomer];
                                }
                            }
                            if (quotes.airline) {
                                quotes.pickupCountry = quotes.pickupCountry ? quotes.pickupCountry : (quotes.airline.COUNTRY ? quotes.airline.COUNTRY : quotes.airline.country);
                                quotes.pickupCity = quotes.pickupCity ? quotes.pickupCity : (quotes.airline.CITY ? quotes.airline.CITY : quotes.airline.city);
                            }
                        }
                        if (DELNAME) {
                            let checkSearched = customersData.find(cd => cd.name === DELNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.scanCust(DELNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: DELNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === DELNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        findCustomer.dtType = 'dynamo';
                                        quotes.gsa = findCustomer;
                                        quotes.gsa_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: DELNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === DELNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    findCustomer.dtType = 'dynamo';
                                    quotes.gsa = findCustomer;
                                    quotes.gsa_options = [findCustomer];
                                }
                            }
                            if (quotes.gsa) {
                                quotes.deliverCountry = quotes.deliverCountry ? quotes.deliverCountry : (quotes.gsa.COUNTRY ? quotes.gsa.COUNTRY : quotes.gsa.country);
                                quotes.deliverCity = quotes.deliverCity ? quotes.deliverCity : (quotes.gsa.CITY ? quotes.gsa.CITY : quotes.gsa.city);
                            }
                        }
                        if (DELCOMPNAME) {
                            let checkSearched = customersData.find(cd => cd.name === DELCOMPNAME);
                            if (!checkSearched) {
                                loadCustomers = await this.scanCust(DELCOMPNAME);
                                if (loadCustomers.success && loadCustomers.data.data.length) {
                                    customersData.push({
                                        name: DELCOMPNAME,
                                        data: loadCustomers.data.data
                                    });
                                    let findCustomer = loadCustomers.data.data.find(c => {
                                        let currentCustName = c.CUSTDES.trim();
                                        return currentCustName.toUpperCase() === DELCOMPNAME.toUpperCase()
                                    });
                                    if (findCustomer) {
                                        findCustomer.dtType = 'dynamo';
                                        quotes.feeder_gsa = findCustomer;
                                        quotes.feeder_gsa_options = [findCustomer];
                                    }
                                } else {
                                    customersData.push({
                                        name: DELCOMPNAME,
                                        data: []
                                    });
                                }
                            } else {
                                let findCustomer = checkSearched.data.find(c => {
                                    let currentCustName = c.CUSTDES.trim();
                                    return currentCustName.toUpperCase() === DELCOMPNAME.toUpperCase()
                                });
                                if (findCustomer) {
                                    findCustomer.dtType = 'dynamo';
                                    quotes.feeder_gsa = findCustomer;
                                    quotes.feeder_gsa_options = [findCustomer];
                                }
                            }
                        }

                        var totalsValues = BookingHelper.calculatorTotal(quotes.offer_price_list, quotes.offer_total_type, quotes.cost_price_list, quotes.cost_total_type, quotes.cost_total_type_2, quotes.unit_total, quotes.offer_currency);
                        quotes.offer_total = totalsValues.offer_total;
                        quotes.cost_total = totalsValues.cost_total;
                        quotes.cost_total_2 = totalsValues.cost_total_2;
                        quotes.step_1 = true;
                        quotes.step_2 = true;
                        quotes.unit_total = { total_pieces: quotesData.Pieces, total_volume: quotesData.Volume, total_weight: quotesData.Weight, tcw: quotesData.CWeight };
                        if (quotes.offer_price_list.length && quotes.offer_total) {
                            quotes.step_3 = true;
                        }
                        quotes.step_4 = true;
                        quotes.step_5 = true;

                        var getFlightParams = {};
                        var compareDate = moment().add(2, 'days');
                        compareDate.set('hour', 0);
                        compareDate.set('minute', 0);
                        compareDate.set('second', 0);
                        var startFlightFilter = moment();
                        startFlightFilter.set('hour', 0);
                        startFlightFilter.set('minute', 0);
                        startFlightFilter.set('second', 0);
                        // console.log(quotes);
                        if (quotes.pickup_dropoff_date) {
                            let toDate = clone(quotes.pickup_dropoff_date);
                            toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');

                            let fromDate = clone(quotes.pickup_dropoff_date);
                            fromDate.add(1, 'days');
                            if (fromDate < compareDate) {
                                fromDate = compareDate;
                            }
                            getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            getFlightParams.todate = toDate.format('DD/MM/YY');

                            //test xong xoa
                            // var fromDate = moment('01/10/2020', 'DD/MM/YYYY HH:mm');
                            // getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            // toDate = moment('31/12/2021', 'DD/MM/YYYY HH:mm');
                            // toDate.add(1, 'days');
                            // getFlightParams.todate = toDate.format('DD/MM/YY');
                            //test xong xoa                            
                        } else if (selectedFlight && selectedFlight.fleg) {
                            let fromDate = moment();
                            // fromDate.subtract(1, 'day');
                            let toDate = moment(selectedFlight.fleg.std, 'DD/MM/YY HH:mm');

                            //test xong xoa
                            // fromDate = moment('01/10/2020', 'DD/MM/YYYY HH:mm');
                            // toDate = moment('15/12/2021', 'DD/MM/YYYY HH:mm');
                            //test xong xoa
                            toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                            if (fromDate < compareDate) {
                                fromDate = compareDate;
                            }
                            getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            getFlightParams.todate = toDate.format('DD/MM/YY');
                        } else if (quotesData.PDate) {
                            let fromDate = moment(quotesData.PDate, 'DD/MM/YY');
                            // fromDate.subtract(1, 'day');                            
                            let toDate = clone(fromDate);
                            toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                            if (fromDate < compareDate) {
                                fromDate = compareDate;
                            }
                            getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            getFlightParams.todate = toDate.format('DD/MM/YY');

                            //test xong xoa
                            // var fromDate = moment('01/10/2020', 'DD/MM/YYYY HH:mm');
                            // getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            // toDate = moment('31/12/2021', 'DD/MM/YYYY HH:mm');
                            // toDate.add(1, 'days');
                            // getFlightParams.todate = toDate.format('DD/MM/YY');
                            //test xong xoa                            
                        } else {
                            // console.log('vao day 4');
                            let fromDate = moment();
                            // fromDate.subtract(1, 'day');
                            let toDate = moment();
                            toDate = toDate.add(AppConfig.dayRangeGetFlight, 'days');
                            if (fromDate < compareDate) {
                                fromDate = compareDate;
                            }
                            getFlightParams.fromdate = fromDate.format('DD/MM/YY');
                            getFlightParams.todate = toDate.format('DD/MM/YY');
                        }

                        if (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'PTD') {
                            getFlightParams.fromport = quotesData.FromPortCode;
                            getFlightParams.fcountry = quotesData.FromCountryCode;
                        } else {
                            getFlightParams.fromport = quotesData.FromZipCode;
                            getFlightParams.fcountry = quotesData.FromCountryCode;
                        }

                        if (quotesData.SaleTypeCode === 'PTP' || quotesData.SaleTypeCode === 'DTP') {
                            getFlightParams.toport = quotesData.ToPortCode;
                            getFlightParams.tcountry = quotesData.ToCountryCode;
                        } else {
                            getFlightParams.toport = quotesData.ToZipCode;
                            getFlightParams.tcountry = quotesData.ToCountryCode;
                        }
                        var res = await this.getBibFlights(getFlightParams);

                        // console.log("res", res);
                        // console.log('selectedFlight', selectedFlight);
                        quotes.routes = [];
                        if (res.success && res.data.Success) {
                            var list_flight = res.data.fl;
                            for (let i = 0; i < list_flight.length; i++) {
                                const flightData = list_flight[i];
                                let flightNums = [flightData.fleg.leg];
                                if (flightData.tleg.leg) {
                                    flightNums.push(flightData.tleg.leg);
                                }
                                if (flightData.vialeg.leg) {
                                    flightNums.push(flightData.vialeg.leg);
                                }


                                var std = flightData.fleg.std;
                                var sta = flightData.fleg.sta;
                                if (flightData.tleg && flightData.tleg.sta) {
                                    sta = flightData.tleg.sta;
                                }
                                var dStd = BookingHelper.getDate(std, "DD/MM/YYYY HH:mm");
                                var dSta = BookingHelper.getDate(sta, "DD/MM/YYYY HH:mm");
                                var wdStd = BookingHelper.getWeekday(dStd);
                                var dStdSort = BookingHelper.getDate(std, "DD/MM/YYYY HH:mm");
                                var dStaSort = BookingHelper.getDate(sta, "DD/MM/YYYY HH:mm");
                                var duration = BookingHelper.getDuration(dStd, dSta);
                                list_flight[i].uuid = uuid();
                                list_flight[i].duration = duration;
                                list_flight[i].wdStd = wdStd;
                                list_flight[i].std = std ? std : "";
                                list_flight[i].sta = sta ? sta : "";
                                list_flight[i].stdSort = std ? dStdSort.format("YYYY/MM/DD HH:mm") : "";
                                list_flight[i].staSort = std ? dStaSort.format("YYYY/MM/DD HH:mm") : "";
                                list_flight[i].priceSort = list_flight[i].price + "";
                                list_flight[i].expresspriceSort = list_flight[i].expressprice + "";

                                if (selectedFlight) {
                                    if (flightNums.includes(selectedFlight.fleg.leg) && (!selectedFlight.tleg.leg || flightNums.includes(selectedFlight.tleg.leg)) && (!selectedFlight.vialeg.leg || flightNums.includes(selectedFlight.vialeg.leg))) {
                                        quotes.selected_flight = flightData;
                                        quotes.selected_flight_review = list_flight[i];
                                    }
                                    // if ((selectedFlight.fleg.leg === flightData.fleg.leg) && (selectedFlight.tleg.leg === flightData.tleg.leg) && (selectedFlight.vialeg.leg === flightData.vialeg.leg)) {
                                    //     quotes.selected_flight = flightData;
                                    //     quotes.selected_flight_review = list_flight[i];
                                    // }
                                }
                            }
                            quotes.routes = list_flight;
                        }

                        quotes.selectedContacts = [
                            { phone: quotesData.phone, Name: quotesData.contactname, Jobtitle: '', Email: quotesData.contactemail.trim().toLowerCase() }
                        ];

                        if (quotes.customer) {
                            let phones = getCProfDetails.data.phone;
                            for (let i = 0; i < phones.length; i++) {
                                const p = phones[i];
                                quotes.selectedContacts.push({ phone: p.phone, Name: p.name, Jobtitle: '', Email: p.email.trim().toLowerCase() });
                            }
                        }


                        var totalsValues = BookingHelper.calculatorTotal(quotes.offer_price_list, quotes.offer_total_type, quotes.cost_price_list, quotes.cost_total_type, quotes.cost_total_type_2, quotes.unit_total, quotes.offer_currency);
                        quotes.offer_total = totalsValues.offer_total;
                        quotes.cost_total = totalsValues.cost_total;
                        quotes.cost_total_2 = totalsValues.cost_total_2;
                        // console.log("quotes", quotes);
                        // await this.props.quotesUpdateData(quotes);
                        resolve({ success: true, quotesData: quotes, proposalData: (getProposal.success && getProposal.data.Success && getProposal.data.data.length) ? getProposal.data.data : [], exprires, listCurrencies })
                    } else {
                        resolve({ success: false, quotesData: null, listCurrencies: [] })
                    }
                } else {
                    resolve({ success: false, quotesData: null, listCurrencies: [] });
                }
            } else {
                resolve({ success: false, quotesData: null, listCurrencies: [] })
            }
        });
    }
}

export function buildQuoteSaveData(quotes) {
    return async (dispatch, getState) => {
        var returnData = { cp: {}, Dimensions: [], cpdet: [], exp: [], hs: [], uld: [], phone: [], leg: [], add: [], cls: [], un: [], ic: [] };
        var legs = [];
        if (quotes.selected_flight) {
            legs.push({
                "leg": quotes.selected_flight.fleg.leg
            });
            if (quotes.selected_flight.vialeg.leg) {
                legs.push({
                    "leg": quotes.selected_flight.vialeg.leg
                });
            }
            if (quotes.selected_flight.tleg.leg) {
                legs.push({
                    "leg": quotes.selected_flight.tleg.leg
                });
            }
        }
        let pickup_type = '';
        if (quotes.pickupOptions.value === 'drop_off') {
            pickup_type = 'drop off';
        } else if (quotes.pickupOptions.value === 'must_ride_till') {
            pickup_type = 'must ride till';
        } else if (quotes.pickupOptions.value === 'pickup') {
            pickup_type = 'pickup date';
        }

        let Dimensions = [];
        let uld = [];
        if (quotes.unit_type.value === 'uld') {
            for (let i = 0; i < quotes.unit_items.length; i++) {
                let un = quotes.unit_items[i];
                let item_pieces = un.item_pieces !== '' ? parseInt(un.item_pieces) : '';
                let uldType = un.item_uld ? un.item_uld.value : null;
                if (uldType && item_pieces !== '') {
                    uld.push({ "Pcs": parseInt(item_pieces), "paltype": uldType });
                }
            }
        } else {
            for (let i = 0; i < quotes.unit_items.length; i++) {
                let un = quotes.unit_items[i];
                let item_length = un.item_length !== '' ? parseFloat(un.item_length).toFixed(2) : '';
                let item_width = un.item_width !== '' ? parseFloat(un.item_width).toFixed(2) : '';
                let item_height = un.item_height !== '' ? parseFloat(un.item_height).toFixed(2) : '';
                let item_volume = un.item_volume !== '' ? parseFloat(un.item_volume).toFixed(2) : '';
                let item_pieces = un.item_pieces !== '' ? parseInt(un.item_pieces) : '';
                if (item_length !== '' || item_width !== '' || item_height !== '' || item_volume !== '') {
                    Dimensions.push({ "Height": item_height, "Width": item_width, "Length": item_length, "Pcs": item_pieces, "unitname": quotes.unit_length.value.toUpperCase(), "Weight": item_volume, "paltype": un.item_uld ? un.item_uld.value : "" });
                }
            }
        }

        let add = [];
        let exp = [];
        let hs = [];
        let cls = [];
        let uns = [];
        if (quotes.enabled_dgr) {
            for (let i = 0; i < quotes.handling.length; i++) {
                let el = quotes.handling[i];
                hs.push({ Code: el.value, Delivertill: (el.Delivertill ? el.Delivertill.format('DD/MM/YY HH:mm') : null) });
            }
            for (let i = 0; i < quotes.dg_class.length; i++) {
                let cl = quotes.dg_class[i];
                cls.push({ text: cl.value });
            }
            var dg_un = quotes.dg_un.trim();
            var dg_un_array = dg_un.split(/\n/);
            for (let i = 0; i < dg_un_array.length; i++) {
                if (dg_un_array[i]) {
                    //additional_information
                    let dg_un_array_text = dg_un_array[i];
                    let numberLoopDGUn = parseInt(dg_un_array_text.length / 68);
                    let checkDivDGUn = dg_un_array_text.length % 68;
                    if (checkDivDGUn) {
                        numberLoopDGUn++;
                    }
                    for (let i = 0; i < numberLoopDGUn; i++) {
                        uns.push({ text: dg_un_array_text.substring(i * 68, (i + 1) * 68) });
                    }
                }
            }
            if (quotes.additional_information) {
                //additional_information
                var additional_information = quotes.additional_information.trim();
                additional_information = additional_information.replace(/(\r\n)/gm, "<br/>");
                additional_information = additional_information.replace(/(\n)/gm, "<br/>");
                var numberLoopAI = parseInt(additional_information.length / 68);
                var checkDivAI = additional_information.length % 68;
                if (checkDivAI) {
                    numberLoopAI++;
                }
                for (let i = 0; i < numberLoopAI; i++) {
                    add.push({ text: additional_information.substring(i * 68, (i + 1) * 68) });
                }
            }
        }


        let cpdet = [];
        let firstCalcmethod = null;
        let firstPrice = null;
        let firstItem = null;
        let firstCurrency = null;
        for (let i = 0; i < quotes.offer_price_list.length; i++) {
            let op = quotes.offer_price_list[i];
            if (!i) {
                firstCalcmethod = op.calculation_method ? op.calculation_method.value : null;
                firstPrice = op.price;
                firstCurrency = op.currency ? op.currency.label : null;
                firstItem = op.items ? op.items.value : null;
            } else {
                if (op.items && op.price != '' && op.calculation_method && op.currency) {
                    cpdet.push({ "subcom": op.items ? op.items.value : "", "subcomdes": "", "price": op.price != '' ? parseFloat(op.price) : 0, "calc": op.calculation_method ? op.calculation_method.label : "", "curr": op.currency ? op.currency.label : "" });
                }
            }
        }

        let phones = [];

        for (let i = 0; i < quotes.selectedContacts.length; i++) {
            if (i) {
                phones.push({ phone: quotes.selectedContacts[i].phone });
            }
        }

        var pDate = moment().add(2, 'day');
        if (quotes.proposal_expises) {
            pDate = quotes.proposal_expises;
        }
        var cp = {
            "CustAU": quotes.apiData.CustAU,
            "CProfAU": quotes.apiData.CProfAU,
            "phone": quotes.apiData.phone,
            "CProfNum": quotes.apiData.CProfNum,
            "PDate": quotes.expriresTime ? quotes.expriresTime.format('DD/MM/YY') : '',
            "SaleTypeCode": quotes.apiData.SaleTypeCode,
            "MustRide": quotes.apiData.MustRide.trim() ? 'Y' : '',
            "T1": quotes.apiData.T1,
            "CaoPax": quotes.apiData.CaoPax,
            "Overlap": (quotes.overlap || quotes.overlap_shipment_check) ? "Y" : "",
            "Litium": (quotes.enabled_dgr && quotes.is_lithium) ? 'Y' : '',
            "Unstackable": quotes.apiData.Unstackable ? quotes.apiData.Unstackable.trim() : '',
            "FromPortCode": quotes.deliver_from === 'Airport' ? quotes.deliver_from_airport.value : "",
            "ToPortCode": quotes.deliver_to === 'Airport' ? quotes.deliver_to_airport.value : "",
            "FromCountryCode": quotes.deliver_from_country.labelhiden,
            "ToCountryCode": quotes.deliver_to_country.labelhiden,
            "FromZipCode": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_zipcode : "",
            "ToZipCode": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_zipcode : "",
            "FromCity": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_city : "",
            "ToCity": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_city : "",
            "AWB": quotes.apiData.AWB,
            "ContactName": quotes.apiData.ContactName,
            "ShippingAgent": quotes.apiData.ShippingAgent,
            "RecievingAgent": quotes.apiData.RecievingAgent,
            "CNor": quotes.apiData.CNor,
            "CNee": quotes.apiData.CNee,
            "Commodity": quotes.commodity ? quotes.commodity.value : "",
            "SubCommodity": quotes.sub_commodity ? quotes.sub_commodity.value : "",
            "caopax": (quotes.enabled_dgr && quotes.is_dangerous) ? quotes.dg_cao_pax : '',
            "dept": quotes.apiData.dept, //chua hieu cai nay
            "Weight": parseFloat(quotes.unit_total.total_weight),
            "CWeight": parseFloat(quotes.unit_total.tcw),
            "Volume": parseFloat(quotes.unit_total.total_volume),
            "Price": firstPrice != null ? parseFloat(firstPrice) : null,
            "Pieces": parseInt(quotes.unit_total.total_pieces),
            "Dimensions": quotes.apiData.Dimensions,
            "ETDOrigin": quotes.pickup_dropoff_date ? quotes.pickup_dropoff_date.format('DD/MM/YY HH:mm') : '',
            "enddate": quotes.apiData.enddate,
            "Remarks": quotes.apiData.Remarks,
            "HandlingInstrunctionCodes": quotes.apiData.HandlingInstrunctionCodes,
            "Participents": {
                "SHIPNAME": quotes.shipping_company ? (quotes.shipping_company.custname ? quotes.shipping_company.custname : quotes.shipping_company.value) : null,
                "SHIPIATACODE": quotes.shippingIATA1 + quotes.shippingIATA2,
                "RECNAME": quotes.receiving_company ? (quotes.receiving_company.custname ? quotes.receiving_company.custname : quotes.receiving_company.value) : null,
                "RECIATACODE": quotes.receivingIATA1 + quotes.receivingIATA2,
                "CNORNUM": null,
                "CNORNAME": quotes.consignor_company ? quotes.consignor_company.custname : null,
                "CNORCOUNTRY": quotes.consignorCountry && quotes.consignorCountry.trim() ? quotes.consignorCountry : null,
                "CNORADDRESS": quotes.consignorAddress && quotes.consignorAddress.trim() ? quotes.consignorAddress : null,
                "CNORCITY": quotes.consignorCity && quotes.consignorCity.trim() ? quotes.consignorCity : null,
                "CNORPOSTALCODE": quotes.consignorPostalCode && quotes.consignorPostalCode.trim() ? quotes.consignorPostalCode : null,
                "CNEENUM": null,
                "CNEENAME": quotes.consignee_company ? quotes.consignee_company.custname : null,
                "CNEECOUNTRY": quotes.consigneeCountry && quotes.consigneeCountry.trim() ? quotes.consigneeCountry : null,
                "CNEEADDRESS": quotes.consigneeAddress && quotes.consigneeAddress.trim() ? quotes.consigneeAddress : null,
                "CNEEPOSTALCODE": quotes.consigneePostalCode && quotes.consigneePostalCode.trim() ? quotes.consigneePostalCode : null,
                "CNEECITY": quotes.consigneeCity && quotes.consigneeCity.trim() ? quotes.consigneeCity : null,
                "PICKNAME": quotes.airline ? quotes.airline.custname : null,
                "PICKEMAIL": quotes.pickupEmail ? quotes.pickupEmail : null,
                "PICKPHONE": quotes.pickupPhoneNumber ? quotes.pickupPhoneNumber : null,
                "PICKCOUNTRY": quotes.pickupCountry && quotes.pickupCountry.trim() ? quotes.pickupCountry : null,
                "PICKCITY": quotes.pickupCity && quotes.pickupCity.trim() ? quotes.pickupCity : '',
                "PICKADDRESS": quotes.pickupAddress && quotes.pickupAddress.trim() ? quotes.pickupAddress : null,
                "PICKPOSTALCODE": quotes.pickupPostalCode ? quotes.pickupPostalCode : null,
                "DELNAME": (quotes.gsa ? quotes.gsa.custname : null),
                "DELEMAIL": quotes.deliverEmail && quotes.deliverEmail.trim() ? quotes.deliverEmail : null,
                "DELPHONE": quotes.deliverPhoneNumber && quotes.deliverPhoneNumber.trim() ? quotes.deliverPhoneNumber : null,
                "DELCOUNTRY": quotes.deliverCountry && quotes.deliverCountry.trim() ? quotes.deliverCountry : null,
                "DELCITY": quotes.deliverCity && quotes.deliverCity.trim() ? quotes.deliverCity : null,
                "DELADDRESS": quotes.deliverAddress && quotes.deliverAddress.trim() ? quotes.deliverAddress : null,
                "DELPOSTALCODE": quotes.deliverPostalCode && quotes.deliverPostalCode.trim() ? quotes.deliverPostalCode : null,
                "DELOPENHOURS": quotes.deliverOpeningHours && quotes.deliverOpeningHours.trim() ? quotes.deliverOpeningHours : null,
                "DELREMARKS": quotes.deliverComments && quotes.deliverComments.trim() ? quotes.deliverComments : null,
                "PICKOPENHOURS": quotes.pickupOpeningHours && quotes.pickupOpeningHours.trim() ? quotes.pickupOpeningHours : null,
                "PICKREMARKS": quotes.pickupComments && quotes.pickupComments.trim() ? quotes.pickupComments : null,
                "PICKCOMPNAME": quotes.airline ? quotes.airline.custname : null,
                "DELCOMPNAME": quotes.feeder_gsa ? quotes.feeder_gsa.custname : null
            },
            "comtype": quotes.apiData.comtype,
            "sname": quotes.apiData.sname,
            "sitestat": quotes.apiData.sitestat,
            "statdate": quotes.apiData.statdate,
            "statchangeres": quotes.apiData.statchangeres,
            "calcmethod": firstCalcmethod,
            "currency": firstCurrency,
            "typedate": pickup_type,
            "usercode": quotes.apiData.usercode,
            "allin": quotes.apiData.allin,
            "custname": quotes.customer ? (quotes.customer.custname ? quotes.customer.custname : quotes.customer.value) : null,
            "custcountry": quotes.apiData.custcountry,
            "custcity": quotes.apiData.custcity,
            "custcurr": quotes.apiData.custcurr,
            "contactemail": quotes.apiData.contactemail,
            "contactname": quotes.apiData.contactname,
            "contactweb": quotes.apiData.contactweb
        };

        returnData = {
            cp, Dimensions, cpdet, exp, hs, uld, phones: phones, legs, add, cls, uns, ic: []
        };
        return returnData;
    }
}


export function buildSCData(quotes) {
    // console.log('quotes', quotes);
    return async (dispatch, getState) => {
        let auth = store.getState().auth;
        // let apiAWB = '';
        // if(auth.webcode){
        //     const apiParams = {
        //         functionName: 'getwebfawb',
        //         method: 'GET',
        //         dataPass: {
        //             webf: '10418'
        //         }
        //     };

        //     let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        //     console.log(dataReturn);
        // }

        var returnData = { cp: {}, Dimensions: [], cpdet: [], exp: [], hs: [], uld: [], phone: [], leg: [], add: [], cls: [], un: [], ic: [] };
        var legs = [];
        var routes = {
            "fromport": "",
            "toport": "",
            "std": "",
            "sta": "",
            "price": 0,
            "expressprice": 0,
            "flights": [],
            "Parts": []
        }

        if (quotes.selected_flight) {
            let tPort = quotes.selected_flight.fleg.tport;
            let tDate = quotes.selected_flight.fleg.sta
            if (quotes.selected_flight.tleg && quotes.selected_flight.tleg.tport) {
                tPort = quotes.selected_flight.tleg.tport;
                tDate = quotes.selected_flight.tleg.sta
            }
            routes.fromport = quotes.selected_flight.fleg.fport;
            routes.toport = tPort;
            routes.std = quotes.selected_flight.fleg.std;
            routes.sta = tDate;

            let flights = [];
            flights.push(
                { "fromport": quotes.selected_flight.fleg.fport, "toport": quotes.selected_flight.fleg.tport, "std": quotes.selected_flight.fleg.std, "sta": quotes.selected_flight.fleg.sta, "price": 0, "expressprice": 0, "leg": quotes.selected_flight.fleg.leg, "routenum": 1 }
            );

            if (quotes.selected_flight.vialeg.fnumber) {
                flights.push(
                    { "fromport": quotes.selected_flight.vialeg.fport, "toport": quotes.selected_flight.vialeg.tport, "std": quotes.selected_flight.vialeg.std, "sta": quotes.selected_flight.vialeg.sta, "price": 0, "expressprice": 0, "leg": quotes.selected_flight.vialeg.leg, "routenum": 1 }
                );
            }

            if (quotes.selected_flight.tleg.fnumber) {
                flights.push(
                    { "fromport": quotes.selected_flight.tleg.fport, "toport": quotes.selected_flight.tleg.tport, "std": quotes.selected_flight.tleg.std, "sta": quotes.selected_flight.tleg.sta, "price": 0, "expressprice": 0, "leg": quotes.selected_flight.tleg.leg, "routenum": 1 }
                );
            }
            routes.flights = flights;

            legs.push({
                "leg": quotes.selected_flight.fleg.leg
            });
            if (quotes.selected_flight.vialeg.leg) {
                legs.push({
                    "leg": quotes.selected_flight.vialeg.leg
                });
            }
            if (quotes.selected_flight.tleg.leg) {
                legs.push({
                    "leg": quotes.selected_flight.tleg.leg
                });
            }
        }


        let pickup_type = '';
        if (quotes.pickupOptions.value === 'drop_off') {
            pickup_type = 'drop off';
        } else if (quotes.pickupOptions.value === 'must_ride_till') {
            pickup_type = 'must ride till';
        } else if (quotes.pickupOptions.value === 'pickup') {
            pickup_type = 'pickup date';
        }

        let Dimensions = [];
        let uld = [];
        if (quotes.unit_type.value === 'uld') {
            for (let i = 0; i < quotes.unit_items.length; i++) {
                let un = quotes.unit_items[i];
                let item_pieces = un.item_pieces !== '' ? parseInt(un.item_pieces) : '';
                let uldType = un.item_uld ? un.item_uld.value : null;
                if (uldType && item_pieces !== '') {
                    uld.push({ "Pcs": parseInt(item_pieces), "paltype": uldType });
                }
            }
        } else {
            for (let i = 0; i < quotes.unit_items.length; i++) {
                let un = quotes.unit_items[i];
                let item_length = un.item_length !== '' ? parseFloat(un.item_length) : '';
                let item_width = un.item_width !== '' ? parseFloat(un.item_width) : '';
                let item_height = un.item_height !== '' ? parseFloat(un.item_height) : '';
                let item_volume = un.item_volume !== '' ? parseFloat(un.item_volume) : '';
                let item_pieces = un.item_pieces !== '' ? parseInt(un.item_pieces) : '';
                if (item_length !== '' || item_width !== '' || item_height !== '' || item_volume !== '') {
                    Dimensions.push({ "Height": item_height, "Width": item_width, "Length": item_length, "Pcs": item_pieces, "unitname": quotes.unit_length.value.toUpperCase(), "Weight": item_volume, "paltype": un.item_uld ? un.item_uld.value : "" });
                }
            }
        }

        let add = [];
        let exp = [];
        let hs = [];
        let cls = [];
        let uns = [];
        if (quotes.enabled_dgr) {
            for (let i = 0; i < quotes.handling.length; i++) {
                let el = quotes.handling[i];
                hs.push({ Code: el.value, Delivertill: (el.Delivertill ? el.Delivertill.format('DD/MM/YY HH:mm') : null) });
            }
            for (let i = 0; i < quotes.dg_class.length; i++) {
                let cl = quotes.dg_class[i];
                cls.push({ text: cl.value });
            }
            var dg_un = quotes.dg_un.trim();
            var dg_un_array = dg_un.split(/\n/);
            for (let i = 0; i < dg_un_array.length; i++) {
                if (dg_un_array[i]) {
                    //additional_information
                    let dg_un_array_text = dg_un_array[i];
                    let numberLoopDGUn = parseInt(dg_un_array_text.length / 68);
                    let checkDivDGUn = dg_un_array_text.length % 68;
                    if (checkDivDGUn) {
                        numberLoopDGUn++;
                    }
                    for (let i = 0; i < numberLoopDGUn; i++) {
                        uns.push({ text: dg_un_array_text.substring(i * 68, (i + 1) * 68) });
                    }
                }
            }
            if (quotes.additional_information) {
                //additional_information
                var additional_information = quotes.additional_information.trim();
                additional_information = additional_information.replace(/(\r\n)/gm, "<br/>");
                additional_information = additional_information.replace(/(\n)/gm, "<br/>");
                var numberLoopAI = parseInt(additional_information.length / 68);
                var checkDivAI = additional_information.length % 68;
                if (checkDivAI) {
                    numberLoopAI++;
                }
                for (let i = 0; i < numberLoopAI; i++) {
                    add.push({ text: additional_information.substring(i * 68, (i + 1) * 68) });
                }
            }
        }

        let cpdet = [];
        let firstCalcmethod = null;
        let firstPrice = null;
        let firstItem = null;
        let firstCurrency = null;
        for (let i = 0; i < quotes.offer_price_list.length; i++) {
            let op = quotes.offer_price_list[i];
            if (!i) {
                firstCalcmethod = op.calculation_method ? op.calculation_method.value : null;
                firstPrice = op.price;
                firstCurrency = op.currency ? op.currency.label : null;
                firstItem = op.items ? op.items.value : null;
            } else {
                if (op.items && op.price != '' && op.calculation_method && op.currency) {
                    cpdet.push({ "subcom": op.items ? op.items.value : "", "subcomdes": "", "price": op.price != '' ? parseFloat(op.price) : 0, "calc": op.calculation_method ? op.calculation_method.label : "", "curr": op.currency ? op.currency.label : "" });
                }
            }
        }

        let phones = [];

        for (let i = 0; i < quotes.selectedContacts.length; i++) {
            if (i) {
                phones.push({ phone: quotes.selectedContacts[i].phone });
            }
        }

        var pDate = moment().add(2, 'day');
        if (quotes.proposal_expises) {
            pDate = quotes.proposal_expises;
        }        

        // console.log(quotes);
        var scData = {
            "currency": quotes.offer_currency ? quotes.offer_currency.label : firstCurrency,
            "awb": '',
            "authcode": "", //chua hieu
            "userPaymentOptionId": "", //chua chieu
            "amount": quotes.offer_total,
            "transid": "-1", //chua hieu
            "custname": quotes.customer ? (quotes.customer.custname ? quotes.customer.custname : quotes.customer.value) : null,
            "cust": 0, //chua hieu
            "type": "", //chua hieu
            "isexpress": quotes.express_delivery,
            "pl": 0,
            "parcelpl": 0,
            "parcelprice": 0.0,
            "parcelexpprice": 0.0,
            "Participents": {
                "SHIPNAME": quotes.shipping_company ? (quotes.shipping_company.custname ? quotes.shipping_company.label : quotes.shipping_company.label) : null,
                "SHIPIATACODE": quotes.shippingIATA1 + quotes.shippingIATA2,
                "RECNAME": quotes.receiving_company ? (quotes.receiving_company.custname ? quotes.receiving_company.label : quotes.receiving_company.label) : null,
                "RECIATACODE": quotes.receivingIATA1 + quotes.receivingIATA2,
                "CNORNUM": null,
                "CNORNAME": quotes.consignor_company ? quotes.consignor_company.label : null,
                "CNORCOUNTRY": quotes.consignorCountry ? quotes.consignorCountry.trim() : null,
                "CNORADDRESS": quotes.consignorAddress ? quotes.consignorAddress.trim() : null,
                "CNORCITY": quotes.consignorCity ? quotes.consignorCity.trim() : null,
                "CNORPOSTALCODE": quotes.consignorPostalCode ? quotes.consignorPostalCode.trim() : null,
                "CNEENUM": null,
                "CNEENAME": quotes.consignee_company ? quotes.consignee_company.label : null,
                "CNEECOUNTRY": quotes.consigneeCountry ? quotes.consigneeCountry.trim() : null,
                "CNEEADDRESS": quotes.consigneeAddress ? quotes.consigneeAddress.trim() : null,
                "CNEEPOSTALCODE": quotes.consigneePostalCode ? quotes.consigneePostalCode.trim() : null,
                "CNEECITY": quotes.consigneeCity ? quotes.consigneeCity.trim() : null,
                "PICKNAME": quotes.airline ? quotes.airline.label : null,
                "PICKEMAIL": quotes.pickupEmail ? quotes.pickupEmail : null,
                "PICKPHONE": quotes.pickupPhoneNumber ? quotes.pickupPhoneNumber : null,
                "PICKCOUNTRY": quotes.pickupCountry ? quotes.pickupCountry.trim() : null,
                "PICKCITY": quotes.pickupCity ? quotes.pickupCity.trim() : '',
                "PICKADDRESS": quotes.pickupAddress ? quotes.pickupAddress.trim() : null,
                "PICKPOSTALCODE": quotes.pickupPostalCode ? quotes.pickupPostalCode : null,
                "DELNAME": (quotes.gsa ? quotes.gsa.label : null),
                "DELEMAIL": quotes.deliverEmail ? quotes.deliverEmail.trim() : null,
                "DELPHONE": quotes.deliverPhoneNumber ? quotes.deliverPhoneNumber.trim() : null,
                "DELCOUNTRY": quotes.deliverCountry ? quotes.deliverCountry.trim() : null,
                "DELCITY": quotes.deliverCity ? quotes.deliverCity.trim() : null,
                "DELADDRESS": quotes.deliverAddress ? quotes.deliverAddress.trim() : null,
                "DELPOSTALCODE": quotes.deliverPostalCode ? quotes.deliverPostalCode.trim() : null,
                "DELOPENHOURS": quotes.deliverOpeningHours ? quotes.deliverOpeningHours.trim() : null,
                "DELREMARKS": quotes.deliverComments ? quotes.deliverComments.trim() : null,
                "PICKOPENHOURS": quotes.pickupOpeningHours ? quotes.pickupOpeningHours.trim() : null,
                "PICKREMARKS": quotes.pickupComments ? quotes.pickupComments.trim() : null,
                "PICKCOMPNAME": quotes.airline ? quotes.airline.label : null,
                "DELCOMPNAME": quotes.feeder_gsa ? quotes.feeder_gsa.label : null
            },
            "PQ": {
                "cprofau": quotes.CProfAU ? quotes.CProfAU : null,
                "phone": quotes.apiData.phone,
                "PDate": quotes.expriresTime ? quotes.expriresTime.format('DD/MM/YY') : '',
                "Plnum": "", //chua hieu
                "ETDOrigin": quotes.pickup_dropoff_date ? quotes.pickup_dropoff_date.format('DD/MM/YY HH:mm') : '',
                "CaoPax": quotes.apiData.CaoPax,
                "Overlap": (quotes.overlap || quotes.overlap_shipment_check) ? "Y" : "",
                "Litium": (quotes.enabled_dgr && quotes.is_lithium) ? 'Y' : '',
                "Unstackable": quotes.apiData.Unstackable ? quotes.apiData.Unstackable.trim() : '',
                "T1": quotes.apiData.T1,

                "FromPortCode": quotes.deliver_from === 'Airport' ? quotes.deliver_from_airport.value : "",
                "ToPortCode": quotes.deliver_to === 'Airport' ? quotes.deliver_to_airport.value : "",
                "FromCountryCode": quotes.deliver_from_country.labelhiden,
                "ToCountryCode": quotes.deliver_to_country.labelhiden,
                "FromZipCode": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_zipcode : "",
                "ToZipCode": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_zipcode : "",
                "FromCity": quotes.deliver_from === 'ZipCode' ? quotes.deliver_from_city : "",
                "ToCity": quotes.deliver_to === 'ZipCode' ? quotes.deliver_to_city : "",

                "AWB": quotes.apiData.AWB ? quotes.apiData.AWB : '',
                "Commodity": quotes.commodity ? quotes.commodity.value : "",
                "SubCommodity": quotes.sub_commodity ? quotes.sub_commodity.value : "",

                "Weight": parseFloat(quotes.unit_total.total_weight),
                // "CWeight": parseFloat(quotes.unit_total.tcw),
                "Volume": parseFloat(quotes.unit_total.total_volume),
                // "Price": firstPrice != null ? parseFloat(firstPrice) : null,
                "Pieces": parseInt(quotes.unit_total.total_pieces),
                "Remarks": quotes.apiData.Remarks,
                "HandlingInstrunctionCodes": quotes.apiData.HandlingInstrunctionCodes,
                "Dimensions": Dimensions,
                // "Dimensions": [
                //     { "Height": 2.0, "Width": 3.0, "Length": 4.0, "Cbm": 1.0, "Pcs": 12, "unitname": "CM", "paltype": "HD" },
                //     { "Height": 2.1, "Width": 3.1, "Length": 4.1, "Cbm": 1.1, "Pcs": 13, "unitname": "IN", "paltype": "Loose" }
                // ]
            },
            "routes": routes
        };
        return scData;
    }
}

export function getGroupEmail(ordId) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'getgroupemail',
            method: 'GET',
            dataPass: {
                ord: ordId
            }
        };

        let dataReturn = await ModelAPI.postMethod('call-cal-api', apiParams);
        return dataReturn;
    }
}

export function setcprofbid(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'setcprofbid',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function setSC(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'setsc',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function updateShipment(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'setbidawb',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function createQuote(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'setcprofbid',
            method: 'POST',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function getProposal(quoteID, idToken) {
    return async (dispatch, getState) => {
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.getPrivateMethod(`proposals?quoteID=${quoteID}`, configs);
        if (!dataReturn.success && dataReturn.tryagain) {
            var newJwt = await ModelAuth.doGetSession();
            configs.headers.Authorization = newJwt.jwtToken;
            dispatch({
                type: ActionTypes.AUTH_CHANGE_DATA,
                payload: { fieldName: 'jwtToken', fieldValue: newJwt.jwtToken }
            });
            dataReturn = await ModelAPI.getPrivateMethod(`proposals?quoteID=${quoteID}`, configs);
            return dataReturn;
        }
        return dataReturn;
    }
}

export function createProposal(params, idToken) {
    return async (dispatch, getState) => {
        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod(`proposals`, params, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPIProposal(dataReturn, dispatch, params);
        return dataReturn;
    }
}

export function declineQuote(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'declineQuotes',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function acceptQuote(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'acceptQuotes',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function counterQuote(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'counterQuotes',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}

export function getTempData(params, idToken) {
    return async (dispatch, getState) => {
        const apiParams = {
            functionName: 'getQuoteTempData',
            dataPass: params
        };

        var configs = {
            headers: {
                'Authorization': idToken
            }
        };
        let dataReturn = await ModelAPI.postMethod('private-api', apiParams, configs);
        dataReturn = await ModelAPI.refreshTokenOnAPI(dataReturn, dispatch, apiParams);
        return dataReturn;
    }
}