import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import StepProgress from '../../layouts/partials/StepProgress';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import validator from 'validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class Participants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                PriceQuotation: { type: "" },
                selectedShippingReceiving: 'Shipping',
                receivingCompanyName: "",
                receivingIATA1: "",
                receivingIATA2: "",
                shippingCompanyName: "",
                shippingIATA1: "",
                shippingIATA2: "",
                consignorCompanyName: "",
                consignorAddress: "",
                consignorCity: "",
                consignorCountry: "",
                consignorGroundHandling: "",
                consignorPostalCode: "",
                consigneeAddress: "",
                consigneeCity: "",
                consigneeCompanyName: "",
                consigneeCountry: "",
                consigneeGroundHandling: "",
                consigneePostalCode: "",
                pickupCheck: false,
                pickupAddress: "",
                pickupCity: "",
                pickupComments: "",
                pickupCountry: "",
                pickupEmail: "",
                pickupCompanyName: "",
                pickupNameOfContact: "",
                pickupOpeningHours: "",
                pickupPhoneNumber: "",
                pickupPostalCode: "",
                deliverAddress: "",
                deliverCheck: false,
                deliverCity: "",
                deliverComments: "",
                deliverCountry: "",
                deliverEmail: "",
                deliverCompanyName: "",
                deliverNameOfContact: "",
                deliverOpeningHours: "",
                deliverPhoneNumber: "",
                deliverPostalCode: ""
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': false,
                    'validate': false,
                    'current': false,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': true,
                    'current': true,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            countries: [],
            isLoadingCountry: false,
            selectedCountryConsignor: null,
            selectedCountryConsignee: null,
            selectedCountryPickup: null,
            selectedCountryDelivery: null,
            showPickup: false,
            showGroundHandlingOrigin: false,
            showDelivery: false,
            showGroundHandlingDestination: false,
            showFreightForwarder: false,
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            consigneeSuggests: [],
            consignorSuggests: [],
            suggestionsReceiving: [],
            receivingSuggests: [],
            consigneeOptions: [],
            consignorOptions: [],
            selectedConsignee: null,
            selectedConsignor: null,
            editFreightForwarder: true,
            showClearConsignor: false,
            showClearConsignee: false,
            consignorGroundHandlingDisabled: false,
            consigneeGroundHandlingDisabled: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleOnBlurSelect = this.handleOnBlurSelect.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.isValidationAwb = this.isValidationAwb.bind(this);
    }

    isValidationAwb = () => {
        const { errors, ebooking } = this.state;
        var isError = false;
        if (ebooking.awb_number_1 != '' && ebooking.awb_number_2 != '') {
            if (validator.isEmpty(ebooking.awb_number_1)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }
            if (!validator.isNumeric(ebooking.awb_number_1)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }

            if (!validator.isLength(ebooking.awb_number_1, { min: 3, max: 3 })) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }

            if (validator.isEmpty(ebooking.awb_number_2)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }

            if (!validator.isNumeric(ebooking.awb_number_2)) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }

            if (!validator.isLength(ebooking.awb_number_2, { min: 8, max: 8 })) {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
            }
        }
        if (!isError) {
            errors['awb_number'] = '';
            if (ebooking.awb_number_2 != "" && ebooking.awb_number_1 != "") {
                this.getAwb();
            }
        }

        this.setState({ errors });
    }

    getAwb() {
        const { ebooking, errors } = this.state;
        var awbNumber = ebooking.awb_number_1 + '' + ebooking.awb_number_2;
        this.props.loadingActions.loadingChangeData(true);
        var jwtToken = this.props.auth.jwtToken;
        this.props.helpActions.checkAllocations(awbNumber, jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success && response.data.res == "Y") {
                errors['awb_number_1'] = "";
                errors['awb_number_2'] = "";
                errors['awb_number'] = "";
                ebooking.isAwb = true;
            } else {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                ebooking.isAwb = false;
            }
            this.props.ebookingActions.ebookingChangeData({ fieldName: "isAwb", fieldValue: ebooking.isAwb }).then(responseData => { });
            this.setState({ errors, ebooking });
        });
    }

    getSuggestionsCNEE = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.state;
        ebooking.consigneeCompanyName = value;
        if (ebooking.deliverCheck) {
            ebooking.deliverCompanyName = value;
            this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCompanyName", fieldValue: ebooking.deliverCompanyName }).then(responseData => { });
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCompanyName", fieldValue: ebooking.consigneeCompanyName }).then(responseData => { });

        this.setState({ ebooking });
        return inputLength === 0 ? [] : this.state.consigneeSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionsCNOR = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.state;
        ebooking.consignorCompanyName = value;
        if (ebooking.pickupCheck) {
            ebooking.pickupCompanyName = value;
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCompanyName", fieldValue: ebooking.pickupCompanyName }).then(responseData => { });
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCompanyName", fieldValue: ebooking.consignorCompanyName }).then(responseData => { });
        this.setState({ ebooking });
        return inputLength === 0 ? [] : this.state.consignorSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionsReceiving = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.state;
        ebooking.receivingCompanyName = value;
        this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingCompanyName", fieldValue: ebooking.receivingCompanyName }).then(responseData => { });
        this.setState({ ebooking });
        return inputLength === 0 ? [] : this.state.receivingSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => {
        return suggestion.name
    };

    renderSuggestion = suggestion => {
        return (
            <div>
                {suggestion.name}
            </div>
        )
    };

    onChangeSugestCNEE = (event, { newValue }) => {
        var { errors, selectedCountryConsignee, showClearConsignee, selectedCountryDelivery } = this.state;
        var { ebooking } = this.props;
        ebooking.consigneeCompanyName = newValue;
        errors["consigneeCompanyName"] = "";
        errors["consigneeAddress"] = "";
        errors["consigneeCity"] = "";
        errors["consigneeCountry"] = "";
        errors["consigneePostalCode"] = "";
        var selectedOption = this.state.consigneeSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            ebooking.clearConsignee = false;
            ebooking.consigneeAddress = selectedOption[0].address;
            ebooking.consigneeCity = selectedOption[0].cityname;
            ebooking.consigneeCountry = selectedOption[0].countryname;
            ebooking.consigneePostalCode = selectedOption[0].zipcode;
            ebooking.populateConsignee = true;
            showClearConsignee = true;
            var findCountry = this.state.countries.find(c => c.label == ebooking.consigneeCountry);
            if (findCountry) {
                selectedCountryConsignee = findCountry;
            }
            // this.props.country.countries.forEach(c => {
            //     var country = { value: c.country, label: c.countryname };
            //     if (c.countryname == ebooking.consigneeCountry) {
            //         selectedCountryConsignee = country;
            //     }
            // });
        }

        if (ebooking.deliverCheck) {
            ebooking.deliverCompanyName = newValue;
            ebooking.deliverAddress = ebooking.consigneeAddress;
            ebooking.deliverCity = ebooking.consigneeCity;
            ebooking.deliverCountry = ebooking.consigneeCountry;
            ebooking.deliverPostalCode = ebooking.consigneePostalCode;
            selectedCountryDelivery = selectedCountryConsignee;
        }

        this.setState({
            ebooking, errors, selectedCountryConsignee: selectedCountryConsignee, showClearConsignee: showClearConsignee, selectedCountryDelivery: selectedCountryDelivery
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    };

    onChangeSugestCNOR = (event, { newValue }) => {
        var { errors, selectedCountryConsignor, showClearConsignor, selectedCountryPickup } = this.state;
        var { ebooking } = this.props;
        ebooking.consignorCompanyName = newValue;
        errors["consignorCompanyName"] = "";
        errors["consignorAddress"] = "";
        errors["consignorCity"] = "";
        errors["consignorCountry"] = "";
        errors["consignorPostalCode"] = "";
        var selectedOption = this.state.consignorSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            ebooking.clearConsignor = false;
            ebooking.consignorAddress = selectedOption[0].address;
            ebooking.consignorCity = selectedOption[0].cityname;
            ebooking.consignorCountry = selectedOption[0].countryname;
            ebooking.consignorPostalCode = selectedOption[0].zipcode;
            ebooking.populateConsignor = true;
            showClearConsignor = true;
            var findCountry = this.state.countries.find(c => c.label == ebooking.consignorCountry);
            if (findCountry) {
                selectedCountryConsignor = findCountry;
            }
            // this.props.country.countries.forEach(c => {
            //     var country = { value: c.country, label: c.countryname };
            //     if (c.countryname == ebooking.consignorCountry) {
            //         selectedCountryConsignor = country;
            //     }
            // });
        }
        if (ebooking.pickupCheck) {
            ebooking.pickupCompanyName = ebooking.consignorCompanyName;
            ebooking.pickupAddress = ebooking.consignorAddress;
            ebooking.pickupCity = ebooking.consignorCity;
            ebooking.pickupCountry = ebooking.consignorCountry;
            ebooking.pickupPostalCode = ebooking.consignorPostalCode;
            selectedCountryPickup = selectedCountryConsignor;
        }

        this.setState({
            ebooking, errors, selectedCountryConsignor: selectedCountryConsignor, showClearConsignor: showClearConsignor, selectedCountryPickup: selectedCountryPickup
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    };

    onChangeSugestReceiving = (event, { newValue }) => {
        var { errors } = this.state;
        var { ebooking } = this.props;
        ebooking.receivingCompanyName = newValue;
        errors["receivingCompanyName"] = "";

        var selectedOption = this.state.receivingSuggests.filter(lang =>
            lang.name === newValue
        );

        if (selectedOption.length) {
            ebooking.receivingIATA1 = selectedOption[0].iata ? selectedOption[0].iata.substr(0, 7).trim() : '';
            ebooking.receivingIATA2 = (selectedOption[0].iata && selectedOption[0].iata.length > 7) ? selectedOption[0].iata.substr(7, selectedOption[0].iata.length - 7).trim() : '';
        }

        this.setState({
            ebooking, errors
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    };

    onSuggestionsFetchRequestedCNEE = ({ value }) => {
        this.setState({
            suggestionsCNEE: this.getSuggestionsCNEE(value)
        });
    };

    onSuggestionsFetchRequestedCNOR = ({ value }) => {
        this.setState({
            suggestionsCNOR: this.getSuggestionsCNOR(value)
        });
    };

    onSuggestionsFetchRequestedReceiving = ({ value }) => {
        this.setState({
            suggestionsReceiving: this.getSuggestionsReceiving(value)
        });
    };

    onSuggestionsClearRequestedCNEE = () => {
        this.setState({
            suggestionsCNEE: []
        });
    };

    onSuggestionsClearRequestedCNOR = () => {
        this.setState({
            suggestionsCNOR: []
        });
    };

    onSuggestionsClearRequestedReceiving = () => {
        this.setState({
            suggestionsReceiving: []
        });
    };

    getParticipentREC = () => {
        var { receivingSuggests } = this.state;
        receivingSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "REC", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    receivingSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ receivingSuggests });
        });
    }

    getParticipentCNEE = () => {
        var { consigneeSuggests } = this.state;
        consigneeSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNEE", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consigneeSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consigneeSuggests });
        });
    }

    getParticipentCNOR = () => {
        var { consignorSuggests } = this.state;
        consignorSuggests = [];
        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNOR", this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consignorSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consignorSuggests });
        });
    }

    getWarehouses = (fport, tport) => {
        var { ebooking } = this.props;
        var { consigneeOptions, consignorOptions, consigneeGroundHandlingDisabled, consignorGroundHandlingDisabled } = this.state;
        consigneeOptions = [];
        consignorOptions = [];
        this.props.loadingActions.loadingChangeData(true);
        var t = this;
        if (fport != 0) {
            this.props.ebookingActions.getWarehouses(fport, 0).then(response => {
                this.props.loadingActions.loadingChangeData(false);
                if (response.success && response.data.Success) {
                    response.data.warehouse.map(wh => {
                        consignorOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                    });
                    consignorOptions = consignorOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
                }

                ebooking.consignorGroundHandlingData = { warhsname: "" };
                var findOption = consignorOptions.find(x => x.value === ebooking['consignorGroundHandling']);
                if (findOption) {
                    ebooking.consignorGroundHandlingData = findOption;
                } else {
                    ebooking['consignorGroundHandling'] = "";
                }
                t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandlingData", fieldValue: ebooking.consignorGroundHandlingData }).then(responseData => { });
                t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandling", fieldValue: ebooking.consignorGroundHandling }).then(responseData => { });
                t.choosedConsignorWarehouse();

                consignorGroundHandlingDisabled = false;
                if (consignorOptions.length == 1) {
                    consignorGroundHandlingDisabled = true;
                    ebooking.consignorGroundHandling = consignorOptions[0].value;
                    ebooking.consignorGroundHandlingData = consignorOptions[0];
                    t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandling", fieldValue: ebooking.consignorGroundHandling }).then(responseData => { });
                    t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandlingData", fieldValue: ebooking.consignorGroundHandlingData }).then(responseData => { });

                    // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                    t.choosedConsignorWarehouse();
                } else if (consignorOptions.length > 1 && ebooking.duplicate && ebooking.duplicateFields.PICKNAME && ebooking['consignorGroundHandling'] === "") {
                    consignorOptions.map(co => {
                        if (co.warhsname === ebooking.duplicateFields.PICKNAME) {
                            ebooking.consignorGroundHandling = co.value;
                            ebooking.consignorGroundHandlingData = co;
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandling", fieldValue: ebooking.consignorGroundHandling }).then(responseData => { });
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandlingData", fieldValue: ebooking.consignorGroundHandlingData }).then(responseData => { });
                            t.choosedConsignorWarehouse();
                        }
                    })
                }
                this.setState({ consigneeOptions, consignorOptions, consignorGroundHandlingDisabled: consignorGroundHandlingDisabled });
                if (tport != 0) {
                    t.props.loadingActions.loadingChangeData(true);
                    t.props.ebookingActions.getWarehouses(0, tport).then(responseTo => {
                        t.props.loadingActions.loadingChangeData(false);
                        if (responseTo.success && responseTo.data.Success) {
                            responseTo.data.warehouse.map(wh => {
                                consigneeOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                            });
                            consigneeOptions = consigneeOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
                        }

                        ebooking.consigneeGroundHandlingData = { warhsname: "" };
                        var findOption = consignorOptions.find(x => x.value === ebooking['consigneeGroundHandling']);
                        if (findOption) {
                            ebooking.consigneeGroundHandlingData = findOption;
                        } else {
                            ebooking['consigneeGroundHandling'] = "";
                        }
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                        t.choosedConsigneeWarehouse();
                        consigneeGroundHandlingDisabled = false;
                        if (consigneeOptions.length == 1) {
                            consigneeGroundHandlingDisabled = true;
                            ebooking.consigneeGroundHandling = consigneeOptions[0].value;
                            ebooking.consigneeGroundHandlingData = consigneeOptions[0];
                            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                            t.choosedConsigneeWarehouse();
                        } else if (ebooking.deliver_to_airport == "TLV") {
                            var mamanIndex = consigneeOptions.findIndex(x => x.value == "MAMAN");
                            if (mamanIndex >= 0) {
                                ebooking.consigneeGroundHandling = consigneeOptions[mamanIndex].value;
                                ebooking.consigneeGroundHandlingData = consigneeOptions[mamanIndex];
                                // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                                t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                                t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                                t.choosedConsigneeWarehouse();
                            }
                        }
                        t.setState({ consigneeOptions, consigneeGroundHandlingDisabled });
                    });
                }
            });
        } else if (tport != 0) {
            this.props.loadingActions.loadingChangeData(true);
            this.props.ebookingActions.getWarehouses(0, tport).then(responseTo => {
                this.props.loadingActions.loadingChangeData(false);
                if (responseTo.success && responseTo.data.Success) {
                    responseTo.data.warehouse.map(wh => {
                        consigneeOptions.push({ value: wh.warhsdes, label: wh.warhsdes, warhsname: wh.warhsname, city: wh.city, address: wh.address, country: wh.country, zip: wh.zip });
                    });
                    consigneeOptions = consigneeOptions.sort((a, b) => { return a.label.localeCompare(b.label) });
                    ebooking.consigneeGroundHandlingData = { warhsname: "" };
                    var findOption = consignorOptions.find(x => x.value === ebooking['consigneeGroundHandling']);
                    if (findOption) {
                        ebooking.consigneeGroundHandlingData = findOption;
                        // t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                    } else {
                        ebooking['consigneeGroundHandling'] = "";
                        // t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                    }
                    t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                    t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                    t.choosedConsigneeWarehouse();
                    consigneeGroundHandlingDisabled = false;
                    if (consigneeOptions.length == 1) {
                        consigneeGroundHandlingDisabled = true;
                        ebooking.consigneeGroundHandling = consigneeOptions[0].value;
                        ebooking.consigneeGroundHandlingData = consigneeOptions[0];
                        // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                        t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                        t.choosedConsigneeWarehouse();
                    } else if (ebooking.deliver_to_airport == "TLV") {
                        var mamanIndex = consigneeOptions.findIndex(x => x.value == "MAMAN");
                        if (mamanIndex >= 0) {
                            ebooking.consigneeGroundHandling = consigneeOptions[mamanIndex].value;
                            ebooking.consigneeGroundHandlingData = consigneeOptions[mamanIndex];
                            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandling", fieldValue: ebooking.consigneeGroundHandling }).then(responseData => { });
                            t.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
                            t.choosedConsigneeWarehouse();
                        }
                    }
                }

                this.setState({ consigneeOptions, consignorOptions, consigneeGroundHandlingDisabled: consigneeGroundHandlingDisabled });
            });
        }
    }

    choosedConsigneeWarehouse = () => {
        var { selectedCountryDelivery } = this.state;
        var { ebooking } = this.props;
        selectedCountryDelivery = null;
        ebooking.deliverCity = ebooking.consigneeGroundHandlingData.city ? ebooking.consigneeGroundHandlingData.city : "";
        ebooking.deliverCountry = ebooking.consigneeGroundHandlingData.country ? ebooking.consigneeGroundHandlingData.country : "";
        ebooking.deliverAddress = ebooking.consigneeGroundHandlingData.address ? ebooking.consigneeGroundHandlingData.address : "";
        ebooking.deliverPostalCode = ebooking.consigneeGroundHandlingData.zip ? ebooking.consigneeGroundHandlingData.zip : "";
        ebooking.populateDelivery = true;
        // console.log("choosedConsigneeWarehouse");
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        var findCountry = this.state.countries.find(c => c.label === ebooking.deliverCountry);
        if (findCountry) {
            selectedCountryDelivery = findCountry;
        }
        this.setState({ ebooking, selectedCountryDelivery });
    }

    choosedConsignorWarehouse = () => {
        var { selectedCountryPickup } = this.state;
        var { ebooking } = this.props;
        selectedCountryPickup = null;
        ebooking.pickupCity = ebooking.consignorGroundHandlingData.city ? ebooking.consignorGroundHandlingData.city : "";
        ebooking.pickupCountry = ebooking.consignorGroundHandlingData.country ? ebooking.consignorGroundHandlingData.country : "";
        ebooking.pickupAddress = ebooking.consignorGroundHandlingData.address ? ebooking.consignorGroundHandlingData.address : "";
        ebooking.pickupPostalCode = ebooking.consignorGroundHandlingData.zip ? ebooking.consignorGroundHandlingData.zip : "";
        ebooking.populatePickup = true;
        // console.log("choosedConsignorWarehouse");
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });

        var findCountry = this.state.countries.find(c => c.label === ebooking.pickupCountry);
        if (findCountry) {
            selectedCountryPickup = findCountry;
        }
        this.setState({ ebooking, selectedCountryPickup });
    }


    clickClearPopulate = (typePopulate) => {
        var { selectedCountryConsignee, selectedCountryConsignor, showClearConsignee, showClearConsignor } = this.state;
        var { ebooking } = this.props;
        if (typePopulate == "consignor") {
            ebooking.clearConsignor = true;
            ebooking.consignorCompanyName = "";
            ebooking.consignorAddress = "";
            ebooking.consignorCity = "";
            ebooking.consignorCountry = "";
            ebooking.consignorPostalCode = "";
            ebooking.populateConsignor = false;
            showClearConsignor = false;
            selectedCountryConsignor = null;
            if (ebooking.pickupCheck) {
                ebooking.pickupCountry = ebooking.consignorCountry;
                ebooking.pickupCity = ebooking.consignorCity;
                ebooking.pickupPostalCode = ebooking.consignorPostalCode;
                ebooking.pickupAddress = ebooking.consignorAddress;
                ebooking.pickupCompanyName = ebooking.consignorCompanyName;
                var selectedCountryPickup = selectedCountryConsignor;
                this.setState({ selectedCountryPickup: selectedCountryPickup });
            }
        } else if (typePopulate == "consignee") {
            ebooking.clearConsignee = true;
            ebooking.consigneeCompanyName = "";
            ebooking.consigneeAddress = "";
            ebooking.consigneeCity = "";
            ebooking.consigneeCountry = "";
            ebooking.consigneePostalCode = "";
            ebooking.populateConsignee = false;
            showClearConsignee = false;
            selectedCountryConsignee = null;
            if (ebooking.deliverCheck) {
                ebooking.deliverCountry = ebooking.consigneeCountry;
                ebooking.deliverCity = ebooking.consigneeCity;
                ebooking.deliverPostalCode = ebooking.consigneePostalCode;
                ebooking.deliverAddress = ebooking.consigneeAddress;
                ebooking.deliverCompanyName = ebooking.consigneeCompanyName;
                var selectedCountryDelivery = selectedCountryConsignee;
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            }
        }
        this.setState({ ebooking, selectedCountryConsignee: selectedCountryConsignee, selectedCountryConsignor: selectedCountryConsignor, showClearConsignor: showClearConsignor, showClearConsignee: showClearConsignee });
        // console.log("clickClearPopulate");
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    componentDidMount() {
        //get redux data and set to state   
        var { ebooking, history, auth } = this.props;
        var authState = auth.state;
        var { selectedCountryConsignee, selectedCountryConsignor, selectedCountryDelivery, selectedCountryPickup, editFreightForwarder, showClearConsignor, showClearConsignee } = this.state;

        // this.props.ebookingActions.ebookingResetStep3().then(res => {
        //     ebooking = this.props.ebooking;
        //     this.setState({ ebooking });
        // });

        this.setState({ ebooking });
        if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        } else if (!ebooking.step_2_ok) {
            if (ebooking.PriceQuotation.pl.length > 0) {
                history.push('/ebooking/flight-pricing-pricelist');
            } else {
                switch (ebooking.PriceQuotation.type) {
                    case "WHITE":
                        history.push('/ebooking/flight-pricing-default');
                        break;
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;
                    default:
                        history.push('/ebooking/shipment');
                        break;
                }
            }
        }else if(auth.IsGSA){
            history.push('/ebooking/participants-gsa');
        }

        var showPickup = false;
        var showDelivery = false;
        var showFreightForwarder = false;
        var showGroundHandlingOrigin = false;
        var showGroundHandlingDestination = false;

        if (ebooking.deliver_from == "ZipCode") {
            showPickup = true;
        }

        if (ebooking.deliver_from == "Airport") {
            showGroundHandlingOrigin = true;
        }

        if (ebooking.deliver_to == "ZipCode") {
            showDelivery = true;
        }
        if (ebooking.deliver_to == "Airport") {
            showGroundHandlingDestination = true;
        }

        if (ebooking.deliver_to_airport == "TLV") {
            showFreightForwarder = true;
        }

        // showFreightForwarder = false;
        // showDelivery = false;
        // showPickup = false;
        // showGroundHandlingOrigin = true;
        // showGroundHandlingDestination = true;

        if (!ebooking.populatePickup && showPickup && ebooking.pickupCountry == "" && ebooking.pickupCity == "" && ebooking.pickupPostalCode == "") {
            ebooking.pickupCountry = ebooking.deliver_from_country;
            ebooking.pickupCity = ebooking.deliver_from_city;
            ebooking.pickupPostalCode = ebooking.deliver_from_zipcode;
            ebooking.populatePickup = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateDelivery && showDelivery && ebooking.deliverCountry == "" && ebooking.deliverCity == "" && ebooking.deliverPostalCode == "") {
            ebooking.deliverCountry = ebooking.deliver_to_country;
            ebooking.deliverCity = ebooking.deliver_to_city;
            ebooking.deliverPostalCode = ebooking.deliver_to_zipcode;
            ebooking.populateDelivery = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (showGroundHandlingDestination) {
            ebooking.populateDelivery = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }
        if (showGroundHandlingOrigin) {
            ebooking.populatePickup = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateConsignor && !ebooking.clearConsignor && ebooking.consignorCompanyName == "" && ebooking.consignorAddress == "" && ebooking.consignorCity == "" && ebooking.consignorCountry == "" && ebooking.consignorPostalCode == "") {
            if (ebooking.PriceQuotation.pl.length && ebooking.PriceQuotation.type !== "PARCEL") {
                if (ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.cnor && ebooking.PricelistChoosed.selectedPLData.cnor.trim() != "") {
                    ebooking.consignorCompanyName = ebooking.PricelistChoosed.selectedPLData.cnordes;
                    ebooking.consignorAddress = ebooking.PricelistChoosed.selectedPLData.cnor_address;
                    ebooking.consignorCity = ebooking.PricelistChoosed.selectedPLData.cnor_cityname;
                    ebooking.consignorCountry = ebooking.PricelistChoosed.selectedPLData.cnor_countryname;
                    ebooking.consignorPostalCode = ebooking.PricelistChoosed.selectedPLData.cnee_zipcode;
                    ebooking.populateConsignor = true;
                }

            } else if (ebooking.selectedSuggessCNOR.name != "") {
                ebooking.consignorCompanyName = ebooking.selectedSuggessCNOR.name;
                ebooking.consignorAddress = ebooking.selectedSuggessCNOR.address;
                ebooking.consignorCity = ebooking.selectedSuggessCNOR.cityname;
                ebooking.consignorCountry = ebooking.selectedSuggessCNOR.countryname;
                ebooking.consignorPostalCode = ebooking.selectedSuggessCNOR.zipcode;
                ebooking.populateConsignor = true;
            }

            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateConsignee && !ebooking.clearConsignee && ebooking.consigneeCompanyName == "" && ebooking.consigneeAddress == "" && ebooking.consigneeCity == "" && ebooking.consigneeCountry == "" && ebooking.consigneePostalCode == "") {
            if (ebooking.PriceQuotation.pl.length && ebooking.PriceQuotation.type !== "PARCEL") {
                if (ebooking.PricelistChoosed.selectedPLData && ebooking.PricelistChoosed.selectedPLData.cnee && ebooking.PricelistChoosed.selectedPLData.cnee.trim() != "") {
                    ebooking.consigneeCompanyName = ebooking.PricelistChoosed.selectedPLData.cneedes;
                    ebooking.consigneeAddress = ebooking.PricelistChoosed.selectedPLData.cnee_address;
                    ebooking.consigneeCity = ebooking.PricelistChoosed.selectedPLData.cnee_cityname;
                    ebooking.consigneeCountry = ebooking.PricelistChoosed.selectedPLData.cnee_countryname;
                    ebooking.consigneePostalCode = ebooking.PricelistChoosed.selectedPLData.cnee_zipcode;
                    ebooking.populateConsignee = true;
                }

            } else if (ebooking.selectedSuggessCNEE.name != "") {
                ebooking.consigneeCompanyName = ebooking.selectedSuggessCNEE.name;
                ebooking.consigneeAddress = ebooking.selectedSuggessCNEE.address;
                ebooking.consigneeCity = ebooking.selectedSuggessCNEE.cityname;
                ebooking.consigneeCountry = ebooking.selectedSuggessCNEE.countryname;
                ebooking.consigneePostalCode = ebooking.selectedSuggessCNEE.zipcode;
                ebooking.populateConsignee = true;
            }

            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateConsignee && ebooking.company_name_of_consignee != "" && ebooking.selectedSuggessCNEE.name == "") {
            ebooking.consigneeCompanyName = ebooking.company_name_of_consignee;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateConsignor && ebooking.company_name_of_consignor != "" && ebooking.selectedSuggessCNOR.name == "") {
            ebooking.consignorCompanyName = ebooking.company_name_of_consignor;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateImA && ebooking.selectedShippingReceiving == "Shipping" && ebooking.shippingCompanyName == "" && ebooking.shippingIATA1 == "" && ebooking.shippingIATA2 == "") {
            ebooking.shippingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            ebooking.populateImA = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        }

        if (!ebooking.populateImA && ebooking.selectedShippingReceiving == "Receiving" && ebooking.receivingCompanyName == "" && ebooking.receivingIATA1 == "" && ebooking.receivingIATA2 == "") {
            ebooking.receivingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            ebooking.populateImA = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
            editFreightForwarder = false;
        }

        if (ebooking.selectedShippingReceiving == "Shipping" && ebooking.PriceQuotation.pl.length && ebooking.PricelistChoosed.selectedPLData.rec && ebooking.PricelistChoosed.selectedPLData.rec.trim() != "") {
            ebooking.receivingCompanyName = ebooking.PricelistChoosed.selectedPLData.recdes.trim();
            ebooking.receivingIATA1 = "";
            ebooking.receivingIATA2 = "";
            ebooking.populateImA = true;
            // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
            editFreightForwarder = false;
        }
        // console.log("Didmount");
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        if (ebooking.PriceQuotation.type == "PARCEL") {
            ebooking.already_awb = false;
            ebooking.awb_number_1 = "700";
            ebooking.awb_number_2 = "";
            this.props.ebookingActions.ebookingChangeData({ fieldName: "already_awb", fieldValue: ebooking.already_awb }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "awb_number_1", fieldValue: ebooking.awb_number_1 }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "awb_number_2", fieldValue: ebooking.awb_number_2 }).then(responseData => { });
        }

        showClearConsignor = false;
        showClearConsignee = false;
        if (ebooking.populateConsignor && (ebooking.PriceQuotation.pl.length == 0 || (!ebooking.PricelistChoosed.selectedPLData.cnor || ebooking.PricelistChoosed.selectedPLData.cnor.trim() == ""))) {
            showClearConsignor = true;
        }

        if (ebooking.populateConsignee && (ebooking.PriceQuotation.pl.length == 0 || (!ebooking.PricelistChoosed.selectedPLData.cnee || ebooking.PricelistChoosed.selectedPLData.cnee.trim() == ""))) {
            showClearConsignee = true;
        }

        var countries = [];
        this.props.country.countries.forEach(c => {
            var country = { value: c.country, label: c.countryname };
            if (c.countryname == ebooking.consigneeCountry) {
                selectedCountryConsignee = country;
            }
            if (c.countryname == ebooking.consignorCountry) {
                selectedCountryConsignor = country;
            }
            if (c.countryname == ebooking.deliverCountry) {
                selectedCountryDelivery = country;
            }
            if (c.countryname == ebooking.pickupCountry) {
                selectedCountryPickup = country;
            }
            countries.push(country);
        });
        this.setState({
            showPickup: showPickup,
            showDelivery: showDelivery,
            showFreightForwarder: showFreightForwarder,
            showGroundHandlingOrigin: showGroundHandlingOrigin,
            showGroundHandlingDestination: showGroundHandlingDestination,
            ebooking,
            countries: countries,
            selectedCountryConsignee: selectedCountryConsignee,
            selectedCountryConsignor: selectedCountryConsignor,
            selectedCountryDelivery: selectedCountryDelivery,
            selectedCountryPickup: selectedCountryPickup,
            editFreightForwarder: editFreightForwarder,
            showClearConsignor: showClearConsignor,
            showClearConsignee: showClearConsignee
        });

        if ((showGroundHandlingOrigin || showGroundHandlingDestination) && (ebooking.deliver_from_airport_number != 0 || ebooking.deliver_to_airport_number != 0)) {
            this.getWarehouses(ebooking.deliver_from_airport_number, ebooking.deliver_to_airport_number);
        }
        if (showFreightForwarder) {
            this.getParticipentREC();
        }

        this.getParticipentCNOR();
        this.getParticipentCNEE();
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    handleOnBlur = (event) => {
        var { errors } = this.state;
        var { ebooking } = this.props;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        if (name == "shippingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
            }
        } else if (name == "shippingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
            }
        } else if (name == "receivingIATA1") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 7)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                if (ebooking.receivingIATA1.length == 7 && ebooking.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.receivingIATA1 + "" + ebooking.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_1;
            }
        } else if (name == "receivingIATA2") {
            if (validator.isEmpty(value) || (!validator.isEmpty(value) && value.length == 4)) {
                errors[name] = "";
                errors["receivingIATA"] = "";
                if (ebooking.receivingIATA1.length == 7 && ebooking.receivingIATA2.length == 4) {
                    this.handlerCheckIATACode(ebooking.receivingIATA1 + "" + ebooking.receivingIATA2);
                }
            } else {
                errors[name] = MESSAGES.ERROR_COMPANY_IATA_2;
            }
        } else if (name == "pickupEmail") {
            if (!validator.isEmpty(value) && validator.isEmail(value)) {
                errors[name] = "";
            }
        } else if (name == "deliverEmail") {
            if (!validator.isEmpty(value) && validator.isEmail(value)) {
                errors[name] = "";
            }
        } else {
            if (!validator.isEmpty(value)) {
                errors[name] = "";
            }
        }

        this.setState({ errors });
    }

    handlerCheckIATACode(iataCode, checkType = "FreightForwarder") {
        var { ebooking } = this.props;
        var { errors } = this.state;
        this.props.loadingActions.loadingChangeData(true);
        this.props.helpActions.checkACode(iataCode).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.length && response.data[0].reason.toUpperCase() == "VALID") {
                if ('FreightForwarder') {
                    ebooking.receivingCompanyName = response.data[0]['agency-name'];
                    ebooking.checkReceivingIATA = true;
                    errors['receivingIATA'] = "";
                    errors['receivingIATA1'] = "";
                    errors['receivingIATA2'] = "";
                } else {
                    ebooking.shippingCompanyName = response.data[0]['agency-name'];
                    errors['shippingIATA1'] = "";
                    errors['shippingIATA2'] = "";
                }
                this.setState({ ebookingActions });
                this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
            } else {
                if ('FreightForwarder') {
                    ebooking.checkReceivingIATA = false;
                    errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
                    errors['receivingIATA1'] = "";
                    errors['receivingIATA2'] = "";
                }
                this.setState({ ebookingActions });
                this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
            }
            this.setState({ errors });
        });
    }

    handleOnBlurSelect = (event) => {
        var { ebooking, errors, selectedCountryConsignee, selectedCountryConsignor, selectedCountryDelivery, selectedCountryPickup } = this.state;
        var { target, target: { id, name, type } } = event;
        if (id == "consignorCountry") {
            if (selectedCountryConsignor) {
                errors["consignorCountry"] = "";
            }
        }
        if (id == "consigneeCountry") {
            if (selectedCountryConsignee) {
                errors["consigneeCountry"] = "";
            }
        }
        if (id == "pickupCountry") {
            if (selectedCountryPickup) {
                errors["pickupCountry"] = "";
            }
        }
        if (id == "deliverCountry") {
            if (selectedCountryDelivery) {
                errors["deliverCountry"] = "";
            }
        }

        this.setState({ errors });
    }

    /* handler form's fields change value */
    handleChange(event) {
        var { auth } = this.props;
        var { errors, countries, editFreightForwarder, consigneeOptions, consignorOptions, showPickup, showDelivery } = this.state;
        var { ebooking } = this.props;
        const { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (name == "already_awb") {
            if (value == false) {
                ebooking["awb_number_1"] = "700";
                ebooking["awb_number_2"] = "";
                errors["awb_number"] = "";
                errors["awb_number_1"] = "";
                errors["awb_number_2"] = "";
            } else {
                ebooking["awb_number_1"] = "700";
            }
            ebooking[name] = value;
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == 'awb_number_1' || name == 'awb_number_2') {
            ebooking[name] = value;
            errors[name] = "";
            errors["awb_number"] = "";
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == "checkShipping") {
            ebooking["selectedShippingReceiving"] = "Shipping";
            ebooking["receivingCompanyName"] = "";
            ebooking["receivingIATA1"] = "";
            ebooking["receivingIATA2"] = "";
            errors["receivingCompanyName"] = "";
            errors["receivingIATA1"] = "";
            errors["receivingIATA2"] = "";

            ebooking.shippingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.shippingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.shippingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';

            if (ebooking.PriceQuotation.pl.length && ebooking.PricelistChoosed.selectedPLData.rec.trim() != "") {
                ebooking.receivingCompanyName = ebooking.PricelistChoosed.selectedPLData.recdes.trim();
                ebooking.receivingIATA1 = "";
                ebooking.receivingIATA2 = "";
                ebooking.populateImA = true;
                editFreightForwarder = false;
            } else {
                editFreightForwarder = true;
            }

            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == "checkReceiving") {
            ebooking["selectedShippingReceiving"] = "Receiving";
            ebooking["shippingCompanyName"] = "";
            ebooking["shippingIATA1"] = "";
            ebooking["shippingIATA2"] = "";
            errors["shippingCompanyName"] = "";
            errors["shippingIATA1"] = "";
            errors["shippingIATA2"] = "";

            ebooking.receivingCompanyName = auth.custdes ? auth.custdes : "";
            ebooking.receivingIATA1 = auth.iatacode ? auth.iatacode.substr(0, 7).trim() : '';
            ebooking.receivingIATA2 = (auth.iatacode && auth.iatacode.length > 7) ? auth.iatacode.substr(7, auth.iatacode.length - 7).trim() : '';
            editFreightForwarder = false;

            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });

        } else if (name == "deliverCheck") {
            if (value) {
                ebooking.deliverAddress = ebooking.consigneeAddress;
                ebooking.deliverCompanyName = ebooking.consigneeCompanyName;
                ebooking.deliverCountry = ebooking.consigneeCountry;
                ebooking.deliverCity = ebooking.consigneeCity;
                ebooking.deliverPostalCode = ebooking.consigneePostalCode;
                errors["deliverAddress"] = "";
                errors["deliverCompanyName"] = "";
                errors["deliverCountry"] = "";
                errors["deliverCity"] = "";
                errors["deliverPostalCode"] = "";
                var selectedCountryDelivery = this.state.selectedCountryConsignee;
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            } else {
                if (showDelivery) {
                    ebooking.deliverCountry = ebooking.deliver_to_country;
                    ebooking.deliverCity = ebooking.deliver_to_city;
                    ebooking.deliverPostalCode = ebooking.deliver_to_zipcode;
                    ebooking.populatePickup = true;
                }
                ebooking.deliverCompanyName = "";
                ebooking.deliverNameOfContact = "";
                ebooking.deliverAddress = "";

                // this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });                
                var selectedCountryDelivery = null;
                var findCountry = countries.find(c => c.label == ebooking.deliverCountry);
                if (findCountry) {
                    selectedCountryDelivery = findCountry;
                }
                // countries.forEach(c => {
                //     if (c.label == ebooking.deliverCountry) {
                //         selectedCountryDelivery = c;
                //     }
                // });
                this.setState({ selectedCountryDelivery: selectedCountryDelivery });
            }
            ebooking[name] = value;
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });

        } else if (name == "pickupCheck") {
            if (value) {
                ebooking.pickupAddress = ebooking.consignorAddress;
                ebooking.pickupCompanyName = ebooking.consignorCompanyName;
                ebooking.pickupCountry = ebooking.consignorCountry;
                ebooking.pickupCity = ebooking.consignorCity;
                ebooking.pickupPostalCode = ebooking.consignorPostalCode;
                errors["pickupAddress"] = "";
                errors["pickupCompanyName"] = "";
                errors["pickupCountry"] = "";
                errors["pickupCity"] = "";
                errors["pickupPostalCode"] = "";
                var selectedCountryPickup = this.state.selectedCountryConsignor;
                this.setState({ selectedCountryPickup: selectedCountryPickup });
            } else {
                if (showPickup) {
                    ebooking.pickupCountry = ebooking.deliver_from_country;
                    ebooking.pickupCity = ebooking.deliver_from_city;
                    ebooking.pickupPostalCode = ebooking.deliver_from_zipcode;
                    ebooking.populateDelivery = true;
                }
                ebooking.pickupCompanyName = "";
                ebooking.pickupNameOfContact = "";
                ebooking.pickupAddress = "";
                var selectedCountryPickup = null;
                var findCountry = countries.find(c => c.label == ebooking.pickupCountry);
                if (findCountry) {
                    selectedCountryPickup = findCountry;
                }
                // countries.forEach(c => {
                //     if (c.label == ebooking.pickupCountry) {
                //         selectedCountryPickup = c;
                //     }
                // });
                this.setState({ selectedCountryPickup: selectedCountryPickup });
            }

            ebooking[name] = value;
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == "receivingIATA1") {
            errors["receivingIATA1"] = "";
            errors["receivingIATA"] = "";
            ebooking[name] = value;
        } else if (name == "receivingIATA2") {
            errors["receivingIATA2"] = "";
            errors["receivingIATA"] = "";
            ebooking[name] = value;
        } else if (name == "consigneeGroundHandling") {
            errors["consigneeGroundHandling"] = "";
            ebooking[name] = value;
            ebooking.consigneeGroundHandlingData = { warhsname: "" };

            var findOption = consigneeOptions.find(x => x.value === value);
            if (findOption) {
                ebooking.consigneeGroundHandlingData = findOption;
            }
            this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeGroundHandlingData", fieldValue: ebooking.consigneeGroundHandlingData }).then(responseData => { });
            this.choosedConsigneeWarehouse();
        } else if (name == "consignorGroundHandling") {
            errors["consignorGroundHandling"] = "";
            ebooking[name] = value;
            ebooking.consignorGroundHandlingData = { warhsname: "" };
            var findOption = consignorOptions.find(x => x.value === value);
            if (findOption) {
                ebooking.consignorGroundHandlingData = findOption;
            }
            this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorGroundHandlingData", fieldValue: ebooking.consignorGroundHandlingData }).then(responseData => { });
            this.choosedConsignorWarehouse();
        } else {
            if (ebooking.pickupCheck) {
                switch (name) {
                    case "consignorCity":
                        ebooking.pickupCity = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCity", fieldValue: ebooking.pickupCity }).then(responseData => { });
                        break;
                    case "consignorAddress":
                        ebooking.pickupAddress = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupAddress", fieldValue: ebooking.pickupAddress }).then(responseData => { });
                        break;
                    case "consignorCompanyName":
                        ebooking.pickupCompanyName = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCompanyName", fieldValue: ebooking.pickupCompanyName }).then(responseData => { });
                        break;
                    case "consignorPostalCode":
                        ebooking.pickupPostalCode = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupPostalCode", fieldValue: ebooking.pickupPostalCode }).then(responseData => { });
                        break;
                }
            }
            if (ebooking.deliverCheck) {
                switch (name) {
                    case "consigneeCity":
                        ebooking.deliverCity = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCity", fieldValue: ebooking.deliverCity }).then(responseData => { });
                        break;
                    case "consigneeAddress":
                        ebooking.deliverAddress = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverAddress", fieldValue: ebooking.deliverAddress }).then(responseData => { });
                        break;
                    case "consigneeCompanyName":
                        ebooking.deliverCompanyName = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCompanyName", fieldValue: ebooking.deliverCompanyName }).then(responseData => { });
                        break;
                    case "consigneePostalCode":
                        ebooking.deliverPostalCode = value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverPostalCode", fieldValue: ebooking.deliverPostalCode }).then(responseData => { });
                        break;
                }
            }
            ebooking[name] = value;
            this.props.ebookingActions.ebookingChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        }

        this.setState({ ebooking, errors, editFreightForwarder: editFreightForwarder });

        //Change Data        
    }

    handleChangePickupCountry = (selectedCountryPickup) => {
        var { ebooking } = this.state;
        ebooking["pickupCountry"] = selectedCountryPickup.label;
        this.setState({ ebooking, selectedCountryPickup });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
    }

    handleChangeDeliveryCountry = (selectedCountryDelivery) => {
        var { ebooking } = this.state;
        ebooking["deliverCountry"] = selectedCountryDelivery.label;
        this.setState({ ebooking, selectedCountryDelivery });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCountry", fieldValue: ebooking.deliverCountry }).then(responseData => { });
    }

    handleChangeConsigneeCountry = (selectedCountryConsignee) => {
        var { ebooking } = this.state;
        ebooking["consigneeCountry"] = selectedCountryConsignee.label;
        if (ebooking.deliverCheck) {
            ebooking.deliverCountry = ebooking.consigneeCountry;
            this.setState({ selectedCountryDelivery: selectedCountryConsignee });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCountry", fieldValue: ebooking.deliverCountry }).then(responseData => { });
        }
        this.setState({ ebooking, selectedCountryConsignee });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCountry", fieldValue: ebooking.consigneeCountry }).then(responseData => { });
    }

    handleChangeConsignorCountry = (selectedCountryConsignor) => {
        var { ebooking } = this.state;
        ebooking["consignorCountry"] = selectedCountryConsignor.label;
        if (ebooking.pickupCheck) {
            ebooking.pickupCountry = ebooking.consignorCountry;
            this.setState({ selectedCountryPickup: selectedCountryConsignor });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
        }
        this.setState({ ebooking, selectedCountryConsignor });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCountry", fieldValue: ebooking.consignorCountry }).then(responseData => { });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        const { ebooking, showPickup, showDelivery, showFreightForwarder, showGroundHandlingOrigin, showGroundHandlingDestination } = this.state;

        if (ebooking.already_awb) {
            var error_awb = false;
            if (ebooking.awb_number_1 == "") {
                errors['awb_number_1'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
                error_awb = true;
            }
            if (ebooking.awb_number_2 == "" && !error_awb) {
                errors['awb_number_2'] = MESSAGES.ERROR_AWB_NUMBER2_ONLY;
                isError = true;
                error_awb = true;
            }
            if (ebooking.awb_number_1 != "" && ebooking.awb_number_2 != "" && !ebooking.isAwb && !error_awb) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                isError = true;
            }
        }
        // if (validator.isEmpty(ebooking.consignorCompanyName)) {
        //     errors['consignorCompanyName'] = MESSAGES.ERROR_REQUIRED;
        //     isError = true;
        // }
        if (validator.isEmpty(ebooking.consignorAddress) && !ebooking.populateConsignor && ebooking.consignorCompanyName) {
            errors['consignorAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(ebooking.consignorCity) && !ebooking.populateConsignor && ebooking.consignorCompanyName) {
            errors['consignorCity'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(ebooking.consignorCountry) && !ebooking.populateConsignor && ebooking.consignorCompanyName) {
            errors['consignorCountry'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(ebooking.consignorPostalCode) && !ebooking.populateConsignor && ebooking.consignorCompanyName) {
            errors['consignorPostalCode'] = "Required";
            isError = true;
        }
        // if (validator.isEmpty(ebooking.consigneeCompanyName)) {
        //     errors['consigneeCompanyName'] = MESSAGES.ERROR_REQUIRED;
        //     isError = true;
        // }
        if (validator.isEmpty(ebooking.consigneeAddress) && !ebooking.populateConsignee && ebooking.consigneeCompanyName) {
            errors['consigneeAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (validator.isEmpty(ebooking.consigneeCity) && !ebooking.populateConsignee && ebooking.consigneeCompanyName) {
            errors['consigneeCity'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(ebooking.consigneeCountry) && !ebooking.populateConsignee && ebooking.consigneeCompanyName) {
            errors['consigneeCountry'] = "Required";
            isError = true;
        }
        if (validator.isEmpty(ebooking.consigneePostalCode) && !ebooking.populateConsignee && ebooking.consigneeCompanyName) {
            errors['consigneePostalCode'] = "Required";
            isError = true;
        }

        if (!showFreightForwarder || (showFreightForwarder && ebooking.selectedShippingReceiving == "Shipping")) {
            if (validator.isEmpty(ebooking.shippingCompanyName) && !ebooking.populateImA) {
                errors['shippingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA1) && ebooking.shippingIATA1.length < 7) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA2) && ebooking.shippingIATA2.length < 4) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
            if (validator.isEmpty(ebooking.shippingIATA1) && !validator.isEmpty(ebooking.shippingIATA2)) {
                errors['shippingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
                isError = true;
            }
            if (!validator.isEmpty(ebooking.shippingIATA1) && validator.isEmpty(ebooking.shippingIATA2)) {
                errors['shippingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
                isError = true;
            }
        }

        if (showFreightForwarder && ebooking.selectedShippingReceiving != "Shipping") {
            if (validator.isEmpty(ebooking.receivingCompanyName) && !ebooking.populateImA) {
                errors['receivingCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }
        if (!validator.isEmpty(ebooking.receivingIATA1) && ebooking.receivingIATA1.length < 7) {
            errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }
        if (!validator.isEmpty(ebooking.receivingIATA2) && ebooking.receivingIATA2.length < 4) {
            errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }
        if (validator.isEmpty(ebooking.receivingIATA1) && !validator.isEmpty(ebooking.receivingIATA2)) {
            errors['receivingIATA1'] = MESSAGES.ERROR_COMPANY_IATA_1;
            isError = true;
        }
        if (!validator.isEmpty(ebooking.receivingIATA1) && validator.isEmpty(ebooking.receivingIATA2)) {
            errors['receivingIATA2'] = MESSAGES.ERROR_COMPANY_IATA_2;
            isError = true;
        }

        if (ebooking.receivingIATA1.length == 7 && ebooking.receivingIATA2.length == 4 && !ebooking.checkReceivingIATA) {
            errors['receivingIATA'] = MESSAGES.ERROR_COMPANY_IATA;
            isError = true;
        }

        if ((ebooking.pickupAddress == undefined || ebooking.pickupAddress == "") && (!ebooking.populatePickup || !showGroundHandlingOrigin)) {
            errors['pickupAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.pickupCity == undefined || ebooking.pickupCity == "") && !ebooking.populatePickup) {
            errors['pickupCity'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.pickupCountry == undefined || ebooking.pickupCountry == "") && !ebooking.populatePickup) {
            errors['pickupCountry'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.pickupPostalCode == undefined || ebooking.pickupPostalCode == "") && !ebooking.populatePickup) {
            errors['pickupPostalCode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (showPickup) {
            if (validator.isEmpty(ebooking.pickupEmail) || !validator.isEmail(ebooking.pickupEmail)) {
                errors['pickupEmail'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }
            if (validator.isEmpty(ebooking.pickupNameOfContact)) {
                errors['pickupNameOfContact'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (!ebooking.pickupCompanyName || validator.isEmpty(ebooking.pickupCompanyName)) {
                errors['pickupCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(ebooking.pickupOpeningHours)) {
                errors['pickupOpeningHours'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(ebooking.pickupPhoneNumber)) {
                errors['pickupPhoneNumber'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }

        if ((ebooking.deliverAddress == undefined || ebooking.deliverAddress == "") && (!ebooking.populateDelivery || !showGroundHandlingDestination)) {
            errors['deliverAddress'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.deliverCity == undefined || ebooking.deliverCity == "") && !ebooking.populateDelivery) {
            errors['deliverCity'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.deliverCountry == undefined || ebooking.deliverCountry == "") && !ebooking.populateDelivery) {
            errors['deliverCountry'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if ((ebooking.deliverPostalCode == undefined || ebooking.deliverPostalCode == "") && !ebooking.populateDelivery) {
            errors['deliverPostalCode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (showDelivery) {
            if (validator.isEmpty(ebooking.deliverEmail) || !validator.isEmail(ebooking.deliverEmail)) {
                errors['deliverEmail'] = MESSAGES.ERROR_EMAIL;
                isError = true;
            }
            if (validator.isEmpty(ebooking.deliverNameOfContact)) {
                errors['deliverNameOfContact'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(ebooking.deliverCompanyName)) {
                errors['deliverCompanyName'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(ebooking.deliverOpeningHours)) {
                errors['deliverOpeningHours'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
            if (validator.isEmpty(ebooking.deliverPhoneNumber)) {
                errors['deliverPhoneNumber'] = MESSAGES.ERROR_REQUIRED;
                isError = true;
            }
        }

        if (showGroundHandlingOrigin && (ebooking.consignorGroundHandling == "" || ebooking.consignorGroundHandling == undefined)) {
            errors['consignorGroundHandling'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (showGroundHandlingDestination && (ebooking.consigneeGroundHandling == "" || ebooking.consigneeGroundHandling == undefined)) {
            errors['consigneeGroundHandling'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_3_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_3_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        window.jumpToTop();
        const { history } = this.props;
        history.push('/ebooking/review-order');
    }

    handBack = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var { ebooking, history } = this.props;
        switch (ebooking.PriceQuotation.type) {
            case "WHITE":
                if (ebooking.PriceQuotation.pl.length > 0) {
                    history.push('/ebooking/flight-pricing-pricelist');
                } else {
                    history.push('/ebooking/flight-pricing-default');
                }
                break;
            case "PL":
                history.push('/ebooking/flight-pricing-pricelist');
                break;
            case "PARCEL":
                history.push('/ebooking/flight-pricing-parcel');
                break;

            default:
                history.push('/ebooking/shipment');
                break;
        }
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        var authState = this.props.auth.state;
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage, suggestionsCNEE, suggestionsCNOR, showDelivery, showPickup, showFreightForwarder, showGroundHandlingOrigin, showGroundHandlingDestination, consigneeOptions, consignorOptions, suggestionsReceiving, editFreightForwarder, showClearConsignor, showClearConsignee, consignorGroundHandlingDisabled, consigneeGroundHandlingDisabled }
            = this.state;

        // Autosuggest will pass through all these props to the input.
        var inputPropsCNEE = {
            placeholder: '',
            value: ebooking.consigneeCompanyName ? ebooking.consigneeCompanyName : "",
            onChange: this.onChangeSugestCNEE,
            disabled: ebooking.populateConsignee,
            readOnly: ebooking.populateConsignee
        };
        var inputPropsCNOR = {
            placeholder: '',
            value: ebooking.consignorCompanyName ? ebooking.consignorCompanyName : "",
            onChange: this.onChangeSugestCNOR,
            disabled: ebooking.populateConsignor,
            readOnly: ebooking.populateConsignor
        };
        var inputPropsREC = {
            placeholder: '',
            value: ebooking.receivingCompanyName ? ebooking.receivingCompanyName : "",
            onChange: this.onChangeSugestReceiving,
            disabled: !editFreightForwarder,
            readOnly: !editFreightForwarder
        };

        return (

            <div className="ebooking participants">

                <StepProgress stepData={steps} progress={70} onClick={this.handClickStep} />

                <div className="container">
                    <h1 className="title mt-5">Step two: Booking</h1>
                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                    </div>
                </div>


                <form>

                    <div className="container block-form">
                        <h1 className="formTitle mb-2">{ebooking.PriceQuotation.type == "PARCEL" ? "Parcel Shipment" : "The Parties Involved"}</h1>
                        <div className="formDescription" style={{ "maxWidth": "600px" }}>
                            Please fill in the details of the parties involved with this shipment.
                        </div>
                    </div>

                    <div className="container no_padding block-form mt-4">

                        <div className="form-content no_padding">
                            <div className={`row ${showFreightForwarder ? "" : "d-none"}`}>
                                <div className="col-12">
                                    <div className="container-child div_center">
                                        <div className="form-group form-rows">
                                            <label className={`checkbox-container inline-elements ${(errors.shipper) ? 'input-error' : ''}`}>I am a shipping agent
										        <input type="checkbox" tabIndex="9" name="checkShipping" value="1" checked={(ebooking.selectedShippingReceiving == "Shipping") ? true : false} onChange={this.handleChange} />
                                                <span className="checkmark-radio"></span>
                                            </label>
                                            <div className={`clb mt-2 d-block d-md-none`}></div>
                                            <label className="checkbox-container inline-elements ml-3 d-none d-md-block"> &nbsp;</label>
                                            <label className={`checkbox-container inline-elements ${(errors.shipper) ? 'input-error' : ''}`}>I am a freight forwarder
                                                <input type="checkbox" tabIndex="9" name="checkReceiving" value="1" checked={(ebooking.selectedShippingReceiving == "Receiving") ? true : false} onChange={this.handleChange} />
                                                <span className="checkmark-radio"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-between mt-5">
                                <div className="col-lg-6">
                                    <div className="container-child div_center">
                                        {/* Khong co bg xanh */}
                                        {/* Bắt đầu shipping agent */}
                                        <div className="h2-detail">Shipping Agent</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <label htmlFor="shippingCompanyName">Company Name<span className={`red ${showFreightForwarder ? "d-none" : "d-none"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.shippingCompanyName) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && ebooking.selectedShippingReceiving != "Shipping") || ebooking.populateImA) ? true : false} id="shippingCompanyName" name="shippingCompanyName" value={ebooking.shippingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <InputError error={errors.shippingCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <label htmlFor="shippingIATA1">IATA Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8 slash-after color-blue">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA1) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && ebooking.selectedShippingReceiving != "Shipping") || ebooking.populateImA) ? true : false} size="7" maxLength="7" id="shippingIATA1" name="shippingIATA1" onBlur={this.handleOnBlur} value={ebooking.shippingIATA1.trim()} onChange={this.handleChange} />
                                                                <EbookingInputError error={errors.shippingIATA1} />
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.shippingIATA2) ? 'input-error' : ''}`} readOnly={((showFreightForwarder && ebooking.selectedShippingReceiving != "Shipping") || ebooking.populateImA) ? true : false} size="4" maxLength="4" id="shippingIATA2" name="shippingIATA2" onBlur={this.handleOnBlur} value={ebooking.shippingIATA2.trim()} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Kết thúc shipping agent */}
                                    </div>
                                    <div className="container-child div_center">
                                        <div className="h2-detail mt-5">Consignor/Shipper Details <a className={` ${showClearConsignor ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "consignor")}>Clear</a></div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className={`form-group form-rows col-12 col-md-10 ${(errors.consignorCompanyName) ? 'input-error' : ''}`}>
                                                        <label htmlFor="consignorCompanyName">Company Name<span className={`red ${(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.consignorCompanyName) ? 'input-error' : ''}`} readOnly={ebooking.populateConsignor ? true : false} id="consignorCompanyName" name="consignorCompanyName" value={ebooking.consignorCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <Autosuggest
                                                            className={`form-control ${(errors.consignorCompanyName) ? 'input-error' : ''}`}
                                                            suggestions={suggestionsCNOR}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNOR}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNOR}
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            inputProps={inputPropsCNOR}
                                                        />
                                                        <EbookingInputError error={errors.consignorCompanyName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-bg-small">
                                        <div className="container-child div_center">
                                            <div className="block-form-content mt-3 div_center">
                                                <div className="form-group bg-blue">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorCountry">Country<span className={`red ${(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <Select
                                                                inputId="consignorCountry"
                                                                className={`select-box huge-input ${(errors.consignorCountry) ? 'input-error' : ''}`}
                                                                value={this.state.selectedCountryConsignor}
                                                                isSearchable={true}
                                                                isDisabled={(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? true : false}
                                                                onChange={this.handleChangeConsignorCountry}
                                                                options={this.state.countries}
                                                                isLoading={this.state.isLoadingCountry}
                                                                onBlur={this.handleOnBlurSelect}
                                                            />
                                                            <EbookingInputError error={errors.consignorCountry} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorCity">City<span className={`red ${(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consignorCity) ? 'input-error' : ''}`} readOnly={(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? true : false} id="consignorCity" name="consignorCity" value={ebooking.consignorCity} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                            <EbookingInputError error={errors.consignorCity} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consignorPostalCode">Postal Code<span className={`red ${(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consignorPostalCode) ? 'input-error' : ''}`} readOnly={(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? true : false} id="consignorPostalCode" name="consignorPostalCode" value={ebooking.consignorPostalCode} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                            <EbookingInputError error={errors.consignorPostalCode} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="consignorAddress">Address<span className={`red ${(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input className={`form-control ${(errors.consignorAddress) ? 'input-error' : ''}`} maxLength={80} readOnly={(ebooking.populateConsignor || !ebooking.consignorCompanyName) ? true : false} id="consignorAddress" name="consignorAddress" value={ebooking.consignorAddress} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            <EbookingInputError error={errors.consignorAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-child div_center">
                                        <h2 className={`h2-detail mt-5 mb-1`}>{ebooking.deliver_from == "Airport" ? "Dropoff Address" : "Pickup Details"}</h2>
                                        <div className="formDescription">{ebooking.deliver_from == "Airport" ? "Dropoff from airport, country." : "Pickup from city, country, zipcode."}</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group mb-2 ${showPickup ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12">
                                                        <label className="checkbox-container pdl-20">Pickup address is the same as the consignor address
                                                            <input type="checkbox" name="pickupCheck" value="pickupCheck" checked={ebooking.pickupCheck} disabled={!showPickup} onChange={this.handleChange} />
                                                            <span className={`checkmark-checkbox ${(errors.pickupCheck) ? 'input-error' : ''}`}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`mb-2 ${(showPickup || (!showDelivery && !showPickup)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "32px" }}></div>
                                            <div className={`form-group ${(showGroundHandlingOrigin) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="consignorGroundHandling">Ground Handling Warehouse<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <select className={`form-control ${(errors.consignorGroundHandling) ? 'input-error' : ''}`} name="consignorGroundHandling" disabled={(!showGroundHandlingOrigin || consignorGroundHandlingDisabled) ? true : false} value={ebooking.consignorGroundHandling} onChange={this.handleChange} >
                                                            <option value="" />
                                                            {
                                                                consignorOptions.map((op, i) => {
                                                                    return <option key={"consignee" + i} value={op.value}>{op.value}</option>;
                                                                })
                                                            }
                                                        </select>
                                                        <EbookingInputError error={errors.consignorGroundHandling} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${(showGroundHandlingOrigin || (!showGroundHandlingDestination && !showGroundHandlingOrigin) || (showPickup && !showDelivery && !showGroundHandlingOrigin && showGroundHandlingDestination)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "78px" }}></div>
                                            <div className={`form-group`}>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupCompanyName">Company Name<span className={`red ${(ebooking.pickupCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupCompanyName) ? 'input-error' : ''}`} maxLength={48} id="pickupCompanyName" name="pickupCompanyName" readOnly={ebooking.pickupCheck} value={ebooking.pickupCompanyName ? ebooking.pickupCompanyName : ''} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupCompanyName} />
                                                    </div>
                                                </div>
                                                {/* <div className={`row ${(showPickup || (!showPickup && !showDelivery)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "79px" }}></div> */}
                                                <div className="row">
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupCountry">Country<span className={`red ${(ebooking.pickupCheck || ebooking.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>

                                                        <Select
                                                            inputId="pickupCountry"
                                                            className={`select-box huge-input ${(errors.pickupCountry) ? 'input-error' : ''}`}
                                                            value={this.state.selectedCountryPickup}
                                                            isSearchable={true}
                                                            isDisabled={(ebooking.pickupCheck || ebooking.populatePickup) ? true : false}
                                                            onChange={this.handleChangePickupCountry}
                                                            options={this.state.countries}
                                                            isLoading={this.state.isLoadingCountry}
                                                            onBlur={this.handleOnBlurSelect}
                                                        />
                                                        <EbookingInputError error={errors.pickupCountry} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupCity">City<span className={`red ${(ebooking.pickupCheck || ebooking.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupCity) ? 'input-error' : ''}`} id="pickupCity" name="pickupCity" value={ebooking.pickupCity} readOnly={(ebooking.pickupCheck || ebooking.populatePickup) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                        <EbookingInputError error={errors.pickupCity} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="pickupPostalCode">Postal Code<span className={`red ${(ebooking.pickupCheck || ebooking.populatePickup) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupPostalCode) ? 'input-error' : ''}`} id="pickupPostalCode" name="pickupPostalCode" value={ebooking.pickupPostalCode} readOnly={(ebooking.pickupCheck || ebooking.populatePickup) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={80} />
                                                        <EbookingInputError error={errors.pickupPostalCode} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupAddress">Address<span className={`red ${(ebooking.pickupCheck || (ebooking.populatePickup && showGroundHandlingOrigin)) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input className={`form-control ${(errors.pickupAddress) ? 'input-error' : ''}`} maxLength={80} id="pickupAddress" name="pickupAddress" value={ebooking.pickupAddress} readOnly={(ebooking.pickupCheck || (ebooking.populatePickup && showGroundHandlingOrigin)) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupAddress} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupNameOfContact">Name of Contact<span className={`red ${(ebooking.pickupCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupNameOfContact) ? 'input-error' : ''}`} maxLength={48} id="pickupNameOfContact" name="pickupNameOfContact" value={ebooking.pickupNameOfContact} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupNameOfContact} />
                                                    </div>
                                                </div>
                                                {/* <hr className={`split-fields ${(showPickup) ? "" : "d-none"}`} /> */}
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupEmail">Email<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="Email" className={`form-control ${(errors.pickupEmail) ? 'input-error' : ''}`} id="pickupEmail" name="pickupEmail" value={ebooking.pickupEmail} maxLength={48} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupEmail} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="pickupPhoneNumber">Phone Number<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.pickupPhoneNumber) ? 'input-error' : ''}`} id="pickupPhoneNumber" name="pickupPhoneNumber" maxLength={20} value={ebooking.pickupPhoneNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupPhoneNumber} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12">
                                                        <label htmlFor="pickupOpeningHours">Opening Hours<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <textarea rows={4} className={`form-control ${(errors.pickupOpeningHours) ? 'input-error' : ''}`} id="pickupOpeningHours" name="pickupOpeningHours" value={ebooking.pickupOpeningHours} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupOpeningHours} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showPickup) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 mb-0">
                                                        <label htmlFor="pickupComments">Comments</label>
                                                        <textarea rows={4} className={`form-control ${(errors.pickupComments) ? 'input-error' : ''}`} id="pickupComments" name="pickupComments" value={ebooking.pickupComments} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.pickupComments} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 last-column">
                                    <div className="container-child div_center">
                                        {/* Khong co bg xanh */}
                                        {/* Bắt đầu shipping agent */}
                                        <div className={`h2-detail ${(showFreightForwarder) ? "" : "d-none d-md-block disable-block"}`}>Receiving Agent/Notify</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group ${(showFreightForwarder) ? "" : "d-none d-md-block disable-block"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <label htmlFor="receivingCompanyName">Company Name<span className={`red d-none rm-dnone`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''} ${editFreightForwarder ? "d-none" : ""}`} readOnly={true} id="receivingCompanyName" name="receivingCompanyName" value={ebooking.receivingCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <Autosuggest
                                                            className={`form-control ${(errors.receivingCompanyName) ? 'input-error' : ''} ${editFreightForwarder ? "" : "d-none"}`}
                                                            suggestions={suggestionsReceiving}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedReceiving}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequestedReceiving}
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            inputProps={inputPropsREC}
                                                            disabled={!showFreightForwarder}
                                                        />
                                                        <InputError error={errors.receivingCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-8">
                                                        <div className="row">
                                                            <div className="col-12 col-md-12">
                                                                <label htmlFor="receivingIATA1">IATA Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8 slash-after">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA1 || errors.receivingIATA) ? 'input-error' : ''}`} disabled={!showFreightForwarder} readOnly={!editFreightForwarder} size="7" maxLength="7" id="receivingIATA1" name="receivingIATA1" value={ebooking.receivingIATA1.trim()} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                                <EbookingInputError error={errors.receivingIATA1} />
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className={`form-control error-full-radius ${(errors.receivingIATA2 || errors.receivingIATA) ? 'input-error' : ''}`} disabled={!showFreightForwarder} readOnly={!editFreightForwarder} size="4" maxLength="4" id="receivingIATA2" name="receivingIATA2" value={ebooking.receivingIATA2.trim()} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Kết thúc shipping agent */}
                                    </div>

                                    <div className="container-child div_center">
                                        <div className={`h2-detail mt-5 ${showFreightForwarder ? "" : ""}`}>Consignee/Importer Details <a className={` ${showClearConsignee ? "" : "d-none"}`} href="javascript:void(0);" onClick={this.clickClearPopulate.bind(this, "consignee")}>Clear</a></div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className={`form-group form-rows col-12 col-md-10 ${(errors.consigneeCompanyName) ? 'input-error' : ''}`}>
                                                        <label htmlFor="consigneeCompanyName">Company Name<span className={`red ${(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        {/* <input type="text" className={`form-control ${(errors.consigneeCompanyName) ? 'input-error' : ''}`} readOnly={ebooking.populateConsignee ? true : false} id="consigneeCompanyName" name="consigneeCompanyName" value={ebooking.consigneeCompanyName} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                        <Autosuggest
                                                            className={`form-control ${(errors.consigneeCompanyName) ? 'input-error' : ''}`}
                                                            suggestions={suggestionsCNEE}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNEE}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNEE}
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            inputProps={inputPropsCNEE}
                                                        />
                                                        <EbookingInputError error={errors.consigneeCompanyName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="blue-bg-small">
                                        <div className="container-child div_center">
                                            <div className="block-form-content mt-3 div_center">
                                                <div className="form-group bg-blue">
                                                    <div className="row">
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneeCountry">Country<span className={`red ${(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <Select
                                                                inputId="consigneeCountry"
                                                                className={`select-box huge-input ${(errors.consigneeCountry) ? 'input-error' : ''}`}
                                                                value={this.state.selectedCountryConsignee}
                                                                isSearchable={true}
                                                                isDisabled={(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? true : false}
                                                                onChange={this.handleChangeConsigneeCountry}
                                                                options={this.state.countries}
                                                                isLoading={this.state.isLoadingCountry}
                                                                onBlur={this.handleOnBlurSelect}
                                                            />
                                                            <EbookingInputError error={errors.consigneeCountry} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneeCity">City<span className={`red ${(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consigneeCity) ? 'input-error' : ''}`} readOnly={(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? true : false} id="consigneeCity" name="consigneeCity" value={ebooking.consigneeCity} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                            <EbookingInputError error={errors.consigneeCity} />
                                                        </div>
                                                        <div className="form-group form-rows col-md-4 col-sm-12">
                                                            <label htmlFor="consigneePostalCode">Postal Code<span className={`red ${(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                            <input type="text" className={`form-control ${(errors.consigneePostalCode) ? 'input-error' : ''}`} readOnly={(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? true : false} id="consigneePostalCode" name="consigneePostalCode" value={ebooking.consigneePostalCode} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={10} />
                                                            <EbookingInputError error={errors.consigneePostalCode} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group form-rows col-12">
                                                            <label htmlFor="consigneeAddress">Address<span className={`red ${(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>

                                                            <input className={`form-control ${(errors.consigneeAddress) ? 'input-error' : ''}`} maxLength={255} readOnly={(ebooking.populateConsignee || !ebooking.consigneeCompanyName) ? true : false} id="consigneeAddress" name="consigneeAddress" value={ebooking.consigneeAddress} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                            <EbookingInputError error={errors.consigneeAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-child div_center">
                                        <h2 className={`h2-detail mt-5 mb-1`}>{ebooking.deliver_to == "ZipCode" ? "Delivery Details " : "Delivery Address"}</h2>
                                        <div className="formDescription">{ebooking.deliver_to == "Airport" ? "Delivery to airport, country." : "Delivery to city, country, zipcode."}</div>
                                        <div className="block-form-content mt-3 div_center">
                                            <div className={`form-group mb-2 ${(showDelivery) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12">
                                                        <label className="checkbox-container pdl-20">Delivery address is the same as the consignee address
                                                            <input type="checkbox" name="deliverCheck" value="deliverCheck" disabled={!showDelivery} checked={ebooking.deliverCheck} onChange={this.handleChange} />
                                                            <span className={`checkmark-checkbox ${(errors.deliverCheck) ? 'input-error' : ''}`}></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`mb-2 ${(showDelivery || (!showDelivery && !showPickup)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "32px" }}></div>
                                            <div className={`form-group ${(showGroundHandlingDestination) ? "" : "d-none"}`}>
                                                <div className="row">
                                                    <div className="form-group form-rows col-sm-12 col-md-10">
                                                        <label htmlFor="consigneeGroundHandling">Ground Handling Warehouse<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <select className={`form-control ${(errors.consigneeGroundHandling) ? 'input-error' : ''}`} name="consigneeGroundHandling" disabled={(!showGroundHandlingDestination || consigneeGroundHandlingDisabled) ? true : false} value={ebooking.consigneeGroundHandling} onChange={this.handleChange} >
                                                            <option value="" />
                                                            {
                                                                consigneeOptions.map((op, i) => {
                                                                    return <option key={"consignee" + i} value={op.value}>{op.value}</option>;
                                                                })
                                                            }
                                                        </select>
                                                        <EbookingInputError error={errors.consigneeGroundHandling} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${(showGroundHandlingDestination || (!showGroundHandlingDestination && !showGroundHandlingOrigin) || (!showPickup && showDelivery && showGroundHandlingOrigin && !showGroundHandlingDestination)) ? 'd-none' : 'd-none d-lg-block'}`} style={{ "height": "78px" }}></div>

                                            <div className={`form-group`}>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverCompanyName">Company Name<span className={`red ${(ebooking.deliverCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverCompanyName) ? 'input-error' : ''}`} maxLength={48} id="deliverCompanyName" name="deliverCompanyName" value={ebooking.deliverCompanyName ? ebooking.deliverCompanyName : ""} readOnly={ebooking.deliverCheck} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverCompanyName} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverCountry">Country<span className={`red ${(ebooking.deliverCheck || ebooking.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <Select
                                                            inputId="deliverCountry"
                                                            className={`select-box huge-input ${(errors.deliverCountry) ? 'input-error' : ''}`}
                                                            value={this.state.selectedCountryDelivery}
                                                            isSearchable={true}
                                                            isDisabled={(ebooking.deliverCheck || ebooking.populateDelivery) ? true : false}
                                                            onChange={this.handleChangeDeliveryCountry}
                                                            options={this.state.countries}
                                                            isLoading={this.state.isLoadingCountry}
                                                            onBlur={this.handleOnBlurSelect}
                                                        />
                                                        <EbookingInputError error={errors.deliverCountry} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverCity">City<span className={`red ${(ebooking.deliverCheck || ebooking.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverCity) ? 'input-error' : ''}`} id="deliverCity" name="deliverCity" value={ebooking.deliverCity} readOnly={(ebooking.deliverCheck || ebooking.populateDelivery) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30}/>
                                                        <EbookingInputError error={errors.deliverCity} />
                                                    </div>
                                                    <div className="form-group form-rows col-md-4 col-sm-12">
                                                        <label htmlFor="deliverPostalCode">Postal Code<span className={`red ${(ebooking.deliverCheck || ebooking.populateDelivery) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverPostalCode) ? 'input-error' : ''}`} id="deliverPostalCode" name="deliverPostalCode" value={ebooking.deliverPostalCode} readOnly={(ebooking.deliverCheck || ebooking.populateDelivery) ? true : false} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={10} />
                                                        <EbookingInputError error={errors.deliverPostalCode} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverAddress">Address<span className={`red ${(ebooking.deliverCheck || (ebooking.populateDelivery && showGroundHandlingDestination)) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input className={`form-control ${(errors.deliverAddress) ? 'input-error' : ''}`} maxLength={255} id="deliverAddress" name="deliverAddress" value={ebooking.deliverAddress} onBlur={this.handleOnBlur} readOnly={(ebooking.deliverCheck || (ebooking.populateDelivery && showGroundHandlingDestination)) ? true : false} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverAddress} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverNameOfContact">Name of Contact<span className={`red ${(ebooking.deliverCheck) ? "d-none" : "d-none rm-dnone"}`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverNameOfContact) ? 'input-error' : ''}`} maxLength={48} id="deliverNameOfContact" name="deliverNameOfContact" value={ebooking.deliverNameOfContact} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverNameOfContact} />
                                                    </div>
                                                </div>
                                                {/* <hr className={`split-fields ${(showDelivery) ? "" : "d-none"}`} /> */}
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverEmail">Email<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="Email" className={`form-control ${(errors.deliverEmail) ? 'input-error' : ''}`} id="deliverEmail" name="deliverEmail" value={ebooking.deliverEmail} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverEmail} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 col-md-10">
                                                        <label htmlFor="deliverPhoneNumber">Phone Number<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <input type="text" className={`form-control ${(errors.deliverPhoneNumber) ? 'input-error' : ''}`} id="deliverPhoneNumber" name="deliverPhoneNumber" value={ebooking.deliverPhoneNumber} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverPhoneNumber} />
                                                    </div>
                                                </div>

                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12">
                                                        <label htmlFor="deliverOpeningHours">Opening Hours<span className={`red d-none rm-dnone`}>*</span></label>
                                                        <textarea rows={4} className={`form-control ${(errors.deliverOpeningHours) ? 'input-error' : ''}`} id="deliverOpeningHours" name="deliverOpeningHours" value={ebooking.deliverOpeningHours} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverOpeningHours} />
                                                    </div>
                                                </div>
                                                <div className={`row ${(showDelivery) ? "" : "d-none"}`}>
                                                    <div className="form-group form-rows col-12 mb-0">
                                                        <label htmlFor="deliverComments">Comments</label>
                                                        <textarea rows={4} className={`form-control ${(errors.deliverComments) ? 'input-error' : ''}`} id="deliverComments" name="deliverComments" value={ebooking.deliverComments} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                                        <EbookingInputError error={errors.deliverComments} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="form-content">
                            <div className={`${ebooking.PriceQuotation.type == "PARCEL" ? "d-none" : "form-rows mt-4"}`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                    </div>
                                    <div className={`col-12 col-md-6 checkawb pl-0`}>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label className="checkbox-container">I already have an AWB
                                        <input type="checkbox" name="already_awb" id="already_awb" value="already_awb" checked={ebooking.already_awb ? ebooking.already_awb : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.already_awb) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={`${(ebooking.PriceQuotation.type == "PARCEL" || !ebooking.already_awb) ? "d-none" : "form-rows mt-3"}`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                        <label htmlFor="awb_number_1" className={`text-right`}>AWB Number</label>
                                    </div>
                                    <div className={`col-6 pl-0`}>
                                        <div className="row">
                                            <div className="col-4 slash-after color-blue">
                                                <input type="text" className={`form-control error-full-radius ${(errors.awb_number_1) ? 'input-error' : ''}`} size="3" maxLength="3" id="awb_number_1" readOnly={ebooking.already_awb ? true : true} name="awb_number_1" value={ebooking.awb_number_1 ? ebooking.awb_number_1 : ""} onBlur={this.isValidationAwb} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-8">
                                                <input type="text" className={`form-control ${(errors.awb_number_2 || errors.awb_number) ? 'input-error' : ''}`} size="8" maxLength="8" id="awb_number_2" name="awb_number_2" readOnly={ebooking.already_awb ? !ebooking.already_awb : true} value={ebooking.awb_number_2 ? ebooking.awb_number_2 : ""} onBlur={this.isValidationAwb} onChange={this.handleChange} />
                                                <EbookingInputError error={errors.awb_number_2} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={`form-rows ${ebooking.PriceQuotation.type == "PARCEL" ? "mt-4" : "mt-3"}`}>
                                <div className={`row border-between right-0`}>
                                    <div className={`col-6`}>
                                        <button type="button" onClick={this.handBack} className="btn btn-back right">Back to Choose Flight</button>
                                    </div>
                                    <div className={`col-6 pl-0`}>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <button type="button" disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : false} onClick={this.handSubmit} className="btn btn-ebooking-orange">Finish & Review Order</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>



            </div>


        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Participants);