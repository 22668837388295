import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
import moment from 'moment';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import ShipmentItem from "../../components/myaccount/element/ShipmentItem";
import clone from 'clone';
import BookingHelper from '../../components/BookingHelper';
import Select from 'react-select';
import AppConfig from '../../AppConfig';

/**
 * Validator
 */
import { bindActionCreators } from 'redux';

class ModalDashboardUpdateQuote extends React.Component {
    constructor(props) {
        super(props);
        const { shipmentData } = this.props;
        let shipments = clone(shipmentData);
        const oldShipments = clone(shipmentData);
        this.state = {
            errors: {},
            shipments,
            oldShipments,
            message: "",
            consigneeSuggests: [],
            consignorSuggests: [],
            maxp: 0,
            maxweight: 0,
            isLoadingConsignee: false,
            isLoadingConsignor: false,
            noOptionsCustomer: "Type to find company",
            inputValueConsignee: '',
            inputValueConsignor: '',
            isLoadingConsignee: false,
            isLoadingConsignor: false,
            consigneeOptions: [],
            consignorOptions: [],
            isHaveConsignee: false,
            isHaveConsignor: false,
            compareWeight: 0
        };
    }

    componentDidMount = async () => {
        var { shipments, isHaveConsignee, isHaveConsignor, compareWeight } = this.state;
        const { history, auth } = this.props;
        this.getParticipentCNEE();
        this.getParticipentCNOR();
        // console.log('shipments', shipments);
        this.props.loadingActions.loadingChangeData(true);
        let getBidMax = await this.props.ebookingActions.getBidMax(0, shipments.quoteID, auth.jwtToken);

        if (getBidMax.success && getBidMax.data.Success) {
            this.setState({
                maxp: parseFloat(getBidMax.data.bm[0].maxp),
                maxweight: parseFloat(getBidMax.data.bm[0].maxweight)
            });
        }
        let getTempData = await this.props.quotesActions.getTempData({ quoteID: shipments.quoteID, quoteData: shipments.quoteData }, auth.jwtToken);
        // console.log('getTempData', getTempData);
        this.props.loadingActions.loadingChangeData(false);
        if(getTempData.success && getTempData.data.Success){
            compareWeight = getTempData.data.tempData.CWeight;
        }else{
            compareWeight = shipments.quoteData.CWeight;
        }
        shipments.consignee_company = null;
        shipments.consignor_company = null;
        if (shipments.consignorCompanyName.trim()) {
            isHaveConsignor = true;
        }
        if (shipments.consigneeCompanyName.trim()) {
            isHaveConsignee = true;
        }
        if (!shipments.units_list.length) {
            shipments.units_list.push(
                {
                    'unitname': shipments.units_type_short,
                    'Pcs': '',
                    'Height': '',
                    'Length': '',
                    'Width': '',
                    'Cbm': '',
                    'paltype': null
                }
            );
            this.setState({ shipments });
        }

        this.setState({ shipments, isHaveConsignor, isHaveConsignee, compareWeight });
    }

    handleChange = async (event) => {
        const { currentChat, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        // value = value.replace(/[^0-9.]/g, "");
        // var strArray = name.split("_");
        // let key = strArray[1];
        // if (currentChat.ChatData.OfferData[key].price !== value) {
        //     currentChat.ChatData.OfferData[key].isUpdated = true;
        // }
        // currentChat.ChatData.OfferData[key].price = value;
        // if (value === '' || parseFloat(value) <= 0) {
        //     errors[name] = 'Invalid';
        // } else {
        //     errors[name] = '';
        // }
        // this.setState({ currentChat, errors });
    }

    handleChangeConsignee = async (selectedOption) => {
        var { errors, shipments } = this.state;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        shipments.consignee_company = selectedOption;
        this.setState({ shipments });
    }
    handleChangeConsignor = async (selectedOption) => {
        var { errors, shipments } = this.state;
        if (Array.isArray(selectedOption) && !selectedOption.length) {
            selectedOption = null;
        }
        shipments.consignor_company = selectedOption;
        this.setState({ shipments });
    }

    handleChangePaltype = async (event) => {
        const { shipments, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        shipments.units_paltype = value;
        if (shipments.units_paltype === 'uld') {
            shipments.units_type_short = 'CM';
        }
        this.setState({ shipments, errors });
    }

    handleChangeUnit = async (event) => {
        const { shipments, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        shipments.units_type_short = value;
        this.setState({ shipments, errors });
    }

    handleTextChange = (event) => {
        var { target } = event;
        this.setState({ message: target.value });

    }

    handleSend = async () => {
        const { currentChat, message } = this.state;
        this.props.handleCounter(message, currentChat);
    }

    handleOnBlur = async (event) => {
        var { errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        this.setState({ errors });
    }

    calculatorTotalChargableWeight = async () => {
        var { errors, shipments } = this.state;
        var totalChargableWeight = 0;
        var totalWeight = shipments.units_total_weight;
        if (shipments.units_list) {
            var totalVolume = BookingHelper.calculatorVolumeKG(shipments.units_total_volume);
            if (totalWeight != "" && validator.isNumeric(totalWeight + "")) {
                totalWeight = parseFloat(totalWeight);
                totalChargableWeight = Math.max(totalVolume, totalWeight);
                shipments.units_total_chargable_weight = totalChargableWeight.toFixed(3);
            } else {
                shipments.units_total_chargable_weight = totalVolume.toFixed(3);
            }
        } else {
            if (shipments.units_total_volume > 0) {
                var totalVolume = BookingHelper.calculatorVolumeKG(shipments.units_total_volume);
                totalChargableWeight = Math.max(totalVolume, totalWeight);
                shipments.units_total_chargable_weight = totalChargableWeight.toFixed(3);
            } else {
                shipments.units_total_chargable_weight = totalVolume.toFixed(3);
            }
        }
        errors['units_total_chargable_weight'] = "";
        this.setState({ errors, shipments });
    }

    handleDeleteShipmentItem = async (dataIndex) => {
        let { shipments } = this.state;
        shipments.units_list.splice(dataIndex, 1);

        if (!shipments.units_list.length) {
            shipments.units_list.push(
                {
                    'unitname': shipments.units_type_short,
                    'Pcs': '',
                    'Height': '',
                    'Length': '',
                    'Width': '',
                    'Cbm': '',
                    'paltype': null
                }
            );
        }

        var overlap_shipment_check = false;
        var totalPieces = 0;
        var totalVolume = 0;
        var totalChargableWeight = 0;
        var totalWeight = shipments.units_total_weight;
        shipments.units_list.forEach(unit_item => {
            if ((!validator.isEmpty(unit_item.Pcs + "") && validator.isInt(unit_item.Pcs + ""))) {
                totalPieces += parseInt(unit_item.Pcs);
            }
            if (((!validator.isEmpty(unit_item.Pcs + "") && validator.isInt(unit_item.Pcs + ""))) && ((!validator.isEmpty(unit_item.Length + "") && validator.isNumeric(unit_item.Length + ""))) && ((!validator.isEmpty(unit_item.Width + "") && validator.isNumeric(unit_item.Width + ""))) && ((!validator.isEmpty(unit_item.Height + "") && validator.isNumeric(unit_item.Height + "")))) {
                unit_item.Cbm = BookingHelper.calculatorVolumeNew(shipments.units_type_short, unit_item.Length, unit_item.Width, unit_item.Height);
                totalVolume += (parseInt(unit_item.Pcs) * parseFloat(unit_item.Cbm));
            }

            if (shipments.units_type_short == "cm" || shipments.units_type_short == "CM") {
                var min = Math.min(parseFloat(unit_item.Width), parseFloat(unit_item.Length)) + (parseFloat(unit_item.Height) - 240);
                if (parseFloat(unit_item.Length) > 317 || (parseFloat(unit_item.Width) > 244 && parseFloat(unit_item.Length) > 244) || min > 244) {
                    overlap_shipment_check = true;
                }
            } else {
                var min = Math.min(parseFloat(unit_item.Width), parseFloat(unit_item.Length)) + (parseFloat(unit_item.Height) - 94.5);
                if (parseFloat(unit_item.Length) > 124.8 || (parseFloat(unit_item.Width) > 96 && parseFloat(unit_item.Length) > 96) || min > 96) {
                    overlap_shipment_check = true;
                }
            }
        });
        shipments.overlap_shipment_check = overlap_shipment_check;
        shipments.units_total_num_of_pieces = totalPieces;
        shipments.units_total_volume = totalVolume;
        this.setState({ shipments });
        await this.calculatorTotalChargableWeight();
    }

    handleAddShipmentItem = async () => {
        var { shipments } = this.state;

        if (shipments.units_list.length < AppConfig.maxShipmentItems) {
            shipments.units_list.push(
                {
                    'unitname': shipments.units_type_short,
                    'Pcs': '',
                    'Height': '',
                    'Length': '',
                    'Width': '',
                    'Cbm': '',
                    'paltype': null
                }
            );
        }
        this.setState({ shipments });
    }

    handleUpdateShipmentItem = async (dataIndex, data) => {
        let { shipments } = this.state;
        shipments.units_list[dataIndex] = data;

        var overlap_shipment_check = false;
        var totalPieces = 0;
        var totalVolume = 0;
        var totalChargableWeight = 0;
        var totalWeight = shipments.units_total_weight;
        shipments.units_list.forEach(unit_item => {
            if ((!validator.isEmpty(unit_item.Pcs + "") && validator.isInt(unit_item.Pcs + ""))) {
                totalPieces += parseInt(unit_item.Pcs);
            }
            if (((!validator.isEmpty(unit_item.Pcs + "") && validator.isInt(unit_item.Pcs + ""))) && ((!validator.isEmpty(unit_item.Length + "") && validator.isNumeric(unit_item.Length + ""))) && ((!validator.isEmpty(unit_item.Width + "") && validator.isNumeric(unit_item.Width + ""))) && ((!validator.isEmpty(unit_item.Height + "") && validator.isNumeric(unit_item.Height + "")))) {
                unit_item.Cbm = BookingHelper.calculatorVolumeNew(shipments.units_type_short, unit_item.Length, unit_item.Width, unit_item.Height);
                totalVolume += (parseInt(unit_item.Pcs) * parseFloat(unit_item.Cbm));
            }

            if (shipments.units_type_short == "cm" || shipments.units_type_short == "CM") {
                var min = Math.min(parseFloat(unit_item.Width), parseFloat(unit_item.Length)) + (parseFloat(unit_item.Height) - 240);
                if (parseFloat(unit_item.Length) > 317 || (parseFloat(unit_item.Width) > 244 && parseFloat(unit_item.Length) > 244) || min > 244) {
                    overlap_shipment_check = true;
                }
            } else {
                var min = Math.min(parseFloat(unit_item.Width), parseFloat(unit_item.Length)) + (parseFloat(unit_item.Height) - 94.5);
                if (parseFloat(unit_item.Length) > 124.8 || (parseFloat(unit_item.Width) > 96 && parseFloat(unit_item.Length) > 96) || min > 96) {
                    overlap_shipment_check = true;
                }
            }
        });
        shipments.overlap_shipment_check = overlap_shipment_check;
        shipments.units_total_num_of_pieces = totalPieces;
        shipments.units_total_volume = totalVolume;
        this.setState({ shipments });
        await this.calculatorTotalChargableWeight();
    }

    buildShipmentItems = () => {
        const { shipments } = this.state;
        let returnItems = [];
        if (shipments && shipments.units_list) {
            shipments.units_list.map((unit_item, i) => {
                returnItems.push(<ShipmentItem ref={'unit-item-' + i} key={`unit-item-${i}`} dataIndex={i} data={unit_item} isUld={shipments.units_paltype === 'pieces' ? false : true} unitname={shipments.units_type_short} onDelete={this.handleDeleteShipmentItem} onUpdate={this.handleUpdateShipmentItem} />);
            })
        }
        return returnItems;
    }

    handleClickSend = async (e) => {
        const { maxp, maxweight, shipments, oldShipments, compareWeight } = this.state;
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        var checkValidator = await this.isValidation();
        if (checkValidator) {
            return false;
        }
        let changeP = 0;
        let units_total_chargable_weight = parseFloat(shipments.units_total_chargable_weight);
        let old_units_total_chargable_weight = parseFloat(compareWeight);
        // console.log("compareWeight", compareWeight);
        if (old_units_total_chargable_weight > 0) {
            changeP = ((units_total_chargable_weight - old_units_total_chargable_weight) / old_units_total_chargable_weight) * 100;
        }
        if (changeP > 10 || (units_total_chargable_weight - old_units_total_chargable_weight) > maxweight) {
            this.props.handleExceedsLimitations(shipments);
        } else {
            this.props.handleUpdateShipmentSuccess(shipments);
            this.props.closeModal();
        }
    }

    checkShipmentItemError = () => {
        const { shipments } = this.state;
        var checkError = true;
        shipments.units_list.forEach(item => {
            if ((validator.isEmpty(item.Pcs + "") || !validator.isInt(item.Pcs + "") || parseInt(item.Pcs) <= 0) || ((validator.isEmpty(item.Length + "") || !validator.isNumeric(item.Length + "")) || parseFloat(item.Length) <= 0) || ((validator.isEmpty(item.Width + "") || !validator.isNumeric(item.Width + "")) || parseFloat(item.Width) <= 0) || ((validator.isEmpty(item.Height + "") || !validator.isNumeric(item.Height + "")) || parseFloat(item.Height) <= 0)) {
                checkError = false;
            }
        });
        return checkError;
    }

    isValidation = async () => {
        var isError = false;
        var checkError = this.checkShipmentItemError();
        if (!checkError) {
            Object.keys(this.refs)
                .filter(key => key.substr(0, 9) === 'unit-item')
                .forEach(key => {
                    this.refs[key].checkErrors();
                });
            isError = true;
        }
        return isError;
    }

    getParticipentCNEE = async () => {
        var { consigneeSuggests, shipments } = this.state;
        consigneeSuggests = [];
        let response = await this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNEE", this.props.auth.jwtToken);
        if (response.success && response.data.Success) {
            response.data.customer.map(c => {
                consigneeSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
            });
        }
        if (shipments.consigneeCompanyName && shipments.consigneeCompanyName.trim()) {
            let consigneeCompanyName = shipments.consigneeCompanyName.trim();
            let findConsignee = consigneeSuggests.find(el => el.name.toLowerCase() == consigneeCompanyName.toLowerCase());
            if (findConsignee) {
                shipments.consignee_company = findConsignee;
            }
        }
        this.setState({ consigneeSuggests, shipments });
        if (shipments.consigneeCompanyName && shipments.consigneeCompanyName.trim() && !shipments.consignee_company) {
            this.handleScanCust('Consignee', shipments.consigneeCompanyName.trim());
        }
    }

    getParticipentCNOR = async () => {
        var { consignorSuggests, shipments } = this.state;
        consignorSuggests = [];
        let response = await this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNOR", this.props.auth.jwtToken);
        if (response.success && response.data.Success) {
            response.data.customer.map(c => {
                consignorSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
            });
        }

        if (shipments.consignorCompanyName && shipments.consignorCompanyName.trim()) {
            let consignorCompanyName = shipments.consignorCompanyName.trim();
            let findConsignor = consignorSuggests.find(el => el.name.toLowerCase() == consignorCompanyName.toLowerCase());
            if (findConsignor) {
                shipments.consignor_company = findConsignor;
            }
        }
        this.setState({ consignorSuggests, shipments });
        if (shipments.consignorCompanyName && shipments.consignorCompanyName.trim() && !shipments.consignor_company) {
            this.handleScanCust('Consignor', shipments.consignorCompanyName.trim());
        }
    }

    handlerSelectTypingConsignee = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueConsignee: e });
        if (this.timeoutSearchConsignee) {
            clearTimeout(this.timeoutSearchConsignee);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchConsignee = setTimeout(function () {
            t.handleScanCust('Consignee');
        }, 1000);
    }

    handlerSelectTypingConsignor = (e) => {
        var t = this;
        var noOptionsCustomer = "Type to find company";
        // e = e.replace(/[^a-zA-Z0-9-_.#& ]/g, "");
        if (e.length > 32) {
            e = e.substring(0, 32);
        }
        this.setState({ inputValueConsignor: e });
        if (this.timeoutSearchConsignor) {
            clearTimeout(this.timeoutSearchConsignor);
        }
        this.setState({ noOptionsCustomer });
        this.timeoutSearchConsignor = setTimeout(function () {
            t.handleScanCust('Consignor');
        }, 1000);
    }

    handleScanCust = async (selectName, findSelected = '') => {
        var { shipments, noOptionsCustomer, inputValueConsignee, inputValueConsignor, consigneeOptions, consignorOptions, consigneeSuggests, consignorSuggests } = this.state;
        var keyword = '';
        switch (selectName) {
            case 'Consignee':
                keyword = findSelected ? clone(findSelected) : clone(inputValueConsignee);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingConsignee: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(findSelected ? findSelected : inputValueConsignee);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        consigneeOptions = consigneeSuggests;
                        if (!consigneeOptions.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        consigneeOptions = consigneeSuggests.concat(scanCustData.data);
                    }
                    if (findSelected) {
                        let findSelectedIOption = consigneeOptions.find(el => el.label.toLowerCase() === findSelected.toLocaleLowerCase());
                        if (findSelectedIOption) {
                            shipments.consignee_company = findSelectedIOption;
                        }
                    }
                    this.setState({ isLoadingConsignee: false, noOptionsCustomer, consigneeOptions, shipments });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
            case 'Consignor':
                keyword = findSelected ? clone(findSelected) : clone(inputValueConsignor);
                keyword = keyword.trim();
                if (keyword.length > 2) {
                    var noOptionsCustomer = "Type to find company";
                    this.setState({ isLoadingConsignor: true });
                    var scanCustData = await this.props.quotesActions.scanCompany(findSelected ? findSelected : inputValueConsignor);
                    var { quotes } = this.props;
                    if (!scanCustData.success || !scanCustData.data.length) {
                        consignorOptions = consignorSuggests;
                        if (!consignorOptions.length) {
                            noOptionsCustomer = "Not found!";
                        }
                    } else {
                        consignorOptions = consignorSuggests.concat(scanCustData.data);
                    }
                    if (findSelected) {
                        let findSelectedIOption = consignorOptions.find(el => el.label.toLowerCase() === findSelected.toLocaleLowerCase());
                        if (findSelectedIOption) {
                            shipments.consignor_company = findSelectedIOption;
                        }
                    }
                    this.setState({ isLoadingConsignor: false, noOptionsCustomer, consignorOptions, shipments });
                    await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
                }
                break;
        }

    }

    showNoOptionCustomer = () => {
        if (this.state.noOptionsCustomer === 'Not found!') {
            return (
                // <div><span>Not found!</span><span className="optionAddCustomer" onClick={this.handleClickShowNewCustomerForm}>Add new</span></div>
                <div><span>Not found!</span></div>
            );
        } else {
            return (
                <span>{this.state.noOptionsCustomer}</span>
            );
        }
    }

    render() {
        const { onShow } = this.props;
        const { shipments } = this.state;
        if (!onShow)
            return (null);


        return (
            <div className={`modal-dialog modal-dialog-medium modal-dialog-centered ${shipments && shipments.units_paltype ? shipments.units_paltype : ''}`} role="document">
                <div className="modal-content booking-modals shadow">
                    <div className="modal-body">
                        <div className="close-button" onClick={this.props.closeModal}></div>
                        <div className="modal-title">
                            <h5 className="text-uppercase">UPDATE SHIPMENT</h5>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="popup-description" style={{ 'maxWidth': '275px' }}>Update the relevant details and click save to let us know</div>
                            </div>
                        </div>
                        <div className="ml-2 mr-2 mt-5">
                            <div className="row d-none">
                                <div className="col-12 text-right">
                                    <select className="form-control d-inline-block shadow mr-2" name="unittype" value={shipments ? shipments.units_paltype : 'pieces'} onChange={this.handleChangePaltype}>
                                        <option value="pieces">Pieces</option>
                                        <option value="uld">ULD</option>
                                    </select>
                                    <select className="form-control d-inline-block shadow" name="unitname" disabled={shipments.units_paltype === 'uld' ? true : false} value={shipments ? shipments.units_type_short : 'CM'} onChange={this.handleChangeUnit}>
                                        <option value="CM">CM</option>
                                        <option value="INCH">INCH</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-4">
                                {
                                    shipments.units_paltype === 'pieces' ? (
                                        <>
                                            <div className="col-12">
                                                <div className="quote-flex-text edit shipment display-flex">
                                                    <div className="input-group-quote-text no-radius group-1">
                                                        <label>Pieces</label>
                                                    </div>
                                                    <div className="input-group-quote-text no-radius group-2">
                                                        <label>Length</label>
                                                    </div>
                                                    <div className="input-group-quote-text no-radius group-3">
                                                        <label>Width</label>
                                                    </div>
                                                    <div className="input-group-quote-text no-radius group-3-1">
                                                        <label>Height</label>
                                                    </div>
                                                    <div className="input-group-quote-text no-radius group-4">
                                                        <label>Volume (CBM)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.buildShipmentItems()}
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-12">
                                                <div className={`quote-flex-text edit shipment uld display-flex`}>
                                                    <div className={`input-group-quote-text no-radius group-1`}>
                                                        <label >ULD Type</label>
                                                    </div>
                                                    <div className={`input-group-quote-text no-radius group-2`}>
                                                        <label >Pieces</label>
                                                    </div>
                                                    <div className={`input-group-quote-text no-radius group-3`}>
                                                        <label >Length</label>
                                                    </div>
                                                    <div className={`input-group-quote-text no-radius group-3-1`}>
                                                        <label >Width</label>
                                                    </div>
                                                    <div className={`input-group-quote-text no-radius group-3-2`}>
                                                        <label >Height</label>
                                                    </div>
                                                    <div className={`input-group-quote-text no-radius group-4`}>
                                                        <label >Volume (CBM)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.buildShipmentItems()}
                                        </>
                                    )
                                }
                            </div>

                            <div className="row mt-1">
                                <div className="col-12">
                                    <button type="button" className="btn btn-add-orange no-border-radius add-small shadow" onClick={this.handleAddShipmentItem.bind(this)}>+</button>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="quote-flex total edit shipment display-flex shadow mb-2">
                                        <div className="input-group-quote no-radius group-1  ">
                                            <label className=" ">{shipments ? shipments.units_total_num_of_pieces : ''}</label>
                                        </div>
                                        <div className="input-group-quote no-radius group-2  ">
                                            <label className=" ">{shipments ? shipments.units_total_volume : ''} <span>{shipments ? shipments.units_type_short : ''}</span></label>
                                        </div>
                                        <div className="input-group-quote no-radius group-3  ">
                                            <label className=" ">{shipments ? shipments.units_total_weight : ''} <span>kg</span></label>
                                        </div>
                                        <div className="input-group-quote no-radius group-4">
                                            <label className="">{shipments ? shipments.units_total_chargable_weight : ''} <span>KG</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="quote-flex-text total shipment display-flex">
                                        <div className="input-group-quote-text no-radius group-1">
                                            <label>Total Pieces</label>
                                        </div>
                                        <div className="input-group-quote-text no-radius group-2">
                                            <label>Total Volume</label>
                                        </div>
                                        <div className="input-group-quote-text no-radius group-3">
                                            <label>Total Weight</label>
                                        </div>
                                        <div className="input-group-quote-text no-radius group-4">
                                            <label>TCW</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="layer-content">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-12 ml-4 mr-4">
                                    <label className="form-label" htmlFor="txtSubject">Consignee/ Importer Company Name</label>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4">
                                        <Select
                                            inputId="consignee"
                                            noOptionsMessage={this.showNoOptionCustomer}
                                            placeholder={``}
                                            className={`select-box huge-input ${this.state.shipments.consignee_company ? 'bg-grey' : ''}`}
                                            value={this.state.shipments.consignee_company ? this.state.shipments.consignee_company : null}
                                            isSearchable={true}
                                            isDisabled={this.state.isHaveConsignee}
                                            options={this.state.consigneeOptions}
                                            isLoading={this.state.isLoadingConsignee}
                                            onChange={this.handleChangeConsignee}
                                            inputValue={this.state.inputValueConsignee}
                                            onInputChange={this.handlerSelectTypingConsignee}
                                        // filterOption={this.filterOption}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 ml-4 mr-4">
                                    <label className="form-label" htmlFor="txtSubject">Consignor/ Shipper Company Name</label>
                                </div>
                                <div className="col-12">
                                    <div className="pl-4 pr-4">
                                        <Select
                                            inputId="consignor"
                                            noOptionsMessage={this.showNoOptionCustomer}
                                            placeholder={``}
                                            className={`select-box huge-input ${this.state.shipments.consignor_company ? 'bg-grey' : ''}`}
                                            value={this.state.shipments.consignor_company ? this.state.shipments.consignor_company : null}
                                            isSearchable={true}
                                            isDisabled={this.state.isHaveConsignor}
                                            options={this.state.consignorOptions}
                                            isLoading={this.state.isLoadingConsignor}
                                            onChange={this.handleChangeConsignor}
                                            inputValue={this.state.inputValueConsignor}
                                            onInputChange={this.handlerSelectTypingConsignor}
                                        // filterOption={this.filterOption}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer no-border mt-4">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-orange" onClick={this.handleClickSend}>SAVE</button>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-link" onClick={this.props.closeModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch),
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDashboardUpdateQuote);
