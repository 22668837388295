import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import StepProgress from '../../layouts/partials/StepProgress';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import uuid from 'uuid';
/**
 * Validator
 */
import EbookingInputError from '../EbookingInputError';
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import BookingHelper from './BookingHelper';

class FlightPricingParcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                checkbox_term_parcel: false,
            },
            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'validate': false,
                    'current': true,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            inCase: 5,
            noExpress: false
        };

        this.handClickStep = this.handClickStep.bind(this);
        this.handChoosePrice = this.handChoosePrice.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickExpressLink = this.handleClickExpressLink.bind(this);
    }

    handleClickExpressLink = () => {
        this.setPriceQuotation();
    }

    setPriceQuotation = () => {

        const { history, ebooking } = this.props;
        var { errorText } = this.state;
        errorText = "";
        this.setState({ errorText });

        var sendEbooking = ebooking.passedData;
        sendEbooking.MustRide = "A";
        // history.push('/ebooking/participants');
        this.props.loadingActions.loadingChangeData(true);
        var jwtToken = this.props.auth.jwtToken;
        this.props.ebookingActions.submitStepOne(sendEbooking, jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                ebooking.ParcelChoosed = {};
                ebooking.PricelistChoosed = { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} };
                ebooking.DefaultChoosed = { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } };
                ebooking.PriceQuotation = response.data;
                ebooking.passedData = response.passedData;

                if (ebooking.populateImA) {
                    ebooking.receivingCompanyName = "";
                    ebooking.receivingIATA1 = "";
                    ebooking.receivingIATA2 = "";
                    ebooking.shippingCompanyName = "";
                    ebooking.shippingIATA1 = "";
                    ebooking.shippingIATA2 = "";
                }
                if (ebooking.populateConsignor) {
                    ebooking.consignorCompanyName = "";
                    ebooking.consignorAddress = "";
                    ebooking.consignorCity = "";
                    ebooking.consignorCountry = "";
                    ebooking.consignorPostalCode = "";
                }
                if (ebooking.populateConsignee) {
                    ebooking.consigneeCompanyName = "";
                    ebooking.consigneeAddress = "";
                    ebooking.consigneeCity = "";
                    ebooking.consigneeCountry = "";
                    ebooking.consigneePostalCode = "";
                }

                if (ebooking.populateDelivery) {
                    ebooking.deliverCountry = "";
                    ebooking.deliverCity = "";
                    ebooking.deliverPostalCode = "";
                }
                if (ebooking.populatePickup) {
                    ebooking.pickupCountry = "";
                    ebooking.pickupCity = "";
                    ebooking.pickupPostalCode = "";
                }
                ebooking.populateImA = false;
                // ebooking.populateConsignor = false;
                // ebooking.populateConsignee = false;
                // ebooking.populateDelivery = false;
                // ebooking.populatePickup = false;
                ebooking.populateConsignor = ebooking.consignorCompanyName ? true : false;
                ebooking.populateConsignee = ebooking.consigneeCompanyName ? true : false;
                ebooking.populateDelivery = ebooking.deliverCountry ? true : false;
                ebooking.populatePickup = ebooking.pickupCountry ? true : false;

                this.setState({ ebooking });
                this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                switch (response.data.type) {
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;

                    case "WHITE":
                        if (ebooking.PriceQuotation.pl.length > 0) {
                            history.push('/ebooking/flight-pricing-pricelist');
                        } else {
                            history.push('/ebooking/flight-pricing-default');
                        }
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;

                    default:
                        history.push('/ebooking/flight-pricing-ad-hoc');
                        break;
                }
            } else {
                errorText = "Quote process failed. Please try again. Thank you and sorry for the inconvenience.";
                this.setState({ errorText });
                window.scrollToTop();
            }
        });
    }

    handleChange(event) {
        var { errors } = this.state;
        var { ebooking } = this.props;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        ebooking[name] = value;
        errors[name] = "";
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        this.setState({ ebooking, errors });
    }

    componentDidMount() {
        //get redux data and set to state   
        var { ebooking, history, auth } = this.props;
        this.setState({ ebooking });
        if (!ebooking.step_1_ok || (auth.state != 'LOGGED_IN' && auth.state != 'NEW_PASSWORD_REQUIRED' && auth.state != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        }
        var CheckCase = 1;
        var noExpress = false;
        if (ebooking.PriceQuotation) {
            if (ebooking.deliver_from_airport == "TLV") {
                CheckCase = 2;
            }
            if (ebooking.PriceQuotation.expressprice <= 0) {
                noExpress = true;
            }
        }

        this.setState({ inCase: CheckCase, noExpress: noExpress });
        if (ebooking.PriceQuotation.type != "PARCEL") {
            switch (ebooking.PriceQuotation.type) {
                case "WHITE":
                    if (ebooking.PriceQuotation.pl.length > 0) {
                        history.push('/ebooking/flight-pricing-pricelist');
                    } else {
                        history.push('/ebooking/flight-pricing-default');
                    }
                    break;
                case "PL":
                    history.push('/ebooking/flight-pricing-pricelist');
                    break;

                default:
                    history.push('/ebooking/shipment');
                    break;
            }
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    handChoosePrice = (priceType) => {
        var { ebooking, errors } = this.state;
        ebooking.ParcelChoosed.priceType = priceType;
        if (ebooking.ParcelChoosed.priceType == "price") {
            ebooking.ParcelChoosed.total = ebooking.PriceQuotation.price;
        } else {
            ebooking.ParcelChoosed.total = ebooking.PriceQuotation.expressprice;
        }
        errors = {};
        this.setState({ ebooking, errors });
    }
    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { ebooking } = this.state;

        if (ebooking.ParcelChoosed && !ebooking.ParcelChoosed.total) {
            isError = true;
            errors.selectBox = MESSAGES.ERROR_PARCEL;
        }

        if (!ebooking.checkbox_term_parcel) {
            isError = true;
            errors.checkbox_term_parcel = MESSAGES.ERROR_CONDITION;
        }

        if (isError) {
            this.setState({ errors });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_2_ok", fieldValue: true }).then(responseData => { });
        return true;
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            window.scrollToFirstError();
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            return false;
        }
        var { ebooking, history, auth } = this.props;
        ebooking.populateConsignor = ebooking.consignorCompanyName ? true : false;
        ebooking.populateConsignee = ebooking.consigneeCompanyName ? true : false;
        ebooking.populateDelivery = ebooking.deliverCountry ? true : false;
        ebooking.populatePickup = ebooking.pickupCountry ? true : false;
        ebooking.step_2_ok = true;
        await this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });

        window.jumpToTop();
        if (auth.IsGSA) {
            history.push('/ebooking/participants-gsa');
        } else {
            history.push('/ebooking/participants');
        }
    }
    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    render() {
        var authState = this.props.auth.state;
        const { ebooking, inCase, noExpress, breadcrumbs, steps, isError, errors, errorMessage }
            = this.state;
        var { auth } = this.props;
        return (

            <div className="ebooking">
                <StepProgress stepData={steps} progress={40} onClick={this.handClickStep} />

                <div className="container">
                    <h1 className="title mt-5">Step one: Getting a Quote</h1>
                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className={`form-group ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                    </div>
                </div>
                <form>
                    <div className="container block-form">
                        <h1 className="formTitle mb-2">Parcel Shipment</h1>
                        <div className="formDescription" style={{ "maxWidth": "600px" }}>
                            According to your shipment details, the shipment you entered fits with our parcel service. Please choose how you would like your parcel delivered.
                        </div>
                        <div className="form-content no_padding mt-5">
                            <div className="div-parcel div_center">
                                <div className={`row mb-2 ${errors.selectBox ? "" : "hide"}`}>
                                    <div className="col-lg-12">
                                        <div className="show-bottom-error bottom0">
                                            <InputError error={errors.selectBox} class="bg-red" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className={`parcel-box div_center ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "price") ? "selected" : ""}`}>
                                            <div className={`parcel-box-content text-center ${noExpress ? "box-blue" : "box-grey"}`}>
                                                <div className="box-title">Economy</div>
                                                <div className="box-description">{inCase == 2 ? "Up to 7 working day delivery" : "5 to 7 working day delivery"}</div>
                                                <div className="box-price">{(ebooking.PriceQuotation && ebooking.PriceQuotation.price) ? BookingHelper.formatMoney(ebooking.PriceQuotation.price) : ""} <span>{auth.cur}</span></div>
                                                <div className="box-button">
                                                    <button type="button" onClick={this.handChoosePrice.bind(this, "price")} className={`btn btn-block btn-select ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "price") ? "hide" : ""}`}>Select</button>
                                                    <span className={`btn btn-block btn-selected ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "price") ? "" : "hide"}`}>Selected</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className={`parcel-box div_center ${noExpress ? "d-none" : ""} ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "expressprice") ? "selected" : ""}`}>
                                            <div className="parcel-box-content text-center box-blue">
                                                <div className="box-title">Express</div>
                                                <div className="box-description">{inCase == 2 ? "Up to 3 working day delivery" : "3 to 5 working day delivery"}</div>
                                                <div className="box-price">{(ebooking.PriceQuotation && ebooking.PriceQuotation.expressprice) ? BookingHelper.formatMoney(ebooking.PriceQuotation.expressprice) : ""} <span>{auth.cur}</span></div>
                                                <div className="box-button">
                                                    <button type="button" onClick={this.handChoosePrice.bind(this, "expressprice")} className={`btn btn-block btn-select ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "expressprice") ? "hide" : ""}`}>Select</button>
                                                    <span className={`btn btn-block btn-selected ${(ebooking.ParcelChoosed && ebooking.ParcelChoosed.priceType == "expressprice") ? "" : "hide"}`}>Selected</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`parcel-box div_center ${noExpress ? "" : "d-none"}`}>
                                            <div className="parcel-box-content text-center box-invailable">
                                                <div className="box-title">Express Delivery Invailable</div>
                                                <div className="box-description">3 to 5 working day delivery</div>
                                                <div className="box-small-text">Must have an express delivery? <a onClick={this.handleClickExpressLink.bind(this)}>Click here</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-content no_padding mt-5">
                        <div className="div-parcel div_center">
                            <div className="row">
                                <div className="col-12">
                                    <div className={`price_includes text-center p-3 ${ebooking.deliver_to_airport == "TLV" ? "" : "d-none"}`}>
                                        This price includes:
                                        <ul>
                                            <li>Land transportation from shipper</li>
                                            <li>Airfreight</li>
                                            <li>Customs clearance</li>
                                            <li>Transmission</li>
                                            <li>Global gate</li>
                                            <li>Non-stackable cargo (if previously packed)</li>
                                        </ul>
                                    </div>
                                    <div className={`price_includes text-center p-3 ${ebooking.deliver_from_airport == "TLV" ? "" : "d-none"}`}>
                                        This price includes:
                                        <ul>
                                            <li>Airfreight</li>
                                            <li>Land transportation delivery address</li>
                                            <li>Customs clearance in Belgium</li>
                                            <li>Non-stackable cargo (if previously packed)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="p-3">


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="parcel-total div_center">
                                    <div className="total-txt">Total</div>
                                    <div className="total-price">{(ebooking.ParcelChoosed && ebooking.ParcelChoosed.total) ? BookingHelper.formatMoney(ebooking.ParcelChoosed.total) : ""} <span>{auth.cur}</span></div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 div_center" style={{ "maxWidth": "767px" }}>
                                <div className="row">
                                    <div className="form-check pl-4 pl-md-0">
                                        <label className={`checkbox-container`}>
                                            I have read and agree to the
                                            <a className="terms_link" href="https://www.cal-cargo.com/terms-conditions/" target="_blank">Terms of Service</a> and
                                            <a className="terms_link" href="https://www.cal-cargo.com/privacy-policy/" target="_blank">Privacy Policy</a>.
                                            <input type="checkbox" name="checkbox_term_parcel" value="checkbox_term_parcel" checked={ebooking.checkbox_term_parcel} onChange={this.handleChange} />
                                            <span className={`checkmark-checkbox ${(errors.checkbox_term_parcel) ? 'input-error' : ''}`}></span>
                                        </label>
                                        <EbookingInputError error={errors.checkbox_term_parcel} class="float-left" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-2">
                                <div className="parcel-buttons div_center">
                                    <div className="btn-process">
                                        <button type="button" onClick={this.handSubmit} disabled={(authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} className="ml-3 btn btn-ebooking-orange">Continue to Booking</button>
                                    </div>
                                    <div className="btn-back-shipment">
                                        <Link className="btn btn-back" to={`${process.env.PUBLIC_URL}/ebooking/shipment`}>Back to Shipment Details</Link>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FlightPricingParcel);