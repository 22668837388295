import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class NavHeaderForm extends React.Component {

    componentDidMount() {
        // console.log(this.props);
    }

    render() {
        return (
            <div className="nav-header">
                <div className="container">
                    <ul className="header-menu">
                        <li><a href='http://www.cal-cargo.com/about-us/'>About</a></li>
                        <li><a href='http://www.cal-cargo.com/contact/'>Contact</a></li>
                        {((this.props.auth.state === 'LOGGED_IN' || this.props.auth.state === 'NEW_PASSWORD_REQUIRED' || this.props.auth.state === 'EMAIL_VERIFICATION_REQUIRED') && this.props.auth.expiredToken === false ) ? <li>Hello {this.props.auth.user_name? this.props.auth.user_name: "Unknown Name"}, <LogoutButton onClick={this.props.onClickLogout}/></li> : <li>Hello Guest, <Link to={"/login"} className="login">Login</Link></li>}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});


const NavHeader = connect(mapStateToProps, null)(NavHeaderForm);
export default NavHeader;

const LogoutButton = ({ onClick }) => (
    <a onClick={onClick} className="btnLogout">LOGOUT</a>
);
LogoutButton.propTypes = {
    onClick: PropTypes.func,
};