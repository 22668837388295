import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AppConfig from '../../../AppConfig';
import BookingHelper from '../../BookingHelper';
/**
 * Validator
 */
import validator from 'validator';
import * as MESSAGES from '../../../libs/Messages';

/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as negotiationActions from '../../../ninja-redux/actions/negotiationActions';
import * as loadingAction from '../../../ninja-redux/actions/loadingAction';


class QuotesChatOfferItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorMessage: null,
      errors: {},
      decline: false,
      deleteMess: false,
      cfDelete: false,
      showDeleteReason: true,
      reasons: []
    }
  }

  componentDidMount() {
  }

  handleClickDecline = (e) => {
    this.setState({ decline: true });
  }

  handleCancelDecline = (e) => {
    this.setState({ decline: false });
  }

  handleConfirmDelete = () => {
    this.setState({ cfDelete: true });
  }

  handleQuoteCancel = () => {
    this.setState({ cfDelete: false });
  }

  handleShowCounter = (counterData, e) => {
    this.setState({ modalIsOpen: true });
  }

  handleChange = (event) => {
    var { reasons } = this.state;
    var { target, target: { name, type } } = event;

    var value = (type === 'checkbox') ? target.checked : target.value;
    reasons = [];
    if (value) {
      if (name === 'better_offer') {
        reasons.push(AppConfig.denideRefuse.better_offer);
      }
      if (name === 'unsatisfactory') {
        reasons.push(AppConfig.denideRefuse.unsatisfactory);
      }
      if (name === 'ship_any_more') {
        reasons.push(AppConfig.denideRefuse.ship_any_more);
      }
      if (name === 'other') {
        reasons.push(AppConfig.denideRefuse.other);
      }
    }
    this.setState({ reasons });
  }

  handleDeclineQuote = async () => {
    const { reasons } = this.state;
    const { itmChat } = this.props;

    // let data = []
    // if (reasons.better_offer) {
    //   data.push(AppConfig.denideRefuse.better_offer);
    // }
    // if (reasons.unsatisfactory) {
    //   data.push(AppConfig.denideRefuse.unsatisfactory);
    // }
    // if (reasons.ship_any_more) {
    //   data.push(AppConfig.denideRefuse.ship_any_more);
    // }
    // if (reasons.other) {
    //   data.push(AppConfig.denideRefuse.other);
    // }

    this.props.handleDeclineQuoteWithData(itmChat.Id, reasons);
    this.setState({ showDeleteReason: true });

  }

  renderOffer = (needReturn = true) => {
    let { offerData } = this.props;
    offerData = offerData || [];
    if (!Array.isArray(offerData) || offerData.length === 0) {
      return null;
    }

    if (needReturn) {
      return offerData.map((offer, i) => {
        return (
          <div key={i}>
            <div className="quote-flex display-flex mt-2">
              <div className="input-group-quote no-radius group-pr-1">
                <label>{offer.subcom}</label>
              </div>
              <div className="input-group-quote no-radius group-pr-2">
                <label>{offer.calc}</label>
              </div>
              <div className="input-group-quote no-radius group-pr-3">
                <label>{offer.curr}</label>
              </div>
              <div className="input-group-quote no-radius group-pr-4"><label className={`${offer.isUpdated ? "green" : ""}`}>{offer.price}</label></div>
            </div>
          </div>
        )
      })
    } else {
      return null;
    }
  }

  renderFooter = (disabledButton) => {
    var { itmChat, currency, quotesData, offerData, currencies, me } = this.props;

    offerData = offerData || [];
    var offerTotal = 0;
    if (Array.isArray(offerData) && offerData.length > 0) {
      offerTotal = BookingHelper.calculatorTotalNegotiation(offerData, currency, quotesData, currencies.data);
    }

    return (
      <div className="chat-footer">
        <div className="footer-buttons">
          {/* <div className="total">Total: {BookingHelper.formatMoney(offerTotal.offer_total)} {this.props.quotesData ? this.props.quotesData.custcurr : ''}</div> */}
          <div className="total"></div>

          <div className={`buttons`}>
            <div className={`btn-group`} role="group" aria-label="Basic example">
              <button type="button" className="btn btn-transparent"></button>
              {disabledButton ? null : (
                <>
                  {itmChat.status && (itmChat.status === 'Accepted' || itmChat.status === 'CustomerAccepted') ? (
                    <>
                      <div className="btn btn-accept">Accepted</div>
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'Declined' ? (
                    <>
                      <div className="btn btn-decline">Declined</div>
                    </>
                  ) : null}
                  {itmChat.status && (itmChat.status === 'Deleted' || itmChat.status === 'SalesDeleted') ? (
                    <>
                      <div className="btn btn-decline">Deleted</div>
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'Countered' ? (
                    <>
                      <div className="btn btn-counter">Countered</div>
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'Waiting' && itmChat.MettingSender === 0 ? (
                    <>
                      <div className="btn btn-decline">Waiting for response</div>
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'SalesDeclined' ? (
                    <>
                      {!me ? <button type="button" className="btn btn-decline" onClick={() => this.props.openConfirmModal(itmChat)}>Decline</button> : null}
                      {!me ? <button type="button" className="btn btn-accept" onClick={() => this.props.handleAccept(itmChat)}>Accept</button> : null}
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'CustomerDeclined' ? (
                    <>
                      {!me ? <div className="btn btn-decline">Declined</div> : null}
                    </>
                  ) : null}
                  {itmChat.status && itmChat.status === 'Waiting' && itmChat.MettingSender !== 0 ? (
                    <>
                      {!me ? <button type="button" className="btn btn-decline" onClick={() => this.props.openConfirmModal(itmChat)}>Decline</button> : null}
                      {!me ? <button type="button" className="btn btn-counter" onClick={() => this.props.handleShowCounter(itmChat)}>Counter</button> : null}
                      {!me ? <button type="button" className="btn btn-accept" onClick={() => this.props.handleAccept(itmChat)}>Accept</button> : null}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>

          <div className="clb"></div>
        </div>
      </div>
    )
  }

  renderDeleteQuote = () => {
    var { me, itmChat, currency, quotesData, offerData, currencies, listChatUpdated } = this.props;
    var { cfDelete } = this.state;
    offerData = offerData || [];
    var offerTotal = 0;
    if (Array.isArray(offerData) && offerData.length > 0) {
      offerTotal = BookingHelper.calculatorTotalNegotiation(offerData, currency, quotesData, currencies.data);
    }
    var checkSent = listChatUpdated.find(u => u === itmChat.Id);
    return (
      <>
        <div className="row mb-4">
          <div className="col-12">
            <div className={`chat-item  ${!me ? 'me' : 'partner'}`}>
              <div className="chat-body">
                This offer was declined.
                There was the reasons given for decline.
            </div>
              <div className="chat-footer">
                <div className="footer-form">
                  <div className="row">
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.better_offer}
                          <input type="checkbox" name="better_offer" value="1" checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.better_offer) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.unsatisfactory}
                          <input type="checkbox" name="unsatisfactory" value="1" onChange={this.handleChange} checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.unsatisfactory) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.ship_any_more}
                          <input type="checkbox" name="ship_any_more" value="1" onChange={this.handleChange} checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.ship_any_more) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.other}
                          <input type="checkbox" name="other" value="1" onChange={this.handleChange} checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.other) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-buttons">
                  {/* <div className="total">Total: {BookingHelper.formatMoney(offerTotal.offer_total)} {quotesData ? quotesData.custcurr : ''}</div> */}
                  <div className="total"></div>
                  <div className="buttons">
                    <div className={`btn-group`} role="group" aria-label="Basic example">
                      <button type="button" className="btn btn-transparent"></button>
                      <div type="button" className="btn btn-accept">Submitted</div>

                    </div>
                  </div>
                  <div className="clb"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderDecline = () => {
    // this.setState({showDeleteReason: false})
    var { me, currency, quotesData, offerData, currencies, itmChat } = this.props;
    var { reasons } = this.state;
    // console.log(reasons);
    offerData = offerData || [];
    var offerTotal = 0;
    if (Array.isArray(offerData) && offerData.length > 0) {
      offerTotal = BookingHelper.calculatorTotalNegotiation(offerData, currency, quotesData, currencies.data);
    }

    return (
      <>
        <div className="row mb-4">
          <div className="col-12">
            <div className={`chat-item  ${!me ? 'me' : 'partner'}`}>
              <div className="chat-body">
                <div className="moment">{itmChat.DateAdded && itmChat.DateAdded !== '' ? moment(moment.unix(itmChat.DateAdded).toDate()).format('MMM Do, HH:mm') : ''}</div>
                <div>This offer was declined. We would be happy if you could quickly mark the reason you declined the offer, so that we can improve our service.</div>
              </div>
              <div className="chat-footer">
                <div className="footer-form">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.better_offer}
                          <input type="checkbox" name="better_offer" value="1" checked={`${reasons && reasons.includes(AppConfig.denideRefuse.better_offer) ? 'checked' : ''}`} onChange={this.handleChange} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.unsatisfactory}
                          <input type="checkbox" name="unsatisfactory" value="1" checked={`${reasons && reasons.includes(AppConfig.denideRefuse.unsatisfactory) ? 'checked' : ''}`} onChange={this.handleChange} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.ship_any_more}
                          <input type="checkbox" name="ship_any_more" value="1" checked={`${reasons && reasons.includes(AppConfig.denideRefuse.ship_any_more) ? 'checked' : ''}`} onChange={this.handleChange} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.other}
                          <input type="checkbox" name="other" value="1" checked={`${reasons && reasons.includes(AppConfig.denideRefuse.other) ? 'checked' : ''}`} onChange={this.handleChange} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-buttons">
                  {/* <div className="total">Total: {BookingHelper.formatMoney(offerTotal.offer_total)} {quotesData ? quotesData.custcurr : ''}</div> */}
                  <div className="total"></div>
                  <div className="buttons">
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <button type="button" className="btn btn-accept" onClick={this.handleDeclineQuote}>Submit</button>
                    </div>
                  </div>
                  <div className="clb"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderDeclinedReasons = () => {
    var { me, itmChat, currency, quotesData, offerData, currencies, listChatUpdated } = this.props;
    var { cfDelete } = this.state;
    offerData = offerData || [];
    var offerTotal = 0;
    if (Array.isArray(offerData) && offerData.length > 0) {
      offerTotal = BookingHelper.calculatorTotalNegotiation(offerData, currency, quotesData, currencies.data);
    }

    return (
      <>
        <div className={`row mb-4 ${(itmChat.reasons.length || itmChat.deleted || itmChat.newCounter) ? '' : 'd-none'}`}>
          <div className="col-12">
            <div className={`chat-item  ${!me ? 'me' : 'partner'} full`}>
              <div className={`chat-body ${itmChat.reasons.length ? '' : 'no-footer'}`}>
                <div className="moment">{itmChat.DateUpdated && itmChat.DateUpdated !== '' ? moment(moment.unix(itmChat.DateUpdated).toDate()).format('MMM Do, HH:mm') : ''}</div>
                <div>This is the reason given for decline</div>
              </div>
              <div className={`chat-footer ${itmChat.deleted || itmChat.newCounter ? '' : 'no-buttons'}`}>
                <div className="footer-form">
                  <div className="row">
                    <div className={`col-12 mb-2 ${itmChat.reasons.length ? 'd-none' : ''}`}>
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>No Reason</label>
                      </div>
                    </div>
                    <div className={`col-12 mb-2 ${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.better_offer) ? '' : 'd-none'}`}>
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.better_offer}
                          <input type="checkbox" name="better_offer" value="1" checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.better_offer) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className={`col-12 mb-2 ${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.unsatisfactory) ? '' : 'd-none'}`}>
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.unsatisfactory}
                          <input type="checkbox" name="unsatisfactory" value="1" checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.unsatisfactory) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className={`col-12 mb-2 ${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.ship_any_more) ? '' : 'd-none'}`}>
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.ship_any_more}
                          <input type="checkbox" name="ship_any_more" value="1" checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.ship_any_more) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                    <div className={`col-12 mb-2 ${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.other) ? '' : 'd-none'}`}>
                      <div className="form-group">
                        <label className={`checkbox-container green pl-4`}>{AppConfig.denideRefuse.other}
                          <input type="checkbox" name="other" value="1" checked={`${itmChat.reasons && itmChat.reasons.includes(AppConfig.denideRefuse.other) ? 'checked' : ''}`} />
                          <span className={`checkmark-checkbox`}></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`footer-buttons ${itmChat.deleted || itmChat.newCounter ? '' : 'd-none'}`}>
                  <div className="total"></div>
                  <div className="buttons">
                    <div className={`btn-group`} role="group" aria-label="Basic example">
                      <button type="button" className="btn btn-transparent"></button>
                      {itmChat.deleted ? (<div className={`btn btn-decline`}>Deleted this quote</div>) : null}
                      {itmChat.newCounter ? (<div className={`btn btn-counter`}>New Counter</div>) : null}
                    </div>
                  </div>
                  <div className="clb"></div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderDelineMessageQuote = () => {
    var { me, itmChat, currency, quotesData, offerData, currencies, listChatUpdated } = this.props;
    offerData = offerData || [];
    var offerTotal = 0;
    if (Array.isArray(offerData) && offerData.length > 0) {
      offerTotal = BookingHelper.calculatorTotalNegotiation(offerData, currency, quotesData, currencies.data);
    }
    return (
      <>
        <div className="row mb-4">
          <div className="col-12">
            <div className={`chat-item  ${!me ? 'partner' : 'me'}`}>
              <div className="chat-body">
                Sadly, we coundn't accept this new offer.<br />
                Here you can respond to out original offer again. It is final.
              </div>
              <div className="chat-footer">
                <div className="footer-buttons">
                  <div className="total">Total: {BookingHelper.formatMoney(offerTotal.offer_total)} {quotesData ? quotesData.custcurr : ''}</div>
                  <div className="buttons">
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <button type="button" className="btn btn-decline" onClick={() => this.props.openConfirmModal(itmChat)}>Decline</button>
                      <button type="button" className="btn btn-accept" onClick={() => this.props.handleAccept(itmChat)}>Accept</button>
                    </div>
                  </div>
                  <div className="clb"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderFooterFiles = () => {
    var { itmChat, quotesData, me } = this.props;
    return (
      <div className={`chat-footer no-buttons`}>
        <div className="footer-form">
          <div className="row">
            <div className={`col-12 mb-2`}>
              <div className="form-group mb-0 text-center">
                {
                  itmChat.AttachmentFiles.map((file, i) => {
                    return (
                      <div className="file-data" key={`file-${i}`} onClick={() => { this.props.handleDownloadFile(file.filename, 'negotiation/' + quotesData.CProfAU, me) }}>
                        <div className="file-icon">
                          <div className="file-ext">{file.fileExt}</div>
                        </div>
                        <div className="file-name" title={file.filenameshow}>{file.filenameshow}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    var { me, text, offerData, noFooter, listChatUpdated, itmChat, itemPosition } = this.props;
    var { decline, deleteMess, modalIsOpen, modalError, successMessage, showDeleteReason } = this.state;
    var checkSent = listChatUpdated.find(u => u === itmChat.Id);
    return (
      <>
        {(itemPosition !== 0 && !["CustomerDeclined", "SalesDeclined", "CustomerAccepted", "SalesDeleted", 'SaleAcceptedText', 'CustomerAcceptedText', 'DeclinedReasonsForm', 'DeclinedReasons'].includes(itmChat.status)) && Array.isArray(offerData) && offerData.length > 0 ? (
          <div className="row mb-4">
            <div className="col-12">
              <div className={`chat-item ${!me ? 'me' : 'partner'} full`}>
                <div className={`chat-body ${noFooter ? 'no-footer' : ''}`} >
                  <div className="moment">{itmChat.DateAdded && itmChat.DateAdded !== '' ? moment(moment.unix(itmChat.DateAdded).toDate()).format('MMM Do, HH:mm') : ''}</div>
                  {this.renderOffer(true)}
                </div>
                {!noFooter ? this.renderFooter() : null}
              </div>
              <div className={`mt-2 ${!me ? 'text-left' : 'text-right'} sent-message ${checkSent && (itmChat.status !== "Declined" && itmChat.status !== "DeclineText" && itmChat.status !== "DeclineThank") ? '' : 'd-none'}`}>{MESSAGES.SUCCESS_QUOTE_CHAT}</div>
            </div>
          </div >
        ) : null}

        {(text !== "") ? (
          <div className="row mb-4">
            <div className="col-12">
              <div className={`chat-item ${!me ? 'me' : 'partner'}`}>
                <div className={`chat-body ${!noFooter && (itemPosition === 0 || ["CustomerDeclined", "SalesDeclined", "CustomerAccepted", "SalesDeleted"].includes(itmChat.status)) ? '' : 'no-footer'}`}>
                  <div className="moment">{itmChat.DateAdded && itmChat.DateAdded !== '' ? moment(moment.unix(itmChat.DateAdded).toDate()).format('MMM Do, HH:mm') : ''}</div>
                  <div dangerouslySetInnerHTML={{ __html: text }}></div>
                </div>
                {!noFooter && (itemPosition === 0 || ["CustomerDeclined", "SalesDeclined", "CustomerAccepted", "SalesDeleted"].includes(itmChat.status)) ? this.renderFooter() : null}
              </div>
              {itemPosition !== 0 && Array.isArray(offerData) && offerData.length > 0 ? null : (
                <div className={`mt-2 ${!me ? 'text-left' : 'text-right'} sent-message ${checkSent && (itmChat.status !== "Declined" && itmChat.status !== "DeclineText" && itmChat.status !== "DeclineThank") ? '' : 'd-none'}`}>{MESSAGES.SUCCESS_QUOTE_CHAT}</div>
              )}
            </div>
          </div >
        ) : null}

        {(itmChat.AttachmentFiles && Array.isArray(itmChat.AttachmentFiles) && itmChat.AttachmentFiles.length) ? (
          <div className="row mb-4">
            <div className="col-12">
              <div className={`chat-item ${!me ? 'me' : 'partner'}`}>
                <div className={`chat-body`}>
                  <div className="moment">{itmChat.DateAdded && itmChat.DateAdded !== '' ? moment(moment.unix(itmChat.DateAdded).toDate()).format('MMM Do, HH:mm') : ''}</div>
                </div>

                {this.renderFooterFiles()}

              </div>

            </div>
          </div >
        ) : null}

        {/* {me && itmChat.status && itmChat.status === "Declined" ? this.renderDelineMessageQuote() : null} */}
        {!me && (itmChat.status && itmChat.status === "DeclinedReasonsForm") ? this.renderDecline() : null}
        {(itmChat.status && itmChat.status === "DeclinedReasons") ? this.renderDeclinedReasons() : null}
        {/* {!me && (itmChat.status && (itmChat.status === "Declined" || itmChat.status === "CustomerDeclined") && (!itmChat.reasons || (itmChat.reasons && itmChat.reasons.length === 0)) && checkSent && !itmChat.submitedReasons) ? this.renderDecline() : null} */}
        {!me && (itmChat.status && (itmChat.status === "Deleted") && (!checkSent || (checkSent && itmChat.submited))) ? this.renderDeleteQuote() : null}

      </>
    );
  }
}


QuotesChatOfferItem.propTypes = {
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  me: PropTypes.bool,
  data: PropTypes.object,
  currency: PropTypes.object,
  // currencies: PropTypes.array,
  quotesData: PropTypes.object,
  listChatUpdated: PropTypes.array
};


const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return {
    negotiationActions: bindActionCreators(negotiationActions, dispatch),
    loadingActions: bindActionCreators(loadingAction, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesChatOfferItem);