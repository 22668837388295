import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * Validator
 */
import InputError from '../../components/InputError';
import Validator from '../../libs/Validator';
import Notification from '../../components/Notification';
import * as MESSAGES from '../../libs/Messages';

class ModalPassRequiredForm extends React.Component {
    constructor(props) {
        super(props);
        this.onShowModal = this.onShowModal.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    onShowModal() {
        this.passRequiredForm.resetFrom();
    };

    render() {
        return (
            <div className="modal-groups ">

                <div className="modal" id="modalPassRequired" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-small modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close-modal" onClick={this.props.closeModal.bind(this, 'modalPassRequired')}></button>
                            </div>
                            <PassRequiredForm onRef={ref => (this.passRequiredForm = ref)} loadingChangeData={this.props.loadingChangeData} auth={this.props.auth} onSubmit={this.props.onSubmit} showModal={this.props.showModal} hideModal={this.props.closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

const ModalPassRequired = connect(mapStateToProps, loadingAction)(ModalPassRequiredForm);

export default ModalPassRequired;


class PassRequiredForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'password': ''
            },
            btnLoginText: 'Set New Password'
        };

        this.loginHandleChange = this.loginHandleChange.bind(this);
        this.validateLogin = this.validateLogin.bind(this);
        this.validateLoginWithField = this.validateLoginWithField.bind(this);
        this.resetFrom = this.resetFrom.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    //reset from data
    resetFrom = () => {
        this.setState({
            error: '',
            errorMessage: '',
            successMessage: '',
            isErrorLogin: false,
            errorLogins: {},
            loginForm: {
                'password': '',
                'fullname': ''
            },
            btnLoginText: 'Set New Password'
        });
    }

    /* handler form's fields change value */
    loginHandleChange(event) {
        const { loginForm } = this.state;
        const { target, target: { name, type } } = event;
        loginForm[name] = target.value;
        this.setState({ loginForm });
    }

    handLoginSubmit = () => {
        this.props.loadingChangeData(true);
        this.setState({ successMessage: '', errorMessage: '' });
        if (this.validateLogin) {            
            this.props.onSubmit(this.props.auth.user_email,this.state.loginForm.password, { 'name': this.state.loginForm.fullname }).then(res => {
                this.setState({ successMessage: 'success', errorMessage: '' });
                this.props.loadingChangeData(false);
                this.props.hideModal('modalPassRequired');
            }).catch(err => {
                this.props.loadingChangeData(false);
                this.setState({ successMessage: '', errorMessage: err.message });
            });

        }
    }

    validateLoginWithField(event) {
        const { loginForm, errorLogins } = this.state;
        const { target, target: { name, type } } = event;
        var value = target.value;
        var isError = false;
        var validator = new Validator();
        switch (target.name) {
            case 'password':
                if (!validator.required(loginForm.password)) {
                    errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errorLogins['password'] = '';
                }
                break;
            case 'fullname':
                if (!validator.required(loginForm.fullname)) {
                    errorLogins['fullname'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }

                if (!isError) {
                    errorLogins['fullname'] = '';
                }
                break;
        }
        this.setState({ errorLogins });
    }

    validateLogin(event) {
        this.setState({ errorLogins: {} });
        const { loginForm } = this.state;
        var errorLogins = {};
        var isError = false;
        var validator = new Validator();

        if (!validator.required(loginForm.password)) {
            errorLogins['password'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!validator.required(loginForm.fullname)) {
            errorLogins['fullname'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errorLogins });
            return false;
        }
        return true;
    }

    render() {
        const { breadcrumbData, isErrorLogin, errorLogins, loginForm } = this.state;

        return (
            <div>
                <div className="modal-body">
                    <h2>Change Password Required</h2>
                    <hr />
                    <div className={`${(this.state.successMessage) ? '' : 'hide'}`}>
                        <div className="block-form">
                            <form>
                                <h2 className="detail-success">Success! You are confirmed!</h2>
                                <p className="claim-text">You must login again after verified. Please click the login button to do it. Thanks !</p>

                                <div className="form-group">
                                    <button type="button" onClick={this.props.showModal.bind(this, 'modalLogin')} className="btn btn-continues btn-left">Login</button>
                                    <div className="clb"> </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={`alert alert-danger ${(this.state.errorMessage) ? '' : 'hide'}`}>
                        {this.state.errorMessage}
                    </div>
                    <form>
                        <div className="form-group">
                            <label htmlFor="fullname">Full Name</label>
                            <input type="text" className={`form-control ${(errorLogins.fullname) ? 'input-error' : ''}`} name="fullname" maxLength={50} onBlur={this.validateLoginWithField} onChange={this.loginHandleChange} />
                            <InputError error={errorLogins.fullname} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className={`form-control ${(errorLogins.password) ? 'input-error' : ''}`} name="password" onBlur={this.validateLoginWithField} onChange={this.loginHandleChange} />
                            <InputError error={errorLogins.password} />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="form-group">
                        <button type="button" onClick={this.handLoginSubmit} className="btn btn-continues btn-login">{this.state.btnLoginText}</button>
                        <div className="clb"></div>
                    </div>
                </div>
            </div>
        );
    }
}

PassRequiredForm.propTypes = {
    onSubmit: PropTypes.func,
    clearCache: PropTypes.func,
    username: PropTypes.string,
    error: PropTypes.string,
    email: PropTypes.string
};
