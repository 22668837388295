import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import AppConfig from '../../AppConfig';
import PropTypes from 'prop-types';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import * as MESSAGES from '../../libs/Messages';
import Progress from '../Progress';

/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class SupplierUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMessage: null,
            errors: {},
            selectedType: '',
            loaded: 0,
            showProgress: false,
            fileTypes: ['AWB', 'Mandate', 'Invoice', 'HS Code', 'Packing List',
                'Phyio', 'Euro 1', 'ATA Carnet', 'Valid Exporter decleration/stamp', 'Other'],
            acceptFiles: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
            maxFileSize: 9
        }

        this.onChangeType = this.onChangeType.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    componentDidMount() {

    }

    onChangeType = (selectedType, event) => {
        selectedType = selectedType.replace("/", "-");
        this.setState({ selectedType: selectedType });
        this.inputElement.click();
    }

    handleUploadFile(e) {
        var selectorFiles = e.target.files;        
        var file = selectorFiles[0];    
        if(file) {
            this.props.onSuccess({ file, filedes: this.state.selectedType });
        } 
        
        // this.uploadFile(file);
        // e.target.value = null;
    }
    
    renderOptions = () => {
        var options = [];
        this.state.fileTypes.map((item, i) => {
            options.push(<option key={i} value={item}>{item}</option>);
        })
        return options;
    }

    render() {

        return (
            <div className={`${this.props.class ? this.props.class : ''}`}>
                <div className="div_center text-center" style={{ "maxWidth": "250px" }}>
                    <div className="dropdown dropdown attact-file-button">
                        <button className="btn btn-attact file-types" type="button" id="dropdownMenuButton" disabled={this.props.dis} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Attach a file
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                this.state.fileTypes.map((file_type, i) => {
                                    return (
                                        <a className="dropdown-item" key={i} onClick={this.onChangeType.bind(this, file_type)}>{file_type}</a>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="dv-choose-file div_center text-center d-none" style={{ "maxWidth": "150px" }}>
                    <label className={`btn btn-attact ${this.props.dis ? 'disabled' : ''}`}>Attach a file
                            <input type="file" ref={input => this.inputElement = input} name="upload" accept="application/pdf, image/png, image/jpeg, application/msword" disabled={this.props.dis} onChange={this.handleUploadFile} />
                    </label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}
SupplierUpload.propTypes = {
    onSuccess: PropTypes.func,
    fileIndex: PropTypes.string,
    subFolder: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierUpload);