import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import ClaimSearch from './ClaimSearch';

//Optional Import
//import { uploadFile } from 'react-s3';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';

class ClaimEditSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'Claim Update'
                },
            ],
            steps: [
                {
                    'link': 'claim',
                    'active': false,
                    'validate': true,
                    'name': 'Basic info'
                },
                {
                    'link': 'claim-detail',
                    'active': false,
                    'validate': true,
                    'name': 'Claim details'
                },
                {
                    'link': 'claim-submit',
                    'active': false,
                    'validate': true,
                    'name': 'Submit documents'
                },
            ],
            claim: []
            
        };

        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.gotoMainPage = this.gotoMainPage.bind(this);
        
    }

    handleClearDate = () => {
        const {claim} = this.state;
        claim.flight_date = '';
        this.setState({claim});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //get redux data and set to state        
        var { claim } = this.props;
        // console.log(claim);
        this.setState({ claim });
        
        document.title = "Claims | Challenge Group"
    }
  
    handleChange(event) {
        const {claim} = this.state;
        const {target, target: {name, type}} = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        claim[name] = value;
        this.setState({claim});

    }

    handSubmit() {

    }

    gotoMainPage() {
        window.location = 'http://www.cal-cargo.com';
    }

    onDrop(files) {
        /*S3FileUpload
		.uploadFile(files, config)
		.then(data => console.log(data))
		.catch(err => console.error(err))*/
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();            
            return false;
        }
    }
    render() {
        const {claim, breadcrumbs, steps} = this.state;

        return (
                <div className="container claim-form">
                    <div className='form-title'>Claim Update</div>
                    <div className="block-form claim-form">
                        <form>
                            <h2 className="detail-success">Your claim was successfully updated</h2>
                            <p className='claim-text'></p>
                            <p className='claim-no'>Claim#: {claim.ClaimNumSuccess}</p>
                            <p className='claim-contact'>A confirmation email has been sent to your email address.</p>
                            <div className="form-group">
                                <button type="button"  onClick={this.gotoMainPage} className="btn btn-continues btn-left">Back to main page</button>
                                <div className="clb"></div>
                            </div>
                        </form>
                
                    </div>
                </div>
                );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, claimActions)(ClaimEditSuccess);