import React from 'react';
import StepProgress from '../../layouts/partials/StepProgress';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import { Link } from 'react-router-dom';
import base64 from 'base-64';
import AppConfig from '../../AppConfig';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import EbookingInputError from '../EbookingInputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';
import * as authActions from '../../ninja-redux/actions/authActions';

let stateLoginChanged = false;
// var a = 0;
class NewEbooking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnText: "View Quote",
            errorText: "",
            inputValueCountryTo: "",
            inputValueCountryFrom: "",
            suggestionsCNEE: [],
            suggestionsCNOR: [],
            autoSubmit: false,
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                deliver_to_airport: "",
                deliver_from_airport: "",
                units_list: [],
                instructions: [],
                class: [],
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'validate': true,
                    'current': true,
                    'name': 'Getting a Quote'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            pickup_dropoff_text: "Dropoff Time (Local)",
            selectedCountryDeliverFrom: null,
            selectedCountryDeliverTo: null,
            selectedAirportDeliverFrom: null,
            selectedAirportDeliverTo: null,
            selectedInstruction: null,
            countries: [],
            countriesZip: [],
            airportsFrom: [],
            airportsTo: [],
            airportsDeliverFrom: [],
            airportsDeliverTo: [],
            isLoadingCountry: false,
            isLoadingAirportFrom: false,
            isLoadingAirportTo: false,
            isLoadingInstruction: false,
            listInstructions: [],
            commodities: [],
            listCommodity: [],
            listSubCommodity: [],
            isLoadingCommodity: false,
            isLoadingSubCommodity: false,
            selectedCommodity: null,
            selectedSubCommodity: null,
            unit_number_of_pieces: "",
            unit_length: "",
            unit_width: "",
            unit_height: "",
            unit_volume: "",
            unit_uld_type: "",
            consigneeSuggests: [],
            consignorSuggests: [],
            submiting: false,
            showProceeding: false,
            dataChanged: false,
            showAddPiecesForm: false,
            validateETD: true,
            getingPhoneZip: false,
            phoneZip: [],
            suggestionsZipFrom: [],
            suggestionsZipTo: [],
            ulds: {
                'HD': 'HD',
                'HDS': 'HD skid',
                'MD': 'MD',
                'MDS': 'MD skid',
                'LD': 'LD',
                'LDS': 'LD skid',
                'LOOSE': 'Loose'
            }
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClearDate = this.handleClearDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUnit = this.handleChangeUnit.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleOnBlurDate = this.handleOnBlurDate.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnBlurSelect = this.handleOnBlurSelect.bind(this);
        this.handleClickInstruction = this.handleClickInstruction.bind(this);
        this.removeAddedInstruction = this.removeAddedInstruction.bind(this);
        this.removeAddedClass = this.removeAddedClass.bind(this);
        this.removeAddedUnit = this.removeAddedUnit.bind(this);
        this.validateUnit = this.validateUnit.bind(this);
        this.addUnit = this.addUnit.bind(this);
        this.addClass = this.addClass.bind(this);
        this.handlerSelectFromTyping = this.handlerSelectFromTyping.bind(this);
        this.handlerSelectToTyping = this.handlerSelectToTyping.bind(this);
        this.showLoginForm = this.handlerShowLoginForm.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onShowHidePieceForm = this.onShowHidePieceForm.bind(this);
    }

    isObject = (a) => {
        return (!!a) && (a.constructor === Object);
    };

    onClickOutside = () => {
        this._calendar.setOpen(false);
        this._calendar.cancelFocusInput();
    }
    handlerShowLoginForm = (e) => {
        stateLoginChanged = true;
        window.parent.postMessage('show-login-form', '*');
    }

    handlerSelectFromTyping = (e) => {
        this.setState({ inputValueCountryFrom: e });
    }
    handlerSelectToTyping = (e) => {
        this.setState({ inputValueCountryTo: e });
    }

    getSuggestionsZipFrom = value => {
        var { selectedCountryDeliverFrom } = this.state;
        value = value ? value.substring(0, 80) : '';
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.props;
        ebooking.deliver_from_zipcode = value;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
        if (this.isObject(selectedCountryDeliverFrom) && selectedCountryDeliverFrom.label) {
            return inputLength === 0 ? [] : this.state.phoneZip.filter(zip =>
                zip.Code.toLowerCase().indexOf(inputValue) > -1 && zip.Name === 'From' && selectedCountryDeliverFrom.label === zip.CountryCode
            );
        } else {
            return inputLength === 0 ? [] : this.state.phoneZip.filter(zip =>
                zip.Code.toLowerCase().indexOf(inputValue) > -1 && zip.Name === 'From'
            );
        }
    };

    getSuggestionsZipTo = value => {
        var { selectedCountryDeliverTo } = this.state;
        value = value ? value.substring(0, 80) : '';
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.props;
        ebooking.deliver_to_zipcode = value;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
        if (this.isObject(selectedCountryDeliverTo) && selectedCountryDeliverTo.label) {
            return inputLength === 0 ? [] : this.state.phoneZip.filter(zip =>
                zip.Code.toLowerCase().indexOf(inputValue) > -1 && zip.Name === 'To' && selectedCountryDeliverTo.label === zip.CountryCode
            );
        } else {
            return inputLength === 0 ? [] : this.state.phoneZip.filter(zip =>
                zip.Code.toLowerCase().indexOf(inputValue) > -1 && zip.Name === 'To'
            );
        }
    };

    getSuggestionsCNEE = value => {
        if (value.length > 48) {
            value = value.slice(0, 48);
        }
        var inputValue = value.trim().toLowerCase();

        const inputLength = inputValue.length;
        var { ebooking } = this.props;
        ebooking.company_name_of_consignee = value;
        ebooking.consigneeCompanyName = "";
        ebooking.consigneeAddress = "";
        ebooking.consigneeCity = "";
        ebooking.consigneeCountry = "";
        ebooking.consigneePostalCode = "";
        ebooking.populateConsignee = false;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "company_name_of_consignee", fieldValue: ebooking.company_name_of_consignee }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });

        return inputLength === 0 ? [] : this.state.consigneeSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );

    };

    getSuggestionsCNOR = value => {
        if (value.length > 48) {
            value = value.slice(0, 48);
        }
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var { ebooking } = this.props;
        ebooking.company_name_of_consignor = value;
        ebooking.consignorCompanyName = "";
        ebooking.consignorAddress = "";
        ebooking.consignorCity = "";
        ebooking.consignorCountry = "";
        ebooking.consignorPostalCode = "";
        ebooking.populateConsignor = false;
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        // this.props.ebookingActions.ebookingChangeData({ fieldName: "company_name_of_consignor", fieldValue: ebooking.company_name_of_consignor }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
        return inputLength === 0 ? [] : this.state.consignorSuggests.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    getSuggestionValueZip = suggestion => suggestion.Code;

    renderSuggestionZip = suggestion => {
        return (
            <div>
                {suggestion.Code}
            </div>
        )
    };

    onChangeSugestZipFrom = (event, { newValue }) => {
        var { errors, phoneZip, selectedCountryDeliverFrom, countries } = this.state;
        var { ebooking } = this.props;
        newValue = newValue ? newValue.substring(0, 80) : '';
        ebooking.deliver_from_zipcode = newValue;
        errors["deliver_from_zipcode"] = "";


        var selectedSuggessZip = { Code: "", CountryCode: "", Name: "", isBasic: false, warhsdes: "" };
        selectedSuggessZip = phoneZip.find(item => item.Code === newValue);
        if (selectedSuggessZip) {
            ebooking.deliver_from_city = selectedSuggessZip.warhsdes;
            errors["deliver_from_city"] = "";
            if (!selectedCountryDeliverFrom || !selectedCountryDeliverFrom.length) {
                var selectedCountry = countries.find(c => c.label === selectedSuggessZip.CountryCode && !c.airportonly);
                if (selectedCountry) {
                    selectedCountryDeliverFrom = selectedCountry;
                }
                ebooking["deliver_from_country"] = selectedCountryDeliverFrom ? selectedCountryDeliverFrom.labelhiden : "";
            }
        }

        this.setState({
            ebooking, errors, dataChanged: true, selectedCountryDeliverFrom
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    onChangeSugestZipTo = (event, { newValue }) => {
        var { errors, phoneZip, selectedCountryDeliverTo, countries } = this.state;
        var { ebooking } = this.props;
        newValue = newValue ? newValue.substring(0, 80) : '';
        ebooking.deliver_to_zipcode = newValue;
        errors["deliver_to_zipcode"] = "";

        var selectedSuggessZip = { Code: "", CountryCode: "", Name: "", isBasic: false, warhsdes: "" };
        selectedSuggessZip = phoneZip.find(item => item.Code === newValue);
        if (selectedSuggessZip) {
            ebooking.deliver_to_city = selectedSuggessZip.warhsdes;
            errors["deliver_to_city"] = "";
            if (!selectedCountryDeliverTo || !selectedCountryDeliverTo.length) {
                var selectedCountry = countries.find(c => c.label === selectedSuggessZip.CountryCode && !c.airportonly);
                if (selectedCountry) {
                    selectedCountryDeliverTo = selectedCountry;
                }
                ebooking["deliver_to_country"] = selectedCountryDeliverTo ? selectedCountryDeliverTo.labelhiden : "";
            }
        }

        this.setState({
            ebooking, errors, dataChanged: true, selectedCountryDeliverTo
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
    }

    onChangeSugestCNEE = (event, { newValue }) => {
        if (newValue.length > 48) {
            newValue = newValue.slice(0, 48);
        }
        var { errors, consigneeSuggests } = this.state;
        var { ebooking } = this.props;
        ebooking.company_name_of_consignee = newValue;
        ebooking.consigneeCompanyName = "";
        ebooking.consigneeAddress = "";
        ebooking.consigneeCity = "";
        ebooking.consigneeCountry = "";
        ebooking.consigneePostalCode = "";
        ebooking.populateConsignee = false;
        errors["company_name_of_consignee"] = "";

        var selectedSuggessCNEE = { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" };
        selectedSuggessCNEE = consigneeSuggests.find(item => item.name === newValue);
        if (!selectedSuggessCNEE) {
            selectedSuggessCNEE = { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" };
        }
        ebooking.selectedSuggessCNEE = selectedSuggessCNEE;

        this.setState({
            ebooking, errors, dataChanged: true
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });


        // this.props.ebookingActions.ebookingChangeData({ fieldName: "company_name_of_consignee", fieldValue: ebooking.company_name_of_consignee }).then(responseData => { });
    };

    onChangeSugestCNOR = (event, { newValue }) => {
        if (newValue.length > 48) {
            newValue = newValue.slice(0, 48);
        }
        var { errors, consignorSuggests } = this.state;
        var { ebooking } = this.props;

        ebooking.company_name_of_consignor = newValue;
        ebooking.consignorCompanyName = "";
        ebooking.consignorAddress = "";
        ebooking.consignorCity = "";
        ebooking.consignorCountry = "";
        ebooking.consignorPostalCode = "";
        ebooking.populateConsignor = false;
        errors["company_name_of_consignor"] = "";

        var selectedSuggessCNOR = { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" };
        selectedSuggessCNOR = consignorSuggests.find(item => item.name === newValue);
        if (!selectedSuggessCNOR) {
            selectedSuggessCNOR = { name: "", iata: "", countryname: "", cityname: "", address: "", zipcode: "", cust: 0, custname: "" };
        }
        ebooking.selectedSuggessCNOR = selectedSuggessCNOR;

        this.setState({
            ebooking, errors, dataChanged: true
        });
        this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });

        // this.props.ebookingActions.ebookingChangeData({ fieldName: "company_name_of_consignor", fieldValue: ebooking.company_name_of_consignor }).then(responseData => { });
    };

    onSuggestionsFetchRequestedZipFrom = ({ value }) => {
        var sugs = this.getSuggestionsZipFrom(value);
        this.setState({
            suggestionsZipFrom: sugs
        });
    };

    onSuggestionsFetchRequestedZipTo = ({ value }) => {
        var sugs = this.getSuggestionsZipTo(value);
        this.setState({
            suggestionsZipTo: sugs
        });
    };

    onSuggestionsFetchRequestedCNEE = ({ value }) => {
        this.setState({
            suggestionsCNEE: this.getSuggestionsCNEE(value)
        });
    };

    onSuggestionsFetchRequestedCNOR = ({ value }) => {
        this.setState({
            suggestionsCNOR: this.getSuggestionsCNOR(value)
        });
    };

    onSuggestionsClearRequestedCNEE = () => {
        this.setState({
            suggestionsCNEE: []
        });
    };

    onSuggestionsClearRequestedCNOR = () => {
        this.setState({
            suggestionsCNOR: []
        });
    };

    onSuggestionsClearRequestedZipFrom = () => {
        this.setState({
            suggestionsZipFrom: []
        });
    };

    onSuggestionsClearRequestedZipTo = () => {
        this.setState({
            suggestionsZipTo: []
        });
    };

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.auth.state != newProps.auth.state && newProps.auth.state == "LOGGED_IN" && !this.state.autoSubmit) {
            this.getParticipentCNEE();
            this.getParticipentCNOR();
            this.getPhoneZip();
        } else if (oldProps.auth.state != newProps.auth.state && newProps.auth.state == "LOGGED_OUT") {
            var { consignorSuggests, consigneeSuggests } = this.state;
            consignorSuggests = [];
            consigneeSuggests = [];
            this.setState({ consignorSuggests, consigneeSuggests });
        }

        if (oldProps.port.state != "PORT_LOADED_DATA" && newProps.port.state == "PORT_LOADED_DATA") {
            if (newProps.ebooking.deliver_from_country == "") {
                this.setDefaultPortFrom();
            }
            if (newProps.ebooking.deliver_to_country == "") {
                this.setDefaultPortTo();
            }
        }
        if (oldProps.country.state != "COUNTRY_LOADED_DATA" && newProps.country.state == "COUNTRY_LOADED_DATA") {
            var selectedCountryDeliverFrom = null;
            var selectedCountryDeliverTo = null;
            var countries = [];
            var countriesZip = [];
            var isLoadingCountry = false;
            newProps.country.countries.forEach(c => {
                // if (c.forwebsite) {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                var countryZip = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                if (c.airportonly) {
                    countryZip.label = c.countryname + " (airport only)";
                }
                countries.push(country);
                countriesZip.push(countryZip);
                if (c.countryname == this.state.ebooking.deliver_from_country) {
                    selectedCountryDeliverFrom = country;
                    this.setState({ selectedCountryDeliverFrom: selectedCountryDeliverFrom });
                }
                if (c.countryname == this.state.ebooking.deliver_to_country) {
                    selectedCountryDeliverTo = country;
                    this.setState({ selectedCountryDeliverTo: selectedCountryDeliverTo });
                }
                // }
            });

            if (selectedCountryDeliverFrom) {
                this.getAirportsFrom(selectedCountryDeliverFrom, newProps.ebooking);
            }
            if (selectedCountryDeliverTo) {
                this.getAirportsTo(selectedCountryDeliverTo, newProps.ebooking);
            }
            this.setState({ countries: countries, countriesZip: countriesZip, isLoadingCountry: isLoadingCountry });
        }

        if (newProps.auth.state != "LOGGED_IN") {
            stateLoginChanged = false;
        }
    }

    getParticipentCNEE = () => {
        var { consigneeSuggests } = this.state;
        consigneeSuggests = [];
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNEE", this.props.auth.jwtToken).then(response => {
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consigneeSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consigneeSuggests });
        });
    }

    getParticipentCNOR = () => {
        var { consignorSuggests } = this.state;
        consignorSuggests = [];
        this.props.ebookingActions.getParticipent(this.props.auth.custname, "CNOR", this.props.auth.jwtToken).then(response => {
            if (response.success && response.data.Success) {
                response.data.customer.map(c => {
                    consignorSuggests.push({ name: c.Custdes, iata: c.IataCode, countryname: c.countryname, cityname: c.cityname, address: c.address, zipcode: c.zipcode, cust: c.Cust, custname: c.Custname });
                });
            }
            this.setState({ consignorSuggests });
        });
    }

    removeAddedInstruction = (instructionID) => {
        const { ebooking } = this.state;
        ebooking.instructions.splice(instructionID, 1);
        this.props.ebookingActions.ebookingChangeData({ fieldName: "instructions", fieldValue: ebooking.instructions }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
    }

    removeAddedClass = (instructionID) => {
        const { ebooking } = this.state;
        ebooking.class.splice(instructionID, 1);
        this.props.ebookingActions.ebookingChangeData({ fieldName: "class", fieldValue: ebooking.class }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
    }
    removeAddedUnit = (removeIndex) => {
        const { ebooking, errors } = this.state;
        ebooking.units_list.splice(removeIndex, 1);
        var overlap_shipment_check = false;

        ebooking.units_list.forEach(u => {
            if (ebooking.units_type_short == "CM") {
                var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                    overlap_shipment_check = true;
                }
            } else {
                var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                    overlap_shipment_check = true;
                }
            }
        });
        ebooking.overlap_shipment_check = overlap_shipment_check;
        errors["overlap_shipment"] = "";
        this.props.ebookingActions.ebookingChangeData({ fieldName: "units_list", fieldValue: ebooking.units_list }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "overlap_shipment_check", fieldValue: ebooking.overlap_shipment_check }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true, errors });
        this.calculatorUnitTotal();
    }

    onShowHidePieceForm = (event) => {
        var { showAddPiecesForm } = this.state;
        this.setState({ showAddPiecesForm: true });
    }

    addUnit = async (event) => {
        var { ebooking, errors, unit_height, unit_number_of_pieces, unit_length, unit_width, unit_volume, unit_uld_type, showAddPiecesForm } = this.state;
        var validator = new Validator();
        var error = false;
        errors["unit_number_of_pieces"] = "";
        errors["unit_height"] = "";
        errors["unit_length"] = "";
        errors["unit_width"] = "";
        errors["unit_uld_type"] = "";
        errors["overlap_shipment"] = "";
        errors["unit_rows"] = "";
        if (ebooking.dimemtions_by === "ULD") {
            if (unit_number_of_pieces == "" || !validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0) {
                error = true;
                errors["unit_number_of_pieces"] = "Input Number";
            }
        } else {
            if (unit_number_of_pieces == "" || !validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0) {
                error = true;
                errors["unit_number_of_pieces"] = "Input Number";
            }
        }
        if (unit_height == "" || !validator.currency(unit_height) || parseFloat(unit_height) <= 0) {
            error = true;
            errors["unit_height"] = "Input Number";
        }
        if (unit_length == "" || !validator.currency(unit_length) || parseFloat(unit_length) <= 0) {
            error = true;
            errors["unit_length"] = "Input Number";
        }
        if (unit_width == "" || !validator.currency(unit_width) || parseFloat(unit_width) <= 0) {
            error = true;
            errors["unit_width"] = "Input Number";
        }

        if (ebooking.dimemtions_by === "ULD" && unit_uld_type === "") {
            error = true;
            errors["unit_uld_type"] = "Plz select";
        }


        this.setState({ errors });
        if (!error) {
            ebooking.units_list.push({
                'unitname': ebooking.units_type_short.toUpperCase(),
                'Pcs': parseFloat(unit_number_of_pieces),
                'Height': parseFloat(unit_height),
                'Length': parseFloat(unit_length),
                'Width': parseFloat(unit_width),
                'Cbm': parseFloat(unit_volume),
                'paltype': ebooking.dimemtions_by === "ULD" ? unit_uld_type : ""
            });

            var overlap_shipment_check = false;
            ebooking.units_list.forEach(u => {
                if (ebooking.units_type_short == "CM") {
                    var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 240);
                    if (parseFloat(u.Length) > 317 || (parseFloat(u.Width) > 244 && parseFloat(u.Length) > 244) || min > 244) {
                        overlap_shipment_check = true;
                    }
                } else {
                    var min = Math.min(parseFloat(u.Width), parseFloat(u.Length)) + (parseFloat(u.Height) - 94.5);
                    if (parseFloat(u.Length) > 124.8 || (parseFloat(u.Width) > 96 && parseFloat(u.Length) > 96) || min > 96) {
                        overlap_shipment_check = true;
                    }
                }
            });
            ebooking.overlap_shipment_check = overlap_shipment_check;
            this.props.ebookingActions.ebookingChangeData({ fieldName: "overlap_shipment_check", fieldValue: ebooking.overlap_shipment_check }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "units_list", fieldValue: ebooking.units_list }).then(responseData => { });
            await this.setState({ ebooking, 'unit_number_of_pieces': '', 'unit_height': '', 'unit_length': '', 'unit_width': '', 'unit_volume': '', dataChanged: true, unit_uld_type: '' });
            await this.calculatorUnitTotal();
            await this.setState({ showAddPiecesForm: false });
        }
    }

    unitTypeChange = (unit_type) => {
        var { ebooking } = this.state;
        if (ebooking.units_list.length > 0) {
            for (let i = 0; i < ebooking.units_list.length; i++) {
                ebooking.units_list[i].unitname = unit_type;
            }
            this.props.ebookingActions.ebookingChangeData({ fieldName: "units_list", fieldValue: ebooking.units_list }).then(responseData => { });
        }
        this.setState({ ebooking, dataChanged: true });
    }

    addClass = (event) => {
        var { ebooking, errors } = this.state;
        if (this.class) {
            errors.class = "";
            var selecteds_value = this.class.value;
            var checkExisted = false;
            ebooking.class.forEach(a => {
                if (a == selecteds_value) {
                    checkExisted = true;
                }
            });
            if (!checkExisted) {
                ebooking.class.push(selecteds_value);
                this.props.ebookingActions.ebookingChangeData({ fieldName: "class", fieldValue: ebooking.class }).then(responseData => { });
            }
            this.setState({ ebooking, errors, dataChanged: true });
        }
    }

    calculatorUnitTotal = () => {
        var { ebooking, errors, unit_height, unit_number_of_pieces, unit_length, unit_width, unit_volume, unit_uld_type } = this.state;
        if (ebooking.units_list.length > 0 || (unit_height && unit_number_of_pieces && unit_length && unit_width && unit_volume)) {
            ebooking.units_total_num_of_pieces = 0;
            ebooking.units_total_volume = 0;
            ebooking.units_list.forEach(u => {
                ebooking.units_total_num_of_pieces += parseFloat(u.Pcs);
                var Cbm = parseFloat(u.Cbm) * parseFloat(u.Pcs);
                // if (u.unitname == "IN") {
                //     Cbm = parseFloat(u.Width) * parseFloat(u.Height) * parseFloat(u.Length) * Math.pow(2.54, 3);
                // }
                ebooking.units_total_volume += Cbm;
            });
            if (unit_volume !== '' && parseFloat(unit_number_of_pieces) && parseFloat(unit_number_of_pieces)) {
                ebooking.units_total_num_of_pieces += parseFloat(unit_number_of_pieces);
                ebooking.units_total_volume += parseFloat(unit_number_of_pieces) * parseFloat(unit_number_of_pieces);
            }
            ebooking.units_total_volume = ebooking.units_total_volume.toFixed(3);
        } else {
            ebooking.units_total_num_of_pieces = "";
            ebooking.units_total_volume = "";
        }

        this.props.ebookingActions.ebookingChangeData({ fieldName: "units_total_num_of_pieces", fieldValue: ebooking.units_total_num_of_pieces }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "units_total_volume", fieldValue: ebooking.units_total_volume }).then(responseData => { });
        errors["units_total_num_of_pieces"] = "";
        errors["units_total_volume"] = "";
        this.setState({ ebooking, errors });
        this.calculatorTotalChargableWeight(ebooking.units_total_weight);
    }

    calculatorTotalChargableWeight = (totalWeight) => {
        var { ebooking, errors } = this.state;
        var totalChargableWeight = "";
        var validator = new Validator();
        if (ebooking.units_list.length) {
            var totalVolume = parseFloat(ebooking.units_total_volume) / 6 * 1000;
            if (ebooking.units_type_short != "CM") {
                totalVolume = totalVolume * 16.387064;
            }
            if (totalWeight != "" && validator.currency(totalWeight)) {
                totalWeight = parseFloat(totalWeight);

                totalChargableWeight = Math.max(totalVolume, totalWeight);
                ebooking.units_total_chargable_weight = totalChargableWeight.toFixed(1);
            } else {
                ebooking.units_total_chargable_weight = "";
            }
        } else {
            if (totalWeight != "" && validator.currency(totalWeight) && ebooking.units_total_volume != "" && validator.currency(ebooking.units_total_volume)) {
                totalWeight = parseFloat(totalWeight);
                var totalVolume = parseFloat(ebooking.units_total_volume) / 6 * 1000;
                if (ebooking.units_type_short != "CM") {
                    totalVolume = totalVolume * 16.387064;
                }
                totalChargableWeight = Math.max(totalVolume, totalWeight);
                ebooking.units_total_chargable_weight = totalChargableWeight.toFixed(1);
            } else {
                ebooking.units_total_chargable_weight = "";
            }
        }
        errors['units_total_chargable_weight'] = "";
        this.props.ebookingActions.ebookingChangeData({ fieldName: "units_total_chargable_weight", fieldValue: ebooking.units_total_chargable_weight }).then(responseData => { });
        this.setState({ ebooking, errors });
    }

    /* Handle clear date field value */
    handleClearDate = () => {
        const { ebooking } = this.state;
        this.setState({ ebooking, dataChanged: true });
    }

    /* Handler date fields change value */
    handleChangeDate = (v) => {
        const { ebooking } = this.state;
        if (moment.isMoment(v)) {
            ebooking.pickup_dropoff_date = v;
            ebooking.pickup_dropoff_date.set({ hour: ebooking.pickup_dropoff_time_hour, minute: ebooking.pickup_dropoff_time_minute });
        } else {
            ebooking.pickup_dropoff_date = null;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "pickup_dropoff_date", fieldValue: ebooking.pickup_dropoff_date }).then(responseData => { });
        this.setState({ ebooking, dataChanged: true });
    }


    handleOnBlurDate = (v) => {
        const { ebooking, errors, validateETD } = this.state;
        var dateNow = moment(new Date);
        var datePickup = moment(new Date);
        datePickup.add(1, 'months');
        var dateDropoff = moment(new Date);
        dateDropoff.add(1, 'months');
        if (ebooking.pickup_dropoff_date) {
            if (validateETD) {
                //Prod
                if (ebooking.deliver_from == "Airport") {
                    if (moment.isMoment(ebooking.pickup_dropoff_date) && ebooking.pickup_dropoff_date >= dateNow && ebooking.pickup_dropoff_date <= dateDropoff) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "";
                    } else if (ebooking.pickup_dropoff_date < dateNow || ebooking.pickup_dropoff_date > dateDropoff) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + dateDropoff.format("DD/MM HH:mm");
                    }
                } else {
                    if (moment.isMoment(ebooking.pickup_dropoff_date) && ebooking.pickup_dropoff_date >= dateNow && ebooking.pickup_dropoff_date <= datePickup) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "";
                    } else if (ebooking.pickup_dropoff_date < dateNow || ebooking.pickup_dropoff_date > datePickup) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + datePickup.format("DD/MM HH:mm");
                    }
                }
            } else {
                //Dev
                if (moment.isMoment(ebooking.pickup_dropoff_date)) {
                    errors["pickup_dropoff_date"] = "";
                }
            }
        } else {
            errors["pickup_dropoff_date"] = MESSAGES.ERROR_REQUIRED;
        }

        this.setState({ errors });
    }

    handleOnBlur = (event) => {
        var { ebooking, errors } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var validator = new Validator();
        if (name == "units_total_num_of_pieces") {
            if (value != "" && validator.number(value)) {
                errors["units_total_num_of_pieces"] = "";
            }
        }
        if (name == "units_total_volume") {
            if (value != "" && validator.currency(value)) {
                errors["units_total_volume"] = "";
            }
        }
        if (name == "units_total_weight") {
            if (value != "" && validator.currency(value)) {
                errors["units_total_weight"] = "";
                var currentWeight = { ...ebooking.units_total_weight };
                var newWeight = parseFloat(ebooking.units_total_weight).toFixed(1);
                if (currentWeight != newWeight) {
                    ebooking.units_total_weight = newWeight;
                    this.setState({ ebooking });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "units_total_weight", fieldValue: ebooking.units_total_weight }).then(responseData => { });
                    this.calculatorTotalChargableWeight(newWeight);

                }
            }
        }
        if (name == "units_total_chargable_weight") {
            if (value == "" || (value != "" && validator.currency(value))) {
                errors["units_total_chargable_weight"] = "";
            }
        }
        if (name == "deliver_from_zipcode") {
            if (value != "") {
                errors["deliver_from_zipcode"] = "";
            }
        }
        if (name == "deliver_to_zipcode") {
            if (value != "") {
                errors["deliver_to_zipcode"] = "";
            }
        }
        if (name == "deliver_to_city") {
            if (value != "") {
                errors["deliver_to_city"] = "";
            }
        }
        if (name == "deliver_from_city") {
            if (value != "") {
                errors["deliver_from_city"] = "";
            }
        }
        if (name == "overlap_shipment") {
            errors["overlap_shipment"] = "";
        }

        this.setState({ errors });
    }

    handleOnBlurSelect = (event) => {
        var { ebooking, errors, selectedCountryDeliverFrom, selectedCountryDeliverTo, selectedAirportDeliverFrom, selectedAirportDeliverTo, selectedCommodity, selectedInstruction, selectedSubCommodity } = this.state;
        var { target, target: { id, name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var validator = new Validator();
        if (id == "deliver_from_country") {
            if (selectedCountryDeliverFrom) {
                errors["deliver_from_country"] = "";
            }
        }
        if (id == "deliver_from_airport") {
            if (selectedAirportDeliverFrom) {
                errors["deliver_from_airport"] = "";
            }
        }
        if (id == "deliver_to_country") {
            if (selectedCountryDeliverTo) {
                errors["deliver_to_country"] = "";
            }
        }
        if (id == "deliver_to_airport") {
            if (selectedAirportDeliverTo) {
                errors["deliver_to_airport"] = "";
            }
        }
        if (id == "commodity_type") {
            if (selectedCommodity) {
                errors["commodity_type"] = "";
            }
        }
        if (id == "sub_commodity") {
            if (selectedSubCommodity) {
                errors["sub_commodity"] = "";
            }
        }
        this.setState({ errors });
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
        window.removeEventListener("message", this.handleWindowMessage);
    }

    handleWindowMessage = (e) => {
        if (e.data == "hide-modalLogin" || e.data == "hide-all-modals") {
            this.setState({ btnText: "View Quote", submiting: false });
        }
    }

    componentDidMount() {
        //get redux data and set to state           
        var { ebooking, auth } = this.props;
        var { steps } = this.state;
        this.setState({ ebooking });
        // console.log("this.props.match.path", this.props.match.path);
        if (this.props.match.path === "/ebooking") {
            this.props.ebookingActions.ebookingReset().then(res => {
                ebooking = this.props.ebooking;
                this.setState({ ebooking });
            });
        }

        window.addEventListener("message", this.handleWindowMessage);

        this.setState({ isLoadingCountry: true, validateETD: AppConfig.validateETD });
        var selectedCountryDeliverFrom = null;
        var selectedCountryDeliverTo = null;

        var countries = [];
        var countriesZip = [];
        var isLoadingCountry = true;
        if (this.props.country.state == "COUNTRY_LOADED_DATA") {
            isLoadingCountry = false;
            this.props.country.countries.forEach(c => {
                // if (c.forwebsite) {
                var country = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                var countryZip = { value: c.country, labelhiden: c.countryname, label: c.countryname, airportonly: c.airportonly };
                if (c.airportonly) {
                    countryZip.label = c.countryname + " (airport only)";
                }
                countries.push(country);
                countriesZip.push(countryZip);
                if (this.props.match.path != "/ebooking") {
                    if (c.countryname == ebooking.deliver_from_country) {
                        selectedCountryDeliverFrom = country;
                        this.setState({ selectedCountryDeliverFrom: selectedCountryDeliverFrom });
                    }
                    if (c.countryname == ebooking.deliver_to_country) {
                        selectedCountryDeliverTo = country;
                        this.setState({ selectedCountryDeliverTo: selectedCountryDeliverTo });
                    }
                }
                // }
            });
            if (selectedCountryDeliverFrom) {
                this.getAirportsFrom(selectedCountryDeliverFrom, ebooking);
            }
            if (selectedCountryDeliverTo) {
                this.getAirportsTo(selectedCountryDeliverTo, ebooking);
            }
        }
        this.setState({ countries: countries, countriesZip: countriesZip, isLoadingCountry: isLoadingCountry });
        if (this.props.port.state == "PORT_LOADED_DATA") {
            if (ebooking.deliver_from_country == "" || this.props.match.path === "/ebooking") {
                this.setDefaultPortFrom(this.props.match.path === "/ebooking" ? true : false, countries);
            }
            if (ebooking.deliver_to_country == "" || this.props.match.path === "/ebooking") {
                this.setDefaultPortTo(this.props.match.path === "/ebooking" ? true : false, countries);
            }
        }

        this.getInstructions();
        this.getCommodities();
        if (this.props.auth.state == "LOGGED_IN") {
            this.getParticipentCNEE();
            this.getParticipentCNOR();
            this.getPhoneZip();
        }
        this.checkShowLoginForm();
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
        if (auth.IsGSA) {
            steps[1].link = 'ebooking/participants-gsa';
        }
        this.setState({ steps });
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        // window.parent.postMessage('show-login-form', '*');
    }

    getPhoneZip = () => {
        this.setState({ getingPhoneZip: true });
        this.props.ebookingActions.getPhoneZip(this.props.auth.jwtToken).then(response => {
            this.setState({ getingPhoneZip: false });
            var ports = [];
            if (response.success && response.data.Success) {
                ports = response.data.Ports;
            }
            this.setState({ getingPhoneZip: false, phoneZip: ports });
        });
    }

    setDefaultPortFrom = (unselected = false, list_countries = []) => {
        var { selectedAirportDeliverFrom, airportsDeliverFrom, countries } = this.state;
        var { ebooking, port } = this.props;
        selectedAirportDeliverFrom = null;
        if (list_countries.length && !countries.length) {
            countries = list_countries;
        }
        airportsDeliverFrom = port.ports.filter(p => {
            var checkCountry = countries.find(c => c.value == p.country);
            if (checkCountry) {
                return true;
            }
            return false;
        });
        if (ebooking.deliver_from_airport && !unselected) {
            var getSelectedP = airportsDeliverFrom.find(p => p.portname === ebooking.deliver_from_airport);
            if (getSelectedP) {
                selectedAirportDeliverFrom = getSelectedP;
            }
        }
        this.setState({ airportsDeliverFrom, selectedAirportDeliverFrom });
    }

    setDefaultPortTo = (unselected = false, list_countries = []) => {
        var { selectedAirportDeliverTo, airportsDeliverTo, countries } = this.state;
        var { ebooking, port } = this.props;
        selectedAirportDeliverTo = null;
        if (list_countries.length && !countries.length) {
            countries = list_countries;
        }
        airportsDeliverTo = port.ports.filter(p => {
            var checkCountry = countries.find(c => c.value == p.country);
            if (checkCountry) {
                return true;
            }
            return false;
        });

        if (ebooking.deliver_to_airport && !unselected) {
            var getSelectedP = airportsDeliverTo.find(p => p.portname === ebooking.deliver_to_airport);
            if (getSelectedP) {
                selectedAirportDeliverTo = getSelectedP;
            }
        }
        this.setState({ airportsDeliverTo, selectedAirportDeliverTo });
    }

    handleChangeCountryFrom = (selectedCountryDeliverFrom) => {
        var { ebooking, inputValueCountryFrom } = this.state;
        ebooking["deliver_from_country"] = selectedCountryDeliverFrom.labelhiden ? selectedCountryDeliverFrom.labelhiden : "";
        ebooking["deliver_from_airport_data"] = { calcity: "", cityname: "" };
        ebooking['deliver_from_zipcode'] = "";
        this.setState({ ebooking, selectedCountryDeliverFrom, selectedAirportDeliverFrom: null, inputValueCountryFrom: "", dataChanged: true });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_country", fieldValue: ebooking.deliver_from_country }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport_data", fieldValue: ebooking.deliver_from_airport_data }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_zipcode", fieldValue: ebooking.deliver_from_zipcode }).then(responseData => { });
        if (ebooking.deliver_from == "Airport") {
            if (ebooking.deliver_from_country == "") {
                this.setDefaultPortFrom();
            } else {
                this.getAirportsFrom(selectedCountryDeliverFrom, ebooking);
            }
        }
    }

    handleChangeCountryTo = (selectedCountryDeliverTo) => {
        var { ebooking, inputValueCountryFrom } = this.state;
        ebooking["deliver_to_country"] = selectedCountryDeliverTo.labelhiden ? selectedCountryDeliverTo.labelhiden : "";
        ebooking["deliver_to_airport"] = "";
        ebooking["deliver_to_airport_number"] = 0;
        ebooking["deliver_to_airport_data"] = { calcity: "", cityname: "" };
        ebooking['deliver_to_zipcode'] = '';
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_country", fieldValue: ebooking.deliver_to_country }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport", fieldValue: ebooking.deliver_to_airport }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_data", fieldValue: ebooking.deliver_to_airport_data }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_number", fieldValue: ebooking.deliver_to_airport_number }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_zipcode", fieldValue: ebooking.deliver_to_zipcode }).then(responseData => { });
        this.setState({ ebooking, selectedCountryDeliverTo, selectedAirportDeliverTo: null, inputValueCountryFrom: "", dataChanged: true });
        if (ebooking.deliver_to == "Airport") {
            if (ebooking.deliver_to_country == "") {
                this.setDefaultPortTo();
            } else {
                this.getAirportsTo(selectedCountryDeliverTo, ebooking);
            }
        }
    }

    handleChangeAirportFrom = (selectedAirportDeliverFrom) => {
        var { ebooking, airportsDeliverFrom, selectedCountryDeliverFrom, countries } = this.state;
        ebooking["deliver_from_airport"] = selectedAirportDeliverFrom.value;
        ebooking["deliver_from_airport_number"] = selectedAirportDeliverFrom.pnum;
        ebooking["deliver_from_airport_data"] = { calcity: "", cityname: "" };

        var findData = airportsDeliverFrom.find(p => p.portname === selectedAirportDeliverFrom.value);
        if (findData) {
            ebooking["deliver_from_airport_data"] = findData;
        }

        if (!selectedCountryDeliverFrom || !selectedCountryDeliverFrom.length) {
            var selectedCountry = countries.find(c => c.value === selectedAirportDeliverFrom.country);
            if (selectedCountry) {
                selectedCountryDeliverFrom = selectedCountry;
                ebooking.deliver_from_country = selectedCountryDeliverFrom.labelhiden;
                airportsDeliverFrom = this.props.port.ports.filter(p => p.country === selectedCountryDeliverFrom.value);
            }
        }

        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_country", fieldValue: ebooking.deliver_from_country }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport", fieldValue: ebooking.deliver_from_airport }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport_data", fieldValue: ebooking.deliver_from_airport_data }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport_number", fieldValue: ebooking.deliver_from_airport_number }).then(responseData => { });
        this.setState({ ebooking, selectedAirportDeliverFrom, dataChanged: true, selectedCountryDeliverFrom, airportsDeliverFrom });
    }

    handleChangeAirportTo = (selectedAirportDeliverTo) => {
        var { ebooking, airportsTo, airportsDeliverTo, selectedCountryDeliverTo, countries } = this.state;
        ebooking["deliver_to_airport"] = selectedAirportDeliverTo.value;
        ebooking["deliver_to_airport_number"] = selectedAirportDeliverTo.pnum;
        ebooking["deliver_to_airport_data"] = { calcity: "", cityname: "" };

        var findData = airportsDeliverTo.find(p => p.portname === selectedAirportDeliverTo.value);
        if (findData) {
            ebooking["deliver_to_airport_data"] = findData;
        }
        if (!selectedCountryDeliverTo || !selectedCountryDeliverTo.length) {
            var selectedCountry = countries.find(c => c.value === selectedAirportDeliverTo.country);
            if (selectedCountry) {
                selectedCountryDeliverTo = selectedCountry;
                ebooking.deliver_to_country = selectedCountryDeliverTo.labelhiden;
                airportsDeliverTo = this.props.port.ports.filter(p => p.country === selectedCountryDeliverTo.value);
            }
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_country", fieldValue: ebooking.deliver_to_country }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport", fieldValue: ebooking.deliver_to_airport }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_data", fieldValue: ebooking.deliver_to_airport_data }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_number", fieldValue: ebooking.deliver_to_airport_number }).then(responseData => { });
        this.setState({ ebooking, selectedAirportDeliverTo, dataChanged: true, selectedCountryDeliverTo, airportsDeliverTo });
    }

    handleChangeInstructions = (selectedInstruction) => {
        this.setState({ selectedInstruction });
    }

    handleChangeCommodity = (selectedCommodity) => {
        var { ebooking } = this.state;
        ebooking["commodity_type"] = selectedCommodity.value;
        ebooking["commodity_type_name"] = selectedCommodity.label;
        ebooking["sub_commodity"] = "";
        ebooking["sub_commodity_name"] = "";
        this.setState({ ebooking, selectedCommodity, selectedSubCommodity: null, listSubCommodity: [], dataChanged: true });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "commodity_type", fieldValue: ebooking.commodity_type }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity", fieldValue: ebooking.sub_commodity }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "commodity_type_name", fieldValue: ebooking.commodity_type_name }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity_name", fieldValue: ebooking.sub_commodity_name }).then(responseData => { });
        this.getSubCommodity(selectedCommodity);
    }

    handleChangeSubCommodity = (selectedSubCommodity) => {
        var { ebooking } = this.state;
        ebooking["sub_commodity"] = selectedSubCommodity.value;
        ebooking["sub_commodity_name"] = selectedSubCommodity.label;
        this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity", fieldValue: ebooking.sub_commodity }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity_name", fieldValue: ebooking.sub_commodity_name }).then(responseData => { });
        this.setState({ ebooking, selectedSubCommodity, dataChanged: true });
    }

    handleClickInstruction = () => {
        var { ebooking } = this.state;
        if (this.state.selectedInstruction) {
            var checkExisted = false;
            ebooking.instructions.forEach(a => {
                if (a.value == this.state.selectedInstruction.value) {
                    checkExisted = true;
                }
            });
            if (!checkExisted) {
                ebooking.instructions.push(this.state.selectedInstruction);
                this.props.ebookingActions.ebookingChangeData({ fieldName: "instructions", fieldValue: ebooking.instructions }).then(responseData => { });
            }
            this.setState({ ebooking, selectedInstruction: null, dataChanged: true });
        }
    }

    /* handler form's fields change value */
    handleChange(event) {
        var { ebooking, pickup_dropoff_text, errors, selectedCountryDeliverFrom, selectedCountryDeliverTo, selectedAirportDeliverTo, selectedAirportDeliverFrom } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        var t = this;
        if (name == "t1_shipment") {
            errors["pickup_dropoff_date"] = "";
            errors["pickup_dropoff_date_max"] = "";
            ebooking["pickup_dropoff_date"] = "";
            ebooking["pickup_dropoff_time_hour"] = "00";
            ebooking["pickup_dropoff_time_minute"] = "00";
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickup_dropoff_date", fieldValue: ebooking.pickup_dropoff_date }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickup_dropoff_time_hour", fieldValue: ebooking.pickup_dropoff_time_hour }).then(responseData => { });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "pickup_dropoff_time_minute", fieldValue: ebooking.pickup_dropoff_time_minute }).then(responseData => { });

            switch (value) {
                case "T1":
                case "PTP":
                    pickup_dropoff_text = "Dropoff Time (Local)";
                    ebooking['deliver_from'] = "Airport";
                    ebooking["deliver_from_zipcode"] = "";
                    ebooking["deliver_from_city"] = "";
                    errors["deliver_from_city"] = "";
                    errors["deliver_from_zipcode"] = "";
                    if (ebooking.deliver_from_country == "") {
                        this.setDefaultPortFrom();
                    } else {
                        this.getAirportsTo(selectedCountryDeliverFrom, ebooking);
                    }
                    ebooking['deliver_to'] = "Airport";
                    ebooking["deliver_to_zipcode"] = "";
                    ebooking["deliver_to_city"] = "";
                    errors["deliver_to_city"] = "";
                    errors["deliver_to_zipcode"] = "";
                    if (ebooking.deliver_to_country == "") {
                        this.setDefaultPortTo();
                    } else {
                        this.getAirportsFrom(selectedCountryDeliverFrom, ebooking);
                    }
                    break;
                case "PTD":
                    pickup_dropoff_text = "Dropoff Time (Local)";
                    ebooking['deliver_from'] = "Airport";
                    ebooking["deliver_from_zipcode"] = "";
                    ebooking["deliver_from_city"] = "";
                    errors["deliver_from_city"] = "";
                    errors["deliver_from_zipcode"] = "";
                    if (ebooking.deliver_from_country == "") {
                        this.setDefaultPortFrom();
                    } else {
                        this.getAirportsTo(selectedCountryDeliverFrom, ebooking);
                    }
                    ebooking['deliver_to'] = "ZipCode";
                    ebooking["deliver_to_airport"] = "";
                    ebooking["deliver_to_airport_number"] = 0;
                    ebooking["deliver_to_airport_data"] = { calcity: "", cityname: "" };
                    errors["deliver_to_airport"] = "";
                    selectedAirportDeliverTo = null;
                    break;
                case "DTD":
                    ebooking['deliver_from'] = "ZipCode";
                    pickup_dropoff_text = "Pickup Time (Local)";
                    ebooking["deliver_from_airport"] = "";
                    ebooking["deliver_from_airport_number"] = 0;
                    errors["deliver_from_airport"] = "";
                    ebooking["deliver_from_airport_data"] = { calcity: "", cityname: "" };
                    selectedAirportDeliverFrom = null
                    ebooking['deliver_to'] = "ZipCode";
                    ebooking["deliver_to_airport"] = "";
                    ebooking["deliver_to_airport_number"] = 0;
                    ebooking["deliver_to_airport_data"] = { calcity: "", cityname: "" };
                    errors["deliver_to_airport"] = "";
                    selectedAirportDeliverTo = null
                    break;
                case "DTP":
                    ebooking['deliver_from'] = "ZipCode";
                    pickup_dropoff_text = "Pickup Time (Local)";
                    ebooking["deliver_from_airport"] = "";
                    ebooking["deliver_from_airport_number"] = 0;
                    errors["deliver_from_airport"] = "";
                    ebooking["deliver_from_airport_data"] = { calcity: "", cityname: "" };
                    selectedAirportDeliverFrom = null
                    ebooking['deliver_to'] = "Airport";
                    ebooking["deliver_to_zipcode"] = "";
                    ebooking["deliver_to_city"] = "";
                    errors["deliver_to_city"] = "";
                    errors["deliver_to_zipcode"] = "";
                    if (ebooking.deliver_to_country == "") {
                        this.setDefaultPortTo();
                    } else {
                        this.getAirportsFrom(selectedCountryDeliverFrom, ebooking);
                    }
                    break;
            }
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == "dimemtions_by") {
            errors["dimemtions_by"] = "";
            errors["unit_height"] = "";
            errors["unit_width"] = "";
            errors["unit_length"] = "";
            errors["unit_volume"] = "";
            errors["unit_uld_type"] = "";
            errors["unit_number_of_pieces"] = "";
            ebooking["units_list"] = [];
            ebooking["units_total_num_of_pieces"] = "";
            ebooking["units_total_volume"] = "";
            ebooking["units_total_chargable_weight"] = "";
            ebooking["overlap_shipment"] = "NonOverlap";
            ebooking["overlap_shipment_check"] = false;
            if (value === "ULD") {
                // ebooking["unit_type"] = "Centimeters";
                // ebooking["units_type_short"] = "CM";
                ebooking['overlap_shipment'] = "NonOverlap";
                ebooking['overlap_shipment_check'] = false;
            }
            this.setState({ unit_height: "", unit_length: "", unit_width: "", unit_number_of_pieces: "", units_list: [], unit_uld_type: "", unit_volume: "" });
            this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
        } else if (name == "units_type") {
            if (value == "Centimeters") {
                ebooking["units_type_short"] = "CM";
            } else {
                ebooking["units_type_short"] = "IN";
            }
            this.unitTypeChange(ebooking["units_type_short"]);
            this.props.ebookingActions.ebookingChangeData({ fieldName: "units_type_short", fieldValue: ebooking.units_type_short }).then(responseData => { });
            this.calculatorTotalChargableWeight(ebooking.units_total_weight);
        } else if (name == "shipment_dangerous_goods") {
            errors.class = "";
            if (ebooking.shipment_lithium_batteries == true) {
                value = true;
            }
            if (value == false) {
                ebooking.class = [];
                ebooking.un = "";
                // ebooking.additional_information = "";
                this.props.ebookingActions.ebookingChangeData({ fieldName: "class", fieldValue: ebooking.class }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "un", fieldValue: ebooking.un }).then(responseData => { });
                // this.props.ebookingActions.ebookingChangeData({ fieldName: "additional_information", fieldValue: ebooking.additional_information }).then(responseData => { });
            }
        } else if (name == "shipment_lithium_batteries") {
            if (value == true) {
                ebooking["shipment_dangerous_goods"] = true;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "shipment_dangerous_goods", fieldValue: ebooking.shipment_dangerous_goods }).then(responseData => { });
            }
        } else if (name == "shipment_dangerous_goods_type") {
            if (value == false) {
                value = "CAO";
            } else {
                value = "PAX";
            }
        } else if (name == "deliver_from") {
            if (value == 'Airport') {
                ebooking["deliver_from_zipcode"] = "";
                errors["deliver_from_zipcode"] = "";
                errors["pickup_dropoff_date"] = "";
                errors["pickup_dropoff_date_max"] = "";
                ebooking["deliver_from_city"] = "";
                errors["deliver_from_city"] = "";
                pickup_dropoff_text = "Dropoff Time (Local)";
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_zipcode", fieldValue: ebooking.deliver_from_zipcode }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_city", fieldValue: ebooking.deliver_from_city }).then(responseData => { });
                this.getAirportsFrom(selectedCountryDeliverFrom, ebooking);
            } else {
                pickup_dropoff_text = "Pickup Time (Local)";
                ebooking["deliver_from_airport"] = "";
                errors["pickup_dropoff_date"] = "";
                errors["pickup_dropoff_date_max"] = "";
                ebooking["deliver_from_airport_number"] = 0;
                errors["deliver_from_airport"] = "";
                selectedAirportDeliverFrom = null;
                if (selectedCountryDeliverFrom && selectedCountryDeliverFrom.airportonly) {
                    selectedCountryDeliverFrom = null;
                    ebooking.deliver_from_country = "";
                }
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport", fieldValue: ebooking.deliver_from_airport }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_country", fieldValue: ebooking.deliver_from_country }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_from_airport_number", fieldValue: ebooking.deliver_from_airport_number }).then(responseData => { });
            }
            this.setState({ inputValueCountryFrom: " " });
            setTimeout(function () {
                t.setState({ inputValueCountryFrom: "" });
            }, 200);
        } else if (name == "deliver_to") {
            if (value == 'Airport') {
                ebooking["deliver_to_zipcode"] = "";
                errors["deliver_to_zipcode"] = "";
                ebooking["deliver_to_city"] = "";
                errors["deliver_to_city"] = "";
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_zipcode", fieldValue: ebooking.deliver_to_zipcode }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_city", fieldValue: ebooking.deliver_to_city }).then(responseData => { });
                this.getAirportsTo(selectedCountryDeliverTo, ebooking);
            } else {
                ebooking["deliver_to_airport"] = "";
                ebooking["deliver_to_airport_number"] = 0;
                ebooking["deliver_to_airport_data"] = { calcity: "", cityname: "" };
                selectedAirportDeliverTo = null;
                if (selectedCountryDeliverTo && selectedCountryDeliverTo.airportonly) {
                    selectedCountryDeliverTo = null;
                    ebooking.deliver_to_country = "";
                }
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport", fieldValue: ebooking.deliver_to_airport }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_data", fieldValue: ebooking.deliver_to_airport_data }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_airport_number", fieldValue: ebooking.deliver_to_airport_number }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "deliver_to_country", fieldValue: ebooking.deliver_to_country }).then(responseData => { });
                errors["deliver_to_airport"] = "";
            }
            this.setState({ inputValueCountryTo: " " });
            setTimeout(function () {
                t.setState({ inputValueCountryTo: "" });
            }, 200);
        } else if (name == "units_total_weight") {
            this.calculatorTotalChargableWeight(value);
        } else if (name == "overlap_shipment") {
            errors["overlap_shipment"] = "";
            if (value) {
                value = "Overlap";
            } else {
                value = "NonOverlap";
            }
        } else if (name == "units_total_volume") {
            ebooking[name] = value;
            this.setState({ ebooking });
            this.calculatorTotalChargableWeight(ebooking.units_total_weight);
        } else if (name == "pickup_dropoff_time_hour") {
            if (moment.isMoment(ebooking.pickup_dropoff_date)) {
                ebooking.pickup_dropoff_date.set({ hour: value, minute: ebooking.pickup_dropoff_time_minute });
                this.props.ebookingActions.ebookingChangeData({ fieldName: 'pickup_dropoff_date', fieldValue: ebooking.pickup_dropoff_date }).then(responseData => { });
                this.setState({ ebooking });
                this.handleOnBlurDate();
            }
        } else if (name == "pickup_dropoff_time_minute") {
            if (moment.isMoment(ebooking.pickup_dropoff_date)) {
                ebooking.pickup_dropoff_date.set({ hour: ebooking.pickup_dropoff_time_hour, minute: value });
                this.props.ebookingActions.ebookingChangeData({ fieldName: 'pickup_dropoff_date', fieldValue: ebooking.pickup_dropoff_date }).then(responseData => { });
                this.setState({ ebooking });
                this.handleOnBlurDate();
            }
        }
        ebooking[name] = value;
        this.setState({ ebooking, errors, pickup_dropoff_text: pickup_dropoff_text, selectedCountryDeliverFrom, selectedCountryDeliverTo, selectedAirportDeliverTo, selectedAirportDeliverFrom, dataChanged: true });

        //Change Data
        this.props.ebookingActions.ebookingChangeData({ fieldName: name, fieldValue: value }).then(responseData => {
            //reset description value
            if (name === "ebookingertype") {
                ebooking["description"] = "";
                this.setState({ ebooking });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "description", fieldValue: "" }).then(responseData => {
                });
            }
        });
    }


    handleChangeUnit = async (event) => {
        var { ebooking, pickup_dropoff_text, unit_height, unit_width, unit_length, unit_volume } = this.state;
        var { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;

        this.state[name] = value;
        if (name === "unit_uld_type") {
            if (value === "HD") {
                unit_height = 300;
                unit_width = 244;
                unit_length = 317;
            } else if (value === "HDS") {
                unit_height = 300;
                unit_width = 100;
                unit_length = 120;
            } else if (value === "MD") {
                unit_height = 240;
                unit_width = 244;
                unit_length = 317;
            } else if (value === "MDS") {
                unit_height = 240;
                unit_width = 100;
                unit_length = 120;
            } else if (value === "LD") {
                unit_height = 160;
                unit_width = 244;
                unit_length = 317;
            } else if (value === "LDS") {
                unit_height = 160;
                unit_width = 100;
                unit_length = 120;
            } else {
                unit_height = "";
                unit_width = "";
                unit_length = "";
            }
            unit_volume = "";
            await this.setState({ unit_height, unit_width, unit_length, unit_volume });
        }
        await this.setState({ name: value, dataChanged: true });
        this.validateUnit();
    }

    validateUnit = (event) => {
        var { ebooking, errors, unit_height, unit_number_of_pieces, unit_length, unit_width } = this.state;
        var validator = new Validator();
        var error = false;
        errors["unit_number_of_pieces"] = "";
        errors["unit_height"] = "";
        errors["unit_length"] = "";
        errors["unit_width"] = "";
        if (ebooking.dimemtions_by === "ULD") {
            if (!validator.number(unit_number_of_pieces) || parseInt(unit_number_of_pieces) <= 0) {
                error = true;
                errors["unit_number_of_pieces"] = "Input Number";
            }
        } else {
            if (!validator.number(unit_number_of_pieces) || parseInt(unit_number_of_pieces) < 1) {
                error = true;
                errors["unit_number_of_pieces"] = "Input Number";
            }
        }

        if (!validator.currency(unit_height) || parseFloat(unit_height) <= 0) {
            error = true;
            errors["unit_height"] = "Input Number";
        }
        if (!validator.currency(unit_length) || parseFloat(unit_length) <= 0) {
            error = true;
            errors["unit_length"] = "Input Number";
        }
        if (!validator.currency(unit_width) || parseFloat(unit_width) <= 0) {
            error = true;
            errors["unit_width"] = "Input Number";
        }
        this.setState({ errors });
        this.calculatorVolume();
    }

    calculatorVolume = () => {
        var { unit_height, unit_length, unit_width, unit_volume } = this.state;

        if (unit_height != "" && unit_length != "" && unit_width != "") {
            unit_volume = unit_height * unit_length * unit_width * 0.000001;
            this.setState({ "unit_volume": unit_volume.toFixed(3) });
        }
    }

    getAirportsFrom = (selectedCountryDeliverFrom, ebooking) => {
        var { airportsDeliverFrom, airportsFrom, selectedAirportDeliverFrom } = this.state;
        var { port } = this.props;
        airportsDeliverFrom = [];
        selectedAirportDeliverFrom = null;
        if (selectedCountryDeliverFrom) {
            this.setState({ isLoadingAirportFrom: true });
            airportsDeliverFrom = port.ports.filter(p => p.country === selectedCountryDeliverFrom.value);

            if (ebooking.deliver_from_airport) {
                var getSelectedP = airportsDeliverFrom.find(p => p.portname === ebooking.deliver_from_airport);
                if (getSelectedP) {
                    selectedAirportDeliverFrom = getSelectedP;
                }
            }
            this.setState({ airportsDeliverFrom: airportsDeliverFrom, isLoadingAirportFrom: false, airportsFrom, selectedAirportDeliverFrom: selectedAirportDeliverFrom });
        } else {
            this.setState({ airportsDeliverFrom: airportsDeliverFrom, airportsFrom: [] });
        }
    }

    getAirportsTo = (selectedCountryDeliverTo, ebooking) => {
        var { airportsDeliverTo, airportsTo, selectedAirportDeliverTo } = this.state;
        var { port } = this.props;
        airportsDeliverTo = [];
        selectedAirportDeliverTo = null;
        if (selectedCountryDeliverTo) {
            this.setState({ isLoadingAirportTo: true });
            airportsDeliverTo = port.ports.filter(p => p.country === selectedCountryDeliverTo.value);
            if (ebooking.deliver_to_airport) {
                var getSelectedP = airportsDeliverTo.find(p => p.portname === ebooking.deliver_to_airport);
                if (getSelectedP) {
                    selectedAirportDeliverTo = getSelectedP;
                }
            }
            this.setState({ airportsDeliverTo: airportsDeliverTo, isLoadingAirportTo: false, airportsTo, selectedAirportDeliverTo: selectedAirportDeliverTo });
        } else {
            this.setState({ airportsDeliverTo: airportsDeliverTo, airportsFrom: [] });
        }
    }

    getInstructions = () => {
        var { listInstructions } = this.state;
        listInstructions = [];
        this.setState({ isLoadingInstruction: true });
        this.props.ebookingActions.getInstructions().then(response => {
            listInstructions = [];
            if (response.success) {
                var handlingInstructions = response.data.handlingInstructions;
                handlingInstructions = handlingInstructions.sort((a, b) => { return a.Descreption.localeCompare(b.Descreption) });
                handlingInstructions.forEach(a => {
                    listInstructions.push({ "value": a.Code, "label": a.Descreption });
                });
            }
            this.setState({ listInstructions, isLoadingInstruction: false });
        });
    }

    getCommodities = () => {
        this.setState({ isLoadingCommodity: true });
        this.props.ebookingActions.getCommodity("0").then(response => {
            var { ebooking, listCommodity, commodities, selectedCommodity } = this.state;
            listCommodity = [];
            commodities = [];
            if (response.success) {
                commodities = response.data.Comodities;
                commodities = commodities.sort((a, b) => { return a.PartDes.localeCompare(b.PartDes) });
                response.data.Comodities.forEach(a => {
                    listCommodity.push({ "value": a.PartName, "label": a.PartDes });
                    if (a.PartName == ebooking.commodity_type) {
                        ebooking.commodity_type_name = a.PartDes;
                        selectedCommodity = { "value": a.PartName, "label": a.PartDes };
                    }
                });
            }
            this.props.ebookingActions.ebookingChangeData({ fieldName: "commodity_type_name", fieldValue: ebooking.commodity_type_name }).then(responseData => { });
            this.setState({ listCommodity, isLoadingCommodity: false, commodities: commodities, selectedCommodity: selectedCommodity });
            if (selectedCommodity) {
                this.getSubCommodity(selectedCommodity);
            }
        });
    }

    getSubCommodity = (selectedCommodity) => {
        var { ebooking, listSubCommodity, commodities, selectedSubCommodity, errors } = this.state;
        selectedSubCommodity = null;
        listSubCommodity = [];
        if (selectedCommodity) {
            this.setState({ isLoadingSubCommodity: true });

            var rootCom = "";
            commodities.forEach(a => {
                if (selectedCommodity.value == a.PartName) {
                    rootCom = a.PartAU;
                }
            });

            this.props.ebookingActions.getCommodity(rootCom).then(response => {
                if (response.success) {
                    var subComodities = response.data.Comodities;
                    subComodities = subComodities.sort((a, b) => { return a.PartDes.localeCompare(b.PartDes) });
                    subComodities.forEach(a => {
                        listSubCommodity.push({ "value": a.PartName, "label": a.PartDes });
                        if (ebooking.sub_commodity == a.PartName) {
                            ebooking.sub_commodity_name = a.PartDes;
                            selectedSubCommodity = { "value": a.PartName, "label": a.PartDes };
                        }
                    });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity_name", fieldValue: ebooking.sub_commodity_name }).then(responseData => { });
                    if (listSubCommodity.length == 1) {
                        errors['sub_commodity'] = "";
                        selectedSubCommodity = listSubCommodity[0];
                        ebooking.sub_commodity = selectedSubCommodity.value;
                        this.props.ebookingActions.ebookingChangeData({ fieldName: "sub_commodity", fieldValue: ebooking.sub_commodity }).then(responseData => { });
                    }
                }
                this.setState({ listSubCommodity, isLoadingSubCommodity: false, selectedSubCommodity: selectedSubCommodity, ebooking, errors });
            });
        }

    }

    /* Validate form */
    isValidation = async () => {
        this.setState({ errors: {}, errorMessage: '' });

        var error = false;
        var errors = {};
        var isError = false;
        var validator = new Validator();
        var { ebooking, validateETD, unit_number_of_pieces, unit_height, unit_length, unit_width, unit_uld_type } = this.state;

        if (unit_uld_type || unit_number_of_pieces !== '' || unit_height !== '' || unit_length !== '' || unit_width !== '') {
            if (ebooking.dimemtions_by === "ULD") {
                if (unit_number_of_pieces == "" || !validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0) {
                    error = true;
                    isError = true;
                    errors["unit_number_of_pieces"] = "Input Number";
                }
            } else {
                if (unit_number_of_pieces == "" || !validator.number(unit_number_of_pieces) || parseFloat(unit_number_of_pieces) <= 0) {
                    error = true;
                    isError = true;
                    errors["unit_number_of_pieces"] = "Input Number";
                }
            }
            if (unit_height == "" || !validator.currency(unit_height) || parseFloat(unit_height) <= 0) {
                error = true;
                isError = true;
                errors["unit_height"] = "Input Number";
            }
            if (unit_length == "" || !validator.currency(unit_length) || parseFloat(unit_length) <= 0) {
                error = true;
                isError = true;
                errors["unit_length"] = "Input Number";
            }
            if (unit_width == "" || !validator.currency(unit_width) || parseFloat(unit_width) <= 0) {
                error = true;
                isError = true;
                errors["unit_width"] = "Input Number";
            }

            if (ebooking.dimemtions_by === "ULD" && unit_uld_type === "") {
                error = true;
                isError = true;
                errors["unit_uld_type"] = "Plz select";
            }

            if (!error) {
                await this.addUnit();
                ebooking = this.state.ebooking;
            }
        }

        var dateNow = moment(new Date);
        var datePickup = moment(new Date);
        datePickup.add(1, 'months');
        // datePickup.add(22, 'days');
        var dateDropoff = moment(new Date);
        dateDropoff.add(1, 'months');
        // dateDropoff.add(21, 'days');

        if (ebooking.pickup_dropoff_date) {
            if (validateETD) {
                //Prod
                if (ebooking.deliver_from == "Airport") {
                    if (moment.isMoment(ebooking.pickup_dropoff_date) && ebooking.pickup_dropoff_date >= dateNow && ebooking.pickup_dropoff_date <= dateDropoff) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "";
                    } else if (ebooking.pickup_dropoff_date < dateNow || ebooking.pickup_dropoff_date > dateDropoff) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + dateDropoff.format("DD/MM HH:mm");
                        isError = true;

                    }
                } else {
                    if (moment.isMoment(ebooking.pickup_dropoff_date) && ebooking.pickup_dropoff_date >= dateNow && ebooking.pickup_dropoff_date <= datePickup) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "";
                    } else if (ebooking.pickup_dropoff_date < dateNow || ebooking.pickup_dropoff_date > datePickup) {
                        errors["pickup_dropoff_date"] = "";
                        errors["pickup_dropoff_date_max"] = "Date is mandatory and limited to " + datePickup.format("DD/MM HH:mm");
                        isError = true;
                    }
                }
            } else {
                //Dev
                if (!moment.isMoment(ebooking.pickup_dropoff_date)) {
                    errors['pickup_dropoff_date'] = MESSAGES.ERROR_REQUIRED;
                    isError = true;
                }
            }

        } else {
            errors["pickup_dropoff_date"] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (ebooking.deliver_from_country == "") {
            errors['deliver_from_country'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_from == "Airport" && ebooking.deliver_from_airport == "") {
            errors['deliver_from_airport'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_from == "ZipCode" && ebooking.deliver_from_zipcode == "") {
            errors['deliver_from_zipcode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_from == "ZipCode" && ebooking.deliver_from_city == "") {
            errors['deliver_from_city'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_to_country == "") {
            errors['deliver_to_country'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_to == "Airport" && ebooking.deliver_to_airport == "") {
            errors['deliver_to_airport'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_to == "ZipCode" && ebooking.deliver_to_zipcode == "") {
            errors['deliver_to_zipcode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_to == "ZipCode" && ebooking.deliver_to_city == "") {
            errors['deliver_to_city'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.deliver_from == "Airport" && ebooking.deliver_to == "Airport" && ebooking.deliver_from_airport == ebooking.deliver_to_airport) {
            errors['deliver_to_airport'] = MESSAGES.ERROR_PORT_SAME;
            isError = true;
        }
        if (ebooking.deliver_from == "ZipCode" && ebooking.deliver_to == "ZipCode" && ebooking.deliver_from_zipcode == ebooking.deliver_to_zipcode && (ebooking.deliver_from_zipcode != "" || ebooking.deliver_to_zipcode != "")) {
            errors['deliver_to_zipcode'] = MESSAGES.ERROR_ZIP_SAME;
            isError = true;
        }
        if (ebooking.commodity_type == "") {
            errors['commodity_type'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.sub_commodity == "") {
            errors['sub_commodity'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (!ebooking.units_list.length) {
            errors["unit_rows"] = "Please enter dimensions";
            isError = true;
        }

        if (ebooking.units_list.length && (ebooking.units_total_num_of_pieces == "" || ebooking.units_total_num_of_pieces <= 0 || !validator.currency(ebooking.units_total_num_of_pieces))) {
            errors['units_total_num_of_pieces'] = "Required";
            isError = true;
        }
        if (ebooking.units_total_weight == "" || ebooking.units_total_weight <= 0 || !validator.currency(ebooking.units_total_weight)) {
            errors['units_total_weight'] = "Required";
            isError = true;
        }
        if (ebooking.units_total_chargable_weight != "" && !validator.currency(ebooking.units_total_chargable_weight)) {
            errors['units_total_chargable_weight'] = "Number only";
            isError = true;
        }

        if (ebooking.units_total_volume == "" || parseFloat(ebooking.units_total_volume) <= 0 || !validator.currency(ebooking.units_total_volume)) {
            errors['units_total_volume'] = "Required";
            isError = true;
        }
        if (ebooking.shipment_dangerous_goods && ebooking.class.length == 0) {
            errors['class'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (ebooking.overlap_shipment == "" && !ebooking.overlap_shipment_check) {
            errors['overlap_shipment'] = "Is this an overlap shipment?";
            isError = true;
        }
        if (isError) {
            this.setState({ errors, error });
            this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.ebookingActions.ebookingChangeData({ fieldName: "step_1_ok", fieldValue: true }).then(responseData => { });
        return true;
    }


    /**
     * Handler click step
     */
    handClickStep = async (e) => {
        // console.log(e);
        var { ebooking } = this.props;
        let validateReturn = await this.isValidation();
        // console.log('validateReturn', validateReturn);
        if (!validateReturn || this.state.dataChanged) {
            e.stopPropagation();
            e.preventDefault();
            if (this.state.dataChanged) {
                if (ebooking.populateImA) {
                    ebooking.receivingCompanyName = "";
                    ebooking.receivingIATA1 = "";
                    ebooking.receivingIATA2 = "";
                    ebooking.shippingCompanyName = "";
                    ebooking.shippingIATA1 = "";
                    ebooking.shippingIATA2 = "";
                }
                if (ebooking.populateConsignor) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consignorCompanyName = ebooking.duplicateFields.CNORNAME ? ebooking.duplicateFields.CNORNAME : "";
                        ebooking.consignorAddress = ebooking.duplicateFields.CNORADDRESS ? ebooking.duplicateFields.CNORADDRESS : "";
                        ebooking.consignorCity = ebooking.duplicateFields.CNORCITY ? ebooking.duplicateFields.CNORCITY : "";
                        ebooking.consignorCountry = ebooking.duplicateFields.CNORCOUNTRY ? ebooking.duplicateFields.CNORCOUNTRY : "";
                        ebooking.consignorPostalCode = ebooking.duplicateFields.CNORPOSTALCODE ? ebooking.duplicateFields.CNORPOSTALCODE : "";
                    } else {
                        ebooking.consignorCompanyName = "";
                        ebooking.consignorAddress = "";
                        ebooking.consignorCity = "";
                        ebooking.consignorCountry = "";
                        ebooking.consignorPostalCode = "";
                    }
                }
                if (ebooking.populateConsignee) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consigneeAddress = ebooking.duplicateFields.CNEEADDRESS ? ebooking.duplicateFields.CNEEADDRESS : "";
                        ebooking.consigneeCity = ebooking.duplicateFields.CNEECITY ? ebooking.duplicateFields.CNEECITY : "";
                        ebooking.consigneeCompanyName = ebooking.duplicateFields.CNEENAME ? ebooking.duplicateFields.CNEENAME : "";
                        ebooking.consigneeCountry = ebooking.duplicateFields.CNEECOUNTRY ? ebooking.duplicateFields.CNEECOUNTRY : "";
                        ebooking.consigneePostalCode = ebooking.duplicateFields.CNEEPOSTALCODE ? ebooking.duplicateFields.CNEEPOSTALCODE : "";
                    } else {
                        ebooking.consigneeCompanyName = "";
                        ebooking.consigneeAddress = "";
                        ebooking.consigneeCity = "";
                        ebooking.consigneeCountry = "";
                        ebooking.consigneePostalCode = "";
                    }
                }

                if (ebooking.populateDelivery) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.deliverCity = ebooking.duplicateFields.DELCITY ? ebooking.duplicateFields.DELCITY : "";
                        ebooking.deliverCountry = ebooking.duplicateFields.DELCOUNTRY ? ebooking.duplicateFields.DELCOUNTRY : "";
                        ebooking.deliverPostalCode = ebooking.duplicateFields.DELPOSTALCODE ? ebooking.duplicateFields.DELPOSTALCODE : "";
                    } else {
                        ebooking.deliverCountry = "";
                        ebooking.deliverCity = "";
                        ebooking.deliverPostalCode = "";
                    }
                }
                if (ebooking.populatePickup) {
                    if (ebooking.duplicate) {
                        ebooking.pickupCity = ebooking.duplicateFields.PICKCITY ? ebooking.duplicateFields.PICKCITY : "";
                        ebooking.pickupCountry = ebooking.duplicateFields.PICKCOUNTRY ? ebooking.duplicateFields.PICKCOUNTRY : "";
                        ebooking.pickupPostalCode = ebooking.duplicateFields.PICKPOSTALCODE ? ebooking.duplicateFields.PICKPOSTALCODE : "";
                    } else {
                        ebooking.pickupCountry = "";
                        ebooking.pickupCity = "";
                        ebooking.pickupPostalCode = "";
                    }
                }

                ebooking.populateImA = false;
                ebooking.populateConsignor = false;
                ebooking.populateConsignee = false;
                ebooking.populateDelivery = false;
                ebooking.populatePickup = false;
                this.props.ebookingActions.ebookingUpdateData(ebooking).then(responseData => { });
                this.setState({ errorText: "You changed data, so please click to \"Continue\" button again. " });
            }
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        const { history, auth } = this.props;
        let validateReturn = await this.isValidation();
        // console.log('validateReturn', validateReturn);
        if (!validateReturn) {
            window.scrollToFirstError();
            return false;
        }

        this.setState({ showProceeding: false });

        if (auth.state != 'LOGGED_IN' && auth.state != 'NEW_PASSWORD_REQUIRED' && auth.state != 'EMAIL_VERIFICATION_REQUIRED') {
            window.parent.postMessage('show-login-form', '*');
            this.setState({ autoSubmit: true });
            if (this.timerID) {
                clearInterval(this.timerID);
            }
            this.timerID = setInterval(
                () => this.checkLogin(),
                1000
            );

            return false;
        }
        var { ebooking } = this.props;
        //Send data to API
        this.setPriceQuotation();
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED') && !auth.expiredToken && !window.checkHasModalShowing()) {
            // window.parent.postMessage('show-login-form', '*');
            history.push("/login?back=" + base64.encode("/ebooking"));
        } else if ((authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED') && auth.expiredToken && (window.checkModalIsShowing("modalLogin") || window.checkModalIsShowing("modalRegister")) && !this.state.showProceeding) {
            window.hideModals("modalLogin");
            window.hideModals("modalRegister");
        }
    }

    checkLogin = () => {
        const { auth } = this.props;
        var authState = auth.state;
        // console.log("checkLogin",this.state.submiting, stateLoginChanged);
        if (((authState === 'LOGGED_IN' || authState === 'NEW_PASSWORD_REQUIRED' || authState === 'EMAIL_VERIFICATION_REQUIRED')) && !auth.expiredToken && !this.state.submiting && !stateLoginChanged) {
            //Send data to API            
            this.setPriceQuotation();
        } else {
            this.setState({ submiting: false });
        }
    }

    setPriceQuotation = () => {
        this.setState({ autoSubmit: false });
        if (this.timerID) {
            clearInterval(this.timerID);
        }
        const { history } = this.props;
        var { ebooking, errorText } = this.state;

        errorText = "";
        this.setState({ errorText });

        var HandlingInstrunctionCodes = [];
        ebooking.instructions.forEach(ins => {
            HandlingInstrunctionCodes.push(ins.value);
        });
        var SaleTypeCode = "";
        if (ebooking.deliver_from == "Airport") {
            if (ebooking.deliver_to == "Airport") {
                SaleTypeCode = "PTP";
            } else {
                SaleTypeCode = "PTD";
            }
        } else {
            if (ebooking.deliver_to == "Airport") {
                SaleTypeCode = "DTP";
            } else {
                SaleTypeCode = "DTD";
            }
        }
        var Remarks = "";
        if (ebooking.shipment_dangerous_goods || ebooking.shipment_lithium_batteries) {
            Remarks = "This is DG shipment ";
            Remarks += "Contain: ";
            ebooking.class.forEach(a => {
                Remarks += a + ", ";
            });
            Remarks += "UN\'s: " + ebooking.un;
            Remarks += "\nAdditional Information: " + ebooking.additional_information;
        } else if (ebooking.shipment_unstackable) {
            Remarks = "Additional Information: " + ebooking.additional_information;
        }

        var Dimensions = [];
        // var Dimensions = ebooking.units_list;

        if (ebooking.dimemtions_by !== "ULD") {
            for (let i = 0; i < ebooking.units_list.length; i++) {
                var di = { ...ebooking.units_list[i] };
                di.Pcs_real = 0;
                delete di.paltype;
                Dimensions.push(di);
            }
        } else {
            for (let i = 0; i < ebooking.units_list.length; i++) {
                var di = { ...ebooking.units_list[i] };
                di.Pcs_real = di.Pcs;
                di.Pcs = 0;
                Dimensions.push(di);
            }
        }

        var sendEbooking = {
            "ETDOrigin": ebooking.pickup_dropoff_date ? ebooking.pickup_dropoff_date.format("DD/MM/YY") : "",
            "SaleTypeCode": ebooking.t1_shipment == "T1" ? "T1" : SaleTypeCode,
            "MustRide": ebooking.pickup_dropoff_xps ? "Y" : null,
            "CaoPax": ebooking.shipment_dangerous_goods ? ebooking.shipment_dangerous_goods_type : "",
            "Overlap": ((ebooking.overlap_shipment == "Overlap" || ebooking.overlap_shipment_check) && ebooking.dimemtions_by !== "ULD") ? "Y" : null,
            "Litium": ebooking.shipment_lithium_batteries ? "Y" : null,
            "Unstackable": ebooking.shipment_unstackable ? "Y" : null,
            "T1": ebooking.t1_shipment == "T1" ? "Y" : null,
            "FromPortCode": ebooking.deliver_from_airport,
            "ToPortCode": ebooking.deliver_to_airport,
            "CNEE": ebooking.company_name_of_consignee,
            "CNOR": ebooking.company_name_of_consignor,
            "FromZipCode": ebooking.deliver_from_zipcode,
            "ToZipCode": ebooking.deliver_to_zipcode,
            "FROMCITY": ebooking.deliver_from_city,
            "TOCITY": ebooking.deliver_to_city,
            "FromCountryCode": ebooking.deliver_from_country,
            "ToCountryCode": ebooking.deliver_to_country,
            "AWB": ebooking.already_awb ? ebooking.awb_number_1 + "" + ebooking.awb_number_2 : "",
            "Commodity": ebooking.commodity_type,
            "SubCommodity": ebooking.sub_commodity,
            "Weight": parseFloat(ebooking.units_total_weight),
            "Volume": parseFloat(ebooking.units_total_volume),
            "Pieces": parseInt(ebooking.units_total_num_of_pieces),
            "Remarks": Remarks,
            "HandlingInstrunctionCodes": HandlingInstrunctionCodes,
            "Dimensions": Dimensions
        };

        ebooking.PricelistChoosed = { selectedPL: -1, selectedPLData: {}, selectedFlight: "", selectedFlightData: {} };
        ebooking.DefaultChoosed = { flightID: "", priceType: "", price: 0, flight: { flights: [], Parts: [] } };
        ebooking.ParcelChoosed = {};
        this.props.ebookingActions.ebookingChangeData({ fieldName: "PricelistChoosed", fieldValue: ebooking.PricelistChoosed }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "DefaultChoosed", fieldValue: ebooking.DefaultChoosed }).then(responseData => { });
        this.props.ebookingActions.ebookingChangeData({ fieldName: "ParcelChoosed", fieldValue: ebooking.ParcelChoosed }).then(responseData => { });
        this.setState({ ebooking, btnText: "Submiting...", submiting: true, showProceeding: false });
        // history.push('/ebooking/participants');
        this.props.loadingActions.loadingChangeData(true);
        var jwtToken = this.props.auth.jwtToken;
        this.props.ebookingActions.submitStepOne(sendEbooking, jwtToken).then(response => {
            this.setState({ submiting: false });
            this.props.loadingActions.loadingChangeData(false);
            if (this.timerID) {
                clearInterval(this.timerID);
            }
            if (response.success && response.data.Success) {
                window.jumpToTop();

                if (ebooking.populateImA) {
                    ebooking.receivingCompanyName = "";
                    ebooking.receivingIATA1 = "";
                    ebooking.receivingIATA2 = "";
                    ebooking.shippingCompanyName = "";
                    ebooking.shippingIATA1 = "";
                    ebooking.shippingIATA2 = "";
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingCompanyName", fieldValue: ebooking.receivingCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingIATA1", fieldValue: ebooking.receivingIATA1 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "receivingIATA2", fieldValue: ebooking.receivingIATA2 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingCompanyName", fieldValue: ebooking.shippingCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingIATA1", fieldValue: ebooking.shippingIATA1 }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "shippingIATA2", fieldValue: ebooking.shippingIATA2 }).then(responseData => { });
                }
                if (ebooking.populateConsignor) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consignorCompanyName = ebooking.duplicateFields.CNORNAME ? ebooking.duplicateFields.CNORNAME : "";
                        ebooking.consignorAddress = ebooking.duplicateFields.CNORADDRESS ? ebooking.duplicateFields.CNORADDRESS : "";
                        ebooking.consignorCity = ebooking.duplicateFields.CNORCITY ? ebooking.duplicateFields.CNORCITY : "";
                        ebooking.consignorCountry = ebooking.duplicateFields.CNORCOUNTRY ? ebooking.duplicateFields.CNORCOUNTRY : "";
                        ebooking.consignorPostalCode = ebooking.duplicateFields.CNORPOSTALCODE ? ebooking.duplicateFields.CNORPOSTALCODE : "";
                    } else {
                        ebooking.consignorCompanyName = "";
                        ebooking.consignorAddress = "";
                        ebooking.consignorCity = "";
                        ebooking.consignorCountry = "";
                        ebooking.consignorPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCompanyName", fieldValue: ebooking.consignorCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorAddress", fieldValue: ebooking.consignorAddress }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCity", fieldValue: ebooking.consignorCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorCountry", fieldValue: ebooking.consignorCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consignorPostalCode", fieldValue: ebooking.consignorPostalCode }).then(responseData => { });
                }
                if (ebooking.populateConsignee) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.consigneeAddress = ebooking.duplicateFields.CNEEADDRESS ? ebooking.duplicateFields.CNEEADDRESS : "";
                        ebooking.consigneeCity = ebooking.duplicateFields.CNEECITY ? ebooking.duplicateFields.CNEECITY : "";
                        ebooking.consigneeCompanyName = ebooking.duplicateFields.CNEENAME ? ebooking.duplicateFields.CNEENAME : "";
                        ebooking.consigneeCountry = ebooking.duplicateFields.CNEECOUNTRY ? ebooking.duplicateFields.CNEECOUNTRY : "";
                        ebooking.consigneePostalCode = ebooking.duplicateFields.CNEEPOSTALCODE ? ebooking.duplicateFields.CNEEPOSTALCODE : "";
                    } else {
                        ebooking.consigneeCompanyName = "";
                        ebooking.consigneeAddress = "";
                        ebooking.consigneeCity = "";
                        ebooking.consigneeCountry = "";
                        ebooking.consigneePostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCompanyName", fieldValue: ebooking.consigneeCompanyName }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeAddress", fieldValue: ebooking.consigneeAddress }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCity", fieldValue: ebooking.consigneeCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneeCountry", fieldValue: ebooking.consigneeCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "consigneePostalCode", fieldValue: ebooking.consigneePostalCode }).then(responseData => { });
                }

                if (ebooking.populateDelivery) {
                    if (ebooking.duplicate) {
                        // ebooking.duplicateFields
                        ebooking.deliverCity = ebooking.duplicateFields.DELCITY ? ebooking.duplicateFields.DELCITY : "";
                        ebooking.deliverCountry = ebooking.duplicateFields.DELCOUNTRY ? ebooking.duplicateFields.DELCOUNTRY : "";
                        ebooking.deliverPostalCode = ebooking.duplicateFields.DELPOSTALCODE ? ebooking.duplicateFields.DELPOSTALCODE : "";
                    } else {
                        ebooking.deliverCountry = "";
                        ebooking.deliverCity = "";
                        ebooking.deliverPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCountry", fieldValue: ebooking.deliverCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverCity", fieldValue: ebooking.deliverCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "deliverPostalCode", fieldValue: ebooking.deliverPostalCode }).then(responseData => { });
                }

                if (ebooking.populatePickup) {
                    if (ebooking.duplicate) {
                        ebooking.pickupCity = ebooking.duplicateFields.PICKCITY ? ebooking.duplicateFields.PICKCITY : "";
                        ebooking.pickupCountry = ebooking.duplicateFields.PICKCOUNTRY ? ebooking.duplicateFields.PICKCOUNTRY : "";
                        ebooking.pickupPostalCode = ebooking.duplicateFields.PICKPOSTALCODE ? ebooking.duplicateFields.PICKPOSTALCODE : "";
                    } else {
                        ebooking.pickupCountry = "";
                        ebooking.pickupCity = "";
                        ebooking.pickupPostalCode = "";
                    }

                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCountry", fieldValue: ebooking.pickupCountry }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupCity", fieldValue: ebooking.pickupCity }).then(responseData => { });
                    this.props.ebookingActions.ebookingChangeData({ fieldName: "pickupPostalCode", fieldValue: ebooking.pickupPostalCode }).then(responseData => { });
                }

                ebooking.populateImA = false;
                ebooking.populateConsignor = false;
                ebooking.populateConsignee = false;
                ebooking.populateDelivery = false;
                ebooking.populatePickup = false;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateImA", fieldValue: ebooking.pickupCopopulateImAuntry }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignor", fieldValue: ebooking.populateConsignor }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateConsignee", fieldValue: ebooking.populateConsignee }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populateDelivery", fieldValue: ebooking.populateDelivery }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "populatePickup", fieldValue: ebooking.populatePickup }).then(responseData => { });

                ebooking.page_df = 1;
                ebooking.page_pl = 1;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "page_df", fieldValue: ebooking.page_df }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "page_pl", fieldValue: ebooking.page_pl }).then(responseData => { });
                ebooking.clearConsignee = false;
                ebooking.clearConsignor = false;
                this.props.ebookingActions.ebookingChangeData({ fieldName: "clearConsignee", fieldValue: ebooking.clearConsignee }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "clearConsignor", fieldValue: ebooking.clearConsignor }).then(responseData => { });
                ebooking.PriceQuotation = response.data;
                ebooking.passedData = response.passedData;
                this.setState({ ebooking, btnText: "View Quote" });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "PriceQuotation", fieldValue: response.data }).then(responseData => { });
                this.props.ebookingActions.ebookingChangeData({ fieldName: "passedData", fieldValue: ebooking.passedData }).then(responseData => { });
                switch (response.data.type) {
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;

                    case "WHITE":
                        if (ebooking.PriceQuotation.pl.length > 0) {
                            history.push('/ebooking/flight-pricing-pricelist');
                        } else {
                            history.push('/ebooking/flight-pricing-default');
                        }
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;

                    default:
                        history.push('/ebooking/flight-pricing-ad-hoc');
                        break;
                }
            } else if (!response.success && response.relogin) {
                this.props.authActions.setExpiredToken(true);
                this.setState({ showProceeding: true, btnText: "View Quote", autoSubmit: true });
                stateLoginChanged = true;
                this.timerID = setInterval(
                    () => this.checkLogin(),
                    1000
                );
                window.jumpToTop();
            } else {
                errorText = "showContactLink";
                this.setState({ errorText, btnText: "View Quote", autoSubmit: false });
                window.jumpToTop();
            }
        });
    }


    renderHours() {
        const hours = [];
        for (var i = 0; i < 24; i++) {
            var h = i;
            if (h < 10) {
                h = "0" + h;
            } else {
                h = "" + h;
            }
            hours.push(<option key={h} value={h}>{h}</option>);
        }
        return hours;
    }

    renderMinutes() {
        const minites = [];
        for (var i = 0; i < 60; i = i + 5) {
            var m = i;
            if (m < 10) {
                m = "0" + m;
            } else {
                m = "" + m;
            }
            minites.push(<option key={m} value={m}>{m}</option>);
        }
        return minites;
    }

    addDays(dirtyAmount) {
        // var dateReturn = moment().add(dirtyAmount, 'days');
        var dateReturn = moment().add(dirtyAmount, 'months');
        return dateReturn;
    }

    buildShipmentSteps = () => {
        var { ebooking } = this.props;
        var { selectedCountryDeliverFrom } = this.state;
        var fromCalAirport = false;
        var toCalAirport = false;
        if (ebooking.deliver_from == "Airport" && ebooking.deliver_from_airport_data.flightport == "Y") {
            fromCalAirport = true;
        }
        if (ebooking.deliver_to == "Airport" && ebooking.deliver_to_airport_data.flightport == "Y") {
            toCalAirport = true;
        }
        if (fromCalAirport && toCalAirport) {
            return (
                <div className="deliver-detail steps-2">
                    <div className="deliver-detail-step">
                        <span className="above-text">{moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do') : ""}</span>
                        <span className="under-text">{ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}</span>
                        <span className="step-icon plain"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step last">
                        {ebooking.deliver_to == "Airport" ? (ebooking.deliver_to_airport ? "Airport " + ebooking.deliver_to_airport : "") : (ebooking.deliver_to_zipcode ? "Zip " + ebooking.deliver_to_zipcode : "")}
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else if (!fromCalAirport && toCalAirport) {
            return (
                <div className="deliver-detail steps-3">
                    <div className="deliver-detail-step">
                        <span className="above-text">{moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do') : ""}</span>
                        <span className="under-text">{ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}</span>
                        <span className="step-icon car"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            Via truck to airport<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step">
                        <span className="step-icon plain"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step last">
                        {ebooking.deliver_to == "Airport" ? (ebooking.deliver_to_airport ? "Airport " + ebooking.deliver_to_airport : "") : (ebooking.deliver_to_zipcode ? "Zip " + ebooking.deliver_to_zipcode : "")}
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else if (fromCalAirport && !toCalAirport) {
            return (
                <div className="deliver-detail steps-3">
                    <div className="deliver-detail-step">
                        <span className="above-text">{moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do') : ""}</span>
                        <span className="under-text">{ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}</span>
                        <span className="step-icon plain"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step">
                        <span className="step-icon car"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step last">
                        {ebooking.deliver_from == "Airport" ? (ebooking.deliver_to_airport ? "Airport " + ebooking.deliver_to_airport : "") : (ebooking.deliver_to_zipcode ? "Zip " + ebooking.deliver_to_zipcode : "")}
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="clb"></div>
                </div>
            );
        } else {
            return (
                <div className="deliver-detail">
                    <div className="deliver-detail-step">
                        <span className="above-text">{moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do') : ""}</span>
                        <span className="under-text">{ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}</span>
                        <span className="step-icon car"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step">
                        <span className="step-icon plain"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step">
                        <span className="step-icon car"></span>
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="deliver-detail-step last">
                        {ebooking.deliver_to == "Airport" ? (ebooking.deliver_to_airport ? "Airport " + ebooking.deliver_to_airport : "") : (ebooking.deliver_to_zipcode ? "Zip " + ebooking.deliver_to_zipcode : "")}
                        {/* <div className="tool-tip">
                            <span>{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} from:</span>
                            {ebooking.deliver_from == "Airport" ? "" : (ebooking.deliver_from_city ? ebooking.deliver_from_city + ' ' : "")} {selectedCountryDeliverFrom ? selectedCountryDeliverFrom.label : ""}<br />
                            {ebooking.deliver_from == "Airport" ? (ebooking.deliver_from_airport ? "Airport " + ebooking.deliver_from_airport : "") : (ebooking.deliver_from_zipcode ? "Zip " + ebooking.deliver_from_zipcode : "")}<br />
                            {moment.isMoment(ebooking.pickup_dropoff_date) ? ebooking.pickup_dropoff_date.format('MMMM Do, YYYY') : ""}
                        </div> */}
                    </div>
                    <div className="clb"></div>
                </div>
            );
        }
    }
    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    render() {
        var { ebooking, breadcrumbs, steps, isError, errors, errorMessage, errorText, showAddPiecesForm, validateETD, suggestionsCNEE, suggestionsCNOR, suggestionsZipFrom, suggestionsZipTo }
            = this.state;

        // Autosuggest will pass through all these props to the input.
        var inputPropsCNEE = {
            placeholder: 'Type',
            value: ebooking.company_name_of_consignee ? ebooking.company_name_of_consignee : "",
            onChange: this.onChangeSugestCNEE
        };
        var inputPropsCNOR = {
            placeholder: 'Type',
            value: ebooking.company_name_of_consignor ? ebooking.company_name_of_consignor : "",
            onChange: this.onChangeSugestCNOR
        };

        var inputPropsZipFrom = {
            placeholder: '',
            value: ebooking.deliver_from_zipcode ? ebooking.deliver_from_zipcode : "",
            onChange: this.onChangeSugestZipFrom
        };

        var inputPropsZipTo = {
            placeholder: '',
            value: ebooking.deliver_to_zipcode ? ebooking.deliver_to_zipcode : "",
            onChange: this.onChangeSugestZipTo
        };
        return (
            <div className="ebooking">
                <StepProgress stepData={steps} progress={25} onClick={this.handClickStep} />

                <div className="container">
                    <h1 className="title mt-5">Step one: Getting a Quote</h1>
                    <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                    <div className={`form-group mt-3 ${(errorText != "" && errorText != "showContactLink") ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            {errorText}
                        </div>
                    </div>

                    <div className={`form-group mt-3 ${this.state.showProceeding ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                        </div>
                    </div>
                    <div className={`form-group mt-3 ${errorText == "showContactLink" ? '' : 'd-none'}`}>
                        <div className="alert alert-danger" role="alert">
                            An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                        </div>
                    </div>
                </div>
                <form>
                    <div className="container block-form  mb-4">
                        <h1 className="formTitle mb-3">Shipment Details</h1>
                        <div className="form-content">

                            <div className="form-group form-radio form-rows">
                                <div className="shippment-type">
                                    <label className="radio-container">PTP
                                        <input type="radio" name="t1_shipment" value="PTP" checked={ebooking.t1_shipment === "PTP"} onChange={this.handleChange} />
                                        <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                    </label>
                                    <label className="radio-container">PTD
                                        <input type="radio" name="t1_shipment" value="PTD" checked={ebooking.t1_shipment === "PTD"} onChange={this.handleChange} />
                                        <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                    </label>
                                    <label className="radio-container">DTD
                                        <input type="radio" name="t1_shipment" value="DTD" checked={ebooking.t1_shipment === "DTD"} onChange={this.handleChange} />
                                        <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                    </label>
                                    <label className="radio-container">DTP
                                        <input type="radio" name="t1_shipment" value="DTP" checked={ebooking.t1_shipment === "DTP"} onChange={this.handleChange} />
                                        <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                    </label>
                                    <label className="radio-container">T1
                                        <input type="radio" name="t1_shipment" value="T1" checked={ebooking.t1_shipment === "T1"} onChange={this.handleChange} />
                                        <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                    </label>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blue-bg-small mt-5">
                        <div className="container block-form">
                            <div className="form-content">
                                <div className="form-rows ebooking-deliver">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="div-before-form-element bg-blue deliver-box">
                                                <div className="row top-row">
                                                    <div className="col-12 delever-title">
                                                        <span>Deliver From</span>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-group max80 form-radio form-rows">
                                                            <label className="radio-container">By Airport
                                                                <input type="radio" name="deliver_from" value="Airport" checked={ebooking.deliver_from == "Airport"} disabled={ebooking.t1_shipment != "T1" ? true : false} onChange={this.handleChange} />
                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                            </label>
                                                            <label className="radio-container">By Zip Code
                                                                <input type="radio" name="deliver_from" value="ZipCode" checked={ebooking.deliver_from == "ZipCode"} disabled={ebooking.t1_shipment != "T1" ? true : false} onChange={this.handleChange} />
                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                            </label>
                                                            <div className="clb"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_from_country">Country</label>
                                                                    <Select
                                                                        placeholder="Choose Country"
                                                                        inputId="deliver_from_country"
                                                                        className={`select-box huge-input ${(errors.deliver_from_country) ? 'input-error' : ''}`}
                                                                        value={this.state.selectedCountryDeliverFrom}
                                                                        isSearchable={true}
                                                                        isDisabled={false}
                                                                        onChange={this.handleChangeCountryFrom}
                                                                        options={ebooking.deliver_from == "ZipCode" ? this.state.countriesZip : this.state.countries}
                                                                        isLoading={this.state.isLoadingCountry}
                                                                        onBlur={this.handleOnBlurSelect}
                                                                        isOptionDisabled={(option) => { return (option.airportonly && ebooking.deliver_from == "ZipCode") ? true : false }}
                                                                        inputValue={this.state.inputValueCountryFrom}
                                                                        onInputChange={this.handlerSelectFromTyping}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_from_country} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_from == 'Airport') ? '' : 'd-none'}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_from_airport">Airport</label>
                                                                    <Select
                                                                        placeholder="Choose Airport"
                                                                        className={`select-box huge-input ${(errors.deliver_from_airport) ? 'input-error' : ''}`}
                                                                        value={this.state.selectedAirportDeliverFrom}
                                                                        inputId="deliver_from_airport"
                                                                        isSearchable={true}
                                                                        isDisabled={false}
                                                                        onChange={this.handleChangeAirportFrom}
                                                                        options={this.state.airportsDeliverFrom}
                                                                        isLoading={this.state.isLoadingAirportFrom}
                                                                        onBlur={this.handleOnBlurSelect}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_from_airport} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_from == 'Airport') ? 'd-none' : ''}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_from_city">City</label>
                                                                    <input type="text" className={`form-control ${(errors.deliver_from_city) ? 'input-error' : ''}`} id="deliver_from_city" name="deliver_from_city" value={ebooking.deliver_from_city ? ebooking.deliver_from_city : ""} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                                    <EbookingInputError error={errors.deliver_from_city} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_from == 'Airport') ? 'd-none' : ''}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_from_zipcode">Zip Code</label>
                                                                    {/* <input type="text" className={`form-control ${(errors.deliver_from_zipcode) ? 'input-error' : ''}`} id="deliver_from_zipcode" name="deliver_from_zipcode" value={ebooking.deliver_from_zipcode ? ebooking.deliver_from_zipcode : ""} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                                    <Autosuggest
                                                                        className={`form-control ${(errors.deliver_from_zipcode) ? 'input-error' : ''}`}
                                                                        suggestions={suggestionsZipFrom}
                                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedZipFrom}
                                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedZipFrom}
                                                                        getSuggestionValue={this.getSuggestionValueZip}
                                                                        renderSuggestion={this.renderSuggestionZip}
                                                                        inputProps={inputPropsZipFrom}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_from_zipcode} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="div-before-form-element bg-blue deliver-box">
                                                <div className="row top-row">
                                                    <div className="col-12 delever-title">
                                                        <span>{this.state.pickup_dropoff_text}</span>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <div className="text-center">
                                                                        <small className={`note-text ${ebooking.deliver_from == "Airport" ? "" : "d-none"}`}>The latest date and time you will drop off the cargo (Local Time)</small>
                                                                        <small className={`note-text ${ebooking.deliver_from == "Airport" ? "d-none" : ""}`}>The Date and Time that Cargo will be ready for Pickup (Local Time)</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="pickup_dropoff_date">{ebooking.deliver_from == "Airport" ? "Dropoff" : "Pickup"} Date</label>
                                                                    <DatePicker
                                                                        ref={(c) => this._calendar = c}
                                                                        name="pickup_dropoff_date"
                                                                        id="pickup_dropoff_date"
                                                                        className={`form-control ${(errors.pickup_dropoff_date || errors.pickup_dropoff_date_max) ? 'input-error' : ''}`}
                                                                        key="pickup_dropoff_date"
                                                                        selected={ebooking.pickup_dropoff_date ? ebooking.pickup_dropoff_date : null}
                                                                        isClearable={true}
                                                                        minDate={validateETD ? moment() : null}
                                                                        maxDate={validateETD ? this.addDays(1) : null}
                                                                        // maxDate={this.addDays(ebooking.deliver_from == "Airport" ? 21 : 22)}                                                                        
                                                                        onChange={this.handleChangeDate}
                                                                        onBlur={this.handleOnBlurDate}
                                                                        disabledKeyboardNavigation
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        peekNextMonth
                                                                        dateFormat="DD/MM/YYYY"
                                                                        placeholderText="DD/MM/YYYY"
                                                                        onClickOutside={this.onClickOutside}
                                                                    />
                                                                    {/* <small className="note-text">disclaimer about RPD</small> */}
                                                                    <EbookingInputError error={errors.pickup_dropoff_date} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="unit_volume">Time</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <select className={`form-control inline-elements ${errors.pickup_dropoff_date_max ? 'input-error' : ''}`} style={{ width: '50px' }} name="pickup_dropoff_time_hour" value={ebooking.pickup_dropoff_time_hour} onChange={this.handleChange} >
                                                                        {
                                                                            this.renderHours()
                                                                        }
                                                                    </select>
                                                                    <label className={`inline-elements`}>:</label>
                                                                    <select className={`form-control inline-elements ${errors.pickup_dropoff_date_max ? 'input-error' : ''}`} style={{ width: '50px' }} name="pickup_dropoff_time_minute" value={ebooking.pickup_dropoff_time_minute} onChange={this.handleChange} >
                                                                        {
                                                                            this.renderMinutes()
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-12">
                                                                    <InputError error={errors.pickup_dropoff_date_max} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row top-row d-none">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <div>
                                                                        <label className="checkbox-container pdl-20 have-small">Must Ride - XPS
                                                                            <input type="checkbox" name="pickup_dropoff_xps" value="pickup_dropoff_xps" checked={ebooking.pickup_dropoff_xps ? ebooking.pickup_dropoff_xps : false} onChange={this.handleChange} />
                                                                            <span className={`checkmark-checkbox ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                                                                        </label>
                                                                        <div className="clb"></div>
                                                                    </div>
                                                                    <div style={{ lineHeight: '11px' }}>
                                                                        {/* <small className="note-text">*explanation about must Ride & add costs</small> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row mt-2 mb-2">
                                                                <div className="form-group max80 col-12 text-center">
                                                                    <a href="https://www.cal-cargo.com/e-services/flights-schedule/" target="_blank" className="link_flight_scedule">View Flight Schedule</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="div-before-form-element bg-blue deliver-box">
                                                <div className="row top-row">
                                                    <div className="col-12 delever-title">
                                                        <span>Deliver To</span>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-group max80 form-radio form-rows">
                                                            <label className="radio-container">By Airport
                                                                <input type="radio" name="deliver_to" value="Airport" checked={ebooking.deliver_to == "Airport"} disabled={ebooking.t1_shipment != "T1" ? true : false} onChange={this.handleChange} />
                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                            </label>
                                                            <label className="radio-container">By Zip Code
                                                                <input type="radio" name="deliver_to" value="ZipCode" checked={ebooking.deliver_to == "ZipCode"} disabled={ebooking.t1_shipment != "T1" ? true : false} onChange={this.handleChange} />
                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                            </label>
                                                            <div className="clb"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row top-row">
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_to_country">Country</label>
                                                                    <Select
                                                                        placeholder="Choose Country"
                                                                        inputId="deliver_to_country"
                                                                        className={`select-box huge-input ${(errors.deliver_to_country) ? 'input-error' : ''}`}
                                                                        value={this.state.selectedCountryDeliverTo}
                                                                        isSearchable={true}
                                                                        isDisabled={false}
                                                                        onChange={this.handleChangeCountryTo}
                                                                        options={ebooking.deliver_to == "ZipCode" ? this.state.countriesZip : this.state.countries}
                                                                        isLoading={this.state.isLoadingCountry}
                                                                        onBlur={this.handleOnBlurSelect}
                                                                        isOptionDisabled={(option) => { return (option.airportonly && ebooking.deliver_to == "ZipCode") ? true : false }}
                                                                        inputValue={this.state.inputValueCountryTo}
                                                                        onInputChange={this.handlerSelectToTyping}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_to_country} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_to == 'Airport') ? '' : 'd-none'}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_to_airport">Airport</label>
                                                                    <Select
                                                                        placeholder="Choose Airport"
                                                                        inputId="deliver_to_airport"
                                                                        className={`select-box huge-input ${(errors.deliver_to_airport) ? 'input-error' : ''}`}
                                                                        value={this.state.selectedAirportDeliverTo}
                                                                        isSearchable={true}
                                                                        isDisabled={false}
                                                                        onChange={this.handleChangeAirportTo}
                                                                        options={this.state.airportsDeliverTo}
                                                                        isLoading={this.state.isLoadingAirportTo}
                                                                        onBlur={this.handleOnBlurSelect}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_to_airport} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_to == 'Airport') ? 'd-none' : ''}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_to_city">City</label>
                                                                    <input type="text" className={`form-control ${(errors.deliver_to_city) ? 'input-error' : ''}`} id="deliver_to_city" name="deliver_to_city" value={ebooking.deliver_to_city ? ebooking.deliver_to_city : ""} onBlur={this.handleOnBlur} onChange={this.handleChange} maxLength={30} />
                                                                    <EbookingInputError error={errors.deliver_to_city} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row top-row ${(ebooking.deliver_to == 'Airport') ? 'd-none' : ''}`}>
                                                    <div className="col-12">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="form-group max80 col-12">
                                                                    <label htmlFor="deliver_to_zipcode">Zip Code</label>
                                                                    {/* <input type="text" className={`form-control ${(errors.deliver_to_zipcode) ? 'input-error' : ''}`} id="deliver_to_zipcode" name="deliver_to_zipcode" value={ebooking.deliver_to_zipcode ? ebooking.deliver_to_zipcode : ""} onBlur={this.handleOnBlur} onChange={this.handleChange} /> */}
                                                                    <Autosuggest
                                                                        className={`form-control ${(errors.deliver_to_zipcode) ? 'input-error' : ''}`}
                                                                        suggestions={suggestionsZipTo}
                                                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedZipTo}
                                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedZipTo}
                                                                        getSuggestionValue={this.getSuggestionValueZip}
                                                                        renderSuggestion={this.renderSuggestionZip}
                                                                        inputProps={inputPropsZipTo}
                                                                    />
                                                                    <EbookingInputError error={errors.deliver_to_zipcode} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container block-form mt-5">
                        <div className="form-content">
                            {this.buildShipmentSteps()}
                            <div className="clb"></div>
                        </div>

                    </div>
                    <div className="container block-form mt-5">
                        <h2 className="formTitle mb-3">Commodity Details</h2>
                        <div className="form-description">What type of commodity are you sending?</div>
                        <div className="form-content-description">
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="div-before-form-element">
                                            <div className="row">
                                                <div className="form-group max80 col-12">
                                                    <label htmlFor="commodity_type">Commodity Type </label>
                                                    <Select
                                                        inputId="commodity_type"
                                                        className={`select-box huge-input ${(errors.commodity_type) ? 'input-error' : ''}`}
                                                        value={this.state.selectedCommodity}
                                                        isSearchable={true}
                                                        isDisabled={false}
                                                        onChange={this.handleChangeCommodity}
                                                        options={this.state.listCommodity}
                                                        isLoading={this.state.isLoadingCommodity}
                                                        onBlur={this.handleOnBlurSelect}
                                                    />
                                                    <EbookingInputError error={errors.commodity_type} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="div-before-form-element">
                                            <div className="row">
                                                <div className="form-group max80 col-12">
                                                    <label htmlFor="sub_commodity">Sub Commodity </label>
                                                    <Select
                                                        inputId="sub_commodity"
                                                        className={`select-box huge-input ${(errors.sub_commodity) ? 'input-error' : ''}`}
                                                        value={this.state.selectedSubCommodity}
                                                        isSearchable={true}
                                                        isDisabled={false}
                                                        onChange={this.handleChangeSubCommodity}
                                                        options={this.state.listSubCommodity}
                                                        isLoading={this.state.isLoadingSubCommodity}
                                                        onBlur={this.handleOnBlurSelect}
                                                    />
                                                    <EbookingInputError error={errors.sub_commodity} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blue-bg-small">
                        <div className="container block-form">
                            <div className="form-content-description mt-5">
                                <div className="form-rows mb-0">
                                    <div className="form-group form-radio form-group-dimemtions-by">
                                        <div className="shippment-type">
                                            <label className="radio-container no-padding">Dimensions by:</label>
                                            <label className="radio-container">Pieces
                                                <input type="radio" name="dimemtions_by" value="pieces" checked={ebooking.dimemtions_by === "pieces"} onChange={this.handleChange} />
                                                <span className={`checkmark ${(errors.dimemtions_by) ? 'input-error' : ''}`}></span>
                                            </label>
                                            <label className="radio-container">ULD
                                                <input type="radio" name="dimemtions_by" value="ULD" checked={ebooking.dimemtions_by === "ULD"} onChange={this.handleChange} />
                                                <span className={`checkmark ${(errors.dimemtions_by) ? 'input-error' : ''}`}></span>
                                            </label>
                                            <div className="clb"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-content">
                                <div className="form-rows ebooking-deliver">

                                    <div className="row">
                                        <div className="col-md-11 div_center">
                                            <div className="div-before-form-element bg-blue">
                                                <div className="row">
                                                    <div className="col-lg-7">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                        <div className="form-group col-12 form-radio form-rows">
                                                                            <label className="radio-container">Centimeters
                                                                                <input type="radio" name="units_type" value="Centimeters" checked={ebooking.units_type === "Centimeters" ? true : false} onChange={this.handleChange} />
                                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                                            </label>
                                                                            <label className="radio-container">Inches
                                                                                <input type="radio" name="units_type" value="Inches" checked={ebooking.units_type === "Inches" ? true : false} onChange={this.handleChange} />
                                                                                <span className={`checkmark ${(errors.t1_shipment) ? 'input-error' : ''}`}></span>
                                                                            </label>
                                                                            <div className="clb"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={`form-rows ${ebooking.units_list.length ? "" : ""}`}>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                        <div className="form-group col-12">
                                                                            <table className="table-list-pieces">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ "width": "70px" }} scope="col" className={`text-center ${ebooking.dimemtions_by === "ULD" ? "" : "d-none"}`}>ULD Type</th>
                                                                                        <th style={{ "width": "50px" }} scope="col" className="text-center">Pieces</th>
                                                                                        <th style={{ "width": ebooking.dimemtions_by === "ULD" ? "60px" : "70px" }} scope="col" className="text-center">Length</th>
                                                                                        <th style={{ "width": ebooking.dimemtions_by === "ULD" ? "60px" : "70px" }} scope="col" className="text-center">Width</th>
                                                                                        <th style={{ "width": ebooking.dimemtions_by === "ULD" ? "60px" : "70px" }} scope="col" className="text-center">Height</th>
                                                                                        <th scope="col" className="text-center">Volume (CBM)</th>
                                                                                        <th style={{ "width": (ebooking.units_list && ebooking.units_list.length) ? '30' : '0' + "px" }} scope="col" className="text-center last"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        ebooking.units_list.map((op, i) => {
                                                                                            return <tr key={`unit-${i}`} className="no-empty">
                                                                                                <td className={`${ebooking.dimemtions_by === "ULD" ? "" : "d-none"}`}><span className="">{this.state.ulds[op.paltype]}</span></td>
                                                                                                <td scope="row"><span>{op.Pcs}</span></td>
                                                                                                <td><span>{op.Length} {op.unitname.toLowerCase()}</span></td>
                                                                                                <td><span>{op.Width} {op.unitname.toLowerCase()}</span></td>
                                                                                                <td><span>{op.Height} {op.unitname.toLowerCase()}</span></td>
                                                                                                <td><span className="bg-grey">{op.Cbm} {op.unitname.toLowerCase()}³</span></td>
                                                                                                <td className="last"><span><button type="button" className="btn btnRemovePiece btn-block" onClick={this.removeAddedUnit.bind(this, i)}></button></span></td>
                                                                                            </tr>;
                                                                                        })
                                                                                    }
                                                                                    <tr>
                                                                                        <td className={`${ebooking.dimemtions_by === "ULD" ? "" : "d-none"}`}>
                                                                                            <span>
                                                                                                <select className={`form-control no-radius no-border text-center ${(errors.unit_uld_type) ? 'input-error' : ''}`} style={{ maxWidth: '70px' }} name="unit_uld_type" value={this.state.unit_uld_type} onChange={this.handleChangeUnit} onBlur={this.calculatorUnitTotal} >
                                                                                                    <option value=""></option>
                                                                                                    <option value="LOOSE">Loose</option>
                                                                                                    <option value="HD">HD</option>
                                                                                                    <option value="HDS">HD Skid</option>
                                                                                                    <option value="LD">LD</option>
                                                                                                    <option value="LDS">LD Skid</option>
                                                                                                    <option value="MD">MD</option>
                                                                                                    <option value="MDS">MD Skid</option>
                                                                                                </select>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={`${(ebooking.dimemtions_by === "ULD" && this.state.unit_uld_type === "") ? "d-none" : ""}`}><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_number_of_pieces) ? 'input-error' : ''}`} name="unit_number_of_pieces" id="unit_number_of_pieces" value={this.state.unit_number_of_pieces} onChange={this.handleChangeUnit} onBlur={this.calculatorUnitTotal} /></span>
                                                                                            <span className={`bg-grey ${(ebooking.dimemtions_by === "ULD" && this.state.unit_uld_type === "") ? "" : "d-none"}`}></span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={`${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "" : "d-none"}`}><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_length) ? 'input-error' : ''}`} id="unit_length" name="unit_length" maxLength={7} value={this.state.unit_length} onChange={this.handleChangeUnit} onBlur={this.calculatorUnitTotal} /></span>
                                                                                            <span className={`bg-grey ${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "d-none" : ""}`}>{this.state.unit_length}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={`${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "" : "d-none"}`}><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_width) ? 'input-error' : ''}`} id="unit_width" name="unit_width" maxLength={7} value={this.state.unit_width} onChange={this.handleChangeUnit} onBlur={this.calculatorUnitTotal} /></span>
                                                                                            <span className={`bg-grey ${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "d-none" : ""}`}>{this.state.unit_width}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className={`${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "" : "d-none"}`}><input type="text" style={{ "minHeight": "35px" }} className={`form-control no-radius no-border text-center ${(errors.unit_height) ? 'input-error' : ''}`} id="unit_height" name="unit_height" maxLength={7} value={this.state.unit_height} onChange={this.handleChangeUnit} onBlur={this.calculatorUnitTotal} /></span>
                                                                                            <span className={`bg-grey ${(ebooking.dimemtions_by !== "ULD" || (ebooking.dimemtions_by === "ULD" && (this.state.unit_uld_type === "LOOSE"))) ? "d-none" : ""}`}>{this.state.unit_height}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="bg-grey">{this.state.unit_volume} {(ebooking.units_type_short && this.state.unit_volume != "") ? ebooking.units_type_short.toLowerCase() + "³" : ""}</span>
                                                                                        </td>
                                                                                        <td className="last">
                                                                                            <span className="bg-orange"><button type="button" className="btn btn-ebooking-orange" onClick={this.addUnit}><i className="playAdd"></i></button></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`show-bottom-error ${errors.unit_rows ? "" : "hide"}`}>
                                                            <InputError error={errors.unit_rows} class="bg-red" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="row">
                                                                        <div className="form-group col-12 form-radio form-rows">
                                                                            <h2 className="totalTitle">Your Total</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-rows">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <table className="table_piece_total">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <label htmlFor="units_total_num_of_pieces">Total Number of Pieces</label>
                                                                                </td>
                                                                                <td style={{ "width": "70px" }}>
                                                                                    <input type="text" className={`form-control error-full-radius ${(errors.units_total_num_of_pieces) ? 'input-error' : ''}`} id="units_total_num_of_pieces" name="units_total_num_of_pieces" value={ebooking.units_total_num_of_pieces ? ebooking.units_total_num_of_pieces : ""} readOnly={ebooking.units_list && ebooking.units_list.length ? true : true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                                    {/* <InputError error={errors.units_total_num_of_pieces} /> */}
                                                                                </td>
                                                                                <td style={{ "width": "20px" }}>

                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <label htmlFor="units_total_volume">Total Volume</label>
                                                                                </td>
                                                                                <td style={{ "width": "70px" }}>
                                                                                    <input type="text" className={`form-control error-full-radius ${(errors.units_total_volume) ? 'input-error' : ''}`} id="units_total_volume" name="units_total_volume" value={ebooking.units_total_volume ? ebooking.units_total_volume : ""} readOnly={ebooking.units_list && ebooking.units_list.length ? true : true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                                    {/* <InputError error={errors.units_total_volume} /> */}
                                                                                </td>
                                                                                <td style={{ "width": "20px" }}>
                                                                                    <label className="un-label text-right">{ebooking.units_type_short ? ebooking.units_type_short.toLowerCase() : ""}³</label>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <label htmlFor="units_total_weight">Total Weight</label>
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className={`form-control error-full-radius ${(errors.units_total_weight) ? 'input-error' : ''}`} id="units_total_weight" name="units_total_weight" value={ebooking.units_total_weight >= 0 ? ebooking.units_total_weight : ""} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                                    {/* <InputError error={errors.units_total_weight} /> */}
                                                                                </td>
                                                                                <td style={{ "width": "20px" }}>
                                                                                    <label className="un-label text-right">kg</label>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <label htmlFor="units_total_chargable_weight">Total Chargeable Weight</label>
                                                                                </td>
                                                                                <td style={{ "width": "70px" }}>
                                                                                    <input type="text" className={`form-control error-full-radius ${(errors.units_total_chargable_weight) ? 'input-error' : ''}`} id="units_total_chargable_weight" name="units_total_chargable_weight" maxLength={10} value={ebooking.units_total_chargable_weight ? ebooking.units_total_chargable_weight : ""} readOnly={true} onChange={this.handleChange} onBlur={this.handleOnBlur} />
                                                                                    {/* <InputError error={errors.units_total_chargable_weight} /> */}
                                                                                </td>
                                                                                <td style={{ "width": "20px" }}>
                                                                                    <label className="un-label text-right">kg</label>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group pt-2">
                                                                        <label className={`checkbox-container pdl-20 ${(ebooking.overlap_shipment_check || ebooking.dimemtions_by === "ULD") ? 'disabled' : ''}`}>Overlap shipment
                                                                            <input type="checkbox" name="overlap_shipment" disabled={(ebooking.overlap_shipment_check || ebooking.dimemtions_by === "ULD") ? true : false} value="Overlap" checked={((ebooking.overlap_shipment == "Overlap" || ebooking.overlap_shipment_check) && ebooking.dimemtions_by !== "ULD") ? true : false} onChange={this.handleChange} />
                                                                            <span className={`checkmark-checkbox ${(errors.overlap_shipment) ? 'input-error' : ''}`}></span>
                                                                        </label>
                                                                        {/* <label className={`radio-container overlap pdl-20 ${(ebooking.overlap_shipment_check) ? 'disabled' : ''}`}>Overlap Shipment
                                                                            <input type="radio" name="overlap_shipment" value="Overlap" checked={(ebooking.overlap_shipment == "Overlap" || ebooking.overlap_shipment_check) ? true : false} disabled={(ebooking.overlap_shipment_check) ? true : false} onChange={this.handleChange} />
                                                                            <span className={`checkmark ${(errors.overlap_shipment) ? 'input-error' : ''}`}></span>
                                                                        </label>
                                                                        <label className={`radio-container overlap pdl-20 ml-4 ${(ebooking.overlap_shipment_check) ? 'disabled' : ''}`}>Non-overlap Shipment
                                                                            <input type="radio" name="overlap_shipment" value="NonOverlap" checked={(ebooking.overlap_shipment == "NonOverlap" && !ebooking.overlap_shipment_check) ? true : false} disabled={(ebooking.overlap_shipment_check) ? true : false} onChange={this.handleChange} />
                                                                            <span className={`checkmark ${(errors.overlap_shipment) ? 'input-error' : ''}`}></span>
                                                                        </label> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`show-bottom-error ${errors.overlap_shipment ? "" : "hide"}`}>
                                                            <InputError error={errors.overlap_shipment} class="bg-red" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container block-form mt-5">
                        <h2 className="formTitle mb-3">Dangerous Goods & Special Instructions</h2>
                        <div className="form-description">Mark any element below that might be relevant to your commodity.</div>
                        <div className="form-content-description dangerous_goods">
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u137_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20 ${ebooking.shipment_lithium_batteries ? "grey" : ""}`}>This shipment contains dangerous goods (DG)
                                                    <input type="checkbox" name="shipment_dangerous_goods" value="shipment_dangerous_goods" checked={ebooking.shipment_dangerous_goods ? ebooking.shipment_dangerous_goods : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_dangerous_goods) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`hidden_dangerous_goods_element ${ebooking.shipment_dangerous_goods ? "" : "hide"}`}>
                                <div className={`form-rows`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <div className="toggle-div">
                                                        <span className="mr-3 toggle-span">DG Type:</span>
                                                        <span className="toggle-span">CAO</span>
                                                        <label className="toggle-check">
                                                            <input type="checkbox" className="toggle-check-input" name="shipment_dangerous_goods_type" checked={ebooking.shipment_dangerous_goods_type == "CAO" ? false : true} onChange={this.handleChange} />
                                                            <span className="toggle-check-text"></span>
                                                            <div className="clb"></div>
                                                        </label>
                                                        <span className="toggle-span">PAX</span>
                                                        <div className="clb"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-rows`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <table className="table-instruction">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ "width": "50px" }}>
                                                                    <label>Class: </label>
                                                                </td>
                                                                <td>
                                                                    <select className={`form-control ${(errors.class) ? 'input-error' : ''}`} name="class" ref={ref => { this.class = ref }}>
                                                                        <option value="1. Explosives">1. Explosives</option>
                                                                        <option value="2. Gases">2. Gases</option>
                                                                        <option value="3. Flammable Liquids">3. Flammable Liquids</option>
                                                                        <option value="4. Flammable Solids">4. Flammable Solids</option>
                                                                        <option value="5. Oxidizing Substances">5. Oxidizing Substances</option>
                                                                        <option value="6. Toxic & Infectious Substances">6. Toxic & Infectious Substances</option>
                                                                        <option value="7. Radioactive Material">7. Radioactive Material</option>
                                                                        <option value="8. Corrosives">8. Corrosives</option>
                                                                        <option value="9. Miscellaneous Dangerous Goods">9. Miscellaneous Dangerous Goods</option>
                                                                    </select>
                                                                    <InputError error={errors.class} />
                                                                </td>
                                                                <td style={{ "width": "82px", "verticalAlign": "top" }}>
                                                                    <button type="button" className="btn btn-ebooking-orange" onClick={this.addClass}>Add <i className="playIcon"></i></button>
                                                                </td>
                                                            </tr>
                                                            {/* <tr className={`${errors.class ? "" : "hide"}`}>
                                                                <td colSpan={3}>
                                                                    <InputError error={errors.class} />
                                                                </td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-rows mt-4 ${ebooking.class.length ? "" : "hide"}`}>
                                    <div className="row">
                                        <div className="col-12" style={{ "padding": "0 66px", "maxWidth": "500px" }}>
                                            <div className="row">
                                                {
                                                    ebooking.class.map((op, i) => {
                                                        return <div className="col-12" key={i} value={op}>
                                                            <div className="instruction-list-item">
                                                                {op}
                                                                <button type="button" onClick={this.removeAddedClass.bind(this, i)} className="close-instruction"></button>
                                                            </div>
                                                        </div>;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-rows ${ebooking.class.length ? "" : "mt-3"}`}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <textarea className="form-control" placeholder="UN Numbers" style={{ width: "100%", height: "100px" }} id="un" name="un" onChange={this.handleChange}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u36_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20`}>This shipment contains lithium batteries
                                                    <input type="checkbox" name="shipment_lithium_batteries" value="shipment_lithium_batteries" checked={ebooking.shipment_lithium_batteries ? ebooking.shipment_lithium_batteries : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_lithium_batteries) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="box">
                                            <img src="/images/u35_blue.png" style={{ width: '40px' }} />
                                            <div className="form-group ml-3 pt-2">
                                                <label className={`checkbox-container pdl-20`}>This shipment is unstackable
                                                    <input type="checkbox" name="shipment_unstackable" value="shipment_unstackable" checked={ebooking.shipment_unstackable ? ebooking.shipment_unstackable : false} onChange={this.handleChange} />
                                                    <span className={`checkmark-checkbox ${(errors.shipment_unstackable) ? 'input-error' : ''}`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container block-form">
                        <div className="form-content no_padding">
                            <div className="form-rows">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="weight">Handling Instructions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-7 col-md-4">
                                                <Select
                                                    inputId="instructions"
                                                    className={`select-box huge-input ${(errors.instructions) ? 'input-error' : ''}`}
                                                    value={this.state.selectedInstruction}
                                                    isSearchable={true}
                                                    isDisabled={false}
                                                    onChange={this.handleChangeInstructions}
                                                    options={this.state.listInstructions}
                                                    isLoading={this.state.isLoadingInstruction}
                                                    onBlur={this.handleOnBlurSelect}
                                                />
                                                <InputError error={errors.instructions} />
                                            </div>
                                            <div className="form-group col-5 col-md-4 col-lg-2">
                                                <button type="button" className="btn btn-ebooking-orange" onClick={this.handleClickInstruction}>Add Instruction <i className="playIcon"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`form-rows mt-4 ${ebooking.instructions && ebooking.instructions.length ? "" : "d-none"}`}>
                                <div className="row">
                                    {
                                        ebooking.instructions.map((op, i) => {
                                            return <div className="col-12" key={i} value={op.value}>
                                                <div className="instruction-list-item">
                                                    {op.label}
                                                    <button type="button" onClick={this.removeAddedInstruction.bind(this, i)} className="close-instruction"></button>
                                                </div>
                                            </div>;
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`form-containers form-rows ${(ebooking.shipment_dangerous_goods || ebooking.shipment_lithium_batteries || ebooking.shipment_unstackable) ? "" : "d-none"}`}>
                                <label htmlFor="additional_information">Additional Information</label>
                                <textarea className="form-control fullwidth" id="additional_information" name="additional_information" onChange={this.handleChange} value={ebooking.additional_information}></textarea>
                                <InputError error={errors.additional_information} />
                            </div>

                            <div className="form-containers form-rows">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="company_name_of_consignor">Company Name of Consignor/Shipper</label>
                                                <Autosuggest
                                                    className={`form-control ${(errors.company_name_of_consignor) ? 'input-error' : ''}`}
                                                    suggestions={suggestionsCNOR}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNOR}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNOR}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    inputProps={inputPropsCNOR}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="company_name_of_consignee">Company Name of Consignee/Importer</label>
                                                <Autosuggest
                                                    className={`form-control ${(errors.company_name_of_consignee) ? 'input-error' : ''}`}
                                                    suggestions={suggestionsCNEE}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCNEE}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedCNEE}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    inputProps={inputPropsCNEE}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-containers form-rows div_center text-center">
                                <button type="button" onClick={this.handSubmit} className="btn btn-ebooking-orange" disabled={this.state.btnText != "View Quote" ? true : false}>{this.state.btnText}</button>
                                <div className="clb"></div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch),
        claimActions: bindActionCreators(claimActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewEbooking);