import * as ActionTypes from '../actionTypes';

const initialState = {
    isError: false,
    ClaimNumSuccess: '',
    ClaimNum: '',
    flight_date: '',
    email: '',
    claimertype: '',
    insurance: false,
    claimertype_description: '',
    awbnum: '',
    awb_number_1: '',
    awb_number_2: '',
    nature_of_goods: '',
    number_of_pc: '',
    weight: '',
    NG: '',
    name: '',
    phonenum: '',
    cargo_type: '',
    cargo_type_object: null,
    //step2
    claimtype: 'Form',
    Insured: '',
    claim_aspects: [],
    text: '',
    uploading: [],
    ClaimAspect: [],
    ClaimAmount: [],
    //step3
    uploadFolder: '',
    ClaimFiles: [
    ],
    upload_file_type: [],
    filestypes: [],
    condition: 0,
    //status           
    step_1_ok: false,
    step_2_ok: false,
    step_3_ok: false,
    claim_id: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLAIM_RESET: {
            var defaultState = {
                isError: false,
                ClaimNumSuccess: '',
                ClaimNum: '',
                flight_date: '',
                email: '',
                claimertype: '',
                insurance: false,
                claimertype_description: '',
                awbnum: '',
                awb_number_1: '',
                awb_number_2: '',
                nature_of_goods: '',
                number_of_pc: '',
                weight: '',
                NG: '',
                name: '',
                phonenum: '',
                cargo_type: '',
                cargo_type_object: null,
                //step2
                claimtype: 'Form',
                Insured: '',
                claim_aspects: [],
                text: '',
                ClaimAspect: [],
                ClaimAmount: [],
                //step3
                uploadFolder: '',
                ClaimFiles: [
                ],
                uploading: [],
                upload_file_type: [],
                filestypes: [],
                condition: 0,
                //status            
                step_1_ok: false,
                step_2_ok: false,
                step_3_ok: false,
                claim_id: ''
            };
            return Object.assign({}, state, defaultState)
        }
        case ActionTypes.CLAIM_CHANGE_DATA: {
            var newState = { ...state };
            newState[action.payload.fieldName] = action.payload.fieldValue;
            return Object.assign({}, state, newState)
        }
        case ActionTypes.CLAIM_UPDATE_DATA: {
            var geted_data = action.payload;
            return Object.assign({}, state, geted_data)
        }
    }
    return state;
}