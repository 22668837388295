import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import Select from 'react-select';
import uuid from 'uuid';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as claimActions from '../../ninja-redux/actions/claimActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as helpActions from '../../ninja-redux/actions/helpActions';

class ClaimBasic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            claim: {
                flight_date: '',
                email: '',
                claimertype: '',
                insurance: false,
                claimertype_description: '',
                awb_number_1: '',
                awb_number_2: '',
                nature_of_goods: '',
                number_of_pc: '',
                weight: '',
                NG: '',
                name: '',
                phonenum: '',
                cargo_type: '',
                cargo_type_object: null
            },
            cargo_types: [],
            breadcrumbs: [
                {
                    'link': '',
                    'active': true,
                    'name': 'New Claim'
                },
            ],
            steps: [
                {
                    'link': '',
                    'active': true,
                    'validate': false,
                    'name': 'Basic Info'
                },
                {
                    'link': 'claim-detail',
                    'active': false,
                    'validate': true,
                    'name': 'Claim Details'
                },
                {
                    'link': 'claim-submit',
                    'active': false,
                    'validate': true,
                    'name': 'Submit Documents'
                },
            ]
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClearDate = this.handleClearDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.getAwb = this.getAwb.bind(this);
        this.isValidationDetail = this.isValidationDetail.bind(this);
        this.isValidationAwb = this.isValidationAwb.bind(this);
    }

    /* Handle clear date field value */
    handleClearDate = () => {
        const { claim } = this.state;
        claim.flight_date = '';
        this.setState({ claim });
    }

    /* Handler date fields change value */
    handleChangeDate = (v) => {
        const { claim } = this.state;
        claim.flight_date = v.format('YYYY/MM/DD');
        this.setState({ claim });
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        //get redux data and set to state   
        var { claim } = this.props;
        this.setState({ claim });
        // console.log("this.props.match.path", this.props.match.path);
        if (this.props.match.path === "/claim") {
            this.props.claimActions.claimReset().then(res => {
                claim = this.props.claim;
                this.setState({ claim });
            });
        }

        if (this.props.cargotype.state === 'CARGO_TYPE_LOADED_DATA' && !this.props.cargotype.types.length) {
            this.props.helpActions.helpGetCargoType();
        }

        document.title = "Claims | Challenge Group"
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if ((oldProps.cargotype.state != "CARGO_TYPE_LOADED_DATA" && newProps.cargotype.state == "CARGO_TYPE_LOADED_DATA") || (newProps.cargotype.state == "CARGO_TYPE_LOADED_DATA" && !this.state.cargo_types.length)) {
            var cargo_types = [];
            newProps.cargotype.types.forEach(c => {
                cargo_types.push({ value: c.name, label: c.name });
            });
            this.setState({ cargo_types });
        }
    }

    getAwb() {
        const { claim } = this.state;
        var errors = {};
        var isError = false;
        var validator = new Validator();
        claim.isAwb = false;
        this.props.claimActions.claimChangeData({ fieldName: "isAwb", fieldValue: false }).then(responseData => { });
        this.setState({ claim });
        var length;
        if (!validator.required(claim.awb_number_1)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }
        if (!validator.number(claim.awb_number_1)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.exlength(claim.awb_number_1, 3)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.required(claim.awb_number_2)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.number(claim.awb_number_2)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.exlength(claim.awb_number_2, 8)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }
        if (!isError) {
            var awbNumber = claim.awb_number_1 + '' + claim.awb_number_2;
            this.props.loadingActions.loadingChangeData(true);
            this.props.claimActions.getAWBData(awbNumber).then(response => {
                this.props.loadingActions.loadingChangeData(false);
                if (response.success && response.data.awbdata.length > 0) {
                    var awbdata = response.data.awbdata[0];
                    claim["nature_of_goods"] = awbdata.NG;
                    claim["number_of_pc"] = awbdata.numOfPieces;
                    claim["weight"] = awbdata.weight;
                    claim["flight_date"] = (awbdata.FATD === "01/01/1988 00:00" ? "" : awbdata.FATD);
                    claim.isAwb = true;
                    this.props.claimActions.claimChangeData({ fieldName: "isAwb", fieldValue: true }).then(responseData => { });
                    this.setState({ claim });
                    errors = [];
                    this.setState({ errors });
                } else {
                    isError = true;
                    errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
                    claim["nature_of_goods"] = "";
                    claim["number_of_pc"] = "";
                    claim["weight"] = "";
                    claim["flight_date"] = "";
                    claim.isAwb = false;
                    this.props.claimActions.claimChangeData({ fieldName: "isAwb", fieldValue: false }).then(responseData => { });
                    this.setState({ claim });
                    this.setState({ errors });
                }
            });
        } else {
            this.setState({ errors });
        }

    }

    isValidationAwb() {
        const { errors } = this.state;
        var validator = new Validator();
        const { claim } = this.state;
        var isError = false;
        if (claim.awb_number_1 != '' && claim.awb_number_2 != '') {
            if (!validator.required(claim.awb_number_1)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }
            if (!validator.number(claim.awb_number_1)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }

            if (!validator.exlength(claim.awb_number_1, 3)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }

            if (!validator.required(claim.awb_number_2)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }

            if (!validator.number(claim.awb_number_2)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }

            if (!validator.exlength(claim.awb_number_2, 8)) {
                errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
                isError = true;
            }
        }

        if (!isError) {
            errors['awb_number'] = '';
        }

        this.setState({ errors });
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { claim } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;

        if (name == 'awb_number_1' || name == 'awb_number_2') {
            if (value.length <= 8) {
                claim[name] = value;
            }
            claim.awbnum = claim.awb_number_1 + '' + claim.awb_number_2;
            this.props.claimActions.claimChangeData({ fieldName: "awbnum", fieldValue: claim.awbnum }).then(responseData => {
            });
        } else {
            claim[name] = value;
        }
        this.setState({ claim });

        //Change Data
        this.props.claimActions.claimChangeData({ fieldName: name, fieldValue: value }).then(responseData => {
            //reset description value
            if (name === "claimertype") {
                claim["description"] = "";
                this.setState({ claim });
                this.props.claimActions.claimChangeData({ fieldName: "description", fieldValue: "" }).then(responseData => {
                });
            }

        });
    }

    handleChangeCargoType = (selectedValue) => {
        var { claim, errors } = this.state;
        claim.cargo_type = selectedValue.label;
        claim.cargo_type_object = selectedValue;
        errors["cargo_type"] = "";
        this.props.claimActions.claimChangeData({ fieldName: "cargo_type", fieldValue: selectedValue.label }).then(responseData => {
        });
        this.props.claimActions.claimChangeData({ fieldName: "cargo_type_object", fieldValue: selectedValue }).then(responseData => {
        });
        this.setState({ claim, errors });
    }

    handleBlurCargoType = () => {
        var { claim, errors } = this.state;
        if (claim.cargo_type) {
            errors['cargo_type'] = "";
        } else {
            errors['cargo_type'] = MESSAGES.ERROR_REQUIRED;
        }
        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { claim } = this.state;
        if (!validator.email(claim.email)) {
            errors['email'] = MESSAGES.ERROR_EMAIL;
            isError = true;
        }
        if (!validator.required(claim.name)) {
            errors['name'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!validator.required(claim.phonenum)) {
            errors['phonenum'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }
        if (!claim.isAwb) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER;
            isError = true;
        }
        if (!validator.required(claim.awb_number_1)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }
        if (!validator.number(claim.awb_number_1)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.exlength(claim.awb_number_1, 3)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.required(claim.awb_number_2)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.number(claim.awb_number_2)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (!validator.exlength(claim.awb_number_2, 8)) {
            errors['awb_number'] = MESSAGES.ERROR_AWB_NUMBER2;
            isError = true;
        }

        if (claim.claimertype == "") {
            errors['claimertype'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (claim.claimertype == "Other" && !validator.required(claim.claimertype_description)) {
            errors['claimertype_description'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (claim.cargo_type == "" || !claim.cargo_type) {
            errors['cargo_type'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            this.props.claimActions.claimChangeData({ fieldName: "step_1_ok", fieldValue: false }).then(responseData => { });
            return false;
        }
        this.props.claimActions.claimChangeData({ fieldName: "step_1_ok", fieldValue: true }).then(responseData => { });
        return true;
    }

    isValidationDetail(event) {
        var { errors, claim } = this.state;
        const { target, target: { name, type } } = event;
        var validator = new Validator();
        switch (name) {
            case 'email':
                if (!validator.email(claim.email)) {
                    errors['email'] = MESSAGES.ERROR_EMAIL;
                } else {
                    errors['email'] = '';
                }
                break;
            case 'claimertype':
                if (!validator.required(claim.claimertype)) {
                    errors['claimertype'] = MESSAGES.ERROR_EMAIL;
                } else {
                    errors['claimertype'] = '';
                }
                break;
            case 'name':
                if (!validator.required(claim.name)) {
                    errors['name'] = MESSAGES.ERROR_REQUIRED;
                } else {
                    errors['name'] = '';
                }
                break;
            case 'phonenum':
                if (!validator.required(claim.email)) {
                    errors['phonenum'] = MESSAGES.ERROR_REQUIRED;
                } else {
                    errors['phonenum'] = '';
                }
                break;
            case 'claimertype_description':
                if (claim.claimertype == "Other" && !validator.required(claim.claimertype_description)) {
                    errors['claimertype_description'] = MESSAGES.ERROR_REQUIRED;
                } else {
                    errors['claimertype_description'] = '';
                }
                break;
            case 'cargo_type':
                if (claim.cargo_type == "") {
                    errors['cargo_type'] = MESSAGES.ERROR_REQUIRED;
                } else {
                    errors['cargo_type'] = '';
                }
                break;
        }
        this.setState({ errors });
    }

    /**
     * Handler click step
     */
    handClickStep = (e) => {
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        const { history } = this.props;
        history.push('/claim-detail');
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    
    render() {
        var { claim, breadcrumbs, steps, isError, errors, errorMessage, cargo_types } = this.state;       
        return (

            <div className="container claim-form">
                <div className='form-title'>NEW CLAIM</div>
                <Step stepData={steps} onClick={this.handClickStep} />
                <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                <div className="block-form claim-form">
                    <form>
                        <h2 className="detail">Your Details</h2>
                        <div className="form-containers form-rows">
                            <div className="row">
                                <div className="form-group col-md-4 col-sm-12">
                                    <label htmlFor="name">Full Name*</label>
                                    <input type="text" maxLength={37} className={`form-control ${(errors.name) ? 'input-error' : ''}`} id="name" name="name" value={this.state.claim.name} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                    <InputError error={errors.name} />
                                </div>
                                <div className="form-group col-md-4 col-sm-12">
                                    <label htmlFor="email">Email*</label>
                                    <input type="email" className={`form-control ${(errors.email) ? 'input-error' : ''}`} id="email" name="email" value={this.state.claim.email} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                    <InputError error={errors.email} />
                                </div>
                                <div className="form-group col-md-4 col-sm-12">
                                    <label htmlFor="phonenum">Phone Number*</label>
                                    <input type="text" maxLength={20} className={`form-control ${(errors.phonenum) ? 'input-error' : ''}`} id="phonenum" name="phonenum" value={this.state.claim.phonenum} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                    <InputError error={errors.phonenum} />
                                </div>
                            </div>
                        </div>


                        <div className="form-group form-radio form-rows">
                            <label className="radio-container">Shipper
                                <input type="radio" name="claimertype" value="Shipper" checked={this.state.claim.claimertype === "Shipper"} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                <span className={`checkmark ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                            </label>
                            <label className="radio-container">Consignee
                                <input type="radio" name="claimertype" value="Cosignee" checked={this.state.claim.claimertype === "Cosignee"} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                <span className={`checkmark ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                            </label>
                            <label className="radio-container">Forwarder
                                <input type="radio" name="claimertype" value="Forwarder" checked={this.state.claim.claimertype === "Forwarder"} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                <span className={`checkmark ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                            </label>
                            <label className="radio-container">Insurence Agent
                                <input type="radio" name="claimertype" value="Insurence" checked={this.state.claim.claimertype === "Insurence"} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                <span className={`checkmark ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                            </label>
                            <label className="radio-container">Other
                                <input type="radio" name="claimertype" value="Other" checked={this.state.claim.claimertype === "Other"} onBlur={this.isValidationDetail} onChange={this.handleChange} />
                                <span className={`checkmark ${(errors.claimertype) ? 'input-error' : ''}`}></span>
                            </label>
                            <label className="checkbox-container hereby">I hereby confirm that no cargo claim was filed with insurance company
                                <input type="checkbox" checked={claim.insurance == true} name="insurance" onChange={this.handleChange} />
                                <span className="checkmark-checkbox"></span>
                            </label>
                            <InputError error={errors.claimertype} />
                            <div className="clb"></div>
                        </div>
                        {(claim.claimertype == "Other") ? (
                            <div className="form-group form-rows">
                                <label htmlFor="email">Description*</label>
                                <textarea className={`form-control ${(errors.claimertype_description) ? 'input-error' : ''}`} name="claimertype_description" onBlur={this.isValidationDetail} onChange={this.handleChange} value={this.state.claim.claimertype_description}></textarea>
                                <InputError error={errors.claimertype_description} />
                            </div>
                        ) : (null)}
                        <div className='hr-line'>
                            <div className='hr-line-container'>
                                <img src='/images/sep_degrade-1024x16.png' />
                            </div>

                        </div>

                        <h2 className="detail">AWB Details</h2>

                        <div className="form-group awb-form form-rows">
                            <label htmlFor="awb_number">AWB Number*</label>
                            <input type="text" size="3" maxLength="3" className={`form-control small ${(errors.awb_number_1 || errors.awb_number) ? 'input-error' : ''}`} id="awb_number_1" name="awb_number_1" value={this.state.claim.awb_number_1} onBlur={this.isValidationAwb} onChange={this.handleChange} />
                            <input type="text" size="8" maxLength="8" className={`form-control medium ${(errors.awb_number_2 || errors.awb_number) ? 'input-error' : ''}`} id="awb_number_2" name="awb_number_2" value={this.state.claim.awb_number_2} onBlur={this.getAwb} onChange={this.handleChange} />
                            <button type="button" className="btn btn-go" onClick={this.getAwb} >Go</button>
                            <InputError error={errors.awb_number_1} />
                            <InputError error={errors.awb_number_2} />
                            <InputError error={errors.awb_number} />
                            <div className="clb"></div>
                        </div>

                        <div className="form-containers form-rows">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-12">
                                    <label htmlFor="nature_of_goods">Nature of Goods</label>
                                    <input type="text" className="form-control input-dark" readOnly id="nature_of_goods" name="nature_of_goods" value={this.state.claim.nature_of_goods} />
                                </div>
                                <div className="form-group  col-md-6 col-sm-12">
                                    <label htmlFor="flight_date">Flight Date</label>
                                    <input type="text" className="form-control input-dark" readOnly id="flight_date" name="flight_date" value={this.state.claim.flight_date} />
                                </div>
                            </div>
                        </div>
                        <div className="form-containers form-rows">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label htmlFor="number_of_pc">Number of Pieces</label>
                                            <input type="text" className="form-control input-dark small" readOnly id="number_of_pc" name="number_of_pc" value={this.state.claim.number_of_pc} />
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="weight">Weight (Kg)</label>
                                            <input type="text" className="form-control input-dark small" readOnly id="weight" name="weight" value={this.state.claim.weight} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group  col-md-6 col-sm-12">
                                    <label htmlFor="cargo_type">Cargo Type</label>
                                    <Select
                                        className={`select-box select-claim ${(errors.cargo_type) ? 'input-error' : ''}`}
                                        value={this.state.claim.cargo_type_object}
                                        isSearchable={false}
                                        isDisabled={false}
                                        onBlur={this.handleBlurCargoType}
                                        onChange={this.handleChangeCargoType}
                                        options={cargo_types}
                                    />
                                    <InputError error={errors.cargo_type} />
                                </div>
                            </div>

                        </div>

                        <div className="form-group form-rows">
                            <div className="dv-form-responsive">
                                <label htmlFor="NG">Nature of Goods (free text)</label>
                                <input className="form-control" name="NG" maxLength={110} onChange={this.handleChange} value={this.state.claim.NG}></input>
                            </div>
                        </div>

                        <div className="form-group form-rows">
                            <div className="dv-form-responsive">
                                <button type="button" onClick={this.handSubmit} className="btn btn-continues right">Continue</button>
                                <div className="clb"></div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        claimActions: bindActionCreators(claimActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        helpActions: bindActionCreators(helpActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimBasic);