import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';

class GSAMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            refno: "",
        };
    }

    componentDidMount() {
        //get redux data and set to state        
        var params = this.props.location.search;
        const parsedParams = queryString.parse(params);
        var refno = parsedParams.ref;
        if (refno) {
            this.setState({ refno });
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking"
    }

    render() {
        var { refno } = this.state;
        return (
            <div className="page-main">
                <div className="page-container" style={{ 'textAlign': "center", 'width': '100%', 'display': 'block' }}>
                    <table style={{ 'width': "100%", 'height': "100%" }}>
                        <tbody>
                            <tr className={``}>
                                <td className="accept-container">
                                    <div className="content-box">
                                        <h2>No problem</h2>
                                        <p>We've received your request and passed it <br /> along to our Cal Agent. You should be<br /> contacted shortly.</p>
                                        <p>Your reference number is: <span className="text-orange">#{refno}</span></p>
                                        <Link to={`/dashboard`} className="btn btn-orange">Great, Thanks</Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GSAMessage);