import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import moment from 'moment';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import base64 from 'base-64';
import StepProgress from '../../layouts/partials/StepProgress';
import BookingHelper from '../BookingHelper';
import * as ActionTypes from '../../ninja-redux/actionTypes';
import EbookingInputError from '../EbookingInputError';
import $ from 'jquery';
/**
 * Validator
 */
import InputError from '../InputError';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
import * as quotesActions from '../../ninja-redux/actions/quotesActions';
import Modal from 'react-modal';
import ModalBookingGSA from "../../layouts/partials/ModalBookingGSA";

var itemsPerPage = 6;
var itemsShowing = 0;
let stateLoginChanged = false;
class QuotesChooseFlights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quoteID: '',
            isError: false,
            errorMessage: null,
            errorText: '',
            isEdit: false,
            errors: {},
            loadedScreen: false,
            modalIsOpen: false,
            steps: [
                {
                    'link': 'quotes/flights',
                    'active': true,
                    'validate': true,
                    'current': false,
                    'name': 'Flights'
                },
                {
                    'link': 'quotes/participants',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Booking'
                },
                {
                    'link': 'quotes/review-order',
                    'active': false,
                    'current': false,
                    'validate': true,
                    'name': 'Review'
                }
            ],
            shipmentData: null,
            rate: 0,
            totalCostWeight: 0,
            totalPrice: 0,
            showMore: true,
            disableEditStatus: ['decline', 'canceled', 'cancel', 'creating quote', 'booked'],
            modalIsOpen: false,
        };
    }

    handlerShowLoginForm = (e) => {
        stateLoginChanged = true;
        window.parent.postMessage('show-login-form', '*');
    }

    onAfterOpen = () => {
        let $this = this;
        $('.ReactModal__Overlay').on('click', function (e) {
            let container = $('.modal-dialog');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $this.setState({ modalIsOpen: false });
            }
        });
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }
    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
    handleGSAContinue = (gsaData) => {
        this.closeModal();
        var { history } = this.props;
        history.push('/ebooking/participants-gsa');
    }

    /* handler form's fields change value */
    handleChange = async (event) => {
        var { quotes } = this.props;
        var { errors } = this.state;
        const { target, target: { name, type } } = event;
        const value = (type === 'checkbox') ? target.checked : target.value;
        quotes[name] = value;
        if (name === "checkbox_term_df") {
            errors["checkbox_term"] = "";
        } else if (name === 'sort_default') {
            // quotes.selected_flight = null;
            // quotes.selected_flight_review = null;
            quotes.page_df = 1;
        }
        // this.props.ebookingActions.ebookingChangeData({ fieldName: name, fieldValue: value }).then(responseData => { });
        this.setState({ errors });
        await this.props.quotesActions.quotesUpdateData(quotes);
        if (name == "sort_default") {
            this.sortFlight();
        }
    }
    sortFlight = async () => {
        var { quotes } = this.props;
        var flights = quotes.routes;
        switch (quotes.sort_default) {
            case "FromDate":
                flights = flights.sort((a, b) => { return a.stdSort.localeCompare(b.stdSort) });
                break;
            case "ToDate":
                flights = flights.sort((a, b) => { return a.staSort.localeCompare(b.staSort) });
                break;
            case "EconomyPrice":
                flights = flights.sort((a, b) => { return a.priceSort.localeCompare(b.priceSort) });
                break;
            case "ExpressPrice":
                flights = flights.sort((a, b) => { return a.expresspriceSort.localeCompare(b.expresspriceSort) });
                break;
            case "Duration":
                flights = flights.sort((a, b) => { return a.duration.localeCompare(b.duration) });
                break;
        }

        if (quotes.selected_flight_review) {
            let findNewIndex = flights.findIndex(flightData => {
                if ((quotes.selected_flight_review.fleg.leg === flightData.fleg.leg) && (quotes.selected_flight_review.tleg.leg === flightData.tleg.leg) && (quotes.selected_flight_review.vialeg.leg === flightData.vialeg.leg)) {
                    return true;
                } else {
                    return false;
                }
            })
            if (findNewIndex >= 0) {
                let moveData = flights[findNewIndex];
                flights.splice(findNewIndex, 1);
                flights.unshift(moveData);
            }
        }

        quotes.routes = flights;
        await this.props.quotesActions.quotesUpdateData(quotes);
    }
    componentDidUpdate = async (oldProps) => {
        const newProps = this.props;
        var { loadedScreen } = this.state;
        var { country, port, currencies } = this.props;
        if (country.countries.length && port.ports.length && currencies.data.length && !loadedScreen) {
            this.initScreen();
        }
        if (newProps.auth.state != "LOGGED_IN") {
            stateLoginChanged = false;
        }
    }

    componentDidMount() {
        localStorage.removeItem('SHOW_VALIDATE');
        var { showError, quoteID, steps } = this.state;
        var { quotes, auth } = this.props;
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
        quoteID = this.props.match.params.quoteID;
        // console.log(quotes);
        steps = steps.map((step, i) => {
            if (quotes.secretKey) {
                if (i < 2) {
                    step.link += '/' + quoteID;
                } else {
                    step.link = 'quotes/review/' + quotes.secretKey + '/' + quoteID;
                }
            } else {
                step.link += '/' + quoteID;
            }
            return step;
        })

        if (auth.IsGSA) {
             steps[1].link = 'quotes/participants-gsa/' + quoteID;            
        }
        this.setState({ steps });

        this.setState({ steps });
        if (!this.timerIDCheckLogin) {
            this.timerIDCheckLogin = setInterval(
                () => this.checkShowLoginForm(),
                1000
            );
        }
    }

    componentWillUnmount() {
        if (this.timerIDCheckLogin) {
            clearInterval(this.timerIDCheckLogin);
        }
    }

    checkShowLoginForm = () => {
        const { history, auth } = this.props;
        var quoteID = this.props.match.params.quoteID;
        var authState = auth.state;
        if ((authState != 'GETTING_DATA' && authState != 'LOGGING_IN' && authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED') && !auth.expiredToken && !window.checkHasModalShowing()) {
            history.push("/login?back=" + base64.encode("/quotes/flights/" + quoteID));
        }
    }

    handClickStep = async () => {

    }

    initScreen = async () => {
        this.setState({ loadedScreen: true });
        var { showError, secretKey, quoteID, steps } = this.state;
        var { quotes, auth, history } = this.props;
        quoteID = this.props.match.params.quoteID;
        await this.props.loadingActions.loadingChangeData(true);
        if (!quotes || quotes.quoteID !== quoteID) {
            await this.props.loadingActions.loadingChangeData(true);
            await this.props.quotesActions.quotesReset();           
            await this.props.loadingActions.loadingChangeData(true); 
            let getQuotesData = await this.props.quotesActions.buildQuoteData(secretKey, quoteID, true);            
            if(auth.IsGSA){
                getQuotesData.quotesData.selectedShippingReceiving = '';
            }
            if (getQuotesData.success) {                
                quotes = getQuotesData.quotesData;
            } else {
                this.setState({ errorText: 'showContactLink' })
            }
            await this.props.loadingActions.loadingChangeData(true);
            await this.props.quotesActions.quotesUpdateData(getQuotesData.quotesData);
        }
        let status = quotes.status ? quotes.status.trim() : '';
        status = status.toLowerCase();
        if (!quotes.status || this.state.disableEditStatus.includes(status)) {
            this.setState({ errorText: 'showContactLink' })
            return;
        }
        this.sortFlight();
        await this.props.loadingActions.loadingChangeData(false);
    }

    getPortDataBig = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.portname;
        } else {
            return "";
        }
    }
    getPortDataSmall = (portname) => {
        var { port } = this.props;
        var getSelectedP = port.ports.find(p => p.portname === portname);
        if (getSelectedP) {
            return getSelectedP.countryname;
        } else {
            return "";
        }
    }
    handChoosePrice = (priceType, flight, price) => {
        var { quotes } = this.props;
        if (price > 0) {
            var flights = flight.flights;
            flights = flights.filter(f => f.leg != -2);
            flight.flights = flights;
            quotes.DefaultChoosed.flightID = flight.uuid;
            quotes.DefaultChoosed.priceType = priceType;
            quotes.DefaultChoosed.price = price;
            quotes.DefaultChoosed.flight = flight;
            this.props.quotesActions.quotesUpdateData(quotes);
            this.calPrice();
        }
    }

    calPrice = () => {
        var { quotes } = this.props;
        var { totalPrice, totalCostWeight, rate } = this.state;
        totalCostWeight = 0;
        totalPrice = 0;
        rate = 0;
        var partPrice = 0;

        for (var i = 0; i < quotes.DefaultChoosed.flight.Parts.length; i++) {
            var part = quotes.DefaultChoosed.flight.Parts[i];
            partPrice += parseFloat(part.price);
        }
        totalCostWeight = parseFloat(quotes.units_total_chargable_weight) * parseFloat(quotes.DefaultChoosed.price);
        totalPrice = totalCostWeight + partPrice;
        this.setState({ totalPrice: totalPrice, totalCostWeight: totalCostWeight });
    }

    buildParts = (data, currency) => {
        const partItems = [];
        if (data && data.flight && data.flight.Parts) {
            for (var i = 0; i < data.flight.Parts.length; i++) {
                var part = data.flight.Parts[i];
                partItems.push(
                    <div className="addition-charges-element" key={i}>
                        <div className="row">
                            <div className="col-8 addition-title">{part.partdes}</div>
                            <div className="col-4 text-right addition-value">{BookingHelper.formatMoney(part.price)} <span>{currency}</span></div>
                        </div>
                    </div>
                );
            }
        }
        return partItems;
    }

    handleShowMore = async () => {
        var { quotes } = this.props;
        quotes.page_df++;
        await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
    }

    selectedFlight = async (flight) => {
        var { quotes } = this.props;
        if (quotes.selected_flight_review != flight) {
            quotes.selected_flight_review = flight;
            quotes.selected_flight = flight;
        } else {
            quotes.selected_flight_review = null;
            quotes.selected_flight = null;
        }
        await this.props.quotesActions.quotesUpdateData(quotes).then(responseData => { });
    }

    buildFlightItems = () => {
        var { quotes } = this.props;
        var { showMore } = this.state;
        const flightItems = [];
        quotes.routes.map((op, i) => {
            var dStd = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var dTime = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            var aTime = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var aTimeDiff = BookingHelper.getDate(op.sta, "DD/MM/YYYY HH:mm");
            var dTimeDiff = BookingHelper.getDate(op.std, "DD/MM/YYYY HH:mm");
            aTimeDiff.set({ hour: 0, minute: 0 });
            dTimeDiff.set({ hour: 0, minute: 0 });
            // var prices = BookingHelper.getPrices(op);
            if (flightItems.length < itemsPerPage * quotes.page_df) {
                flightItems.push(
                    <div className="col-md-6 col-lg-4" key={i}>
                        <div className={`flight-element update-quotes mb-3 div_center ${(quotes.selected_flight_review && quotes.selected_flight_review === op) ? 'active' : ''}`} onClick={this.selectedFlight.bind(this, op)}>
                            <div className="flight-element-content">
                                <div className="flight-name text-center">Flight {op.fleg.fnumber}</div>
                                <div className="flight-date text-center">{dStd.format("MMMM Do")}</div>
                                <div className="flight-duration mt-3 mb-3 text-center">
                                    <div className="row">
                                        <div className="col-4 text-right">{dTime.format("HH:mm")}</div>
                                        <div className="col-4 duration-line">
                                            <div className="line"></div>
                                        </div>
                                        <div className="col-4 text-left showLandingTime">
                                            {aTime.format("HH:mm")}
                                            <span className={`${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'd-none'}`}>+{aTimeDiff.diff(dTimeDiff, "days")}</span>
                                            <div className={`landingTime ${aTimeDiff.diff(dTimeDiff, "days") > 0 ? '' : 'no-add-day'}`}>Lands: {aTime.format("MMMM Do, HH:mm")}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`choose-flight text-center`}>
                                    {op.fleg.fport} - {op.fleg.tport} {op.vialeg.tport ? (' - ' + op.vialeg.tport) : ''} {op.tleg.tport ? (' - ' + op.tleg.tport) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        itemsShowing = flightItems.length;
        return flightItems;
    }

    /* Validate form */
    isValidation = async () => {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        var { quotes } = this.props;

        // if (!quotes.selected_flight_review) {
        //     isError = true;
        //     errors.selectedFlight = "Please choose flight.";
        // }

        if (isError) {
            this.setState({ errors });
            quotes.step_2_ok = false;
            await this.props.quotesActions.quotesUpdateData(quotes);
            return false;
        }
        quotes.step_2_ok = true;
        await this.props.quotesActions.quotesUpdateData(quotes);
        return true;
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!this.isValidation()) {
            window.scrollToFirstError();
            return false;
        }
        var { quotes, history, auth } = this.props;
        quotes.step_2_ok = true;
        await this.props.quotesActions.quotesUpdateData(quotes);
        window.jumpToTop();
        if (auth.IsGSA) {            
            history.push('/quotes/participants-gsa/' + quotes.CProfAU);
        } else {
            history.push('/quotes/participants/' + quotes.CProfAU);
        }

    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };

    render() {
        var { steps, modalIsOpen, shipmentData, errorMessage, isError, errorText, errors } = this.state;
        var { quotes, auth } = this.props;
        return (
            <div className="ebooking review-order  quote-preview">
                <StepProgress stepData={steps} progress={40} onClick={this.handClickStep} />

                <div className="container">
                    <h1 className="title mt-5">Step one: Getting a Quote</h1>
                </div>
                <form>
                    <div className="container block-form">
                        <h1 className="formTitle mb-2">Choosing a flight</h1>
                        <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />
                        <div className={`form-group ${(errorText != "" && errorText != "showContactLink") ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                {errorText}
                            </div>
                        </div>

                        <div className={`form-group mt-3 ${this.state.showProceeding ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                Session expired. Please <a href="javascript:void(0)" onClick={this.handlerShowLoginForm}>login</a> before proceeding. Thanks.
                            </div>
                        </div>

                        <div className={`form-group mt-3 ${errorText == "showContactLink" ? '' : 'd-none'}`}>
                            <div className="alert alert-danger" role="alert">
                                An error occurred. Please try again. If problem persists please <a href="https://www.cal-cargo.com/contact-us/" target="_blank">contact us</a>. Thank you!
                            </div>
                        </div>

                        <div className="mt-5 flight-shipment-detail div_center text-center">
                            <div className={`row`}>
                                <div className="col-4 col-lg-5">
                                    <div className="shipment-city">
                                        {quotes.selected_flight_review ? quotes.selected_flight_review.fleg.fport : ""}
                                    </div>
                                    <div className="shipment-address">
                                        {quotes.selected_flight_review ? this.getPortDataSmall(quotes.selected_flight_review.fleg.fport) : ""}
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 shipment-plain-icon"></div>
                                <div className="col-4 col-lg-5">
                                    <div className="shipment-city">
                                        {quotes.selected_flight_review ? ((quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.tport) ? quotes.selected_flight_review.tleg.tport : quotes.selected_flight_review.fleg.tport) : ""}
                                    </div>
                                    <div className="shipment-address">
                                        {quotes.selected_flight_review ? this.getPortDataSmall((quotes.selected_flight_review.tleg && quotes.selected_flight_review.tleg.tport) ? quotes.selected_flight_review.tleg.tport : quotes.selected_flight_review.fleg.tport) : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="shipment-quote-detail text-center mt-3">
                                <div className="shipment-quote-detail-title">My Quote Details</div>
                                <div className="shipment-quote-pieces mt-3">
                                    <div className="quote-elements">
                                        <div className="quote-element">
                                            <div className="quote-value">{quotes.unit_total.total_pieces}</div>
                                            <div className="quote-name">Pieces</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{quotes.unit_total.total_weight} <span>kg</span></div>
                                            <div className="quote-name">Actual Weight</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{quotes.unit_total.tcw} <span>kg</span></div>
                                            <div className="quote-name">Chargable Weight</div>
                                        </div>
                                        <div className="quote-element">
                                            <div className="quote-value">{quotes.unit_total.total_volume}</div>
                                            <div className="quote-name">Volume (CBM)</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="list-flight mt-5">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <select name="sort_default" id="sort_default" value={quotes.sort_default} className="sort-select" onChange={this.handleChange}>
                                        <option value="FromDate">Sort by from date</option>
                                        <option value="ToDate">Sort by to date</option>
                                        <option value="EconomyPrice">Sort by economy price</option>
                                        <option value="ExpressPrice">Sort by express price</option>
                                        <option value="Duration">Sort by duration</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center local-times">
                                    All times are local times
                                </div>
                            </div>
                            <div className={`row mb-1 mt-5 ${errors.selectedFlight ? "" : "hide"}`}>
                                <div className="col-lg-12">
                                    <div className="show-bottom-error bottom0">
                                        <InputError error={errors.selectedFlight} class="bg-red" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 flight-elements div_center mt-5">
                                <div className="row">
                                    {
                                        this.buildFlightItems()
                                    }
                                </div>
                            </div>
                            <div className={`row mt-3 ${itemsShowing < quotes.routes.length ? '' : 'd-none'}`}>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-show-more-results" onClick={this.handleShowMore}>Show More Results</button>
                                </div>
                            </div>
                        </div>

                        <div className="addition-charges footer-text div_center mt-3 mb-4 text-center">
                            {/* I want to continue with a <span>Cal Agent</span> (this form will be saved) */}
                        </div>

                    </div>


                    <div className="container no_padding">
                        <div className={`row mt-3 mb-2 ${errors.checkbox_term_parcel ? "" : "hide"}`}>
                            <div className="col-lg-12">
                                <div className="show-bottom-error bottom0">
                                    <InputError error={errors.checkbox_term_parcel} class="bg-red" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-total div_center">
                                    <div className="total-txt">Total</div>
                                    <div className="total-price">{BookingHelper.formatMoney(quotes.offer_total)} <span>{quotes.offer_currency ? quotes.offer_currency.label : ''}</span></div>
                                    {/* <div className="total-price"></div> */}
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="footer-buttons float-right pr-2 pr-md-0 mt-3 mt-md-0">
                                    <div className="btn-process">
                                        <button type="button" disabled={(auth.state === 'LOGGED_IN' || auth.state === 'NEW_PASSWORD_REQUIRED' || auth.state === 'EMAIL_VERIFICATION_REQUIRED') ? false : true} onClick={this.handSubmit} className="ml-3 btn btn-ebooking-orange">Continue to Booking</button>
                                    </div>
                                    <div className="clb"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={this.closeModal}
                    shouldCloseOnOverlayClick={false}
                    className="modal fade show"
                    overlayClassName="mfp-bg"
                >
                    <ModalBookingGSA
                        onShow={modalIsOpen}
                        closeModal={this.closeModal}
                        onHide={this.closeModal}
                        handleGSAContinue={this.handleGSAContinue}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch),
        quotesActions: bindActionCreators(quotesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotesChooseFlights);