import React from 'react';

export default class InputError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { error } = this.props;
        if (error) {
            return (
                <label className={`error text-danger ${this.props.class}`}>{error}</label>
            )
        } else {
            return (null)
        }
    }
}