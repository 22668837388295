import React from 'react';
import Breadcrumb from '../../layouts/partials/Breadcrumb';
import Step from '../../layouts/partials/Step';
import DatePicker from 'react-datepicker';
import DatePickerInput from '../DatePickerInput';
import { Link } from 'react-router-dom';
import axios from 'axios';
import sha256 from 'sha256';
import uuid from 'uuid';
import moment from 'moment';
import ReactDOM from 'react-dom';
/**
 * Validator
 */
import InputError from '../InputError';
import CountrySelect from '../CountrySelect';
import Validator from '../../libs/Validator';
import Notification from '../Notification';
import * as MESSAGES from '../../libs/Messages';
/**
 * Redux actions
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ebookingActions from '../../ninja-redux/actions/ebookingActions';
import * as loadingAction from '../../ninja-redux/actions/loadingAction';
/**
 * react-table
 */
import 'core-js/es6/number';
import 'core-js/es6/array';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../css/react-table.css';

/**
 * Tooltip
 */
import ReactTooltip from 'react-tooltip'
import '../../css/react-tooltip.css';


class PaymentCASS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            isEdit: false,
            errors: {},
            ebooking: {
                PricelistChoosed: {
                }
            },
            breadcrumbs: [
                {
                    'link': 'ebooking/shipment',
                    'active': true,
                    'name': 'e-Booking'
                },
            ],
            steps: [
                {
                    'link': 'ebooking/shipment',
                    'active': false,
                    'validate': false,
                    'name': 'Booking Details'
                },
                {
                    'link': 'ebooking/flight-pricing-default',
                    'active': false,
                    'validate': true,
                    'name': 'Choose Flight'
                },
                {
                    'link': 'ebooking/participants',
                    'active': false,
                    'validate': true,
                    'name': 'Participants'
                },
                {
                    'link': 'ebooking/review-order',
                    'active': false,
                    'validate': true,
                    'name': 'Review Order'
                },
                {
                    'link': 'ebooking/payment',
                    'active': true,
                    'validate': true,
                    'name': 'Payment'
                },
            ],
            total: 0,
            paymentUrl: "",
            pricingCase: "",
            payment: {
                currency: "USD",
                customerName: "",
                paymentAmount: "",
                iataCode: "",
                cassStation: ""
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handClickStep = this.handClickStep.bind(this);
        this.handleBook = this.handleBook.bind(this);
        this.isValidationWithField = this.isValidationWithField.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    async componentDidMount() {
        // console.log(this.props);
        //get redux data and set to state   
        var { ebooking, history, pricingCase, auth } = this.props;
        var authState = auth.state;
        var { steps } = this.state;
        if (!ebooking.step_1_ok || (authState != 'LOGGED_IN' && authState != 'NEW_PASSWORD_REQUIRED' && authState != 'EMAIL_VERIFICATION_REQUIRED')) {
            history.push('/ebooking/shipment');
        } else if (!ebooking.step_2_ok) {
            if (ebooking.PriceQuotation.pl.length > 0) {
                history.push('/ebooking/flight-pricing-pricelist');
            } else {
                switch (ebooking.PriceQuotation.type) {
                    case "WHITE":
                        history.push('/ebooking/flight-pricing-default');
                        break;
                    case "PARCEL":
                        history.push('/ebooking/flight-pricing-parcel');
                        break;
                    case "PL":
                        history.push('/ebooking/flight-pricing-pricelist');
                        break;
                    default:
                        history.push('/ebooking/shipment');
                        break;
                }
            }
        } else if (!ebooking.step_3_ok) {
            if (auth.IsGSA) {
                history.push('/ebooking/participants-gsa');
            } else {
                history.push('/ebooking/participants');
            }
        }
        if (auth.IsGSA) {
            steps[2].link = 'ebooking/participants-gsa';
        }
        this.setState({ steps, ebooking });
        if (this.props.auth.credit != "") {
            history.push('/ebooking/payment-credit');
        } else if (this.props.auth.cass != "") {
            this.buildFormData(ebooking);
        } else {
            history.push('/ebooking/payment');
        }
        document.title = "Cargo Air lines - Air Cargo Service - E-booking";
    }

    buildFormData = (ebooking) => {
        var { payment } = this.state;
        var amount = 0;
        switch (ebooking.PriceQuotation.type) {
            case "PARCEL":
                amount = ebooking.ParcelChoosed.total;
                break;
            case "WHITE":
                if (ebooking.PriceQuotation.pl.length == 0) {
                    var totalCostWeight = parseFloat(ebooking.units_total_chargable_weight) * parseFloat(ebooking.DefaultChoosed.price);
                    amount += totalCostWeight;
                    ebooking.DefaultChoosed.flight.Parts.map((part, i) => {
                        amount += parseFloat(part.price);
                    });
                }
                break;
        }
        payment.paymentAmount = amount.toFixed(2);
        this.setState({ payment });
    }

    /* handler form's fields change value */
    handleChange(event) {
        const { payment, errors } = this.state;
        const { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        payment[name] = value;
        errors[name] = "";
        this.setState({ payment, errors });
    }

    handleOnBlur(event) {
        const { payment, errors } = this.state;
        const { target, target: { name, type } } = event;
        var value = (type === 'checkbox') ? target.checked : target.value;
        if (value != "") {
            errors[name] = "";
        }
        this.setState({ errors });
    }

    /* Validate form */
    isValidation() {
        this.setState({ errors: {}, errorMessage: '' });

        var errors = {};
        var isError = false;
        var validator = new Validator();
        const { payment } = this.state;


        if (payment.customerName == "") {
            errors['customerName'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (payment.iataCode == "") {
            errors['iataCode'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (payment.cassStation == "") {
            errors['cassStation'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (payment.paymentAmount == "" || !validator.currency(payment.paymentAmount)) {
            errors['paymentAmount'] = MESSAGES.ERROR_REQUIRED;
            isError = true;
        }

        if (isError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    isValidationWithField(event) {
        const { ebooking, errors } = this.state;
        var validator = new Validator();
        const { target } = event;
        var value = target.value;
        var isError = false;
        switch (target.name) {
        }
    }


    handleBook() {
        // console.log('book');
    }


    /**
     * Handler click step
     */
    handClickStep = (e) => {
        // console.log(e);
        if (!this.isValidation()) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }

    getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

    /* Handle subbmit form */
    handSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        var { history } = this.props;
        var { payment, ebooking, errorMessage } = this.state;
        this.setState({ errorMessage: null, isError: false });
        if (!this.isValidation()) {
            return false;
        }
        var routeFlight = {};
        var isexpress = "";
        var pl = 0;
        var parcelprice = 0.0;
        var parcelexpprice = 0.0;
        if ((ebooking.PriceQuotation.type == "WHITE" || ebooking.PriceQuotation.type == "PL") && ebooking.PriceQuotation.pl.length > 0) {
            routeFlight = ebooking.PricelistChoosed.selectedFlightData;
            pl = ebooking.PricelistChoosed.selectedPLData.plnum;
        } else if (ebooking.PriceQuotation.type == "WHITE") {
            routeFlight = ebooking.DefaultChoosed.flight;
            isexpress = ebooking.DefaultChoosed.priceType == "express" ? "Y" : "";
        } else if (ebooking.PriceQuotation.type == "PARCEL") {
            routeFlight = ebooking.DefaultChoosed.flight;
            isexpress = ebooking.ParcelChoosed.priceType == "expressprice" ? "Y" : "";
            parcelprice = ebooking.PriceQuotation.price;
            parcelexpprice = ebooking.PriceQuotation.expressprice;
        }

        var bookData = {
            "currency": payment.currency,
            "awb": "70060424674",
            // "awb": this.props.auth.iatacode,
            "authcode": "",
            "amount": payment.paymentAmount,
            "transid": "-1",
            // "custname": this.props.auth.custname,
            "custname": "11250006",
            // "cust": this.props.auth.cust,
            "cust": 0,
            "type": "CASS",
            "isexpress": isexpress,
            "pl": pl,
            "parcelpl": 0,
            "parcelprice": parcelprice,
            "parcelexpprice": parcelexpprice,
            "Participents": {
                "SHIPNAME": ebooking.shippingCompanyName,
                "SHIPIATACODE": ebooking.shippingIATA1 + ebooking.shippingIATA2,
                "RECNAME": ebooking.receivingCompanyName,
                "RECIATACODE": ebooking.receivingIATA1 + ebooking.receivingIATA2,
                "CNORNUM": "",
                "CNORNAME": ebooking.consignorCompanyName,
                "CNORCOUNTRY": ebooking.consignorCountry,
                "CNORADDRESS": ebooking.consignorAddress,
                "CNORCITY": ebooking.consignorCity,
                "CNORPOSTALCODE": ebooking.consignorPostalCode,
                "CNEENUM": "",
                "CNEENAME": ebooking.consigneeCompanyName,
                "CNEECOUNTRY": ebooking.consigneeCountry,
                "CNEEADDRESS": ebooking.consigneeAddress,
                "CNEEPOSTALCODE": ebooking.consigneePostalCode,
                "CNEECITY": ebooking.consigneeCity,
                "PICKCOMPNAME": ebooking.pickupCompanyName,
                "PICKNAME": ebooking.pickupNameOfContact,
                "PICKEMAIL": ebooking.pickupEmail,
                "PICKPHONE": ebooking.pickupPhoneNumber,
                "PICKCOUNTRY": ebooking.pickupCountry,
                "PICKCITY": ebooking.pickupCity,
                "PICKADDRESS": ebooking.pickupAddress,
                "PICKPOSTALCODE": ebooking.pickupPostalCode,
                "DELCOMPNAME": ebooking.deliverCompanyName,
                "DELNAME": ebooking.deliverNameOfContact,
                "DELEMAIL": ebooking.deliverEmail,
                "DELPHONE": ebooking.deliverPhoneNumber,
                "DELCOUNTRY": ebooking.deliverCountry,
                "DELCITY": ebooking.deliverCity,
                "DELADDRESS": ebooking.deliverAddress,
                "DELPOSTALCODE": ebooking.deliverPostalCode,
            },
            "PriceQuotation": ebooking.passedData,
            "routes": routeFlight
        };

        this.props.loadingActions.loadingChangeData(true);
        this.props.ebookingActions.setSafeCharge(bookData, this.props.auth.jwtToken).then(response => {
            this.props.loadingActions.loadingChangeData(false);
            if (response.success && response.data.Success) {
                history.push('/ebooking/success');
            } else {
                this.setState({ errorMessage: "Booking Error. Plz try again.", isError: true });
                window.scrollToTop();
            }
        });
    }

    hideMessage = () => {
        this.setState({ errorMessage: '' });
    };
    
    render() {
        const { ebooking, breadcrumbs, steps, isError, errors, errorMessage }
            = this.state;

        return (

            <div className="container ebooking">
                <Breadcrumb breadcrumbData={breadcrumbs} />

                <h1 className="title">e-Booking</h1>

                <Step stepData={steps} onClick={this.handClickStep} />

                <Notification isError={isError} message={errorMessage} hideMessage={this.hideMessage} />

                <div className="block-form">
                    <form>
                        <h2 className="detail">
                            Payment
                        </h2>

                        <div className="form-group form-rows">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="form-group form-rows col-12">
                                            <label htmlFor="customerName">Customer Name</label>
                                            <input type="text" className={`form-control ${(errors.customerName) ? 'input-error' : ''}`} id="customerName" name="customerName" value={this.state.payment.customerName} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                            <InputError error={errors.customerName} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group form-rows col-12">
                                            <label htmlFor="cassStation">Payment via CASS station</label>
                                            <input type="text" className={`form-control ${(errors.cassStation) ? 'input-error' : ''}`} id="cassStation" name="cassStation" value={this.state.payment.cassStation} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                            <InputError error={errors.cassStation} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group form-rows col-12">
                                            <label htmlFor="iataCode">IATA Code</label>
                                            <input type="text" className={`form-control ${(errors.iataCode) ? 'input-error' : ''}`} maxLength="11" id="iataCode" name="iataCode" value={this.state.payment.iataCode} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                            <InputError error={errors.iataCode} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group form-rows col-12">
                                            <label htmlFor="paymentAmount">Paymnet Amount</label>
                                            <input type="text" className={`form-control ${(errors.paymentAmount) ? 'input-error' : ''}`} id="paymentAmount" name="paymentAmount" value={this.state.payment.paymentAmount} onBlur={this.handleOnBlur} onChange={this.handleChange} />
                                            <InputError error={errors.paymentAmount} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group form-rows col-12">
                                        <label className={`inline-elements`}>Currency: </label>
                                        <select className={`form-control inline-elements`} name="currency" value={this.state.payment.currency} onChange={this.handleChange}>
                                            <option value="USD">USD</option>
                                            <option value="GBP">GBP</option>
                                            <option value="EUR">EUR</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="form-containers form-rows">
                    <button type="button" onClick={this.handSubmit} className="btn btn-continues">Book Now</button>
                    <div className="clb"></div>
                </div>

            </div>


        );
    }
}

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch) {
    return {
        ebookingActions: bindActionCreators(ebookingActions, dispatch),
        loadingActions: bindActionCreators(loadingAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCASS);